import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { Link } from 'react-router-dom';
import { callingAPI } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
function Pharmacypayment() {
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [paymentHistory, setPayedHistory] = useState([]);
	const [totalCount, setotalCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [totalAmount, setTotal] = useState();
	const [functionName] = useState('Pharmacypayment');
	const [search, setSearch] = useState('');
	const [enable, setEnable] = useState(1);
	const [searchValue, setsearchValue] = useState('');
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	const [isData, setIsData] = useState(0);
	const exportFunction = () => {
		const csvRow = [];
		var A = [['No:', 'Patient%20Name', 'Invoice%20Number', 'Date', 'Receipt%20Number', 'Amount%20in%20Rupees']];
		var re = paymentHistory;
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: "",
			offset: 0,
			dropdownSearchType: search,
			searchKeyword: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('reports/pharmacyPayment', PostData)
			.then((response) => {
				for (var item = 0; item < response.data.result.paymentHistory.length; item++) {
					A.push([
						item + 1,
						response.data.result.paymentHistory[item].memeberProcedurePaymentRecieptPatientName.replace(/ /g, '%20'),
						response.data.result.paymentHistory[item].memeberProcedureInvoiceNo,
						response.data.result.paymentHistory[item].memeberProcedureInvoiceDate.replace(/,/g, '').replace(/ /g, '%20'),
						response.data.result.paymentHistory[item].memeberProcedurePaymentReceiptReceipt,
						response.data.result.paymentHistory[item].doctorVisitMedicineNetAmnt.replace(/,/g, ''),
					]);
				}
				for (var i = 0; i < A.length; ++i) {
					csvRow.push(A[i].join('|'));
				}
				var csvString = csvRow.join('%0A');
				var a = document.createElement('a');
				a.href = 'data:attachment/csv,' + csvString;
				a.target = '_Blank';
				a.download = functionName + '.csv';
				document.body.appendChild(a);
				a.click();
			})
	};
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setPageOffset(offset)
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) {
			setSearch("");
		}
		if (!booliansearchValue) { setsearchValue("") }
		if (!boolianfromDate) { setFromDate("") }
		if (!booliantoDate) { setToDate("") }
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: max,
			offset: offset,
			dropdownSearchType: boolianfilterName,
			searchKeyword: booliansearchValue,
			fromDate: boolianfromDate ? formatDate(fromDate) : '',
			toDate: booliantoDate ? formatDate(toDate) : '',
		};
		callingAPI('reports/pharmacyPayment', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setPayedHistory(response.data.result.paymentHistory);
					setotalCount(response.data.result.totalCount);
					setTotal(response.data.result.totalAmount);
					setIsData(1);
				}
			})
			.catch();
	};
	const setReceiptRecptno = (htmlData,doctorId) => {
		// window.sessionStorage.setItem('htmlData', htmlData);
		var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${htmlData+ '|' + doctorId + '|'+ window.localStorage.getItem('hospitalId')}`
		window.open(a, "_blank")
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchValueChange = (event) => {
		setsearchValue(event.target.value);
	};
	const changeText = (value) => {
		setsearchValue('');
		setSearch(value);
		if (value === '') {
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}

	};
	const searchValueOnChange = () => {
		if (document.querySelector('#selectName').value !== '' &&
			document.getElementById('searchText').value === ''
		) {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			alert(selectedText + ' Required');
			document.querySelector('#searchText').focus();
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(search);
		setboolianfromDate(fromDate);
		setbooliantoDate(toDate)
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				alert('End date must be greater than start date');
				return false;
			}
		}
		setActivePage(1);
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: 10,
			offset: pageOffset,
			dropdownSearchType: search,
			searchKeyword: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('reports/pharmacyPayment', PostData)
			.then((response) => {
				// 	if (response.data.success === '0') {
				// 		setPayedHistory([]);
				// 		setIsData(1);
				// }
				if (response.data.success === '1') {
					setPayedHistory(response.data.result.paymentHistory);
					setotalCount(response.data.result.totalCount);
					setTotal(response.data.result.totalAmount);
					setIsData(1);
				}
				else {
					setPayedHistory([]);
					setIsData(1);

				}
			})
			.catch();
	};
	useEffect(() => {
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
			dropdownSearchType: search,
			searchKeyword: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
		};
		callingAPI('reports/pharmacyPayment', PostData)
			.then((response) => {
				if (response.data.success === '1') {
					setPayedHistory(response.data.result.paymentHistory);
					setotalCount(response.data.result.totalCount);
					setTotal(response.data.result.totalAmount);
					setIsData(1);
				}
				else {
					setIsData(1);
					setPayedHistory([]);
					setotalCount(0);
				}
			})
			.catch();
	}, []);

	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setToDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};


	return (
		<div className="dashboardCover">
			<div className="dashboardTitle">Pharmacy Payment</div>
			{totalCount > 10 && (
				// {paymentHistory.length > 0 && (
				<div className="paginationSection">
					<Pagination
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
				// )}
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									className="inputField"
									id="selectName"
									name="selectName"
									value={search}
									onChange={(e) => changeText(e.target.value)}

								>
									<option value="">Search In</option>
									<option value="1">Patient Name</option>
									<option value="2">Invoice Number</option>
									<option value="3">Reciept Number</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								{search == "" ?
									<input
										type="text"
										className="inputField"
										value=""
										// onChange={searchValueChange}
										disabled
										style={{ background: "transparent" }}
										id="searchText"
										name="searchText"
									/>
									:
									<input
										type="text"
										className="inputField"
										value={searchValue}
										onChange={searchValueChange}
										// disabled={enable}
										autofocus="autofocus"
										autoComplete='off'
										id="searchText"
										name="searchText"
										style={{ background: "white" }}
									/>
								}
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										// maxDate={new Date()}
										className="Datepicker pa2 inputField"
										placeholderText="From Date"
										calendarClassName="rasta-stripes"
										selected={fromDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setFromDate(date)}
										id="fromdate"
										peekNextMonth
										showMonthDropdown
										autoComplete='off'
										showYearDropdown
										dropdownMode="select" calendarContainer={MyContainer} onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
									<Datepicker
										required
										autoComplete='off'
										// maxDate={new Date()}
										className="Datepicker pa2 inputField"
										placeholderText="To Date"
										calendarClassName="rasta-stripes"
										selected={toDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setToDate(date)}
										id="todate"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select" calendarContainer={MyContainerTo} onChangeRaw={handleDateChangeRaw}
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span onClick={searchValueOnChange} className="dashButtonBorder">
									Search
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span onClick={exportFunction} className="dashButtonBorder dashButtonBorderGrey">
									Export
								</span>
							</div>
						</div>
						<div className="row">
							<div className="col-md-9">
								<div className="searchTxt" style={{ textAlign: 'right' }}>
									<b>Total Amount</b> : {paymentHistory.length == 0 ? 0 : totalAmount}
								</div>
							</div>
							<div className="col-md-3">
								<div className="searchCount">
									No.of pharmacy payment : {paymentHistory.length == 0 ? 0 : totalCount}
								</div>
							</div>
						</div>
					</div>
					{isData == 0 ? (
						<div align="center">
							<Loader />
						</div>
					) : (
						<div className="dashboardtable">
							{isData == 1 && (


								<table style={{tableLayout:"unset"}}>
									{paymentHistory.length > 0 && (
										<thead>
											<tr>
												<th>Date</th>
												<th>Patient Name</th>
												<th style={{maxWidth:"300px"}}>Invoice Number</th>
												<th>Receipt Number</th>
												<th style={{ textAlign: 'right' }}>Amount</th>
											</tr>
										</thead>
									)}
									<tbody>
										{paymentHistory.map((data, i) => (
											<tr>
												<td>{data.memeberProcedureInvoiceDate}</td>
												<td>{data.memeberProcedurePaymentRecieptPatientName}</td>
												<td style={{maxWidth:"300px",wordBreak:"break-word"}}>{data.memeberProcedureInvoiceNo}</td>
												<td>
													<div>
														{' '}
														{data.memeberProcedurePaymentReceiptReceipt != '' ? (
															<Link
																to="#"
																onClick={() =>
																	setReceiptRecptno(data.memberProcedurePaymentRecieptId,data.doctorId)}
															>
																{' '}
																{data.memeberProcedurePaymentReceiptReceipt}
															</Link>
														) : (
															''
														)}
													</div>
												</td>
												<td style={{ textAlign: 'right' }}>
													&#8377; {data.doctorVisitMedicineNetAmnt}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
							{paymentHistory.length == 0 && isData == 1 && <NoData />}
							{/* ) : (
							<NoData />
						)} */}
						</div>
					)}
				</div>
				{totalCount > 10 && (
					/* {paymentHistory.length > 0 && ( */
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							hideDisabled
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={totalCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePageChange}
						/>
					</div>
				)}
				{/* {paymentHistory.length > 0 && (
					<div align="center" style={{ textAlign: 'center' }}>
						<span class="successMessageRed successMessageRedBottom" style={{ clear: 'both' }}>
							* Total Amount Excluded Of Discount,PromoCode And Other Discount Coupons
						</span>
					</div>
				)} */}
				{/* {paymentHistory.length == 0 ? <NoData /> : ''} */}
			</div>
		</div>
	);
}
export default Pharmacypayment;
