import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TodayAppoinment from "../../images/icons/today-appointment.png"
import TotalIncome from "../../images/icons/total-income.png"
import OpenAppoinment from "../../images/icons/open-appoinments.png"
import ArrivedAppoinment from "../../images/icons/arrived-appoinments.png"
import Patient from "../../images/icons/total-patient.png"
import TotalAppoinments from "../../images/icons/total-appoinments.png"
// import UpcomingAppoiments from "./upcomingAppoiments";
// import TodoListStatusView from './todoListStatusView';
import {
    faArrowLeft,
    faHome,
    faUser,
    faWallet,
    faEllipsisV
} from "@fortawesome/free-solid-svg-icons";

function TodayTotalAppoimentSec() {
    const [dataFlag, setDataFlag] = useState(false);
    const [todayTotalAppointment, setTodayTotalAppointment] = useState([])
    const [todayTotalIncome, setTodayTotalIncome] = useState([])
    const [todayTotalOpenAppointment, setTodayTotalOpenAppointment] = useState([])
    const [todayTotalArrivedAppointment, setTodayTotalArrivedAppointment] = useState([])
    const [totalPatient, setTotalPatient] = useState([])
    const [totalAppointment, setTotalAppointment] = useState([])
    useEffect(() => {
        const PostData = {
            hospitalId: localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/totalPatientsAppointmentsIncomeDetails', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setTodayTotalAppointment(response.data.result.todayTotalAppointment)
                setTodayTotalIncome(response.data.result.todayTotalIncome)
                setTodayTotalOpenAppointment(response.data.result.todayTotalOpenAppointment)
                setTodayTotalArrivedAppointment(response.data.result.todayTotalArrivedAppointment)
                setTotalPatient(response.data.result.totalPatient)
                setTotalAppointment(response.data.result.totalAppointment)
            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    return (
        <div className="col-md-3 total-sec-wrpr">
            {/* <UpcomingAppoiments/> */}
            <div className="total_block">
                <div className="total_subsec border_bottom">
                    <div className="total_secicon"><img src={TodayAppoinment} alt="Today Appoinment" className='img-fluid' width={25} height={25} /></div>
                    <div className="total_sectext">
                        <div className='total_sechead'>Today's Appointments</div>
                        <h5 className='total_seccount'>{todayTotalAppointment}</h5>
                    </div>
                </div>
                <div className="total_subsec">
                    <div className="total_secicon"><img src={TotalIncome} alt="Total Income" className='img-fluid' width={25} height={25} /></div>
                    <div className="total_sectext">
                        <div className='total_sechead'>Today's Income</div>
                        <h5 className='total_seccount'>{todayTotalIncome}</h5>
                    </div>
                </div>

            </div>

            <div className="total_block">
                <div className="total_subsec border_bottom">
                    <div className="total_secicon"><img src={OpenAppoinment} alt="Open Appoinments" className='img-fluid' width={25} height={25} /></div>
                    <div className="total_sectext">
                        <div className='total_sechead'>Today's Open Appointments</div>
                        <h5 className='total_seccount'>{todayTotalOpenAppointment}</h5>
                    </div>
                </div>
                <div className="total_subsec">
                    <div className="total_secicon"><img src={ArrivedAppoinment} alt="Arrived Appoinment" className='img-fluid' width={25} height={25} /></div>
                    <div className="total_sectext">
                        <div className='total_sechead'>Today's Arrived Appointments</div>
                        <h5 className='total_seccount'>{todayTotalArrivedAppointment}</h5>
                    </div>
                </div>

            </div>

            <div className="total_block">
                <div className="total_subsec border_bottom">
                    <div className="total_secicon"><img src={Patient} alt="Patient" className='img-fluid' width={25} height={25} /></div>
                    <div className="total_sectext">
                        <div className='total_sechead'>Total Patients</div>
                        <h5 className='total_seccount'>{totalPatient}</h5>
                    </div>
                </div>
                <div className="total_subsec">
                    <div className="total_secicon"><img src={TotalAppoinments} alt="Total Appoinments" className='img-fluid' width={25} height={25} /></div>
                    <div className="total_sectext">
                        <div className='total_sechead'>Total Appointments</div>
                        <h5 className='total_seccount'>{totalAppointment}</h5>
                    </div>
                </div>

            </div>
            {/* <TodoListStatusView/> */}
        </div>
    )
}

export default TodayTotalAppoimentSec
