import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Image from '../images/media/imanews.jpg';
import HealthRecordPinkImg from '../images/media/healthRecordPinkImg.jpg';
import HealthRecordBlueImg from '../images/media/healthRecordBlueImg.jpg';
import Close from '../images/icons/close.png';
import Medicalfile from '../images/media/medicalfile.jpg';
import { callingAPI } from '../config';
import { Link } from 'react-router-dom';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import AWS from 'aws-sdk';
import upload from '../images/icons/upload.png';
var moment = require('moment');
var s3;

function PatientFilesMedical() {
	const [medicalRecordList, setMedicalRecordList] = useState([]);
	const [dataFlag, setDataFlag] = useState(false);
	const [getData1, setData1] = useState(false);
	const [file, setFile] = useState('');
	const [imageName, setImageName] = useState(null);
	const [folderArray, setFolderArray] = useState([]);
	const [folderRecordName, setFolderRecordName] = useState('');
	const [folderDocumentId, setFolderDocumentId] = useState('');
	const [folderName, setFolderName] = useState('');

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	useEffect(() => {
		listing();
	}, []);
	const listing = () => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: window.sessionStorage.getItem('memberId'),
		};
		callingAPI('clinicalBackground/medicalFilesList', apiJson).then((data) => {
			if (data.data.success === '1') {
				setMedicalRecordList(data.data.result.addHealthRecordDateGroup);
				if (data.data.result.addHealthRecordDateGroup.length > 0) {
					setData1(true)
				}
				else {
					setData1(false)
				}

				setDataFlag(true);
			}
		});

	};
	const girlBabyGraph = () => {

	};
	const boyBabyGraph = () => {

	};

	const handleImageUploadOnChange = (e) => {
		if (e.target.files[0].size > 3000000) {
			alert("Image size should less than 3 MB");
			return false;
		}
		else if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
			alert("Please attach jpg,jpeg or png files");
			return false;
		}
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name);
		document.getElementById('uploadButton').style.display = 'none';
		document.getElementById('loadingButton').style.display = 'block';
		saveRecord(e.target.files[0].name, e.target.files[0]);
	};
	const saveRecord = (imageName, fulFile) => {


		var dateCurrent = moment(new Date()).format('YYYY-MM-DD hh:mm A');

		const apiJson = {
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			scheduledtimeSlotStartTime: '',
			scheduledtimeSlotEndTime: '',
			appointmentDate: dateCurrent,
			userId: window.sessionStorage.getItem('userId'),
			documentName: imageName,
		};
		callingAPI('clinicalBackground/saverecords', apiJson).then((data) => {
			if (data.data.success === '1') {
				imageS3Save(imageName, fulFile);

			}
		});
	};
	const imageS3Save = async (imageName, file) => {
		var S3BucketMainFolder =
			process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;
		var fullFilePath = S3BucketMainFolder + '/' + imageName;

		await s3.upload(
			{
				Key: fullFilePath,
				Body: file,
				ACL: 'public-read',
			},
			(err, data) => {
				if (err) {
					alert('There was an error uploading your photo: ' + err.message);
					return null;
				} else {
					alert("File Uploaded Successfully")
					document.getElementById('addHealthRecord').style.display = 'none';
					document.getElementById('uploadButton').style.display = 'block';
					document.getElementById('loadingButton').style.display = 'none';
					listing();
				}
			}
		);
	};

	const closeTest = () => {
		document.getElementById('addHealthRecord').style.display = 'none';
		document.getElementById('uploadButton').style.display = 'block';
		setData1(false)
	};
	const addHealthRecord = () => {
		document.getElementById('addHealthRecord').style.display = 'block';
		setData1(true)
	};

	const deleteChange = (id) => {
		var x = false;
		x = window.confirm('Do you want to Delete?');
		if (x === true) {
			const satusData = {
				recordId: id,
			};
			callingAPI('clinicalBackground/deleteRecords', satusData).then((data) => {
				if (data.data.success === '1') {
					alert('Deleted Successfully');
					window.location.href = '/patients/patientFilesMedical';
					listing();
				}
				if (data.data.success === '0') {
					alert(data.data.errorMessage);
				}
			});
		}
	};

	const showDetailImageList = (folderDetails) => {
		setFolderArray(folderDetails.folderArray);
		setFolderName(folderDetails.folderName);
		setFolderRecordName(folderDetails.descriptions);

		document.getElementById('imageList').style.display = 'none';
		document.getElementById('imageExpansionFolder').style.display = 'block';
	};
	const showImageList = () => {
		document.getElementById('imageList').style.display = 'block';
		document.getElementById('imageExpansionFolder').style.display = 'none';
	};

	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div className="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">


										<div className="treatmentPlanCover" id="treatmentPlanListDiv">
											<div className="treatmentPlanHead" style={{ padding: '13px 10px 2px' }}>
												<span className="treatmentPlanName">HEALTH RECORDS</span>
												<span
													className="treatmentPlanBtn pl_blue_btn"
													onClick={addHealthRecord}
												>
													+ Add
												</span>
											</div>
											{/* ====== Health Records Image Upload Begins ====== */}
											<div
												style={{ display: 'none' }}
												id="addHealthRecord"
												className="healthRecordsImgUpload"
											>
												<h2>Add Health Record</h2>
												<div className="healthRecordsClose">
													<img
														src={Close}
														alt="Close"
														title="Close"
														onClick={closeTest}
													/>
												</div>
												<span id="uploadButton" className="uploadButton uploadButtonSmall">
													<input
														type="file"
														id="file1"
														title="Upload"
														onChange={(e) => handleImageUploadOnChange(e)}
													/>
													<label for="file1">Upload</label>

												</span>
												<span className='uploadCondtionMedical'>Maximum 3MB</span>
												<span
													style={{ display: 'none' }}
													id="loadingButton"
													className="uploadButton uploadButtonSmall"
												>
													<label for="loadfile1">Loading...</label>
												</span>
											</div>
											{/* ====== Health Records Image Upload Ends ====== */}
											{/* ====== Health Records Image New updation begins  ======  */}
											{/* <div className='healthRecordWrapper'>
												<div className='HealthRecordThumb'>
													<img id="girlBaby" src={HealthRecordPinkImg} onClick={girlBabyGraph} />
												</div>
												<div className='HealthRecordThumb'>
													<img id="boyBaby" src={HealthRecordBlueImg} onClick={boyBabyGraph} />
												</div>
											</div> */}
											{/* ====== Health Records Image New updation ends  ======  */}
											{/*  */}
											{/*  */}
											{dataFlag == false ? (
												<Loader />
											) : medicalRecordList.length > 0 ? (
												<div>
													{medicalRecordList.map((listObj, i) => (
														<div
															className="treatmentPlanList ptFilesMedical"
															style={{ height: 'auto' }}
														>
															<div className="treatmentPlanDate">{listObj.visit_date}</div>
															<div className="treatmentTable planActive pl_patientFiles">
																<table>
																	<thead>
																		<tr>
																			<th className="col-md-12">
																				<div className="dentalProName">Files</div>
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr className="groupBrdr">
																			<td className="col-md-12 fileRecordThumbWarp">
																				<div
																					id="imageList"
																					style={{ display: 'block' }}
																				>
																					<div id="appendFolderData1" />
																					{listObj.details.map((itemObj, j) => (
																						<div id="allImages">
																							{itemObj.folderName === null ||
																								itemObj.folderName === '' ? (
																								<div
																									className="fileRecordThumb itemFolder itemFolderImg"
																									id="thumbSection136"
																									title=""
																								>
																									<Link
																										to={{
																											pathname:
																												'/Patients/patientFilesMedicalView',
																											state: {
																												emrPatientdocument_name:
																													itemObj.document_name,
																												emrPatientRecordId:
																													itemObj.emrPatientRecordId,
																												emrPatientSpecializationRecordsId:
																													itemObj.specializationRecordsId,
																											},
																										}}
																									>
																										<img
																											src={
																												process.env
																													.REACT_APP_S3_BUCKET_URL +
																												process.env
																													.REACT_APP_S3_ENVIRONMEWNT_TYPE +
																												process.env
																													.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH +
																												'/' +
																												itemObj.document_name
																											}
																											className="imgSec"
																										/>
																									</Link>

																									<span className="thumbClose imgSecthumbClose">
																										<img
																											src={Close}
																											style={{
																												width:
																													'15px',
																												height:
																													'15px',
																											}}
																											title="Click to Delete "
																											onClick={() =>
																												deleteChange(
																													itemObj.emrPatientRecordId
																												)}
																										/>
																									</span>
																									<span className="fileimgHead elipsis" />
																								</div>
																							) : (
																								<div
																									className="fileRecordThumb1"
																									id="thumbSection136"
																									title=""
																								>
																									<div
																										onClick={(e) => {
																											showDetailImageList(
																												itemObj
																											);
																										}}
																									>
																										<div id="divNotNull2">
																											<div class="itemFolder ">
																												<div class="itemFolderIcon" />
																												<span className="plfolderTxt">
																													{
																														itemObj.folderName
																													}
																												</span>
																											</div>
																										</div>
																									</div>
																								</div>
																							)}
																						</div>
																					))}{' '}
																					{/*  */}
																				</div>

																				<div
																					style={{ display: 'none' }}
																					id="imageExpansionFolder"
																				>
																					<div class="sliderBreadCrumb">
																						<span style={{ display: 'block' }}>
																							<a onClick={showImageList}>
																								All Images
																							</a>
																						</span>
																						<span
																							id="divFolderName2"
																							style={{ display: 'block' }}
																						>
																							{folderName}
																						</span>
																					</div>

																					{folderArray.map((folderimage, j) => (
																						<div
																							className="fileRecordThumb"
																							id="thumbSection136"
																							title=""
																						>
																							<Link
																								to={{
																									pathname:
																										'/Patients/patientFilesMedicalView',
																									state: {
																										emrPatientdocument_name:
																											folderimage.document_name,
																										emrPatientRecordId:
																											folderimage.emrPatientRecordId,
																									},
																								}}
																							>
																								<img
																									src={
																										process.env
																											.REACT_APP_S3_BUCKET_URL +
																										process.env
																											.REACT_APP_S3_ENVIRONMEWNT_TYPE +
																										process.env
																											.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH +
																										'/' +
																										folderimage.document_name
																									}
																								/>
																							</Link>

																							<span className="thumbClose">
																								<img
																									src={Close}
																									style={{
																										width: '15px',
																										height: '15px',
																									}}
																									title="Click to Delete "
																									onClick={() =>
																										deleteChange(
																											folderimage.emrPatientRecordId
																										)}
																								/>
																							</span>
																							<span className="plfolderTxt">
																								{folderRecordName}
																							</span>
																							<span className="fileimgHead elipsis" />
																						</div>
																					))}
																				</div>

																				{/*  */}
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													))}
												</div>
											) : (
												<p id="noData" >
													{getData1 === false ? <NoData /> : ''}
												</p>
											)}
										</div>

									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PatientFilesMedical;
