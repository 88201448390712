import Logo from '../images/avatarHospitalDefault.png';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import { useParams } from 'react-router-dom';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import Loader from '../Common/loader';
function RecieptPrint(props) {
	let { printId } = useParams();
	const [recieptType, setRecieptType] = useState('')
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [doctorDetails, setDoctorDetails] = useState({});
	const [invoiceDate, setInvoiceDate] = useState('');

	const [advanceAmount, setAdvanceAmount] = useState('');
	const [dueAmount, setDueAmount] = useState('');
	const [recieptList, setRecieptList] = useState({});

	const [getItem, setItem] = useState([]);
	const [load, setLoad] = useState(false);
	const [hospitalImagename, sethospitalImagename] = useState('');
	const [hospitalAddress, sethospitalAddress] = useState('');
	const [headertitle, setheadertitle] = useState('');

	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');

	const [pagesizeid, setpagesizeid] = useState('');
	const [treatmentPlanResult, setTreatmentPlanResult] = useState([]);
	const [treatmentPlanDate, setTreatmentPlanDate] = useState('');
	const [grandTotal, setSrandTotal] = useState('');
	const [headingType, setHeadingType] = useState('Treatment Plans');
	const [isData, setIsData] = useState(false);
	const [isCompleted, setIsCompleted] = useState('0')
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [hosId, setHosId] = useState("")
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);

	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: hosId ? hosId : localStorage.getItem('hospitalId'),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

			});

	}

	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;

	useEffect(
		() => {
			let receiptId = printId.toString().split('|')[0]
			let docId = printId.toString().split('|')[1]
			var hos = printId.toString().split('|')[2]
			setHosId(hos)
			setLoad(false);
			//styleValues(printId)

			//Please check the route "/patients/RecieptPrint/:recieptType?/:receiptId?/:doctorId?"
			//Please change api postData dynamicaly as mentioned below with the url parameter
			// {
			// 	"receiptId" : receiptId,
			//  "doctorId":doctorId
			// }
			window.sessionStorage.removeItem("htmlData")
			callingAPI('commonUserFunctions/receiptPrint', {
				receiptId: receiptId,
				doctorId: typeof docId === "undefined" ? "" : docId,
			}).then((res) => {
				setMemberDetails(res.data.result[0].memberDetails);
				setHospitalDetails(res.data.result[0].hospitalDetails);
				setAdvanceAmount(res.data.result[0].advanceAmount);
				setDueAmount(res.data.result[0].dueAmount);
				setDoctorDetails(res.data.result[0].doctorDetails);
				setInvoiceDate(res.data.result[0].finalArray[0].receipt_date);
				sethospitalImagename(res.data.result[0].hospitalDetails.hospitalImageName);
				setheadertitle(res.data.result[0].hospitalDetails.hospitalName);
				sethospitalAddress(res.data.result[0].hospitalDetails.hospitalAddress);
				if (res.data.result[0].finalArray[0].main[0].recieptList.length > 0) {
					setRecieptList(res.data.result[0].finalArray[0].main[0].recieptList[0]);
					setLoad(true);
				}
				if (res.data.result[0].finalArray[0].main[0].recieptList[0].isRefund === 0 && res.data.result[0].finalArray[0].main[0].recieptList[0].isAdvance === 0) {
					setRecieptType("Payment");
				}
				else if (res.data.result[0].finalArray[0].main[0].recieptList[0].isRefund === 1) {
					setRecieptType("Refund");
				}
				else if (res.data.result[0].finalArray[0].main[0].recieptList[0].isAdvance === 1) {
					setRecieptType("Advance");
				}
				if (res.data.result[0].finalArray[0].main[0].item.length > 0) {
					// setInvoiceNumber(res.data.result[0].finalArray[0].main[0].item[0].invoiceNumber);
					// setPrescriptionArray(res.data.result[0].finalArray[0].main[0].item[0].prescriptionArray);
					// setProcedureArray(res.data.result[0].finalArray[0].main[0].item[0].procedureArray);
					// setInventoryArray(res.data.result[0].finalArray[0].main[0].item[0].inventoryArray);
					// setLabArray(res.data.result[0].finalArray[0].main[0].item[0].labArray);
					// setRoomArray(res.data.result[0].finalArray[0].main[0].item[0].roomArray);
					// setCafeteriaArray(res.data.result[0].finalArray[0].main[0].item[0].cafeteriaArray);
					// setLaundryArray(res.data.result[0].finalArray[0].main[0].item[0].laundryArray);
					setItem(res.data.result[0].finalArray[0].main[0].item);
					setLoad(true);
				}
			});
		},
		[props]
	);

	const onPrintButtonClick = () => {
		// document.getElementById('cancel').style.display = 'none';
		document.getElementById('print').style.display = 'none';
		window.print();
		// document.getElementById('cancel').style.display = 'inline-block';
		document.getElementById('print').style.display = 'inline-block';
	};
	// const back = () => {
	// 	window.location.href = '/reports/refund';
	// };
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div className="receiptPrint">
			<div>
				{load === false ? <Loader /> : ''}
				<div align="center">
					<div
						align="center"
						style={{
							width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
							height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
							marginLeft: '0px',
							marginright: '0px',
							marginTop: '10px',
							marginBottom: '10px',
						}}
					>
						<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">
							<thead>
								<tr>
									<th style={{ fontWeight: '400' }}>
										<div class="printOutHeaderSec ">
											<table style={{ width: '100%' }}>
												<tbody>

													<HeaderPrint hospitalDataId={hosId} />



													<tr>
														<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'left',
															}}
														>
															Patient :&nbsp;{memberDetails.memberFirstName == "" ? memberDetails.memberDisplayName : memberDetails.memberFirstName + " " + memberDetails.memberLastName}
														</td>

														{doctorDetails !== '' ? (
															<td colspan="6"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '17px',
																	textAlign: 'right',
																}}
															>
																Doctor :
																&nbsp;{doctorDetails.doctorDisplayName}
															</td>
														) : (
															''
														)}
													</tr>

													<tr>
														{memberDetails !== '' ? (
															<td
																colspan="4"
																style={{
																	width: '40%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '17px',
																	textAlign: 'left',
																}}
															>
																Age : {memberDetails.memberAge}
															</td>
														) : (
															''
														)}
														{invoiceDate !== '' ? (
															<td
																colspan="8"
																style={{
																	width: '80%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '17px',
																	textAlign: 'right',
																}}
															>
																Receipt Date : {invoiceDate}{' '}
															</td>
														) : (
															''
														)}
													</tr>
													<tr>
														{memberDetails !== '' ? (
															<td
																colspan="6"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '17px',
																	textAlign: 'left',
																}}
															>
																Sex : {memberDetails.memberGender}
															</td>
														) : (
															''
														)}
														{memberDetails !== '' ? (
															<td colspan="6"
																style={{
																	width: '50%',
																	fontSize: '12px',
																	padding: '0',
																	lineHeight: '17px',
																	textAlign: 'right',
																}}
															>
																Phone No : {memberDetails.SecuserMobileNumber}
															</td>
														) : (
															''
														)}
													</tr>
													<tr>
														{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
															(memberDetails !== '' ? (
																<td
																	colspan="6"
																	style={{
																		width: '50%',
																		fontSize: '12px',
																		padding: '0',
																		lineHeight: '17px',
																		textAlign: 'left',
																	}}
																>
																	MR No : {memberDetails.memberMrNo}
																</td>
															) : (
																''
															)) :
															(!memberDetails.memberPrNo == "" &&
																<td
																	colspan="6"
																	style={{
																		width: '50%',
																		fontSize: '12px',
																		padding: '0',
																		lineHeight: '17px',
																		textAlign: 'left',
																	}}
																>
																	PR No : {memberDetails.memberPrNo}
																</td>
															)}

													</tr>

													<tr>
														<td colspan="12">
															<hr
																color="#142536"
																style={{ margin: '0', opacity: '1', height: '2px' }}
															/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td>
										<div class="printOutSettingContent">
											<table width="100%" cellspacing="0">
												<tbody>
													<tr>
														<td
															style={{
																fontSize: '14px',
																paddingBottom: '10px',
																textAlign: 'left',
															}}
														>

															{recieptType}
														</td>
														<td
															style={{
																fontSize: '14px',
																paddingBottom: '10px',
																textAlign: 'right',
															}}
														>
															Receipt Number :&nbsp; {recieptList.receiptNumber}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>


								{(recieptType === 'Payment' || recieptType === 'Refund') && (
									<tr>
										<td>
											<div class="printOutSettingContent">
												<table width="100%" cellspacing="0">
													<tbody>
														<tr
															style={{
																fontSize: '12px',
																color: '#393939',
																background: ' #eae8e8',
															}}
														>
															<td
																style={{
																	boxSizing: ' border-box',
																	padding: ' 5px 2px',
																	width: '14%',
																	textAlign: 'left',
																	fontWeight: '600',
																	fontSize: '12px',

																	paddingLeft: '3px',
																}}
															>
																INVOICE
															</td>
															<td
																style={{
																	boxSizing: 'border-box',
																	padding: '5px 2px',
																	width: '90%',
																	textAlign: 'left',
																	position: 'relative',
																	fontSize: '11px',
																}}
															>
																<div
																	class="procedureCode"
																	style={{ fontSize: '11px !important' }}
																>
																	<div
																		class="procedureCodeHead"
																		style={{ fontWeight: '600' }}
																	>
																		<div
																			style={{
																				width: '18%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			TOWARDS
																		</div>
																		<div
																			style={{
																				width: '15%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Batch
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'left',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Exp
																		</div>
																		<div
																			style={{
																				width: '7%',
																				display: 'inline-block',
																				textAlign: 'center',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Qty
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Unit Price
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Cost
																		</div>
																		<div
																			style={{
																				width: '8%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Tax
																		</div>
																		<div
																			style={{
																				width: '13%',
																				display: 'inline-block',
																				textAlign: 'right',
																				verticalAlign: 'top',
																				fontSize: '12px',
																			}}
																		>
																			Total
																		</div>
																	</div>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								)}

								{(recieptType === 'Payment' || recieptType === 'Refund') && (
									<tr>
										<td>
											<div class="printOutSettingContent">
												<table
													width="100%"
													cellspacing="0"
													className="receiptprintInnerTable"
												>
													{getItem.map((data, i) => (
														<tbody>
															<tr>
																<td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '14%',
																		textAlign: 'left',
																		fontSize: '12px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td>
																<td>
																	{data.prescriptionArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td> */}

																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Prescription
																						</div>
																					</div>
																					{data.prescriptionArray.map(
																						(details) => (
																							<div class="procedureCodeValue codevalue_txt">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																								{details.hsnCode !== "" && <div class="procedureCodeIntake">
																									<strong>
																										HSN Code :{' '}
																									</strong>
																									<span>
																										{
																											details.hsnCode
																										}
																									</span>
																								</div>}

																								<div class="procedureCodeIntake">
																									<strong>
																										Intake :{' '}
																									</strong>
																									<span>
																										{details.instructions}{' '}
																										({details.inTake})
																									</span>
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																	{data.procedureArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>

																	{data.invoiceNumber}
																</td> */}
																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Procedure
																						</div>
																					</div>
																					{data.procedureArray.map(
																						(details) => (
																							<div class="procedureCodeValue">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																	{data.inventoryArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td> */}
																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Inventory
																						</div>
																					</div>
																					{data.inventoryArray.map(
																						(details) => (
																							<div class="procedureCodeValue">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																	{data.labArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td> */}
																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Lab
																						</div>
																					</div>
																					{data.labArray.map(
																						(details) => (
																							<div class="procedureCodeValue">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																	{data.roomArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td> */}
																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Room
																						</div>
																					</div>
																					{data.roomArray.map(
																						(details) => (
																							<div class="procedureCodeValue">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																	{data.cafeteriaArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td> */}
																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Cafeteria
																						</div>
																					</div>
																					{data.cafeteriaArray.map(
																						(details) => (
																							<div class="procedureCodeValue">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																	{data.laundryArray.length > 0 && (
																		<tr
																			style={{
																				borderBottom: '1px solid #ddd',
																				display: 'table',
																				width: '100%',
																			}}
																		>
																			{/* <td
																	style={{
																		boxSizing: 'border-box',
																		padding: '5px 2px',
																		width: '10%',
																		display: 'inline-block',
																		textAlign: 'left',
																		fontSize: '10px',
																		color: '#393939',
																		lineHeight: '18px',
																	}}
																>
																	{data.invoiceNumber}
																</td> */}
																			<td
																				class="inoviceBorder"
																				style={{
																					verticalAlign: 'top !important',
																					boxSizing: 'border-box',
																					padding: '5px 2px',
																					width: '90%',
																					textAlign: 'left',
																					fontSize: '12px',
																					color: '#393939',
																					lineHeight: '18px',
																				}}
																			>
																				<div
																					class="procedureCode"
																					style={{
																						fontSize: '11px !important',
																						marginTop: '0',

																						paddingBottom: '2px',
																						marginBottom: '2px', fontSize: "11px"
																					}}
																				>
																					<div
																						class="procedureCodeHead"
																						style={{
																							fontWeight: '600',
																							paddingBottom: '3px',
																						}}
																					>
																						<div
																							style={{
																								width: '18%',
																								display:
																									'inline-block',
																								textAlign: 'left',
																								verticalAlign:
																									'top', fontSize: "11px"
																							}}
																						>
																							Laundry
																						</div>
																					</div>
																					{data.laundryArray.map(
																						(details) => (
																							<div class="procedureCodeValue">
																								<div
																									style={{
																										width:
																											'18%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.itemName
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'15%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.batchNo ? (
																										details.batchNo
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'left',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.expireDate ? (
																										details.expireDate
																									) : (
																										'-'
																									)}
																								</div>
																								<div
																									style={{
																										width: '7%',
																										display:
																											'inline-block',
																										textAlign:
																											'center',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.quantity
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.unitPrice
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{details.cost}
																								</div>
																								<div
																									style={{
																										width: '8%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.taxAmount
																									}
																								</div>
																								<div
																									style={{
																										width:
																											'13%',
																										display:
																											'inline-block',
																										textAlign:
																											'right',
																										verticalAlign:
																											'top',
																									}}
																								>
																									{
																										details.totalAmount
																									}
																								</div>
																							</div>
																						)
																					)}
																				</div>
																			</td>
																		</tr>
																	)}
																</td>
															</tr>
														</tbody>
													))}
												</table>
											</div>
										</td>
									</tr>
								)}

								<tr>
									<td>
										<div class="printOutSettingContent">
											<table width="100%" cellspacing="0">
												<tbody>
													<tr>
														<td>
															<div
																class="taxValueTable"
																style={{
																	width: '40%',
																	marginTop: '-6px',
																	display: 'inline-block',
																	boxSizing: 'border-box',
																	verticalAlign: 'top',
																	color: '#393939',
																}}
															>
																{/*  */}
																<div style={{ boxSizing: "border-box", fontSize: "0px", border: "1px solid #ddd", display: "none" }}>
																	<div style={{ width: "23%", display: "inline-block", textAlign: "left", verticalAlign: "top", fontSize: "11px", boxSizing: " border-box" }}>
																		<div style={{ height: "40px", boxSizing: "border-box", fontWeight: "600", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px" }}>Tax (%)</div>

																		<div style={{ textAlign: "left", verticalAlign: "top", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px", boxSizing: "border-box" }}>12.0</div>

																	</div>
																	<div style={{ width: "35%", display: "inline-block", textAlign: "right", verticalAlign: "top", fontSize: "11px", boxSizing: "border-box" }}>
																		<div style={{ height: "40px", boxSizing: "border-box", fontWeight: "600", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px" }}>Taxable Amt</div>

																		<div style={{ textAlign: "right", verticalAlign: "top", borderBottom: "1px solid #ddd", padding: " 5px 2px 5px 4px", boxSizing: "border-box" }}>1.79</div>

																	</div>
																	<div style={{ width: "23%", display: "inline-block", textAlign: "right", verticalAlign: "top", fontSize: "11px", boxSizing: "border-box" }}>
																		<div style={{ height: "40px", boxSizing: "border-box", fontWeight: "600", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px" }}></div>


																		<div style={{ textAlign: "right", verticalAlign: "top", borderBottom: "1px solid #ddd", padding: "5px 2px 5px 4px", boxSizing: "border-box" }}>0.21</div>
																	</div>
																</div>
																{/*  */}
															</div>

															<div
																style={{
																	width: '60%',
																	display: 'inline-block',
																	boxSizing: 'border-box',
																	verticalAlign: 'top',
																}}
															>
																{recieptType === 'Payment' || recieptType === 'Refund' ? (
																	<div
																		class="estimatedPrice"
																		style={{
																			textAlign: 'right',
																			fontSize: '13px',
																			paddingTop: '6px',
																			color: '#4a4a4a',
																		}}
																	>
																		Total amount (INR) :&nbsp;<span
																			style={{
																				width: '75px',
																				display: 'inline-block',
																				verticalAlign: 'top',
																			}}
																		>
																			{' '}
																			{recieptList.totalAmount}
																		</span>
																	</div>
																) : ""}
																{recieptList.discountAmount > 0 &&
																	<div
																		class="estimatedPrice"
																		style={{
																			textAlign: 'right',
																			fontSize: '13px',
																			paddingTop: '6px',
																			color: '#4a4a4a',
																		}}
																	>

																		Total discount amount (INR) :&nbsp;<span
																			style={{
																				width: '75px',
																				display: 'inline-block',
																				verticalAlign: 'top',
																			}}
																		>
																			{' '}
																			{recieptList.discountAmount}
																		</span>

																	</div>
																}
																<div
																	class="estimatedPrice"
																	style={{
																		textAlign: 'right',
																		fontSize: '13px',
																		paddingTop: '6px',
																		color: '#4a4a4a',
																	}}
																>
																	Amount{' '}
																	{recieptType === 'Refund' ? 'refunded' : 'paid'}{' '}
																	(INR) :&nbsp;<span
																		style={{
																			width: '75px',
																			display: 'inline-block',
																			verticalAlign: 'top',
																		}}
																	>
																		{' '}
																		{recieptList.amount}
																	</span>
																</div>
																{/* {recieptType === 'Payment' && (
																		<div
																			class="estimatedPrice"
																			style={{
																				textAlign: 'right',
																				fontSize: '13px',
																				paddingTop: '6px',
																				color: '#4a4a4a',
																			}}
																		>
																			CGST (INR):&nbsp;<span
																				style={{
																					width: '75px',
																					display: 'inline-block',
																					verticalAlign: 'top',
																				}}
																			>
																				{' '}
																				{recieptList.amount}
																			</span>
																		</div>
																	)} */}

																{recieptType === 'Payment' || recieptType === 'Refund' ? (
																	recieptList.amountFromAdvance > 0 && (
																		<div
																			class="estimatedPrice"
																			style={{
																				textAlign: 'right',
																				fontSize: '13px',
																				paddingTop: '6px',
																				color: '#4a4a4a',
																			}}
																		>
																			Amount from Advance (INR) :&nbsp;<span
																				style={{
																					width: '75px',
																					display: 'inline-block',
																					verticalAlign: 'top',
																				}}
																			>
																				{' '}
																				{recieptList.amountFromAdvance}
																			</span>
																		</div>
																	)
																) : ""}

																<div
																	class="estimatedPrice"
																	style={{
																		textAlign: 'right',
																		fontSize: '13px',
																		paddingTop: '6px',
																		color: '#4a4a4a',
																	}}
																>
																	Mode of payment :&nbsp;<span
																		style={{
																			width: '75px',
																			display: 'inline-block',
																			verticalAlign: 'top',
																		}}
																	>
																		{' '}
																		{recieptList.paymentMode}
																	</span>
																</div>
																{recieptType !== 'Payment' && (
																	<div
																		class="estimatedPrice"
																		style={{
																			textAlign: 'right',
																			fontSize: '13px',
																			paddingTop: '6px',
																			color: '#4a4a4a',
																		}}
																	>
																		{' '}
																		{recieptType !== 'Payment' && (
																			<span>
																				Towards :&nbsp;<span
																					style={{
																						width: '75px',
																						display: 'inline-block',
																						verticalAlign: 'top',
																					}}
																				>
																					{' '}
																					{recieptType === 'Refund' ? (
																						'Refund'
																					) : (
																						'Advance'
																					)}
																				</span>
																			</span>
																		)}
																	</div>
																)}

																{(recieptType === 'Payment' || recieptType === 'Refund') && (
																	dueAmount > 0 && (
																		<div
																			class="estimatedPrice"
																			style={{
																				textAlign: 'right',
																				fontSize: '13px',
																				paddingTop: '6px',
																				color: '#4a4a4a',
																			}}
																		>
																			Total due Amount :&nbsp;<span
																				style={{
																					width: '75px',
																					display: 'inline-block',
																					verticalAlign: 'top',
																				}}
																			>
																				{' '}
																				{dueAmount}
																			</span>
																		</div>
																	)
																)}

																{(recieptType === 'Payment' || recieptType === 'Refund') && (
																	<div
																		class="estimatedPrice"
																		style={{
																			textAlign: 'right',
																			fontSize: '13px',
																			paddingTop: '6px',
																			color: '#4a4a4a',
																		}}
																	>
																		{' '}
																		{/* {recieptType === 'Payment'  && ( */}
																		{advanceAmount > 0 && (
																			<span>
																				Advance amount :&nbsp;<span
																					style={{
																						width: '75px',
																						display: 'inline-block',
																						verticalAlign: 'top',
																					}}
																				>
																					{' '}
																					{advanceAmount}
																				</span>
																			</span>
																		)}
																		{/* )} */}
																	</div>
																)}
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							</tbody>
							<FooterPrint hospitalDataId={hosId} />
						</table>
					</div>
				</div>
				<div class="printBtm" style={{ width: '595px', margin: 'auto' }}>
					<p
						onClick={onPrintButtonClick}
						id="print"
						style={{
							width: '70px',
							textAlign: 'center',
							paddingTop: '5px',
							lineHeight: '18px',
							display: 'inline-block',
							fontWeight: 'bold',
							fontSize: '14px',
							color: 'white',
							background: 'rebeccapurple none repeat scroll 0% 0%',
							marginTop: '2px',
							cursor: 'pointer',
							borderRadius: '5px',
							marginBottom: '2px',
							height: '25px',
							position: 'absolute',
							right: '80px',
							fontFamily: '  Verdana',
						}}
					>
						Print
					</p>&nbsp;
					{/* <p
									id="cancel"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
									}}
									onClick={back}
								>
									Back
								</p> */}
				</div>
			</div>

		</div>
	);
}
export default RecieptPrint;
