import React from 'react';
import BillingHeader from '../billingLogin/layout/header';
import Footer from '../layout/footer';
import { Route } from 'react-router-dom';
import BillingLeft from '../billingLogin/billingLeft';

import LabOrderDetails from '../labLogin/labOrderDetails';
import LabTestBill from '../labLogin/labTestBill';
import ProcedurePayment from '../billingLogin/procedurePayment';
import ProcedureBill from '../billingLogin/procedureBill';
import ReportsPayment from '../Reports/pharmacypayment';

import { BrowserRouter as Router, Routes } from 'react-router-dom';
function Billing() {
	return (
		<div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec hosPharmacy headerHospitalLab headerHospitalharmacy ">
			<BillingHeader />
			<div className="contentWraper contentWraperScroll">
				<BillingLeft />
				<div className="settingsRight" >

					<Route path="/billingLogin/procedurePayment" component={ProcedurePayment} />
					<Route path="/billingLogin/procedureBill" component={ProcedureBill} />
					<Route path="/labLogin/labOrderDetails" component={LabOrderDetails} />
					<Route path="/labLogin/labTestBill" component={LabTestBill} />
					<Route path="/reports/pharmacypayment" component={ReportsPayment} />


				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Billing;
