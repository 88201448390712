import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const VisitorStatics = (props) => {
  const [series] = useState([
    {
      name: 'Total Appointments',
      data: props.totalAppointments.map((obj) => obj.totalAppointments)
    }
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'line',
    },
    forecastDataPoints: {
      count: 7
    },
    stroke: {
      width: 5,
      curve: 'smooth'
    },
    xaxis: {
      categories: props.totalAppointments.map((obj) => obj.appointmentYearAndMonth),
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      },
    },
    yaxis: {
      min: -10,
      max: Math.max(...props.totalAppointments.map((obj) => obj.totalAppointments)) + 10,
    }
  });

  return (
    <div id="chart">
      <ReactApexChart key={props} options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default VisitorStatics;