import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
function TreatmentDetails() {
  const [TreatmentList, setTreatmentListData] = useState([]);
  const downloadName = "TreatmentList";
  const [count, setCount] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [dataFlag, setDataFlag] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const getpatientsList = () => {
    let PostData = {
      "roleName": window.sessionStorage.getItem("role"),
      "userId": window.sessionStorage.getItem("userId"),
      "max": process.env.REACT_APP_PAGINATION_COUNT,
      "offset": pageOffset
    }
    callingAPI("treatmentProcedure/treatmentDetails", PostData).then((response) => {
      if (response.data.success === "1") {
        setTreatmentListData(response.data.result.treatmentDetails);
        setCount(response.data.result.totalCount);
        setDataFlag(true);
      } else { setDataFlag(true); }
    });
  };
  useEffect(() => {
    getpatientsList();
  }, []);
  function patientListDownload() {
    const csvRow = [];
    var patientlist = [
      [
        "Sl%20No",
        "Treatment%20Name",
        "Treatment%20Code",
      ],
    ];
    var name = TreatmentList;
    let PostData = {
      "roleName": window.sessionStorage.getItem("role"),
      "userId": window.sessionStorage.getItem("userId"),
      "max": process.env.REACT_APP_PAGINATION_COUNT,
      "offset": pageOffset
    };
    callingAPI("treatmentProcedure/treatmentDetails", PostData).then((response) => {
      for (var item = 0; item < response.data.result.treatmentDetails.length; item++) {
        patientlist.push([
          item + 1,
          response.data.result.treatmentDetails[item].pmTreatmentName.replace(/ /g, '%20'),
          response.data.result.treatmentDetails[item].pmHospitalTreatmentDetailsProcedureCode.replace(/,/g, '%20').replace(/ /g, '%20'),
        ]);
      }
      for (var i = 0; i < patientlist.length; ++i) {
        csvRow.push(patientlist[i].join(","));
      }
      var csvString = csvRow.join("%0A");
      var creat = document.createElement("a");
      creat.href = "data:attachment/csv," + csvString;
      creat.target = "_Blank";
      creat.download = downloadName + ".csv";
      document.body.appendChild(creat);
      creat.click();
    })
  }
  //pagegination function
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      "roleName": window.sessionStorage.getItem("role"),
      "userId": window.sessionStorage.getItem("userId"),
      "max": process.env.REACT_APP_PAGINATION_COUNT,
      "offset": pageOffset
    };
    callingAPI("treatmentProcedure/treatmentDetails", PostData).then((response) => {
      if (response.data.success === "1") {
        setTreatmentListData(response.data.result.treatmentDetails);
        setCount(response.data.result.totalCount);
      }
    });
  };
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <div className="dashboardTitle">Treatment Details</div>
      {dataFlag == false ? (
        <div align="center">
          <Loader />
        </div>
      ) : (
        <div>
          {dataFlag == true && (
            <div className="dashboardContent">
              {TreatmentList.length !== 0 && (
                <div>
                  {count > 10 && (
                    <div className="paginationSection">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                        onChange={handlePageChange}
                        //hideNavigation
                        hideDisabled
                      />
                    </div>)}
                </div>)}
              <div className="reportWrap">
                <div className="dashboardSearch">
                  <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>

                    {!TreatmentList.length ? (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey"
                          disabled
                        >
                          Export
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          onClick={patientListDownload}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey"
                        >
                          Export
                        </button>
                      </div>
                    )}
                    <div className="col-md-3 dashboardSearchList">
                      <div className="searchCount">Total Treatment Count :&nbsp;{count}</div>
                    </div>
                  </div>
                </div>
                <div className="dashboardtable">
                  <table>
                    <thead>
                      <tr>
                        <th>SI No</th>
                        <th >Name</th>
                        <th>Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TreatmentList.map((list, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{list.pmTreatmentName}</td>
                          <td>{list.pmHospitalTreatmentDetailsProcedureCode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {count > 10 && (
                <div className="paginationSection">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                    totalItemsCount={count}
                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                    onChange={handlePageChange}
                    //hideNavigation
                    hideDisabled
                  />
                </div>
              )}
            </div>
          )}
          {TreatmentList.length == 0 && dataFlag == true && <NoData />}
        </div>
      )}
    </div>
  );
}
export default TreatmentDetails;
