import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Datepicker from 'react-datepicker';
import DrugsRight from '../Patients/drugsRight';
import PatientPriscriptionWrite from '../Patients/patientPriscriptionWrite';
import { callingAPI } from '../config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AWS from 'aws-sdk';
import Loader from '../Common/loader';
import $ from 'jquery';
var s3;
AWS.config.update({
	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
	secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
	region: 'us-east-1',
});
var bucketParams = {
	Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
};
s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
function PatientPriscriptionAdd(props) {
	$(document).ready(function () {
		$('#priscriptionAdd ').addClass("testResultAdd");

	})
	const [imgArr, setImgArr] = useState([]);
	const [imgArr1, setImgArr1] = useState([]);
	const [createInvoiceId, setCreateInvoiceId] = useState([]);
	const [storeId, setStoreId] = useState("")
	const [comment, setComments] = useState([])
	const [writeStoreId, setWriteStoreId] = useState("")
	const [disable, setDisable] = useState(false);
	const [getEdit, setEdit] = useState(false);
	const [radioType, setradioType] = useState(false);
	const [radioWrite, setradioWrite] = useState(false);
	const canvas = React.createRef();
	const prescriptionwrite = React.createRef();
	const [imgFlag, setImgFlag] = useState(false);
	const [imgName, setImgName] = useState(false);
	const [medicineFrequencyDropdown, setmedicineFrequencyDropdown] = useState([]);
	const [medicineIntakeDropdown, setMedicineIntakeDropdown] = useState([]);
	const [selectedMedicineArrListing, setSelectedMedicineArrayListing] = useState([]);
	const [storeAuto, setStoreAuto] = useState([]);
	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [refillCheck, setRefillCheck] = useState('');
	const [selectedCheckbox, setSelectedCheckbox] = useState('Type');
	const [bufferdata, setBufferData] = useState([]);
	const [frequencyinvalid, setAllvalid] = useState(true);
	const [intakevalid, setAllvalid1] = useState(true);
	const [bufferSendData, setBufferSendData] = useState([]);
	const [timesData, setTimesData] = useState([]);
	const [prescriptionImageData, setPrescriptionImageData] = useState([]);
	var [templateClickShow, settemplateClickShow] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [isShowLoader, setIsShowLoader] = useState(0)
	const [isDrawn, setIsDrawn] = useState(false);
	const [getTemplateDetailsArray, setGetTemplateDetailsAray] = useState([
		{
			medicineId: '',
			frequency: '',
			frequencyId: '',
			medicineName: '',
			dosage: ['', '', '', ''],
			intake: '',
			intakeId: '',
			instruction: '',
			quantity: '',
			duration: '',
			durationId: '',
			refill: '',
			startDate: '',
			storeId: '',
			comments: ''
		},
	]);
	const dropdownValuesSettingFunction = (val) => {
		setmedicineFrequencyDropdown(val.frequency);
		var timesArr = [...timesData];
		// var medArray = []
		selectedMedicineArrListing.forEach((valList, index) => {
			val.frequency.forEach((dp, id) => {
				if (dp.id === Number(valList.frequencyId)) {
					valList.timesPerDay = Number(dp.times)
					timesArr[index] = Number(dp.times);
				}
			});

		});

		//	setTimesData(timesArr);
		setMedicineIntakeDropdown(val.intake);
	};
	const setImageBufferData = (data, dataName, canvas) => {
		setBufferData(data);
		setBufferSendData(dataName);
		setPrescriptionImageData(data);
	};
	const setTimesCheckbox = (val, index, arrindex) => {

		const re = /^[0-9.\b]+$/;
		if (val === '' || re.test(val)) {
			const values = [...selectedMedicineArrListing];
			values[arrindex].dosage[index] = val;
			setSelectedMedicineArrayListing(values);
		} else {
			alert('Only Numbers Accepted');
		}

	};
	const selectedDateFunction = (date, i) => {
		setSelectedStartDate(date);
		const values = [...selectedMedicineArrListing];
		values[i].startDate = date;
		setSelectedMedicineArrayListing(values);
	};
	const setStoreClick = (name, id, i) => {
		setStoreId(id)
		setRefillCheck(id)
		const values = [...selectedMedicineArrListing];
		selectedMedicineArrListing.forEach((val, index) => {
			val.storeId = id;
		})
		setSelectedMedicineArrayListing(values);
		document.getElementById('txtStore').value = name;
		document.getElementById('hiddenStoreId').value = id;
		setStoreAuto([]);
		document.getElementById('storeList').style.display = 'none';
	};
	const setMedicineDetails = (e, i) => {
		const re = /^[0-9.\b]+$/;
		const { name, value, checked } = e.target;
		const values = [...selectedMedicineArrListing];

		if (e.target.name === 'refill') {
			values[i] = { ...values[i], [name]: checked };
		} else if (e.target.name == 'frequencyId') {
			var t = document.getElementById('frequencyId' + i);
			var selectedTextForFrequency = t.options[t.selectedIndex].text;
			medicineFrequencyDropdown.forEach((val, id) => {
				if (val.id === Number(e.target.value)) {
					// var timesArr = [...timesData];
					// timesArr[i] = Number(val.times);
					//setTimesData(timesArr);
					values[i].timesPerDay = Number(val.times)
					if (val.times == 1) {
						values[i].dosage = [''];
					}
					else if (val.times == 3) {
						values[i].dosage = ['', '', ''];
					}
					else if (val.times == 4) {
						values[i].dosage = ['', '', '', ''];
					}

				}
			});
			values[i].frequencyId = value;
			values[i].frequency = selectedTextForFrequency;
		} else if (e.target.name == 'intakeId') {
			var t1 = document.getElementById('intakeId' + i);
			var selectedTextForIntake = t1.options[t1.selectedIndex].text;
			values[i].intakeId = e.target.value;
			values[i].intake = selectedTextForIntake;
		} else if (e.target.name == 'quantity' || e.target.name == 'duration') {
			if (re.test(value) || value === '') {
				values[i] = { ...values[i], [name]: value };
			}
			else {
				alert('Only Numbers Accepted');
			}
		} else if (e.target.name == 'storeId') {
			selectedMedicineArrListing.forEach((val, id) => {
				val.storeId = document.getElementById('hiddenStoreId').value = id
			})
		}
		else {
			values[i] = { ...values[i], [name]: value };
		}
		setSelectedMedicineArrayListing(values);
	};

	const saveAndSendOutside = (serviceProviderId) => {
		const postData = {
			siteId: window.sessionStorage.getItem("siteId"),
			serviceProviderId: serviceProviderId
		}
		callingAPI('medicinePrescription/prescribeMedicineMailSent', postData).then((data) => {
			if (data.data.success === '1') {
				// alert("Sms Send")
				window.location.href = '/patients/patientPriscription';
			}
		})
	};

	const createInvoice = (id) => {
		var array1 = []
		array1.push(id)
		if (array1[0].length > 0) {
			var array = array1[0].replace('"').split(',');
		}
		else {
			var array = array1[0]
		}
		window.sessionStorage.setItem('prescriptionid', array);
		window.sessionStorage.setItem('isFlag', "");
		window.location.href = '/patients/patientInvoiceAdd';
		//setCreateInvoiceId(window.sessionStorage.setItem('prescriptionid'))
	}

	const checkingType = (e, type) => {
		if (getEdit === true) {
			savePrescription(e, type)
		}
		else {
			updatePrescription(e, type)
		}
	}
	const savePrescription = async (e, type) => {
		setIsShowLoader(1);
		var array1 = imgArr
		// if(imgFlag === true && type == 'Saveandsend'){
		// 	if ([imgArr1].length > 0 && imgArr1.imageName!=='') {
		// 		array1.push(imgArr1)
		// 		setImgArr(array1)

		// 	}
		// }
		var selected = imgArr.find((object) => object.doctorLanguageId);
		if (selected !== undefined) {
			if (selected.imageName === imgArr1.imageName) {
				setImgName(true)
			}
		}
		if (imgFlag === false || type == 'Saveandsend' && imgName === true) {
			if ([imgArr1].length > 0 && imgArr1.imageName !== '') {
				array1.push(imgArr1)
				setImgArr(array1)

			}
		}
		// setImgArr()
		//setImgArr(imgArr.splice(-1))
		if (selectedCheckbox == 'Write') {

			var flag = true;
			if (isDrawn === true || imgArr != '') {
				flag = true;
				prescriptionwrite.current.canvasdetails().current.clearCanvas();
				prescriptionwrite.current.canvasdetails().current.exportImage('png')
					.then((data) => {
						if (data) {
							var obj = {
								doctorVisitMedicineImageId: '',
								imageName: moment().valueOf() + 'Prescription.png',
								prescriptionImageName: data,
							};
							bufferdata.push([...imgArr, obj]);
							setBufferData(bufferdata);
						}
					})
					.catch();
				let isValidateOk1 = true;
				for (let index = 0; index < imgArr.length; index++) {
					if (imgArr[index].storeId === undefined || imgArr[index].storeId === "" && type == 'Saveandsend') {
						setErrMsg('Please Select Store');
						prescriptionwrite.current.canvasdetails().current.clearCanvas();
						setIsShowLoader(0);
						setImgName(true)
						// let newChildDetail = [...imgArr];
						// newChildDetail.splice(index, 1);
						// setImgArr(newChildDetail);
						setImgFlag(true)
						isValidateOk1 = false;

						return false

					}
				}
				let fileName = [];
				let imageList = [];

				let S3BucketMainFolder =
					process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH;
				for (let i = 0; i < imgArr.length; i++) {
					if (imgArr[i].prescriptionImageName.length > 0) {
						let fullFilePath = S3BucketMainFolder + imgArr[i].imageName;
						const buf = Buffer.from(imgArr[i].prescriptionImageName.replace(/^data:image\/\w+;base64,/, ''), 'base64');
						var a = {
							doctorVisitMedicineImageId: imgArr[i].doctorVisitMedicineImageId,
							imageName: imgArr[i].imageName,
							storeId: imgArr[i].storeId ? imgArr[i].storeId : "",
							comments: ""
						};
						imageList.push(a);
						if (imgArr[i].doctorVisitMedicineImageId == '') {
							const buf = Buffer.from(imgArr[i].prescriptionImageName.replace(/^data:image\/\w+;base64,/, ''), 'base64');
							var a = {
								doctorVisitMedicineImageId: "",
								imageName: imgArr[i].imageName,
								//  storeId: imgArr[i].storeId
							};
							await s3.upload(
								{
									Key: fullFilePath,
									Body: buf,
									ContentEncoding: 'base64',
									ContentType: 'image/png',
								},
								(err, data) => {
									if (err) {
										alert('There was an error uploading your photo: ' + err.message);
									}
									else {
										// alert('File Uploaded Successfully222');
									}
								}
							);
						}
					}
					else {

					}
				}

				if (isValidateOk1 === true) {
					setIsShowLoader(1);

					let postDta = {
						functionName: 'saveAppointmentPrescription',
						// scheduledTimeSlotId: "14157",
						prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
						userId: window.sessionStorage.getItem('memberId'),
						doctorId: window.sessionStorage.getItem('patientDoctorId'),
						hospitalId: localStorage.getItem('hospitalId'),
						type: 'image',
						serviceProviderId: writeStoreId,
						currentDate: moment(new Date()).format('YYYY-MM-DD'),
						currentDateAndTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
						medicineData: [],
						medicineImageData: imageList,
						scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
						scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
					};
					callingAPI('medicinePrescription/saveAppointmentPrescription', postDta).then((data) => {
						// alert('Medicine Saved Successfully');
						if (data.data.success === '1') {
							if (type === "Saveandsend") {
								// alert("save and send")
								alert('Medicine Details Saved and sent successfully');
								// setIsShowLoader(0);
								saveAndSendOutside(data.data.doctorVisitId)
								window.location.href = '/patients/patientPriscription'
								//
							}
							else if (type === "Save") {
								alert('Medicine Saved Successfully');
								// setIsShowLoader(0);
								window.location.href = '/patients/patientPriscription';

							}
							else if (type === "SaveAndPrint") {
								setIsShowLoader(0);
								alert('Medicine Saved Successfully');
								window.sessionStorage.setItem('doctorVisitIdOfPrint', data.data.doctorVisitId);
								window.sessionStorage.setItem('visitDate', moment(new Date()).format('YYYY-MM-DD'));
								window.sessionStorage.setItem('typeOrWrite', "write")
								window.sessionStorage.setItem('drVisitIdOfMedicine', data.data.doctorVisitMedicineImageId);
								// window.open(`${process.env.PUBLIC_URL}/patients/priscriptionPrint`, '_blank');
								window.location.href = '/patients/patientPriscription';
								window.sessionStorage.setItem('fromSaveAndPrint', 1);
								// window.location.href = '/patients/patientPriscription/1';
							}


						} else {
							alert(data.data.errorMessage);
						}
						setDisable(false);
					});
				}
			}
			else {
				setErrMsg('Prescription can not be empty');
				flag = false;
			}
		}
		else {
			setIsShowLoader(0);
			let isValidateOk = true;
			for (let index = 0; index < selectedMedicineArrListing.length; index++) {
				if (selectedMedicineArrListing[index].frequencyId === '' || selectedMedicineArrListing[index].frequencyId === '0' || selectedMedicineArrListing[index].frequencyId === null) {
					setErrMsg('Please Select Frequency & Dosage');
					isValidateOk = false;
					break;
				} else if (selectedMedicineArrListing[index].intakeId === '' || selectedMedicineArrListing[index].intakeId === '0' || selectedMedicineArrListing[index].intakeId === null) {
					setErrMsg('Please Select Intake');
					isValidateOk = false;
					break;
				}
				else if (selectedMedicineArrListing[index].storeId === '' && type == 'Saveandsend') {
					setErrMsg('Please Select Store');
					isValidateOk = false;
					break;
				}
			}

			if (isValidateOk === true) {
				setErrMsg('');
				var finalArrayToSend = JSON.parse(JSON.stringify(selectedMedicineArrListing));
				finalArrayToSend.map((val, index) => {
					val.refill = val.refill == true ? 'yes' : 'no';
					val.storeId = refillCheck
					val.comments = document.getElementById("txtComments").value ? document.getElementById("txtComments").value : ""
					val.startDate = val.startDate
						? moment(val.startDate).format('DD/MM/YYYY')
						: moment(selectedStartDate).format('DD/MM/YYYY');
					let finalDosage = val.dosage.map(
						(dosageCount) => (dosageCount.length > 0 ? dosageCount : dosageCount.replaceAll('', 0))
					);
					val.dosage = finalDosage.toString().replaceAll(',', '-');
					return val;

					// array.push(val.medicineId)
					// if (array[0].length > 0) {
					// 	var array2 = array[0].replace('"').split(',');
					// }
					// else {
					// 	var array2 = array[0]
					// }


				});
				// let array = []
				// 	for(let i=0;i<finalArrayToSend.length;i++){

				// 		array.push(finalArrayToSend[i].medicineId)
				// 	}
				// 	let saveAndPrintId = array.toString()
				setIsShowLoader(1);
				let postDta = {
					functionName: 'saveAppointmentPrescription',
					// scheduledTimeSlotId: "14157",
					prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
					userId: window.sessionStorage.getItem('memberId'),
					doctorId: window.sessionStorage.getItem('patientDoctorId'),
					hospitalId: localStorage.getItem('hospitalId'),
					type: 'write',
					serviceProviderId: storeId,
					currentDate: moment(new Date()).format('YYYY-MM-DD'),
					currentDateAndTime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
					medicineData: finalArrayToSend,
					medicineImageData: [],
					scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
					scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
				};
				callingAPI('medicinePrescription/saveAppointmentPrescription', postDta).then((data) => {
					if (data.data.success === '1') {
						// alert('Medicine Saved Successfully');
						if (type === "Saveandsend") {
							alert('Medicine Details Saved and sent successfully')
							setIsShowLoader(0);
							saveAndSendOutside(data.data.serviceProviderId)
							window.location.href = '/patients/patientPriscription'
							//;
						}
						else if (type === "Save") {
							alert('Medicine Saved Successfully');
							setIsShowLoader(0);
							window.location.href = '/patients/patientPriscription';
						}
						else if (type === "createinvoice") {

							createInvoice(data.data.doctorVisitMedicineId)
							setIsShowLoader(0);
						}
						else if (type === "SaveAndPrint") {
							setIsShowLoader(0);
							window.sessionStorage.setItem('doctorVisitIdOfPrint', data.data.doctorVisitId);
							window.sessionStorage.setItem('typeOrWrite', "type")
							window.sessionStorage.setItem('drVisitIdOfMedicine', data.data.doctorVisitMedicineId);
							// window.open(`${process.env.PUBLIC_URL}/patients/priscriptionPrint`, '_blank');
							window.location.href = '/patients/patientPriscription';
							window.sessionStorage.setItem('fromSaveAndPrint', 1);
							// window.location.href = '/patients/patientPriscription/1';

						}
					} else {
						alert(data.data.errorMessage);
					}
					setDisable(false);
				});
			}
		}
	};


	// updateeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee

	const updatePrescription = async (e, mode) => {
		setIsShowLoader(1);
		var array1 = imgArr
		// if (imgFlag === false) {
		// 	if (imgArr1.length > 0) {
		// 		array1.push(imgArr1)
		// 		setImgArr(array1)
		// 	}
		// }
		var selected = imgArr.find((object) => object.doctorLanguageId);
		if (selected !== undefined) {
			if (selected.imageName === imgArr1.imageName) {
				setImgName(true)
			}
		}
		if (imgFlag === false || mode == 'Saveandsend' && imgName === true) {
			if ([imgArr1].length > 0 && imgArr1.imageName !== '') {
				array1.push(imgArr1)
				setImgArr(array1)

			}
		}
		if (selectedCheckbox == 'Write') {
			setIsShowLoader(0);
			var flag = true;
			if (isDrawn === true || imgArr != '') {
				flag = true;
				prescriptionwrite.current.canvasdetails().current.clearCanvas();
				prescriptionwrite.current
					.canvasdetails()
					.current.exportImage('png')
					.then((data) => {
						if (data) {
							var obj = {
								doctorVisitMedicineImageId: '',
								imageName: moment().valueOf() + 'Prescription.png',
								prescriptionImageName: data,
							};
							bufferdata.push([...imgArr, obj]);
							setBufferData(bufferdata);
						}
					})
					.catch();
				// 			const base64 = baseDrawing;



				let isValidateOk1 = true;
				for (let index = 0; index < imgArr.length; index++) {
					if (imgArr[index].storeId === "" && mode == 'Saveandsend') {
						setErrMsg('Please Select Store');
						prescriptionwrite.current.canvasdetails().current.clearCanvas();
						setImgFlag(true)
						setImgName(true)
						isValidateOk1 = false;

						return false

					}
				}
				let fileName = [];
				let imageList = [];

				let S3BucketMainFolder =
					process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH;
				for (let i = 0; i < imgArr.length; i++) {
					if (imgArr[i].prescriptionImageName.length > 0) {
						let fullFilePath = S3BucketMainFolder + imgArr[i].imageName;
						const buf = Buffer.from(imgArr[i].prescriptionImageName.replace(/^data:image\/\w+;base64,/, ''), 'base64');

						var a = {
							doctorVisitMedicineImageId: imgArr[i].doctorVisitMedicineImageId,
							imageName: imgArr[i].imageName,
							storeId: imgArr[i].storeId ? imgArr[i].storeId : "",
							comments: ""

						};
						imageList.push(a);

						if (imgArr[i].doctorVisitMedicineImageId == '') {
							const buf = Buffer.from(imgArr[i].prescriptionImageName.replace(/^data:image\/\w+;base64,/, ''), 'base64');
							var a = {
								doctorVisitMedicineImageId: "",
								imageName: imgArr[i].imageName

							};
							// imageList.push(a);
							await s3.upload(
								{
									Key: fullFilePath,
									Body: buf,
									ContentEncoding: 'base64',
									ContentType: 'image/png',
								},
								(err, data) => {
									if (err) {
										alert('There was an error uploading your photo: ' + err.message);
									}
									else {
									}
								}
							);
						}
					}
					else {

					}
				}
				if (isValidateOk1 === true) {
					setIsShowLoader(0);
					let postDta = {
						functionName: "updatePrescription",
						// scheduledTimeSlotId: "14157",
						doctorVisitId: props && props.location && props.location.state && props.location.state.data
							? props.location.state.data
							: '',
						memberId: window.sessionStorage.getItem('memberId'),
						serviceProviderId: writeStoreId,
						doctorId: window.sessionStorage.getItem('patientDoctorId'),
						hospitalId: localStorage.getItem('hospitalId'),
						type: 'image',
						medicineData: [],
						medicineImageData: imageList,
					};
					callingAPI('medicinePrescription/updatePrescription', postDta).then((data) => {
						if (data.data.success === '1') {
							if (mode === "Saveandsend") {
								alert('Medicine Details Saved and sent successfully');
								// setIsShowLoader(0);
								saveAndSendOutside(data.data.serviceProviderId)
								window.location.href = '/patients/patientPriscription'
								//
							}
							else if (mode === "Update") {
								alert('Medicine Updated Successfully');
								// setIsShowLoader(0);
								window.location.href = '/patients/patientPriscription';
							}
							else if (mode === "createinvoice") {
								//  alert("create invoice")
								// setIsShowLoader(0);
								createInvoice(data.data.doctorVisitMedicineId)
							}
							else if (mode === "SaveAndPrint") {
								// setIsShowLoader(0);
								alert('Medicine Updated Successfully');
								window.sessionStorage.setItem('doctorVisitIdOfPrint', data.data.doctorVisitId);
								window.sessionStorage.setItem('visitDate', moment(new Date()).format('YYYY-MM-DD'));
								window.sessionStorage.setItem('typeOrWrite', "write")
								window.sessionStorage.setItem('drVisitIdOfMedicine', data.data.doctorVisitMedicineImageId);
								// window.open(`${process.env.PUBLIC_URL}/patients/priscriptionPrint`, '_blank');
								window.location.href = '/patients/patientPriscription';
								window.sessionStorage.setItem('fromSaveAndPrint', 1);
								// window.location.href = '/patients/patientPriscription/1';
							}
						} else {
							alert(data.data.errorMessage);
						}
						setDisable(false);
					});
				}

			}
			else {
				setErrMsg('Prescription can not be empty');
				flag = false;
			}
		}
		else {
			setIsShowLoader(0);
			let isValidateOk = true;
			for (let index = 0; index < selectedMedicineArrListing.length; index++) {
				if (
					selectedMedicineArrListing[index].frequencyId === '' || selectedMedicineArrListing[index].frequencyId === '0' || selectedMedicineArrListing[index].frequencyId === null
				) {
					setErrMsg('Please Select Frequency & Dosage');
					isValidateOk = false;
					break;
				} else if (
					selectedMedicineArrListing[index].intakeId === '' || selectedMedicineArrListing[index].intakeId === '0' || selectedMedicineArrListing[index].intakeId === null) {
					setErrMsg('Please Select Intake');
					isValidateOk = false;
					break;
				}
				else if (selectedMedicineArrListing[index].storeId === '' && mode === 'Saveandsend') {
					setErrMsg('Please Select Store');
					isValidateOk = false;
					break;
				}
			}
			if (isValidateOk === true) {
				setErrMsg('');
				var finalArrayToSend = JSON.parse(JSON.stringify(selectedMedicineArrListing));
				finalArrayToSend.map((val, index) => {
					val.refill = val.refill == true ? 'yes' : 'no';
					val.storeId = refillCheck
					val.comments = document.getElementById("txtComments").value ? document.getElementById("txtComments").value : ""
					val.startDate = val.startDate
						? moment(val.startDate).format('DD/MM/YYYY')
						: moment(selectedStartDate).format('DD/MM/YYYY');
					let finalDosage = val.dosage.map(
						(dosageCount) => (dosageCount.length > 0 ? dosageCount : dosageCount.replaceAll('', 0))
					);
					val.dosage = finalDosage.toString().replaceAll(',', '-');
					return val;
				});
				setIsShowLoader(1);
				let postDta = {
					functionName: "updatePrescription",
					// scheduledTimeSlotId: "14157",
					doctorVisitId: props && props.location && props.location.state && props.location.state.data
						? props.location.state.data
						: '',
					memberId: window.sessionStorage.getItem('memberId'),
					serviceProviderId: storeId,
					doctorId: window.sessionStorage.getItem('patientDoctorId'),
					hospitalId: localStorage.getItem('hospitalId'),
					type: 'write',
					medicineData: finalArrayToSend,
					medicineImageData: [],
				};
				callingAPI('medicinePrescription/updatePrescription', postDta).then((data) => {
					if (data.data.success === '1') {
						if (mode === "Saveandsend") {
							alert('Medicine Details Saved and sent successfully');
							setIsShowLoader(0);
							saveAndSendOutside(data.data.serviceProviderId)
							window.location.href = '/patients/patientPriscription';

						}
						else if (mode === "Update") {
							alert('Medicine Updated Successfully');
							setIsShowLoader(0);
							window.location.href = '/patients/patientPriscription';
						}
						else if (mode === "createinvoice") {
							//  alert("create invoice")
							setIsShowLoader(0);
							createInvoice(data.data.doctorVisitMedicineId)
						}
						else if (mode === "SaveAndPrint") {
							setIsShowLoader(0);
							window.sessionStorage.setItem('doctorVisitIdOfPrint', data.data.doctorVisitId);
							window.sessionStorage.setItem('visitDate', moment(new Date()).format('YYYY-MM-DD'));
							window.sessionStorage.setItem('typeOrWrite', "type")
							window.sessionStorage.setItem('drVisitIdOfMedicine', data.data.doctorVisitMedicineId);
							window.location.href = '/patients/patientPriscription';
							window.sessionStorage.setItem('fromSaveAndPrint', 1);
							// window.open(`${process.env.PUBLIC_URL}/patients/priscriptionPrint`, '_blank');
							// window.location.href = '/patients/patientPriscription/1';
						}
					} else {
						alert(data.data.errorMessage);
					}
					setDisable(false);
				});
			}
		}
	};

	const settingMedicineTemplateFunction = (groupCodeId, divshow) => {
		settemplateClickShow(true);
		let postData = {
			functionName: 'medicineTemplateDetails',
			templateId: groupCodeId,
		};
		callingAPI('medicinePrescription/medicineTemplateDetails', postData).then((data) => {
			if (data.data.success === '1') {
				var copyArr = [...selectedMedicineArrListing];
				let timeArrayList = []
				data.data.medicineTemplateData.map((temp, i) => {
					copyArr.push(temp)
					temp.storeId = ""
					temp.durationId = ""
					temp.startDate = moment(selectedStartDate).format('DD-MMM-YYYY');
					temp.dosage = temp.dosage1.split('-');
					medicineFrequencyDropdown.forEach((val, id) => {
						if (val.id === Number(temp.frequencyId)) {
							// copyArr[i].timesPerDay = Number(val.times)
							// if (val.times == 1) {
							// 	copyArr[i].dosage = [copyArr[i].dosage];
							// }
							// else if (val.times == 3) {
							// 	copyArr[i].dosage = [copyArr[i].dosage[0],copyArr[i].dosage[1],copyArr[i].dosage[2]];
							// }
							// else if (val.times == 4) {
							// 	copyArr[i].dosage = [copyArr[i].dosage[0],copyArr[i].dosage[1],copyArr[i].dosage[2],copyArr[i].dosage[3]];
							// }
							timeArrayList[i] = Number(val.times)
							var timesArr = [...timesData];
							timesArr[i] = Number(val.times);
							temp.timesPerDay = Number(val.times)
							setTimesData(timesArr);
						}
					});
					return temp;
				});


				//copyArr.push(data.data.medicineTemplateData);
				setSelectedMedicineArrayListing([...copyArr]);
			}
		});
	};
	const isDrawnFunction = (checkval) => {
		setIsDrawn(checkval)
	}
	const settingMedicalNameFunction = (obj, divshow) => {
		var copymedicineArr = [...selectedMedicineArrListing];
		var appendArr = {
			doctorVisitMedicineId: '',
			pmHospitalMedicineId: obj.pmHospitalMedicineId,
			medicineName: obj.medicineName,
			medicineId: obj.medicineId,
			frequency: '',
			frequencyId: '',
			dosage: ['', '', '', ''],
			intake: '',
			intakeId: '',
			instruction: '',
			quantity: '',
			duration: '',
			durationId: '',
			startDate: moment().format('DD-MMM-YYYY'),
			refill: '',
			storeId: '',

		};
		copymedicineArr.push(appendArr);
		// appendArr.push(a)
		setSelectedMedicineArrayListing(copymedicineArr);
	};
	const removePrescription = (i) => {
		let removed = [...selectedMedicineArrListing];
		if (removed[i].doctorVisitMedicineId) {
			var x = false;
			x = window.confirm('Do you want to remove the medicine?');
			if (x === true) {
				const postData = {
					userId: window.sessionStorage.getItem('userId'),
					doctorVisitMedicineId: removed[i].doctorVisitMedicineId

				};
				callingAPI('medicinePrescription/deactivatePrescriptionDetails', postData)
					.then((data) => {
						if (data.data.success === '1') {
							removed.splice(i, 1);
							setSelectedMedicineArrayListing(removed);
						}
					})
					.catch((err) => { });
			}
		} else {
			removed.splice(i, 1);
			setSelectedMedicineArrayListing(removed);
		}
	};
	const onChangeValue = (e) => {
		setSelectedCheckbox(e.target.value);
	};
	const getStoreNameAutoComplete = (mode, dropdownId) => {
		const postdata = {
			keyWord: document.getElementById(mode).value,
		};
		callingAPI('medicinePrescription/getStoreName', postdata).then((res) => {
			if (res.data.success === '1') {
				document.getElementById(dropdownId).style.display = 'block';
				setStoreAuto(res.data.data);
				document.addEventListener('mouseup', function (e) {
					var container = document.getElementById(dropdownId);
					if (container !== null) {
						if (!container.contains(e.target)) {
							container.style.display = 'none';
						}
					}
				});
				if (res.data.data.length == 0) {
					// alert('No Supplier Found.Please add Supplier');
					document.getElementById(dropdownId).style.display = 'none';
					setStoreAuto([]);
				}

			}
			else {
				document.getElementById(dropdownId).style.display = 'none';
				setStoreAuto([]);
			}

		})
			.catch();
	};

	const getPatientPrescriptionListDetails = () => {
		setEdit(true)
		if (props && props.location && props.location.state && props.location.state.data) {
			setImgFlag(true)
			setEdit(false)
			const apiJson = {
				functionName: 'prescriptionList',
				scheduledTimeSlotId: '',
				memberId: window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				//  visitId: "9890"
				visitId:
					props && props.location && props.location.state && props.location.state.data
						? props.location.state.data
						: '',
			};
			callingAPI('medicinePrescription/prescriptionList', apiJson).then((data) => {
				if (data.data.success === '1') {
					if (data.data.result.prescriptionMedicineData.length && props.location.state.type === 'typing') {
						setradioType(true)
						// setComments(data.data.result.prescriptionMedicineData[0].prescriptionData[0].prescriptionDetails.comments)
						var copy = [...selectedMedicineArrListing];
						let timeArrayList = []
						data.data.result.prescriptionMedicineData[0].prescriptionData[0].prescriptionDetails.map((temp, index) => {
							copy.push(temp)
							temp.dosage = temp.dosage.split('-');
							timeArrayList.push(temp.comments)
							for (let i = 0; i < 13; i++) {
								if (i === Number(temp.frequencyId)) {
									if (i == 1 || i == 12 || i == 5 || i == 6 || i == 7 || i == 9 || i == 10 || i == 11) {
										temp.timesPerDay = 1
									}
									else if (i == 2 || i == 3 || i == 8 || i == 12) {
										temp.timesPerDay = 3
									}
									else if (i == 4) {
										temp.timesPerDay = 4
									}
								}
							}
							// temp.timesPerDay = temp.fr
							temp.refill = temp.refill == 'no' ? false : true;
							return temp;
						});
						// setComments([...timeArrayList])
						setComments(timeArrayList[0])
						setSelectedMedicineArrayListing([...copy]);
					} else if (data.data.result.prescriptionImageData.length && props.location.state.type === 'write') {
						setradioWrite(true)
						// document.getElementById("witeImage").style={cursor:"no-drop", pointerEvents:"none"}
						let imageListArr = [];
						let dataImg = data.data.result.prescriptionImageData ? data.data.result.prescriptionImageData[0].prescriptionDetails : '';
						let dataImgId = data.data.result.prescriptionImageData ? data.data.result.prescriptionImageData[0].prescriptionDetails : '';
						let imgPath =
							process.env.REACT_APP_S3_BUCKET_URL +
							process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
							process.env.REACT_APP_S3_PRESCRIPTION_IMAGE_UPLOAD_PATH;
						for (let i = 0; i < dataImg.length; i++) {
							imageListArr.push({
								doctorVisitMedicineImageId: dataImg[i].doctorVisitMedicineImageId,
								imageName: dataImg[i].prescriptionImageName,
								prescriptionImageName: imgPath + dataImg[i].prescriptionImageName,
								storeId: dataImg[i].storeId
							});
						}
						setImgArr(imageListArr);
						setImgFlag(false)
						// setImgArr(imgArr1)
						setPrescriptionImageData(data.data.result.prescriptionImageData[0].prescriptionDetails);
						// setImgArr(data.data.result.prescriptionImageData[0].prescriptionDetails);
					}
				}
			});
		}
		else {
			setradioType(true)
			setradioWrite(true)
			setImgFlag(false)
		}
		if (props && props.location && props.location.state && props.location.state.type === 'write') {
			setSelectedCheckbox('Write');
		}
		else if (props && props.location && props.location.state && props.location.state.type === 'typing') {
			setSelectedCheckbox('Type');
		}
	};

	useEffect(() => {
		getPatientPrescriptionListDetails();
	}, []);
	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment ptpri ptadd">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					{isShowLoader === 1 ? <Loader /> :
						<div className="practiceManagementWrap">
							<div className="row practiceManagementRow">
								<div className="col-lg-8 col-md-12 practiceLeftSection">
									<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
										<div id="divPatientContent" class="sideMenuContent">
											<div id="prescriptionDivAdd">
												<div class="treatmentWrap " id="treatmentPlanSection">
													<div class="treatmentSelect d-flex flex-wrap">
														<span style={{ float: 'left' }}>PRESCRIPTION</span>
														<div
															class="radioAreaType prescriptionradioBtn_PTour"
															id="radioareaid"

														// onChange={onChangeValue}
														>

															<input
																type="radio"
																id="witeImage"
																value="Type"
																checked={selectedCheckbox == 'Type'}
																name="format"
																onClick={(e) => { setSelectedCheckbox('Type'); }}
																style={{ pointerEvents: radioType == false ? "none" : "" }}
															/>{' '}
															Type &nbsp;
															<input

																type="radio"
																id="typeImage"
																value="Write"
																checked={selectedCheckbox == 'Write'}
																name="format"
																onClick={(e) => { setSelectedCheckbox('Write'); }}
																style={{ pointerEvents: radioWrite == false ? "none" : "" }}
															/>{' '}
															Write
														</div>
														<div class="treatmentSelectBtnsType d-flex align-items-center ms-auto">
															<Link to="/patients/patientPriscription">
																<input
																	type="button"
																	value="Cancel"
																	id="btnCancelPreType"
																	class="clinicalCancel precriptionCancel_PTour"
																/>
															</Link>
															{selectedCheckbox === 'Type' &&
																selectedMedicineArrListing.length ? (
																<React.Fragment>
																	<span
																		type="submit"
																		name="_action_savePrescription"
																		id="saveid"
																		style={{

																			display: getEdit === true ? 'block' : 'none'
																		}}
																		value="Save"
																		onClick={(e) => {
																			savePrescription(e, 'Save');
																		}}
																		class="clinicalSave precriptionSaveBtn_PTour"
																	><a>Save</a></span>
																	<span
																		type="submit"
																		name="_action_savePrescription"
																		id="updateid"
																		style={{
																			display: getEdit === false ? 'block' : 'none'
																		}}
																		value="Update"
																		onClick={(e) => { updatePrescription(e, 'Update'); }}
																		class="clinicalSave precriptionSaveBtn_PTour"
																	><a>Update</a></span>

																	<input
																		type="submit"
																		name="_action_savePrescription"
																		onClick={(e) => {
																			checkingType(e, 'Saveandsend');
																		}}
																		value="Save &amp; Send  Outside"
																		class="clinicalSave precriptionSaveSendBtn_PTour"
																	/>
																	<input
																		type="submit"
																		name="_action_savePrescription"
																		onClick={(e) => {
																			checkingType(e, 'SaveAndPrint');
																		}}
																		value="Save &amp; Print"
																		index="18"
																		class="clinicalSave precriptionSaveAndPrintBtn_PTour"
																	/>
																	<span className="treatmentPlanBtn pl_blue_btn  clinicalSave  " id="createinvoice" onClick={(e) => {
																		checkingType(e, 'createinvoice');
																	}} index="18" name="_action_savePrescription" >
																		<Link style={{ display: 'block' }}
																			id={'treatmentCompleteTick'}
																			to={{
																				pathname: '/patients/patientInvoiceAdd'
																				, state: { prescriptionIds: createInvoiceId, hospitalId: localStorage.getItem('hospitalId'), memberId: window.sessionStorage.getItem('memberId'), isFlag: "" },
																			}}>
																		</Link>

																		Create Invoice

																	</span>
																</React.Fragment>
															) : null}
															{selectedCheckbox === 'Write' ? (
																<React.Fragment>
																	<span
																		type="submit"
																		name="_action_savePrescription"
																		id="saveid"
																		style={{

																			display: getEdit === true ? 'block' : 'none'
																		}}
																		value="Save"
																		onClick={(e) => {
																			savePrescription(e, 'Save');
																		}}
																		class="clinicalSave precriptionSaveBtn_PTour"
																	><a>Save</a></span>
																	<span
																		type="submit"
																		name="_action_savePrescription"
																		id="updateid"
																		style={{
																			display: getEdit === false ? 'block' : 'none'
																		}}
																		value="Update"
																		onClick={(e) => {
																			updatePrescription(e, 'Update');
																		}}
																		class="clinicalSave precriptionSaveBtn_PTour"
																	><a>Update</a></span>

																	<input
																		type="submit"
																		name="_action_savePrescription"
																		onClick={(e) => {
																			checkingType(e, 'Saveandsend');
																		}}
																		value="Save &amp; Send  Outside"
																		class="clinicalSave precriptionSaveSendBtn_PTour"
																	/>
																	<input
																		type="submit"
																		name="_action_savePrescription"
																		onClick={(e) => {
																			checkingType(e, 'SaveAndPrint');
																		}}
																		value="Save &amp; Print"
																		index="18"
																		class="clinicalSave precriptionSaveAndPrintBtn_PTour"
																	/>
																</React.Fragment>
															) : null}
														</div>
														<div class="clearfix" />
														<div style={{
															color: 'red',
															display: 'flex',
															justifyContent: 'center', width: "100%", fontSize: "13px"
														}}
														>
															{errMsg}
														</div>
													</div>

													<div class="prescriptionContent prescriptionContentAdd precriptionAddedDrugsgroups_PTour">
														<div class="practiceManagementForm1">
															<div class="practiceManagementFormBlcok" id="typePreId">
																<div id="divJsFiles" />

																<div
																	id="treatmentPlanSection"
																	class="prescriptionAddedMedcne_PTour"
																>
																	<div class="treatmentsList precscriptionTrtmntList">
																		<div
																			class="practiceManagementForm"
																			style={{
																				padding: ' 0px',
																				position: 'relative',
																			}}
																		>
																			<div
																				class="practiceManagementFormBlcok"
																				style={{ margin: '0px' }}
																			>
																				<div class="col-md-12 col-lg-12 form_registrationPatient">
																					{/*  ====================================== */}
																					<div
																						class="treatmentDivSecWrap prescriptionAddDiv_PTour"
																						id="prescriptionNoDataDiv"
																						style={{
																							display:
																								selectedCheckbox ==
																									'Write' ||
																									selectedMedicineArrListing.length
																									? 'none'
																									: '',
																						}}
																					>
																						<span
																							class="treatmentCommonTxt"
																							id="treatmentCommonTxt"
																						>
																							<span>
																								Add a medicine from the
																								right. Multiple medicine(s)
																								can be added
																							</span>
																						</span>
																					</div>
																					{selectedCheckbox === 'Type' ? (
																						<div
																							id="prescriptionDivMain"
																							class="prescriptionFreqMedcne_PTour"
																							style={{
																								display:
																									selectedCheckbox ==
																										'Type'
																										? ''
																										: 'none',
																							}}
																						>
																							<p
																								style={{
																									display: frequencyinvalid
																										? 'none'
																										: '',
																								}}
																							>
																								{frequencyinvalid &&
																									!intakevalid ? (
																									''
																								) : (
																									'Selcect Frquncy'
																								)}
																							</p>
																							{frequencyinvalid &&
																								intakevalid ? (
																								''
																							) : (
																								'Select Intake id'
																							)}
																							{selectedMedicineArrListing.map((object, i) => (

																								<tbody
																									style={{ width: '100%', display: 'block' }}
																									className={object.status === 'Invoice Generated' || object.status === 'Completed' ? (
																										'treatmentSectionDisable'
																									) : (
																										''
																									)
																									}
																								>

																									{/* <div
																									class="prescriptionTrtmntListItem"
																									id="prescriptionTrtmntListItem0"
																								> */}


																									<span
																										className="close_row"
																										onClick={(e) =>
																											removePrescription(
																												i
																											)}
																									>
																										x
																									</span>
																									<ul className="row">
																										<li class="col-md-3 col-lg-3">
																											<label
																												id="lblMedicineName0"
																												class="lblMedicineName elipsis"
																												title=" CINCLOX-S inj  vial"
																											>
																												{' '}
																												<b>

																													{
																														object.medicineName
																													}
																												</b>
																											</label>
																										</li>

																										<li class="col-md-3 col-lg-3">
																											<label>
																												Frequency
																												&amp;
																												Dosage <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup>
																											</label>
																											<div class="form-group">
																												<select
																													class="fulWidthSelect"
																													tabindex="2"
																													name="frequencyId"
																													id={
																														'frequencyId' +
																														i
																													}
																													value={
																														object.frequencyId
																													}
																													onChange={(
																														e
																													) =>
																														setMedicineDetails(
																															e,
																															i
																														)}
																													style={{ background: "#ececec", border: "none" }}
																												>
																													<option value="0">
																														Select
																													</option>
																													{medicineFrequencyDropdown.map(
																														(
																															val,
																															id
																														) => (
																															<option
																																value={
																																	val.id
																																}
																															>
																																{
																																	val.name
																																}
																															</option>
																														)
																													)}
																												</select>
																											</div>
																										</li>
																										{/*  */}
																										<li
																											class="col-md-3 col-lg-3 liFrequency"
																											id="liFrequencyOnce0"
																										>
																											<label>
																												&nbsp;
																											</label>
																											<input
																												type="text"
																												id="dosage"
																												name="dosage"
																												placeholder="0"
																												tabindex="3"
																												maxlength="6"
																												value={
																													object
																														.dosage[0]
																													// object.dosage.substring(0,1)
																												}
																												style={{
																													display:
																														(object.timesPerDay >= 1)
																															? ''
																															: 'none',
																												}}
																												onChange={(
																													e
																												) =>
																													setTimesCheckbox(
																														e
																															.target
																															.value,
																														'0',
																														i
																													)}
																											// onChange={(
																											// 	e
																											// ) =>
																											// 	setMedicineDetails(
																											// 		e,
																											// 		i
																											// 	)}
																											/>
																											<input
																												type="text"
																												id="dosage1"
																												name="txtNoon0"
																												placeholder="0"
																												tabindex="4"
																												maxlength="6"
																												value={
																													// object.dosage.substring(3,4)
																													object
																														.dosage[1]
																												}
																												style={{
																													display:
																														(object.timesPerDay >= 2)
																															? ''
																															: 'none',
																												}}
																												onChange={(
																													e
																												) =>
																													setTimesCheckbox(
																														e
																															.target
																															.value,
																														'1',
																														i
																													)}
																											/>
																											<input
																												type="text"
																												id="txtNight0"
																												name="txtNight0"
																												placeholder="0"
																												tabindex="5"
																												maxlength="6"
																												value={
																													object
																														.dosage[2]
																												}
																												style={{
																													display:
																														(object.timesPerDay >= 3)
																															? ''
																															: 'none',
																												}}
																												onChange={(
																													e
																												) =>
																													setTimesCheckbox(
																														e
																															.target
																															.value,
																														'2',
																														i
																													)}
																											/>
																											<input
																												type="text"
																												id="txtNight0"
																												name="txtNight0"
																												placeholder="0"
																												tabindex="5"
																												maxlength="6"
																												value={
																													object
																														.dosage[3]
																												}
																												style={{
																													display:
																														(object.timesPerDay >= 4)
																															? ''
																															: 'none',
																												}}
																												onChange={(
																													e
																												) =>
																													setTimesCheckbox(
																														e
																															.target
																															.value,
																														'3',
																														i
																													)}
																											/>
																										</li>
																										{/*  */}

																										<li class="col-md-3 col-lg-3">
																											<label >Intake <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></label>
																											<div class="form-group">
																												<select
																													class="fulWidthSelect"
																													data-width="100%"
																													id={
																														'intakeId' +
																														i
																													}
																													name="intakeId"
																													tabindex="6"
																													value={
																														object.intakeId
																													}
																													onChange={(
																														e
																													) =>
																														setMedicineDetails(
																															e,
																															i
																														)}

																													style={{ background: "#ececec", border: "none" }}
																												>
																													<option
																														selected=""
																														value="0"
																													>
																														Select
																													</option>
																													{medicineIntakeDropdown.map(
																														(
																															val,
																															id
																														) => (
																															<option
																																value={
																																	val.id
																																}
																															>
																																{
																																	val.name
																																}
																															</option>
																														)
																													)}
																												</select>
																											</div>
																										</li>
																										<li class="col-md-3 col-lg-3">
																											<label>
																												Instructions
																											</label>
																											<input
																												type="text"
																												id="instruction"
																												name="instruction"
																												placeholder="Instructions"
																												tabindex="7"
																												autocomplete="off"
																												class="txtInstructions"
																												value={
																													object.instruction
																												}
																												onChange={(
																													e
																												) =>
																													setMedicineDetails(
																														e,
																														i
																													)}

																											// onChange={(e) => setInstruction(e.target.value)}
																											/>
																										</li>
																										<li class="col-md-2 col-lg-2">
																											<label>
																												Quantity
																											</label>
																											<input
																												type="text"
																												id="quantity"
																												name="quantity"
																												placeholder="Qty"
																												tabindex="8"
																												maxlength="8"
																												value={
																													object.quantity
																												}
																												onChange={(
																													e
																												) =>
																													setMedicineDetails(
																														e,
																														i
																													)}

																											// onChange={(e) => setQuantity(e.target.value)}
																											/>
																										</li>
																										<li class="col-md-2 col-lg-2">
																											<label>
																												Days
																											</label>
																											<input
																												type="text"
																												id="duration"
																												name="duration"
																												placeholder="Days"
																												tabindex="9"
																												maxlength="9"
																												value={
																													object.duration
																												}
																												// onChange={(e) => setDays(e.target.value)}
																												onChange={(
																													e
																												) =>
																													setMedicineDetails(
																														e,
																														i
																													)}
																											/>
																										</li>
																										<li class="col-md-3 col-lg-3">
																											<label>
																												Start
																												Date
																											</label>
																											<Datepicker
																												required
																												id="startdate"
																												name="startdate"
																												className="Datepicker pa2 inputField"
																												// placeholderText="15/11/2021"
																												calendarClassName="rasta-stripes"
																												dateFormat="dd-MM-yyyy"
																												// value={}
																												selected={
																													object.startDate ? (
																														new Date(
																															object.startDate
																														)
																													) : (
																														new Date()
																													)
																												}
																												peekNextMonth
																												showMonthDropdown
																												showYearDropdown
																												minDate={
																													new Date()
																												}
																												dropdownMode="select"
																												onChange={(
																													date
																												) =>
																													selectedDateFunction(
																														date,
																														i
																													)}
																											/>
																										</li>
																										<li class="col-md-1 col-lg-1">
																											<label>
																												Refill
																											</label>
																											<input
																												type="checkbox"
																												id={
																													'refill' +
																													i
																												}
																												tabindex="11"
																												onChange={(
																													e
																												) =>
																													setMedicineDetails(
																														e,
																														i
																													)}
																												name={
																													'refill'
																												}
																												checked={
																													object.refill
																												}
																												placeholder="Patient Name"
																												style={{
																													width:
																														'15px',
																													marginTop:
																														'10px',
																												}}
																											/>
																										</li>

																										<div class="clearfix" />
																									</ul>
																								</tbody>

																							)
																							)}
																							{selectedMedicineArrListing.length >
																								0 ? (
																								<div
																									class="precsriptionStore"
																									id="medicineCommonSection"
																								>
																									<ul>
																										<li class="col-md-12 col-lg-12 PaddingGridFix12">
																											<label>
																												Select Store
																											</label>
																											<input
																												type="text"
																												id="txtStore"
																												name="txtStore"
																												class="prescriptionSelectStore_PTour ui-autocomplete-input"
																												data-position="top"
																												tabindex="13"
																												placeholder="Select Store"
																												autocomplete="off"
																												onKeyUp={() =>
																													getStoreNameAutoComplete("txtStore", "storeList")}
																												onClick={() =>
																													getStoreNameAutoComplete("txtStore", "storeList")}
																											/>
																											<ul
																												className="autoSearch autoSearch-p0"
																												id="storeList"
																												style={{
																													display:
																														'none',
																												}}
																											>
																												{storeAuto.map(
																													(
																														item,
																														i
																													) => (
																														<li
																															onClick={() =>
																																setStoreClick(
																																	item.StoreName,
																																	item.ServiceProvId, i
																																)}
																														>
																															{
																																item.StoreName
																															}
																														</li>
																													)
																												)}
																											</ul>
																											<input
																												type="hidden"
																												id="hiddenStoreId"
																											/>
																										</li>
																										<li class="col-md-12 col-lg-12 PaddingGridFix12 prescriptionComments_PTour">
																											<label>
																												Comments
																											</label>
																											<textarea
																												value={comment}
																												id="txtComments"
																												name="txtComments"
																												tabindex="14"
																												placeholder="Comments"
																												maxlength="5000"
																												className="textareaField"
																												onChange={(e) => setComments()}
																											/>
																										</li>
																									</ul>
																									<div class="treatmentComplaintRowWrap treatmentPrescriptionRowWrap" />
																								</div>
																							) : null}
																						</div>
																					) : (
																						<div
																							id="prescriptionDivMain"
																							class="prescriptionFreqMedcne_PTour"
																						>
																							<PatientPriscriptionWrite
																								setWriteStoreId={setWriteStoreId}
																								imgArr={imgArr}
																								imgArr1={imgArr1}

																								isDrawnFunction={isDrawnFunction}
																								setImgArr={setImgArr}
																								setImgArr1={setImgArr1}
																								settingImageDate={
																									setImageBufferData
																								}
																								prescriptionImageData={
																									prescriptionImageData
																								}
																								ref={prescriptionwrite}
																								buffer
																							/>
																						</div>
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/*  */}
								{selectedCheckbox !== 'Write' ? (
									<div className="col-md-4 practiceRightSection">
										<DrugsRight
											parentCallback={dropdownValuesSettingFunction}
											setingMedicalName={settingMedicalNameFunction}
											settingMedicalTemplate={settingMedicineTemplateFunction}
											getTemplateDetails={getTemplateDetailsArray}

										/>
									</div>
								) : null}
							</div>
						</div>}
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default PatientPriscriptionAdd;
