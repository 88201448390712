import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useState } from 'react';
import PharmacyLeft from '../pharmacyLogin/pharmacyLeft';
function Settingleft() {
	const [toggle, setToggle] = useState(false);
	const [togglePhrmcy, setTogglePhrmcy] = useState(false);
	const [toggleLab, setToggleLab] = useState(false);
	const showlalert = () => {
		window.sessionStorage.setItem('pageNumber', 1)
		window.sessionStorage.setItem("activepagenew", 0)
		window.location.href = '/settings/treatmentProcedure'
	}
	const paginationOfPharmacyManufacture = () => {
		window.sessionStorage.setItem('PharmacyManufacturepageNumber', 1)
		window.sessionStorage.setItem("manufactureListDownloadactivepagenew", 0)
	}
	const paginationOfPharmacySupplieer = () => {
		window.sessionStorage.setItem('PharmacysupplierpageNumber', 1)
		window.sessionStorage.setItem("SupplierListDownloadactivepagenew", 0)
	}
	const ConsentForm = () => {
		window.sessionStorage.setItem("hospitalConsentFormDescription", "")
		window.sessionStorage.setItem("concentFormType", "")
		window.sessionStorage.setItem("descriptionLocalLanguage", "")
	}
	const taxDetailsSection = () => {
		window.sessionStorage.setItem("pageNumberTaxDetails", 1)
		window.sessionStorage.setItem("offsetTaxDetails", 0)
	}
	const expenceDetailsSection = () => {
		window.sessionStorage.setItem("pageNumberExpenseDetails", 1)
		window.sessionStorage.setItem("offsetExpenseDetails", 0)
	}
	const inventoryDetailsSection = () => {
		window.sessionStorage.setItem("pageNumberInventoryDetails", 1)
		window.sessionStorage.setItem("offsetInventoryDetails", 0)
	}
	const medicineDetailsSection = () => {
		window.sessionStorage.setItem("pageNumberMedicineDetails", 1)
		window.sessionStorage.setItem("offsetMedicineDetails", 0)
	}
	const purchaseDetailsSection = () => {
		window.sessionStorage.setItem("pageNumberPurchaseDetails", 1)
		window.sessionStorage.setItem("offsetPurchaseDetails", 0)
	}
	const labTest = () => {
		window.sessionStorage.setItem("activePage", 1)
		window.sessionStorage.setItem("offset", 0)
		//	window.location.href="/settings/labTestDetails"
	};
	const treatmentProcedure = () => {
		window.sessionStorage.setItem("activePage", 1)
		window.sessionStorage.setItem("offset", 0)
		window.location.href = "/settings/treatmentProcedure"
	};
	const addSpecimen = () => {
		//	window.location.href='/settings/addSpecimenType'
	}
	const labTestType = () => {
		//	window.location.href='/settings/labTestType'
		window.sessionStorage.setItem("labTestactivePage", 1)
		window.sessionStorage.setItem("labTestoffset", 0)
	}
	return (
		<>
			{window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ?
				<div className="settingMenu">
					<div className="settingLeftTitle">Settings</div>
					<span className='LeftMenuTitle'>{window.sessionStorage.getItem("userName")}</span>
					<div className="settingMenuList">
						<ul>
							<li className="settingListLi">
								<NavLink to="/settings/practiceDetails" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Practice Details</NavLink>
							</li>
							<li className="settingListLi">
								{/* <NavLink to="/settings/treatmentProcedure"  activeClassName="active" onClick={showlalert}>Treatment Procedure</NavLink> */}
								<NavLink to="/settings/treatmentProcedure" activeClassName="active" onClick={showlalert}>
									<span onClick={() => treatmentProcedure()}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Treatment Procedure</span>
								</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink to="/settings/simplifiedFormSettings" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Simplified Form</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink to="/settings/autoIdGeneration" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Auto Id Generation</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink to="/settings/doctorPrescription" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Doctor Prescription</NavLink>
							</li>
							<li>
								<Link to="#">
									<a onClick={() => { setTogglePhrmcy(!togglePhrmcy); paginationOfPharmacySupplieer(); paginationOfPharmacyManufacture() }} style={{ padding: '0px' }}> Pharmacy</a>
									{togglePhrmcy && (
										<div className="plSubMenu inventorySubMenu" style={{ padding: "0px", marginTop: "15px" }}>
											<div>
												<li style={{ borderTop: "1px solid white" }}>
													<NavLink to="/settings/pharmacySupplier">
														<span onClick={paginationOfPharmacySupplieer}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Supplier</span>
													</NavLink>
												</li>
											</div>
											<div>

												<li style={{ borderBottom: "none" }}>
													<NavLink to="/settings/pharmacyManufacture">
														<span onClick={paginationOfPharmacyManufacture}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} > Manufacturer</span>
													</NavLink>
												</li>
											</div>
										</div>
									)}
								</Link>
							</li>
							{/*  */}

							<li className="settingListLi">
								<Link to="#"  >
									<a onClick={() => setToggleLab(!toggleLab)} style={{ padding: '0px' }}> Lab</a>
									{toggleLab && (
										<div className="plSubMenu inventorySubMenu" style={{ padding: "0px", marginTop: "15px" }}>
											<div>
												<li style={{ borderTop: "1px solid white" }}>
													<NavLink to="/settings/labTestDetails">
														<span onClick={() => labTest()}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} >
															Lab Test Details</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li >
													<NavLink to="/settings/addSpecimenType">
														<span onClick={() => addSpecimen()}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Add Specimen Type</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li style={{ borderBottom: "none" }}>
													<NavLink to="/settings/labTestType">
														<span onClick={() => labTestType()}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Lab Test Type</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li style={{ borderBottom: "none" }}>
													<NavLink to="/settings/addLab">
														<span onClick={() => labTestType()}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Add Lab</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li style={{ borderBottom: "none" }}>
													<NavLink to="/settings/addLabWork">
														<span onClick={() => labTestType()}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>Add Lab Work</span>
													</NavLink>
												</li>
											</div>
										</div>
									)}
								</Link>
							</li>
							<li>
								<Link to="#">
									<a onClick={() => setToggle(!toggle)} style={{ padding: '0px' }}> Hospital Inventory</a>
									{toggle && (
										<div className="plSubMenu inventorySubMenu" style={{ padding: "0px", marginTop: "15px" }}>
											<div>
												<li style={{ borderTop: "1px solid white" }}>
													<NavLink onClick={medicineDetailsSection}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to="/settings/medicineStockUpdate">
														<span>Medicine Stock Update</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li>
													<NavLink onClick={inventoryDetailsSection}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to="/settings/hospitalInventory">
														<span> Inventory Stock Update</span>
													</NavLink>
												</li>
											</div>
											<div>
												<li>
													<NavLink onClick={purchaseDetailsSection}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to="/settings/purchaseLists">
														<span>Purchase Lists</span>
													</NavLink>
												</li>

											</div>
											<div>
												<li style={{ borderBottom: "none" }}>
													<NavLink onClick={expenceDetailsSection}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to="/settings/hospitalExpenses">
														<span>Hospital Expenses</span>
													</NavLink>
												</li>
											</div>
										</div>
									)}
								</Link>
							</li>

							<li className="settingListLi">
								<NavLink to="/settings/consentform" activeClassName="active" onClick={ConsentForm}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} >Consent Form</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink onClick={taxDetailsSection}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to="/settings/taxDetails" activeClassName="active" >Tax Details</NavLink>
							</li>

							<li className="settingListLi">
								<NavLink to="/settings/healthcard" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Health Card</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink to="/settings/printouts" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Printouts</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink to="/settings/emails" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>Emails</NavLink>
							</li>
							<li className="settingListLi">
								<NavLink to="/settings/toDoList" activeClassName="active" onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}}>To-Do List</NavLink>
							</li>
						</ul>
					</div>
				</div>
				: (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALPHARMACY' ?
					<PharmacyLeft />
					:
					<div className="settingMenu">
						<div className="settingLeftTitle">Settings</div>
						<div className="settingMenuList">
							<ul>
								<li className="settingListLi">
									<NavLink to="/settings/healthcard" activeClassName="active" onClick={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}>Health Card</NavLink>
								</li>
								<li className="settingListLi">
									<NavLink to="/settings/simplifiedFormSettings" activeClassName="active" onClick={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}>Simplified Form</NavLink>
								</li>
								<li className="settingListLi">
									<NavLink to="/settings/casesheetSettings" activeClassName="active" onClick={() => {
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}>Case Sheet Settings</NavLink>
								</li>
							</ul>
						</div>
					</div>


				)}
		</>

	);
}

export default Settingleft;
