import React, { useEffect, useState } from 'react';
import Loader from '../Common/loader';
import Header from '../layout/header';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import PatientPaymentList from '../Patients/patientPaymentList';
import { callingAPI } from '../config';
import $ from 'jquery';
function AddPayments() {
	$(document).ready(function () {
		$('#paymentAdd').addClass("testResultAdd");

	})
	const [getInvoiceGroupListing, setInvoiceGroupListing] = useState([]);
	const [getAdvanceAmt, setAdvanceAmt] = useState('0.00');
	const [getAmoutDue, setAmoutDue] = useState('0.00');
	const [getData, setData] = useState('');
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [loaderFlagNew, setLoaderFlagNew] = useState(true);
	const [invoiceId, setInvoiceId] = useState([])
	const location = useLocation();
	const [getinvoiceData, setinvoiceData] = useState([]);
	const [count, setCount] = useState(0);
	const [invoiceDataAppnd, setinvoiceDataAppnd] = useState([]);
	const [total, setTotal] = useState('0.00')
	const [getPay, setPay] = useState('0.00');
	const [getAppliedAdvnc, setAppliedAdvnc] = useState('0.00')
	const [getDueAfter, setDueAfter] = useState(0.00)
	const [dueSection, setDueSection] = useState(false)
	const [afterSection, setAfterSection] = useState(false)
	const [getAdvncAfter, setAdvncAfter] = useState(getAdvanceAmt)
	const [gethidAdvcAmtSave, sethidAdvcAmtSave] = useState('0.00')
	const [errMsg, setErrMsg] = useState('');
	const [gethidPayAfterAdvnc, sethidPayAfterAdvnc] = useState('0.00')




	useEffect(async () => {
		await listing();
	}, [])
	const listing = () => {
		const apiJson = {
			"hospitalId": localStorage.getItem("hospitalId"),
			"memberId": window.sessionStorage.getItem('memberId')
		};
		callingAPI("payment/pendingInvoiceList", apiJson).then((data) => {
			if (data.data.success === "1") {
				setInvoiceGroupListing(data.data.result.invoiceData)
				setAdvanceAmt(data.data.result.advanceAmount)
				if (data.data.result.advanceAmount > 0) {
					setAdvncAfter(data.data.result.advanceAmount)
					setAfterSection(true)
				}
				else {
					setDueSection(true)
				}
				window.sessionStorage.setItem('advanceAmount', data.data.result.advanceAmount)
				var sum = 0;
				for (var i = 0; i < data.data.result.invoiceData.length; i++) {
					sum = sum + data.data.result.invoiceData[i].memberProcedureInvoiceTotalBalanceAmount;
				}
				setAmoutDue(sum)
				setLoaderFlag(true)
				if (typeof location.state !== 'undefined' || window.sessionStorage.getItem('memberProcedureInvoiceId') !== null) {
					var myArray = []
					if (typeof location.state !== 'undefined') {
						var helloWorld = location.state.memberProcedureInvoiceId
					} else {
						var helloWorld = window.sessionStorage.getItem('memberProcedureInvoiceId')
					}
					window.sessionStorage.removeItem('memberProcedureInvoiceId')
					var myArray = helloWorld.split(",");
					setInvoiceId(myArray)
					for (var i = 0; i < myArray.length; i++) {
						document.getElementById("aaa" + myArray[i]).style.display = 'none';
						setData(myArray[i])
						listing1(myArray[i])
					}
				}
			}
		});
	}
	const addInvList = (invoiceId, id) => {
		setLoaderFlagNew(false)
		setData(invoiceId)
		document.getElementById(id).style.display = 'none';

		var a = 0;
		getInvoiceGroupListing.map((obj) => {
			if (document.getElementById("aaa" + obj.memberProcedureInvoiceId).style.display === 'none') {
				a = parseInt(a) + 1
			}
		})
		if ((getInvoiceGroupListing.length) === parseInt(a)) {
			document.getElementById("invoiceTxt").style.display = 'none'
			document.getElementById("noInvoiceTxt").style.display = 'block'

		} else {
			document.getElementById("invoiceTxt").style.display = 'block'
			document.getElementById("noInvoiceTxt").style.display = 'none'
		}


		listing1(invoiceId)

	}





	const listing1 = (invoiceIdData) => {
		const apiJson = {
			"memberProcedureInvoiceId": invoiceIdData,
			"hospitalId": localStorage.getItem("hospitalId"),
			"memberId": window.sessionStorage.getItem('memberId')
		};
		callingAPI("payment/selectPendingInvoice", apiJson).then((data) => {
			if (data.data.success === "1") {
				var arrayObj = {
					invoiceId: data.data.result.invoiceData[0].invoiceId,
					invoiceNumber: data.data.result.invoiceData[0].invoiceNumber,
					totalBalanceAmount: data.data.result.invoiceData[0].totalBalanceAmount,
					totalNetAmount: data.data.result.invoiceData[0].totalNetAmount,
					procedureCode: data.data.result.invoiceData[0].procedureCode,
					medicineName: data.data.result.invoiceData[0].medicineName,
					inventoryName: data.data.result.invoiceData[0].inventoryName,
					testName: data.data.result.invoiceData[0].testName
				};
				invoiceDataAppnd.push(arrayObj)
				var totalBalance = 0;
				invoiceDataAppnd.map((val, idx) => {
					totalBalance = parseFloat(totalBalance) + parseFloat(val.totalBalanceAmount)
				})
				setTotal(totalBalance)
				if (invoiceIdData !== undefined && invoiceIdData !== null && invoiceIdData.length > 0) {
					payNowSaveFromInvoice(totalBalance)
				}
				else {
					setAmount(totalBalance)
				}
				setinvoiceData(data.data.result.invoiceData)
				setCount(data.data.result.invoiceData.lenth)
				setLoaderFlagNew(true)
			}
		});
	}
	const setAmount = (totalBalance) => {
		var getAdvanceAmt = window.sessionStorage.getItem('advanceAmount')
		var advanceAmt = 0;
		var dueAmt = 0;
		var txtAmount = 0;
		var totalPayable = totalBalance;
		if (totalBalance == "") {
			txtAmount = 0;
		}
		else {
			txtAmount = totalBalance
		}
		if (parseFloat(totalPayable) > 0) {
			if (parseFloat(txtAmount) > parseFloat(getAdvanceAmt)) {
				dueAmt = parseFloat(txtAmount) - parseFloat(getAdvanceAmt)
				setPay(parseFloat(dueAmt).toFixed(2))
				setDueAfter(0.00);
				setDueSection(true)
				setAfterSection(false)
				setAdvncAfter(0.00);
				sethidAdvcAmtSave(0.00);
				advanceAmt = -1
				if (parseFloat(getAdvanceAmt) > 0) {
					setAfterSection(true)
					setAppliedAdvnc(getAdvanceAmt);
					sethidPayAfterAdvnc(getAdvanceAmt)
				}
			}
			else {
				advanceAmt = parseFloat(getAdvanceAmt) - parseFloat(txtAmount)
				setAfterSection(true)
				setAppliedAdvnc(parseFloat(txtAmount).toFixed(2))
				sethidPayAfterAdvnc(txtAmount)
				setPay(0)
			}
		}
		else {
			advanceAmt = parseFloat(getAdvanceAmt) + parseFloat(txtAmount)
		}
		if (advanceAmt >= 0) {
			setAdvncAfter(parseFloat(advanceAmt).toFixed(2));
			setAfterSection(true)
			setDueSection(false)
			sethidAdvcAmtSave(advanceAmt);
		}
	}
	const payNowSaveFromInvoice = (totalBalance) => {
		var getAdvanceAmt = window.sessionStorage.getItem('advanceAmount')
		if (getAdvanceAmt > totalBalance) {
			setPay(0)
			if (totalBalance > 0) {
				setAfterSection(true)
				sethidPayAfterAdvnc(totalBalance)
				setAppliedAdvnc(parseFloat(totalBalance).toFixed(2))
			}
		} else {
			setPay(parseFloat(totalBalance) - parseFloat(getAdvanceAmt))
			if (totalBalance > 0) {
				if (getAdvanceAmt > 0) {
					setAfterSection(true)
				}
				sethidPayAfterAdvnc(getAdvanceAmt)
				setAppliedAdvnc(parseFloat(getAdvanceAmt).toFixed(2));
			}
		}
		if (getAdvanceAmt > 0) {
			if (getAdvanceAmt > totalBalance) {
				sethidAdvcAmtSave(parseFloat(getAdvanceAmt) - parseFloat(totalBalance));
				setDueSection(false)
				setAfterSection(true)
				setDueAfter(0.00)
				setAdvncAfter(parseFloat(getAdvanceAmt) - parseFloat(totalBalance))
			}
			else {
				sethidAdvcAmtSave(0)
				setDueSection(true)
				setAfterSection(false)
				setDueAfter(0.00)
				setAdvncAfter(0.00)
			}
		}
		else {
			sethidAdvcAmtSave(0)
			setDueSection(true)
			setAfterSection(false)
			setDueAfter(0.00)
			setAdvncAfter(0.00)
		}
	}
	const payNowSave = () => {
		var isFlag = true
		if ((invoiceDataAppnd.length === 0 && getPay == 0) || (invoiceDataAppnd.length > 0 && getPay == 0 && parseFloat(getAppliedAdvnc) === 0)) {
			setErrMsg("Amount should be greater than zero")
			removeMessage()
			isFlag = false
		}
		if (isFlag === true) {
			var getAdvanceAmt = window.sessionStorage.getItem('advanceAmount')
			setLoaderFlag(false)
			var flag = true
			document.getElementById("payNowSave").style.display = "none";

			var hidInvoiceIdArray = []
			for (var i = 0; i < invoiceDataAppnd.length; i++) {
				var g = invoiceDataAppnd[i].invoiceId
				hidInvoiceIdArray.push(g)
			}
			if (hidInvoiceIdArray.length > 0) {
				var apiJson = {
					"hospitalId": localStorage.getItem("hospitalId"),
					"memberId": window.sessionStorage.getItem('memberId'),
					"userId": window.sessionStorage.getItem("userId"),
					"memberProcedureInvoiceId": hidInvoiceIdArray.toString().replace(/[\[\]']/g, ''),
					"txtAmount": getPay.toString(),
					"hidAdvcAmtSave": gethidAdvcAmtSave,
					"hidPayAfterAdvnc": gethidPayAfterAdvnc,
					"hidNetAmt": total,
					"hidPromoId": "",
					"hidPromoPerc": "",
					"hidPromoAmt": "",
					"promoReason": "",
					"hidAdvcAmt": getAdvanceAmt,
					"invoicePaymentMode": document.getElementById('paymentTypeId').value
				};
				flag = true
			}
			else {
				var apiJson = {
					"hospitalId": localStorage.getItem("hospitalId"),
					"memberId": window.sessionStorage.getItem('memberId'),
					"userId": window.sessionStorage.getItem("userId"),
					"memberProcedureInvoiceId": "",
					"txtAmount": getPay,
					"hidAdvcAmtSave": getAdvncAfter,
					"hidPayAfterAdvnc": "0",
					"hidNetAmt": "0",
					"hidPromoId": "",
					"hidPromoPerc": "",
					"hidPromoAmt": "",
					"promoReason": "",
					"hidAdvcAmt": getAdvanceAmt,
					"invoicePaymentMode": document.getElementById('paymentTypeId').value
				};
				flag = true
			}
			if (flag === true && typeof apiJson !== "undefined") {
				callingAPI("payment/savePendingInvoice", apiJson).then((data) => {
					if (data.data.success === "1") {
						alert("Payment Received Successfully");
						window.location.href = '/patients/patientPayments'
					} else {
						alert("Something went wrong")
						document.getElementById("payNowSave").style.display = "block";
					}
				});
				// } else {
				// 	setErrMsg("Amount Should be Greater than Zero")
				// 	removeMessage()
				// 	document.getElementById("payNowSave").style.display = "block";
				// }

			}

		}
	}
	const setPay1 = (val) => {
		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (val === '' || re.test(val)) {
			setPay(val)
		} else {
			alert('Only Numbers Accepted')
		}
	}
	const removeMessage = () => {
		setTimeout(() => {
			setErrMsg("")
		}, 2000);
	};
	const settingDueAfterPay = (val) => {
		var getAdvanceAmt = window.sessionStorage.getItem('advanceAmount')
		var advanceAmt = 0;
		var txtAmount = 0;
		var totalPayable = total;

		const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		if (val === '' || re.test(val)) {
			if (getPay == "") {
				txtAmount = 0;
			}
			else {
				txtAmount = getPay
			}
			if (parseFloat(totalPayable) > 0) {
				if (parseFloat(txtAmount) > parseFloat(getAdvanceAmt)) {
					advanceAmt = parseFloat(getAdvanceAmt) + parseFloat(txtAmount) - parseFloat(totalPayable)
					if (advanceAmt < 0) {
						setDueAfter(parseFloat(-(advanceAmt)).toFixed(2))
						setAfterSection(false)
						setDueSection(true)
						setAdvncAfter(0.00)
						sethidAdvcAmtSave(0)
					}
				}
				else {
					if (parseFloat(totalPayable) > parseFloat(getAdvanceAmt)) {
						advanceAmt = parseFloat(getAdvanceAmt) + parseFloat(txtAmount) - parseFloat(totalPayable)
					}
					else {
						if (parseFloat(txtAmount) < parseFloat(getAdvanceAmt)) {
							advanceAmt = parseFloat(getAdvanceAmt) + parseFloat(txtAmount) - parseFloat(gethidPayAfterAdvnc)
						}
						else {
							advanceAmt = parseFloat(getAdvanceAmt) + parseFloat(txtAmount) - parseFloat(totalPayable)
						}
					}
					if (advanceAmt < 0) {
						setDueAfter(parseFloat(-(advanceAmt)).toFixed(2))
						setAfterSection(false)
						setDueSection(true)
						setAdvncAfter(0.00)
						sethidAdvcAmtSave(0)
					}
				}
			}
			else {
				advanceAmt = parseFloat(getAdvanceAmt) + parseFloat(txtAmount)
			}
			if (advanceAmt >= 0) {
				setAdvncAfter(parseFloat(advanceAmt).toFixed(2));
				setAfterSection(true)
				setDueSection(false)
				sethidAdvcAmtSave(advanceAmt);
			}
		}
	}
	const removeInvoice = (e, i, id) => {
		var getAdvanceAmt = window.sessionStorage.getItem('advanceAmount')
		let newFormValues1 = [...invoiceDataAppnd];
		newFormValues1.splice(i, 1);
		setinvoiceDataAppnd(newFormValues1);
		var totalBalance = 0;
		newFormValues1.map((val, idx) => {
			totalBalance = totalBalance + val.totalBalanceAmount
		})
		setTotal(totalBalance)
		if (totalBalance < getAdvanceAmt) {
			setPay(0)
			setAppliedAdvnc(totalBalance)
		} else {
			setPay(totalBalance)
			setAppliedAdvnc(getAdvanceAmt)
		}
		setAmount(totalBalance)
		document.getElementById(id).style.display = 'block';
		document.getElementById("invoiceTxt").style.display = 'block'
		document.getElementById("noInvoiceTxt").style.display = 'none'
	};






	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll addPaymentWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					{(loaderFlag === false || loaderFlagNew === false) ? <Loader /> : ''}
					<div className="practiceManagementWrap">
						{loaderFlag === true ?
							<div className="row practiceManagementRow">
								<div className="col-lg-8 col-md-12 practiceLeftSection">





									{/* <PatientPaymentList getData={getData} getAdvanceAmt={getAdvanceAmt} invoiceId={invoiceId} /> */}




									<div class="treatmentPlanWrap patientPaymentList patientConsultInnerPgeLoadDiv_PTour">
										<div id="divPatientContent" class="sideMenuContent">
											<div class="treatmentPlanCover" id="treatmentPlanListDiv">
												<div class="treatmentPlanHead" style={{ border: 'none', padding: '13px 10px' }}>
													<span class="treatmentPlanName">PAYMENT</span>
													<span id="payNowSave" class="treatmentPlanBtn pl_blue_btn" style={{ background: '#5bb75b' }} onClick={() => { payNowSave() }}>
														<span >Pay Now</span>
													</span>
													{invoiceId !== undefined && invoiceId !== null && invoiceId.length > 0 ?
														<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
															<a href="/patients/patientInvoices">Cancel</a>
														</span>
														:
														<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
															<a href="/patients/patientPayments">Cancel</a>
														</span>
													}
												</div>
												<div class="treatmentPlanList pl_treatmentDiv pl_addTreatmnt">
													<div class="pl_treat treatmentTable planActive">
														<div>
															<table style={{ border: 'none' }}>
																{invoiceDataAppnd.length > 0 ?
																	<thead>
																		<tr>
																			<th class="col-md-2">
																				<div class="dentalProName">INVOICE NO</div>
																			</th>
																			<th class="col-md-2">
																				<div class="dentalProName">TOWARDS</div>
																			</th>
																			<th class="col-md-3  ">
																				<div class="dentalProNote" style={{ textAlign: 'right' }}>
																					TOTAL AMOUNT (INR)
																				</div>
																			</th>
																			<th class="col-md-2  ">
																				<div class="dentalProNote" style={{ textAlign: 'right' }}>
																					PAID (INR)
																				</div>
																			</th>
																			<th class="col-md-3  ">
																				<div class="dentalProNote BalanceAmntTh" style={{ textAlign: 'right' }}>
																					BALANCE (INR)
																				</div>
																			</th>
																		</tr>
																	</thead>
																	: ''}
																{invoiceDataAppnd.map((obj, i) => (
																	<tbody>
																		<span colspan="4" className='ptPymntCloseIcon'><span className="close_row" style={{ display: 'block' }} onClick={(e) => removeInvoice(e, i, "aaa" + obj.invoiceId)}>x</span></span>
																		<tr class="groupBrdr">
																			<td class="col-md-2">
																				<div class="dentalProName">
																					<a href="" style={{ color: '#00abff', textDecoration: 'none', fontWeight: "normal" }}>
																						{obj.invoiceNumber}
																					</a>
																				</div>
																			</td>
																			<td class="col-md-2 patientPaymntListItems" >
																				{obj.procedureCode !== "" ? <p >{obj.procedureCode ? <span><b>Procedure -</b> {obj.procedureCode.replaceAll('()', '')}</span> : ""}<br /></p> : ""}
																				{obj.medicineName !== "" ? <p> {obj.medicineName ? <span><b>Medicine -</b> {obj.medicineName}</span> : ""}<br /></p> : ""}
																				{obj.testName !== "" ? <p> {obj.testName ? <span><b>Lab -</b> {obj.testName}</span> : ""}<br /></p> : ""}
																				{obj.inventoryName !== "" ? <p> {obj.inventoryName ? <span><b>Disposable -</b> {obj.inventoryName}</span> : ""}<br /></p> : ""}
																			</td>
																			<td class="col-md-2 ">
																				<div
																					class="dentalProDis"
																					style={{
																						textAlign: 'right',
																						color: '008000', padding: "7px 4px",
																						fontSize: "12px"
																					}}
																				>
																					{parseFloat(obj.totalNetAmount).toFixed(2)}
																				</div>
																			</td>
																			<td class="col-md-3">
																				<span className="totalinr" style={{ color: '008000', fontSize: '12px' }}>
																					{parseFloat(obj.totalNetAmount - obj.totalBalanceAmount).toFixed(2)}
																				</span>
																			</td>
																			<td class="col-md-3">
																				<span className="totalinr balanceAmountTblList" style={{ color: 'red' }}>
																					{parseFloat(obj.totalBalanceAmount).toFixed(2)}
																				</span>
																			</td>
																		</tr>
																	</tbody>
																))}
															</table>
														</div>
														<div
															class="paymentSec paymentSecDiv paymentPaymntSec_PTour"
															style={{ display: 'block' }}
														>
															<div class="paymentSecTop paymentSecTop_PTour">
																<span class="ContentLabel">
																	TOTAL PAYABLE : INR<span style={{ color: '#f00' }}>
																		{parseFloat(total).toFixed(2)} </span>
																</span>
																{' '}
																{getAdvanceAmt > 0 ?
																	<span class="ContentLabel">
																		AVAILABLE ADVANCE : INR<span style={{ color: '#171717' }}>{parseFloat(getAdvanceAmt).toFixed(2)}</span>
																	</span>
																	: ""}
															</div>
															<br />
															<div class="paymentSecContent paymentSecContent_PTour" style={{ width: "100%", float: "left", marginBottom: "8px", borderBottom: "1px dashed #8a8a8a" }}>
																{getAdvanceAmt > 0 && invoiceDataAppnd.length > 0 ?
																	<span class="ContentBtmLabel" >
																		ADVANCE AMOUNT APPLIED : INR&nbsp;
																		<span>{getAppliedAdvnc}</span>
																	</span>
																	: ""}
																<div class="paymentSecLst">
																	<label style={{ float: "left" }}>&nbsp;PAY NOW : </label>
																	<span style={{ float: "left" }}>
																		<input
																			type="text"
																			value={getPay}
																			autocomplete="off"
																			maxlength="10"
																			style={{ height: '30px' }}
																			onChange={(e) => setPay1(e.target.value)}
																			onKeyUp={(e) => settingDueAfterPay(e.target.value)}
																		/>
																		<select
																			style={{
																				height: '30px',
																				width: 'auto',
																				border: '1px solid #9a9a9a',
																			}}
																			id="paymentTypeId"
																		>
																			<option value="1">Cash</option>
																			<option value="2">Cheque</option>
																			<option value="3">Card</option>
																			<option value="4">UPI</option>
																		</select>
																	</span>
																	<label
																		id="lblMessage"
																		style={{ color: 'red', textAlign: "center", fontSize: "13px", float: "left", marginLeft: "20px" }}
																	>	{errMsg}
																	</label>
																</div>
															</div>
															<div class="paymentSecBtm paymentSecBtm_PTour">
																{dueSection === true &&
																	<span class="ContentBtmLabel">
																		DUE AFTER PAYMENT : INR  <span style={{ color: '#f00', fontWeight: '600' }}> {parseFloat(getDueAfter).toFixed(2)}</span>
																	</span>
																}
																{' '}
																{afterSection === true &&
																	<span class="ContentBtmLabel txtGreenColor" >
																		ADVANCE AFTER PAYMENT : INR
																		<span style={{ color: '#f00' }}> {parseFloat(getAdvncAfter).toFixed(2)}</span>
																	</span>
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>



								</div>
								<div className="col-lg-4 col-md-12 practiceRightSection">
									<div
										class="treatmentProcedureList completedPlanProce_PTour proceduresinner_PTour testResultDetails_PTour"
										id="treatmentProcedureList"
									>
										<div class="treatmentListtop invoiceSearchProcedure_PTour paymentPending_PTour">
											<div class="treatmentListHead txtCenter">
												<span id="invoiceTxt" class="inVoiceTxtLabel">
													Select pending Invoice
												</span>
												<span id="noInvoiceTxt" class="inVoiceTxtLabel" style={{ display: 'none' }}>
													No pending Invoice
												</span>
												<div>
													{getAdvanceAmt === '0.00' ? ''
														:
														<span class="treatmentHeadAdvance">
															Advance (INR) : <span>{getAdvanceAmt}</span>
														</span>
													}
													{getInvoiceGroupListing.length !== 0 ?
														<span class="treatmentHeadDue" >
															Due (INR) : <span id="dueTotal1">{parseFloat(getAmoutDue).toFixed(2)}</span>
														</span>
														: ""}
												</div>
											</div>
										</div>
										{getInvoiceGroupListing.length > 0 ?
											getInvoiceGroupListing.map((listObj, i) => (
												<div style={{ display: 'block' }} class="treatmentListSide invoiceFullDetails_PTour paymentDetailsPending_PTour" id={"aaa" + listObj.memberProcedureInvoiceId} onClick={() => { addInvList(listObj.memberProcedureInvoiceId, "aaa" + listObj.memberProcedureInvoiceId) }}>
													<div class="treatmentNameList">
														<ul id="treatList">
															<div class="inVoiceSideWraper paymentSideWrapper_PTour" id="pendingDiv" style={{ display: parseFloat(listObj.memberProcedureInvoiceTotalBalanceAmount == "0") ? 'none' : 'block' }}>
																{/* {listObj.memberProcedureInvoiceTotalBalanceAmount > 0 && */}
																<div class="inVoiceSideBlck" id="invoiceDiv53068">
																	<div class="inVoiceTop">
																		<div class="inVoiceSideLeft">{listObj.memberProcedureInvoiceNumber}</div>
																		<div class="inVoiceSideRight">{listObj.memberProcedureInvoiceGeneratedOn}</div>
																	</div>

																	<div class="inVoiceAmount">
																		<div class="inVoiceAmountList">
																			<div class="inVoiceSideLeft">Invoice Amount</div>
																			<div class="inVoiceSideRight">₹ {parseFloat(listObj.memberProcedureInvoiceTotalNetAmount).toFixed(2)}</div>
																		</div>
																		<div class="inVoiceAmountList">
																			<div class="inVoiceSideLeft">Paid Amount</div>

																			<div class="inVoiceSideRight">₹ {parseFloat(listObj.memberProcedureInvoiceTotalNetAmount - listObj.memberProcedureInvoiceTotalBalanceAmount).toFixed(2)}  </div>
																		</div>
																		<div class="inVoiceTotalAmount">
																			<div class="inVoiceSideLeft">Amount due</div>
																			<div class="inVoiceSideRight">₹ {parseFloat(listObj.memberProcedureInvoiceTotalBalanceAmount).toFixed(2)} </div>
																		</div>
																	</div>
																</div>
																{/* } */}
															</div>
														</ul>
													</div>
												</div>
											))
											: "No Pending Invoice"}
									</div>
								</div>
							</div>
							: ""}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AddPayments;
