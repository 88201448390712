import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';

import { Route } from 'react-router-dom';
import Reportmenu from '../Reports/reportmenu';
import Patientdetails from './patientdetails';
import Pharmacystockdetails from './pharmacystockdetails';
import Pharmacypayment from './pharmacypayment';
import IncomeExpense from './incomeExpense';
import Pendingpaymentdetails from './pendingpaymentdetails';
import Patientpayment from './patientpayment';
import Refund from './refund';
import PrescriptionAmount from './prescriptionAmount';
import PrescriptionAmountDetails from './prescriptionAmountdetails';
import RevenueByDoctor from './revenueByDoctor';
import PurchaseInvoiceDetails from './purchaseInvoiceDetails';
import SupplierInvoice from './supplierInvoice';
import IncomeExpenseInvoice from './incomeExpenseInvoice';
import Receipt from './receipt';
import RevenueInvoice from './revenueInvoice';
import BillingLeft from '../billingLogin/billingLeft';
import BillingHeader from '../billingLogin/layout/header';
import PharmacyLeft from '../pharmacyLogin/pharmacyLeft';
import PharmacyHeader from '../pharmacyLogin/layout/header';
import DoctorReference from '../Reports/doctorReference';
import MedicineByDoctor from '../Reports/medicineByDoctor';
import AppointmentList from '../Reports/appointmentList';
import LabTestInventory from '../Reports/labTestInventory';
import TreatmentDetails from '../Reports/treatmentDetails';
import ReceiptTaxDetails from '../Reports/receiptTaxDetails';
import GenericMedicineList from '../Reports/genericMedicineList';


function reports() {
	return (
		<div>

			{/* <div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec">
           {window.sessionStorage.getItem("loginRole")==="BILLING ADMIN"?<BillingHeader/>:<LabHeader />}
            <div className="contentWraper contentWraperScroll">
               {window.sessionStorage.getItem("loginRole")==="BILLING ADMIN"?<BillingLeft/>:<LabLeft />} */}
			<div className="coverWraper innerCoverWraper">
				{/* {window.sessionStorage.getItem("loginRole") === "BILLING ADMIN" ? <BillingHeader /> : <Header />} */}
				{/* {window.sessionStorage.getItem("loginRole") === "HOSPITAL PHARMACY" ? <PharmacyHeader /> : <Header />} */}

				{window.sessionStorage.getItem("role") === 'ROLE_BILLINGADMIN' ? <BillingHeader /> : window.sessionStorage.getItem("role") === 'ROLE_HOSPITALPHARMACY' ? <PharmacyHeader /> : <Header />}

				<div className="contentWraper contentWraperScroll">
					{/* {window.sessionStorage.getItem("loginRole")==="BILLING ADMIN"?<BillingLeft/>:<Reportmenu />} */}
					{window.sessionStorage.getItem("role") === 'ROLE_BILLINGADMIN' ? <BillingLeft /> : window.sessionStorage.getItem("role") === 'ROLE_HOSPITALPHARMACY' ? <PharmacyLeft /> : <Reportmenu />}
					<div className="settingsRight">
						<Route path="/reports/patientdetails" component={Patientdetails} />
						<Route path="/reports/pharmacystockdetails" component={Pharmacystockdetails} />
						<Route path="/reports/pharmacypayment" component={Pharmacypayment} />
						<Route path="/reports/incomeExpense" component={IncomeExpense} />
						<Route path="/reports/pendingpaymentdetails" component={Pendingpaymentdetails} />
						<Route path="/reports/patientpayment" component={Patientpayment} />
						<Route path="/reports/refund" component={Refund} />
						<Route path="/reports/prescriptionAmount" component={PrescriptionAmount} />
						<Route path="/reports/prescriptionAmountdetails" component={PrescriptionAmountDetails} />
						<Route path="/reports/revenueByDoctor" component={RevenueByDoctor} />
						<Route path="/reports/supplierInvoice" component={SupplierInvoice} />
						<Route path="/reports/purchaseInvoiceDetails" component={PurchaseInvoiceDetails} />
						<Route path="/reports/incomeExpenseInvoice" component={IncomeExpenseInvoice} />
						<Route path="/reports/receipt" component={Receipt} />
						<Route path="/reports/revenueInvoice" component={RevenueInvoice} />
						<Route path="/reports/doctorReference" component={DoctorReference} />
						<Route path="/reports/medicineByDoctor" component={MedicineByDoctor} />
						<Route path="/reports/appointmentList" component={AppointmentList} />
						<Route path="/reports/labTestInventory" component={LabTestInventory} />
						<Route path="/reports/treatmentDetails" component={TreatmentDetails} />
						<Route path="/reports/receiptTaxDetails" component={ReceiptTaxDetails} />
						<Route path="/reports/genericMedicineList" component={GenericMedicineList} />

					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default reports;
