import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import HomeleftMenu from '../Hospital Admin/homeleftMenu';
import news from '../images/media/idaDashboardImg.jpg';
import { callingAPI } from '../config';
function HospitalHome() {
	const [hospitalHomeScreenArray, setHospitalHomeScreenArray] = useState([])
	useEffect(() => {
		let PostData = {
			"pmSiteId": window.sessionStorage.getItem("siteId")
		};
		callingAPI("commonUserFunctions/hospitalHomescreen", PostData
		).then((data) => {
			if (data.data.success === "1") {
				setHospitalHomeScreenArray(data.data.result.hospitalHomescreenArraydetails[0].dashboardContent)
			}
		})
			.catch()
	}, []);
	return (
		<div>
			<div className="coverWraper innerCoverWraper">
				<Header />
				<div className="contentWraper contentWraperScroll">
					<div className="container">
						<div className="row">
							<div className="col-md-4">
								<HomeleftMenu />
							</div>
							<div className="col-md-8">
								{hospitalHomeScreenArray ? (
									<div dangerouslySetInnerHTML={{ __html: hospitalHomeScreenArray }}></div>
								) :
									<div className="landingBodyWrap">

										<React.Fragment>
											<div class="landingHead">Bulletin Board</div>
											<div className="landingMainContent" >
												<img src={news} alt="" />
												<h3>Welcome to IDA KSB Assist, the Clinic Management Solution!</h3>
												<p>
													Welcome and congratulations on signing up with IDA KSB Assist, the Clinic
													Management Solution! We are very pleased to offer this very robust and
													feature packed application that will help you and clinic operate more
													efficiently, and engage better with your patients & customers.
												</p>
												<h3>Features Include:</h3>
												<ul>
													<li>Patient Booking </li>
													<li>Patient Records</li>
													<li>SMS &amp; Email reminders and alerts</li>
													<li>Data &amp; Analysis Dashboard</li>
													<li>Telehealth Integration</li>
												</ul>
												<div class="lastSection">
													<p>
														We are constantly improving and updating and expanding this software to
														make it better, more powerful and easier to use. Please look here for
														more information as and when we have new and exciting news to share with
														you.
													</p>
												</div>
												<div class="final">
													<p>Best Regards,</p>
													<b>IDA Kerala State Branch</b>
												</div>
											</div>
										</React.Fragment>
									</div>}
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default HospitalHome;
