import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import { callingAPI } from "../config";
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Printer_icon from '../images/icons/printer_icon.png';
import Editicon from '../images/icons/edit-bg.png';
import moment from 'moment';
import $ from "jquery";

import { Link, useLocation } from 'react-router-dom';

function VitalSignsAdd(props) {
	const location = useLocation();
	const [listingData, setListingData] = useState([])
	const [medicalHealthReportId, setMedicalHealthReportId] = useState("");
	const [weightData, setWeightData] = useState("")
	const [heightData, setHeightData] = useState("")
	const [bloodPressureDataSystolic, setBloodPressureDataSystolic] = useState("")
	const [bloodPressureDataDiastolic, setBloodPressureDataDiastolic] = useState("")
	const [bloodPressureDataDiastolicType, setBloodPressureDataDiastolicType] = useState("Sitting")
	const [pulseData, setPulseData] = useState("")
	const [respirationData, setRespirationData] = useState("")
	const [temperatureData, setTemperature] = useState("")
	const [typeTemperatureTakenData, settypeTemperatureTakenData] = useState("1")
	const [fBSData, setFBSData] = useState("")
	const [pPBSData, setPPBSData] = useState("")
	const [rBSData, setRBSData] = useState("")
	const [hbA1cData, setHbA1cData] = useState("")
	const [hbData, setHbData] = useState("")
	const [cBCData, setCBCData] = useState("")
	const [totalCholestrolData, settotalCholestrolData] = useState("")
	const [triglycerideData, setTriglycerideData] = useState("")
	const [lDLData, setLDLData] = useState("")
	const [hDLData, setHDLData] = useState("")
	const [urineAlbuminData, seturineAlbuminData] = useState("")
	const [ureaData, setUreaData] = useState("")
	const [creatinineData, setcreatinineData] = useState("")
	const [lPTData, setLPTData] = useState("")
	const [tFTData, setTFTData] = useState("")
	const [eyesData, setEyesData] = useState("")
	const [commentsData, setCommentsData] = useState("")
	const [sys, setSys] = useState("")
	const [dia, setDia] = useState("")
	const [pul, setPul] = useState("")
	const [buttonDisable, setButtonDisable] = useState(false);

	useEffect(() => {
		if (typeof location.state !== 'undefined') {
			const medicalHealthReportId = location.state.memberHealthReportId;
			setMedicalHealthReportId(medicalHealthReportId);
			if (medicalHealthReportId !== "") {
				const postdata = {

					"memberId": window.sessionStorage.getItem('memberId'),
					"hospitalId": localStorage.getItem("hospitalId"),
					"memberHealthReportId": medicalHealthReportId

				};

				callingAPI('commonUserFunctions/simpleEntryFormVitalList', postdata).then((data) => {
					if (data.data.success === '1') {
						setListingData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList);
						setWeightData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].weight);


						setHeightData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].height);
						setBloodPressureDataSystolic(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].systolic);
						setBloodPressureDataDiastolic(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].diastolic);
						setBloodPressureDataDiastolicType(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].sitOrStand);
						setPulseData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].pulse);
						setRespirationData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].respRate);
						setTemperature(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].temp);
						settypeTemperatureTakenData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].tempTypeId);
						setFBSData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].fbs);
						setPPBSData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].ppbs);
						setRBSData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].rbs);
						setHbA1cData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].hbac);
						setHbData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].hb);
						setCBCData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].cbc);
						settotalCholestrolData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].cholestrol);
						setTriglycerideData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].triglyceride);
						setLDLData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].ldl);
						setHDLData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].hdl);
						seturineAlbuminData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].urineAalbumin);
						setUreaData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].urea);
						setcreatinineData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].creatinine);
						setLPTData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].lpt);
						setTFTData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].tft);
						setEyesData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].eyes);
						setCommentsData(data.data.result.vitalSignDateArr[0].VitalSignDoctorList[0].VitalSignList[0].comments);


					}
				});
			}

		}

	}, [])

	function inRangesis(x) {
		setBloodPressureDataSystolic(x);
		if (x !== "" && x > 50 && x < 300) {
			setSys("true")
		}
		else {
			setSys("false")
		}
	}
	function inRangeDia(x) {
		setBloodPressureDataDiastolic(x);
		if (x !== "" && x > 25 && x < 200) {
			setDia("true")
		}
		else {
			setDia("false")
		}
	}
	function inRangePulse(x) {
		setPulseData(x);
		if (x !== "" && x > 10 && x < 200) {
			setPul("true")
		}
		else {
			setPul("false")
		}
	}




	const SaveApiCall = () => {
		setButtonDisable(true)
		if (weightData === "" && heightData === "" && sys === "" && dia === "" && pul === "" && respirationData === "" && temperatureData === "" &&
			fBSData === "" && pPBSData === "" && rBSData === "" && hbA1cData === "" && hbData === "" && cBCData === "" && totalCholestrolData === "" &&
			triglycerideData === "" && lDLData === "" && hDLData === "" && urineAlbuminData === "" && ureaData === "" && creatinineData === "" &&
			lPTData === "" && tFTData === "" && eyesData === "" && commentsData === "") {
			alert("Please Enter Atleast One Field");
			setButtonDisable(false)
			return false;
		}
		else if (weightData > 700) {
			alert('Weight cannot be greater than 700');
			document.getElementById('WeightData').focus();
			setWeightData("");
			setButtonDisable(false)
			return false;
		}
		else if (heightData > 700) {
			alert('Height cannot be greater than 700');
			document.getElementById('heightData').focus();
			setHeightData("");
			setButtonDisable(false)
			return false;
		}
		else if (sys === 'false') {
			alert('Systolic Blood Pressure Should Between 50 and 300');
			document.getElementById('bloodPressureDataSystolic').focus();
			setBloodPressureDataSystolic("");
			setSys('')
			setButtonDisable(false)
			return false;
		}
		else if (dia === 'false') {
			alert('Diastolic Blood Pressure Should Between 25 and 200');
			document.getElementById('bloodPressureDataDiastolic').focus();
			setBloodPressureDataDiastolic("");
			setDia('')
			setButtonDisable(false)
			return false;
		}

		else if (pul === 'false') {
			alert('Pulse Rate Should Between 10 and 200');
			document.getElementById('pulseData').focus();
			setPulseData("");
			setPul('')
			setButtonDisable(false)
			return false;
		}
		var date = moment(new Date()).format('yyyy-MM-DD hh:mm A');
		const currentTimeInMilliseconds = new Date().getTime();
		const thirtyMinutesInMilliseconds = 30 * 60 * 1000;
		const futureTimeInMilliseconds = currentTimeInMilliseconds + thirtyMinutesInMilliseconds;
		let PostData = {
			"memberHealthReportId": medicalHealthReportId !== "" ? medicalHealthReportId : "",
			"doctorId": window.sessionStorage.getItem('doctorId'),
			"userId": window.sessionStorage.getItem('userId'),
			"height": heightData,
			"memberId": window.sessionStorage.getItem('memberId'),
			"pulse": pulseData,
			"temperature": temperatureData,
			"weight": weightData,
			"bpSitOrStand": bloodPressureDataDiastolicType,
			"temperatureTypeId": typeTemperatureTakenData,
			"temperatureUnit": "°C",
			"respRate": respirationData,
			"bpHigh": bloodPressureDataSystolic,
			"bpLow": bloodPressureDataDiastolic,
			"cbc": cBCData,
			"creatinine": creatinineData,
			"eyes": eyesData,
			"fbs": fBSData,
			"hb": hbData,
			"hbac": hbA1cData,
			"hdl": hDLData,
			"ldl": lDLData,
			"lpt": lPTData,
			"Others": commentsData,
			"ppbs": pPBSData,
			"rbs": rBSData,
			"tft": tFTData,
			"totalCholestrol": totalCholestrolData,
			"triglyceride": triglycerideData,
			"urea": ureaData,
			"urineAlbumin": urineAlbuminData,
			"hospitalId": localStorage.getItem("hospitalId"),
			"bloodOxygenLevel": "",
			"description": "",
			"createdDateAndTime": date,
			"scheduledtimeSlotStartTime": currentTimeInMilliseconds,
			"scheduledtimeSlotEndTime": futureTimeInMilliseconds,
			"prNoEnabled": window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
		}
		callingAPI("medicalCertificate/saveOrUpdateVitalSigns", PostData).then((response) => {
			if (response.data.success === "1") {
				if (medicalHealthReportId == "") {
					alert("Vital Sign Saved Successfully.")
				}
				else {
					alert("Vital Sign Edited Successfully.")
				}

				setButtonDisable(false)
				window.location.href = "/patients/vitalSigns";
			}
			else {
				setButtonDisable(true)
			}
		});

	};
	const addCaseClick = () => {

		$("#patientProfileId").hide();
		$("#patientProfileEditId").show();
		$("#caseSheetAdd").hide();
		$("#caseSheetSave").show();
		$("#treatmentNodata").hide();
		$("#vitalData").show();
	}
	const editCancelClick = () => {
		//$("#caseSheetSave").hide();
		//$("#caseSheetAdd").show();
	}
	const Sitting = () => {
		$("#sitting").hide();
		$("#standing").show();
		setBloodPressureDataDiastolicType("Standing ")
	}
	const Standing = () => {
		$("#sitting").show();
		$("#standing").hide();
		setBloodPressureDataDiastolicType("Sitting")
	}

	const numberValidation = (val, type) => {
		// const re = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
		const re = /^[0-9.\b]+$/;
		if (val === '' || (re.test(val))) {
			if (type === "Weight") {
				setWeightData(val)
			}
			if (type === "Height") {
				setHeightData(val)
			}
			if (type === "hbA1cData") {
				setHbA1cData(val)
			}
			if (type === "Temprature") {
				setTemperature(val)
			}
		}
		else {
			alert('Accepts Numbers Only')
		}
		// else{setWeightData(val)}
		// else if(type === "Weight"){
		// 	setWeightData(val)
		// }
		// else if(type === "Height"){
		// 	setHeightData(val)
		// }
		// else if (type === "hbA1cData"){
		// 	setHbA1cData(val)
		// }

	}

	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>

										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">VITAL SIGNS</span>
												<span id="saveVitalButton" class="treatmentPlanBtn pl_blue_btn" style={{ background: "rgb(91, 183, 91)", padding: "4px 15px", display: buttonDisable === false ? '' : 'none' }} onClick={SaveApiCall} >Save</span>
												<a href="/patients/vitalSigns"><span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">Cancel</span></a>
											</div>
											<div class="treatmentPlanList" style={{ height: "auto" }}>
												<div className="vitalsigns">
													<div className="treatmentProfileCover">
														<div className="treatmentPlanContent">
															<div id="vitalData" className="profileCover" style={{ display: "block" }}>
																<div className="practiceManagementGrid">
																	<div className="row rowMargin">
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Weight(Kg)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			{/* onChange={(e)=>setWeightData(e.target.value)}  */}
																			{/* onKeyUp={(e) => numberValidation(e.target.value)} */}
																			<input id="WeightData" type="text" placeholder='Weight' onChange={(e) => numberValidation(e.target.value, "Weight")} value={weightData} className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">Height (Cm)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			{/* onChange={(e)=>setHeightData(e.target.value)} */}
																			<input id="heightData" type="text" onChange={(e) => numberValidation(e.target.value, "Height")} value={heightData} placeholder='Height' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Blood Pressure (mmHg)</label>
																		</div>
																		<div className="col-md-8 col-lg-8 practiceManagementList">
																			<input type="text" placeholder='Systolic' id="bloodPressureDataSystolic" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => inRangesis(e.target.value)} value={bloodPressureDataSystolic} className="inputField practiceInputField" />
																			<span className="slashDivide">/</span>
																			<input type="text" id="bloodPressureDataDiastolic" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => inRangeDia(e.target.value)} placeholder='Diastolic' value={bloodPressureDataDiastolic} className="inputField practiceInputField" />
																			<span id="sitting" className="toggleOne" value={bloodPressureDataDiastolicType} onClick={Sitting}>Sitting</span>
																			<span id="standing" className="toggleOne" value={bloodPressureDataDiastolicType} onClick={Standing} style={{ display: "none" }}>Standing</span>

																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Pulse (Heart beats/min)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input id="pulseData" type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => inRangePulse(e.target.value)} value={pulseData} placeholder='Pulse' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">RESP.Rate (breaths/min)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input id="respirationData" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => setRespirationData(e.target.value)} value={respirationData} type="text" placeholder='Reading' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Temperature ( °C )</label>
																		</div>
																		<div className="col-md-8 col-lg-8 practiceManagementList">
																			{/* onChange={(e) => setTemperature(e.target.value)} */}
																			<input type="text" id="temperatureData" placeholder='Temprature' onChange={(e) => numberValidation(e.target.value, "Temprature")} value={temperatureData} className="inputField practiceInputField" />
																			<div className="practiceListSub">
																				<select className="inputField practiceInputField" value={typeTemperatureTakenData} onChange={(e) => (settypeTemperatureTakenData(e.target.value))}>
																					<option value="1">Axillary (Armpit)</option>
																					<option value="2">Oral (Mouth)</option>
																					<option value="3">Tympanic (Ear)</option>
																					<option value="4">Temporal  (Forehead)</option>
																					<option value="5">Rectal (Anus)</option>
																				</select>
																			</div>
																		</div>
																		<div className="clearfix"></div>
																		<div className="borderSeparate"><span></span></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField"><strong>Blood Glucose</strong></label>
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">FBS (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" id="fBSData" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (setFBSData(e.target.value))} value={fBSData} placeholder='FBS' className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">PPBS (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" id="pPBSData" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (setPPBSData(e.target.value))} value={pPBSData} placeholder='PPBS' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">RBS (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" id="rBSData" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={rBSData} onChange={(e) => (setRBSData(e.target.value))} placeholder='RBS' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="borderSeparate"><span></span></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField"><strong>Hemoglobin & Blood Count</strong></label>
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">HbA1c (%)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			{/* onChange={(e)=>(setHbA1cData(e.target.value))} */}
																			<input type="text" value={hbA1cData} onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => numberValidation(e.target.value, "hbA1cData")} placeholder='HbA1c' className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">Hb (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" value={hbData} onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (setHbData(e.target.value))} placeholder='Hb' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">CBC</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" value={cBCData} onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (setCBCData(e.target.value))} placeholder='CBC' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="borderSeparate"><span></span></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField"><strong>Lipid Profile</strong></label>
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Total Cholestrol (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (settotalCholestrolData(e.target.value))} value={totalCholestrolData} type="text" placeholder='Cholestrol' className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">Triglyceride (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={triglycerideData} onChange={(e) => (setTriglycerideData(e.target.value))} placeholder='Triglyceride' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">LDL (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (setLDLData(e.target.value))} value={lDLData} type="text" placeholder='LDL' className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">HDL (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={hDLData} onChange={(e) => (setHDLData(e.target.value))} placeholder='HDL' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="borderSeparate"><span></span></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField"><strong>Urine Analysis</strong></label>
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Urine Albumin</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} onChange={(e) => (seturineAlbuminData(e.target.value))} value={urineAlbuminData} placeholder='Urine Albumin' className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">Urea (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={ureaData} onChange={(e) => (setUreaData(e.target.value))} placeholder='Urea' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Creatinine (mg/dl)</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={creatinineData} onChange={(e) => (setcreatinineData(e.target.value))} placeholder='Creatinine' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="borderSeparate"><span></span></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">LPT</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={lPTData} onChange={(e) => (setLPTData(e.target.value))} placeholder='LPT' className="inputField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">TFT</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<input type="text" onKeyPress={(event) => {
																				if (!/[0-9]/.test(event.key)) {
																					event.preventDefault();
																					alert("Accepts Numbers Only");
																				}
																			}} value={tFTData} onChange={(e) => (setTFTData(e.target.value))} placeholder='TFT' className="inputField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>
																		<div className="borderSeparate"><span></span></div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<label className="labelTextField">Eyes</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<textarea value={eyesData} onChange={(e) => (setEyesData(e.target.value))} className="textareaField practiceInputField" />
																		</div>
																		<div className="col-md-2 col-lg-2 practiceManagementList">
																			<label className="labelTextField">Comments</label>
																		</div>
																		<div className="col-md-3 col-lg-3 practiceManagementList">
																			<textarea value={commentsData} onChange={(e) => (setCommentsData(e.target.value))} className="textareaField practiceInputField" />
																		</div>
																		<div className="clearfix"></div>

																	</div>
																</div>
															</div>

														</div>
													</div>

												</div>
											</div>


										</div>


									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default VitalSignsAdd;
