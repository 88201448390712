import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';

function Mostprescribedmedcine() {
    const [dataFlag, setDataFlag] = useState(false);
    const [mostPrescribedMedicine, setMostPrescribedMedicine] = useState([])
    const [mostPrescribedTreatments, setMostPrescribedTreatments] = useState([])
    useEffect(() => {
        const PostData = {
            hospitalId: localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/mostPrescribedMedicineDetails', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setMostPrescribedMedicine(response.data.result.mostPrescribedMedicineDetails);
                setMostPrescribedTreatments(response.data.result.mostPrescribedTreatmentDetails);

            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    return (
        <>

            <div className="patient-status-wrpr">
                <div className="col-md-12">
                    <div className="total_revnuheader pb-0">
                        <div className="">
                            <h2 className='total_revnuheadtxt'>Most Prescribed Medicine</h2>
                            <div className="top_precribdmedcn-wrpr top_precribdmedcn-height">
                                {mostPrescribedMedicine.length > 0 ?
                                    <>
                                        {mostPrescribedMedicine.map((data) => (
                                            <div className='top_precribdmedcn-sec'>
                                                {data.medicineName}
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <div className='todayAppScroll nodataFnd nodata-found_wrpr'>
                                        <p>No Most Prescribed Medicine Found </p>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className="">
                    <h2 className='total_revnuheadtxt'>Most Prescribed Treatments</h2>
                    <div className="top_precribdmedcn-wrpr">
                        {mostPrescribedTreatments.map((data) => (
                        <div className='top_precribdmedcn-sec'>
                            {data.procedureName}
                        </div>
                         ))} 
                    </div>
                </div> */}
                    </div>
                </div>
            </div>
            <div className="patient-status-wrpr">
                <div className="col-md-12">
                    <div className="total_revnuheader pb-0">
                        {/* <div className="">
                            <h2 className='total_revnuheadtxt'>Most Prescribed Medicine</h2>
                            <div className="top_precribdmedcn-wrpr">
                                {mostPrescribedMedicine.map((data) => (
                                    <div className='top_precribdmedcn-sec'>
                                        {data.medicineName}
                                    </div>
                                ))}
                            </div>
                        </div> */}
                        <div className="">
                            <h2 className='total_revnuheadtxt'>Most Prescribed Treatments</h2>
                            <div className="top_precribdmedcn-wrpr top_precribdmedcn-height">
                                {mostPrescribedTreatments.length > 0 ?
                                    <>
                                        {mostPrescribedTreatments.map((data) => (
                                            <div className='top_precribdmedcn-sec'>
                                                {data.procedureName}
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <div className='todayAppScroll nodataFnd nodata-found_wrpr'>
                                        <p>No Most Prescribed Treatments Found </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Mostprescribedmedcine