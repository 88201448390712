import React from 'react';
import Logo from '../images/media/logo.png';
function completedPlansPrint() {
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: '420px',
					height: '595px',
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '0px',
					marginBottom: '0px',
				}}
			>
				<table style={{ width: '100%', marginTop: '0px' }} border="0">
					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr height="80px">
												<td colspan="3" valign="middle" align="center">
													<div style={{ width: '13%', float: 'left' }}>
														<p
															style={{
																fontWeight: '400',
																fontSize: '16px',
																clear: 'both',
																width: '100%',
															}}
														>
															<span
																style={{
																	marginTop: '-5px',
																	display: 'inline-block',
																	width: '100%',
																}}
															>
																<img
																	src={Logo}
																	style={{
																		display: 'block',
																		width: 'auto',
																		height: '45px',
																	}}
																/>
															</span>
														</p>
													</div>
													<div
														class="practicePrintName "
														style={{ width: '87%', float: 'left' }}
													>
														<p
															style={{
																fontWeight: '600',
																fontSize: '16px',
																clear: 'both',
																width: '100%',
																marginBottom: '1px',
																marginTop: '0',
															}}
														/>
														<p
															style={{
																fontWeight: '400',
																fontSize: '12px!important',
																clear: 'both',
																width: '100%',
																margin: '0',
															}}
														>
															.
														</p>
													</div>
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>
											<tr>
												<td
													width="50%"
													colspan="2"
													align="left"
													style={{ fontSize: '12px', wordBreak: 'break-all' }}
												/>
												<td
													width="50%"
													align="right"
													style={{ fontSize: '12px', wordBreak: 'break-all' }}
												/>
											</tr>

											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Name : AAVANI MOHAN
												</td>
												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Date : 21, Dec 2020{' '}
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													MR No : 202003050048
												</td>
												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Phone No : 0000000000
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Age: 26
												</td>
												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Email Id :{' '}
												</td>
											</tr>
											<tr>
												<td
													colspan="2"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Sex : Male
												</td>
												<td
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												/>
											</tr>

											<tr>
												<td colspan="3">
													<hr color="#142536" style={{ margin: '0' }} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr style={{ borderBottom: '2px solid black' }}>
												<td style={{ fontSize: '14px', paddingBottom: '10px' }}>
													Completed Plan
												</td>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'right',
													}}
												>
													{' '}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
										<tbody>
											<tr style={{ fontSize: '13px', background: '#eae8e8' }}>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Procedure
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '20%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Notes
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Cost
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Tax
												</td>

												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '15%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Total
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr>
												<td
													style={{
														boxSizing: ' border-box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'left',
														fontSize: '12px',
														color: '#393939',
														lineHeight: '18px',
													}}
												>
													SCALING (001)
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '20%',
														display: 'inline-block',
														textAlign: 'left',
														fontSize: '12px',
														color: '#393939',
														lineHeight: '18px',
													}}
												>
													1
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontSize: '12px',
														color: '#393939',
														lineHeight: '18px',
													}}
												>
													500.00
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'right',
														fontSize: '12px',
														color: '#393939',
														lineHeight: '18px',
													}}
												>
													-
												</td>

												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontSize: '12px',
														color: '#393939',
														lineHeight: '18px',
													}}
												>
													500.00
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											{/* <tr class="dentalProPlaned">
												<td>
													<div
														class="taxValueTable"
														style={{
															width: '42%',
															marginTop: '-6px',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
															color: '#393939',
														}}
													/>

													<div
														style={{
															width: '56%',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
														}}
													>
														<div
															class="estimatedPrice txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																paddingTop: '15px',
																color: ' #4a4a4a',
															}}
														>
															Grand Total (INR) :&nbsp;
															<span
																style={{
																	width: '75px',
																	display: 'inline-block',
																	verticalAlign: 'top',
																}}
															>
																500.00
															</span>
														</div>
													</div>
												</td>
											</tr> */}
											<tr>
												<td>
													<div class="printOutSettingContent">
														<table width="100%" cellspacing="0">
															<tbody>
																<tr class="dentalProPlaned">
																	<td
																		style={{ paddingTop: '15px', fontSize: '10px' }}
																	>
																		The Final treatment charges may vary depending
																		on any unseen treatments included or
																		modification in treatment plan.
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td>
								<div class="printOutFooterSec">
									<table style={{ width: '100%' }}>
										<tbody>
											<tr>
												<td
													width="25%"
													colspan="2"
													align="left"
													style={{ wordBreak: 'break-all' }}
												>
													<div align="justify" style={{ fontSize: '10px' }}>
														testclinic@ida.com{' '}
													</div>
												</td>
												<td
													width="50%"
													align="right"
													style={{ fontSize: '10px', wordBreak: 'break-all' }}
												>
													Dr. Manu
												</td>
											</tr>

											<tr>
												<td colspan="3">
													<div align="justify" class="practiceFootBtm">
														Working time
													</div>
												</td>
											</tr>
											<tr>
												<td colspan="3" style={{ paddingTop: '10px' }} />
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
									}}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default completedPlansPrint;
