import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Add from '../images/icons/Add_img.png';
import useAutocomplete from 'use-autocomplete';
import moment from 'moment';
import Datepicker from 'react-datepicker';
import Delete from '../images/icons/inactive.png';
import { callingAPI } from '../config';
import Webcam from '../Patients/webcam';
import Editicon from '../images/icons/edit-bg.png';
import WebcamCapture from './webcam';
import AWS from 'aws-sdk';
import Loader from '../Common/loader';
import girl from '../images/media/noImage.png';
import ImageUploadEdit from '../images/icons/editing.png';
import Youtube from '../images/icons/youtube.png';
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
var s3;
var fileNameUpload;
function AddPatient() {
	const [popUpDoctorList, setPopUpDoactorList] = useState(false);
	const [popaler, setpopaler] = useState([]);
	const [memberIdNew, setMemberIdNew] = useState('');
	const [getOldMr, setOldMr] = useState('');
	const [getLangList, setLangList] = useState([]);
	const [gethidLngId, sethidLngId] = useState('');
	const [getDobAlert, setDobAlert] = useState(false);
	const [getAgeAlert, setAgeAlert] = useState(false);
	const [getMrFlag, setMrFlag] = useState(true);
	const [isData1, setIsData1] = useState(true);
	const [getHospitalListByDoctor, setHospitalListByDoctor] = useState([]);
	const [today, setToday] = useState(moment(new Date()).format('/yyyy/MM/DD/'));
	const [dob, setFromDate] = useState('');
	const [state, setState] = useState({ imageName: '', mobileNumber: '', phone: '', ageId: '' });
	const [file, setFile] = useState('');
	const [getMrNoPrefix, setMrNoPrefix] = useState('');
	const [imageName, setImageName] = useState('');
	const [imagePreview, setImagePreview] = useState(girl);
	const [areaCode, setAreaCode] = useState('+91');
	const [mobileNumber, setMobileNumber] = useState(null);
	const [mrNo, setMrNo] = useState('');
	const [patientFirstName, setPatientFirstName] = useState('');
	const [patientLastName, setPatientLastName] = useState('');
	const [patientGender, setPatientGender] = useState('');
	const [emailId, setEmailId] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [patientBloodGroup, setPatientBloodGroup] = useState('');
	const [patientAddress, setPatientAddress] = useState('');
	const [web, setWeb] = useState('');
	const [city, setCity] = useState('');
	const [country, setCountry] = useState('');
	const [zip, setZip] = useState('');
	const [phone, setPhone] = useState('');
	const [ageId, setAgeId] = useState('');
	const [patientAadharNo, setPatientAadharNo] = useState('');
	const [isSmsAlert, setIsSmsAlert] = useState('');
	const [isEmailAlert, setIsEmailAlert] = useState('');
	const [freeArticleData1, setFreeArticleData1] = useState([]);
	const [freeArticleData12, setFreeArticleData12] = useState([]);
	const [freeArticleData123, setFreeArticleData123] = useState([]);
	const [picture, setPicture] = useState(null);
	const [imgData, setImgData] = useState(null);
	const [getLang, setLang] = useState([]);
	const [addValue, setAddValue] = useState([]);
	const [memberName, setMemberName] = useState('');
	const [memberAge, setMemberAge] = useState('');
	const [memberGender, setMemberGender] = useState('');
	const [getHospitalNameChange, hospitalNameChange] = useState(localStorage.getItem("hospitalId"));
	const { register, handleSubmit } = useForm();
	const [textState, setTextState] = useState('');
	const [setlangArray, getlangArray] = useState([]);
	const [completions] = useAutocomplete(textState, setlangArray);
	const [addValue1, setAddValue1] = useState([]);
	const [memberName122, setMemberName122] = useState('');
	const [memberName1223, setMemberName1223] = useState('');
	const [freeArticleData1234, setFreeArticleData1234] = useState([]);
	const [freeArticleData12345, setFreeArticleData12345] = useState([]);
	const [freeArticleData123456, setFreeArticleData123456] = useState([]);
	const [freeArticleData1234567, setFreeArticleData1234567] = useState([]);
	const [isData] = useState(0);
	const [image, setImage] = useState('');
	const [base64, setBase64] = useState('');
	const [display, setDisplay] = useState(false);
	const [getLastMrNo, setLastMrNo] = useState('');
	const [capturedData, setData] = useState({});
	const [display1, setDisplay1] = useState(false);
	const [getAgeMonth, setAgeMonth] = useState("Year");
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const handleImageUploadOnChange = (e) => {
		if (e.target.files[0].size > 3000000) {
			alert("Image size should less than 3 MB");
			return false;
		}
		else if (!e.target.files[0].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
			alert("Please attach jpg,jpeg or png files");
			return false;
		}
		else {
			setFile(e.target.files[0]);
			setImageName(e.target.files[0].name);
			setState({
				imageName: e.target.files[0].name,
			});

			setBase64(URL.createObjectURL(e.target.files[0]));
			setDisplay1(false)
			//	document.getElementById("qazId").style.display = 'none'
			document.getElementById("imageUploadId").style.display = 'block'
		}
	};
	const ageMonth = (e) => {
		setAgeMonth(e.target.value)
		setState({
			ageId: "",
		});
	};
	const onCaptureHandler = (captureData) => {
		setData(captureData);
		// alert(captureData);
	};
	const imageS3Save = async () => {
		setIsData1(false)
		if (Object.keys(capturedData).length === 0) {
			var S3BucketMainFolder =
				process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
			var fullFilePath = S3BucketMainFolder + '/' + imageName;

			await s3.upload(
				{
					Key: fullFilePath,
					Body: file,
					ACL: 'public-read',
				},
				(err, data) => {
					if (err) {
						alert('There was an error uploading your photo: ' + err.message);
						return null;
					} else {
						setIsData1(true)
						// alert("File Uploaded Successfully")
					}
				}
			);
		} else {
			await s3.putObject(capturedData, (err, data) => {
				if (err) {
					alert('There was an error uploading your photo: ' + err.message);
				} else {
					setIsData1(true)
					// alert('File Uploaded Successfully');
				}
			});
		}
	};
	const categoryTypeNewChange = (e, i) => {
		const { name, value } = e.target;
		const values = [...addValue];
		values[i] = { ...values[i], [name]: value };
		setAddValue(values);
	};
	const webCamImageFunction = (image, base64) => {
		setImage(image);
		setBase64(base64);
		setState({
			isData: 0,
		});
		document.getElementById("imageUploadId").style.display = 'block'
	};
	const EditiconShow = () => {
		document.getElementById('editiconId').style.display = 'none';
		document.getElementById('mrNo').style.display = 'block';
		document.getElementById('mrNoId').style.display = 'none';
		document.getElementById("mrBorderId").style.display = 'none'
	};
	const add = () => {
		// alert('Opening Webcam');
		setState({
			isData: 1,
		});

		setDisplay1(false);
	};
	const changeImage = () => {
		setDisplay(true);
		setDisplay1(true)
		setState({
			isData: 0,
		});
		document.getElementById('imageUploadId').style.display = 'none'
	};

	const removeAchievementItem = (e, i) => {
		let newFormValues = [...addValue];
		newFormValues.splice(i, 1);
		setAddValue(newFormValues);
	};
	const removeLanguage = (e, i) => {
		let newFormValues1 = [...addValue1];
		newFormValues1.splice(i, 1);
		setAddValue1(newFormValues1);
	};
	const onDescChange1422 = (event) => {
		const { name, value } = event.target;
		setMemberName122(event.target.value);
	};
	const setFreeArticleData234 = (id, itemName, mode, dropdownId) => {
		// $("#" + mode).val(itemName)
		document.getElementById(mode).value = itemName;
		setMemberName1223(id);
		sethidLngId(id)
		setMemberName122(document.getElementById('freeArticleData1234').value);

		//$("#" + dropdownId).hide()
		document.getElementById(dropdownId).style.display = 'none';
	};
	const setFreeArticleData2345 = (id) => {
		window.sessionStorage.setItem('memberId', id);
		popup(id)
		// localStorage.setItem('hospitalId', getHospitalNameChange);
		// window.location.href = '/patients/patientconsult/Edit';
	};
	const setFreeArticleData23456 = (id) => {
		//	alert(id)
		window.sessionStorage.setItem('memberId', id);
		popup(id)
		// localStorage.setItem('hospitalId', getHospitalNameChange);
		// window.location.href = '/patients/patientconsult/Edit';
	};
	const setFreeArticleData234567 = (id) => {
		//	alert(id)

		window.sessionStorage.setItem('memberId', id);
		popup(id)
		// localStorage.setItem('hospitalId', getHospitalNameChange);
		// window.location.href = '/patients/patientconsult/Edit';
	};
	const cityAutocomplete234 = (mode, dropdownId) => {
		const apiJson = {
			//  searchKeyword: $("#" + mode).val()
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/autoCompleteGetLanguages', apiJson).then((data) => {
				if (data.data.success === '1') {
					//  $("#" + dropdownId).show()
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData1234(data.data.result);
				} else {
					// $("#" + dropdownId).hide()
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
		}
	};
	const onDescChange = (event) => {
		const { name, value } = event.target;
		setMemberName(event.target.value);
	};
	const onDescChange1 = (event) => {
		const { name, value } = event.target;
		setMemberAge(event.target.value);
	};
	const onDescChange2 = (event) => {
		const { name, value } = event.target;
		setMemberGender(event.target.value);
	};
	const addAchievements = () => {
		//	document.getElementById('addFamId').style.display='block'
		let copy = JSON.parse(JSON.stringify(addValue));
		copy.push({ memberFamilyName: memberName, memberFamilyAge: memberAge, memberFamilyGender: memberGender });
		setAddValue(copy);
		setMemberAge('');
		setMemberName('');
	};
	const addAchievements1 = () => {
		//if()

		var flag = true;
		if (document.getElementById('freeArticleData1234').value === "0") {
			alert('Language Required');
			document.getElementById('freeArticleData1234').focus();
			flag = false;
		}
		var selected = addValue1.find((object) => object.memberName122 == memberName122)

		if (selected !== undefined) {
			if (selected.memberName122 == memberName122) {
				alert(selected.id + ' ' + 'is already added');
				document.getElementById('freeArticleData1234').focus();
				document.getElementById('freeArticleData1234').value = "0"
				setMemberName122("0")
				flag = false;
				// 		// alertlanguage()
				// 		// window.scrollTo(0, 0)
			}
		}
		// else if( === ''){
		// 	alert('Invalid Language');
		// 	document.getElementById('freeArticleData1234').focus();
		// 	flag = false;

		// }

		if (flag === true) {
			let copy1 = JSON.parse(JSON.stringify(addValue1));
			copy1.push({ memberName122: memberName122, id: document.getElementById("freeArticleData1234").options[document.getElementById("freeArticleData1234").selectedIndex].text });
			setAddValue1(copy1);
			setMemberName122('');
			sethidLngId('')
		}
	};
	const onChangePicture = (e) => {
		if (e.target.files[0]) {
			setPicture(e.target.files[0]);
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setImgData(reader.result);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};
	const setFreeArticleData = (id, itemName, mode, dropdownId) => {
		//  $("#" + mode).val(itemName)
		document.getElementById(mode).value = itemName;
		setFreeArticleData1([]);
		//$("#" + dropdownId).hide()
		document.getElementById(dropdownId).style.display = 'none';
	};
	const cityAutocomplete = (mode, dropdownId) => {
		const apiJson = {
			// searchKeyword: $("#" + mode).val()
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/autoCompleteGetCity', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData1(data.data.result);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
		}
	};
	const setFreeArticleData2 = (id, itemName, mode, dropdownId) => {
		//  $("#" + mode).val(itemName)
		document.getElementById(mode).value = itemName;
		setFreeArticleData12([]);
		//$("#" + dropdownId).hide()
		document.getElementById(dropdownId).style.display = 'none';
	};
	const cityAutocomplete2 = (mode, dropdownId) => {
		const apiJson = {
			//  searchKeyword: $("#" + mode).val()
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/autoCompleteGetState', apiJson).then((data) => {
				if (data.data.success === '1') {
					//  $("#" + dropdownId).show()
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData12(data.data.result);
				} else {
					//  $("#" + dropdownId).hide()
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
		}
	};
	const setFreeArticleData23 = (id, itemName, mode, dropdownId) => {
		document.getElementById(mode).value = itemName;
		// $("#" + mode).val(itemName)
		setFreeArticleData123([]);

		document.getElementById(dropdownId).style.display = 'none';
		//$("#" + dropdownId).hide()
	};
	const cityAutocomplete23 = (mode, dropdownId) => {
		const apiJson = {
			//searchKeyword: $("#" + mode).val()
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/autoCompleteGetCountry', apiJson).then((data) => {
				if (data.data.success === '1') {
					// $("#" + dropdownId).show()
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData123(data.data.result);
				} else {
					//  $("#" + dropdownId).hide()
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
		}
	};
	const addPatientSave = async () => {
		setIsData1(false)

		var isMrNoEdit = false
		if (mrNo === getOldMr) {
			isMrNoEdit = false
		}
		else {
			isMrNoEdit = true
		}
		const apiJson1 = {
			"hospitalId": getHospitalNameChange,
			"mrNo": mrNo,
			"memberId": "",
			"Newuser": "1"
		};
		callingAPI('addPatient/checkMrNo', apiJson1).then((data) => {
			if (data.data.success === '1') {
				var langData = [];
				if (addValue1.length === 0) {
					// memberName1223
					// langData.push(memberName122)
					if (memberName122 !== "0" || 0) {
						langData.push(memberName122);
					}

				} else if (memberName122.length === 0) {
					for (var i = 0; i < addValue1.length; i++) {
						var y = addValue1[i].memberName122;
						langData.push(y);
						// langData.push(memberName1223)
					}
				}
				else if (addValue1.length > 0 && memberName122.length !== 0) {
					for (var i = 0; i < addValue1.length; i++) {
						var y = addValue1[i].memberName122;
						langData.push(y);

					}
					// langData.push(memberName122)
					if (memberName122 !== "0" || 0) {
						langData.push(memberName122);
					}

				}


				// var langData = [];
				// for (var i = 0; i < addValue1.length; i++) {
				// 	var y = addValue1[i].memberName122;
				// 	langData.push(y);
				// }
				var flag = true;
				//Mobile Number must be 10 Integers
				var characters = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				//var email = $("#emailId").val();
				var email = document.getElementById('emailId').value;
				var splitemail = email.split(/\r|\r\n|\n/);
				var emailcount = splitemail.length;
				setIsData1(true)
				if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChange === '') {
					alert('Hospital Required');
					document.getElementById('hospitalNameId').focus();

					flag = false;
				}
				else if (document.getElementById('mobileNumber').value === '') {
					alert('Mobile Number Required');
					document.getElementById('mobileNumber').focus();
					flag = false;
				}
				else if (document.getElementById('mobileNumber').value.length < 10) {
					alert('Mobile Number must be 10 digits');
					document.getElementById('mobileNumber').focus();
					flag = false;
				}
				else if (mrNo === '') {
					alert('MR Number is Required');
					document.getElementById('mrNo').focus();
					flag = false;
				} else if (patientFirstName === '') {
					alert('First Name Required');
					document.getElementById('patientFirstName').focus();
					flag = false;
				}
				else if (patientGender === '') {
					alert('Gender Required ');
					flag = false;
				}
				else if ((document.getElementById('dobId').value === '' || dob === null) && getDobAlert === true) {
					alert('DOB Required');
					document.getElementById('dobId').focus();
					flag = false;
					// if(document.getElementById('dobId').value === ''){
					// 	alert('DOB Required');
					// 	document.getElementById('dobId').focus();
					// 	flag = false;
					// }
				}
				else if (document.getElementById('ageId').value === '') {
					alert('Age Required');
					document.getElementById('ageId').focus();
					flag = false;
				} else if (
					characters.test(splitemail[emailcount - 1]) === false &&
					document.getElementById('emailId').value !== ''
				) {
					alert('Invalid Email Id');
					//$("#emailId").focus();
					document.getElementById('emailId').focus();
					flag = false;
				}
				var selected = addValue1.find((object) => object.memberName122 === memberName122);
				if (selected !== undefined) {
					if (
						selected.memberName122 === memberName122
						// document.getElementById("freeArticleData1234").value
					) {
						alert(selected.id + " " + "is already added");
						document.getElementById("freeArticleData1234").focus();
						document.getElementById("freeArticleData1234").value = "0";
						// selected.memberName122 = ""
						// selected.id = ""
						setMemberName122("0");
						//   setLoad(true);
						flag = false;
						// 		// alertlanguage()
						// 		// window.scrollTo(0, 0)
					}
				}

				if (flag === true) {
					//document.getElementById('addPatientSaveId').style.display = 'none';
					setIsData1(false)
					//document.getElementById('addPatientSaveIdDemo').style.display = 'block';
					const apiJson = {
						hospitalId: getHospitalNameChange,
						siteId: '',
						secUserAreaCode: areaCode,
						secUserMobileNumber: document.getElementById('mobileNumber').value,
						mrNo: mrNo,
						memberFirstName: patientFirstName,
						memberLastName: patientLastName,
						memberGender: patientGender,
						secUserEmailId: emailId,
						memberDateOfBirth: moment(dob).format('yyyy-MM-DD'),
						memberBloodGroup: patientBloodGroup,
						memberAddress: patientAddress,
						memberCity: document.getElementById('freeArticleData1').value,
						memberState: document.getElementById('freeArticleData12').value,
						memberCountry: document.getElementById('freeArticleData123').value,
						memberZipCode: zip,
						memberPhoneNumber: document.getElementById('phone').value,
						memberImageName: image ? image : imageName,
						memberIsSmsAlert: isSmsAlert !== '' ? 'true' : 'false',
						memberIsEmailAlert: isEmailAlert !== '' ? 'true' : 'false',
						memberAadharNo: patientAadharNo,
						memberLanguages: langData.toString().replace(/[\[\]']/g, ''),
						memberFamily: addValue,
						isMrNoEdit: isMrNoEdit
					};

					callingAPI('addPatient/addPatientNew', apiJson).then((data) => {
						if (data.data.success === '1') {
							imageS3Save();
							setIsData1(true)
							callingAPI('commonUserFunctions/createAppointmentMail', data.data.result)
								.then((response) => {
									if (response.data.success === '0') {
										//alert('error message');
									}
									if (response.data.success === '1') {
										//	alert('sms email success');
									}
								})
								.catch();
							// 
							alert('Patient Details Saved Successfully');
							// window.location.reload();
							window.location.href = '/patients/patientListing';
						} else {
							alert(data.data.errorMessage);
							setIsData1(true)
						}
					});

				}


			} else {
				setIsData1(true)
				alert(data.data.errorMessage);
				setMrNo(getLastMrNo)
				document.getElementById('mrNo').focus()
				//	alert("dddddddddddd")
			}
		});


	};
	//async function fetchMyAPI() {
	const fetchMyAPI = () => {
		const apiJson = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('patientsListing/getHospitalListByDoctor', apiJson).then((data) => {

			if (data.data.success === '1') {
				//fetchMyAPI();
				setHospitalListByDoctor(data.data.result);
				if (data.data.result.length === 1) {

					hospitalNameChange(localStorage.getItem("hospitalId"))
					autoCompleteGetMrNo(localStorage.getItem("hospitalId"))
				}
				else {

					// autoCompleteGetMrNo(localStorage.getItem("hospitalId"))
					if (window.sessionStorage.getItem('role') !== 'ROLE_DOCTOR') {
						autoCompleteGetMrNo(localStorage.getItem("hospitalId"))
						hospitalNameChange(localStorage.getItem("hospitalId"))
					}
					else {
						autoCompleteGetMrNo(data.data.result[0].hospitalId);
						hospitalNameChange(data.data.result[0].hospitalId)
					}
				}
				// setCount(data.data.count);
			}
		});
	}




	const test1 = (data) => {
		if (data != "") {
			autoCompleteGetMrNo(data)
			hospitalNameChange(data)
		}
	}
	const test2 = (data) => {
		setMrFlag(true)

		if (data !== getHospitalNameChange) {
			//alert("d")

			setMrNo('')
			autoCompleteGetMrNo(data)
			setState({
				mobileNumber: '',
				ageId: '',
				phone: ''
			});
			setPatientFirstName('')
			setPatientLastName('')
			setPatientGender('')
			setEmailId('')

			setFromDate('')
			setPatientBloodGroup('')
			setPatientAddress('')

			document.getElementById("mrBorderId").style.display = 'block'
			document.getElementById("freeArticleData1").value = ""
			document.getElementById("freeArticleData12").value = ""
			document.getElementById("freeArticleData123").value = ""

			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			document.getElementById('isSmsAlert').checked = false;
			document.getElementById('isEmailAlert').checked = false;

			setZip('')

			setIsSmsAlert('')
			setIsEmailAlert('')
			setPatientAadharNo('')

			setAddValue1([])
			setAddValue([])

		}
		document.getElementById("mobileNumber").focus()
	}
	//	const hospitalNameChange1 = (data) => {
	const hospitalNameChange1 = async (data) => {
		await test1(data);
		await test2(data);


	}

	const autoCompleteGetMrNo = (data) => {
		const apiJson = {
			"hospitalId": data,
			"memberId": "0"
		};
		setIsData1(false)
		callingAPI('calenderListing/autoCompleteGetMrNo', apiJson).then((response) => {
			if (response.data.success === "1") {
				//isMrNoExist
				if (response.data.result[0].isMrNoExist === false) {
					setLastMrNo(response.data.result[0].mrNo)
					setIsData1(true)
				}
				// else {
				// 	autoCompleteGetMrNo(data)
				// }

				// setLastMrNo(data.data.result[0].mrNo + 1);
				// setMrNoPrefix(data.data.result[0].mrNoPrefix);
				// if(data.data.result[0].mrNoPrefix !==""){

				// 	setToday(moment(new Date()).format('/yyyy/MM/DD/'))
				// }
				// else{

				// 	setToday('0')
				// }

			}
			//  else {
			// 	//	alert(data.data.errorMessage);
			// }
		});
	}

	useEffect(() => {
		window.sessionStorage.setItem("qaz", "none");
		window.sessionStorage.setItem("qaz1", "none");
		fetchMyAPI();
		langDropDown()
		document.getElementById("mobileNumber").focus()
		// if (window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN') {
		autoCompleteGetMrNo(localStorage.getItem("hospitalId"))
		// }
		//autoCompleteGetMrNo()
	}, []);
	const cancel = () => {
		// window.location.href = '/patients/appointment';
		window.location.reload();
	};
	const ageChange1 = (val) => {

	};
	const handleChange = (event) => {

		if (getMrFlag === true) {
			var flag1 = true;
			if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChange === '') {
				alert('Please Select Hospital');
				setMrNo('')
				document.getElementById('hospitalNameId').focus();
				flag1 = false;
			} else {
				setMrNo(getLastMrNo);
				setOldMr(getLastMrNo)
				document.getElementById("mrBorderId").style.display = 'none'
				setMrFlag(false);
			}

		}

		if (event.target.id === "ageId") {
			if (getAgeMonth === "Year") {
				if (document.getElementById("ageId").value < 1 && document.getElementById("ageId").value !== "") {
					alert("Please enter value greater than zero")
					event.target.value = ""
				} else {
					var age = document.getElementById('ageId').value * 31556926000; //in Millis
					var dateOfBirth = new Date(new Date().getTime() - age);
					if ((0 == dateOfBirth.getFullYear() % 4) && (0 != dateOfBirth.getFullYear() % 100) || (0 == dateOfBirth.getFullYear() % 400)) {
						setFromDate(dateOfBirth);
					}
					else {
						setFromDate(dateOfBirth);
					}
					setState({
						ageId: event.target.value,
					});
				}
			}
			else if (getAgeMonth === "Month") {
				if (document.getElementById("ageId").value < 1 && document.getElementById("ageId").value !== "") {
					alert("Please enter value greater than zero")
					event.target.value = ""
				}
				else if (document.getElementById("ageId").value <= 12) {

					var month = document.getElementById('ageId').value * 2.628e+9; //in Millis
					var dateOfBirth = new Date(new Date().getTime() - month);
					// if ((0 == dateOfBirth.getFullYear() % 4) && (0 != dateOfBirth.getFullYear() % 100) || (0 == dateOfBirth.getFullYear() % 400)){
					setFromDate(dateOfBirth);
					// }
					// else{
					// setFromDate(dateOfBirth);
					// }
					setState({
						ageId: event.target.value,
					});
				}
				else {
					alert("Please enter value less than twelve")
					event.target.value = ""
				}

			}
			else if (getAgeMonth === "Day") {
				// alert("age2222")
				// flag1 = false
				if (document.getElementById("ageId").value < 1 && document.getElementById("ageId").value !== "") {
					alert("Please enter value greater than zero")
					event.target.value = ""
				} else {
					var month = document.getElementById('ageId').value * 8.64e+7; //in Millis
					var dateOfBirth = new Date(new Date().getTime() - month);
					// if ((0 == dateOfBirth.getFullYear() % 4) && (0 != dateOfBirth.getFullYear() % 100) || (0 == dateOfBirth.getFullYear() % 400)){
					setFromDate(dateOfBirth);
				}
			}
		}
		var flag = true;
		const re = /^[0-9\b]+$/;
		if (re.test(event.target.value) || event.target.value === '') {
			if (event.target.id === 'mobileNumber') {
				document.getElementById('mobileNumber').value = event.target.value;
				setMobileNumber(document.getElementById('mobileNumber').value);
			} else if (event.target.id === 'phone') {
				document.getElementById('phone').value = event.target.value;
				setMobileNumber(document.getElementById('phone').value);
			} else if (event.target.id === 'ageId') {
				document.getElementById('ageId').value = event.target.value;
				//setMobileNumber(document.getElementById('ageId').value);
			}
		} else {
			if (event.target.id === 'mobileNumber') {
				document.getElementById('mobileNumber').value = mobileNumber;
				//alert('Only Numbers Accepted');
				flag = false;
			} else if (event.target.id === 'phone') {
				document.getElementById('phone').value = phone;
				//alert('Only Numbers Accepted');
				flag = false;
			} else if (event.target.id === 'ageId') {
				document.getElementById('ageId').value = ageId;
				//alert('Only Numbers Accepted');
				flag = false;
			}
		}
		const { name, value } = event.target;
		//const [ mobileNumber, setMobileNumber ] = useState('');
		setState((prevState) => ({ ...prevState, [name]: value }));
	};
	const showMore = (event) => {
		document.getElementById('showMoreId').style.display = 'block';
		document.getElementById('showMoreLink').style.display = 'none';
	};

	const cityAutocomplete2345 = (mode, dropdownId) => {
		var flag = true;
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChange === '') {
			alert('Please Select Hospital');
			setMrNo('')
			document.getElementById('hospitalNameId').focus();
			flag = false;
		}
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
		}
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';

		const apiJson = {
			//  searchKeyword: $("#" + mode).val()
			//searchKeyword: document.getElementById(mode).value,
			hospitalId: getHospitalNameChange,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/patientAutoComplteByNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					//  $("#" + dropdownId).show()
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData12345(data.data.result);
				} else {
					// $("#" + dropdownId).hide()
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	const cityAutocomplete23456 = (mode, dropdownId) => {
		if (getMrFlag === true) {
			setMrNo(getLastMrNo);
			setOldMr(getLastMrNo)
			document.getElementById("mrBorderId").style.display = 'none'
			setMrFlag(false);
		}
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
		}
		document.getElementById('locationSearch12345').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';
		const apiJson = {
			//  searchKeyword: $("#" + mode).val()
			//searchKeyword: document.getElementById(mode).value,
			hospitalId: getHospitalNameChange,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/patientAutoComplteByNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					//  $("#" + dropdownId).show()
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData123456(data.data.result);
				} else {
					// $("#" + dropdownId).hide()
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	const cityAutocomplete234567 = (mode, dropdownId) => {
		if (getMrFlag === true) {
			setMrNo(getLastMrNo);
			setOldMr(getLastMrNo)
			document.getElementById("mrBorderId").style.display = 'none'
			setMrFlag(false);
		}
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
		}
		document.getElementById('locationSearch12345').style.display = 'none';
		document.getElementById('locationSearch123456').style.display = 'none';
		const apiJson = {
			//  searchKeyword: $("#" + mode).val()
			//searchKeyword: document.getElementById(mode).value,
			hospitalId: getHospitalNameChange,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('addPatient/patientAutoComplteByNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					//  $("#" + dropdownId).show()
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData1234567(data.data.result);
				} else {
					// $("#" + dropdownId).hide()
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	//
	const ageChange = (date) => {
		// if(date === ""){
		// 	setDobAlert(true)
		// }
		if (date !== null && date !== "null") {
			setFromDate(date);
			var today = new Date();
			var birthDate = date;
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			setState({
				ageId: age,
			});
			//	setAge(age);
		}
		else {
			setFromDate('');
			setState({
				ageId: '',
			});
		}
	};
	const spanColour = (e) => {
		//alert("ssssssss")
		if (e.target.id === 'dobGreen') {
			setDobAlert(true)
			// alert("1")
			document.getElementById('dobDashId').style.display = 'block';
			document.getElementById('ageDashId').style.display = 'none';
			document.getElementById('ageGreen').classList.remove('tabActive');
			document.getElementById('dobGreen').classList.add('tabActive');
			if (document.getElementById('ageId').value !== '') {
				//	alert("ddd")
				// var age = document.getElementById('ageId').value * 31556926000; //in Millis
				// var dateOfBirth = new Date(new Date().getTime() - age);
				// setFromDate(dateOfBirth);
				setFromDate('')
				setState({
					ageId: '',
				});
			}
			else {
				setFromDate('')
			}

			// var today = new Date();
			// if (dob != '') {
			// 	//alert("11111111")
			// 	var birthDate = new Date(dob);
			// 	var age = today.getFullYear() - birthDate.getFullYear();
			// 	var m = today.getMonth() - birthDate.getMonth();
			// 	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			// 		age--;
			// 	}
			// 	//	document.getElementById("ageId").value= age
			// 	setState({
			// 		ageId: age,
			// 	});
			// }
			// else{
			// 	setState({
			// 		ageId: '',
			// 	});
			// }

			//return age;

			//alert("dobGreen")
		} else {
			setAgeAlert(true)
			// alert("2")
			setFromDate('');
			document.getElementById('dobDashId').style.display = 'none';
			document.getElementById('ageDashId').style.display = 'block';
			document.getElementById('dobGreen').classList.remove('tabActive');
			document.getElementById('ageGreen').classList.add('tabActive');
			if (document.getElementById('dobId').value === '') {
				//	alert("ddd")
				var age = document.getElementById('ageId').value * 31556926000; //in Millis
				var dateOfBirth = new Date(new Date().getTime() - age);
				setFromDate(dateOfBirth);
				// setState({
				// 	ageId: '',
				// });
			}
			else {
				// setFromDate(dob)
				setState({
					ageId: '',
				});

			}
			// var age = document.getElementById('ageId').value * 31556926000; //in Millis
			// var dateOfBirth = new Date(new Date().getTime() - age);
			// setFromDate(dateOfBirth);

			// var today = new Date();
			// if (dob != '') {
			// 	//alert("222222")
			// 	var birthDate = new Date(dob);
			// 	var age = today.getFullYear() - birthDate.getFullYear();
			// 	var m = today.getMonth() - birthDate.getMonth();
			// 	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			// 		age--;
			// 	}
			// 	setState({
			// 		ageId: age,
			// 	});
			// 	//document.getElementById("ageId").value = age
			// }
			// else{
			// 	setState({
			// 		ageId: '',
			// 	});
			// }
			//return age;
		}
	};

	const clearData = (e) => {
		document.getElementById('locationSearch12345').style.display = 'none';
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';
		document.getElementById('locationSearch12').style.display = 'none';
		document.getElementById('locationSearch1').style.display = 'none';
		document.getElementById('locationSearch123').style.display = 'none';

		document.getElementById('locationSearch1234').style.display = 'none';
		//ageCalculator()
	}
	const langDropDown = () => {
		let PostData = {
			"searchKeyword": ""
		};
		callingAPI('addPatient/autoCompleteGetLanguages', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setLangList(data.data.result);
				}
			})

	}
	// 	const ageCalculator = (e) => {
	// 		//alert(dob)
	// 		var dateString = document.getElementById("dobId").value
	// 		var now = new Date();
	// 		var today = new Date(now.getYear(),now.getMonth(),now.getDate());

	// 		var yearNow = now.getYear();
	// 		var monthNow = now.getMonth();
	// 		var dateNow = now.getDate();

	// 		var dob = new Date(dateString.substring(6,10),
	// 						   dateString.substring(0,2)-1,
	// 						   dateString.substring(3,5)
	// 						   );

	// 		var yearDob = dob.getYear();
	// 		var monthDob = dob.getMonth();
	// 		var dateDob = dob.getDate();
	// 		var age = {};
	// 		var ageString = "";
	// 		var yearString = "";
	// 		var monthString = "";
	// 		var dayString = "";


	// 		var yearAge = yearNow - yearDob;

	// 		if (monthNow >= monthDob)
	// 		  var monthAge = monthNow - monthDob;
	// 		else {
	// 		  yearAge--;
	// 		  var monthAge = 12 + monthNow -monthDob;
	// 		}

	// 		if (dateNow >= dateDob)
	// 		  var dateAge = dateNow - dateDob;
	// 		else {
	// 		  monthAge--;
	// 		  var dateAge = 31 + dateNow - dateDob;

	// 		  if (monthAge < 0) {
	// 			monthAge = 11;
	// 			yearAge--;
	// 		  }
	// 		}

	// 		age = {
	// 			years: yearAge,
	// 			months: monthAge,
	// 			days: dateAge
	// 			};

	// 		if ( age.years > 1 ) yearString = " years";
	// 		else yearString = " year";
	// 		if ( age.months> 1 ) monthString = " months";
	// 		else monthString = " month";
	// 		if ( age.days > 1 ) dayString = " days";
	// 		else dayString = " day";


	// 		if ( (age.years > 0) && (age.months > 0) && (age.days > 0) )
	// 		  ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString + " old.";
	// 		else if ( (age.years == 0) && (age.months == 0) && (age.days > 0) )
	// 		  ageString = "Only " + age.days + dayString + " old!";
	// 		else if ( (age.years > 0) && (age.months == 0) && (age.days == 0) )
	// 		  ageString = age.years + yearString + " old. Happy Birthday!!";
	// 		else if ( (age.years > 0) && (age.months > 0) && (age.days == 0) )
	// 		  ageString = age.years + yearString + " and " + age.months + monthString + " old.";
	// 		else if ( (age.years == 0) && (age.months > 0) && (age.days > 0) )
	// 		  ageString = age.months + monthString + " and " + age.days + dayString + " old.";
	// 		else if ( (age.years > 0) && (age.months == 0) && (age.days > 0) )
	// 		  ageString = age.years + yearString + " and " + age.days + dayString + " old.";
	// 		else if ( (age.years == 0) && (age.months > 0) && (age.days == 0) )
	// 		  ageString = age.months + monthString + " old.";
	// 		else ageString = "Oops! Could not calculate age!";
	// 		alert(ageString)
	// 		return ageString;

	// }

	// showMore
	// showMoreId
	const popup = (memberId) => {
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') {
			window.sessionStorage.setItem('memberId', memberId);
			window.sessionStorage.setItem('patientDoctorId', window.sessionStorage.getItem('userId'));
			localStorage.setItem('hospitalId', getHospitalNameChange);
			window.sessionStorage.setItem('patientDoctorName', window.sessionStorage.getItem('userName'));
			// window.sessionStorage.setItem('patientDoctorSpecialation', popaler[index].specialization);
			window.location.href = '/patients/patientconsult/Edit';
		} else {
			setMemberIdNew(memberId);
			// setFlag(false);
			let PostData = {
				hospitalId: localStorage.getItem('hospitalId'),
				selectCombo: '',
				inputSearch: '',
			};
			callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
				if (response.data.success === '1') {
					// setFlag(true);
					setpopaler(response.data.result.doctorsListArray);
					setPopUpDoactorList(true);
				}
				else {
					alert("no doctors found")
				}
			});
		}
	};
	const closePopUp = () => {
		setPopUpDoactorList(false);
	};
	const hospitalpageChange = (index) => {
		if (index !== '') {
			window.sessionStorage.setItem('memberId', memberIdNew);
			window.sessionStorage.setItem('doctorId', popaler[index].doctorId);
			window.sessionStorage.setItem('medicineBranchId', popaler[index].medicineBranchId);
			window.sessionStorage.setItem('patientDoctorId', popaler[index].doctorId);
			localStorage.setItem('hospitalId', popaler[index].hospitalId);
			window.sessionStorage.setItem('patientDoctorName', popaler[index].doctorName);
			window.sessionStorage.setItem('patientDoctorSpecialation', popaler[index].specialization);
			window.location.href = '/patients/patientconsult/Edit';
		}
	};
	const abcA = (a) => {
		// setData(captureData);
		if (a === "true") {
			setState({
				isData: 0,
			});
			document.getElementById("imageUploadId").style.display = 'block'
		}
		setWeb(a)

	};
	return (
		<div className="coverWraper addPatientMainpg">
			<Header />
			{isData1 === false ? <Loader /> : ''}
			<div className="contentWraper contentWraperScroll whiteBg plAddPatient" onClick={(e) => clearData()}>
				<div className="container">
					<div className="innerContent">
						{/* ========== Youtube Begins here =========== */}
						{/* <div className=" pl_vdo_dpdwnAddPatient">
							<span>
								<a>
									<img src={Youtube} />{' '}
								</a>{' '}
							</span>
							<div className="selectLanguage" autoComplete="off" style={{ display: 'none' }}>
								<ul>
									<li>English</li>
									<li>Malayalam</li>
								</ul>
							</div>
						</div> */}
						{/* ========== Youtube Ends here =========== */}
						<div className="titleHeading">Add Patient</div>

						<div className="addpatientContent">
							<div className="row">
								<div className="col-md-2 dAlignCenterAddPatient">
									<div
										className="col-md-12 col-lg-12 practiceManagementList "
										style={{ textAlign: 'center' }}
									>
										<div
											className="filUploadInline dAlignCenterAddPatient"
											id="results1"
											style={{ textAlign: 'center' }}
										>
											<img
												// src={imagePreview||image||capturedData}
												src={base64 ? base64 : imagePreview || imagePreview ? imagePreview : base64}
												id="imageThumb1"
												className="webOpenImage1 plAddPatnt"
											/>

										</div>
										{/*  Image Upload begins */}
										<span class="changeImage changeImgPro AddPatientImgUpload" id="imageUploadId" title="Upload Images" style={{ display: "block" }} onClick={changeImage}>
											Image Upload</span>
										{/* Image Upload Ends */}

										<div className="webcamDiv" style={{ display: !display ? '' : 'block' }}>
											<div className="myCamera" id="my_camera" />
											<div className="webCamButton">
												{state.isData === 1 ? (
													<WebcamCapture
														onCapture={onCaptureHandler}
														webCamImage={webCamImageFunction}
														web={abcA}
													/>
												) : (
													''
												)}
												<span
													style={{ display: display1 ? '' : 'none' }}
													className="borderButton borderButtonSmall"
													onClick={add}
												>
													Take Snapshot
												</span>
												<span

													style={{ display: display1 ? '' : 'none' }}
													className="uploadButton borderButton  uploadButtonSmall1"
												>
													<input
														type="file"
														id="file1"
														onChange={(e) => handleImageUploadOnChange(e)}
													/>
													<label
														for="file1"
														className="uploadBtn"
														style={{ textAlign: 'center' }}
														onChange={(e) => handleImageUploadOnChange(e)}
													>
														Upload
													</label>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-10">
									<div className="appointmentForm">
										{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
											<div
												class="newAppoinmentRightList"
												style={{
													display:
														getHospitalListByDoctor.length === 1 ? 'none' : 'inline-block',
												}}
											>
												<label className="formLabel">
													Hospital Name<sup>*</sup>
												</label>
												<div className="appointmentFormList">
													<select
														name="hospitalNameId"
														className="formInput"
														id="hospitalNameId"
														autoComplete="off"
														//onChange={hospitalNameChange}
														value={getHospitalNameChange}
														onChange={(e) => hospitalNameChange1(e.target.value)}
														autoFocus
													>
														{/* {getHospitalListByDoctor.length !== 1 ? (
															<option value="">Select</option>
														) : (
															''
														)} */}

														{getHospitalListByDoctor.map((data, i) => (
															<option value={data.hospitalId}>{data.hospitalName}</option>
														))}
													</select>
												</div>
											</div>
										) : (
											''
										)}

										<div class="newAppoinmentRightList">
											<label className="formLabel">
												Mobile No<sup>*</sup>
											</label>
											<div className="appointmentFormList">
												<span class="mobileCountrySelect">
													<input
														type="text"
														value={areaCode}
														id="areaCode"
														name="areaCode"
														autoComplete="off"
														className="formInput"
														placeholder="Mobile Number"
														onChange={(e) => setAreaCode(e.target.value)}
														autoFocus
													/>
												</span>
												<span class="mobileNumberField">
													<input
														type="text"
														value={state.mobileNumber}
														id="mobileNumber"
														name="mobileNumber"
														placeholder="Mobile Number"
														maxlength="10"
														autoComplete="off"
														disabled=""
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}
														onChange={handleChange}
														className="input-design formInput"
														onKeyUp={() =>
															cityAutocomplete2345('mobileNumber', 'locationSearch12345')}
													/>
													<ul
														className="autoSearch1 autoSearch-p0 appointmentNumberSearch appointmentNumberSearchW {
"
														id="locationSearch12345"
														style={{ display: 'none' }}
													>
														{freeArticleData12345.map((freeArticleDataObj, i) => (
															<li
																onClick={() =>
																	setFreeArticleData2345(freeArticleDataObj.memberId)}
															>
																{freeArticleDataObj.memberDetailsWithMrNo}
															</li>
														))}
													</ul>
												</span>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">MR Number<sup>*</sup></label>
											<div className="appointmentFormList">
												<input
													style={{ display: 'none' }}
													type="text"
													className="formInput"
													value={mrNo}
													autoComplete="off"
													id="mrNo"
													name="mrNo"
													placeholder="MR Number"
													onChange={(e) => setMrNo(e.target.value)}
												/>
												{/* <span
																						id="mrNoId"
																						style={{ display: 'none' }}
																					>

																					</span> */}
												<input
													style={{ display: 'block', border: 'none' }}
													className="formInput"
													placeholder="MR Number"
													id="mrBorderId"
												/>
												<span class="mrNumberEdit" id="mrNoId" style={{ display: 'block' }}>

													{mrNo}
													<img
														title="Click here for edit Mr Number"
														id="editiconId"
														style={{ display: 'block' }}
														src={Editicon}
														onClick={(e) => EditiconShow()}
													/>
												</span>
											</div>
										</div>

										{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
											<div
												class="newAppoinmentRightList plEmptyLabel"
												style={{
													display:
														getHospitalListByDoctor.length === 1 ? 'inline-block' : 'none',
												}}
											>
												<label className="formLabel">&nbsp;</label>
											</div>
										) : (
											''
										)}

										{/* <div class="newAppoinmentRightList">
											<label className="formLabel">MR Number</label>
											<div className="appointmentFormList">
												<input
													type="text"
													className="formInput"
													value={mrNo}
													id="mrNo"
													name="mrNo"
													placeholder="MR Number"
													onChange={(e) => setMrNo(e.target.value)}
												/>
											</div>
										</div> */}
										{window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ? (
											<div class="newAppoinmentRightList plEmptyLabel">
												<label className="formLabel">&nbsp;</label>
											</div>
										) : (
											''
										)}
										<div class="newAppoinmentRightList">
											<label className="formLabel">
												First Name<sup>*</sup>
												{''}
												<span
													className="blue-text"
													id="newUsertextId"
													style={{
														color: 'green',
														display: 'block',
													}}
												>
													<b> </b>
												</span>
											</label>
											<div className="appointmentFormList">
												<input
													type="text"
													value={patientFirstName}
													id="patientFirstName"
													name="patientFirstName"
													placeholder="FIRST NAME"
													onChange={(e) => setPatientFirstName(e.target.value.toUpperCase())}
													className="input-design formInput"
													autoComplete="off"
													onKeyUp={() =>
														cityAutocomplete23456(
															'patientFirstName',
															'locationSearch123456'
														)}
												/>
												<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch appointmentNumberSearchW"
													id="locationSearch123456"
													style={{ display: 'none' }}
												>
													{freeArticleData123456.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData23456(freeArticleDataObj.memberId)}
														>
															{freeArticleDataObj.memberDetailsWithMrNo}
														</li>
													))}
												</ul>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">Last Name</label>
											<div className="appointmentFormList">
												<input
													type="text"
													value={patientLastName}
													id="patientLastName"
													name="patientLastName"
													placeholder="LAST NAME"
													onChange={(e) => setPatientLastName(e.target.value.toUpperCase())}
													className="input-design formInput"
													autoComplete="off"
													onKeyUp={() =>
														cityAutocomplete234567(
															'patientLastName',
															'locationSearch1234567'
														)}
												/>
												<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch appointmentNumberSearchW"
													id="locationSearch1234567"
													style={{ display: 'none' }}
												>
													{freeArticleData1234567.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData234567(freeArticleDataObj.memberId)}
														>
															{freeArticleDataObj.memberDetailsWithMrNo}
														</li>
													))}
												</ul>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">
												Gender<sup>*</sup>
											</label>
											<div className="appointmentFormList">
												<span className="radioSection1">
													<input
														name="patientLastName"
														type="radio"
														onChange={(e) => setPatientGender(e.target.value)}
														value="Male"
														id="radio1"
													/>
													<label for="radio1" />Male
												</span>
												<span className="radioSection1">
													<input
														name="patientLastName"
														onChange={(e) => setPatientGender(e.target.value)}
														type="radio"
														value="Female"
														id="radio2"
													/>
													<label for="radio2" />Female
												</span>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">Email Id</label>
											<div className="appointmentFormList">
												<input
													type="text"
													value={emailId}
													id="emailId"
													autoComplete="off"
													name="emailId"
													className="formInput"
													placeholder="Email Id"
													onChange={(e) => setEmailId(e.target.value)}
												/>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">
												<span
													class="addPatientTab "
													id="dobGreen"
													onClick={(e) => spanColour(e)}
												>
													DOB
												</span> / <span
													onClick={(e) => spanColour(e)}
													class="addPatientTab tabActive"
													id="ageGreen"
													autoComplete="off"
												>
													Age*
												</span>{' '}

											</label>

											<div
												className="appointmentFormList" autoComplete="off"
												style={{ display: 'none' }}
												id="dobDashId"
											>
												<Datepicker
													id="dobId"
													className="Datepicker pa2 inputField formInput"
													placeholderText="Date of Birth"
													calendarClassName="rasta-stripes"
													selected={dob}
													value={dob}
													maxDate={new Date()}
													dateFormat="dd/MM/yyyy"
													peekNextMonth
													showMonthDropdown
													showYearDropdown
													autoComplete="off"
													dropdownMode="select"
													onChange={(date) => ageChange(date)}
												/>
											</div>

											<div className="appointmentFormList" id="ageDashId">
												<div className='yearOfBirthInfo'>

													<span className="practiceContentText" style={{ width: "50%" }}>
														<select
															onChange={ageMonth}
															value={getAgeMonth}
															name="agemonth"
															id="agemonth"
															className="inputField formInput" style={{ height: "20px", marginTop: "0px" }}
														>
															<option value="Year">Year</option>
															<option value="Month">Month</option>
															<option value="Day">Day</option>


														</select>
													</span>
												</div>
												<input
													onChange={handleChange}
													value={state.ageId}
													type="text"
													className="formInput"
													id="ageId"
													maxlength="3"
													name="ageId"
													onKeyPress={(event) => {
														if (!/[0-9]/.test(event.key)) {
															event.preventDefault();
														}
													}}
													placeholder={getAgeMonth}
												/>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">Blood Group</label>
											<div className="appointmentFormList">
												<select
													className="formInput"
													value={patientBloodGroup}
													id="patientBloodGroup"
													name="patientBloodGroup"
													autoComplete="off"
													onChange={(e) => setPatientBloodGroup(e.target.value)}
												>
													<option value="">Select Blood Group</option>
													<option value="A+">A+</option>
													<option value="A-">A-</option>
													<option value="B+">B+</option>
													<option value="B-">B-</option>
													<option value="AB+">AB+</option>
													<option value="AB-">AB-</option>
													<option value="O+">O+</option>
													<option value="O-">O-</option>
												</select>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">Address </label>
											<div className="appointmentFormList">
												<input
													type="text"
													className="formInput"
													placeholder="Type your Address"
													value={patientAddress}
													id="patientAddress"
													name="patientAddress"
													autoComplete="off"
													onChange={(e) => setPatientAddress(e.target.value)}
												/>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">City</label>
											<div className="appointmentFormList">
												<input
													type="text"
													id="freeArticleData1"
													name="cityName"
													placeholder="Type your City"
													className="formInput"
													autoComplete="off"
													onKeyUp={() =>
														cityAutocomplete('freeArticleData1', 'locationSearch1')}
												/>
												<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
													id="locationSearch1"
													style={{ display: 'none' }}
												>
													{freeArticleData1.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData(
																	freeArticleDataObj.cityName,
																	freeArticleDataObj.cityName,
																	'freeArticleData1',
																	'locationSearch1'
																)}
														>
															{freeArticleDataObj.cityName}
														</li>
													))}
												</ul>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">State</label>
											<div className="appointmentFormList">
												<input
													type="text"
													id="freeArticleData12"
													name="stateName"
													placeholder="Type your State"
													autoComplete="off"
													className="formInput"
													onKeyUp={() =>
														cityAutocomplete2('freeArticleData12', 'locationSearch12')}
												/>
												<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
													id="locationSearch12"
													style={{ display: 'none' }}
												>
													{freeArticleData12.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData2(
																	freeArticleDataObj.stateName,
																	freeArticleDataObj.stateName,
																	'freeArticleData12',
																	'locationSearch12'
																)}
														>
															{freeArticleDataObj.stateName}
														</li>
													))}
												</ul>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">Country </label>
											<div className="appointmentFormList">
												<input
													type="text"
													id="freeArticleData123"
													name="countryName"
													placeholder="Type your Country"
													autoComplete="off"
													className="formInput"
													onKeyUp={() =>
														cityAutocomplete23('freeArticleData123', 'locationSearch123')}
												/>
												<ul
													className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
													id="locationSearch123"
													style={{ display: 'none' }}
												>
													{freeArticleData123.map((freeArticleDataObj, i) => (
														<li
															onClick={() =>
																setFreeArticleData23(
																	freeArticleDataObj.countryName,
																	freeArticleDataObj.countryName,
																	'freeArticleData123',
																	'locationSearch123'
																)}
														>
															{freeArticleDataObj.countryName}
														</li>
													))}
												</ul>
											</div>
										</div>
										<div class="newAppoinmentRightList">
											<label className="formLabel">Pin Code / Zip Code </label>
											<div className="appointmentFormList">
												<input
													type="text"
													maxlength="20"
													className="formInput"
													placeholder="Type your Pin Code / Zip Code"
													value={zip}
													id="zip"
													autoComplete="off"
													name="zip"
													onChange={(e) => setZip(e.target.value)}
												/>
											</div>
										</div>
										<br />
										{/* ==== Show More  ==== */}
										<div class="appoinmentShowMoreBtn">
											<a id="showMoreLink" onClick={() => showMore()}>
												Show More
											</a>
										</div>
										{/*  ====  Show More  ====*/}
										<div style={{ display: 'none' }} id="showMoreId">
											<div class="newAppoinmentRightList">
												<label className="formLabel">Phone No</label>
												<div className="appointmentFormList">
													<input
														type="text"
														onKeyPress={(event) => {
															if (!/[0-9]/.test(event.key)) {
																event.preventDefault();
															}
														}}
														className="formInput"
														placeholder="Phone No"
														// value={phone}
														maxlength="10"
														value={state.phone}
														autoComplete="off"
														id="phone"
														name="phone"
														onChange={handleChange}
													// onChange={(e) => setPhone(e.target.value)}
													/>
												</div>
											</div>
											{/* ================ */}
											<div class="newAppoinmentRightList" style={{ display: 'inline-flex' }}>
												<div className="plAlerts">
													<label class="label-text"> </label>
													<br />
													<input
														class="mr-2"
														//id="chkCreditCardFreeDelivery"
														type="checkbox"
														value={'isSmsAlert'}
														id="isSmsAlert"
														name="isSmsAlert"
														onChange={(e) => setIsSmsAlert(e.target.value)}
													/>
													<label className="label-text"> &nbsp; SMS Alert</label>
												</div>

												<div className="plAlerts">
													<label class="label-text"> </label>
													<br />
													<input
														class="mr-2"
														//id="chkCreditCardFreeDelivery"
														type="checkbox"
														value={'isEmailAlert'}
														id="isEmailAlert"
														name="isEmailAlert"
														onChange={(e) => setIsEmailAlert(e.target.value)}
													/>
													<label className="label-text"> &nbsp; Email Alert</label>
												</div>
											</div>
											{/* ================ */}
											<div class="newAppoinmentRightList">
												<label className="formLabel">Aadhar ID</label>
												<div className="appointmentFormList">
													<input
														type="text"
														className="formInput"
														placeholder="Aadhar ID"
														value={patientAadharNo}
														id="patientAadharNo"
														autoComplete="off"
														name="patientAadharNo"
														onChange={(e) => setPatientAadharNo(e.target.value)}
													/>
												</div>
											</div>
											{/* <br /> <br /> */}
											<div className="plResLang">
												<div className="homeProfileDetails">
													<div className="practiceManagementGrid profileForm">
														<div className="row rowMargin">
															{/* ------------------------------- */}
															<div className="col-md-6 col-lg-4 col-10 row-margin">
																<label className="labelTextField labelMarginBottom">
																	<span
																		className="addLanguage"

																	>
																		{' '}
																		+ Add Language
																	</span>
																</label>
																<div class="newAppoinmentRightList" style={{ width: "100%", padding: "0px" }}>
																	{/* <label className="formLabel">Blood Group</label> */}
																	<div className="appointmentFormList">
																		<select
																			className="formInput"
																			id="freeArticleData1234"
																			value={memberName122}
																			name={memberName122}
																			onChange={onDescChange1422}
																		>
																			<option value="0">Select Language</option>
																			{getLangList.map((obj, i) => (
																				<option value={obj.languageId}>{obj.languageDescription}</option>
																			))}

																		</select>
																	</div>
																</div>
																{/* <input
																	type="text"
																	id="freeArticleData1234"
																	value={memberName122}
																	name={memberName122}
																	onChange={onDescChange1422}
																	placeholder="Type your Language"
																	className="input-design formInput"
																	autoComplete="off"
																	onKeyUp={() =>
																		cityAutocomplete234(
																			'freeArticleData1234',
																			'locationSearch1234'
																		)}
																/> */}
																<input type="hidden" value={gethidLngId}
																	name={"hidLngId"} id="hidLngId" />
																<ul
																	className="autoSearch1 autoSearch-p0"
																	id="locationSearch1234"
																	style={{ display: 'none' }}
																>
																	{freeArticleData1234.map(
																		(freeArticleDataObj, i) => (
																			<li
																				onClick={() =>
																					setFreeArticleData234(
																						freeArticleDataObj.languageId,
																						freeArticleDataObj.languageDescription,
																						'freeArticleData1234',
																						'locationSearch1234'
																					)}
																			>
																				{freeArticleDataObj.languageDescription}
																			</li>
																		)
																	)}
																</ul>
															</div>
															<div className="col-md-1 col-1" style={{ padding: '2px', display: "flex", alignItems: "center" }}>
																<div className="form-group">
																	<span onClick={(e) => addAchievements1(e)}>
																		<img
																			src={Add}
																			title="Add Language"
																			style={{ cursor: 'pointer', marginLeft: '8px', marginTop: "25px" }}
																		/>
																	</span>
																</div>
															</div>
															<div className="col-md-6 col-lg-1 practiceManagementList">
																<label className="labelTextField labelMarginBottom">
																	&nbsp;
																</label>
																{/* <span
																class="borderButton borderButtonLarge formInput plAddFam"
																onClick={(e) => addAchievements1(e)}
															>
																+ Add
															</span> */}
															</div>
														</div>
													</div>
													<div class="col-md-12">
														<div class="row">
															<div class="table-responsive">
																{/* <table
																class="table"
																id="tblAchievements1"
																style={{ display: 'table' }}
															> */}
																{/* <thead>
																<tr id="grid-profile-docreg">
																	<th class="col-md-8">+ Add Language</th>
																</tr>
															</thead> */}
																{/* <tbody> */}
																{addValue1.map((item, index) => (
																	<span id="gridnew_docedit_achtrow" class="row">
																		<span className="col-md-6 col-lg-4 col-10">
																			<input
																				disabled="disabled"
																				type="text"
																				name="achievementDes1"
																				id="achievementDes1"
																				value={item.id}
																				className="input-design formInput"
																			/>
																		</span>

																		<span className="col-md-6 col-lg-1 col-1 dalignCenter">
																			<a id="remove" tabindex="4" href="#">
																				<img
																					style={{ width: '20px' }}
																					src={Delete}
																					title="Remove Language"
																					onClick={(e) =>
																						removeLanguage(e, index)}
																				/>
																			</a>
																		</span>
																	</span>
																))}{' '}
																{/* </tbody> */}
																{/* </table> */}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div>
												<div className="homeProfileDetails">
													{/* <div className="practiceManagementGrid profileForm">
														<div className="row rowMargin">
															<div className="col-md-12 col-lg-12 row-margin">
																<label className="labelTextField labelMarginBottom">
																	<span
																		className="addLanguage"
																		onClick={(e) => addAchievements(e)}
																	>
																		{' '}
																		+ Add Family Members
																	</span>
																</label>
															</div>
														</div>


													</div> */}
													{addValue.length > 0 ? (
														<div class="col-md-12" id="addFamId">
															<div class="table-responsive">
																<table
																	class="table"
																	id="tblAchievements"
																	style={{ display: 'table' }}
																>
																	<thead>
																		{/* <tr id="grid-profile-docreg">
																			<th>Family members</th>
																		</tr> */}
																	</thead>
																	<tbody style={{ border: 'none' }}>
																		{addValue.map((item, index) => (
																			<div id="gridnew_docedit_achtrow  ">
																				<div className="addFamilyMem">
																					<input
																						// disabled="disabled"
																						type="text"
																						name="memberFamilyName"
																						id="memberFamilyName"
																						placeholder="Family Member Name"
																						value={item.memberFamilyName}
																						class="achievementDesc formInput"
																						onChange={(e) =>
																							categoryTypeNewChange(
																								e,
																								index
																							)}
																					/>
																				</div>
																				<div className="addFamilyMem">
																					<input
																						// disabled="disabled"
																						type="text"
																						name="memberFamilyAge"
																						id="memberFamilyAge"
																						maxlength="3"
																						placeholder="Age"
																						onKeyPress={(event) => {
																							if (
																								!/[0-9]/.test(event.key)
																							) {
																								event.preventDefault();
																							}
																						}}
																						value={item.memberFamilyAge}
																						class="achievementDesc formInput"
																						onChange={(e) =>
																							categoryTypeNewChange(
																								e,
																								index
																							)}
																					/>
																				</div>
																				<div className="addFamilyMem">
																					<select
																						name="memberFamilyGender"
																						class="dentalFilesSelectType_PTour formInput"
																						id="memberFamilyGender"
																						value={item.memberFamilyGender}
																						onChange={(e) =>
																							categoryTypeNewChange(
																								e,
																								index
																							)}
																					>
																						<option value="Male">
																							Male
																						</option>
																						<option value="Female">
																							Female
																						</option>
																					</select>
																				</div>

																				<div>
																					<a
																						id="remove"
																						tabindex="4"
																						href="#"
																					>
																						<img
																							style={{
																								width: '20px',
																								margin: '8px',
																							}}
																							src={Delete}
																							title="Remove"
																							onClick={(e) =>
																								removeAchievementItem(
																									e,
																									index
																								)}
																						/>
																					</a>
																				</div>
																			</div>
																		))}{' '}
																	</tbody>
																</table>
															</div>
														</div>
													) : (
														''
													)}
												</div>
											</div>
										</div>
										<div class="newAppoinmentButton">
											<button type='button'
												className="borderButton"
												id="addPatientSaveId"
												onClick={() => addPatientSave()}
											>
												Save
											</button>
											{/* <span
												className="borderButton"
												style={{ display: 'none' }}
												id="addPatientSaveIdDemo"
											>
												Save
											</span> */}

											<button type='button' className="borderButton borderCancelButton" onClick={() => cancel()}>
												Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<div>
				{popUpDoctorList == true && (
					<div>
						{popaler.length > 1 ?
							<div className="ploverlayy">

								<div className="plpopup plpopupList selectDr " style={{ width: '400px' }}>
									<h2>
										<a class="close" href="#" onClick={closePopUp}>
											&times;
										</a>
									</h2>

									<div className="plcontent">
										<label>
											Select Doctor<span className="mandatory">*</span>
										</label>
										<div className="addDataContent" style={{ padding: '4px 0 0' }}>
											<div className="addDataDiv" id="styled-select">
												{popaler.length > 1 ?
													<select
														id="default"
														style={{ width: '100%' }}
														onChange={(e) => hospitalpageChange(e.target.value)}
													>
														<option value="">Doctor Name</option>
														{popaler.map((Popuplist, i) => (
															<option value={i}>
																{Popuplist.doctorName}({Popuplist.specialization})
															</option>
														))}
													</select>
													:
													<select
														id="default"
														style={{ width: '100%' }}
														onChange={(e) => hospitalpageChange(e.target.value)}
													>
														{popaler.map((Popuplist, i) => (
															<option value={i}>
																{Popuplist.doctorName}({Popuplist.specialization})
															</option>
														))}
													</select>}

											</div>
										</div>
									</div>

								</div>

							</div>
							: hospitalpageChange(0)}
					</div>
				)}
			</div>
		</div>
	);
}

export default AddPatient;
