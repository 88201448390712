import React from 'react';

function NoAppointmentData() {
	return (
		<div className="practiceManagementForm" style={{ border: '1px solid #dadada' }}>
			<div className="noDataFound">
				<p>No appointment(s) found matching the criteria.</p>
			</div>
		</div>
	);
}
export default NoAppointmentData;
