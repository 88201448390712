import React from 'react';
import Logo from '../images/media/logo.png';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';

function ShareDataConsentForm() {
	let { printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const location = useLocation();
	const [pagesizeid, setpagesizeid] = useState('');
	const [hospitalDetails, setHospitalDetails] = useState([]);
	const [patientDetails, setPatientDetails] = useState([]);
	const [hospitalGroupDetails, setHospitalGroupDetails] = useState([]);
	const [patientName, setPatientName] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [gender, setGender] = useState("");
	const [mrNo, setMrNo] = useState("");
	const [prNo, setPrNo] = useState("");
	const [hospitalName, setHospitalName] = useState("");
	const [hospitalGroupName, setHospitalGroupName] = useState("");
	const [title, setTitle] = useState("");
	const [EmailId, setEmailId] = useState("");
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);
	const [medicalCertificatePrinting, setMedicalCertificatePrinting] = useState([]);
	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);
			});

	}



	useEffect(() => {
		consentList()

	}, []);

	const consentList = () => {
		const apiJson =
		{
			"patientId": window.sessionStorage.getItem('memberId'),
			"hospitalId": localStorage.getItem('hospitalId')
		}
		callingAPI('patientProfile/consentFormShareData', apiJson).then((res) => {
			if (res.data.success === "1") {
				setPatientName(res.data.result.patientDetails[0].patientName);
				setDateOfBirth(res.data.result.patientDetails[0].dateOfBirth);
				setGender(res.data.result.patientDetails[0].gender);
				setMrNo(res.data.result.patientDetails[0].mrNo);
				setPrNo(res.data.result.patientDetails[0].prNo);
				setHospitalName(res.data.result.hospitalDetails[0].hospitalName);
				setTitle(res.data.result.hospitalDetails[0].title);
				setEmailId(res.data.result.hospitalDetails[0].EmailId);
				setHospitalGroupName(res.data.result.hospitalGroupDetails[0].hospitalGroupName);
			}
		})
	}
	const printTreatment = () => {
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}

	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				{/* {isData === false ? <Loader /> : ''} */}
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">

					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>

											<HeaderPrint />
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr style={{ display: 'flex', justifyContent: 'center' }}>
												<td style={{ fontSize: '14px', paddingBottom: '6px' }}>
													{/* {headingType} */}
													<div class="certificateCvrDiv"><h5 style={{ margin: '0', paddingTop: '15px', color: '#000' }}>CONSENT FORM
													</h5>
													</div>
												</td>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'right',
													}}
												>
													{' '}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>

											<tr class="dentalProPlaned">
												<td>
													<div
														class="taxValueTable"
														style={{
															width: '50%',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
															color: '#393939',
														}}
													>

														<p> <b>Name & Age : </b> {patientName}, {dateOfBirth}, {gender} </p>

													</div>

													<div
														style={{
															width: '50%',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
														}}
													>
														<div
															class="  txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																color: ' #4a4a4a',
															}}
														>
															{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																(<p>MR NO : {mrNo}</p>) :
																(<p>PR NO : {prNo}</p>)
															}

														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
										<tbody>
											<tr style={{ fontSize: '13px' }}>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '100%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													<div className='medCertificateForm'>
														<div id="medicalCreate" class="treatmentPlanContent" style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
															<p>I, {patientName} authorize {hospitalName} to share my health records with Group Name : {hospitalGroupName} containing the following hospital : {title}.</p>

															<textarea style={{ resize: 'none', minHeight: '100px', height: 'auto', border: "1px solid #ddd" }}>

															</textarea>

															{/* </div> */}
														</div>

													</div>
												</td>

											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>


						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>

											<tr class="dentalProPlaned">
												<td>
													<div
														class="taxValueTable"
														style={{
															width: '42%',
															marginTop: '-6px',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
															color: '#393939',
														}}
													>

														<p>  </p>

													</div>

													<div
														style={{
															width: '58%',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
														}}
													>
														<div
															class="  txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																color: ' #4a4a4a',
															}}
														>
															<p style={{ margin: '0px' }}>Thank You,</p>
															<p>{patientName}</p>

														</div>
														<div
															class=" txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																color: ' #4a4a4a',
															}}
														>
															{medicalCertificatePrinting.map((data) => (
																<p style={{ margin: "0px" }}> {data.doctorDetails.title}{data.doctorDetails.doctorDisplayName}</p>
															))}
														</div>
														<div
															class=" txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																color: ' #4a4a4a',
															}}
														>
															{medicalCertificatePrinting.map((data) => (
																<p>{data.hospitalDetails[0].hospitalName}</p>
															))}
														</div>
													</div>
												</td>
											</tr>
											<tr><td ><hr color="#142536" style={{ margin: "0px", opacity: "1", height: "2px" }} /></td></tr>
											{/* <tr>
												<td>
													<div class="printOutSettingContent">
														<table width="100%" cellspacing="0">
															<tbody>
																<tr class="dentalProPlaned">
																	<td
																		style={{ paddingTop: '15px', fontSize: '10px' }}
																	>
																		The Final treatment charges may vary depending
																		on any unseen treatments included or
																		modification in treatment plan.
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</td>
											</tr> */}
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>
					<FooterPrint />
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									id="printButton"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
										fontFamily: '  Verdana',
									}}
									onClick={printTreatment}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default ShareDataConsentForm;
