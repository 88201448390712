import React from 'react';
import Logo from '../images/media/logo.png';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';

function TreatmentPlansPrint() {
	let { printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');

	const [pagesizeid, setpagesizeid] = useState('');
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [treatmentPlanResult, setTreatmentPlanResult] = useState([]);
	const [doctorDetails, setdoctorDetails] = useState([]);
	const [treatmentPlanDate, setTreatmentPlanDate] = useState('');
	const [grandTotal, setSrandTotal] = useState('');
	const [headingType, setHeadingType] = useState('Treatment Plans');
	const [isData, setIsData] = useState(false);
	const [isCompleted, setIsCompleted] = useState('0')
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);

	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

				//setMarginTop()
			});

	}

	useEffect(() => {

		styleValues(printId.toString().split('|')[2])
		let memberProcedureDentalOrderId = '';
		let memberProcedureDentalDetailsId = '';
		if (printId) {
			if (printId.toString().split('|')[1] === '') {
				memberProcedureDentalOrderId = printId.toString().split('|')[0];
			} else {
				memberProcedureDentalOrderId = printId.toString().split('|')[0];
				memberProcedureDentalDetailsId = printId.toString().split('|')[1];
				setIsCompleted('1')
				setHeadingType('Completed Plans');
			}
		}
		callingAPI('commonUserFunctions/printTreatmentAndCompletedPlan', {
			memberProcedureDentalOrderId: memberProcedureDentalOrderId,
			memberProcedureDentalDetailsId: memberProcedureDentalDetailsId,
		}).then((res) => {
			setMemberDetails(res.data.result[0].memberDetails[0]);
			setHospitalDetails(res.data.result[0].hospitalDetails[0]);
			setdoctorDetails(res.data.result[0].doctorDetails)
			setHospitalLogo(res.data.result[0].hospitalDetails[0].hospitalLogo);
			setTreatmentPlanResult(res.data.result[0].treatmentPlanResult[0].item);
			setTreatmentPlanDate(res.data.result[0].treatmentPlanResult[0].printDate);
			setSrandTotal(res.data.result[0].treatmentPlanResult[0].item[0].totalNetAmount);
			setIsData(true);

		});
	}, []);

	const printTreatment = () => {
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				{isData === false ? <Loader /> : ''}
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">

					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>

											<HeaderPrint />

											<div class="caseSheetTop">
									<div class="printOutHeaderSec "></div>
									{/* {doctorDetails.map((item, i) => ( */}
										<div class="caseDoctor">
											<p>
												{doctorDetails.title}
												{doctorDetails.doctorDisplayName},
												{' '}
												{doctorDetails.education}
												{/* {item.speciality} */}

											</p>
											{/* <p>Council Reg. No.{item.medicalRegNumber}</p> */}
										</div>
									{/* ))} */}
								</div>

											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Name : {memberDetails.memberFirstName}{' '}
													{memberDetails.memberLastName}
												</td>
												<td colspan="6"
													style={{

														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Date : {treatmentPlanDate}{' '}
												</td>
											</tr>

											<tr>
												<td
													colspan="4"
													style={{
														width: '40%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Age : {memberDetails.memberAge}
												</td>
												<td
													colspan="8"
													style={{
														width: '80%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Email Id : {memberDetails.secUserEmailid}{' '}
												</td>
											</tr>
											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Sex : {memberDetails.memberGender}
												</td>
												<td colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Phone No : {memberDetails.SecuserMobileNumber}
												</td>
											</tr>
											<tr>
												{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
													(<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														MR No : {memberDetails.memberMrNo}
													</td>) :
													(!memberDetails.memberPrNo == "" &&
														<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'left',
															}}
														>
															PR No : {memberDetails.memberPrNo}
														</td>
													)}

											</tr>

											<tr>
												<td colspan="12">
													<hr
														color="#142536"
														style={{ margin: '0', opacity: '1', height: '2px' }}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr style={{ borderBottom: '2px solid black' }}>
												<td style={{ fontSize: '14px', paddingBottom: '10px' }}>
													{headingType}
												</td>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'right',
													}}
												>
													{' '}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
										<tbody>
											<tr style={{ fontSize: '13px', background: '#eae8e8' }}>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Procedure
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '20%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Notes
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '8%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Qty
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Cost
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '17%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Tax
												</td>

												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '16%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Total
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											{treatmentPlanResult.map((item) => (
												<tr>
													{item.procedureCode !== "" ?
														<td
															style={{
																boxSizing: ' border-box',
																padding: '5px 2px',
																width: '25%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{item.procedureName} ({item.procedureCode})
														</td>
														:
														<td
															style={{
																boxSizing: ' border-box',
																padding: '5px 2px',
																width: '25%',
																display: 'inline-block',
																textAlign: 'left',
																fontSize: '12px',
																color: '#393939',
																lineHeight: '18px',
															}}
														>
															{item.procedureName}
														</td>
													}
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '20%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{item.notes}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '8%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{item.quantity}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '14%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{item.amount}
														{/* {parseFloat((item.amount * item.quantity)).toFixed(2)} */}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '17%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{parseFloat(item.totalTax).toFixed(2)}
													</td>

													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '16%',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{parseFloat((item.amount * item.quantity + item.totalTax)).toFixed(2)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											{isCompleted === '0' &&
												<tr class="dentalProPlaned">
													<td>
														<div
															class="taxValueTable"
															style={{
																width: '42%',
																marginTop: '-6px',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
																color: '#393939',
															}}
														/>

														<div
															style={{
																width: '58%',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
															}}
														>
															<div
																class="estimatedPrice txtright"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '15px',
																	color: ' #4a4a4a',
																}}
															>
																Grand Total (INR) :&nbsp;
																<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{parseFloat(grandTotal).toFixed(2)}
																</span>
															</div>
														</div>
													</td>
												</tr>}
											<tr>
												<td>
													<div class="printOutSettingContent">
														<table width="100%" cellspacing="0">
															<tbody>
																<tr class="dentalProPlaned">
																	<td
																		style={{ paddingTop: '15px', fontSize: '10px' }}
																	>
																		The Final treatment charges may vary depending
																		on any unseen treatments included or
																		modification in treatment plan.
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>
					<FooterPrint />
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									id="printButton"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '18px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '0px',
										fontFamily: '  Verdana',
									}}
									onClick={printTreatment}
								>
									Print
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default TreatmentPlansPrint;
