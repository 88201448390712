import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import DoctorLeftMenu from "../Reports/DoctorLeftMenu";
function PharmacyLeft() {
  const [toggle, setToggle] = useState(false);
  const [toggleFiles, setToggleFiles] = useState(false);
  const [toggleReports, setToggleReports] = useState(false);
  const [togglePhrmcy, setTogglePhrmcy] = useState(false);
  const [settingMenuPharmacy, setsettingMenuPharmacy] = useState("");
  const paginationOfPharmacyReport1 = () => {
    window.sessionStorage.setItem("paginationOfPharmacyReport1", 1);
    window.sessionStorage.setItem("paginationOfPharmacyReport1", 0);
  };
  const paginationOfPharmacyReport2 = () => {
    window.sessionStorage.setItem("paginationOfPharmacyReport2", 1);
    window.sessionStorage.setItem("paginationOfPharmacyReport2", 0);
  };
  const inventoryDetailsSection = () => {
    window.sessionStorage.setItem("pageNumberInventoryDetails", 1);
    window.sessionStorage.setItem("offsetInventoryDetails", 0);
  };
  const medicineDetailsSection = () => {
    window.sessionStorage.setItem("pageNumberMedicineDetails", 1);
    window.sessionStorage.setItem("offsetMedicineDetails", 0);
  };
  const purchaseDetailsSection = () => {
    window.sessionStorage.setItem("pageNumberPurchaseDetails", 1);
    window.sessionStorage.setItem("offsetPurchaseDetails", 0);
  };

  const paginationOfPharmacySupplieer = () => {
    window.sessionStorage.setItem("PharmacysupplierpageNumber", 1);
    window.sessionStorage.setItem("SupplierListDownloadactivepagenew", 0);
  };
  const paginationOfPharmacyManufacture = () => {
    window.sessionStorage.setItem("PharmacyManufacturepageNumber", 1);
    window.sessionStorage.setItem("manufactureListDownloadactivepagenew", 0);
  };

  const setToggleFiles4 = (event) => {
    if (document.getElementById("qaz4").style.display === "block") {
      window.sessionStorage.setItem("qaz4", "none")
    }
    else {
      window.sessionStorage.setItem("qaz4", "block")
    }
  }
  const setToggleFiles6 = (event) => {
    if (document.getElementById("qaz6").style.display === "block") {
      window.sessionStorage.setItem("qaz6", "none")
    }
    else {
      window.sessionStorage.setItem("qaz6", "block")
    }
  }
  const setToggleFiles7 = (event) => {
    if (document.getElementById("qaz7").style.display === "block") {
      window.sessionStorage.setItem("qaz7", "none")
    }
    else {
      window.sessionStorage.setItem("qaz7", "block")
    }
  }
  return (
    <div className="settingMenu" style={{ paddingTop: "0px" }}>
      {window.sessionStorage.getItem("role") === "ROLE_HOSPITALPHARMACY" ? (
        <div className="settingMenuList">
          <span className="LeftMenuTitle">
            {window.sessionStorage.getItem("userName")}
          </span>
          <ul>
            <li className="settingListLi">
              <NavLink to="/pharmacyLogin/prescriptionDetails" activeClassName="active">
                Prescription Details
              </NavLink>
            </li>
            <li className="settingListLi">
              <NavLink to="/pharmacyLogin/prescriptionBill" activeClassName="active">
                Prescription Bill
              </NavLink>
            </li>
            <li>
              <Link to="#"  >
                <span onClick={() => setToggleFiles4()}>Add Data</span>

                <div className="plSubMenu" id="qaz4" style={{ display: window.sessionStorage.getItem("qaz4"), padding: '0px', paddingTop: '15px' }}>
                  <div>
                    <li>
                      <NavLink to="/settings/pharmacySupplier" activeClassName="active" onClick={paginationOfPharmacySupplieer}>
                        <span  >Supplier</span></NavLink>
                    </li>
                  </div>
                  <div>
                    <li>
                      <NavLink to="/settings/pharmacyManufacture" activeClassName="active" onClick={paginationOfPharmacyManufacture} >
                        <span >Manufacturer</span></NavLink>

                    </li>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="#"  >
                <span onClick={() => setToggleFiles6()}>Hospital Inventory</span>

                <div className="plSubMenu" id="qaz6" style={{ display: window.sessionStorage.getItem("qaz6"), padding: '0px', paddingTop: '15px' }}>
                  <div>
                    <li>
                      <NavLink onClick={medicineDetailsSection} to="/settings/medicineStockUpdate" activeClassName="active"  >
                        <span  > Medicine Stock Update</span></NavLink>
                    </li>
                  </div>
                  <div>
                    <li>
                      <NavLink onClick={inventoryDetailsSection} to="/settings/hospitalInventory" activeClassName="active"   >
                        <span >Inventory Stock Update</span></NavLink>

                    </li>
                  </div>
                  <div>
                    <li>
                      <NavLink onClick={purchaseDetailsSection}{...window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to="/settings/purchaseLists" activeClassName="active"    >
                        <span >Purchase List</span></NavLink>

                    </li>
                  </div>
                </div>
              </Link>
            </li>


            <li >
              <Link to="#">
                <span onClick={() => setToggleFiles7()}>Reports</span>
                <div className="plSubMenu  " id="qaz7" style={{ display: window.sessionStorage.getItem("qaz7"), padding: '0px', paddingTop: '15px' }}>
                  <div>
                    <li  >
                      <NavLink to="/reports/supplierInvoice" activeClassName="active" onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                      }}  >
                        Supplier Invoice
                      </NavLink>
                    </li>
                    <li >
                      <NavLink activeClassName="active"
                        to="/reports/pharmacystockdetails" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Pharmacy Stock Details
                      </NavLink>
                    </li>
                    <li >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/medicineReorder" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Medicine Reorder
                      </NavLink>
                    </li>

                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/expiredMedicineStockDetails" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Expired Medicine Stock Details
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/medicineSalebyDay" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Medicine Sale by Day
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/reports/pharmacypayment" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Pharmacy Payment
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/patientMedicine" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Patient Medicine
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/prescribedMedicinebyQuantity" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Prescribed Medicine by Quantity
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/reports/prescriptionAmount" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Prescriptions Amount
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/outsidePharmacyOrderDetails" onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}
                      >
                        Outside Pharmacy Order
                      </NavLink>
                    </li>
                    <li >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/hospitalMedicineHistory"
                        onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }}  >
                        Hospital Medicine History
                      </NavLink>
                    </li>
                    <li  >
                      <NavLink
                        activeClassName="active"
                        to="/pharmacyLogin/unavailableMedicine"
                        onClick={() => {
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }} >
                        Unavailable Medicines
                      </NavLink>
                    </li>
                  </div>
                </div>
              </Link>

            </li>
            <li className="settingListLi">
              <NavLink to="/Dashboard/changepassword" activeClassName="active" onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }} >Change Password</NavLink>
            </li>
          </ul>
        </div>
      ) : (
        <DoctorLeftMenu />
      )}
    </div>
  );
}
export default PharmacyLeft;
