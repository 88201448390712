import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Img1 from '../images/media/doctor.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callingAPI } from '../config';
import moment from 'moment';
import {
    faAngleRight,
    faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Datepicker from "react-datepicker";
import TeethSection from './teethSectionLaborder';

function LabOrderSave(props) {
    const [dentalLabWorkName, setDentalLabWorkName] = useState([]);
    const [workName, setWorkName] = useState([]);
    const [dentalLabShadeDetails, setDentalLabShadeDetails] = useState([]);
    const [dentalLabWorkAlloyTypeDetails, setDentalLabWorkAlloyTypeDetails] = useState([]);
    const [dentalLabStatusDetails, setDentalLabStatusDetails] = useState([]);
    const [dentalLabNameDetails, setDentalLabNameDetails] = useState([]);
    const [labWorkName, setLabWorkName] = useState('');
    const [newDate, setDate] = useState('');
    const [workNameSelect, setWorkNameSelect] = useState(null);
    const [showTeeth, setShowTeeth] = useState(false);
    // const [workNameDisplay, setWorkNameDisplay] = useState(false);
    const [jobName, setJobName] = useState('');
    const [labName, setLabName] = useState('');
    const [labShade, setLabShade] = useState('');
    const [labAlloy, setLabAlloy] = useState('');
    const [labStatus, setLabStatus] = useState('');
    const [teethArrayGet, seTeethArrayGet] = useState([]);
    const [teethArrayGetSave, seTeethArrayGetSave] = useState('');
    const [teethArrayPass, seTeethArrayPass] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        statusList();
        if (props.editClick !== "" && props.editClick.length !== 0) {
            setDate(props.editClick.dueDate);
            setLabWorkName(props.editClick.dentalLabWorkTypeId)
            setWorkNameSelect([{ id: props.editClick.dentalLabWorkNameId, name: props.editClick.dentalLabWorkName }])
            setLabName(props.editClick.dentalLabId)
            setLabShade(props.editClick.dentalLabShadeId)
            setLabAlloy(props.editClick.dentalLabAlloyTypeId)
            setLabStatus(props.editClick.dentalLabStatusId)
            setJobName(props.editClick.id)

            if (props.editClick.toothNumber !== "") {

                seTeethArrayPass(props.editClick.toothNumber.split("|").map(Number))
                seTeethArrayGet(props.editClick.toothNumber.split("|").map(Number))
            }
        }
        if (props.editClick.length === 0) {
            setDate(new Date());
        }

    }, []);
    const workNameDisplayFn = () => {
        setWorkNameSelect(null)
    }

    const getTeethArray = (data) => {

        seTeethArrayGet(data.map(item => String(item.teethNumber)))
        seTeethArrayGetSave(data.map(item => item.teethNumber).join('|'))
    }

    const saveLabOrder = () => {
        setIsButtonDisabled(true);
        var flag = true
        if (workNameSelect === null) {
            alert("Please choose work name")
            document.getElementById("dentalLabWorkName").focus()
            setIsButtonDisabled(false);
            flag = false

        }
        else if (labName === "") {
            alert("Please choose Lab name")
            document.getElementById("labName").focus()
            setIsButtonDisabled(false);
            flag = false
        }
        else if (document.getElementById("treatmentDateTime").value === "") {
            alert("Please enter Due date")
            document.getElementById('treatmentDateTime').focus()
            setIsButtonDisabled(false);
            flag = false
        }

        if (flag == true) {
            const apiJson = {
                "id": jobName,
                "memberId": window.sessionStorage.getItem('memberId'),
                "hospitalId": localStorage.getItem('hospitalId'),
                "doctorId": window.sessionStorage.getItem('doctorId'),
                "scheduleTimeSlotId": "",
                "dentalLabId": labName,
                "dueDate": new Date(newDate).getTime(),
                "dentalLabWorkNameId": workNameSelect.length > 0 ? workNameSelect[0].id.toString() : "",
                "dentalLabStatusId": labStatus,
                "dentalLabShadeId": labShade,
                "dentalLabAlloyTypeId": labAlloy,
                "toothNumber": teethArrayGetSave,
                "prNoEnabled": window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
            }
            callingAPI("commonUserFunctions/saveOrUpdateDentalLabOrder", apiJson).then((data) => {

                if (data.data.success === "1") {
                    alert("Saved Succcessfully")
                    props.labTrackingList()
                    cancelLabOrder()
                }
            })
        }
    }
    const statusList = () => {
        const apiJson = {
            "hospitalId": localStorage.getItem('hospitalId'),
        }
        callingAPI("commonUserFunctions/getDentalLabMasterDetails", apiJson).then((data) => {

            if (data.data.success === "1") {
                var result = data.data.result
                setDentalLabWorkName(result.dentalLabWorkTypeDetails)
                setWorkName(result.dentalLabWorkNameDetails)
                setDentalLabShadeDetails(result.dentalLabShadeDetails)
                setDentalLabWorkAlloyTypeDetails(result.dentalLabWorkAlloyTypeDetails)
                setDentalLabStatusDetails(result.dentalLabStatusDetails)
                setDentalLabNameDetails(result.dentalLabNameDetails)
            }
        })
    }
    const cancelLabOrder = () => {
        setIsButtonDisabled(false);
        props.addNewOrder()
    }
    const teethSectionChange = () => {
        setShowTeeth(!showTeeth)
        if (props.editClick !== "" && props.editClick.length !== 0) {
            if (props.editClick.toothNumber !== "") {
                seTeethArrayPass(props.editClick.toothNumber.split("|").map(Number))
            }
        }
        if (showTeeth === true) {
            document.getElementById("teethSectionLabOrder").style.display = "block"
        }
        else {
            document.getElementById("teethSectionLabOrder").style.display = "none"
        }
    }

    return (
        <div className="coverWraper">
            <div className="contentWraper contentWraperScroll">
                <Leftmenu />
                <div className="rightSection laborder-wraper">
                    <Profiledetail />
                    <div className="lab-orderheadsectn align-items-center">
                        <div className='d-flex align-items-center'>
                        </div>
                        <div>
                            <span className="pl_blue_btn btn-laborder btn-laborder-cancel" onClick={(e) => cancelLabOrder()}>Cancel</span>
                            {/* <span className="pl_blue_btn btn-laborder">Save Order & Fix Appoinment</span> */}
                            {props.editClick !== "" && props.editClick.length !== 0 ? <button className="pl_blue_btn btn-laborder" onClick={(e) => saveLabOrder()} disabled={isButtonDisabled}>Update Lab Order</button> :
                                <button className="pl_blue_btn btn-laborder" onClick={(e) => saveLabOrder()} disabled={isButtonDisabled}>Save Lab Order</button>}
                        </div>
                    </div>
                    <div className="row laborder-savewrpr">
                        <div className="col-md-4">

                            <div className={workNameSelect !== null ? "  disabledDiv laborder-leftdropdwn" : "laborder-leftdropdwn"}>
                                <div className='laborder-divtext  py-2 px-3'>
                                    Choose Work Name<span className='start-red'>*</span> :
                                </div>
                                {/* <div>
                                    <div className='inputField laborder-input'>  {dentalLabWorkName.map((obj, i) => (
                                        <span value={obj.id}>{obj.name}</span>))}</div>
                                    <ul className='laborderlist-ul'>
                                        <li className='laborderlist-li'>1</li>
                                        <li className='laborderlist-li'>2</li>
                                        <li className='laborderlist-li'>3</li>
                                        <li className='laborderlist-li'>4</li>
                                        <li className='laborderlist-li'>5</li>
                                        <li className='laborderlist-li'>6</li>
                                    </ul>
                                </div> */}

                                <select value={labWorkName} name="" id="dentalLabWorkName" onChange={(e) => setLabWorkName(e.target.value)} style={{ width: "100%" }}>
                                    {dentalLabWorkName.map((obj, i) => (
                                        <option value={obj.id}>{obj.name}</option>))}
                                </select>
                            </div>
                            <ul className={workNameSelect !== null ? "  disabledDiv laborder-leftmenu" : "laborder-leftmenu"} >
                                {workName.filter((sectionObject) => sectionObject.dental_lab_work_type_id === parseInt(document.getElementById("dentalLabWorkName").value)).map((obj, i) =>
                                    <li onClick={(e) => setWorkNameSelect([{ id: obj.id, name: obj.name }])}>{obj.name}</li>)}
                            </ul>
                        </div>
                        <div className="col-md-8 bg-color-right">
                            <div className={workNameSelect === null ? "labOrder-savesectn disabledDiv" : "labOrder-savesectn"} >

                                <div className="labOrdersave-headsectn">
                                    {/* <span className='close-laborder'>X</span> */}
                                    {props.editClick !== "" && props.editClick.length !== 0 ?
                                        <div className="lab-order-top">
                                            <label htmlFor="" className='lab-order-toplabl'>JOB NO <span className='start-red'>*</span></label>
                                            <div className='laborder-divtext'>{jobName}</div>
                                        </div> : ""}
                                    <div className="lab-order-top">
                                        <div className="d-flex justify-content-between">
                                            <label htmlFor="" className='lab-order-toplabl'>LAB NAME <span className='start-red'>*</span></label>
                                            {/* <label htmlFor="" className='lab-order-toplabl'>Add new lab</label> */}
                                        </div>
                                        <div className='laborder-divtext'>
                                            <select value={labName} name="labName" id="labName" onChange={(e) => setLabName(e.target.value)}>
                                                <option value="">Choose the lab</option>
                                                {dentalLabNameDetails.map((item, i) => (
                                                    <option value={item.id}>{item.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="lab-order-top">
                                        <span className='laborder-divtext' >
                                            DUE DATE&nbsp;<span className='start-red'>*</span><span style={{ color: '#1b99d5' }} />
                                            <Datepicker
                                                required
                                                id="treatmentDateTime"
                                                className="Datepicker pa2 form-control "
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                placeholderText=""
                                                calendarClassName="rasta-stripes"
                                                selected={newDate}
                                                showTimeSelect
                                                onChange={(date) => setDate(date)}
                                                style={{ background: 'transparent!important' }}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="labOrdersave-details" >
                                        <div className="labOrdersectn-div">
                                            <label htmlFor="">Work Name : </label>
                                            {workNameSelect && (
                                                <span className='labOrdersectn-span labOrdersectn-spantxt'>{workNameSelect[0].name}
                                                    <span className='laborder-divtext changeWrkNm' onClick={(e) => workNameDisplayFn(true)}> Change Work Name
                                                    </span>

                                                </span>)}

                                        </div>
                                        <div className="labOrdersectn-div">
                                            <label htmlFor="">Tooth number : </label>
                                            {teethArrayGet.length > 0 ?
                                                <div className='labOrdersectn-span'>
                                                    {teethArrayGet.map((data, i) => (
                                                        <span>{data + "|"}</span>))}
                                                    <span className='laborder-divtext changeWrkNm' onClick={(e) => teethSectionChange()}  >
                                                        {showTeeth === true ? "hide" : "show"}
                                                    </span>
                                                </div> :
                                                <span className='laborder-divtext' onClick={(e) => teethSectionChange()}>
                                                    Please select teeth
                                                </span>}
                                        </div>
                                        <div className='teethSectionLabOrder' id='teethSectionLabOrder' style={{ display: showTeeth === true ? "block" : "none" }}>
                                            <TeethSection getTeethArray={getTeethArray} teethArrayPass={teethArrayPass} key={teethArrayPass} />
                                        </div>
                                        <div className="labOrdersectn-div">
                                            <label htmlFor="">Shade : </label>
                                            <span className='labOrdersectn-span'>
                                                <select value={labShade} name="shade" className='form-control' id="shade" onChange={(e) => setLabShade(e.target.value)}>
                                                    <option value="">Select Shade</option>
                                                    {dentalLabShadeDetails.map((item, i) => (
                                                        <option value={item.id}>{item.name}</option>))}
                                                </select>
                                            </span>
                                        </div>
                                        <div className="labOrdersectn-div">
                                            <label htmlFor="">Alloy Type : </label>
                                            <span className='labOrdersectn-span'>
                                                <select value={labAlloy} name="" className='form-control' id="" onChange={(e) => setLabAlloy(e.target.value)}>
                                                    <option value="">Select Alloy Type</option>
                                                    {dentalLabWorkAlloyTypeDetails.map((item, i) => (
                                                        <option value={item.id}>{item.name}</option>))}
                                                </select>
                                            </span>
                                        </div>
                                        <div className="labOrdersectn-div">
                                            <label htmlFor="">Status : </label>
                                            <span className='labOrdersectn-span'>
                                                <select value={labStatus} name="" className='form-control' id="" onChange={(e) => setLabStatus(e.target.value)}>
                                                    <option value="">Select Status</option>
                                                    {dentalLabStatusDetails.map((item, i) => (
                                                        <option value={item.id}>{item.name}</option>))}
                                                </select>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="nofutr-appoinmts">No future appointments </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}
export default LabOrderSave;
