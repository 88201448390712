import React, { useState, useEffect } from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
import { callingAPI } from '../config';
function TestResultPrint(props) {
	const [printResultTestDetails, setprintResultTestDetails] = useState([])
	const [printResultTestisverified, setprintResultTestisverified] = useState('')
	const [txtlabTechnicianPrint, settxtlabTechnicianPrint] = useState('')
	const [txtlabTechnologistPrint, settxtlabTechnologistPrint] = useState('')
	const [hospitalName, sethospitalName] = useState('')
	const [Drname, setDrname] = useState('')
	const [Drvalue, setDrvalue] = useState('')
	const [hospitalAddress, sethospitalAddress] = useState('')
	const [hospitalCity, sethospitalCity] = useState('')
	const [hospitalHeaderLeftText, sethospitalHeaderLeftText] = useState('')
	const [hospitalHeaderRightText, sethospitalHeaderRightText] = useState('')
	const [memberFirstName, setmemberFirstName] = useState('')
	const [memberLastName, setmemberLastName] = useState('')
	const [memberDOB, setmemberDOB] = useState('')
	const [memberMrNo, setmemberMrNo] = useState('')
	const [memberPrNo, setmemberPrNo] = useState('')
	const [SecuserMobileNumber, setSecuserMobileNumber] = useState('')
	const [memberAge, setmemberAge] = useState('')
	const [secUserEmailid, setsecUserEmailid] = useState('')
	const [memberGender, setmemberGender] = useState('')
	const [hospitalDescription, sethospitalDescription] = useState('')
	const [hospitalEmailId, sethospitalEmailId] = useState('')
	const [hospitalLogo, sethospitalLogo] = useState('')
	const [hospitalImagename, sethospitalImagename] = useState('');
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');

	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
	const testResultprint = () => {
		const PostData = {
			"doctorId": window.sessionStorage.getItem("doctorIdTest"),
			"hospitalId": localStorage.getItem("hospitalId"),
			"memberId": window.sessionStorage.getItem("memeberIdTest"),
			"hidType": "all",
			"laborderTestId": window.sessionStorage.getItem("laborderTestIdValue"),
			"txtVerifiedBy": window.sessionStorage.getItem("txtVerifiedBy"),
			"doctorVisitId": window.sessionStorage.getItem("doctorvisitIdTest"),
			"txtlabTechnician": window.sessionStorage.getItem("txtlabTechnician",),
			"txtlabTechnologist": window.sessionStorage.getItem("txtlabTechnologist"),
			"hidMemProcPayReceipt": ""
		};
		callingAPI('printLabTestDetails/printTestResult', PostData).then((data) => {
			if (data.data.success === '1') {
				setprintResultTestDetails(data.data.result.labOrderTestDate.labData);
				setprintResultTestisverified(data.data.result.labOrderTestDate.verifiedBy)
				settxtlabTechnicianPrint(data.data.result.labOrderTestDate.labTechnician)
				settxtlabTechnologistPrint(data.data.result.labOrderTestDate.labTechnologist)
				sethospitalName(data.data.result.hospitalDetails[0].hospitalName)
				sethospitalAddress(data.data.result.hospitalDetails[0].hospitalAddress)
				sethospitalCity(data.data.result.hospitalDetails[0].hospitalCity)
				sethospitalHeaderLeftText(data.data.result.hospitalDetails[0].hospitalHeaderLeftText)
				sethospitalHeaderRightText(data.data.result.hospitalDetails[0].hospitalHeaderRightText)
				setmemberFirstName(data.data.result.memberDetails[0].memberFirstName)
				setmemberLastName(data.data.result.memberDetails[0].memberLastName)
				setmemberDOB(data.data.result.memberDetails[0].memberDOB)
				setmemberMrNo(data.data.result.memberDetails[0].memberMrNo)
				setmemberPrNo(data.data.result.memberDetails[0].memberPrNo)
				setSecuserMobileNumber(data.data.result.memberDetails[0].SecuserMobileNumber)
				setmemberAge(data.data.result.memberDetails[0].memberAge)
				setsecUserEmailid(data.data.result.memberDetails[0].secUserEmailid)
				setmemberGender(data.data.result.memberDetails[0].memberGender)
				sethospitalDescription(data.data.result.hospitalDetails[0].hospitalDescription)
				sethospitalEmailId(data.data.result.hospitalDetails[0].hospitalEmailId)
				sethospitalLogo(data.data.result.hospitalDetails[0].hospitalLogo)
				setDrname(data.data.result.doctorDetails.doctorDisplayName)
				setDrvalue(data.data.result.doctorDetails.title)
				// setCount(data.data.result.totalCount);
				// setDataFlag(true);
			}
			else {
				setprintResultTestDetails([])
				// setDataFlag(true);
				// setLabOrderDetailsListData([]);
			}
		});
	};
	function styleValues(hospitalId) {
		const PostData = {
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: ""
		};
		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);
				//setMarginTop()
			});
	}
	useEffect(() => {
		testResultprint()
		callingAPI('commonUserFunctions/headerFooterLogoPrints', {
			hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
			doctorId: ""
		}).then((res) => {
			sethospitalImagename(res.data.result.hospitalDetail[0].hospitalLogoImage);
		});
	}, [])
	const onPrintButtonClick = () => {
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	};

	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div className="receiptPrint">
			<div>
				<div align="center" >
					<div align="center"
						style={{
							fontFamily: "SourceSansPro-Regular",
							width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
							height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
							marginLeft: '0px',
							marginright: '0px',
							marginTop: '10px',
							marginBottom: '10px',
						}}>
						<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">
							<thead>
								<tr>
									<th style={{ fontWeight: "400" }}>
										<div class="printOutHeaderSec  ">
											<table style={{ width: "100%" }}>
												{/* ========== */}
												<thead>
													<tr>
														<th style={{ fontWeight: '400' }}>
															<div class="printOutHeaderSec ">
																<table style={{ width: '100%' }}>
																	<tbody>
																		<HeaderPrint />
																		<tr>

																			<td
																				colspan="6"
																				style={{
																					width: '50%',
																					fontSize: '12px',
																					padding: '0',
																					lineHeight: '17px',
																					textAlign: 'left',
																				}}
																			>
																				Patient : {memberFirstName} {memberLastName}



																			</td>


																			<td colspan="6"
																				style={{
																					width: '50%',
																					fontSize: '12px',
																					padding: '0',
																					lineHeight: '17px',
																					textAlign: 'right',
																				}}
																			>
																				Date : {memberDOB}

																			</td>

																		</tr>
																		<tr>
																			{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																				(<td
																					colspan="4"
																					style={{
																						width: '40%',
																						fontSize: '12px',
																						padding: '0',
																						lineHeight: '17px',
																						textAlign: 'left',
																					}}
																				>
																					MR No : {memberMrNo}
																				</td>) :
																				(<td
																					colspan="4"
																					style={{
																						width: '40%',
																						fontSize: '12px',
																						padding: '0',
																						lineHeight: '17px',
																						textAlign: 'left',
																					}}
																				>
																					PR No : {memberPrNo}
																				</td>)
																			}

																			<td
																				colspan="8"
																				style={{
																					width: '80%',
																					fontSize: '12px',
																					padding: '0',
																					lineHeight: '17px',
																					textAlign: 'right',
																				}}
																			>
																				Phone No : {SecuserMobileNumber}
																			</td>

																		</tr>
																		<tr>

																			<td
																				colspan="6"
																				style={{
																					width: '50%',
																					fontSize: '12px',
																					padding: '0',
																					lineHeight: '17px',
																					textAlign: 'left',
																				}}
																			>
																				Age : {memberAge}
																			</td>


																			<td colspan="6"
																				style={{
																					width: '50%',
																					fontSize: '12px',
																					padding: '0',
																					lineHeight: '17px',
																					textAlign: 'right',
																				}}
																			>
																				Email Id :  {secUserEmailid}
																			</td>

																		</tr>
																		<tr>

																			<td
																				colspan="6"
																				style={{
																					width: '50%',
																					fontSize: '12px',
																					padding: '0',
																					lineHeight: '17px',
																					textAlign: 'left',
																				}}
																			>
																				Sex : {memberGender}
																			</td>




																		</tr>
																		<tr>
																			<td colspan="12">
																				<hr
																					color="#142536"
																					style={{ margin: '0', opacity: '1', height: '2px' }}
																				/>
																			</td>
																		</tr>
																	</tbody>
																	{/* <tbody>
													<HeaderPrint />
													
 
													<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "left" }}>{memberFirstName} {memberLastName}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "right" }}>Date : {memberDOB} </td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>MR No : {memberMrNo}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Phone No : {SecuserMobileNumber}</td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Age : {memberAge}
													</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Email Id :  {secUserEmailid}</td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Sex : {memberGender}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}></td>
												</tr>
												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr>
												</tbody> */}
																</table>
															</div>
														</th>
													</tr>
												</thead>
												{/* =========== */}
												{/* <tbody>
											{/* <HeaderPrint /> */}
												{/* <tr height="80px">
													<td colspan="3" valign="middle" align="center">
														<div style={{ width: "100%", float: "none" }}>
															<p style={{ fontWeight: "400", fontSize: "16px", clear: "both", width: "100%", margin: "0px 0 3px 0px" }}>
																<span style={{ marginTop: "-5px", display: "inline-block", width: "100%" }}>
																	{hospitalImagename != (null || "") ?
																		<img
																			src={getS3folder + '/' + localStorage.getItem("hospitalId") + ".png"}
																			style={{ display: "block", width: "auto", maxHeight: "65px", margin: "auto" }} align="middle"
																		/> : <img src={Logo}
																			style={{ display: "block", width: "auto", maxHeight: "65px", margin: "auto" }} align="middle" />}
																</span>
															</p>
														</div>
														<div class="practicePrintName" style={{ width: "100%" }}>
															<p style={{ fontWeight: "400", fontSize: "16px", clear: "both", width: "100%", marginBottom: "1px", marginTop: "0" }}>
																{hospitalName}</p>
															<p style={{ fontWeight: "400", fontSize: "12px !important", clear: "both", width: "100%", margin: "0" }}>{hospitalAddress + " , " + hospitalCity}</p>
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr>
												<tr>
													<td width="50%" colspan="2" align="left" style={{ fontSize: "12px", wordBreak: "break-all" }}>
														{hospitalHeaderLeftText}
													</td>
													<td width="50%" align="right" style={{ fontSize: "12px", wordBreak: "break-all" }}>
														{hospitalHeaderRightText}
													</td>
												</tr>
												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr> */}
												{/* <tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "left" }}>{memberFirstName} {memberLastName}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "17px", textAlign: "right" }}>Date : {memberDOB} </td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>MR No : {memberMrNo}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Phone No : {SecuserMobileNumber}</td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Age : {memberAge}
													</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}>Email Id :  {secUserEmailid}</td>
												</tr>
												<tr>
													<td colspan="2" style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "left" }}>Sex : {memberGender}</td>
													<td style={{ width: "50%", fontSize: "12px", padding: "0", lineHeight: "15px", textAlign: "right" }}></td>
												</tr>
												<tr>
													<td colspan="3"><hr color="#142536" style={{ margin: "0" }} /></td>
												</tr>  
											</tbody> */}
											</table>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr style={{ width: '100%', display: 'grid' }}>
									<td>
										<div class="printOutSettingContent">
											<table style={{ width: "100%" }} cellspacing="0">
												<tbody>
													<tr>
														<td>
															<div class="teethTop">
																<div class="teethTopOne">
																</div>
																<div class="teethTopOne teethTopSampleOne">
																	<div class="teethDivItem">
																	</div>
																	{/* <!--<div class="teethDivItem">
											<span class="teethItemReport">Report On :</span>
											<span class="teethReportSpan">14, Mar 2022 05:27</span>
										</div>--> */}
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
								<tr style={{ width: '100%', display: 'grid' }}>
									<td>
										<div class="printOutSettingContent">
											<table style={{ width: "100%" }} cellspacing="0">
												<tbody>
													<tr>
														<td>
															<div class="teethContentHead">
																<div class="teethHeadItem testNameHead">Test Name (Type)</div>
																<div class="teethHeadItem patientReadingHead">Patient Reading</div>
																<div class="teethHeadItem normalValueHead">Normal Value</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
								{printResultTestDetails.map((item) => (
									<tr style={{ width: '100%', display: 'grid' }}>
										<td>
											<div class="printOutSettingContent">
												<table style={{ width: "100%" }} cellspacing="0">
													<tbody>
														<tr>
															<td>
																<div class="testPrintWrap">
																	<div class="teethContentDesRow teethContentTestNAme">
																		<div class="teethContentDesItem teethContentDesDiv" style={{ width: '100%' }}><span style={{
																			fontFamily: "Arial",
																			fontWeight: 600
																		}}><u>{item.testName}  </u>
																		</span>
																		</div>
																		<tr style={{ width: '100%', display: 'grid' }}>
																			<td>
																				<div class="printOutSettingContent">
																					<table style={{ width: "100%" }} cellspacing="0">
																						<tbody>
																							<tr>
																								<td>
																									<div class="testPrintWrap">
																										<div class="teethContentDesRow teethContentTestNAme">
																											<div class="teethContentDesItem teethContentDesDiv"></div>
																										</div>
																										{item.labDetails.map((data) => (
																											<div class="teethContentDesRow">
																												<div style={{ padding: "4px 2px", fontSize: "12px" }}><b> </b></div>
																												<div class="teethContentDesItem testNameHead"><span> {data.parameter}</span></div>
																												<div class="teethContentDesItem patientReadingHead"><span>
																													<span style={{ color: "red", fontWeight: "bold" }}>{data.patientReading} </span>
																												</span></div>
																												<div class="teethContentDesItem normalValueHead">
																													<div class="teethDesItemUnit">
																														<span>
																															{data.normalValue}</span>
																													</div>
																												</div>
																											</div>))}
																									</div>
																								</td>
																							</tr>
																						</tbody>
																					</table>
																				</div>
																			</td>
																		</tr>
																	</div>
																	{/* <div class="teethContentDesRow">
								 <div style={{ padding: "4px 2px", fontSize: "12px" }}><b> </b></div>
									<div class="teethContentDesItem testNameHead"><span> - S.G.OT</span></div>
									<div class="teethContentDesItem patientReadingHead"><span>
                                    <span style={{ fontWeight: "bold" }}>33 </span>
									</span></div>
									<div class="teethContentDesItem normalValueHead">
										<div class="teethDesItemUnit">
											<span>
											5
											-40</span>
										</div>
									</div>
								</div> */}
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								))}
								<tr>
									<td>
										<div class="printOutSettingContent" style={{ paddingTop: "80px" }}>
											<table style={{ width: "100%" }} cellspacing="0">
												<tbody>
													<tr>
														<td>
															<div style={{ padding: '0 0', textAlign: "center", display: "block", float: "left", width: "33.33%", fontSize: "13px" }}>
																<span>
																	{txtlabTechnicianPrint}
																	<br />
																	Lab Technician</span>
															</div>
															<div style={{ padding: "0 0", textAlign: "center", display: "block", float: "left", width: "33.33%", fontSize: "13px" }}>
																<span>
																	{txtlabTechnologistPrint}
																	<br />
																	Lab Technologist</span>
															</div>
															<div style={{ padding: "0 0", textAlign: "center", display: "block", float: "left", width: "33.33%", fontSize: "13px" }}>
																<span>
																	{printResultTestisverified}
																	<br />
																	Verified By</span>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>
							</tbody>
							<FooterPrint />
						</table>
						<div class="printBtm" style={{ textAlign: "center", clear: "both", overflow: "hidden" }}>
							<p id="printButton"
								onClick={onPrintButtonClick}
								style={{ width: "70px", textAlign: "center", paddingTop: "5px", lineHeight: "18px", display: "inline-block", fontWeight: "bold", fontSize: "14px", color: "white", background: "rebeccapurple none repeat scroll 0% 0%", marginTop: "2px", cursor: "pointer", borderRadius: "5px", marginBottom: "2px", height: "30px", fontFamily: "Verdana" }}>Print</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default TestResultPrint