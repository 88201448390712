import React, { useEffect, useState } from 'react';
import LabHeader from './layout/header';
import Footer from './layout/footer';
import { Route } from 'react-router-dom';
import LabLeft from './labLeft'
import Datepicker, { CalendarContainer } from 'react-datepicker';
import labPrescription from '../images/icons/lab/labPrescription.png';
import Printer_icon from '../images/icons/printer_icon.png';
import { callingAPI } from '../config';
import BillingLeft from '../billingLogin/billingLeft';
import BillingHeader from '../billingLogin/layout/header';
import { Link } from 'react-router-dom';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
function LabTestBill() {
    const [PopUpodataFlag1, setPopUpodataFlag1] = useState(false);
    const [dataFlag, setDataFlag] = useState(false);
    const [getalltestId, setgetalltestId] = useState('');
    const [labTestDetailedInfo, setLabTestDetailedInfo] = useState([]);
    const [doctorvisitIdTest, setdoctorvisitIdTest] = useState('');
    const [memeberIdTest, setmemeberIdTest] = useState('');
    const [doctorIdTest, setdoctorIdTest] = useState('');
    const [txtVerifiedBy, settxtVerifiedBy] = useState('');
    const [txtlabTechnician, settxtlabTechnician] = useState('');
    const [txtlabTechnologist, settxtlabTechnologist] = useState('');
    const [testResultIdvaluebill, settestResultIdvalueforbill] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [searchType, setSearchType] = useState("0");
    const [searchValue, setSearchValue] = useState('');
    const [isEnabled, setIsEnabled] = useState(true);
    const [labArray, setLabArray] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [totalCount, setTotalCount] = useState('');
    const [booliansearchValue, setbooliansearchValue] = useState("");
    const [boolianfilterName, setboolianfilterName] = useState("");
    const [boolianfromDate, setboolianfromDate] = useState("");
    const [booliantoDate, setbooliantoDate] = useState("");
    useEffect(() => {
        let postData = {
            userId: window.sessionStorage.getItem('userId'),
            selectCombo: "",
            inputSearch: "",
            fromDate: "",
            toDate: "",
            offset: "0",
            max: "10"
        };
        callingAPI('labTestbill/labTestbill', postData)
            .then((response) => {
                if (response.data.success === '1') {
                    setLabArray(response.data.result.lab_array);
                    setTotalCount(response.data.result.datacount);
                    setDataFlag(true);
                }
                else {
                    setLabArray([]);
                    setTotalCount(0);
                    setDataFlag(true);
                }
            })
            .catch();
    }, []);
    const testresultprintSelected = () => {
        if (testResultIdvaluebill.length == "0") {
            alert("Atleast one test required")
        }
        else {
            window.sessionStorage.setItem("doctorIdTest", doctorIdTest)
            window.sessionStorage.setItem("doctorvisitIdTest", doctorvisitIdTest)
            window.sessionStorage.setItem("memeberIdTest", memeberIdTest)
            window.sessionStorage.setItem("txtVerifiedBy", txtVerifiedBy)
            window.sessionStorage.setItem("txtlabTechnician", txtlabTechnician)
            window.sessionStorage.setItem("txtlabTechnologist", txtlabTechnologist)
            window.sessionStorage.setItem("laborderTestIdValue", testResultIdvaluebill.toString().replace(/[\[\]']+/g, ''))
            window.open("testResultPrint");
        }
    }
    const PrintTestresult = (a, b, testResultId, event) => {
        const value = testResultId;
        settestResultIdvalueforbill((item) =>
            testResultIdvaluebill.includes(value)
                ? item.filter((data) => data !== value)
                : [...item, testResultId]
        );
    }
    const txtVerifiedBychange = (event) => {
        settxtVerifiedBy(event.target.value);
    };
    const txtlabTechnicianchange = (event) => {
        settxtlabTechnician(event.target.value);
    };
    const txtlabTechnologistonchange = (event) => {
        settxtlabTechnologist(event.target.value);
    };
    const setReceiptRecptno = (htmlData) => {
        window.sessionStorage.setItem("htmlDataprintid1", htmlData)
        window.open(`${process.env.PUBLIC_URL}/labLogin/labTestBillPrint`, "_blank")
    }
    const testresultprintAll = () => {
        // if(person.length == "0"){
        //     alert("Atleast one test required")
        // }
        // else{
        window.sessionStorage.setItem("doctorIdTest", doctorIdTest)
        window.sessionStorage.setItem("doctorvisitIdTest", doctorvisitIdTest)
        window.sessionStorage.setItem("memeberIdTest", memeberIdTest)
        window.sessionStorage.setItem("txtVerifiedBy", txtVerifiedBy)
        window.sessionStorage.setItem("txtlabTechnician", txtlabTechnician)
        window.sessionStorage.setItem("txtlabTechnologist", txtlabTechnologist)
        window.sessionStorage.setItem("laborderTestIdValue", getalltestId)
        window.open("testResultPrint");
        // }
    }
    const searchTypeOnChange = (event) => {
        if (event.target.value == 0) {
            setIsEnabled(true);
            setSearchType(event.target.value);
            setSearchValue('');
        } else {
            setSearchType(event.target.value);
            setIsEnabled(false);
        }
        if (document.getElementById('selectName').value !== '') {
            document.getElementById('inputBox').disabled = false;
            document.getElementById('inputBox').focus();
        } else {
            document.getElementById('inputBox').disabled = true;
        }
        document.getElementById('inputBox').value = '';
    };
    const searchValueFunction = (event) => {
        setSearchValue(event.target.value);
    };
    const searchClickFunction = () => {
        if (startDate && endDate) {
            if (startDate > endDate) {
                alert('End date must be greater than start date');
                document.getElementById("todate").focus();
                return false;
            }
        }
        if (searchType !== 0 && searchValue.split('').length === 0) {
            if (searchType === '2') {
                alert('Enter Patient Name');
                return false;
            }
            else if (searchType === '1') {
                alert("Enter MR Number");
                return false;
            }
        }
        setActivePage(1);
        setbooliansearchValue(searchValue);
        setboolianfilterName(searchType);
        setboolianfromDate(startDate);
        setbooliantoDate(endDate);
        let postData = {
            userId: window.sessionStorage.getItem('userId'),
            selectCombo: searchType,
            inputSearch: searchValue,
            fromDate: startDate ? formatDate(startDate) : '',
            toDate: endDate ? formatDate(endDate) : '',
            offset: "0",
            max: "10"
        };
        callingAPI('labTestbill/labTestbill', postData)
            .then((response) => {
                if (response.data.success === '1') {
                    setLabArray(response.data.result.lab_array);
                    setTotalCount(response.data.result.datacount);
                    setDataFlag(true);
                }
            })
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    var moment = require('moment');
    const labTestDetailedListInfo = (memberId, testDate, labOrderTestId) => {
        const PostData =
        {
            hospitalId: localStorage.getItem("hospitalId"),
            labOrderTestId: labOrderTestId,
            memberId: memberId,
            testDate: moment(testDate).format('YYYY-MM-DD'),
        };
        setPopUpodataFlag1(false);
        callingAPI('calenderListing/labTestReadingDetails', PostData).then((data) => {
            if (data.data.success === '1') {
                setPopUpodataFlag1(true);
                setgetalltestId(labOrderTestId)
                setLabTestDetailedInfo(data.data.result.testGroupData);
                setdoctorIdTest(data.data.result.testGroupData[0].doctorId)
                setdoctorvisitIdTest(data.data.result.testGroupData[0].doctorVisitId)
                setmemeberIdTest(memberId)
            }
            else {
                setPopUpodataFlag1(true);
            }
        });
    };
    const MyContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
                    </span>
                </div>
            </CalendarContainer>
        );
    };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    const FromDateClear = () => {
        setStartDate("")
    };
    const ToDateClear = () => {
        setEndDate("")
    };
    const MyContainerTo = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
                        {/* <a  className="closeCalander">Close</a> */}
                    </span>
                </div>
            </CalendarContainer>
        );
    };
    return (
        <div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec labTestBillWrapper">
            {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? <BillingHeader /> : <LabHeader />}
            <div className="contentWraper contentWraperScroll">
                {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? <BillingLeft /> : <LabLeft />}
                <div className="settingsRight">
                    <div className="dashboardCover pharmacyManufactureWrap">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="dashboardTitle">Lab Test Bill</div>
                            </div>
                        </div>
                        <div className="dashboardContent">
                            <div className="reportWrap">
                                <div className="dashboardSearch">
                                    <div className="row">
                                        <div className="col-md-2 dashboardSearchList">
                                            {/* <select className="inputField"
                                                id="selectName"
                                                value={searchType}
                                                onChange={searchTypeOnChange}>
                                                <option value="0">Select</option>
                                                <option value="2">Patient Name</option>
                                                <option value="1">MR Number</option>
                                            </select> */}
                                            <select
                                                autocomplete="off"
                                                className="inputField"
                                                id="selectName"
                                                value={searchType}
                                                onChange={searchTypeOnChange}
                                            >
                                                <option value="">Select</option>
                                                {window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<option value="1">MR Number</option>)}
                                                <option value="2">Patient Name</option>
                                                {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<option value="3">PR Number</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <input type="text" className="inputField" id="inputBox" placeholder='Enter Value'
                                                value={searchValue}
                                                disabled={isEnabled}
                                                onChange={searchValueFunction}
                                                style={{ background: searchType === "0" ? "transparent" : "white" }} />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <Datepicker
                                                autoComplete='off'
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                required
                                                closeCalendar="true"
                                                className="Datepicker pa2 inputField"
                                                placeholderText="From Date"
                                                id="fromdate" calendarContainer={MyContainer}
                                                onChangeRaw={handleDateChangeRaw}
                                                calendarClassName="rasta-stripes"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList">
                                            <Datepicker
                                                autoComplete='off'
                                                peekNextMonth
                                                showMonthDropdown
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                showYearDropdown
                                                dropdownMode="select"
                                                required
                                                closeCalendar="true"
                                                id="todate" calendarContainer={MyContainerTo}
                                                onChangeRaw={handleDateChangeRaw}
                                                className="Datepicker pa2 inputField"
                                                placeholderText="To Date"
                                                calendarClassName="rasta-stripes"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                        <div className="col-md-2 dashboardSearchList"  >
                                            <span className="dashButtonBorder labButton" style={{ padding: "8px 20px", height: "35px" }} onClick={searchClickFunction}>Search</span>
                                        </div>
                                    </div>
                                </div>
                                {dataFlag == false ? (
                                    <Loader />
                                ) :
                                    labArray.length > 0 ? (
                                        <div>
                                            {/*  */}
                                            {labArray.map((data, i) => (
                                                <div class="treatmentPlanList">
                                                    <div class="treatmentPlanDate">{data.testDate} </div>
                                                    <div class="treatmentTable planActive">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th class="col-md-3"><div class="dentalProName">Patient Name</div></th>
                                                                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                        (<th class="col-md-2"><div class="dentalProName">MR No</div></th>) :
                                                                        (<th class="col-md-2"><div class="dentalProName">PR No</div></th>)
                                                                    }
                                                                    <th class="col-md-3"><div class="dentalProName">Receipt No</div></th>
                                                                    <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                                    <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.item.map((obj, i) => (
                                                                    <tr class="groupBrdr">
                                                                        <td class="col-md-3">
                                                                            <div class="dentalProName paddingFive">
                                                                                <span class="elipsis">
                                                                                    {obj.patientName}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                            (<td class="col-md-2"><div class="dentalProName paddingFive">{obj.mrNo}</div></td>) :
                                                                            (<td class="col-md-2"><div class="dentalProName paddingFive">{obj.prNo}</div></td>)
                                                                        }
                                                                        <td class="col-md-3"><div class="dentalProName paddingFive">{obj.receiptNo}</div></td>
                                                                        <td class="col-md-2">
                                                                            <div class="dentalProName ">
                                                                                <span class="viewDetailLink">
                                                                                    {/* <a href=""> View &amp; Print Bill</a> */}
                                                                                    <a >        {obj.receiptNo != '' ?
                                                                                        <Link to="#" style={{ cursor: "pointer" }} onClick={() => setReceiptRecptno(obj.receiptId)}>View & Print Bill</Link>
                                                                                        : ''}</a>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="col-md-3">
                                                                            <div class="dentalProName txtCenter">
                                                                                <span class="viewDetailLink clrBlack" style={{ display: "inline-block", marginRight: "10px" }}>
                                                                                    <Link to={{ pathname: '/patients/testResultAdd', state: { testmemberId: obj.memberId, istestFlag: "TestResult" } }}> <img src={labPrescription} title="Add Test Result" /></Link>
                                                                                </span>
                                                                                <span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Test Result" style={{ display: "inline-block", verticalAlign: "middle" }} onClick={() => labTestDetailedListInfo(obj.memberId, data.testDate, obj.labOrderTestId)}>
                                                                                    {/* <span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Test Result" style={{ display: "inline-block", verticalAlign: "middle" }}  onClick={() => labTestDetailedListInfo(363493, "2022-06-28", "5701,5702,5703,5704,5705")}> */}
                                                                                    <a href='#labTestDetailedInfo'>  <img src={Printer_icon} title="Printer_icon" /></a>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}</tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>
                                            <NoData />
                                        </p>
                                    )}
                                {/* <div class="treatmentPlanList">
                                    <div class="treatmentPlanDate"> 28, Aug 2019   </div>
                                    <div class="treatmentTable planActive">
                                         <table>
                                            <thead>
                                                <tr>
                                                    <th class="col-md-3"><div class="dentalProName">Patient Name</div></th>
                                                    <th class="col-md-2"><div class="dentalProName">MR No</div></th>
                                                    <th class="col-md-3"><div class="dentalProName">Receipt No</div></th>
                                                    <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                    <th class="col-md-2"><div class="dentalProName">&nbsp;</div></th>
                                                </tr>
                                            </thead>
						                    <tbody>
								                <tr class="groupBrdr">
                                                    <td class="col-md-3">
                                                        <div class="dentalProName paddingFive">
                                                        <span class="elipsis">
                                                        ABHAY SAJU.S
                                                        </span>
                                                        </div>
                                                    </td>
                                                    <td class="col-md-2"><div class="dentalProName paddingFive">22 </div></td>
                                                    <td class="col-md-3"><div class="dentalProName paddingFive">RCP303 </div></td>
										            <td class="col-md-2">
														<div class="dentalProName ">
															<span class="viewDetailLink">
																<a href=""> View &amp; Print Bill</a>
															</span>
														</div>
													</td>
													<td class="col-md-3">
														<div class="dentalProName txtCenter">
															<span class="viewDetailLink clrBlack" style={{display:"inline-block",marginRight: "10px"}}>
												                <img src={labPrescription}   title="Add Test Result"/>
															</span>
                                                            <span class="viewDetailLink viewAndPrint clrBlack" title="View &amp; Print Test Result" style={{ display: "inline-block", verticalAlign: "middle" }}>
                                                              <a href='#labTestDetailedInfo'>  <img src={Printer_icon} title="Printer_icon" /></a>
															</span>
														</div>
													</td>
												</tr>
												</tbody>
											</table>
                                    </div>
			                    </div> */}
                                {/* PRINNT 2nd MODAL BEGINS HERE */}
                                <div id="labTestDetailedInfo" class="modalDialog">
                                    <div>
                                        <a href="#close" title="Close" class="close exportClose">X</a>
                                        <div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
                                            <div class="addDataHead">
                                                <span className='labTitle'>Lab Test Details</span>
                                            </div>
                                            {PopUpodataFlag1 == false ? (
                                                <Loader />
                                            ) : labTestDetailedInfo.length > 0 ? (
                                                <div>
                                                    {labTestDetailedInfo.map((itemDetail, a) => (
                                                        <div class="addDataContent" id="medicineDetails">
                                                            <div class="patientNameDiv row">
                                                                <span class="col-md-2 patientName patientDate">
                                                                    <span>{itemDetail.createdOn}</span>
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {itemDetail.labData.map((items, b) => (
                                                                    <div class="treatmentTable planActive">
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th class="col-md-4"><div class="dentalProCost txtLeft">Test Name (Type)</div></th>
                                                                                    <th class="col-md-4"><div class="dentalProDis txtLeft">Normal Value</div></th>
                                                                                    <th class="col-md-4"><div class="dentalProNote txtLeft">Patient Readings</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr class="groupBrdr">
                                                                                    <td colspan="3" class="col-md-12">
                                                                                        <div class="dentalProName">
                                                                                            <div class="optionWrapper">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={
                                                                                                        'chkSelectValue' +
                                                                                                        items.testId}
                                                                                                    onClick={() =>
                                                                                                        PrintTestresult(
                                                                                                            a,
                                                                                                            b,
                                                                                                            items.testId
                                                                                                        )}
                                                                                                />
                                                                                                <label
                                                                                                    for={
                                                                                                        'chkSelectValue' +
                                                                                                        items.testId
                                                                                                    }
                                                                                                />
                                                                                                <label for="chkSelect01"></label>
                                                                                            </div>
                                                                                            <div class="dentalProCost txtLeft" style={{ paddingTop: "0px", paddingBottom: "0", fontWeight: "600", width: "95%" }}>
                                                                                                {items.testName}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                {items.labDetails.map((labiteminfo, l) => (
                                                                                    <>
                                                                                        <tr class="groupBrdr">
                                                                                            <td class="col-md-4"><div class="dentalProDis txtLeft" style={{ paddingLeft: "5px" }}> - {labiteminfo.parameter}</div></td>
                                                                                            <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                                                {labiteminfo.normalValue}
                                                                                            </div></td>
                                                                                            <td class="col-md-4">
                                                                                                <div class="dentalProNote paddingEight">
                                                                                                    <span class="elipsis">
                                                                                                        <span style={{ fontWeight: "bold" }}>
                                                                                                            {labiteminfo.patientReading} </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr class="groupBrdr">
                                                                                            <td colspan="3" class="col-md-12">
                                                                                                <div class="dentalProName">
                                                                                                    <b></b>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))}
                                                                                <tr class="dentalProPlaned">
                                                                                    <td colspan="3"><div class="plannedBy">Noted by <span> {items.notedBy}</span></div></td>
                                                                                    <td colspan="2"></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div class="serviceProviderVerfied">
                                                                <span>Verified By : </span>
                                                                <input type="text" onChange={txtVerifiedBychange} />
                                                                <span>Lab Technician : </span>
                                                                <input type="text" onChange={txtlabTechnicianchange} />
                                                                <span>Lab Technologist : </span>
                                                                <input type="text" onChange={txtlabTechnologistonchange} />
                                                            </div>
                                                            <div class="serviceProviderBtns">
                                                                <input type="submit" value="Print Selected" onClick={testresultprintSelected} style={{ background: "#177f40" }} />
                                                                <input type="submit" value="Print All" id="btnPrintAll" onClick={testresultprintAll} style={{ background: "#177f40" }} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div class="noDataFoundCommon treatmentCommonTxtWrap">
                                                    <span class="treatmentCommonTxt">
                                                        <span class="addNewRcdTxt">No Test Result Found </span>
                                                    </span>
                                                </div>
                                            )}
                                            {/* --- */}
                                            {/* <div class="addDataContent" id="medicineDetails">
                                                    <div class="patientNameDiv row">
                                                        <span class="col-md-2 patientName patientDate">
                                                            <span>28, Aug 2019</span>
                                                        </span>
                                                </div>
                                                <div class="treatmentTable planActive">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th class="col-md-4"><div class="dentalProCost txtLeft">Test Name (Type)</div></th>
                                                            <th class="col-md-4"><div class="dentalProDis txtLeft">Normal Value</div></th>
                                                            <th class="col-md-4"><div class="dentalProNote txtLeft">Patient Readings</div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="groupBrdr">
                                                            <td colspan="3" class="col-md-12">
                                                                        <div class="dentalProName">
                                                                            <div class="optionWrapper">
                                                                                <input type="checkbox" id="chkSelect01" name="chkSelect01"/>
                                                                                <label for="chkSelect01"></label>
                                                                            </div>
                                                                            <div class="dentalProCost txtLeft" style={{ paddingTop: "0px", paddingBottom: "0", fontWeight: "600", width: "95%" }}>
                                                                                    BLOOD -SEROLOGY FOR HIV,HCV,HBsAg (Bilirubin Total) (Specimen Type:Blood)
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                        </tr>
                                                        <tr class="groupBrdr">
                                                            <td colspan="3" class="col-md-12">
                                                                <div class="dentalProName">
                                                                        <b></b>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="groupBrdr">
                                                                    <td class="col-md-4"><div class="dentalProDis txtLeft"> - Rapid immunochromoatography for HIVI and HIV2</div></td>
                                                                        <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                            REACTIVE,NON REACTIVE
                                                                        </div></td>
                                                                        <td class="col-md-4">
                                                                        <div class="dentalProNote paddingEight">
                                                                            <span class="elipsis">
                                                                                    <span style={{ fontWeight: "bold" }}>
                                                                                1 </span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="groupBrdr">
                                                                    <td colspan="3" class="col-md-12">
                                                                    <div class="dentalProName">
                                                                        <b></b>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="groupBrdr">
                                                                    <td class="col-md-4"><div class="dentalProDis txtLeft"> - Rapid immunochromoatography for HbsAg</div></td>
                                                                        <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                            positive, negative
                                                                        </div></td>
                                                                        <td class="col-md-4">
                                                                        <div class="dentalProNote paddingEight">
                                                                            <span class="elipsis">
                                                                                <span style={{ fontWeight: "bold" }}>
                                                                                1 </span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="groupBrdr">
                                                                    <td colspan="3" class="col-md-12">
                                                                    <div class="dentalProName">
                                                                        <b></b>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="groupBrdr">
                                                                    <td class="col-md-4"><div class="dentalProDis txtLeft"> - HCV</div></td>
                                                                        <td class="col-md-4"><div class="dentalProDis txtLeft">
                                                                            REACTIVE,NON REACTIVE
                                                                        </div></td>
                                                                        <td class="col-md-4">
                                                                        <div class="dentalProNote paddingEight">
                                                                            <span class="elipsis">
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                1 </span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            <tr class="dentalProPlaned">
                                                                <td colspan="2"><div class="plannedBy">Noted by <span>Dr. Nagarjun Mishra Ima</span></div></td>
                                                                <td colspan="1"><div class="estimatedPrice"></div></td>
                                                            </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                <div class="serviceProviderVerfied">
                                                    <span>Verified By : </span>
                                                    <input type="text"    />
                                                    <span>Lab Technician : </span>
                                                    <input type="text"   />
                                                    <span>Lab Technologist : </span>
                                                    <input type="text"    />
                                                </div>
                                             <div class="serviceProviderBtns">
                                                 <input type="submit"   value="Print Selected" style={{ background: "#177f40" }}/>
                                                 <input type="submit"   value="Print All" id="btnPrintAll" style={{ background: "#177f40" }}/>
                                               </div>
                                                </div> */}
                                            {/* --- */}
                                        </div>
                                    </div>
                                </div>
                                {/* PRINNT 2nd MODAL ENDS HERE */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default LabTestBill;
