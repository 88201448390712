import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { callingAPI } from '../config';
import NoData from '../Common/noDataFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import pulseLoader from '../images/icons/pulseLoader.png';
import Loader from '../Common/loader';
function Patientpayment() {
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [functionName] = useState('PatientPayment');
	const [paymentDetails, setPaymentDetails] = useState([]);
	const [count, setCount] = useState('');
	const [totalPayment, settotalPayment] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [searchValue, setSearchValue] = useState('');
	const [searchType, setSearchType] = useState(0);
	const [isEnabled, setIsEnabled] = useState(true);
	const [dropDown, setDropDown] = useState('');
	const [pageOffset, setPageOffset] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");

	const exportFunction = () => {
		const csvRow = [];
		var patientArray = [['Date', 'Patient%20Name', 'Payment%20Towards', 'Procedure%20Name', 'Amount']];
		var fullDetails = paymentDetails;
		let postData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: '',
			offset: 0,
			cmbSelect: searchType,
			txtInput: searchValue,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			cmbRoomtype: dropDown,
		};
		callingAPI('reports/patientPayment', postData).then((response) => {
			for (var item = 0; item < response.data.result.patientDetails.length; item++) {
				patientArray.push([
					response.data.result.patientDetails[item].memberProedureInvoiceDate.replace(/,/g, '').replace(/ /g, '%20'),
					response.data.result.patientDetails[item].memberName.replace(/ /g, '%20'),
					response.data.result.patientDetails[item].memberProedureInvoiceDescription === "" ? "%20" : response.data.result.patientDetails[item].memberProedureInvoiceDescription.replace(/,/g, '/').replace(/ /g, '%20'),
					response.data.result.patientDetails[item].pmTreatmentsProcedureName.replace(/ /g, '%20'),
					"Rs." + "" + response.data.result.patientDetails[item].memberProedureInvoiceAmount.replace(/,/g, ''),
				]);
			}
			for (var i = 0; i < patientArray.length; ++i) {
				csvRow.push(patientArray[i].join('|'));
			}
			var csvString = csvRow.join('%0A');
			var a = document.createElement('a');
			a.href = 'data:attachment/csv,' + csvString;
			// a.target = '_Blank';
			a.download = functionName + '.csv';
			document.body.appendChild(a);
			a.click();
		});
	};
	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) { setSearchType("") }
		if (!booliansearchValue) { setSearchValue("") }
		if (!boolianfromDate) { setStartDate("") }
		if (!booliantoDate) { setEndDate("") }
		let postData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: max,
			offset: offset,
			cmbSelect: boolianfilterName,
			txtInput: booliansearchValue,
			fromDate: boolianfromDate ? formatDate(startDate) : '',
			toDate: booliantoDate ? formatDate(endDate) : '',
			cmbRoomtype: dropDown,
		};
		callingAPI('reports/patientPayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setPaymentDetails(response.data.result.patientDetails);
					setCount(response.data.result.count);
					settotalPayment(response.data.result.totalPatientPayment);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	const searchTypeOnChange = (event) => {
		if (event.target.value == 0) {
			setIsEnabled(true);
			setSearchType(event.target.value);
			setSearchValue('');
		} else {
			setSearchType(event.target.value);
			setIsEnabled(false);
		}
	};
	const searchValueFunction = (event) => {
		event.target.value =
			event.target.value.split('').length === 1 ? event.target.value.replace(/\s/, '') : event.target.value;
		setSearchValue(event.target.value);
	};
	const changeTowards = (event) => {
		setDropDown(event.target.value);
	};
	const searchClickFunction = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				alert(' End date must be greater than or equal to start date');
				document.getElementById('todate').focus();
				return false;
			}
		}

		if (searchType !== 0 && searchValue.split('').length === 0) {
			if (searchType === '1') {
				alert('Enter Patient Name');
				return false;
			} else if (searchType === '2') {
				alert('Enter Procedure Name');
				return false;
			}
		}
		setActivePage(1);
		setbooliansearchValue(searchValue);
		setboolianfilterName(searchType);
		setboolianfromDate(startDate);
		setbooliantoDate(endDate);
		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: 10,
			offset: 0,
			cmbSelect: searchType,
			txtInput: searchValue,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			cmbRoomtype: dropDown,
		};
		callingAPI('reports/patientPayment', postData)
			.then((response) => {
				if (response.data.success === '0') {
					setPaymentDetails([]);
					setCount(0);
					settotalPayment(0);
				}
				if (response.data.success === '1') {
					setPaymentDetails(response.data.result.patientDetails);
					setCount(response.data.result.count);
					settotalPayment(response.data.result.totalPatientPayment);
				}
			})
			.catch();
	};
	useEffect(() => {
		let postData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
		};
		callingAPI('reports/patientPayment', postData)
			.then((response) => {
				if (response.data.success === '1') {
					setPaymentDetails(response.data.result.patientDetails);
					setCount(response.data.result.count);
					settotalPayment(response.data.result.totalPatientPayment);
					setDataFlag(true);
				}
				else { setDataFlag(true) }
			})
			.catch();
	}, []);

	const FromDateClear = () => {
		setStartDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		setEndDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	return (
		<div>
			<div className="dashboardCover">
				<div className="dashboardTitle">Patient Payment</div>
				{count > 10 && (
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={count}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePagination}
						/>
					</div>
				)}
				<div className="dashboardContent">
					<div className="reportWrap">
						<div className="dashboardSearch">
							<div className="row">
								<div className="col-md-2 dashboardSearchList">
									<select
										className="input-design inputField"
										name="selectName"
										value={searchType}
										onChange={searchTypeOnChange}
									>
										<option value="0">Search In</option>
										<option value="1">Patient Name</option>
										<option value="2">Procedure Name</option>
									</select>
								</div>
								<div className="col-md-2 dashboardSearchList">
									{searchType == 0 ?
										<input
											className="input-design inputField"
											type="text"
											value={searchValue}
											disabled={isEnabled}
											// onChange={searchValueFunction}
											name="searchText"
											style={{ background: "transparent" }}
											autoComplete="off"
										/>
										:
										<input
											className="input-design inputField"
											type="text"
											value={searchValue}
											// disabled={isEnabled}
											onChange={searchValueFunction}
											name="searchText"
											autoComplete="off"
										/>
									}
								</div>
								{/* <div className="col-md-2 dashboardSearchList">
									<select className="inputField" value={dropDown} onChange={changeTowards}>
										<option selected value="">
											Payment Towards
										</option>
										<option value="Lab">Lab</option>
										<option value="Laundry">Laundry</option>
										<option value="Pharmacy">Pharmacy</option>
										<option value="Procedure">Procedure</option>
									</select>
								</div> */}
								<div className="col-md-2 dashboardSearchList">
									<div>
										<Datepicker
											className="inputField"
											selected={startDate}
											placeholderText="From"
											calendarClassName="rasta-stripes"
											dateFormat="dd-MM-yyyy"
											onChange={(date) => setStartDate(date)}
											id="fromdate" calendarContainer={MyContainer}
											onChangeRaw={handleDateChangeRaw}
										/>
										<label
											className="icon iconCalendar calendar plReportsCalendar"
											style={{ fontSize: '16px' }}
											htmlFor="fromdate"
										>
											<FontAwesomeIcon icon={faCalendarAlt} />
										</label>
									</div>
								</div>
								<div className="col-md-2 dashboardSearchList">
									<div>
										<Datepicker
											className="inputField"
											selected={endDate}
											placeholderText="To"
											calendarClassName="rasta-stripes"
											dateFormat="dd-MM-yyyy"
											onChange={(date) => setEndDate(date)}
											id="todate" calendarContainer={MyContainerTo}
											onChangeRaw={handleDateChangeRaw}
										/>
										<label
											className="icon iconCalendar calendar plReportsCalendar"
											style={{ fontSize: '16px' }}
											htmlFor="todate"
										>
											<FontAwesomeIcon icon={faCalendarAlt} />
										</label>
									</div>
								</div>
								<div className="col-md-2 dashboardSearchList">
									<span onClick={searchClickFunction} className="dashButtonBorder">
										Search
									</span>
								</div>
								<div className="col-md-2 dashboardSearchList">
									<span onClick={exportFunction} className="dashButtonBorder dashButtonBorderGrey">
										Export
									</span>
								</div>
							</div>
							<div className="row">
								<div className="col-md-9">
									<div className="searchTxt" style={{ textAlign: 'right', fontWeight: '600' }}>
										Total number of  payments : &#8377; {paymentDetails.length == 0 ? 0 : totalPayment}
									</div>
								</div>
								<div className="col-md-3">
									<div className="searchCount">
										Total patient payment : {paymentDetails.length == 0 ? 0 : count}{' '}
									</div>
								</div>
							</div>
						</div>
						{dataFlag == false ? (
							<Loader />
						) : paymentDetails.length > 0 ? (
							<div className="dashboardtable">
								<table style={{ tableLayout: 'auto' }}>
									<thead>
										<tr>
											<th>Date</th>
											<th>Patient Name </th>
											<th>Payment Towards</th>
											<th>Procedure Name</th>
											<th>Refund Status</th>
											<th>Invoice No</th>
											<th style={{ textAlign: 'right' }}>Amount</th>
										</tr>
									</thead>
									<tbody>
										{paymentDetails.map((data, i) => (
											<tr>
												<td>{data.memberProedureInvoiceDate}</td>
												<td>{data.memberName}</td>
												<td>{data.memberProedureInvoiceDescription.replace(/,/g, ', ')}</td>
												<td>{data.pmTreatmentsProcedureName}</td>
												<td>{data.refundStatus}</td>
												<td>{data.invpoiceNo}</td>
												<td style={{ textAlign: 'right' }}>
													&#8377; {data.memberProedureInvoiceAmount}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<p>
								<NoData />
							</p>
						)}
					</div>
					{/* {process.env.REACT_APP_PAGINATION_COUNT > 10 && ( */}
					{count > 10 && (
						<div className="paginationSection">
							<Pagination
								// hideNavigation
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={count}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={handlePagination}
							/>
						</div>
					)}
					{/* {paymentDetails.length <= 0 && <NoData />} */}
				</div>
			</div>
		</div>
	);
}
export default Patientpayment;
