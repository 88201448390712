import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import $ from "jquery";
import moment from 'moment';

function LaborderList() {

    const [LaborderList, setLaborderList] = useState([]);
    const [role, setRole] = useState(window.sessionStorage.getItem("role"));
    useEffect(() => {
        labOrderStatusList();
    }, []);

    const labOrderStatusList = () => {

        let postData = {
            labOrderId: "",
            memberId: "",
            hospitalId: localStorage.getItem('hospitalId'),
            doctorId: role == "ROLE_DOCTOR" ? window.sessionStorage.getItem('doctorId') : "",
            searchInput: "",
            cmbStatus: "",
            offset: "",
            max: "",
            dueDateSelect: ""
        }
        callingAPI('commonUserFunctions/getDashboardLabOrderDetails', postData).then((response) => {

            if (response.data.success === "1") {
                setLaborderList(response.data.result)
            }

        })
    }


    return (
        <>
            <div className="todayappoimnt_wrapr">
                <div className="total_revnuheader">
                    <h2 className='total_revnuheadtxt'>Lab Order List(s)</h2>
                </div>
                <div className='todayAppScroll111 todayAppScroll-max111'>

                    <table class="labOrderListTable fixed_header">
                        <thead>
                            <tr>
                                <th >Job No</th>
                                <th  >Doctor Name</th>
                                <th>Lab</th>
                                <th>Work Name</th>
                                <th>Due Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {LaborderList.length > 0 ?
                                <>
                                    {LaborderList.map((list) =>
                                        <tr>
                                            <td >{list.id}</td>
                                            <td  >{list.doctorName}</td>
                                            <td>{list.dentalLabName}</td>
                                            <td>{list.dentalLabWorkName}</td>
                                            <td>{moment(list.dueDate).format("DD/MMM/YYYY")}</td>
                                            <td><span className={
                                                list.dentalLabStatusName === "In Transit"
                                                    ? "tblStatusClr transistCountBg"
                                                    : list.dentalLabStatusName === "Active"
                                                        ? "tblStatusClr activeCountBg"
                                                        : list.dentalLabStatusName === "Sent"
                                                            ? "tblStatusClr sentCountBg"
                                                            : list.dentalLabStatusName === "Overdue"
                                                                ? "tblStatusClr overdueCountBg"
                                                                : list.dentalLabStatusName === "In Production"
                                                                    ? "tblStatusClr prodCountBg"
                                                                    : list.dentalLabStatusName === "Received"
                                                                        ? "tblStatusClr receivedCountBg"
                                                                        : ""
                                            }>
                                                {list.dentalLabStatusName}
                                            </span></td>
                                        </tr>
                                    )}
                                </>
                                :
                                <div className='todayAppScroll nodataFnd'>
                                    <p>No Lab Order List Found </p>
                                </div>

                            }
                        </tbody>
                    </table>

                </div>
            </div>

        </>
    )
}

export default LaborderList