import React, { useEffect, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import Pagination from 'react-js-pagination';
import { callingAPI } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import moment from 'moment';
function Pharmacystockdetails() {
	const [medicine, setmedicineData] = useState([]);
	const [fromDate, setfromtDate] = useState();
	const [toDate, settoDate] = useState();
	const Download = 'Pharmacy Stock Details';
	const [count, setCount] = useState('');
	const [activePage, setActivePage] = useState(1);
	const [sellingprice, setsellingprice] = useState();
	const [sellingunitprice, setsellingunitprice] = useState();
	const [searchValue, setsearchValue] = useState('');
	const [filterName, setFilterInput] = useState('');
	const [dataFlag, setDataFlag] = useState(false);
	const [pageOffset, setPageOffset] = useState(0);
	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	var date = new Date();
	var requiredDate = moment(date).format('DD-MM-YYYY');
	var toDay = requiredDate.split('-')
	toDay = new Date(toDay[2], (toDay[1] - 1), toDay[0])
	useEffect(() => {
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: pageOffset,
			cmbSelect: filterName,
			txtInput: searchValue,
			doctorId: window.sessionStorage.getItem('userId'),
		};
		callingAPI('reports/pharmacyStockDetails', PostData).then((response) => {
			if (response.data.success === '1') {
				setmedicineData(response.data.result.medicine);
				setCount(response.data.result.count);
				setsellingprice(response.data.result.totalSellingPrice);
				setsellingunitprice(response.data.result.totalSellingUnitPrice);
				setDataFlag(true);
			}
			else if (response.data.success === '0') {
				setmedicineData("");
				setCount("");
				setsellingprice("");
				setsellingunitprice("");
				setDataFlag(true);
				alert(response.data.errorMessage)
			}
		});
	}, []);
	const searchFunction = () => {
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				alert('To date should be greater than or equal to from date');
			}
		}
		if (
			document.getElementById('selectFilterName').value !== '' &&
			document.getElementById('searchText').value === ''
		) {
			var t = document.getElementById('selectFilterName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('searchText').focus();
			alert('Enter' + ' ' + selectedText);
			return false;
		}
		setbooliansearchValue(searchValue);
		setboolianfilterName(filterName);
		setboolianfromDate(fromDate);
		setbooliantoDate(toDate);
		let PostData = {
			userId: window.sessionStorage.getItem('userId'),
			role: window.sessionStorage.getItem('role'),
			max: 10,
			offset: 0,
			cmbSelect: filterName,
			txtInput: searchValue,
			fromDate: fromDate ? formatDate(fromDate) : '',
			toDate: toDate ? formatDate(toDate) : '',
			doctorId: window.sessionStorage.getItem('userId'),
		};
		callingAPI('reports/pharmacyStockDetails', PostData).then((response) => {
			if (response.data.success === '0') {
				setmedicineData([]);
				setActivePage(0);
			} else if (response.data.success === '1') {
				setmedicineData(response.data.result.medicine);
				setsellingprice(response.data.result.totalSellingPrice);
				setsellingunitprice(response.data.result.totalSellingUnitPrice);
				setCount(response.data.result.count);
				setActivePage(1);
				setDataFlag(true);
			}
		});
	};
	function formatDate(date) {
		var dates = new Date(date),
			day = '' + dates.getDate(),
			month = '' + (dates.getMonth() + 1),
			year = dates.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	function medicineFileDownload() {
		const csvRow = [];
		var Stocklist = [
			[
				'Medicine%20Name',
				'Total%20Available%20Quantity',
				'Batch%20Number',
				'Available%20Quantity',
				'Expiry%20Date',
				'Selling%20Price',
				'Selling%20UnitPrice',
			],
		];
		var re = medicine;
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			max: "",
			offset: 0,
			cmbSelect: boolianfilterName,
			txtInput: booliansearchValue,
			fromDate: boolianfromDate ? formatDate(fromDate) : "",
			toDate: booliantoDate ? formatDate(toDate) : "",
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('reports/pharmacyStockDetails', PostData).then((response) => {
			for (var item = 0; item < response.data.result.medicine.length; item++) {

				Stocklist.push([
					response.data.result.medicine[item].medicineName.replace(/ /g, '%20'),
					response.data.result.medicine[item].pmHospitalMedicineStocktotalqty,
					response.data.result.medicine[item].pmHospitalMedicineBatchNo,
					response.data.result.medicine[item].pmHospitalMedicineStockqty,
					response.data.result.medicine[item].pmHospitalMedicineStockExpDate1.replace(/ /g, '%20'),
					"Rs." + "" + response.data.result.medicine[item].pmHospitalMedicineSellingPrice.toFixed(2),
					"Rs." + "" + response.data.result.medicine[item].pmHospitalMedicineUnitPrice.toFixed(2),
				]);
			}
			for (var i = 0; i < Stocklist.length; ++i) {
				csvRow.push(Stocklist[i].join(','));
			}
			var csvString = csvRow.join('%0A');
			var a = document.createElement('a');
			a.href = 'data:attachment/csv,' + csvString;
			a.target = '_Blank';
			a.download = Download + '.csv';
			document.body.appendChild(a);
			a.click();
		})
	};
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) { setFilterInput("") }
		if (!booliansearchValue) { setsearchValue("") }
		if (!boolianfromDate) { setfromtDate("") }
		if (!booliantoDate) { settoDate("") }
		let PostData = {
			userId: window.sessionStorage.getItem("userId"),
			role: window.sessionStorage.getItem("role"),
			max: max,
			offset: offset,
			cmbSelect: boolianfilterName,
			txtInput: booliansearchValue,
			fromDate: boolianfromDate ? formatDate(fromDate) : "",
			toDate: booliantoDate ? formatDate(toDate) : "",
			hospitalId: localStorage.getItem("hospitalId"),
		};
		setDataFlag(false);
		callingAPI('reports/pharmacyStockDetails', PostData).then((response) => {
			if (response.data.success === '1') {
				setmedicineData(response.data.result.medicine);
				setsellingprice(response.data.result.totalSellingPrice);
				setsellingunitprice(response.data.result.totalSellingUnitPrice);
				setDataFlag(true);
			}
		});
	};
	const filterBymedicine = (value) => {
		setsearchValue(value);
	};
	const changeText = (value) => {
		setsearchValue('');
		setFilterInput(value);
		if (value === '') {
			setsearchValue('');
			document.getElementById('searchText').disabled = true;
		} else {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		}
	};
	const onKeyDown = (event) => {
		if (event.key === 'Enter') {
			// alert("ssssssssss")
			searchFunction();
		}
	};
	const FromDateClear = () => {
		setfromtDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const ToDateClear = () => {
		settoDate("")
	};

	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	return (
		<div className="dashboardCover">
			<div className="dashboardTitle">Pharmacy Stock Details</div>
			{dataFlag == false ? (
				<div align="center">
					<Loader />
				</div>
			) : (
				<div>
					{dataFlag == true && (
						<div className="dashboardContent">
							{medicine.length !== 0 && (
								<div>
									{count > 10 &&
										<div className="paginationSection">
											<Pagination
												// hideNavigation
												hideDisabled
												activePage={activePage}
												itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
												totalItemsCount={count}
												pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
												onChange={handlePageChange}
											/>
										</div>
									}
								</div>
							)}
							<div className="reportWrap">
								<div className="dashboardSearch">
									<div className="row">
										<div className="col-md-2 dashboardSearchList">
											<select
												className="inputField"
												id="selectFilterName"
												value={filterName}
												name="selectFilterName"
												onKeyDown={onKeyDown}
												onChange={(e) => changeText(e.target.value)}
											>
												<option value="">Search In</option>
												<option value="1">Medicine Name </option>
												<option value="2"> Batch Number</option>
											</select>
										</div>
										<div className="col-md-2 dashboardSearchList">
											{filterName == "" ? (
												<input
													type="text"
													className="inputField"
													value={searchValue}
													onKeyDown={onKeyDown}
													onChange={(e) => filterBymedicine(e.target.value)}
													id="searchText"
													name="searchText"
													style={{ background: "transparent" }}
													disabled
												/>
											) : (
												<input
													type="text"
													className="inputField"
													value={searchValue}
													onChange={(e) => filterBymedicine(e.target.value)}
													id="searchText"
													name="searchText"
													onKeyDown={onKeyDown}
													autofocus="autofocus"
													style={{ background: "white" }}
												/>
											)}
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<DatePicker
													className="inputField"
													selected={fromDate}
													dateFormat="dd-MM-yyyy"
													placeholderText="From Date"
													// maxDate={new Date()}
													onChange={(date) => setfromtDate(date)}
													onKeyDown={onKeyDown}
													id="fromdate"
													showMonthDropdown
													showYearDropdown
													autoComplete='off'
													dropdownMode="select"
													calendarContainer={MyContainer}
													onChangeRaw={handleDateChangeRaw}
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="fromdate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<div>
												<DatePicker
													className="inputField"
													selected={toDate}
													dateFormat="dd-MM-yyyy"
													placeholderText="To Date"
													// maxDate={new Date()}
													autoComplete='off'
													onKeyDown={onKeyDown}
													onChange={(date) => settoDate(date)}
													id="todate"
													showMonthDropdown
													showYearDropdown
													dropdownMode="select"
													calendarContainer={MyContainerTo}
													onChangeRaw={handleDateChangeRaw}
												/>
												<label
													className="icon iconCalendar calendar plReportsCalendar"
													style={{ fontSize: '16px' }}
													htmlFor="todate"
												>
													<FontAwesomeIcon icon={faCalendarAlt} />
												</label>
											</div>
										</div>
										<div className="col-md-2 dashboardSearchList">
											<span className="dashButtonBorder" onKeyDown={onKeyDown} onClick={searchFunction}>
												Search
											</span>
										</div>
										{!medicine.length ? (
											<div className="col-md-2 dashboardSearchList">
												<button
													type="button"
													style={{ width: '100%' }}
													id="export"
													className="dashButtonBorder dashButtonBorderGrey"
													disabled
												>
													Export
												</button>
											</div>
										) : (
											<div className="col-md-2 dashboardSearchList">
												<button
													type="button"
													style={{ width: '100%' }}
													onClick={medicineFileDownload}
													id="export"
													className="dashButtonBorder dashButtonBorderGrey"
												>
													Export
												</button>
											</div>
										)}
									</div>
									<div className="row">
										<div className="col-md-4">
											<div className="searchTxt">Total Selling Price : &#8377; {sellingprice}</div>
										</div>
										<div className="col-md-4">
											<div className="searchTxt">
												Total Selling Unit Price : &#8377; {sellingunitprice}
											</div>
										</div>
										<div className="col-md-4">
											<div className="searchCount">Total Medicines : {count}</div>
										</div>
									</div>
								</div>
								<div className="dashboardtable">
									<table>
										{medicine.length !== 0 && (
											<thead>
												<tr>
													<th>Medicine Name</th>
													<th>Total Available Quantity</th>
													<th>Batch Number</th>
													<th>Available Quantity</th>
													<th>Expiry Date</th>
													<th style={{ textAlign: 'right' }}>Selling Price</th>
													<th style={{ textAlign: 'right' }}>Selling Unit Price</th>
												</tr>
											</thead>
										)}
										<tbody>
											{medicine.map((medicinelist, i) => (
												<tr>
													<td>{medicinelist.medicineName}</td>
													<td>{medicinelist.pmHospitalMedicineStocktotalqty}</td>
													<td>{medicinelist.pmHospitalMedicineBatchNo}</td>
													<td
														className={
															medicinelist.pmHospitalMedicineThreshno >=
																medicinelist.pmHospitalMedicineStockqty ? (
																'class-red'
															) : (
																''
															)
														}
													>
														{medicinelist.pmHospitalMedicineStockqty}
													</td>
													<td
														className={new Date(medicinelist.pmHospitalMedicineStockExpdate.split('-')[2], (medicinelist.pmHospitalMedicineStockExpdate.split('-')[1] - 1), medicinelist.pmHospitalMedicineStockExpdate.split('-')[0]) <= toDay ? 'class-red' : ''}
													>
														{medicinelist.pmHospitalMedicineStockExpDate1}
													</td>
													<td style={{ textAlign: 'right' }}>
														&#8377; {medicinelist.pmHospitalMedicineSellingPrice.toFixed(2)}
													</td>
													<td style={{ textAlign: 'right' }}>
														&#8377; {medicinelist.pmHospitalMedicineUnitPrice.toFixed(2)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
							{medicine.length !== 0 && (
								<div>
									{count > 10 &&
										<div className="paginationSection">
											<Pagination
												activePage={activePage}
												itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
												totalItemsCount={count}
												pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
												onChange={handlePageChange}
												//hideNavigation
												hideDisabled
											/>
										</div>
									}
								</div>
							)}
						</div>
					)}
					{medicine.length == 0 && dataFlag == true && <NoData />}
				</div>
			)}
		</div>
	);
}
export default Pharmacystockdetails;
