import React from 'react';
import Editicon from '../images/icons/edit-bg.png';
import Tick from '../images/icons/tick.png';
import Close from '../images/icons/close.png';
import { useEffect, useState } from 'react';
import { callingGetAPI, callingAPI, callingDeleteAPI } from '../config';
import Pagination from 'react-js-pagination';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { event } from 'jquery';
function PharmacyManufacture() {
	const [manufactureData, setManufactureData] = useState([]);
	const [selectedManufacture, setSelectedManufacture] = useState({});
	const [count, setCount] = useState(0);
	const [popupHeading, setPopupHeading] = useState('');
	const [ContactNumber, setContactNumber] = useState('');
	const [DataFlag, setDataFlag] = useState(false);
	const [searchValue, setsearchValue] = useState("");
	const [filterName, setFilterInput] = useState("");
	const [saveFlag, setsaveFlag] = useState(true);
	const [booliansearchValue, setbooliansearchValue] = useState('');
	const [booliantxtInput, setbooliantxtInput] = useState('');
	const [activePage, setActivePage] = useState(window.sessionStorage.getItem('PharmacyManufacturepageNumber'));
	const [pageOffset, setPageOffset] = useState(0);
	const downloadName = 'ManufacturerData';
	function manufactureListDownload() {
		const csvRow = [];
		var patientlist = [
			['SlNo:', 'Manufacturer%20Name', 'Manufacturer%20Address', 'Email', 'Contact%20Number.', 'Contact%20Person'],
		];
		var name = manufactureData;
		var postdata = {
			cmbSelect: filterName,
			txtInput: searchValue,
			hospitalId: localStorage.getItem("hospitalId"),
			max: "",
			offset: 0,
		};
		callingAPI('manufacturer', postdata).then((res) => {
			for (var item = 0; item < res.data.result.manufacturerList.length; item++) {
				patientlist.push([
					[item + 1],

					res.data.result.manufacturerList[item].manufactureName.replace(/ /g, '%20'),
					res.data.result.manufacturerList[item].manufactureAddress.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
					res.data.result.manufacturerList[item].manufactureEmail,
					res.data.result.manufacturerList[item].manufactureContactNo,
					res.data.result.manufacturerList[item].manufactureContactPerson.replace(/,/g, '%20').replace(/ /g, '%20'),
				]);
			}
			for (var i = 0; i < patientlist.length; ++i) {
				csvRow.push(patientlist[i].join(','));
			}
			var csvString = csvRow.join('%0A');
			var creat = document.createElement('a');
			creat.href = 'data:attachment/csv,' + csvString;
			creat.target = '_Blank';
			creat.download = downloadName + '.csv';
			document.body.appendChild(creat);
			creat.click();
		})
	};
	// document.addEventListener("keydown", function(event) {
	// 	if (event.code === 13) {

	// 	}
	//   });

	// document.addEventListener("keyup", function(event) {
	// 	if (event.code === 'Enter') {

	// 	}
	// });
	const getGridDetails = () => {
		setActivePage(Number(window.sessionStorage.getItem('PharmacyManufacturepageNumber')))
		callingAPI('manufacturer', {
			cmbSelect: filterName,
			txtInput: searchValue,
			hospitalId: localStorage.getItem("hospitalId"),
			max: 10,
			offset: window.sessionStorage.getItem('manufactureListDownloadactivepagenew') == null ? 0 : window.sessionStorage.getItem('manufactureListDownloadactivepagenew'),

		}).then((res) => {
			if (res.data.success === '1') {
				//setActivePage(1)
				setManufactureData(res.data.result.manufacturerList);
				setCount(res.data.result.totalCount);
				setDataFlag(true);
			}
			else {
				setDataFlag(true);
			}
		});
	};
	useEffect(() => {
		getGridDetails();
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);
	const handleBeforeUnload = (e) => {
		e.preventDefault();
		window.sessionStorage.setItem('PharmacyManufacturepageNumber', 1)
		window.sessionStorage.setItem('manufactureListDownloadactivepagenew', 0)
	};
	//////////////////////////////////////////////
	const re = /^\d+$/;
	const procedureCostChange = (event) => {
		if ((event.target.value).match(re) || event.target.value === '') {
			setContactNumber(event.target.value);
		}
		else {
			alert('Only Numbers Accepted');
			return event.target.value = ContactNumber
		}
	};
	const updateManufacture = () => {
		setsaveFlag(false)
		window.sessionStorage.setItem("manufactureListDownloadactivepagenew", pageOffset)
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		if (document.getElementById('manufacturerName').value == '') {
			alert('Enter Manufacturer Name');
			document.getElementById('manufacturerName').focus();
			setsaveFlag(true)
			return;
		}
		if (ContactNumber === '') {
			alert('Enter Contact Number')
			document.getElementById('manufacturerContactNumber').focus();
			setsaveFlag(true)
			return;

		}
		if (!ContactNumber.match(phNum)) {
			alert('Enter a Valid Contact Number');
			document.getElementById('manufacturerContactNumber').focus();
			setsaveFlag(true)
			return;
		}
		if (document.getElementById('manufacturerEmail').value !== '') {
			if (!document.getElementById('manufacturerEmail').value.match(email)) {
				alert('Enter a Valid Email ID');
				document.getElementById('manufacturerEmail').focus();
				setsaveFlag(true)
				return;
			}
		}
		var postdata = {
			manufactureId: selectedManufacture.manufactureId,
			manufactureName: document.getElementById('manufacturerName').value,
			manufactureAddress: document.getElementById('manufacturerAddress').value,
			manufactureContactNo: ContactNumber,
			manufactureContactPerson: document.getElementById('manufacturerContactPerson').value,
			manufactureEmail: document.getElementById('manufacturerEmail').value,
			hospitalId: localStorage.getItem("hospitalId"),
			userId: window.sessionStorage.getItem('userId'),
		};
		// setDataFlag(false);
		callingAPI('manufacturer/manufacturerAddUpdate', postdata).then((res) => {
			if (res.data.success === '1') {
				// setDataFlag(true);
				getGridDetails();
				alert('Manufacturer Details Updated Successfully');
				setsaveFlag(true)
				setPopupHeading('');
				setContactNumber('');
			} else if (res.data.success === '0') {
				setDataFlag(true);
				setsaveFlag(true)
				alert(res.data.successMessage);
				// setDataFlag(true);
				// setPopupHeading('');
				// setContactNumber('');
			}
		});
	};
	const addNewManufacture = () => {
		setsaveFlag(false)
		window.sessionStorage.setItem("manufactureListDownloadactivepagenew", pageOffset)
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		if (document.getElementById('manufacturerName').value == '') {
			alert('Enter Manufacturer Name');
			document.getElementById('manufacturerName').focus();
			setsaveFlag(true)
			return;
		}
		if (ContactNumber === '') {
			alert("Enter Contact Number")
			document.getElementById('manufacturerContactNumber').focus();
			setsaveFlag(true)
			return;
		}
		if (!ContactNumber.match(phNum)) {
			alert('Enter a Valid Contact Number');
			document.getElementById('manufacturerContactNumber').focus();
			setsaveFlag(true)
			return;
		}
		if (document.getElementById('manufacturerEmail').value !== '') {
			if (!document.getElementById('manufacturerEmail').value.match(email)) {
				alert('Enter a Valid Email ID');
				document.getElementById('manufacturerEmail').focus();
				setsaveFlag(true)
				return;
			}
		}
		var postdata = {
			manufactureName: document.getElementById('manufacturerName').value,
			manufactureAddress: document.getElementById('manufacturerAddress').value.replace(/\s{1,}/g, ' '),
			manufactureContactNo: ContactNumber,
			manufactureContactPerson: document.getElementById('manufacturerContactPerson').value,
			manufactureEmail: document.getElementById('manufacturerEmail').value,
			hospitalId: localStorage.getItem("hospitalId"),
			userId: window.sessionStorage.getItem('userId'),
		};
		// setDataFlag(false);
		callingAPI('manufacturer/manufacturerAddUpdate', postdata).then((res) => {
			if (res.data.success === '1') {
				alert('Manufacturer Details Saved Successfully ');
				setDataFlag(true);
				getGridDetails();
				setSelectedManufacture({});
				setPopupHeading('');
				setContactNumber('');
				setsaveFlag(true)
			} else if (res.data.success === '0') {
				alert(res.data.successMessage);
				setDataFlag(true);
				setsaveFlag(true)
				// setPopupHeading('');
				// setContactNumber('');
			}
		});
	};
	const changeText = (value) => {
		setFilterInput(value);
		setsearchValue("");
		if (value === "") {
			setsearchValue("");
			document.getElementById("TextToSearch").disabled = true;
		} else {
			document.getElementById("TextToSearch").disabled = false;
			document.getElementById("TextToSearch").focus();
		}
	};
	const searchManufacture = () => {
		if (filterName !== "" && searchValue === "") {
			var t = document.getElementById("SearchingOption");
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById("TextToSearch").focus();
			alert('Enter' + ' Manufacturer ' + selectedText);
			return false;
		}
		setbooliansearchValue(filterName);
		setbooliantxtInput(searchValue);
		var postdata = {
			cmbSelect: filterName,
			txtInput: searchValue,
			hospitalId: localStorage.getItem("hospitalId"),
			max: 10,
			offset: 0,
		};
		callingAPI('manufacturer', postdata).then((res) => {
			if (res.data.success === '1') {
				setManufactureData(res.data.result.manufacturerList);
				setCount(res.data.result.totalCount);
				setActivePage(1);
			} else if (res.data.success === '0') {
				setManufactureData('');
				setCount(res.data.totalCount);
			}
		});
	};

	const paginationHandling = (pageNumber) => {
		window.sessionStorage.setItem('PharmacyManufacturepageNumber', pageNumber)
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);

		if (!booliantxtInput) { setFilterInput("") }
		if (!booliansearchValue) { setsearchValue("") }

		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			cmbSelect: booliansearchValue,
			txtInput: booliantxtInput,
		};
		setDataFlag(false);
		callingAPI('manufacturer', postData).then((response) => {
			if (response.data.success === '1') {
				setManufactureData(response.data.result.manufacturerList);
				setCount(response.data.result.totalCount);
				setDataFlag(true);
			}
			else if (response.data.success === '0') {
				alert(response.data.errorMessage);
				setDataFlag(true);
			}
		});
	};
	const deleteManufacture = (manufacture) => {
		window.sessionStorage.setItem("manufactureListDownloadactivepagenew", pageOffset)
		var x = false;
		if (manufacture.isActive == true) {
			x = window.confirm('Do you want to delete this?');
		} else {
			x = false;
		}
		if (x === true) {
			var postdata = {
				manufactureId: manufacture.manufactureId,
				isActive: !manufacture.isActive,
				userId: window.sessionStorage.getItem('userId'),
			};
			setDataFlag(false);
			callingAPI('manufacturer/manufacturerActiveDeactive', postdata).then((res) => {
				if (res.data.success === '1') {
					alert('Manufacturer Details Deleted Successfully');
					getGridDetails();
					//paginationHandling();
					setDataFlag(true);
				}
				else if (res.data.success === '0') {
					alert(res.data.errorMessage);
					setDataFlag(true);
				}
			});
		}
	};
	const filterByPatient = (value) => {
		if (filterName == "4" && value !== "") {
			if (/[^0-9.]/.test(value)) {
				alert('Only Numbers Accepted');
				return;

			}
			if (value.length > 10) {
				alert('Enter a Valid Contact Number');
				return;
			}

		}
		setsearchValue(value);
	};
	const closepopup = () => {
		setPopupHeading('');
		setContactNumber('');
		setsaveFlag(true)
	};
	const handleKeypress = (event) => {
		if (event.key === 'Enter') {
			searchManufacture();
		}
	};
	return (
		<div className="dashboardCover pharmacyManufactureWrap pharmacyWrapper ">
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle"> Manufacturer</div>
				</div>
				<div className="col-md-2">
					<a
						href="#plpopup1"
						onClick={(e) => {
							setSelectedManufacture({});
							setPopupHeading('Add Manufacturer');
						}}
					>
						<span
							className="dashButtonBorder"
							style={{ background: '#22a2fd', color: '#fff', border: 'none' }}
						>
							Add +
						</span>
					</a>
				</div>
			</div>
			<div className="dashboardContent">
				{DataFlag == false ?
					<div align="center">
						<Loader />
					</div>
					: (
						<div className="reportWrap">
							{count > 10 && (
								<Pagination
									//hideNavigation
									hideDisabled
									activePage={activePage}
									itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
									totalItemsCount={count}
									pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
									onChange={paginationHandling}
								/>
							)}
							<div className="dashboardSearch">
								<div className="row">
									<div className="col-md-2 dashboardSearchList">
										<select className="inputField"
											value={filterName}
											onChange={(e) => changeText(e.target.value)} id="SearchingOption" onKeyPress={handleKeypress} >
											<option value="">Search In</option>
											<option value="1">Name</option>
											<option value="2">Address</option>
											<option value="3">Email</option>
											<option value="4">Contact Number</option>
											<option value="5">Contact Person</option>
										</select>
									</div>
									{filterName == "" ? (
										<div className="col-md-2 dashboardSearchList">
											<input type="text" className="inputField" onKeyPress={handleKeypress}
												value={searchValue}
												onChange={(e) => filterByPatient(e.target.value)} id="TextToSearch" style={{ background: "transparent" }} disabled />
										</div>
									) : (
										<div className="col-md-2 dashboardSearchList">
											<input type="text" className="inputField" onKeyPress={handleKeypress}
												value={searchValue}
												onChange={(e) => filterByPatient(e.target.value)} autofocus="autofocus" id="TextToSearch" style={{ background: "white" }} disabled />
										</div>)}
									<div className="col-md-2 dashboardSearchList" id="search" onKeyPress={handleKeypress} >
										<span className="dashButtonBorder" onClick={searchManufacture}  >
											Search
										</span>
									</div>
									<div className="col-md-2 dashboardSearchList">
										<span className="dashButtonBorder" onClick={manufactureListDownload}>
											Export
										</span>
									</div>
									<div className="col-md-4 dashboardSearchList">
										<div className="searchCount">
											<b>Total : {count}</b>
										</div>
									</div>
								</div>
							</div>
							{manufactureData.length > 0 &&
								DataFlag == true && (
									<div className="dashboardtable">
										<table>
											<thead>
												<tr>
													<th>Name</th>
													<th> Address </th>
													<th>Email</th>
													<th>Contact Number</th>
													<th style={{ width: "150px" }}>Contact Person</th>
													<th style={{ width: "100px" }}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{manufactureData.map((manufacture) => (
													<tr>
														<td>{manufacture.manufactureName}</td>
														<td>{manufacture.manufactureAddress}</td>
														<td>{manufacture.manufactureEmail}</td>
														<td>{manufacture.manufactureContactNo}</td>
														<td>{manufacture.manufactureContactPerson}</td>
														<td>
															<a
																href="#plpopup1"
																onClick={() => {
																	setSelectedManufacture(manufacture);
																	setContactNumber(manufacture.manufactureContactNo)
																	setPopupHeading('Edit Manufacturer');
																}}
															>
																<img title='Edit' src={Editicon} />
															</a>
															<a>
																<img
																	src={Close} title='Delete'
																	onClick={() => {
																		setSelectedManufacture(manufacture);
																		deleteManufacture(manufacture);
																	}}
																/>
															</a>
														</td>
													</tr>
												))}
											</tbody>
										</table>

										{count > 10 && (
											<Pagination
												//hideNavigation
												hideDisabled
												activePage={activePage}
												itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
												totalItemsCount={count}
												pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
												onChange={paginationHandling}
											/>
										)}

									</div>
								)}
							{manufactureData.length == 0 && DataFlag == true && <NoData />}
							{/* Popup Begins */}
							{popupHeading !== '' && (
								<div id="plpopup1" class="ploverlay">
									<div class="plpopup">
										<h2>{popupHeading}</h2>
										<a id="btnCancel" title="Close" class="close" onClick={closepopup}>
											<span class="popUpClose" style={{ right: '25px' }}>
												x
											</span>
										</a>
										<div class="plcontent">
											<div class="addDataContent">
												<div class="addDataDiv">
													<label>
														Manufacturer Name<span class="mandatory">*</span>
													</label>
													<input
														id="manufacturerName"
														defaultValue={selectedManufacture.manufactureName}
														type="text"
														placeholder="Enter Name"
													/>
												</div>
												<div class="addDataDiv">
													<label>Address</label>
													<textarea
														id="manufacturerAddress"
														defaultValue={selectedManufacture.manufactureAddress}
														placeholder="Enter Address"
														style={{ height: '85px', resize: 'none' }}
													/>
												</div>
												<div class="addDataDiv">
													<label>Email</label>
													<input
														id="manufacturerEmail"
														defaultValue={selectedManufacture.manufactureEmail}
														type="text"
														placeholder="Enter Email"
													/>
												</div>
												<div class="addDataDiv">
													<label>Contact Number<span class="mandatory">*</span></label>
													<input
														onChange={procedureCostChange}
														id="manufacturerContactNumber"
														Value={ContactNumber}
														maxLength="10"
														style={{ boxShadow: 'none' }}
														type="text"
														placeholder="Enter Phone Number"
													/>
												</div>
												<div class="addDataDiv">
													<label>Contact Person</label>
													<input
														id="manufacturerContactPerson"
														defaultValue={selectedManufacture.manufactureContactPerson}
														class=""
														style={{ boxShadow: 'none' }}
														type="text"
														placeholder="Enter contact Person"
													/>
												</div>
												<div class="summaryBtnDiv">
													{popupHeading === 'Add Manufacturer' && (
														<>
															{saveFlag == true &&
																<input
																	type="submit"
																	value="Save"
																	onClick={() => {
																		addNewManufacture();
																	}}
																	// onClick={addNewManufacture}
																	class="practiceGreenButton"
																/>
															}
														</>
													)}
													{popupHeading === 'Edit Manufacturer' && (
														<>
															{saveFlag == true &&
																<input
																	type="submit"
																	value="Update"
																	onClick={updateManufacture}
																	class="practiceGreenButton"
																/>
															}
														</>
													)}
													<a class="close" href="#">
														<input
															type="submit"
															value="Cancel"
															onClick={closepopup}
															class=" practiceGreyButton"
															style={{ fontFamily: 'Montserrat-SemiBold' }}
														/>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{/* Popup Ends */}
						</div>
					)}
			</div>
		</div>
	);
}
export default PharmacyManufacture;
