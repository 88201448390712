import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';

function MostprescribedmedcineAmount() {
    const [dataFlag, setDataFlag] = useState(false);
    const [mostPrescribedTreatmentDetails, setMostPrescribedTreatmentDetails] = useState([])

    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            doctorId: role === "ROLE_DOCTOR" ? window.sessionStorage.getItem('doctorId') : "",
            hospitalId: localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/mostPrescribedTreatmentDetails', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setMostPrescribedTreatmentDetails(response.data.result.mostPrescribedTreatmentDetails);


            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    return (
        <>

            <div className="patient-status-wrpr presMedicineAmnt">
                <div className="col-md-12">
                    <div className="total_revnuheader pb-0">
                        <div className="">
                            <h2 className='total_revnuheadtxt'>Most Prescribed Treatment Amount</h2>
                            <div className="top_precribdmedcn-wrpr">
                                <div className="row">
                                    {mostPrescribedTreatmentDetails.length > 0 ?
                                        <>
                                            {mostPrescribedTreatmentDetails.map((data) => (
                                                <div className="  presMedWrap">

                                                    <div className="preMedAmt">
                                                        <span>
                                                            <h4><b>  {data.procedureName}</b></h4>
                                                            <p>Procedure Code : {data.procedureCode}</p>
                                                        </span>

                                                    </div>

                                                    <span className='presMedAmont'>&#8377; {data.netAmount}</span>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className='todayAppScroll nodataFnd'>
                                            <p style={{ color: "red" }}>No Most Prescribed Treatment Amount Found </p>
                                        </div>
                                    }


                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


        </>
    )
}

export default MostprescribedmedcineAmount