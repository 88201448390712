import React, { useState, useEffect } from 'react';
import { callingAPI, callingPostAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import DatePicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Editicon from '../images/icons/edit-bg.png';
import moment from 'moment';
import Loader from '../Common/loader';
import newStock from '../images/icons/newStock.png';

// window.addEventListener("keydown", function(e) {
//     if([ 38, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, ].indexOf(e.keyCode) > -1) {
//         e.preventDefault();
//     }
// }, false);
function AddPurchase() {
	const [isData, setIsData] = useState(true);
	const [disableFlag, setdisableFlag] = useState(false);
	//listing area
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [hospitalInventoryList, setHospitalInventoryListData] = useState([]);
	const [supplierListData, setSupplierListData] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [count, setCount] = useState('');
	

	//supplier
	const [supplierAuto, setSupplierAuto] = useState([]);

	const [invoiceNumber, setInvoiceNumber] = useState('');
	const [invoiceDate, setInvoiceDate] = useState('');
	const [statusType, setStatusType] = useState('UnPaid');
	const [supplierInvoiceId, setsupplierInvoiceId] = useState('');
	const [supplierInvoiceMedicinesId, setsupplierInvoiceMedicinesId] = useState('');
	const [supplierErrors, setSupplierErrors] = useState('');

	/////////item Area/////
	const [CategoryData, setCategoryData] = useState([])

	const [taxCalculation, setTaxCalculation] = useState(0);
	const [taxCalculationPurchase, setTaxCalculationPurchase] = useState(0);
	const [manufactureAuto, setManufactureAuto] = useState([]);
	const [medicineAuto, setMedicineAuto] = useState([]);
	const [disposableAuto, setDisposableAuto] = useState([]);

	const [medicineUnits, setMedicineUnits] = useState([]);
	const [medicineTypes, setMedicineTypes] = useState([]);
	const [taxListdata, setTaxListData] = useState([]);
	const [taxListdataPurchase, setTaxListDataPurchase] = useState([]);

	const [isShowPharmacy, setShowPharmacy] = useState(false);
	const [isShowGeneric, setIsGeneric] = useState(false);


	const [categoryType, setcategoryType] = useState(0);
	const [brandName, setBrandName] = useState('');
	const [genericName, setGenericName] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const [medType, setMedType] = useState('');
	const [strength, setStrength] = useState('');
	const [medUnit, setMedUnit] = useState('');
	const [medTypeOld, setMedTypeOld] = useState('');
	const [strengthOld, setStrengthOld] = useState('');
	const [medUnitOld, setMedUnitOld] = useState('');
	const [interactions, setInteractions] = useState('');
	const [drugReactions, setDrugReactions] = useState('');
	const [majorSideEffects, setMajorSideEffects] = useState('');
	const [dosage, setDosage] = useState('');
	const [precautions, setPrecautions] = useState('');
	const [stripCount, setStripCount] = useState('');
	const [batchNo, setBatchNo] = useState('');
	const [stockQuantity, setStockQuantity] = useState('');
	const [expireDate, setExpireDate] = useState('');
	const [reOrderQuantity, setReorderQuantitu] = useState('');

	const [itemName, setItemName] = useState('');
	const [masterCode, setMasterCode] = useState('');
	const [noOfUse, setnoOfUse] = useState('');
	const [disposablehsnCode, setDisposableHsnCode] = useState('');
	const [disposablebatchNo, setDisposableBatchNo] = useState('');
	const [disposablestockQuantity, setDisposableStockQuantity] = useState('');
	const [disposableexpireDate, setDisposableExpireDate] = useState('');
	const [disposablereOrderQuantity, setDisposableReorderQuantitu] = useState('');
	const [popVisible, setPopVisible] = useState(false)
	const [popSupplierVisible, setPopSupplierVisible] = useState(false)
	// const [mrp, setMrp] = useState(0);
	// const [mrpUnitPrice, setmrpUnitPrice] = useState(0);
	// const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState(0);
	// const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState(0);
	// const [sellingPriceWithTax, setSellingPriceWithTax] = useState(0);
	// const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState(0);

	// const [purchasePrice, setPurchasePrice] = useState(0);
	// const [purchaseUnitPrice, setPurchaseUnitPrice] = useState(0);
	// const [purchasePriceWithTax, setPurchasePriceWithTax] = useState(0);
	// const [purchaseUnitPriceWithTax, setPurchaseUnitPriceWithTax] = useState(0);

	const [mrp, setMrp] = useState("");
	const [mrpUnitPrice, setmrpUnitPrice] = useState("");
	const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState("");
	const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState("");
	const [sellingPriceWithTax, setSellingPriceWithTax] = useState("");
	const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState("");

	const [purchasePrice, setPurchasePrice] = useState("");
	const [purchaseUnitPrice, setPurchaseUnitPrice] = useState("");
	const [purchasePriceWithTax, setPurchasePriceWithTax] = useState("");
	const [purchaseUnitPriceWithTax, setPurchaseUnitPriceWithTax] = useState("");


	const [manufactError, setManufactError] = useState('');
	const [onlycheckedTaxList, setOnlyCheckedTaxList] = useState([]);
	const [onlycheckedTaxListPurchase, setOnlyCheckedTaxListPurchase] = useState([]);

	var date = new Date();
	var requiredDate = moment(date).format('YYYY-MM');
	var date = new Date();
	var requiredDateSupplier = moment(date).format('YYYY-MM-DD');

	var date = new Date();
	var requiredDatelist = moment(date).format('DD-MM-YYYY');
	var toDay = requiredDatelist.split('-');
	toDay = new Date(toDay[2], toDay[1] - 1, toDay[0]);
	let taxCalcPurchase = 0;
	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	///purchase area///

	const onStatusChange = (e) => {
		setStatusType(e.target.value);
	};

	const onChangeInvoiceNumber = (event) => {
		setInvoiceNumber(event.target.value);
	};
	const onChangeInvoiceDateChange = (event) => {
		if ([38, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,].indexOf(event.keyCode) > -1) {
			event.preventDefault();
		}
	}

	const onChangeInvoiceDate = (event) => {
		setInvoiceDate(event.target.value);
	};

	const setSupplierClick = (name, id) => {
		document.getElementById('supplierInput').value = name;
		document.getElementById('hiddenSupplierId').value = id;
		setSupplierAuto([]);
		document.getElementById('supplierList').style.display = 'none';
	};

	const getSupplierAutoComplete = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			keyWord: document.getElementById('supplierInput').value,
		};
		if (document.getElementById('supplierInput').value.length > 1) {
			callingAPI('inventoryStockUpdate/supplierAutoComplete', postdata)
				.then((res) => {
					if (res.data.success === '1' && res.data.result.supplierDetails.length > 0) {
						document.getElementById('supplierList').style.display = 'block';
						setSupplierAuto(res.data.result.supplierDetails);
						setSupplierErrors('');
					} else {
						document.getElementById('supplierList').style.display = 'none';
						setSupplierErrors('Please Add Supplier');
						setSupplierAuto([]);
					}
				})
				.catch();
		}
		else if (document.getElementById('supplierInput').value.length === 0) {
			document.getElementById('supplierList').style.display = 'none';
			setSupplierErrors('Please Add Supplier');
			setSupplierAuto([]);

		}
	};

	const addNewSupplier = () => {

		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		let flagSupplier = true;
		if (!document.getElementById('supplierName').value) {
			alert('Supplier Name Required');
			flagSupplier = false;
			return;
		}
		if (document.getElementById('supplierNumber').value === '') {
			alert('Contact Number Required');
			flagSupplier = false;
			return;

		}
		if (document.getElementById('supplierNumber').value !== '') {
			if (!document.getElementById('supplierNumber').value.match(phNum)) {
				alert('Valid Contact Number Required');
				document.getElementById('supplierNumber').focus();
				return;
			}
		}
		if (document.getElementById('supplierEmail').value !== '') {
			if (!document.getElementById('supplierEmail').value.match(email)) {
				alert('Valid Email Required');
				document.getElementById('supplierEmail').focus();
				return;
			}
		}
		if (flagSupplier === true) {
			const postdata = {
				supplierId: '',
				supplierName: document.getElementById('supplierName').value,
				supplierAddress: document.getElementById('supplierAddress').value,
				supplierEmail: document.getElementById('supplierEmail').value,
				supplierContactNo: document.getElementById('supplierNumber').value,
				supplierContactPerson: document.getElementById('supplierContactPerson').value,
				supplierGstin: document.getElementById('supplierGstin').value,
				userId: window.sessionStorage.getItem('userId'),
				hospitalId: localStorage.getItem("hospitalId"),
			};
			setIsData(false);
			callingAPI('supplier/supplierAddUpdate', postdata).then((response) => {
				if (response.data.success === '1') {
					setIsData(true);
					alert('Supplier Saved Successfully');
					SupplierClose();
				} else if (response.data.success === '0') {
					alert(response.data.successMessage);
					setIsData(true);

				}
			});

		}
	};
	const listItemDetails = () => {
		document.getElementById('additemDetailsButton').style.display = 'none';
		document.getElementById('additemDetailsButtonAfterPurchaseSave').style.display = 'none';
		document.getElementById('itemExpansionArea').style.display = 'block';
		document.getElementById('listViewArea').style.display = 'none';
		document.getElementById("categoryidvalue").selectedIndex = 0
		

		setcategoryType('');
		setBrandName('');
		setGenericName('');
		setHsnCode('');
		setMedType('');
		setStrength('');
		setMedUnit('');
		setInteractions('');
		setDrugReactions('');
		setMajorSideEffects('');
		setDosage('');
		setPrecautions('');
		setStripCount('');
		setBatchNo('');
		setStockQuantity('');
		setExpireDate('');
		setReorderQuantitu('');
		setMrp('');
		setmrpUnitPrice('');
		setSellingPriceWithoutTax('');
		setSellingUnitPriceWithoutTax('');
		setSellingPriceWithTax('');
		setSellingUnitPriceWithTax('');

		setItemName('');
		setMasterCode('');
		setnoOfUse('');
		setDisposableHsnCode('');
		setDisposableBatchNo('');
		setDisposableStockQuantity('');
		setDisposableExpireDate('');
		setDisposableReorderQuantitu('');

		setPurchasePrice('');
		setPurchaseUnitPrice('');
		setPurchasePriceWithTax('');
		setPurchaseUnitPriceWithTax('');
	}
	const savePurchaseDetails = () => {
		setIsData(false);
		let flag = true;
		//// disposable and other category
		if (!invoiceNumber) {
			alert('Invoice Number  Required');
			document.getElementById('invoiceNumber').focus();
			flag = false;
			setIsData(true);
		} else if (!invoiceDate) {
			alert('Invoice Date Required');
			document.getElementById('invoiceDate').focus();
			flag = false;
			setIsData(true);
		} else if (!document.getElementById('supplierInput').value) {
			alert('Supplier Name Required');
			document.getElementById('supplierInput').focus();
			flag = false;
			setIsData(true);
		}
		else if (!document.getElementById('hiddenSupplierId').value) {
			alert('Supplier Name Required');
			document.getElementById('supplierInput').focus();
			flag = false;
			setIsData(true);
		}
		if (flag === true) {
			const postData = {
				supplierInvoiceAmount: '0',
				userId: window.sessionStorage.getItem('userId'),
				hospitalId: localStorage.getItem("hospitalId"),
				invoiceDate: invoiceDate,
				invoiceNo: invoiceNumber,
				netAmount: '0',
				serviceProviderId: "",
				supplierId: document.getElementById('hiddenSupplierId').value,
				tax: '0',
				status: statusType,
				supplierInvoiceId: '',
			};

			callingAPI('inventoryStockUpdate/savePurchase', postData).then((data) => {
				if (data.data.success === '1') {
					setdisableFlag(true)
					setIsData(true);
					alert('Purchase Details Saved Successfully');
					setsupplierInvoiceId(data.data.result.supplierInvoiceId);

					document.getElementById('additemDetailsButton').style.display = 'none';
					document.getElementById('additemDetailsButtonAfterPurchaseSave').style.display = 'none';
					document.getElementById('itemExpansionArea').style.display = 'block';
					document.getElementById('listViewArea').style.display = 'none';
				} else {
					alert(data.data.errorMessage);
				}
			});
		}
	};

	///listing area
	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		}
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			type: '',
			isSupplier: true,
			supplierInvoiceId: supplierInvoiceId
			//	supplierInvoiceMedicinesId: supplierInvoiceMedicinesId,
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setSupplierListData(data.data.result.supplierDetails)
			        setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
				}
			})
			.catch();
	};
	const changeText = () => {
		if (document.getElementById('selectName').value !== '') {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		} else {
			document.getElementById('searchText').disabled = true;
		}
		document.getElementById('searchText').value = '';
	};
	const searchValueChange = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				alert('End date must be greater than start date');
				return false;
			}
		}
		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('searchText').focus();
			alert(selectedText + ' required');
			return false;
		}

		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
			type: '',
			isSupplier: true,
			supplierInvoiceId: supplierInvoiceId
			//supplierInvoiceMedicinesId: supplierInvoiceMedicinesId,
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setSupplierListData(data.data.result.supplierDetails)
					setHospitalInventoryListData(data.data.result.inventoryList);
					setCount(data.data.result.totalCount);
				}
				else {
					setHospitalInventoryListData([]);
					setCount([]);
					setSupplierListData([]);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	const inventorylisting = (id) => {
		setsupplierInvoiceMedicinesId(id);
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			type: '',
			isSupplier: true,
			supplierInvoiceId: supplierInvoiceId
			//supplierInvoiceMedicinesId: id,
		};

		callingAPI('inventoryStockUpdate', PostData)
			.then((data) => {
				setSupplierListData(data.data.result.supplierDetails)
				setHospitalInventoryListData(data.data.result.inventoryList);
				setCount(data.data.result.totalCount);
			})
			.catch();
	};

	/////items area///

	const onSelectChange = (e) => {
		setcategoryType(e.target.value);
		setBrandName('');
		setGenericName('');
		setHsnCode('');
		setMedType('');
		setStrength('');
		setMedUnit('');
		setInteractions('');
		setDrugReactions('');
		setMajorSideEffects('');
		setDosage('');
		setPrecautions('');
		setStripCount('');
		setBatchNo('');
		setStockQuantity('');
		setExpireDate('');
		setReorderQuantitu('');
		setMrp('');
		setmrpUnitPrice('');
		setSellingPriceWithoutTax('');
		setSellingUnitPriceWithoutTax('');
		setSellingPriceWithTax('');
		setSellingUnitPriceWithTax('');

		setItemName('');
		setMasterCode('');
		setnoOfUse('');
		setDisposableHsnCode('');
		setDisposableBatchNo('');
		setDisposableStockQuantity('');
		setDisposableExpireDate('');
		setDisposableReorderQuantitu('');

		setPurchasePrice('');
		setPurchaseUnitPrice('');
		setPurchasePriceWithTax('');
		setPurchaseUnitPriceWithTax('');
	};
	const onChangeShowPharmacy = (event) => {
		setShowPharmacy(event.target.checked);
	};
	const onChangeisGeneric = (event) => {
		setIsGeneric(event.target.checked);
	};
	const onChangeBrandName = (event) => {
		setBrandName(event.target.value);
	};
	const onChangeGenericName = (event) => {
		setGenericName(event.target.value);
	};
	const onChangeHsnCode = (event) => {
		setHsnCode(event.target.value);
	};
	const onChangeMedType = (event) => {
		setMedType(event.target.value);
	};
	const onChangeStrength = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStrength(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeMedUnit = (event) => {
		setMedUnit(event.target.value);
	};
	const onChangeInteraction = (event) => {
		setInteractions(event.target.value);
	};
	const onChangeDrugReactions = (event) => {
		setDrugReactions(event.target.value);
	};
	const onChangeMajorSideEffects = (event) => {
		setMajorSideEffects(event.target.value);
	};
	const onChangeDosage = (event) => {
		setDosage(event.target.value);
	};
	const onChangePrecautions = (event) => {
		setPrecautions(event.target.value);
	};
	const onChangeStripCount = (event) => {
		if (!categoryType) {
			alert('Select Category');
			window.scrollTo(0, 200);
			return;
		}
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStripCount(event.target.value);
			setMrp('');
			setmrpUnitPrice('');
			setSellingPriceWithoutTax('');
			setSellingUnitPriceWithoutTax('');
			setSellingPriceWithTax('');
			setSellingUnitPriceWithTax('');
			setPurchasePrice('');
			setPurchaseUnitPrice('');
			setPurchasePriceWithTax('');
			setPurchaseUnitPriceWithTax('');

		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeBatchNo = (event) => {
		setBatchNo(event.target.value);
	};
	const onChangeStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStockQuantity(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeExpireDate = (event) => {
		setExpireDate(event.target.value);
	};
	const onChangeReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setReorderQuantitu(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};

	const onBlurItemCategory = () => {
		document.getElementById('disposableList').style.display = 'none';
		setDisposableAuto([]);
	};

	const onBlurBrandCategory = () => {
		document.getElementById('medicineList').style.display = 'none';
		setMedicineAuto([]);
	};

	const onBlurManufacture = () => {
		setManufactureAuto([]);
	};

	const stripCountBy = () => {
		if (categoryType == 1) {
			var price = mrp;
			var quantity = stripCount;
			var totalCost = price / quantity;
			setmrpUnitPrice(parseFloat(totalCost).toFixed(2));
			setSellingUnitPriceWithoutTax(parseFloat(totalCost).toFixed(2));
			setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
		}
	};

	const stripCountBySellingArea = (event) => {
		var quantity = stripCount;
		if (document.getElementById("sellingPricewithoutTax").value == "") {
			setSellingUnitPriceWithoutTax(sellingPriceWithoutTax);
			setSellingPriceWithTax(sellingPriceWithoutTax);
			setSellingUnitPriceWithTax(sellingPriceWithoutTax);
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc1 = 0;
			var quantity = stripCount;
			var totalCost = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc1 = taxCalc1 + (sellingPriceWithoutTax * Number(val.hospitalTaxDetailsTaxValue) / 100);
				}

				setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
				setSellingUnitPriceWithTax(parseFloat(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)).toFixed(2));
				setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)))
				if (categoryType == 1) {
					setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
					totalCost = (parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)) / quantity;
					setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
					setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)) / quantity)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value / quantity);
				setSellingUnitPriceWithoutTax(event.target.value / quantity);
			}
			else {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
			}



		}
	};

	const stripCountBySellingAreawithTax = (event) => {
		var stripcount = stripCount;
		if (document.getElementById("sellingPricewithTax").value == "") {
			setSellingPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithTax(sellingPriceWithTax)
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc = 0;
			let sellingPriceMultiple = 0;
			let totalTaxValue = 0;
			let totalTaxCalculation = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				}
				sellingPriceMultiple = sellingPriceWithTax * 100;
				totalTaxValue = taxCalc + 100;
				totalTaxCalculation = sellingPriceMultiple / totalTaxValue;

				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)))
				if (categoryType == 1) {
					var stripcount = stripCount;
					var unitSellingPice = totalTaxCalculation / stripcount;
					setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)) / stripcount)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value / stripCount);
				setSellingUnitPriceWithTax(event.target.value / stripCount);
			}
			else {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			}
		}
	};

	const onChangemrp = (event) => {
		if (!categoryType) {
			alert('Select Category');
			window.scrollTo(0, 200);
			return;
		}
		const re = /^[0-9.\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setMrp(event.target.value);
			setSellingPriceWithoutTax(event.target.value);
			setSellingPriceWithTax(event.target.value);
		} else {
			alert('Only Numbers Accepted');
			return
		}
		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');
				return;
			}


			onlycheckedTaxList.map((val, index) => {
				if (taxListdata.includes(val.hospitalTaxDetailsId)) {
					val.isChecked = true;
				}
				else {
					val.isChecked = false;
				}
				return val;
			})
		}
		else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setMrp(event.target.value);
				setmrpUnitPrice(event.target.value);
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}
			onlycheckedTaxList.map((val, index) => {
				if (taxListdata.includes(val.hospitalTaxDetailsId)) {
					val.isChecked = true;
				}
				else {
					val.isChecked = false;
				}
				return val;
			})
		}
	};
	const onChangeSellingPriceAreawithTax = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');

			}
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				//setSellingPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}


		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				//setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}

		}
	};
	const onChangeSellingPriceArea = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');

			}
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setSellingPriceWithoutTax(event.target.value);
				//setSellingPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}


		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				//setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}

		}

	};
	const onChangeItemNameName = (event) => {
		setItemName(event.target.value);
	};
	const onChangeMasterCode = (event) => {
		setMasterCode(event.target.value);
	};
	const onChangeNoOfUse = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setnoOfUse(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeDisposableHsnCode = (event) => {
		setDisposableHsnCode(event.target.value);
	};
	const onChangeDisposableBatchNo = (event) => {
		setDisposableBatchNo(event.target.value);
	};
	const onChangeDisposableStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setDisposableStockQuantity(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeDisposableExpireDate = (event) => {
		setDisposableExpireDate(event.target.value);
	};
	const onChangeDisposableReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setDisposableReorderQuantitu(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};

	const stripCountByPurchaseArea = () => {
		if (!categoryType) {
			alert('Please Select Category Type');
			return;
		}
		if (categoryType == 1) {
			var price = purchasePrice;
			var quantity = stripCount;
			var totalCost = price / quantity;
			setPurchaseUnitPrice(parseFloat(totalCost).toFixed(2));
			setPurchaseUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
		}
	};


	const onChangePurchasePrice = (event) => {
		if (categoryType == '') {
			alert('Please Select Category Type');
			return;
		} else {
			if (categoryType == 1) {
				if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					setPurchasePrice('');
					setPurchaseUnitPrice('');
					setPurchasePriceWithTax('');
					setPurchaseUnitPriceWithTax('');
				}
				const re = /^[0-9.\b]+$/;
				if (event.target.value === '' || re.test(event.target.value)) {
					setPurchasePrice(event.target.value);
					setPurchasePriceWithTax(event.target.value);
				} else {
					alert('Only Numbers Accepted');
				}
			} else {
				const re = /^[0-9.\b]+$/;
				if (event.target.value === '' || re.test(event.target.value)) {
					setPurchasePrice(event.target.value);
					setPurchaseUnitPrice(event.target.value);
					setPurchasePriceWithTax(event.target.value);
					setPurchaseUnitPriceWithTax(event.target.value);
				} else {
					alert('Only Numbers Accepted');
				}
			}
		}
		onlycheckedTaxListPurchase.map((val, index) => {
			if (taxListdataPurchase.includes(val.hospitalTaxDetailsId)) {
				val.isChecked = true;
			}
			else {
				val.isChecked = false;
			}
			return val;

		})
	};

	const setManufactureClick = (name, id) => {
		document.getElementById('manufactureInput').value = name;
		document.getElementById('hiddenManufacId').value = id;
		setManufactureAuto([]);
		document.getElementById('manufactureList').style.display = 'none';
	};
	const setMedicineClick = (medName, medId) => {
		document.getElementById('hdnMedicineName').value = medName;
		setBrandName(medName);
		document.getElementById('hdnMedicineId').value = medId;
		setMedicineAuto([]);
		document.getElementById('medicineList').style.display = 'none';
		getSelectedDetailsComplete(medId);
	};
	const setDisposableClick = (DisposableName, DisposableId) => {
		document.getElementById('itemName').value = DisposableName;
		setItemName(DisposableName);
		document.getElementById('hdnDisposableId').value = DisposableId;
		setDisposableAuto([]);
		document.getElementById('disposableList').style.display = 'none';
		getSelectedDetailsComplete(DisposableId);
	};

	const taxesChangePurchase = (item, index) => {
		if (!categoryType) {
			alert('Please Select Category Type');
			return;
		}
		else {
			if (categoryType == 1) {
				if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					return;
				} else if (!purchasePrice) {
					alert('PurchasePrice Required');
					document.getElementById('txtPrice').focus();
					return;
				}
			} else {
				//// disposable and other category
				if (!purchasePrice) {
					alert('PurchasePrice Required');
					document.getElementById('txtPrice').focus();
					return;
				}
			}
		}
		const checkedTaxArrCopyPurchase = JSON.parse(JSON.stringify(taxListdataPurchase));
		checkedTaxArrCopyPurchase[index].isChecked = !item.isChecked;
		setTaxListDataPurchase(checkedTaxArrCopyPurchase);
		let onlyCheckedTaxArrCopyPurchase = [...checkedTaxArrCopyPurchase];
		onlyCheckedTaxArrCopyPurchase = checkedTaxArrCopyPurchase.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});
		setOnlyCheckedTaxListPurchase(onlyCheckedTaxArrCopyPurchase)

		////////////////////////    tax Calculation     ////////////////////////

		let sellingPriceMultiplePurchase = 0;
		let totalTaxValuePurchase = 0;
		let totalTaxCalculationPurchase = 0;
		checkedTaxArrCopyPurchase.map((val, index) => {
			if (val.isChecked == true) {
				taxCalcPurchase = taxCalcPurchase + Number(val.hospitalTaxDetailsTaxValue);
				setTaxCalculationPurchase(taxCalcPurchase);
			}
			totalTaxValuePurchase = taxCalcPurchase + 100;
			sellingPriceMultiplePurchase = purchasePrice * totalTaxValuePurchase;
			totalTaxCalculationPurchase = sellingPriceMultiplePurchase / 100;
			setPurchasePriceWithTax(Math.round(totalTaxCalculationPurchase.toFixed(2) * 100) / 100);
			setPurchaseUnitPriceWithTax(Math.round(totalTaxCalculationPurchase.toFixed(2) * 100) / 100);
			if (categoryType == 1) {
				var stripcount = stripCount;
				var unitSellingPurchasePice = totalTaxCalculationPurchase / stripcount;
				setPurchasePriceWithTax(Math.round(totalTaxCalculationPurchase.toFixed(2) * 100) / 100);
				setPurchaseUnitPriceWithTax(Math.round(unitSellingPurchasePice.toFixed(2) * 100) / 100);
			}

		});
	};
	const taxesChange = (item, index) => {
		if (!categoryType) {
			alert('Select Category');
			window.scrollTo(0, 200);
			return;
		} else {
			if (categoryType == 1) {
				if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					return;
				} else if (!mrp) {
					alert('MRP Required');
					document.getElementById('mrp').focus();
					return;
				}
			} else {
				//// disposable and other category
				if (!mrp) {
					alert('MRP Required');
					document.getElementById('mrp').focus();
					return;
				}
			}
		}
		const checkedTaxArrCopy = [...taxListdata];
		checkedTaxArrCopy[index].isChecked = !item.isChecked;
		setTaxListData(checkedTaxArrCopy);
		let onlyCheckedTaxArrCopy = [...checkedTaxArrCopy];
		onlyCheckedTaxArrCopy = checkedTaxArrCopy.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});
		setOnlyCheckedTaxList(onlyCheckedTaxArrCopy)
		////////////////////////    tax Calculation     ////////////////////////
		let taxCalc = 0;
		let sellingPriceMultiple = 0;
		let totalTaxValue = 0;
		let totalTaxCalculation = 0;

		checkedTaxArrCopy.map((val, index) => {
			if (val.isChecked == true) {
				taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				setTaxCalculation(taxCalc);
			}
			sellingPriceMultiple = sellingPriceWithTax * 100;
			totalTaxValue = taxCalc + 100;
			totalTaxCalculation = sellingPriceMultiple / totalTaxValue;

			setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			if (categoryType == 1) {
				var stripcount = stripCount;
				var unitSellingPice = totalTaxCalculation / stripcount;
				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
			}
		});
	};
	const getTaxListingDataValues = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};

		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					val.isChecked = false;
					return val;
				});
			}
				if (res.data.success === '1') {
					setTaxListData(res.data.result.hospitalTaxDetailsList);
					setTaxListDataPurchase(res.data.result.hospitalTaxDetailsList);
				} else {
					setTaxListData([]);
					setTaxListDataPurchase([]);
				}
			})
			.catch();
	};
	const getMedicineUnitsTypes = () => {
		callingPostAPI('inventoryStockUpdate/getMedicineDetails')
			.then((data) => {
				setMedicineUnits(data.data.result.medicineUnit);
				setMedicineTypes(data.data.result.medicineType);
			})
			.catch();
	};

	const getInventoryAutoComplete = () => {
		if (!categoryType) {
			alert('Please Select Category Type');
			setBrandName('');
			setItemName('');
			return;
		}
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			master: categoryType,
			keyWord:
				categoryType == 1
					? document.getElementById('hdnMedicineName').value
					: document.getElementById('itemName').value,
		};
		if (categoryType == 1) {
			if (document.getElementById('hdnMedicineName').value.length > 1) {

				callingAPI('inventoryStockUpdate/getInventoryAutocomplete', postdata)
					.then((res) => {
						if (res.data.success === '1' && res.data.result.inventoryList.length > 0) {
							document.getElementById('medicineList').style.display = 'block';
							setMedicineAuto(res.data.result.inventoryList);
						} else {
							document.getElementById('medicineList').style.display = 'none';
							setMedicineAuto([]);
						}
					})
					.catch();
			}
			else if (document.getElementById('hdnMedicineName').value.length === 0) {
				setGenericName('');
				setMedType('');
				setStrength('');
				setMedUnit('');
				setInteractions('');
				setDrugReactions('');
				setMajorSideEffects('');
				setDosage('');
				setPrecautions('');
				setStripCount('');
				setBatchNo('');
				setHsnCode('')
				document.getElementById('medicineList').style.display = 'none';
				setMedicineAuto([]);

			}
		} else {
			if (document.getElementById('itemName').value.length > 1) {

				callingAPI('inventoryStockUpdate/getInventoryAutocomplete', postdata)
					.then((res) => {
						if (res.data.success === '1' && res.data.result.inventoryList.length > 0) {
							document.getElementById('disposableList').style.display = 'block';
							setDisposableAuto(res.data.result.inventoryList);
						} else {
							document.getElementById('disposableList').style.display = 'none';
							setDisposableAuto([]);
						}
					})
					.catch();
			}
			else if (document.getElementById('itemName').value.length === 0) {
				setMasterCode('');
				setnoOfUse('');
				setDisposableHsnCode('');
				document.getElementById('disposableList').style.display = 'none';
				setDisposableAuto([]);

			}
		}
	};

	const getSelectedDetailsComplete = (medicineId) => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			medicineId: categoryType == 1 ? medicineId : '',
			hospitalInventoryMasterId: categoryType == 1 ? '' : medicineId,
		};

		callingAPI('inventoryStockUpdate/getMedicineDetailsById', postdata)
			.then((res) => {
				if (res.data.success === '1') {
					if (categoryType == 1) {
						setBrandName(res.data.result.inventoryList[0].medicine);
						setGenericName(res.data.result.inventoryList[0].medicineGenericName);
						setManufactureClick(
							res.data.result.inventoryList[0].manufactureName,
							res.data.result.inventoryList[0].manufactureId
						);
						setMedType(res.data.result.inventoryList[0].medicineTypeId);
						setStrength(res.data.result.inventoryList[0].medicineStrength);
						setMedUnit(res.data.result.inventoryList[0].medicineUnitId);
						setMedTypeOld(res.data.result.inventoryList[0].medicineTypeId);
						setStrengthOld(res.data.result.inventoryList[0].medicineStrength);
						setMedUnitOld(res.data.result.inventoryList[0].medicineUnitId);
						setInteractions('');
						setDrugReactions(res.data.result.inventoryList[0].medicineDrugReactions);
						setMajorSideEffects(res.data.result.inventoryList[0].medicineMajorSideEffects);
						setDosage(res.data.result.inventoryList[0].medicineIndicationAndDosage);
						setPrecautions('');
						setStripCount('');
						setBatchNo(res.data.result.inventoryList[0].pmHospitalMedicineBatchNo);
					} else {
						setItemName(res.data.result.inventoryList[0].hospitalInventoryMasterName);
						setMasterCode(res.data.result.inventoryList[0].hospitalInventoryMasterCode);
						setnoOfUse(res.data.result.inventoryList[0].hospitalInventoryMasterNoOfUse);
						setDisposableHsnCode(res.data.result.inventoryList[0].hospitalInventoryMasterhsnInvCode);
					}
				} else {
					setMedicineAuto([]);
					setDisposableAuto([]);
				}
			})
			.catch();
	};

	const getManufactureAutoComplete = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			keyWord: document.getElementById('manufactureInput').value,
		};
		if (document.getElementById('manufactureInput').value.length > 1) {

			callingAPI('inventoryStockUpdate/getManuFactureAutocomplete', postdata)
				.then((res) => {
					if (res.data.success === '1' && res.data.result.manufactureDetails.length > 0) {
						document.getElementById('manufactureList').style.display = 'block';
						setManufactureAuto(res.data.result.manufactureDetails);
						setManufactError('');
					} else {
						document.getElementById('manufactureList').style.display = 'none';
						setManufactureAuto([]);
						document.getElementById('manufactureInput').value = "";
						setManufactError('Please Add Manufacturer');
					}
				})
				.catch();
		}
		else if (document.getElementById('manufactureInput').value.length === 0) {
			document.getElementById('manufactureList').style.display = 'none';
			setManufactureAuto([]);
			setManufactError('Please Add Manufacturer');
		}
	};

	const addNewManufacture = () => {
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		let flagManufacture = true;
		if (!document.getElementById('manufactureName').value) {
			alert('Manufacturer Name Required');
			flagManufacture = false;
			return;
		}
		if (!document.getElementById('manufactureNumber').value) {
			alert('Contact Number Required');
			flagManufacture = false;
			return;
		}
		if (document.getElementById('manufactureNumber').value !== '') {
			if (!document.getElementById('manufactureNumber').value.match(phNum)) {
				alert('Valid Contact Number Required');
				document.getElementById('manufactureNumber').focus();
				return;
			}
		}
		if (document.getElementById('manufactureEmail').value !== '') {
			if (!document.getElementById('manufactureEmail').value.match(email)) {
				alert('Valid Email Required');
				document.getElementById('manufactureEmail').focus();
				return;
			}
		}

		if (flagManufacture === true) {
			const postdata = {
				manufactureName: document.getElementById('manufactureName').value,
				manufactureAddress: document.getElementById('manufactureAddress').value,
				manufactureEmail: document.getElementById('manufactureEmail').value,
				manufactureContactNo: document.getElementById('manufactureNumber').value,
				manufactureContactPerson: document.getElementById('manufactureContactPerson').value,
				hospitalId: localStorage.getItem("hospitalId"),
				userId: window.sessionStorage.getItem('userId'),
			};

			setIsData(false);
			callingAPI('manufacturer/manufacturerAddUpdate', postdata).then((res) => {
				if (res.data.success === '1') {
					setIsData(true);
					alert('Manufacturer Saved Successfully');
					ManufactureClose()
				} else if (res.data.success === '0') {
					alert(res.data.successMessage);

				}
			});
		}
	};
	const ManufacturePopUpClick = () => {
		setPopVisible(true)
	}
	const ManufactureClose = () => {
		setPopVisible(false)
	}
	const SupplierPopUpClick = () => {
		setPopSupplierVisible(true)
	}
	const SupplierClose = () => {
		setPopSupplierVisible(false)
	}
	const saveInventoryDetails = () => {
		setIsData(false);
		let flag = true;
		if (categoryType == 0) {
			alert('Select Category');
			flag = false;
			setIsData(true);
			window.scrollTo(0, 200);
			return;

		}
		if (!categoryType) {
			alert('Please Select Category Type');
			flag = false;
			setIsData(true);
			return;
		} else {
			if (categoryType == 1) {
				if (!brandName) {
					alert('Medicine/Brand Name Required');
					document.getElementById('hdnMedicineName').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!medType) {
					alert('Select Type');
					document.getElementById('cmbMedicineType').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 280);
				} else if (!batchNo) {
					alert('Batch NO: Required');
					document.getElementById('hdnMedicineBatchNo').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 280);
				} else if (!stockQuantity) {
					alert('Stock Quantity Required');
					document.getElementById('hdnMedicineStockQuantity').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 280);
				} else if (!expireDate) {
					alert('Expire Date Required');
					document.getElementById('scheduleDate').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 280);
				} else if (!reOrderQuantity) {
					alert('Reorder quantity Required');
					document.getElementById('hdnMedicineThreshold').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 280);
				} else if (!purchasePrice) {
					alert('Purchase Price Required');
					document.getElementById('txtPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 450);
				} else if (!purchaseUnitPrice) {
					alert('Purchase UnitPrice Required');
					document.getElementById('txtUntPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 450);
				}
				else if (!mrp) {
					alert('MRP Required');
					document.getElementById('mrp').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 890);
				} else if (!mrpUnitPrice) {
					alert('MRP UnitPrice Required');
					document.getElementById('mrpUnitPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 890);
				} else if (!sellingPriceWithoutTax) {
					alert('Selling Price WithoutTax Required');
					document.getElementById('sellingPricewithoutTax').focus();
					flag = false;
					setIsData(true);

				} else if (!sellingUnitPriceWithoutTax) {
					alert('Selling UnitPrice WithoutTax Required');
					document.getElementById('sellingUnitPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingPriceWithTax) {
					alert('Selling Price WithTax Required');
					document.getElementById('sellingPricewithTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithTax) {
					alert('Selling UnitPrice WithTax Required');
					document.getElementById('sellingUnitPricewithTax').focus();
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
					alert('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
					alert('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}

			} else {
				//// disposable and other category
				if (!itemName) {
					alert('Item Name Required');
					document.getElementById('itemName').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!masterCode) {
					alert('Master Code Required');
					document.getElementById('masterCode').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!noOfUse) {
					alert('No Of Use Required');
					document.getElementById('noOfUse').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				}
				// else if (!disposablehsnCode) {
				// 	alert('hsnCode Required');
				// 	document.getElementById('hsnCode').focus();
				// 	flag = false;
				// 	setIsData(true);
				// }
				else if (!disposablebatchNo) {
					alert('Batch NO: Required');
					document.getElementById('batchNum').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!disposablestockQuantity) {
					alert('Stock Quantity Required');
					document.getElementById('stockQuantity').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				}
				// else if (!disposableexpireDate) {
				// 	alert('Expire Date Required');
				// 	document.getElementById('expireDate').focus();
				// 	flag = false;
				// 	setIsData(true);
				// }
				else if (!disposablereOrderQuantity) {
					alert('Reorder quantity Required');
					document.getElementById('threshold').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 200);
				} else if (!purchasePrice) {
					alert('Purchase Price Required');
					document.getElementById('txtPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 300);
				} else if (!purchaseUnitPrice) {
					alert('Purchase UnitPrice Required');
					document.getElementById('txtUntPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 300);
				} else if (!mrp) {
					alert('MRP Required');
					document.getElementById('mrp').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 300);
				} else if (!mrpUnitPrice) {
					alert('MRP UnitPrice Required');
					document.getElementById('mrpUnitPrice').focus();
					flag = false;
					setIsData(true);
					window.scrollTo(0, 300);
				} else if (!sellingPriceWithoutTax) {
					alert('Selling Price WithoutTax Required');
					document.getElementById('sellingPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithoutTax) {
					alert('Selling UnitPrice WithoutTax Required');
					document.getElementById('sellingUnitPricewithoutTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingPriceWithTax) {
					alert('Selling Price WithTax Required');
					document.getElementById('sellingPricewithTax').focus();
					flag = false;
					setIsData(true);
				} else if (!sellingUnitPriceWithTax) {
					alert('Selling UnitPrice WithTax Required');
					document.getElementById('sellingUnitPricewithTax').focus();
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
					alert('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}
				else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
					alert('MRP should not be less than Selling Price');
					flag = false;
					setIsData(true);
				}

			}
		}
		if (flag === true) {
			var selectedtaxvaluesArr = [];
			var selectedtaxvaluesArr = taxListdata.reduce(
				(a, o) => (o.isChecked && a.push(o.hospitalTaxDetailsId), a),
				[]
			);
			var selectedtaxvaluesPurchaseArr = [];
			var selectedtaxvaluesPurchaseArr = taxListdataPurchase.reduce(
				(a1, o1) => (o1.isChecked && a1.push(o1.hospitalTaxDetailsId), a1),
				[]
			);
			var today = new Date();

			var currentDate = today.getDate();
			if(currentDate <= 9)
			{
				currentDate = '0'+currentDate;
			}
			const postData = {
				pmHospitalMedicineId: '',
				medicine: categoryType == 1 ? brandName : '',
				role: window.sessionStorage.getItem('role'),
				isPurchase: true,
				hospitalInventoryCategoryId: categoryType,
				cmbSpeci: '',
				medicineId: categoryType == 1 ? (medType == medTypeOld && medUnit == medUnitOld && strength == strengthOld ? document.getElementById('hdnMedicineId').value : '') : '',
				medicineGenericName: categoryType == 1 ? genericName : '',
				medicineIndicationAndDosage: categoryType == 1 ? dosage : '',
				userId: window.sessionStorage.getItem('userId'),
				medicineBrandId: '',
				medicineQuantity: categoryType == 1 ? stripCount : '',
				medicineDrugReactions: categoryType == 1 ? drugReactions : '',
				medicineMajorSideEffects: categoryType == 1 ? majorSideEffects : '',
				manufactureId: categoryType == 1 ? document.getElementById('hiddenManufacId').value : '',
				medicinePrecautions: categoryType == 1 ? precautions : '',
				medicineTypeId: categoryType == 1 ? '' + medType : '',
				medicineUnitId: categoryType == 1 ? '' + medUnit : '',
				medicineStrength: categoryType == 1 ? '' + strength : '',
				medicineHsnCode: categoryType == 1 ? hsnCode : '',
				medicineForDentalAndMedical: '',
				medicineSpecializationId: '',
				medicineInteractions: categoryType == 1 ? interactions : '',
				pmHospitalMedicineBatchNo: categoryType == 1 ? batchNo : disposablebatchNo,
				hospitalInventoryMasterHsnCode: categoryType == 1 ? '' : disposablehsnCode,
				hospitalInventoryMasterId: categoryType == 1 ? '' : document.getElementById('hdnDisposableId').value,
				hospitalInventoryMasterCode: categoryType == 1 ? '' : masterCode,
				hospitalInventoryMasterName: categoryType == 1 ? '' : itemName,
				hospitalInventoryMasterNoOfUse: categoryType == 1 ? '' : noOfUse,

				pmHospitalMedicineMrp: mrp,
				pmHospitalMedicineMrpUnitPrice: mrpUnitPrice,
				pmHospitalMedicineQty: categoryType == 1 ? stripCount : '',
				pmHospitalMedicineCode: '',
				pmHospitalMedicineNoOfUse: categoryType == 1 ? '' : noOfUse,
				pmHospitalMedicineIsDisposableItem: categoryType == 1 ? false : isShowPharmacy,
				isGeneric: categoryType == 1 ? isShowGeneric : false,
				pmHospitalMedicineHsnCode: categoryType == 1 ? '' : disposablehsnCode,
				pmHospitalMedicineExpiryDate: categoryType == 1 ? expireDate + "-" + currentDate : disposableexpireDate + "-" + currentDate,
				pmHospitalMedicineThreshold: categoryType == 1 ? reOrderQuantity : disposablereOrderQuantity,
				pmHospitalMedicinePrice: sellingPriceWithoutTax,
				pmHospitalMedicineUnitPrice: sellingUnitPriceWithoutTax,
				// medicine :stripCount otherwise from backend they set as '0' if not value coming
				pmHospitalMedicineStockQty: categoryType == 1 ? stockQuantity : disposablestockQuantity,
				hospitalId: localStorage.getItem("hospitalId"),
				selectedTax: selectedtaxvaluesArr,

				supplierInvoiceMedicinesMrp: mrp,
				supplierInvoiceMedicinesMrpUnitPrice: mrpUnitPrice,
				supplierInvoiceMedicinesSellingPrice: sellingPriceWithoutTax,
				supplierInvoiceMedicinesSellingUnitPrice: sellingUnitPriceWithoutTax,

				supplierInvoiceId: supplierInvoiceId,
				supplierInvoiceMedicinesTotalTax: selectedtaxvaluesPurchaseArr,
				supplierInvoiceMedicinesPrice: purchasePrice,
				supplierInvoiceMedicinesUnitPrice: purchaseUnitPrice,
				supplierInvoiceMedicinesPriceWithTax: purchasePriceWithTax,
				supplierInvoiceMedicinesUnitPriceWithTax: purchaseUnitPriceWithTax,
			};

			callingAPI('inventoryStockUpdate/addInventoryStock', postData).then((data) => {
				if (data.data.success === '1') {
					alert('Purchase Stock Added Successfully');
					setsupplierInvoiceMedicinesId(data.data.result.supplierInvoiceMedicinesId);
					document.getElementById('additemDetailsButton').style.display = 'none';
					document.getElementById('additemDetailsButtonAfterPurchaseSave').style.display = 'block';

					document.getElementById('itemExpansionArea').style.display = 'none';
					document.getElementById('listViewArea').style.display = 'block';
					setIsData(true);
					changeText();
					inventorylisting(data.data.result.supplierInvoiceMedicinesId);
					// window.location.href="/settings/addPurchase"
				} else {
					alert(data.data.errorMessage);
				}
			});
		}
	};
	const categoryDropDownApi = () => {
		callingAPI('inventoryStockUpdate/categoryListing',
		).then((response) => {
			if (response.data.success === "1") {
				setCategoryData(response.data.result.categoryList)
			}
		}
		)
			.catch()
	}

	useEffect(() => {
		categoryDropDownApi();
		document.getElementById('additemDetailsButton').style.display = 'block';
		document.getElementById('additemDetailsButtonAfterPurchaseSave').style.display = 'none';

		document.getElementById('itemExpansionArea').style.display = 'none';
		document.getElementById('listViewArea').style.display = 'none';
		getMedicineUnitsTypes();
		getTaxListingDataValues();
	}, []);
	return (
		<div class="doc breadcrumbDoc breadCrumbPrctmngt pladdpurchase">
			<ul>
				<li>
					<a href="/settings/purchaseLists">Purchase Lists</a>
				</li>
				<div class="arrow-right" />
				<li>
					<span>Purchase Details</span>
				</li>
			</ul>
			<div class="practiceManagementForm practiceManagementFormNew">
				{isData === false ? <Loader /> : ''}
				<h3 class="practiceHead">Purchase Details</h3>
				<div class="practiceManagementFormBlcok">
					<form>
						<div class="taxMainContentWrap">
							<div id="divItemDefault">
								<div class="taxMainWrap">
									<ul>
										<li class="col-md-3 col-lg-3">
											<label>
												<span>Invoice Number *</span>
											</label>

											<input
												autocomplete="off"
												type="text"
												id="invoiceNumber"
												name="invoiceNumber"
												placeholder="Enter Invoice No"
												disabled={disableFlag}
												value={invoiceNumber}
												onChange={onChangeInvoiceNumber} tabindex="-1"
											/>
										</li>
										<li class="col-md-3 col-lg-3">
											<label>
												<span>Invoice Date *</span>
											</label>

											<input
												autocomplete="off"
												type="date"
												name="invoiceDate"
												disabled={disableFlag}
												id="invoiceDate"
												max={requiredDateSupplier}
												value={invoiceDate}
												onChange={onChangeInvoiceDate}
												onKeyDown={(e) => onChangeInvoiceDateChange(e)} tabindex="-1"
											/>
										</li>

										<li class="col-md-3 col-lg-3">
											<label>Supplier Name *</label>

											<input
												type="text"
												id="supplierInput"
												name="supplierInput"
												placeholder="Supplier Name"
												className="input-design"
												disabled={disableFlag}
												autoComplete="off"
												onKeyUp={() => getSupplierAutoComplete()}
												style={{ marginBottom: '5px' }} tabindex="-1"
											/>
											<ul
												className="autoSearch autoSearch-p0 appointmentNumberSearch labOrderDentalAddAutoSearch addPurcharseAutoSearch"
												id="supplierList"
												style={{ display: 'none' }}
											>
												{supplierAuto.map((item, i) => (
													<li onClick={() => setSupplierClick(item.supplyName, item.id)}>
														{item.supplyName}
													</li>
												))}
											</ul>
											<input type="hidden" id="hiddenSupplierId" />
											<p
												id=""
												style={{
													color: 'red',
													display: 'flex',
													justifyContent: 'left',
													fontSize: '12px',
													marginBottom: '4px',
												}}
											>
												{supplierErrors}
											</p>
											<span class="addManufLink addLinkTxt">
												<a onClick={() => SupplierPopUpClick()} style={{ color: '#0093cc', textDecoration: 'none' }}>
													Add Supplier +
												</a>
											</span>
										</li>

										<li class="col-md-3 col-lg-3">
											<label>
												<span>Status</span>
											</label>
											<select
												autoComplete="off"
												class="fulWidthSelect"
												name="category"
												disabled={disableFlag}
												id="categoryStatus"
												value={statusType}
												onChange={onStatusChange} tabindex="-1"
											>
												<option value="UnPaid">UnPaid</option>
												<option value="Paid">Paid</option>
												{/* <option value="Open">Open</option> */}
											</select>
										</li>
									</ul>
								</div>
							</div>
							<div class="clearfix" />
						</div>
						<div class="col-md-3 col-lg-3">
							<input
								autoComplete="off"
								id="additemDetailsButton"
								type="button"
								value="Add Item Details"
								class="practiceGreenButton pl_add_item_details"
								onClick={savePurchaseDetails}
							/>
							<input
								autoComplete="off"
								id="additemDetailsButtonAfterPurchaseSave"
								type="button"
								value="Add Item Details"
								class="practiceGreenButton pl_add_item_details"
								onClick={listItemDetails}
							/>
						</div>
					</form>
				</div>
				<div
					class="pl_editInventory practiceManagementForm practiceManagementFormNew edtinv "
					id="itemExpansionArea"
					style={{ padding: '0px' }}
				>
					<div class="practiceManagementFormBlcok inventoryStockDiv">
						{isData === false ? <Loader /> : ''}
						<h3 class="practiceHead">Item Details</h3>
						<form>
							<ul className="row">
								<li class="col-md-4 col-lg-4">
									<label>Category Name *</label>

									<select
										autoComplete="off"
										class="fulWidthSelect"
										name="category"
										id="categoryidvalue"
										onChange={onSelectChange} tabindex="-1"
									>
										{/* <option value="">Select Category</option> */}

										<option value="0">Select Category</option>
										{CategoryData.map((item, i) => (
											<option value={item.id}>{item.categoryName}</option>
										))}
									</select>
								</li>

								{categoryType == 2 || categoryType == 3 || categoryType == 4 || categoryType == 5 || categoryType == 0 ? (
									///////////////////sneha
									<div className="row" onClick={onBlurItemCategory}>
										<li class="col-md-4 col-lg-4">
											<label id="medBid">Item Name*</label>

											<input
												autoComplete="off"
												type="text"
												id="itemName"
												name="itemName"
												autofocus="autofocus"
												value={itemName}
												onChange={onChangeItemNameName}
												placeholder="Enter Name"
												onKeyUp={() => getInventoryAutoComplete()} tabindex="-1"
											//	onBlur={onBlurItemCategory}
											/>
											<ul
												className="autoSearch autoSearch-p0 itemNameSearch"
												id="disposableList"
												style={{ display: 'none' }}
											>
												{disposableAuto.map((item, i) => (
													<li
														onClick={() =>
															setDisposableClick(
																item.hospitalInventoryMasterName,
																item.hospitalInventoryMasterId
															)}
													>
														{item.hospitalInventoryMasterName}
													</li>
												))}
											</ul>
											<input type="hidden" id="hdnDisposableId" />
										</li>

										<li class="col-md-4 col-lg-4">
											<label>Master Code*</label>

											<input
												autoComplete="off"
												type="text"
												id="masterCode"
												name="masterCode"
												autofocus="autofocus"
												value={masterCode != '' ? masterCode : ''}
												//value={masterCode}
												onChange={onChangeMasterCode}
												placeholder="Enter Master Code" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>No Of Use*</label>

											<input
												autoComplete="off"
												type="text"
												autocomplete="off"
												maxlength="2"
												id="noOfUse"
												name="noOfUse"
												value={noOfUse != '' ? noOfUse : ''}
												autofocus="autofocus"
												//value={noOfUse}
												onChange={onChangeNoOfUse}
												placeholder="Quantity" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Show In Pharmacy</label>
											<div class="procedureCatType">
												<input
													autoComplete="off"
													type="checkbox"
													id="chkShowPharmacy"
													name="chkShowPharmacy"
													value={isShowPharmacy}
													onChange={onChangeShowPharmacy} tabindex="-1"
												/>
												<label for="Show In Pharmacy"> </label>
											</div>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Hsn Code</label>

											<input
												autoComplete="off"
												type="text"
												id="hsnCode"
												name="hsnCode"
												autofocus="autofocus"
												value={disposablehsnCode != '' ? disposablehsnCode : ''}
												//	value={disposablehsnCode}
												onChange={onChangeDisposableHsnCode}
												placeholder="Hsn Code" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Batch No*</label>

											<input
												autoComplete="off"
												type="text"
												id="batchNum"
												name="batchNum"
												autofocus="autofocus"
												value={disposablebatchNo}
												onChange={onChangeDisposableBatchNo}
												placeholder="Enter Batch No" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Stock Quantity*</label>

											<input
												autoComplete="off"
												type="text"
												maxlength="100"
												id="stockQuantity"
												name="stockQuantity"
												autofocus="autofocus"
												value={disposablestockQuantity}
												onChange={onChangeDisposableStockQuantity}
												placeholder="Enter Stock Quantity" tabindex="-1"
											/>
										</li>

										<li class="col-md-4 col-lg-4">
											<label id="expDatY">Expiry Date</label>

											<input
												autoComplete="off"
												type="month"
												name="expireDate"
												id="expireDate"
												autofocus="autofocus"
												value={disposableexpireDate}
												min={requiredDate}
												onChange={onChangeDisposableExpireDate}
												onKeyDown={(e) => e.preventDefault()}
												placeholder='Expiry Date' tabindex="-1"
												// required
												style={{ display: "flex", justifyContent: "space-between" }}
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label style={{ color: "#333" }}>Reorder Quantity*</label>
											<input
												autoComplete="off"
												type="text"
												id="threshold"
												name="threshold"
												autofocus="autofocus"
												value={disposablereOrderQuantity}
												onChange={onChangeDisposableReOrderQuantity}
												placeholder="Enter Threshold" tabindex="-1"
											/>
										</li>
									</div>
								) : (
									<div className="row" onClick={onBlurBrandCategory}>
										<li class="col-md-4 col-lg-4">
											<label id="medBid">Medicine/Brand Name*</label>
											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineName"
												name="hdnMedicineName"
												autofocus="autofocus"
												value={brandName}
												onChange={onChangeBrandName}
												placeholder="Enter Medicine Name"
												onKeyUp={() => getInventoryAutoComplete()} tabindex="-1"
											//	onBlur={onBlurBrandCategory}
											/>
											<ul
												className="autoSearch autoSearch-p0 itemNameSearch "
												id="medicineList"
												style={{ display: 'none' }}
											>
												{medicineAuto.map((item, i) => (
													<li
														onClick={() =>
															setMedicineClick(item.medicineDetails, item.medicineId)}
													>
														{item.medicineDetails}
													</li>
												))}
											</ul>
											<input type="hidden" id="hdnMedicineId" />
										</li>

										<li class="col-md-4 col-lg-4">
											<label>Generic Name</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineGenericName"
												name="hdnMedicineGenericName"
												autofocus="autofocus"
												value={genericName != '' ? genericName : ''}
												onChange={onChangeGenericName}
												placeholder="Enter Generic Name" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Hsn Code</label>

											<input
												autoComplete="off"
												type="text"
												autocomplete="off"
												maxlength="100"
												id="hdnMedicineHsnCode"
												name="hdnMedicineHsnCode"
												autofocus="autofocus"
												value={hsnCode != '' ? hsnCode : ''}
												onChange={onChangeHsnCode}
												placeholder="Hsn Code" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Manufacture</label>
											<input
												type="text"
												id="manufactureInput"
												name="cityName"
												placeholder="Item Name"
												className="input-design"
												autoComplete="off"
												onKeyUp={() => getManufactureAutoComplete()}
												style={{ marginBottom: '5px' }} tabindex="-1"
											//onBlur={onBlurManufacture}
											/>
											<ul
												className="autoSearch autoSearch-p0 itemNameSearch manufactureListHeight "
												id="manufactureList"
												style={{ display: 'none' }}
											>
												{manufactureAuto.map((item, i) => (
													<li
														onClick={() =>
															setManufactureClick(
																item.manufactureName,
																item.manufactureId
															)}
													>
														{item.manufactureName + " - " + item.phoneNumber}
													</li>
												))}
											</ul>
											<input type="hidden" id="hiddenManufacId" />
											<p
												id=""
												style={{
													color: 'red',
													display: 'flex',
													justifyContent: 'left',
													fontSize: '12px',
													marginBottom: '4px',
												}}
											>
												{manufactError}
											</p>

											<span class="addManufLink addLinkTxt">
												<a onClick={() => ManufacturePopUpClick()} style={{ color: '#0093cc', textDecoration: 'none' }}>
													Add Manufacturer +
												</a>
											</span>
										</li>

										<li class="col-md-4 col-lg-4">
											<label>Type*</label>
											<select
												autoComplete="off"
												class="fulWidthSelect"
												name="cmbMedicineType"
												id="cmbMedicineType"
												value={medType != '' ? medType : ''}
												onChange={onChangeMedType} tabindex="-1"
											>
												<option value="0">Select Type</option>
												{medicineTypes.map((item, index) => (
													<option value={item.medicineTypeId}>{item.medicineTypeName}</option>
												))}
											</select>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Strength</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineStrength"
												name="hdnMedicineStrength"
												autofocus="autofocus"
												value={strength != '' ? strength : ''}
												onChange={onChangeStrength}
												placeholder="Enter Strength" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Unit</label>
											<select
												autoComplete="off"
												class="fulWidthSelect"
												name="cmbMedicineUnit"
												id="cmbMedicineUnit"
												value={medUnit != '' ? medUnit : ''}
												onChange={onChangeMedUnit} tabindex="-1"
											>
												<option value="0">Select Unit</option>
												{medicineUnits.map((item, idix) => (
													<option value={item.medicineUnitId}>{item.medicineUnitName}</option>
												))}
											</select>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Interactions</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineInteractions"
												name="hdnMedicineInteractions"
												autofocus="autofocus"
												value={interactions != '' ? interactions : ''}
												onChange={onChangeInteraction}
												placeholder="Enter Interactions" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Drug reactions</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineDrugReactions"
												name="hdnMedicineDrugReactions"
												autofocus="autofocus"
												value={drugReactions != '' ? drugReactions : ''}
												onChange={onChangeDrugReactions}
												placeholder="Enter Drug reactions" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Major side effects</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineSideEffect"
												name="hdnMedicineSideEffect"
												autofocus="autofocus"
												value={majorSideEffects != '' ? majorSideEffects : ''}
												onChange={onChangeMajorSideEffects}
												placeholder="Enter Side Effect" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Indication and dosage</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineIndication"
												name="hdnMedicineIndication"
												autofocus="autofocus"
												value={dosage != '' ? dosage : ''}
												onChange={onChangeDosage}
												placeholder="Enter Indication" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Precautions</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicinePrecautions"
												name="hdnMedicinePrecautions"
												autofocus="autofocus"
												value={precautions != '' ? precautions : ''}
												onChange={onChangePrecautions}
												placeholder="Enter Precautions" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label>Number Of Units per Strip/Box Required *</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineQuantity"
												name="hdnMedicineQuantity"
												autofocus="autofocus"
												value={stripCount}
												onChange={onChangeStripCount}
												placeholder="Enter Quantity" tabindex="-1"
											/>
										</li>

										<li class="col-md-4 col-lg-4">
											<label>Batch No*</label>

											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineBatchNo"
												name="hdnMedicineBatchNo"
												autofocus="autofocus"
												value={batchNo}
												onChange={onChangeBatchNo}
												placeholder="Enter Batch No" tabindex="-1"
											/>
										</li>

										<li class="col-md-4 col-lg-4">
											<label>Stock Quantity*</label>

											<input
												autoComplete="off"
												type="text"
												maxlength="100"
												id="hdnMedicineStockQuantity"
												name="hdnMedicineStockQuantity"
												autofocus="autofocus"
												value={stockQuantity}
												onChange={onChangeStockQuantity}
												placeholder="Enter Stock Quantity" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label id="expDatY">Expiry Date*</label>

											<input
												autoComplete="off"
												type="month"
												name="scheduleDate"
												id="scheduleDate"
												value={expireDate}
												min={requiredDate}
												onChange={onChangeExpireDate}
												onKeyDown={(e) => e.preventDefault()} tabindex="-1"
												placeholder='Expiry Date'
												// required
												style={{ display: "flex", justifyContent: "space-between" }}
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label style={{ color: "#333" }}>Reorder Quantity*</label>
											<input
												autoComplete="off"
												type="text"
												id="hdnMedicineThreshold"
												name="hdnMedicineThreshold"
												autofocus="autofocus"
												value={reOrderQuantity}
												onChange={onChangeReOrderQuantity}
												placeholder="Enter Threshold" tabindex="-1"
											/>
										</li>
										<li class="col-md-4 col-lg-4">
											<label style={{ color: "#000" }}>Is generic</label>
											<div class="procedureCatType">
												<input
													autocomplete="off"
													type="checkbox"
													id="chkgeneric"
													name="chkgeneric"
													value={isShowGeneric}
													onChange={onChangeisGeneric}
													style={{ width: "15px" }} tabindex="-1"
												/>
												<label for="Is generic"> </label>
											</div>
										</li>
									</div>
								)}
							</ul>

							<div class="borderSeparate">
								<span />
							</div>
							<h3 class="practiceHead" style={{ textAlign: "center" }}>Pricing Details</h3>
							{/* ============ Purchase Details Begins here =========== */}
							<ul className="row">
								<h3 class="practiceHead" style={{ padding: '0px' }}>
									Purchase Details
								</h3>
								<ul>
									<li class="col-md-3 col-lg-3">
										<label>Purchase Price *</label>

										<input
											autoComplete="off"
											type="text"
											id="txtPrice"
											name="txtPrice"
											placeholder="Enter Purchase Price"
											tabindex="-1"
											value={purchasePrice}
											onChange={onChangePurchasePrice}
											onKeyUp={stripCountByPurchaseArea}

										/>
									</li>
									<li class="col-md-3 col-lg-3">
										<label>Purchase Unit Price *</label>

										<input
											autoComplete="off"
											type="text"
											id="txtUntPrice"
											name="txtUntPrice"
											placeholder="Unit Price"
											value={purchaseUnitPrice} tabindex="-1"
										/>
									</li>
									<li class="col-md-3 col-lg-3" id="newTax">
										<label class="priceLbl">
											Price with Tax{' '}
											<p className="plPriceInfo" id="totalprice">
												{purchasePriceWithTax}
											</p>
										</label>
									</li>
									<li class="col-md-3 col-lg-3">
										<label class="priceLbl" style={{ color: "#333" }}>
											Unit Price with Tax{' '}
											<p className="plPriceInfo" id="totalUntprice">
												{purchaseUnitPriceWithTax}
											</p>
										</label>
									</li>
								</ul>
								<div class="procedureCatList" id="divTaxPurchaseDetails">
									<div
										class="applicabletaxDiv pl_applicableTax_scroll row"
										style={{ flexWrap: 'nowrap', margin: '0px' }}
									>
										<div class="col-lg-3 procedureCatName">Applicable Taxes</div>

										<div class="col-lg-9 procedureCatType procedureCatListWrap">
											<div className="row">
												{/* <div className="col-lg-3"> */}
												{taxListdataPurchase.map((option, idx) => {
													return (
														<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
															<input

																type="checkbox"
																id={option.hospitalTaxDetailsId}
																value={option.hospitalTaxDetailsTaxName}
																checked={option.isChecked}
																onChange={() =>
																	taxesChangePurchase(option, idx)} tabindex="-1"
															/>
															<label>{option.taxNames}</label>
														</div>
													);
												})}
												{/* </div> */}
											</div>
										</div>
									</div>
								</div>
								{/* ========= Purchase Details Ends here ==============  */}
								<ul className="row sellingDetaillsWrap">
									<h3 class="practiceHead" style={{ padding: '0px' }}>
										Selling Details
									</h3>
									<li class="col-md-4 col-lg-4">
										<label>MRP*</label>

										<input
											autoComplete="off"
											type="text"
											id="mrp"
											name="mrp"
											autofocus="autofocus"
											value={mrp}
											onChange={onChangemrp}
											onKeyUp={stripCountBy}
											placeholder="Enter MRP" tabindex="-1"
										/>
									</li>
									<li class="col-md-4 col-lg-4">
										<label>MRP Unit Price*</label>

										<input
											autoComplete="off"
											type="text"
											readonly=""
											id="mrpUnitPrice"
											name="mrpUnitPrice"
											autofocus="autofocus"
											value={mrpUnitPrice}
											//onChange={onChangeParamPrice}
											placeholder="MRP Unit Price" tabindex="-1"
										/>
									</li>
								</ul>

								<div class="borderSeparate">
									<span />
								</div>
								<div class="subTitileNAme">
									<h3 class="practiceHead">Taxes</h3>
								</div>

								<div class="borderSeparate">
									<span />
								</div>
								{onlycheckedTaxList.length > 0 ? (
									<div className="taxType">
										<div className="taxDataOuter">
											{onlycheckedTaxList.map((option, idx) => {
												return (
													<div className="taxDataSelected">
														{option.isChecked ? (
															option.taxNames +
															': ' +
															(sellingPriceWithoutTax *
																(option.hospitalTaxDetailsTaxValue / 100)).toFixed(2)
														) : (
															''
														)}
													</div>
												);
											})}
										</div>
									</div>
								) :
									(
										''
									)
								}
								<div class="procedureCatList" id="divTaxDetails">
									<div
										class="applicabletaxDiv pl_applicableTax_scroll row"
										style={{ flexWrap: 'nowrap', margin: '0px' }}
									>
										<div class="col-lg-3 procedureCatName">Applicable Taxes</div>

										<div class=" col-lg-9 procedureCatType procedureCatListWrap">
											<div className="row">
												{taxListdata.map((option, idx) => {
													return (
														<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
															<input
																type="checkbox"
																id={option.hospitalTaxDetailsId}
																// id={option.hospitalTaxDetailsTaxValue}
																value={option.hospitalTaxDetailsTaxName}
																checked={option.isChecked}
																onChange={() => taxesChange(option, idx)} tabindex="-1"
															/>
															<label>{option.taxNames}</label>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
								<div class="borderSeparate">
									<span />
								</div>
								{/* <div class="subTitileNAme">
									<h3 class="practiceHead">Selling Details</h3>
								</div> */}
								<li class="col-md-3 col-lg-3 sellingDetailSec " style={{ paddingLeft: '0px' }}>
									<label>Selling Price (without tax)*</label>

									<input
										autoComplete="off"
										type="text"
										id="sellingPricewithoutTax"
										name="sellingPricewithoutTax"
										autofocus="autofocus"
										value={sellingPriceWithoutTax}
										onChange={onChangeSellingPriceArea}
										onKeyUp={stripCountBySellingArea}
										placeholder="Enter Price" tabindex="-1"
									/>
								</li>
								<li class="col-md-3 col-lg-3 sellingDetailSec">
									<label>Selling Unit Price (without tax)*</label>

									<input
										autoComplete="off"
										type="text"
										readonly=""
										id="sellingUnitPricewithoutTax"
										name="sellingUnitPricewithoutTax"
										autofocus="autofocus"
										value={sellingUnitPriceWithoutTax}
										placeholder="Enter Selling Unit Price" tabindex="-1"
									/>
								</li>
								<li class="col-md-3 col-lg-3 sellingDetailSec">
									<label>Selling Price(with tax)*</label>
									<input
										autoComplete="off"
										type="text"
										id="sellingPricewithTax"
										name="sellingPricewithTax"
										autofocus="autofocus"
										value={sellingPriceWithTax}
										onChange={onChangeSellingPriceAreawithTax}
										onKeyUp={stripCountBySellingAreawithTax}
										placeholder="Enter Selling Price" tabindex="-1"
									/>
								</li>
								<li class="col-md-3 col-lg-3 sellingDetailSec">
									<label>Selling Unit Price(with tax)*</label>
									<input
										autoComplete="off"
										type="text"
										readonly=""
										id="sellingUnitPricewithTax"
										name="sellingUnitPricewithTax"
										autofocus="autofocus"
										value={sellingUnitPriceWithTax}
										placeholder="Enter Selling Unit Price" tabindex="-1"
									/>
								</li>
							</ul>
							<div class="clearfix" />
							<br />
							<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
								<span className="dashButtonBorder" style={{ border: '1px solid rgb(178, 34, 34)' }}>
									<Link
										to="/settings/purchaseLists"
										style={{ textDecoration: 'none', color: '#393939' }} tabindex="-1"
									>
										Cancel
									</Link>
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
								<span className="dashButtonBorder" onClick={saveInventoryDetails} tabindex="-1">
									Save{' '}
								</span>
							</div>
						</form>
					</div>
					{popVisible === true &&
						<div id="plpopup12" class="ploverlay" style={{ visibility: 'visible', opacity: 1 }}>
							<div class="plpopup">
								<h2>
									Add Manufacturer{' '}
									<a class="close" onClick={() => ManufactureClose()}>
										&times;
									</a>
								</h2>
								<div class="plcontent">
									<div class="addDataContent">
										<div class="addDataDiv">
											<label>
												Manufacturer Name<span class="mandatory">*</span>
											</label>
											<input
												autoComplete="off"
												type="text"
												id="manufactureName"
												placeholder="Manufacture Name"
											/>
										</div>
										<div class="addDataDiv">
											<label>Address</label>
											<textarea
												autoComplete="off"
												id="manufactureAddress"
												placeholder="Enter Address"
												style={{ height: '85px' }}
											/>
										</div>
										<div class="addDataDiv">
											<label>Email</label>
											<input
												autoComplete="off"
												type="text"
												id="manufactureEmail"
												placeholder="Enter Email"
											/>
										</div>
										<div class="addDataDiv">
											<label>Contact Number<span class="mandatory">*</span></label>
											<input
												maxLength="10"
												autoComplete="off"
												class=""
												style={{ boxShadow: 'none' }}
												type="text"
												id="manufactureNumber"
												placeholder="Mobile Number"
												onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}

											/>
										</div>
										<div class="addDataDiv">
											<label>Contact Person</label>
											<input
												autoComplete="off"
												class=""
												style={{ boxShadow: 'none' }}
												type="text"
												id="manufactureContactPerson"
												placeholder="Contact Person"
											/>
										</div>

										<div class="summaryBtnDiv">
											<input
												type="submit"
												value="Save"
												onClick={addNewManufacture}
												class="practiceGreenButton"
											/>

											<a class="close" onClick={() => ManufactureClose()}>
												<input type="submit" value="Cancel" class=" practiceGreyButton" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				</div>

				<br />
				<br />

				<div id="listViewArea">
					{count > 10 && (
						<div className="paginationSection">
							<Pagination
								// hideNavigation
								hideDisabled
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={count}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={handlePageChange}
							/>
						</div>
					)}
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									autoComplete="off"
									className="input-design inputField"
									id="selectName"
									name="selectName"
									onChange={changeText}
								>
									<option value="">Select</option>

									{CategoryData.map((item, i) => (
										<option value={item.id}>{item.categoryName}</option>
									))}
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									autoComplete="off"
									className="input-design inputField"
									type="text"
									id="searchText"
									name="searchText"
									placeholder="Enter Item Name"
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									required
									className="Datepicker pa2 inputField  "
									//  maxDate={new Date()}
									placeholderText="Expiry-From"
									calendarClassName="rasta-stripes"
									selected={startDate}
									dateFormat="dd-MM-yyyy"
									onChange={(date) => setStartDate(date)}
									id="fromdate"
									autoComplete='off'
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerFromDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="fromdate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"
									required
									className="Datepicker pa2 inputField"
									// maxDate={new Date()}
									placeholderText="Expiry-To"
									calendarClassName="rasta-stripes"
									selected={endDate}
									dateFormat="dd-MM-yyyy"
									onChange={(date) => setEndDate(date)}
									id="todate"
									autoComplete='off'
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerToDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="todate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span className="dashButtonBorder" onClick={searchValueChange}>
									Search
								</span>
							</div>
						</div>
					</div>
					<div class="table-responsive">
						<table class="table col-md-12">
							<thead>
								<tr>
									<th>Category</th>
									<th> Name</th>
									<th>Batch No</th>
									<th>Qty</th>
									<th>MRP</th>
									<th> MRP Unit Price</th>
									<th>Price</th>
									<th>Unit Price</th>
									<th>Expiry Date</th>
									<th>Actions</th>

								</tr>
							</thead>
							<tbody>
								{/* <tbody> */}

								{hospitalInventoryList.map((item, index) => (
									<tr>
										<td>{item.hospitalInventoryCategoryName}</td>
										{item.hospitalInventoryCategoryId == 1 ? (
											<td>
												<span title={item.medicine + "-" + item.medicineTypeName + "-" + item.medicineStrength + "-" + item.medicineUnitName}
												>{item.medicine + "-" + item.medicineTypeName + "-" + item.medicineStrength + "-" + item.medicineUnitName}</span>
											</td>) : (
											<td>
												<span title={item.hospitalInventoryMasterName}
												>
													{item.hospitalInventoryMasterName}
												</span>
											</td>
										)}
										<td>{item.pmHospitalMedicineBatchNo}</td>
										<td
											className={
												parseFloat(item.pmHospitalMedicineThresholdNo) >=
													parseFloat(item.pmHospitalMedicineStockQty) ? (
													'class-red'
												) : (
													''
												)
											}
										>
											{item.pmHospitalMedicineStockQty}
										</td>
										<td>&#8377; {item.supplierInvoiceMedicinesPrice}</td>
										<td>&#8377; {item.supplierInvoiceMedicinesUnitPrice}</td>
										<td>&#8377; {item.pmHospitalMedicinePrice}</td>
										<td>&#8377; {item.pmHospitalMedicineUnitPrice}</td>
										<td
											title={item.pmHospitalMedicineExpiryDate}
											className={
												new Date(
													parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[2]),
													parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[1] - 1),
													parseFloat(item.pmHospitalMedicineExpiryDate.split('-')[0])
												) <= toDay ? (
													'class-red'
												) : (
													''
												)
											}
										>
											{item.pmHospitalMedicineExpiryDateNew}
										</td>
										{supplierListData.map((itemtext, index) => (
										<td>
											<Link
												to={{
													pathname: '/Settings/editPurchaseDetails',
													state: {
														pmhospitalMedicineId: item.pmHospitalMedicineId,
														supplierInvoiceMedicinesId: supplierInvoiceMedicinesId,
														supplierInvoiceId: supplierInvoiceId,
														supplierInvoiceNumber: itemtext.supplierInvoiceInvoiceNo,
														supplierInvoiceDate: itemtext.supplierInvoiceInvoiceDateNew,
														supplierName: itemtext.supplierName,
														supplierInvoiceStatus: itemtext.supplierInvoiceStatus,
													},
												}}
											>
												<img src={Editicon} title="purchase eit" />
											</Link>
											<Link
												to={{
													pathname: '/settings/newBatchPurchase',
													state: {
														pmhospitalMedicineId: item.pmHospitalMedicineId,
														supplierInvoiceMedicinesId: supplierInvoiceMedicinesId,
														supplierInvoiceId: supplierInvoiceId,

													},
												}}
											>
												<img src={newStock} style={{ marginLeft: '6px' }} title="Add New Batch" />
											</Link>

										</td>
											))}
									</tr>
								))}
								{/* </tbody> */}
							</tbody>
						</table>
					</div>
					{count > 10 && (
						<div className="paginationSection">
							<Pagination
								// hideNavigation
								hideDisabled
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={count}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={handlePageChange}
							/>
						</div>
					)}
				</div>
			</div>
			{/* Popup Begins */}
			{popSupplierVisible === true &&
				<div id="supplierpopup1" class="ploverlay" style={{ visibility: 'visible', opacity: 1 }}>

					<div class="plpopup">
						<h2>
							Add Supplier
							<a class="close" onClick={() => SupplierClose()}>
								&times;
							</a>
						</h2>
						<div class="plcontent">
							<div class="addDataContent">
								<div class="addDataDiv">
									<label>
										Supplier Name<span class="mandatory">*</span>
									</label>
									<input autoComplete="off" type="text" id="supplierName" placeholder="Enter Name" />
								</div>
								<div class="addDataDiv">
									<label>Address</label>
									<textarea
										autoComplete="off"
										id="supplierAddress"
										placeholder="Enter Address"
										style={{ height: '85px' }}
									/>
								</div>
								<div class="addDataDiv">
									<label>Email</label>
									<input autoComplete="off" id="supplierEmail" type="text" placeholder="Enter Email" />
								</div>
								<div class="addDataDiv">
									<label>Contact Number<span class="mandatory">*</span></label>
									<input
										maxLength="10"
										autoComplete="off"
										id="supplierNumber"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										placeholder="Enter Phone Number"
										onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}

									/>
								</div>
								<div class="addDataDiv">
									<label>Contact Person</label>
									<input
										autoComplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="supplierContactPerson"
										placeholder="Enter contact Person"
									/>
								</div>
								<div class="addDataDiv">
									<label>GSTIN</label>
									<input
										autoComplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="supplierGstin"
										placeholder="Enter GSTIN"
									/>
								</div>

								<div class="summaryBtnDiv">
									<input
										type="submit"
										value="Save"
										onClick={addNewSupplier}
										class="practiceGreenButton"
									/>

									<a class="close" onClick={() => SupplierClose()}>
										<input type="submit" value="Cancel" class=" practiceGreyButton" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{/* Popup Ends */}
		</div>
	);
}

export default AddPurchase;
