import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';

function LaborderStatus() {

    const [LaborderStatus, setLaborderStatus] = useState([]);
    const [inTransit, setInTransit] = useState([""]);
    const [active, setActive] = useState([""]);
    const [inProduction, setInProduction] = useState([""]);
    const [received, setReceived] = useState([""]);
    const [sent, setSent] = useState([""]);
    const [overdue, setOverdue] = useState([""]);
    useEffect(() => {
        labOrderStatus();
    }, []);

    const labOrderStatus = () => {

        let postData = {
            memberId: "",
            hospitalId: localStorage.getItem('hospitalId'),
            doctorId: window.sessionStorage.getItem('doctorId'),
            searchInput: "",
            cmbStatus: "",
        }
        callingAPI('commonUserFunctions/labOrderCountByStatus', postData).then((response) => {

            if (response.data.success === "1") {
                setLaborderStatus(response.data.result)
                setInTransit(response.data.result.filter(
                    (sectionObject) => sectionObject.statusName === "In Transit"
                ));

                setActive(response.data.result.filter(
                    (sectionObject) => sectionObject.statusName === "Active"
                ));

                setInProduction(response.data.result.filter(
                    (sectionObject) => sectionObject.statusName === "In Production"
                ));
                setReceived(response.data.result.filter(
                    (sectionObject) => sectionObject.statusName === "Received"
                ));
                setSent(response.data.result.filter(
                    (sectionObject) => sectionObject.statusName === "Sent"
                ));
                setOverdue(response.data.result.filter(
                    (sectionObject) => sectionObject.statusName === "Overdue"
                ));
            }
        })
    }


    return (
        <>
            <div className='toDoListPg recall-wrpar labOrderStatus'>
                <div>
                    <div className="total_revnuheader recall-paadTop">
                        <h2 className='total_revnuheadtxt'>Lab Order Status</h2>
                    </div>
                    <div className='col-lg-12 laborderStatusInfo '>

                        <div className='xl-parpl visitrs_cardblock'>

                            <div class="visior_subblock visior_iconblock activeCount">
                                {active.length > 0 ?
                                    <h4 class="visitrs_card-h4">{active[0].statusCount ? active[0].statusCount : "0"}</h4>
                                    : <h4 class="visitrs_card-h4">0</h4>}
                            </div>
                            <div class="visior_subblock statusName activeCountBg">
                                <h4 class="visitrs_card-h4">Active</h4>
                            </div>
                        </div>


                        <div className='xl-parpl visitrs_cardblock'>

                            <div class="visior_subblock visior_iconblock sentCount">
                                {sent.length > 0 ?
                                    <h4 class="visitrs_card-h4">{sent[0].statusCount ? sent[0].statusCount : "0"}</h4>
                                    : <h4 class="visitrs_card-h4">0</h4>}
                            </div>
                            <div class="visior_subblock statusName sentCountBg ">
                                <h4 class="visitrs_card-h4">Sent</h4>
                            </div>
                        </div>


                        <div className='xl-parpl visitrs_cardblock '>

                            <div class="visior_subblock visior_iconblock prodCount">
                                {inProduction.length > 0 ?
                                    <h4 class="visitrs_card-h4">{inProduction[0].statusCount ? inProduction[0].statusCount : "0"}</h4>
                                    : <h4 class="visitrs_card-h4">0</h4>}
                            </div>

                            <div class="visior_subblock statusName prodCountBg">
                                <h4 class="visitrs_card-h4">In Production</h4>
                            </div>
                        </div>


                        <div className='xl-parpl visitrs_cardblock'>

                            <div class="visior_subblock visior_iconblock transistCount">
                                {inTransit.length > 0 ?
                                    <h4 class="visitrs_card-h4">{inTransit[0].statusCount ? inTransit[0].statusCount : "0"}</h4>
                                    : <h4 class="visitrs_card-h4">0</h4>}
                            </div>

                            <div class="visior_subblock statusName transistCountBg">
                                <h4 class="visitrs_card-h4">In Transit</h4>
                            </div>
                        </div>


                        <div className='xl-parpl visitrs_cardblock'>
                            <div class="visior_subblock visior_iconblock receivedCount">
                                {received.length > 0 ?
                                    <h4 class="visitrs_card-h4">{received[0].statusCount ? received[0].statusCount : "0"}</h4>
                                    : <h4 class="visitrs_card-h4">0</h4>}
                            </div>
                            <div class="visior_subblock statusName receivedCountBg">
                                <h4 class="visitrs_card-h4">Received</h4>
                            </div>
                        </div>


                        <div className='xl-parpl visitrs_cardblock'>
                            <div class="visior_subblock visior_iconblock overdueCount">
                                {overdue.length > 0 ?
                                    <h4 class="visitrs_card-h4">{overdue[0].statusCount ? overdue[0].statusCount : "0"}</h4>
                                    : <h4 class="visitrs_card-h4">0</h4>}
                            </div>
                            <div class="visior_subblock statusName overdueCountBg">
                                <h4 class="visitrs_card-h4">Overdue</h4>
                            </div>
                        </div>

                    </div>

                </div>

            </div >

        </>
    )
}

export default LaborderStatus