import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from './leftmenu';
import Profiledetail from './profiledetail';
import Patientright from './patientright';
import Datepicker from 'react-datepicker';
import { callingAPI } from '../config';
import moment from 'moment';
import Add_img from '../images/icons/Add_img.png';
import Close from '../images/icons/close_img.png';
import SimplifiedFormLabOrder from '../Patients/simplifiedFormLabOrder';
import SimplifiedFormCasesheet from '../Patients/simplifiedFormCasesheet';
import SimplifiedFormPrescription from '../Patients/simplifiedFormPrescription';

function SimplifiedForm() {
	// const SimplifiedForm = forwardRef((props, ref) => {
	const [getCaseSheetData, setCaseSheetData] = useState([])
	const [getPrescriptionData, setPrescriptionData] = useState([])
	const [getLabOrderData, setLabOrderData] = useState([])
	const childCompRefcasesheet = useRef()
	const childCompReflaborder = useRef()
	const childCompRefprescription = useRef()
	const aceEditorRef = useRef();
	const inputRef = useRef();
	const [isActiveLab, setActiveLab] = useState(false)
	const [isActivePrescriptions, setisActivePrescriptions] = useState(false)
	const [isActiveCasesheet, setisActiveCasesheet] = useState(false)
	const [comments, setComments] = useState('');
	const [recallDate, setRecallDate] = useState('');

	const labOrderClick = () => {

		if (isActivePrescriptions === true && isActiveCasesheet === true && isActiveLab === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'block';
			document.getElementById('CasesheetContentDiv').style.display = 'none';
			document.getElementById('LabOrderPlainDiv').style.display = 'none';
			document.getElementById('LabOrderContentDiv').style.display = 'block';
			document.getElementById('PrescriptionPlainDiv').style.display = 'block';
			document.getElementById('PrescriptionContentDiv').style.display = 'none';

		}
		else if (isActivePrescriptions === true && isActiveLab === true) {
			// 	document.getElementById('CaseSheetPlainDiv').style.display = 'block';
			// document.getElementById('CasesheetContentDiv').style.display = 'none';
			document.getElementById('LabOrderPlainDiv').style.display = 'none';
			document.getElementById('LabOrderContentDiv').style.display = 'block';
			document.getElementById('PrescriptionPlainDiv').style.display = 'block';
			document.getElementById('PrescriptionContentDiv').style.display = 'none';


		}
		else if (isActiveLab === true && isActiveCasesheet === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'block';
			document.getElementById('CasesheetContentDiv').style.display = 'none';
			document.getElementById('LabOrderPlainDiv').style.display = 'none';
			document.getElementById('LabOrderContentDiv').style.display = 'block';
			// document.getElementById('PrescriptionPlainDiv').style.display = 'block';
			// document.getElementById('PrescriptionContentDiv').style.display = 'none';


		}
		else if (isActiveLab === true) {
			document.getElementById('LabOrderPlainDiv').style.display = 'none';
			document.getElementById('LabOrderContentDiv').style.display = 'block';

		}

	};
	const prescriptionClick = () => {
		if (isActivePrescriptions === true && isActiveCasesheet === true && isActiveLab === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'block';
			document.getElementById('CasesheetContentDiv').style.display = 'none';
			document.getElementById('LabOrderPlainDiv').style.display = 'block';
			document.getElementById('LabOrderContentDiv').style.display = 'none';
			document.getElementById('PrescriptionPlainDiv').style.display = 'none';
			document.getElementById('PrescriptionContentDiv').style.display = 'block';

		}
		else if (isActivePrescriptions === true && isActiveCasesheet === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'block';
			document.getElementById('CasesheetContentDiv').style.display = 'none';
			// document.getElementById('LabOrderPlainDiv').style.display = 'block';
			// document.getElementById('LabOrderContentDiv').style.display = 'none';
			document.getElementById('PrescriptionPlainDiv').style.display = 'none';
			document.getElementById('PrescriptionContentDiv').style.display = 'block';


		}
		else if (isActivePrescriptions === true && isActiveLab === true) {
			// document.getElementById('CaseSheetPlainDiv').style.display = 'block';
			// document.getElementById('CasesheetContentDiv').style.display = 'none';
			document.getElementById('LabOrderPlainDiv').style.display = 'block';
			document.getElementById('LabOrderContentDiv').style.display = 'none';
			document.getElementById('PrescriptionPlainDiv').style.display = 'none';
			document.getElementById('PrescriptionContentDiv').style.display = 'block';


		}
		else if (isActivePrescriptions === true) {
			document.getElementById('PrescriptionPlainDiv').style.display = 'none';
			document.getElementById('PrescriptionContentDiv').style.display = 'block';
			// document.getElementById('LabOrderPlainDiv').style.display = 'block';
			// document.getElementById('CaseSheetPlainDiv').style.display = 'block';

		}


	};

	const caseSheetClick = () => {
		// document.getElementById('CaseSheetPlainDiv').style.display = 'none';
		// document.getElementById('CasesheetContentDiv').style.display = 'block';
		// document.getElementById('LabOrderPlainDiv').style.display = 'block';
		// document.getElementById('LabOrderContentDiv').style.display = 'none';
		// document.getElementById('PrescriptionPlainDiv').style.display = 'block';
		// document.getElementById('PrescriptionContentDiv').style.display = 'none';
		if (isActivePrescriptions === true && isActiveCasesheet === true && isActiveLab) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'none';
			document.getElementById('CasesheetContentDiv').style.display = 'block';
			document.getElementById('LabOrderPlainDiv').style.display = 'block';
			document.getElementById('LabOrderContentDiv').style.display = 'none';
			document.getElementById('PrescriptionPlainDiv').style.display = 'block';
			document.getElementById('PrescriptionContentDiv').style.display = 'none';

		}
		else if (isActivePrescriptions === true && isActiveCasesheet === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'none';
			document.getElementById('CasesheetContentDiv').style.display = 'block';
			// document.getElementById('LabOrderPlainDiv').style.display = 'block';
			// document.getElementById('LabOrderContentDiv').style.display = 'none';
			document.getElementById('PrescriptionPlainDiv').style.display = 'block';
			document.getElementById('PrescriptionContentDiv').style.display = 'none';


		}
		else if (isActiveLab === true && isActiveCasesheet === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'none';
			document.getElementById('CasesheetContentDiv').style.display = 'block';
			document.getElementById('LabOrderPlainDiv').style.display = 'block';
			document.getElementById('LabOrderContentDiv').style.display = 'none';
			// document.getElementById('PrescriptionPlainDiv').style.display = 'block';
			// document.getElementById('PrescriptionContentDiv').style.display = 'none';


		}
		else if (isActiveCasesheet === true) {
			document.getElementById('CaseSheetPlainDiv').style.display = 'none';
			document.getElementById('CasesheetContentDiv').style.display = 'block';

		}


	};

	const recallClickSave = (recallDate, comments) => {
		let postData = {
			patientReacallId: "",
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			userId: window.sessionStorage.getItem('userId'),
			recallDateAndTime: recallDate ? Date.parse(moment(recallDate).format('YYYY-MM-DD hh:mm A')).toString() : "",
			status: "Pending",
			comments: comments
		}
		callingAPI('patientProfile/savePatientRecallDetails', postData).then((data) => {
			console.log("recallSave", data);
		})
	}

	const simplifiedformAllSave = (type) => {

		if (window.sessionStorage.getItem('medicineBranchId') === '4') {
			//if (getCaseSheetData.length > 0 || document.getElementById('clearinputname').value !== '') {
			if (getCaseSheetData.length > 0) {

				//	alert("getCaseSheetData")
				var casesheet = []
				casesheet.push(childCompRefcasesheet.current.showCaseSheet());
				// console.log(".showCaseSheet...showCaseSheet..............",casesheet);
				console.log(".showCaseSheet...showCaseSheet..........1....", ...casesheet);
				var getDate = casesheet[0].getDate
				var getcomment = casesheet[0].getcomment
				console.log(".showCaseSheet...showCaseSheet...........2...", recallDate);
				console.log(".showCaseSheet...showCaseSheet..........3....", comments);
				delete casesheet[0].getDate;
				delete casesheet[0].getcomment;
				console.log(".showCaseSheet...showCaseSheet..........4....", ...casesheet);

			}

			if (getPrescriptionData.length > 0) {
				// alert(getPrescriptionData.frequencyId)
				var prescription = []
				prescription.push(childCompRefprescription.current.showPrescription());
				console.log("prescription.......", prescription);
			}
			// if(getCaseSheetData.length < 0 && getPrescriptionData.length < 0 ){
			// 	alert("hiii")
			// 	return false;
			// }
			const postData = {
				"functionName": "saveSimplifiedForm",
				userId: window.sessionStorage.getItem('userId'),
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				"memberId": window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				"casesheet": casesheet ? casesheet : [],
				"casesheetMedical": [],
				"prescription": prescription ? prescription : [],
				"labOrder": [],
				prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
			}
			callingAPI('commonUserFunctions/saveAllSimplifiedForms', postData).then((data) => {
				console.log(postData, "dataa", data)
				if (data.data.success === '1') {
					if (type === "save") {
						if (getDate !== "" || getcomment !== "") {
							recallClickSave(getDate, getcomment);
						}
						window.location.href = '/patients/patientconsult';
					}
					else {
						console.log("data.data.result[0]", data.data.result[0])
						if (getDate !== "" || getcomment !== "") {
							recallClickSave(getDate, getcomment);
						}
						localStorage.setItem('simplifiedPrint', JSON.stringify(data.data.result));
						window.sessionStorage.setItem('saveAndPrintButton', 1);
						// window.open('/patients/simplifiedFormPrint', "_blank")
						window.location.href = '/patients/simplifiedForm'
						// window.print();
					}
					//  
					// setDataFlag(true);
					// window.location.href = '/Patients/casesheetDental';
					// alert('Case Sheet Saved Successfully');
				} else if (data.data.success === '0') {
					// setLoad(true);

					alert(data.data.errorMessage);
					// // setDataFlag(true);
					// // alert(data.data.errorMessage);
					// // window.location.href = '/Patients/casesheetDentalAdd';
				}
				// setDisable(false);
			})

			//  if(getLabOrderData.length > 0 ){
			// 	alert("getLabOrderData")
			// 	var labOrder = childCompReflaborder.current.showLabOrder();
			// 	console.log("labOrder.......",labOrder);
			// }

			// setTimeout(() => {
			// 	window.location.href = '/Patients/simplifiedForm';
			// }, 3000);
		}
		else {
			// if (getCaseSheetData.length > 0 || document.getElementById('clearinputname').value !== '') {

			if (getCaseSheetData.length > 0) {
				//	alert("getCaseSheetData")
				var casesheet = []
				casesheet.push(childCompRefcasesheet.current.showCaseSheet());
				console.log(".showCaseSheet...showCaseSheet..........1....", ...casesheet);
				var getDate = casesheet[0].getDate
				var getcomment = casesheet[0].getcomment
				// var recallClick = casesheet[0].recallClick
				console.log(".showCaseSheet...showCaseSheet..........3....", getDate, getcomment);
				delete casesheet[0].getDate;
				delete casesheet[0].getcomment;
				// delete casesheet[0].recallClick;
				console.log(".showCaseSheet...showCaseSheet..........4....", ...casesheet);

			}
			if (getPrescriptionData.length > 0) {
				var prescription = []
				prescription.push(childCompRefprescription.current.showPrescription());
			}
			if (getLabOrderData.length > 0) {
				//alert("getLabOrderData")
				var laborder = []
				laborder.push(childCompReflaborder.current.showLabOrder());
			}
			// var recallClick = casesheet[0].recallClick
			// delete casesheet[0].recallClick;
			// if (recallClick === true){
			// 	alert("Please Enter Recall Date")
			// 	return false;
			// }
			// else{
			const postData = {
				"functionName": "saveSimplifiedForm",
				userId: window.sessionStorage.getItem('userId'),
				doctorId: window.sessionStorage.getItem('patientDoctorId'),
				"memberId": window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				"casesheet": [],
				"casesheetMedical": casesheet ? casesheet : [],
				"prescription": prescription ? prescription : [],
				"labOrder": laborder ? laborder : [],
				prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,
			}
			callingAPI('commonUserFunctions/saveAllSimplifiedForms', postData).then((data) => {
				console.log(postData, "dataameddddddddddddddddd", comments, recallDate)
				if (data.data.success === '1') {
					if (type === "save") {
						if (getDate !== "" || getcomment !== "") {
							recallClickSave(getDate, getcomment);
						}
						window.location.href = '/patients/patientconsult';
					}
					else {
						console.log("data.data.result[0]", data.data.result[0])
						if (getDate !== "" || getcomment !== "") {
							recallClickSave(getDate, getcomment);
						}
						localStorage.setItem('simplifiedPrint', JSON.stringify(data.data.result));
						window.sessionStorage.setItem('saveAndPrintButton', 1);
						// window.open('/patients/simplifiedFormPrint', "_blank")
						window.location.href = '/patients/simplifiedForm'
						// window.print();
					}
					//  
					// setDataFlag(true);
					// window.location.href = '/Patients/casesheetDental';
					// alert('Case Sheet Saved Successfully');
				} else if (data.data.success === '0') {
					// setLoad(true);

					alert(data.data.errorMessage);
					// // setDataFlag(true);
					// // alert(data.data.errorMessage);
					// // window.location.href = '/Patients/casesheetDentalAdd';
				}
				// setDisable(false);
			})
			// }
			// setTimeout(() => {
			// 	window.location.href = '/Patients/simplifiedForm';
			// }, 8000);
		}

	}
	const saveAndPrint = async () => {
		// simplifiedformAllSave()
		// useImperativeHandle(ref, () => ({
		// 	saveAndPrintPage() {

		// 	}
		// }))
	}

	const save = async (type) => {
		// if (getCaseSheetData.length == 0 || document.getElementById('clearinputname').value === '' && getPrescriptionData.length == 0 && getLabOrderData.length == 0) {
		// if (document.getElementById('clearinputname').value === '' && getCaseSheetData.length == 0 && getPrescriptionData.length == 0 && getLabOrderData.length == 0) {

		// if (getCaseSheetData.length == 0 && getPrescriptionData.length == 0 && getLabOrderData.length == 0) {
		// 	alert("Please Fill the Data")
		// }
		// else {
		await simplifiedformAllSave(type)
		//	await simplifiedformAllSave1()

		//}
	}

	const onCaseSheetHandler = (caseSheetData) => {
		setCaseSheetData(caseSheetData);

	};
	const onPrescriptionHandler = (prescriptionData) => {
		setPrescriptionData(prescriptionData);

	};
	const onLaborderHandler = (LaborderData) => {
		setLabOrderData(LaborderData);

	};
	useEffect(() => {
		if (window.sessionStorage.getItem('saveAndPrintButton') == 1) {
			window.open('/patients/simplifiedFormPrintNew', "_blank")
		}
		simplifiedListing();
	}, []);
	const simplifiedListing = () => {
		window.sessionStorage.removeItem('saveAndPrintButton');
		const postdata = {

			"doctorId": (window.sessionStorage.getItem('role') === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION") ? "" : window.sessionStorage.getItem('userId'),
			"role": window.sessionStorage.getItem('role'),
			"hospitalId": localStorage.getItem('hospitalId'),

		};
		callingAPI('commonUserFunctions/simplifiedSettingsList', postdata).then((res) => {
			console.log("responselisting", res.data.result.main)
			setisActivePrescriptions(res.data.result.main[2].isActive)
			setActiveLab(res.data.result.main[3].isActive)
			setisActiveCasesheet(res.data.result.main[0].isActive)



		});
	}
	return (
		<div className="coverWraper">


			<Header />
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap simplifiedForm">
				<Leftmenu />

				<div className="rightSection">

					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-12 col-md-12 practiceLeftSection simWraper">

								<div className='col-lg-8 col-md-8'>



									<div class="treatmentPlanHead EntryFormsSec" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
										<span class="treatmentPlanName">ENTRY FORMS &nbsp;</span>
										<span
											onClick={() => { save("saveandprint") }}
											class="treatmentPlanBtn pl_blue_btn"
											id="saveid"
											style={{ background: '#5bb75b', display: 'block' }}
										>
											Save and Print
										</span>
										<span onClick={() => { save("save") }}
											class="treatmentPlanBtn pl_blue_btn"
											id="saveid"
											style={{ background: '#5bb75b', display: 'block' }}
										>
											Save
										</span>


									</div>

								</div>

								{/* casesheet */}
								{window.sessionStorage.getItem('medicineBranchId') === '4' ? (
									<div>
										{isActiveCasesheet === true ?
											<>
												<div className='col-lg-8 col-md-8' >

													<div class="treatmentPlanHead EntryFormsSec labOrderHeadDiv"
														style={{ display: 'none' }}
														id="CaseSheetPlainDiv">
														<span class="treatmentPlanName labOrderHeadDivPlusName ">Case Sheet</span>
														<button onClick={() => caseSheetClick()}
															class="labOrderHeadDivPlus  ">+</button>
													</div>


												</div>
												<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
													style={{ display: 'block' }}
													id="CasesheetContentDiv">
													<div id="divPatientContent" class="sideMenuContent">
														<div class="treatmentPlanCover" id="treatmentPlanListDiv">
															<div class="treatmentClinicContent" id="treatmentClinicContentId">
																{/* <SimplifiedFormLabOrder /> */}
																<SimplifiedFormCasesheet
																	onCaseSheet={onCaseSheetHandler}
																	ref={childCompRefcasesheet} />
																{/* <SimplifiedFormPrescription /> */}
															</div>

														</div>
													</div>
												</div>

											</>
											: ""}

										{/* Prescription */}
										{isActivePrescriptions === true ?
											<>
												<div className='col-lg-8 col-md-8'>
													<div class="treatmentPlanHead EntryFormsSec labOrderHeadDiv"
														style={{ display: 'block' }}
														id="PrescriptionPlainDiv">
														<span class="treatmentPlanName labOrderHeadDivPlusName ">Prescription</span>
														<button onClick={() => prescriptionClick()}
															class="labOrderHeadDivPlus  ">+</button>
													</div>

												</div>

												<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
													style={{ display: 'none' }}
													id="PrescriptionContentDiv">
													<div id="divPatientContent" class="sideMenuContent">
														<div class="treatmentPlanCover" id="treatmentPlanListDiv">
															<div class="treatmentClinicContent" id="treatmentClinicContentId">
																{/* <SimplifiedFormLabOrder /> */}
																{/* <SimplifiedFormCasesheet /> */}

																<SimplifiedFormPrescription
																	onPrescription={onPrescriptionHandler}
																	ref={childCompRefprescription} />
															</div>

														</div>
													</div>
												</div>
											</>
											: ""}
										{/* Lab Order */}
										{isActiveLab === true ?
											<>
												<div className='col-lg-8 col-md-8'

													style={{ display: 'none' }}
												>

													<div class="treatmentPlanHead EntryFormsSec labOrderHeadDiv"
														style={{ display: 'block' }}
														id="LabOrderPlainDiv">
														<span class="treatmentPlanName labOrderHeadDivPlusName ">Lab Order</span>
														<button onClick={() => labOrderClick()}
															class="labOrderHeadDivPlus  ">+</button>
													</div>


												</div>

												<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
													style={{ display: 'none' }}
													id="LabOrderContentDiv">
													<div id="divPatientContent" class="sideMenuContent">
														<div class="treatmentPlanCover" id="treatmentPlanListDiv">
															<div class="treatmentClinicContent" id="treatmentClinicContentId">
																<SimplifiedFormLabOrder
																	onLaborder={onLaborderHandler}
																	ref={childCompReflaborder}
																// ref="labReference"
																/>
																{/* <SimplifiedFormCasesheet /> */}
																{/* <SimplifiedFormPrescription /> */}
															</div>

														</div>
													</div>
												</div>
											</>
											: ""}
									</div>
								) :
									<div>
										{isActiveCasesheet === true ?
											<>
												<div className='col-lg-8 col-md-8' >

													<div class="treatmentPlanHead EntryFormsSec labOrderHeadDiv"
														style={{ display: 'none' }}
														id="CaseSheetPlainDiv">
														<span class="treatmentPlanName labOrderHeadDivPlusName ">Case Sheet</span>
														<button onClick={() => caseSheetClick()}
															class="labOrderHeadDivPlus  ">+</button>
													</div>

												</div>
												<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
													style={{ display: 'block' }}
													id="CasesheetContentDiv">
													<div id="divPatientContent" class="sideMenuContent">
														<div class="treatmentPlanCover" id="treatmentPlanListDiv">
															<div class="treatmentClinicContent" id="treatmentClinicContentId">
																{/* <SimplifiedFormLabOrder /> */}
																<SimplifiedFormCasesheet
																	onCaseSheet={onCaseSheetHandler}
																	ref={childCompRefcasesheet} />
																{/* <SimplifiedFormPrescription /> */}
															</div>

														</div>
													</div>
												</div>
											</>
											: ""}

										{/* Prescription */}
										{isActivePrescriptions === true ?
											<>
												<div className='col-lg-8 col-md-8'>
													<div class="treatmentPlanHead EntryFormsSec labOrderHeadDiv"
														style={{ display: 'block' }}
														id="PrescriptionPlainDiv">
														<span class="treatmentPlanName labOrderHeadDivPlusName ">Prescription</span>
														<button onClick={() => prescriptionClick()}
															class="labOrderHeadDivPlus  ">+</button>
													</div>

												</div>

												<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
													style={{ display: 'none' }}
													id="PrescriptionContentDiv">
													<div id="divPatientContent" class="sideMenuContent">
														<div class="treatmentPlanCover" id="treatmentPlanListDiv">
															<div class="treatmentClinicContent" id="treatmentClinicContentId">
																{/* <SimplifiedFormLabOrder /> */}
																{/* <SimplifiedFormCasesheet /> */}

																<SimplifiedFormPrescription
																	onPrescription={onPrescriptionHandler}
																	ref={childCompRefprescription} />
															</div>

														</div>
													</div>
												</div>
											</>
											: ""}
										{/* Lab Order */}
										{isActiveLab === true ?
											<>
												<div className='col-lg-8 col-md-8'
													style={{ display: 'block' }}
												>
													<div class="treatmentPlanHead EntryFormsSec labOrderHeadDiv"
														style={{ display: 'block' }}
														id="LabOrderPlainDiv">
														<span class="treatmentPlanName labOrderHeadDivPlusName ">Lab Order</span>
														<button onClick={() => labOrderClick()}
															class="labOrderHeadDivPlus  ">+</button>
													</div>


												</div>

												<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour"
													style={{ display: 'none' }}
													id="LabOrderContentDiv">
													<div id="divPatientContent" class="sideMenuContent">
														<div class="treatmentPlanCover" id="treatmentPlanListDiv">
															<div class="treatmentClinicContent" id="treatmentClinicContentId">
																<SimplifiedFormLabOrder
																	onLaborder={onLaborderHandler}
																	ref={childCompReflaborder}
																// ref="labReference"
																/>
																{/* <SimplifiedFormCasesheet /> */}
																{/* <SimplifiedFormPrescription /> */}
															</div>

														</div>
													</div>
												</div>
											</>
											: ""}
									</div>
								}
								{/* ///// */}
							</div>

						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}
export default SimplifiedForm;
