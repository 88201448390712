import PharmacyHeader from "../labLogin/layout/header";
import Footer from "../labLogin/layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "../pharmacyLogin/pharmacyLeft";
import Pagination from "react-js-pagination";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { callingAPI } from "../config";
import Loader from "../Common/loader";
import NoData from "../Common/noDataFound";
function PatientMedicineView(props) {
  const [medicineDetails, setMedicineDetails] = useState([]);
  const [dataFlag, setDataFlag] = useState(false);
  const location = useLocation();
  const [drugListingArr, setDrugListingArr] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const [searchType, setSearchType] = useState(0);
  const [isData, setIsData] = useState(0);
  const [medicine, setmedicine] = useState("");
  const [MedicineIdSetting, setMedicineIdSetting] = useState("");
  useEffect(() => {
    setmedicine("");
    if (typeof location.state !== "undefined") {
      const { medname } = location.state;
      const { index1 } = location.state;
      setMedicineIdSetting(index1);
      const PostData = {
        roleName: window.sessionStorage.getItem("role"),
        medicineName: "",
        userId: window.sessionStorage.getItem("userId"),
        compId: props.location.state.index,
        hdnIdHospital2: MedicineIdSetting,
      };
      callingAPI("viewMedicineList/viewMedicineList", PostData).then((data) => {
        setMedicineDetails(data.data.result.medicineDetails);
        setIsData(1);
      });
    }
  }, [props]);
  const searchFunction = () => {
    setMedicineIdSetting("");
    let PostData = {
      roleName: window.sessionStorage.getItem("role"),
      medicineName: searchValue,
      userId: window.sessionStorage.getItem("userId"),
      compId: props.location.state.index,
      hdnIdHospital2: medicine == "" ? "" : MedicineIdSetting,
    };
    callingAPI("viewMedicineList/viewMedicineList", PostData)
      .then((data) => {
        if (data.data.success === "1") {
          setMedicineDetails(data.data.result.medicineDetails);
        } else {
          setMedicineDetails([]);
        }
      })
      .catch();
  };
  const searchValueFunction = (event) => {
    setSearchValue(event.target.value);
    getPrecriptionMedicineAutoComplete();
  };
  const getPrecriptionMedicineAutoComplete = () => {
    const postdata = {
      role: window.sessionStorage.getItem("role"),
      userId: window.sessionStorage.getItem("userId"),
      keyWordMedicine: document.getElementById("searchText").value,
    };
    callingAPI(
      "medicinePrescription/medicineAutoCompleteDetails",
      postdata
    ).then((data) => {
      if (data.data.success === "1" && data.data.result.length > 0) {
        setDrugListingArr(data.data.result);
        document.getElementById("prescriptionMedicineList").style.display =
          "block";
      } else {
        document.getElementById("prescriptionMedicineList").style.display =
          "none";
        setDrugListingArr([]);
      }
    });
  };
  const setMedicineName = (name, medicineId) => {
    setMedicineIdSetting(medicineId);
    setSearchValue(name);
    setmedicine(name);
    document.getElementById("prescriptionMedicineList").style.display = "none";
  };
  const onBlurItemCategory = () => {
    document.getElementById("prescriptionMedicineList").style.display = "none";
    setDrugListingArr([]);
  };
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <div className="row">
        <div className="breadcumbDiv">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/pharmacyLogin/patientMedicine">Patient Medicine</Link>
            </BreadcrumbItem>
            <div class="arrow-right" style={{ marginLeft: "7px" }} />
            <BreadcrumbItem>Medicines List</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle">Medicines List</div>
        </div>
      </div>
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch" onClick={onBlurItemCategory}>
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  className="inputField"
                  placeholder="Select Medicine"
                  id="searchText"
                  autoComplete="off"
                  name="searchText"
                  value={searchValue}
                  // disabled={isEnabled}
                  onChange={searchValueFunction}
                />
                <ul
                  className="autoSearch autoSearch-p0 appointmentNumberSearch"
                  id="prescriptionMedicineList"
                  style={{ display: "none" }}
                >
                  {drugListingArr.map((item, i) => (
                    <li
                      onClick={() =>
                        setMedicineName(item.medicineName, item.medicineId)
                      }
                    >
                      {item.medicineName}
                    </li>
                  ))}
                </ul>
                <input type="hidden" id="hiddenPrescriptionMedicineId" />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span className="dashButtonBorder" onClick={searchFunction}>
                  Search
                </span>
              </div>
            </div>
          </div>
          <div className="dashboardtable">
            {isData == 0 ? (
              <Loader />
            ) : medicineDetails.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Medicine Name </th>
                    <th>Dosage/ Intake </th>
                    <th>No.of days prescribed </th>
                    <th>Prescribed Quantity </th>
                    <th>Billing Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {medicineDetails.map((index) => (
                    <tr>
                      <td>{index.medicineName}</td>
                      <td>{index.dosage}</td>
                      <td>{index.prescribed}</td>
                      <td>{index.prescribedquantity}</td>
                      <td>{index.billing}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <div className="paginationSection"></div>
      </div>
    </div>
  );
}
export default PatientMedicineView;
