import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { change_userId } from "../Redux/user/userData";
import jwt from "jsonwebtoken";
import Footer from "../layout/footer";
import { Link } from "react-router-dom";
import teethbanner from "../images/media/teethbanner.jpg";
import pulseMain from "../images/media/pulseMain.jpg";
import img1 from "../images/media/1.png";
import img2 from "../images/media/2.png";
import img3 from "../images/media/3.png";
import qleft from "../images/icons/q-left.png";
import healthBack from "../images/media/healthback.jpg";
import PoweredBy from '../images/media/powered-By.png'
import LoginHeader from "../layout/loginHeader";
import { Helmet } from "react-helmet";
import $ from "jquery";
import axios from "axios";
import MembersReceive from "./membersReceive";
import { callingAPI } from "../config";
function Login() {
  let { siteId, token } = useParams();
  if (typeof token === "undefined") {
    token = "";
  }
  if (
    token.toString().length < 6 &&
    typeof token !== "undefined" &&
    token !== "" &&
    (siteId === "" || typeof siteId === "undefined")
  ) {
    siteId = token;
    token = "";
  } else {
    siteId =
      typeof siteId === "undefined"
        ? ""
        : siteId.toString().indexOf("GROUP") != -1
          ? siteId.toString().split("-")[1]
          : siteId;
    token = typeof token === "undefined" ? "" : token;
  }
  const [pmSiteId, setSiteId] = useState(siteId);
  const [tokenData, setTokenData] = useState(token);

  const userId = useSelector((state) => state.userId.value);
  const dispatch = useDispatch();
  const [thisismycopy, setthisismycopy] = useState("");
  const [userName, setUserName] = useState("");
  // const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrors, setloginErrors] = useState("");
  const [forgotPassErrors, setForgotPassErrors] = useState("");
  const [login, setLogin] = useState("block");
  const [forgotPassword, setforgotPassword] = useState("none");
  const [forgotUserName, setForgotUserName] = useState("");
  const [siteInnerHtml, setsiteInnerHtml] = useState("");
  const [pulseTitle, setpulseTitle] = useState("");
  const [isData, setisData] = useState(false);
  const [adminEmailId, setAdminEmailId] = useState("");
  const [supportEmailId, setSupportEmailId] = useState("");
  const [subject, setSubject] = useState("");
  const [bccEmailRequired, setBccEmailRequired] = useState(0);
  const [ccEmailRequired, setCcEmailRequired] = useState(0);
  const [html, setHtml] = useState("");
  const [ip, setIP] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [popUpShowArray, setPopUpShowArray] = useState([]);
  const [arrayResult, setArrayResult] = useState([]);
  const [popClickId, setPopClickId] = useState("");
  const [popHosClickId, setpopHosClickId] = useState("");
  const [doctorPopUpArray, setDoctorPopUpArray] = useState([]);
  // var localIpV4Address = require("local-ipv4-address");

  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      setLoginHandler();
    }
  };
  const handleKeypressForgot = (event) => {
    if (event.key === "Enter") {
      submitForgotPassword();
    }
  };


  // const doctorRedirectURL = (hospitalLength, url) => {
  //   if (hospitalLength > 1) {
  //     document.getElementById("PopupShowDoctor").style.display = "block";
  //   } else {
  //     window.location.href = url;
  //   }
  // };
  const redirectURL = (associationLength, url, siteId) => {
    if (associationLength > 1) {
      document.getElementById("PopupShow").style.display = "block";
    } else {
      window.sessionStorage.setItem("siteId", siteId);
      window.location.href = url;
    }
  };
  const calenderListingCount = async (role, doctId, hospId) => {
    return new Promise((resolve, reject) => {

      let postData = {}
      if (role == 'ROLE_DOCTOR') {
        postData = {
          "doctorId": doctId,
          "hospitalId": ""
        }
      }
      else {
        postData = {
          "doctorId": "",
          "hospitalId": hospId
        }
      }

      callingAPI("calenderListing/scheduleListCount", postData).then((data) => {

        localStorage.setItem("doctorAppointmentCount", data.data.result.totalCount);

        resolve(true)
      })
    })
  }
  const setLoginHandler = () => {
    setloginErrors("");
    setLogin("block");
    setforgotPassword("none");
    let flag = true;
    var phoneno = /^[0-9]+$/;
    var num = /^\d{10}$/;
    var email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
    if (!userName) {
      setloginErrors("Mobile Number/Email ID required");
      document.getElementById("userName").focus();
      flag = false;
    } else if (userName.match(phoneno) && !userName.match(num)) {
      setloginErrors("Please enter valid mobile number");
      document.getElementById("userName").focus();
      flag = false;
    } else if (!userName.match(phoneno) && !email.test(userName)) {
      setloginErrors("Please enter valid Email ID/Mobile Number");
      document.getElementById("userName").focus();
      flag = false;
    } else if (!password) {
      setloginErrors("Please enter Password");
      document.getElementById("password").focus();
      flag = false;
    }

    if (flag === true) {
      setloginErrors("");
      const token = jwt.sign(
        {
          username: userName,
          password: password,
        },
        process.env.REACT_APP_JWT_SECRET
      );
      const apiJson = {
        token: token,
        siteId: window.sessionStorage.getItem("siteId"),
        ipAddress: ip,
        macId: "0",
      };

      callingAPI("userLogin/logIn", apiJson).then(async (data) => {

        if (data.data.success === "1") {

          await calenderListingCount(data.data.result[0].role, data.data.result[0].userId, data.data.result[0].hospitalId)

          setArrayResult(data);
          setDoctorPopUpArray(data.data.result)
          var doctorPopUpListLength = data.data.result.length
          setPopUpShowArray(data.data.associationDetails);
          var associationDetailsLength = data.data.associationDetails.length;
          var associationSiteId = data.data.associationDetails[0].siteId;
          let hospitalId = [];
          let isOwner = false;
          data.data.result.map((obj) => {
            hospitalId.push(obj.hospitalId);
            if (obj.isOwner === "true") {
              isOwner = true;
            }
          });
          var hospitalIds = hospitalId.join(",");
          if (data.data.result.length === 1) {
            //localStorage.setItem("hospitalId", hospitalIds);
            localStorage.setItem("hospitalId", data.data.result[0].hospitalId);
          } else if (data.data.result.length > 1 && isOwner === false) {
            localStorage.setItem("hospitalId", data.data.result[0].hospitalId);
          }

          // dispatch(change_userId({
          //     userId:data.data.result[0].userId
          // }))
          window.sessionStorage.setItem("role", data.data.result[0].role);
          window.sessionStorage.setItem("isPrNumEnabled", data.data.result[0].isPrNoEnabled);
          window.sessionStorage.setItem("userId", data.data.result[0].userId);
          window.sessionStorage.setItem(
            "userEmail",
            data.data.result[0].emailId
          );
          // window.sessionStorage.setItem("userTitle", data.data.result[0].userTitle);k
          if (data.data.result[0].role === "ROLE_DOCTOR") {
            window.sessionStorage.setItem(
              "userName",
              data.data.result[0].userTitle + data.data.result[0].userName
            );
          } else {
            window.sessionStorage.setItem(
              "userName",
              data.data.result[0].userName
            );
            window.sessionStorage.setItem("loginRole", "Administrator");
            window.sessionStorage.setItem("isPrNumEnabled", data.data.result[0].isPrNoEnabled);
          }
          window.sessionStorage.setItem("isLoggedIn", "true");
          window.sessionStorage.setItem("loginHospitalIds", hospitalIds);
          if (data.data.result[0].role === "ROLE_DOCTOR") {
            window.sessionStorage.setItem(
              "isOwner",
              data.data.result[0].isOwner
            );

            window.sessionStorage.setItem(
              "medicineBranchId",
              data.data.result[0].medicineBranchId
            );
            window.sessionStorage.setItem(
              "specializationId",
              data.data.result[0].specializationId
            );
            window.sessionStorage.setItem(
              "specializationName",
              data.data.result[0].specializationName
            );
            window.sessionStorage.setItem(
              "doctorId",
              data.data.result[0].userId
            );
            window.sessionStorage.setItem(
              "userName",
              data.data.result[0].userTitle + data.data.result[0].userName
            );

            window.sessionStorage.setItem(
              "userEmail",
              data.data.result[0].emailId
            );
            window.sessionStorage.setItem("homeHeader", "headerIconActive");

            window.sessionStorage.setItem("calenderHeader", "active");
            window.sessionStorage.setItem("loginRole", "Doctor");
            window.sessionStorage.setItem("isPrNumEnabled", data.data.result[0].isPrNoEnabled);
            if (doctorPopUpListLength > 1) {
              window.sessionStorage.setItem("siteId", associationSiteId);
              document.getElementById("PopupShowDoctor").style.display = "block";
            }
            else {
              redirectURL(
                associationDetailsLength,
                "Dashboard/dashboardView",
                associationSiteId
              );
            }

          } else if (data.data.result[0].role === "ROLE_HOSPITALLAB") {
            window.sessionStorage.setItem(
              "actualUserId",
              data.data.result[0].userId
            );
            window.sessionStorage.setItem("loginRole", "Hospital Lab");
            window.sessionStorage.setItem("isOwner", false);

            redirectURL(
              associationDetailsLength,
              "/labLogin/labOrderDetails",
              associationSiteId
            );
          } else if (
            window.sessionStorage.getItem("role") === "ROLE_RECEPTION"
          ) {
            window.sessionStorage.setItem(
              "actualUserId",
              data.data.result[0].userId
            );
            window.sessionStorage.setItem("isOwner", false);
            window.sessionStorage.setItem("loginRole", "Receptionist");
            window.sessionStorage.setItem("clinicsHeader", "headerIconActive");
            redirectURL(
              associationDetailsLength,
              "/clinics/calendar",
              associationSiteId
            );
          } else if (data.data.result[0].role === "ROLE_NURSE") {
            window.sessionStorage.setItem(
              "actualUserId",
              data.data.result[0].userId
            );
            window.sessionStorage.setItem("isOwner", false);
            window.sessionStorage.setItem("loginRole", "Nurse");
            window.sessionStorage.setItem("clinicsHeader", "headerIconActive");
            redirectURL(
              associationDetailsLength,
              "/clinics/calendar",
              associationSiteId
            );
          } else if (data.data.result[0].role === "ROLE_BILLINGADMIN") {
            window.sessionStorage.setItem(
              "actualUserId",
              data.data.result[0].userId
            );
            window.sessionStorage.setItem(
              "loginRole",
              "Hospital Billing Admin"
            );
            window.sessionStorage.setItem("isOwner", false);
            redirectURL(
              associationDetailsLength,
              "/billingLogin/procedurePayment",
              associationSiteId
            );
          } else if (data.data.result[0].role === "ROLE_HOSPITALPHARMACY") {
            window.sessionStorage.setItem(
              "actualUserId",
              data.data.result[0].userId
            );
            window.sessionStorage.setItem("loginRole", "Pharmacy");
            window.sessionStorage.setItem("isOwner", false);
            redirectURL(
              associationDetailsLength,
              "/pharmacyLogin/prescriptionDetails",
              associationSiteId
            );
          } else {
            // window.location.href = "/hospital Admin/hospitalHome";
            redirectURL(
              associationDetailsLength,
              // "/hospital Admin/hospitalHome",
              "/Dashboard/dashboardView",
              associationSiteId
            );
            window.sessionStorage.setItem("homeHeader", "headerIconActive");
            window.sessionStorage.setItem("calenderHeader", "active");
            window.sessionStorage.setItem("isPrNumEnabled", data.data.result[0].isPrNoEnabled);
            window.sessionStorage.removeItem("clinicsHeader");
            window.sessionStorage.removeItem("communicationsHeader");
            window.sessionStorage.removeItem("reportsHeader");
            window.sessionStorage.removeItem("appointmentsHeader");
            window.sessionStorage.removeItem("patientsHeader");
            window.sessionStorage.removeItem("doctorHeader");
            window.sessionStorage.removeItem("staffHeader");
            window.sessionStorage.removeItem("addpatientsHeader");
            window.sessionStorage.removeItem("treatmentHeader");
            window.sessionStorage.removeItem("dashboardHeader");
            window.sessionStorage.removeItem("reportHeader");
          }
        } else {
          setloginErrors("Invalid Mobile Number/Email ID or Password");
          setUserName("");
          setPassword("");
          document.getElementById("userName").focus();
        }
      });
    }
  };
  // const setingPassword=()=>{
  // 	window.sessionStorage.setItem('password',password);
  // }
  const siteNameRadioBtnDoctor = (data) => {
    setpopHosClickId(data.hospitalId);
  };
  const siteNameRadioBtn = (data) => {
    setPopClickId(data.siteId);
  };

  // const closePopUp = () => {
  //   document.getElementById('PopupShow').style.display = 'none';
  // }
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    setIP(res.data.IPv4);
  };

  const siteNameRadioBtnOKDoc = () => {
    var radioValue = $("input[name='rdHospitalId']:checked").val();
    if (radioValue !== "") {
      localStorage.setItem("hospitalId", radioValue);
      window.location.href = "Dashboard/dashboardView";
    } else {
      alert("Please choose an Hospital");
      document.getElementById("PopupShowDoctor").style.display = "block";
    }
  };
  const siteNameRadioBtnOK = () => {
    if (popClickId !== "") {
      window.sessionStorage.setItem("siteId", popClickId);
      window.location.href = "Dashboard/dashboardView";
    } else {
      alert("Please choose an association");
      document.getElementById("PopupShow").style.display = "block";
    }
  };
  useEffect(() => {


    //if (window.sessionStorage.getItem('siteId') == null || window.sessionStorage.getItem('siteId') == "null" || window.sessionStorage.getItem('siteId') == "") {

    getData();

    //   localIpV4Address().then(function(ipAddress){

    //     // My IP address is 10.4.4.137

    // });
    let siteId = "";
    let siteURL = "";
    if (pmSiteId) {
      siteId = pmSiteId;
    } else {
      siteURL = window.location.host;
    }
    const postdata = {
      siteUrl: siteURL,
      id: siteId,
    };
    callingAPI("commonUserFunctions/getSiteUrl", postdata).then(
      async (data) => {

        if (data.data.success === "1") {
          window.sessionStorage.setItem(
            "siteId",
            data.data.result.siteUrlList[0].id
          );
          window.sessionStorage.setItem(
            "siteBranchId",
            data.data.result.siteUrlList[0].branchId
          );
          window.sessionStorage.setItem(
            "siteName",
            data.data.result.siteUrlList[0].name
          );
          window.sessionStorage.setItem(
            "siteLogo",
            data.data.result.siteUrlList[0].logo
          );
          window.sessionStorage.setItem(
            "siteFavIcon",
            data.data.result.siteUrlList[0].favIcon
          );
          window.sessionStorage.setItem(
            "sitePhoneNumber",
            data.data.result.siteUrlList[0].contactNo
          );
          window.sessionStorage.setItem(
            "siteEmailId",
            data.data.result.siteUrlList[0].email
          );
          await setsiteInnerHtml(data.data.result.siteUrlList[0].innerHtml);
          await setthisismycopy(data.data.result.siteUrlList[0].innerHtml);
          setisData(true);
          setSiteId(data.data.result.siteUrlList[0].id);
          setpulseTitle(data.data.result.siteUrlList[0].pulseTitle);
          if (tokenData) {
            decreptURL();
          }
          //$("#pulseContentSec").html($("#loginDiv").html())
          //	document.getElementById("pulseContentSec").innerHTML = document.getElementById("loginDiv").innerHTML
        } else {
          window.sessionStorage.removeItem("siteId");
          window.sessionStorage.removeItem("siteName");
          window.sessionStorage.removeItem("siteLogo");
          window.sessionStorage.removeItem("siteFavIcon");
          window.sessionStorage.removeItem("dashboardHeader");
          window.sessionStorage.removeItem("reportHeader");
          window.sessionStorage.removeItem("appointmentsHeader");
          window.sessionStorage.removeItem("patientsHeader");
          window.sessionStorage.removeItem("addpatientsHeader");
          window.sessionStorage.removeItem("communicationsHeader");
          window.sessionStorage.removeItem("clinicsHeader");
          window.sessionStorage.removeItem("reportsHeader");
          window.sessionStorage.removeItem("doctorHeader");
          window.sessionStorage.removeItem("staffHeader");
          window.sessionStorage.removeItem("treatmentHeader");
          window.sessionStorage.removeItem("siteBranchId");
          setisData(true);
        }
      }
    );

    setTimeout(() => {
      window.sessionStorage.setItem("setForgotPassErrors", "");
    }, 3000);
  }, []);

  const decreptURL = () => {
    const apiJson = {
      encryptedData: tokenData,
    };
    callingAPI("commonUserFunctions/decryptPulseUrl", apiJson).then((data) => {
      if (data.data.success === "1") {
        window.sessionStorage.setItem(
          "redirectURL",
          data.data.result[0].siteUrl
        );
        window.sessionStorage.setItem(
          "userName",
          data.data.result[0].userTitle + data.data.result[0].userName
        );
        window.sessionStorage.setItem("role", data.data.result[0].role);
        window.sessionStorage.setItem("userId", data.data.result[0].userId);
        window.sessionStorage.setItem("userEmail", data.data.result[0].emailId);
        localStorage.setItem("hospitalId", data.data.result[0].hospitalId);
        window.sessionStorage.setItem("isLoggedIn", "true");
        window.sessionStorage.setItem(
          "loginHospitalIds",
          data.data.result[0].hospitalId
        );
        if (data.data.result[0].role === "ROLE_DOCTOR") {
          window.sessionStorage.setItem("isOwner", data.data.result[0].isOwner);
          window.sessionStorage.setItem(
            "medicineBranchId",
            data.data.result[0].medicineBranchId
          );
          window.sessionStorage.setItem(
            "specializationId",
            data.data.result[0].specializationId
          );
          window.sessionStorage.setItem(
            "specializationName",
            data.data.result[0].specializationName
          );
          window.sessionStorage.setItem("doctorId", data.data.result[0].userId);
          window.sessionStorage.setItem(
            "userName",
            data.data.result[0].userTitle + data.data.result[0].userName
          );

          window.sessionStorage.setItem(
            "userEmail",
            data.data.result[0].emailId
          );
          window.sessionStorage.setItem("homeHeader", "headerIconActive");

          window.sessionStorage.setItem("calenderHeader", "active");

          window.location.href = "Dashboard/dashboardView";
        }
      }
    });
  };

  const forgotPasswordHandler = () => {
    setForgotPassErrors("");
    setLogin("none");
    setforgotPassword("block");
    setTimeout(() => {
      document.getElementsByName("forgotUserName")[0].focus();
    }, 700);
    setTimeout(() => {
      document.getElementsByName("userName")[0].focus();
    }, 700);
  };
  const forgotPasswordHandlerRevert = () => {
    setForgotPassErrors("");
    setLogin("block");
    setforgotPassword("none");
    setTimeout(() => {
      document.getElementsByName("forgotUserName")[0].focus();
    }, 700);
    setTimeout(() => {
      document.getElementsByName("userName")[0].focus();
    }, 700);
  };
  const submitForgotPassword = () => {
    document.getElementById("afterSubmit").style.display = "block";
    document.getElementById("submitted").style.display = "none";
    let flag = true;
    var number = /^[0-9]+$/;
    var phNum = /^\d{10}$/;
    var email = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/;
    if (!forgotUserName) {
      setForgotPassErrors("Email ID / Mobile Number required");
      document.getElementById("forgotUserName").focus();
      document.getElementById("submitted").style.display = "block";
      document.getElementById("afterSubmit").style.display = "none";
      flag = false;
    } else if (forgotUserName.match(number) && !forgotUserName.match(phNum)) {
      setForgotPassErrors("Please enter valid mobile number");
      document.getElementById("forgotUserName").focus();
      document.getElementById("submitted").style.display = "block";
      document.getElementById("afterSubmit").style.display = "none";
      flag = false;
    } else if (!forgotUserName.match(number) && !email.test(forgotUserName)) {
      setForgotPassErrors("Please enter valid Email ID/Mobile Number");
      document.getElementById("forgotUserName").focus();
      document.getElementById("submitted").style.display = "block";
      document.getElementById("afterSubmit").style.display = "none";
      flag = false;
    } else if (!forgotUserName) {
      setForgotPassErrors(
        "Password reset link has been sent to your registered Email Id and Mobile Number"
      );
      document.getElementById("submitted").focus();
      flag = false;
    }
    if (flag === true) {
      setForgotPassErrors();
      const apiJson = {
        siteId: window.sessionStorage.getItem("siteId"),
        userId: "",
        userName: forgotUserName,
        newPassword: "",
      };

      callingAPI("userLogin/forgotPassword", apiJson).then((data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: pmSiteId,
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired: "",
              ccEmailRequired: "",
            },
          };

          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                alert("error message");
              } else if (response.data.success === "1") {
                // setForgotPassErrors("Email Sent Seccessfully");
                window.sessionStorage.setItem(
                  "setForgotPassErrors",
                  "Password reset link has been sent to your registered Email Id and Mobile Number"
                );
                //  setForgotPassErrors("Password reset link has sent to your Email Id");
                // <Link to="/Dashboard/welcome"> </Link>
                //window.location.href = "/";
                setLogin("");
                setLogin("block");
                setforgotPassword("none");
                document.getElementById("userName").focus();
              }
            })
            .catch();
        } else {
          setForgotPassErrors(data.data.errorMessage);
          setforgotPassword("");
          setForgotUserName("");
          document.getElementById("forgotUserName").focus();
        }
      });
    }
  };
  return (
    <div>
      <div className="coverWraper innerCoverWraper loginOuter">
        <div className="contentWraper contentWraperScroll homeContentWraper">
          <div className="homeContentBanner" style={{ padding: "0px" }}>
            {isData === true && <LoginHeader />}
            {pulseTitle !== null &&
              pulseTitle !== "null" &&
              pulseTitle !== "" ? (
              <Helmet>
                <title>{pulseTitle}</title>
              </Helmet>
            ) : (
              <Helmet>
                <title>Welcome to Purple Pulse</title>
              </Helmet>
            )}
            {pmSiteId !== null && pmSiteId !== "null" && pmSiteId !== "" ? (
              <div className="loginSiteID">
                <div className="container">
                  {window.sessionStorage.getItem("isLoggedIn") === "true" ? (
                    <div class="col-md-11 successFullLogin">
                      <div>
                        <span>
                          You are logged in as{" "}
                          {window.sessionStorage.getItem("userName")}
                        </span>
                        {window.sessionStorage.getItem("role") ===
                          "ROLE_DOCTOR"(
                            <a href="Dashboard/dashboardView">Go to Home</a>
                          )}
                        {window.sessionStorage.getItem("role") ===
                          "ROLE_HOSPITALADMIN" && (
                            <a
                              // href="/hospital Admin/hospitalHome"
                              href="/Dashboard/dashboardView"
                            >
                              Go to Home</a>
                          )}
                      </div>
                    </div>
                  ) : (
                    <div id="loginDiv" className="loginForm">
                      <div id="loginSection" style={{ display: login }}>
                        <p
                          id="Errmessagebloc"
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            style={{
                              color:
                                window.sessionStorage.getItem(
                                  "setForgotPassErrors"
                                ) === ""
                                  ? "red"
                                  : "green",
                            }}
                          >
                            {window.sessionStorage.getItem(
                              "setForgotPassErrors"
                            ) === ""
                              ? loginErrors
                              : window.sessionStorage.getItem(
                                "setForgotPassErrors"
                              )}
                          </span>
                        </p>
                        <div className="loginList floating-label">
                          <input
                            type="text"
                            value={userName}
                            id="userName"
                            name="userName"
                            onKeyPress={handleKeypress}
                            autoComplete="off"
                            className="inputField floating-input"
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder=" "
                            tabIndex="1"
                            autoFocus="autofocus"
                          />
                          <label>Mobile Number / Email ID</label>
                        </div>
                        <div className="loginList floating-label mb-2">
                          <input
                            autoComplete="off"
                            type="password"
                            id="password"
                            onKeyPress={handleKeypress}
                            value={password}
                            name="password"
                            className="inputField floating-input"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder=" "
                            tabIndex="2"
                          />
                          <label>Password</label>
                        </div>
                        <div className="loginList txtRight mb-2">
                          <span className="forgotLink">
                            {" "}
                            <a
                              href="javascript:void(0);"
                              style={{ textDecoration: "none" }}
                              onClick={forgotPasswordHandler}
                              tabIndex="3"
                            >
                              Forgot password ?
                            </a>{" "}
                          </span>
                        </div>
                        <div className="loginList mb-1">
                          <a
                            className="loginButton mb-1"
                            onClick={() => setLoginHandler()}
                            tabIndex="4"
                          >
                            Login
                          </a>
                        </div>
                        {window.sessionStorage.getItem("siteBranchId") === "4" ?
                          <div className="loginList text-center mb-4">
                            <a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
                              <img src={PoweredBy} alt="Powered By PurpleHealth" />
                            </a>
                          </div>
                          : ""}
                      </div>
                      <div
                        id="loginSection"
                        className="loginForm"
                        style={{ display: forgotPassword }}
                      >
                        <p
                          id=""
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {forgotPassErrors}
                        </p>
                        <div className="loginList floating-label mb-2">
                          <input
                            autoComplete="off"
                            type="text"
                            value={forgotUserName}
                            id="forgotUserName"
                            name="forgotUserName"
                            onKeyPress={handleKeypressForgot}
                            autoFocus="autofocus"
                            className="inputField floating-input"
                            onChange={(e) => setForgotUserName(e.target.value)}
                            placeholder=" "
                          />
                          <label>Mobile Number / Email ID</label>
                        </div>
                        <div className="loginList txtRight mb-2">
                          <span className="forgotLink mb-2">
                            {" "}
                            <a
                              href="javascript:void(0);"
                              style={{ textDecoration: "none" }}
                              onClick={forgotPasswordHandlerRevert}
                            >
                              Login
                            </a>{" "}
                          </span>
                        </div>
                        <div className="loginList mb-2">
                          <a
                            className="loginButton mb-2"
                            onClick={submitForgotPassword}
                            id="submitted"
                            style={{ display: "block" }}
                          >
                            Submit
                          </a>

                          <div className="loginList mb-2">
                            <a
                              className="loginButton mb-2"
                              id="afterSubmit"
                              style={{ display: "none" }}
                            >
                              Please Wait...
                            </a>
                            {/* <div id="loginSection"
                        className="loginForm"
                        style={{ display: login }}
                      ></div>                         */}
                            <span className="loginButton commonTrialButton">
                              Try For Free<span>(14 Day Trial)</span>
                            </span>
                          </div>
                        </div>
                        {window.sessionStorage.getItem("siteBranchId") === "4" ?
                          <div className="loginList text-center mb-4">
                            <a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
                              <img src={PoweredBy} alt="Powered By PurpleHealth" />
                            </a>
                          </div>
                          : ""}
                      </div>
                    </div>
                  )}
                </div>
                <div dangerouslySetInnerHTML={{ __html: siteInnerHtml }}></div>
              </div>
            ) : (
              <div>
                {
                  isData == true && (
                    <div>
                      <div className="container">
                        <div className="contentHead pulseLoginHead">
                          Welcome to Purple Pulse
                          <span>GO DIGITAL TODAY!</span>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="pulseLeftImg">
                              <img src={pulseMain} alt="Pulse Medical" />
                            </div>
                          </div>
                          {window.sessionStorage.getItem("isLoggedIn") ===
                            "true" ? (
                            <div class="col-md-6 successFullLogin">
                              <div>
                                <span>
                                  You are logged in as{" "}
                                  {window.sessionStorage.getItem("userName")}
                                </span>
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_DOCTOR" && (
                                    <a href="Dashboard/dashboardView">Go to Home</a>
                                  )}
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_RECEPTION" && (
                                    <a href="Dashboard/dashboardView">Go to Home</a>
                                  )}
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_HOSPITALPHARMACY" && (
                                    <a href="Dashboard/dashboardView">Go to Home</a>
                                  )}
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_BILLINGADMIN" && (
                                    <a href="Dashboard/dashboardView">Go to Home</a>
                                  )}
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_NURSE" && (
                                    <a href="Dashboard/dashboardView">Go to Home</a>
                                  )}
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_HOSPITALLAB" && (
                                    <a href="Dashboard/dashboardView">Go to Home</a>
                                  )}
                                {window.sessionStorage.getItem("role") ===
                                  "ROLE_HOSPITALADMIN" && (
                                    <a
                                      // href="/hospital Admin/hospitalHome"
                                      href="/Dashboard/dashboardView"
                                    >
                                      Go to Home
                                    </a>
                                  )}
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-6">
                              <div
                                className="loginForm"
                                style={{ display: login }}
                              >
                                <p
                                  id="Errmessagebloc"
                                  style={{
                                    color: "red",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      color:
                                        window.sessionStorage.getItem(
                                          "setForgotPassErrors"
                                        ) === ""
                                          ? "red"
                                          : "green",
                                    }}
                                  >
                                    {window.sessionStorage.getItem(
                                      "setForgotPassErrors"
                                    ) === ""
                                      ? loginErrors
                                      : window.sessionStorage.getItem(
                                        "setForgotPassErrors"
                                      )}
                                  </span>
                                </p>
                                <div className="loginList floating-label">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    value={userName}
                                    id="userName"
                                    onKeyPress={handleKeypress}
                                    name="userName"
                                    className="inputField floating-input"
                                    onChange={(e) =>
                                      setUserName(e.target.value)
                                    }
                                    placeholder=" "
                                    tabIndex="1"
                                    autoFocus="autofocus"
                                  />
                                  <label>Mobile Number / Email ID</label>
                                </div>
                                <div className="loginList floating-label mb-2">
                                  <input
                                    autoComplete="off"
                                    type="password"
                                    id="password"
                                    onKeyPress={handleKeypress}
                                    value={password}
                                    name="password"
                                    className="inputField floating-input"
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                    placeholder=" "
                                    tabIndex="2"
                                  />
                                  <label>Password</label>
                                </div>
                                <div className="loginList txtRight mb-2">
                                  <span className="forgotLink">
                                    {" "}
                                    <a
                                      href="javascript:void(0);"
                                      style={{ textDecoration: "none" }}
                                      onClick={forgotPasswordHandler}
                                      tabIndex="3"
                                    >
                                      Forgot password ?
                                    </a>{" "}
                                  </span>
                                </div>
                                <div className="loginList mb-1">
                                  <a
                                    className="loginButton mb-1"
                                    onClick={() => setLoginHandler()}
                                    tabIndex="4"
                                  >
                                    Login
                                  </a>
                                </div>
                                {/* {window.sessionStorage.getItem("siteId") === "1" ? */}
                                <div className="loginList text-center mb-0">
                                  <a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={PoweredBy} alt="Powered By PurpleHealth" />
                                  </a>
                                </div>
                                {/* : ""} */}
                              </div>
                              <div
                                className="loginForm"
                                style={{ display: forgotPassword }}
                              >
                                <p
                                  id=""
                                  style={{
                                    color: "red",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {forgotPassErrors}
                                </p>
                                <div className="loginList floating-label mb-2">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    value={forgotUserName}
                                    id="forgotUserName"
                                    onKeyPress={handleKeypress}
                                    name="forgotUserName"
                                    autoFocus="autofocus"
                                    className="inputField floating-input"
                                    onChange={(e) =>
                                      setForgotUserName(e.target.value)
                                    }
                                    placeholder=" "
                                  />
                                  <label>Mobile Number / Email ID</label>
                                </div>
                                <div className="loginList txtRight mb-2">
                                  <span className="forgotLink">
                                    {" "}
                                    <a
                                      href="javascript:void(0);"
                                      style={{ textDecoration: "none" }}
                                      onClick={forgotPasswordHandlerRevert}
                                    >
                                      Login
                                    </a>{" "}
                                  </span>
                                </div>
                                {/* {window.sessionStorage.getItem("siteId") === "1" ?
                                  <div className="loginList text-center">
                                    <a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
                                      <img src={PoweredBy} alt="Powered By PurpleHealth" />
                                    </a>
                                  </div>
                                  : ""} */}
                                <div className="loginList mb-1">
                                  <a
                                    className="loginButton mb-1"
                                    onClick={submitForgotPassword}
                                    id="submitted"
                                  >
                                    Submit
                                  </a>

                                  <span className="loginButton commonTrialButton">
                                    Try For Free<span>(14 Day Trial)</span>
                                  </span>
                                </div>
                                {/* {window.sessionStorage.getItem("siteId") === "1" ? */}
                                <div className="loginList text-center mb-0">
                                  <a href="https://www.purplehealth.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={PoweredBy} alt="Powered By PurpleHealth" />
                                  </a>
                                </div>
                                {/* : ""} */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <MembersReceive />
                    </div>
                  )

                  // :
                  // <div>
                  //   <div className="container-fluid" style={{ padding: '0px' }}
                  //   >
                  //     <img src={healthBack} alt="Pulse Medical" style={{ width: '100%', opacity: '.3' }} />
                  //   </div>
                  // </div>
                }
              </div>
            )}
          </div>
        </div>
        {isData === true && <Footer />}
      </div>


      <div className="ploverlayy" id="PopupShowDoctor" style={{ display: "none" }}>
        <div className="plpopup plpopupList assoc_popupList">
          <div className="popupup_headtext">Choose Your Hospital</div>
          <div className="plcontent">
            {doctorPopUpArray.map((data, k) => (
              <div className="assc_popupdiv">
                <label htmlFor={"siteNameIdDoctor" + k} class="labl_association">
                  <input
                    value={data.hospitalId}
                    id={"siteNameIdDoctor" + k}
                    type="radio"
                    name="rdHospitalId"
                    // onClick={() => siteNameRadioBtnDoctor(data)}
                    className="input_radioassoc"
                  />
                  {data.hospitalName}
                </label>
              </div>
            ))}
          </div>
          <div className="popup_btnDiv">
            <span
              className="popupbtn_submit"
              onClick={() => siteNameRadioBtnOKDoc()}
            >
              SUBMIT
            </span>
          </div>
        </div>
      </div>

      <div className="ploverlayy" id="PopupShow" style={{ display: "none" }}>
        <div className="plpopup plpopupList assoc_popupList">
          <div className="popupup_headtext">Choose Your Association</div>
          <div className="plcontent">
            {popUpShowArray.map((data, k) => (
              <div className="assc_popupdiv">
                <label htmlFor={"siteNameId" + k} class="labl_association">
                  <input
                    id={"siteNameId" + k}
                    type="radio"
                    onChange={() => siteNameRadioBtn(data)}
                    className="input_radioassoc"
                  />
                  {data.siteName}
                </label>
              </div>
            ))}
          </div>
          <div className="popup_btnDiv">
            <span
              className="popupbtn_submit"
              onClick={() => siteNameRadioBtnOK()}
            >
              SUBMIT
            </span>
          </div>
        </div>
      </div>





    </div>
  );
}
export default Login;
