import React, { useEffect, useState } from 'react'; 
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import eyeTable from '../images/icons/lab/eyeTable.png';
import { callingAPI } from '../config';
import Loader from "../Common/loader";
import NoData from '../Common/noDataFound';
function OutsidePharmacyOrderDetails() {
	const csvDownload = 'Outside Pharmacy Order Details';
	const [pharmaData, setPharmaData] = useState([])
	const [doctorName, setDoctorName] = useState("")
	const [totalCount, setTotalCount] = useState("")
	const [search, setSearch] = useState('');
	const [pharmaText, setSearchPharma] = useState("")
	const [fromDate, setFromDate] = useState();
	const [toDate, setToDate] = useState();
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");
	const [activePage, setActivePage] = useState(1);
	const [pageOffset, setPageOffset] = useState(0);
	const [filterName, setFilterInput] = useState('');
	const [doctorData, setDoctordata] = useState([])
	const [doctorId, setDoctorId] = useState("")
	const [idSearch, setIdSearch] = useState("")
	const [active, setActive] = useState(false)
	const [isData, setIsData] = useState(0);
	const [pharmacyData, setPharmacyData] = useState([])
	const changeText = (event) => {
		setSearch(event.target.value);
		doctorDropDown()
	};
	const changePharmacyText = (event) => {
		setSearchPharma(event.target.value);
	};
	const pharmacyDropDown = () => {
		let PostData =
		{
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"keyWordPharmacy": document.getElementById('searchTextPharmacy').value
		}
		callingAPI('pharmacyList/pharmacyList', PostData).then((response) => {
			if (document.getElementById('searchTextPharmacy').value.length > 1) {
				if (response.data.success === '1') {
					setPharmacyData(response.data.result.pharmacyData)
					document.getElementById("pharmacyList").style.display = 'block';
					setIsData(1);
				}
				else {
					document.getElementById('pharmacyList').style.display = 'none';
					setPharmacyData([]);
					setIsData(1);
				}
			}
			else {
				setPharmacyData([]);
				document.getElementById('pharmacyList').style.display = 'none';
				setIsData(1);
			}
		});
	}
	const doctorDropDown = () => {
		let PostData = {
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"keyWordDoctor": document.getElementById('searchText').value
		}
		callingAPI('doctorsList/doctorsList', PostData).then((response) => {
			if (document.getElementById('searchText').value.length > 1) {
				if (response.data.success === '1') {
					setDoctordata(response.data.result.doctorsData);
					document.getElementById("prescriptionMedicineList").style.display = 'block';
					setIsData(1);
				}
				else {
					document.getElementById('prescriptionMedicineList').style.display = 'none';
					setDoctordata([]);
					setIsData(1);
				}
			} else {
				setDoctordata([]);
				document.getElementById('prescriptionMedicineList').style.display = 'none';
				setIsData(1);
			}
		});
	}

	//pagination
	const paginationView = (pageNumber) => {
		setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) {
			setFilterInput("");
		}
		//if (!booliansearchValue){setSearchValue("")}
		if (!boolianfromDate) { setFromDate("") }
		if (!booliantoDate) { setToDate("") }
		const pharmaParams =
		{
			"fromDate": boolianfromDate ? formatDate(fromDate) : '',
			"toDate": boolianfromDate ? formatDate(toDate) : '',
			"doctorName": document.getElementById('searchText').value,
			"pharmacyName": document.getElementById('searchTextPharmacy').value,
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"max": max,
			"offset": offset
		}
		callingAPI('pharmacyOrder/pharmacyOrder', pharmaParams)
			.then((response) => {
				if (response.data.success === '1') {
					setPharmaData(response.data.result.memberPlanData)
					setDoctorName(response.data.result.doctorName)
					setTotalCount(response.data.result.memberPlanCountData)
					setIsData(1);
				}
			})
			.catch();
	};
	const outsideExport = () => {
		const csvRow = [];
		var outsideList = [
			[
				'Patient ',
				' Pharmacy%20Name',
				' Doctor%20Name ',
				' Prescription%20Date ',
			],
		];
		var name = pharmaData;
		let pharmaParams =
		{
			"fromDate": "",
			"toDate": "",
			"doctorName": "",
			"pharmacyName": " ",
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"max": '',
			"offset": 0
		}
		callingAPI('pharmacyOrder/pharmacyOrder', pharmaParams)
			.then((response) => {
				if (response.data.success === '1') {
					for (var item = 0; item < response.data.result.memberPlanData.length; item++) {
						outsideList.push([
							response.data.result.memberPlanData[item].patientname.replace(/ /g, "%20"),
							response.data.result.memberPlanData[item].serviceprovidername.replace(/ /g, "%20"),
							response.data.result.memberPlanData[item].doctorname.replace(/ /g, "%20"),
							response.data.result.memberPlanData[item].createdDate.replace(/ /g, "%20").replace(/,/g, "%20"),
						]);
					}
					for (var i = 0; i < response.data.result.memberPlanData.length; ++i) {
						csvRow.push(outsideList[i].join('|'));
					}
					var csvString = csvRow.join('%0A');
					var create = document.createElement('a');
					create.href = 'data:attachment/csv,' + csvString;
					// create.target = '_Blank';
					create.download = csvDownload + '.csv';
					document.body.appendChild(create);
					create.click();
				}
			})
	}
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('-');
	}
	//Search
	const outSideSearch = () => {
		// setbooliansearchValue(searchValue);
		setboolianfilterName(search);
		setboolianfromDate(fromDate);
		setbooliantoDate(toDate)
		if (fromDate && toDate) {
			if (fromDate > toDate) {
				alert('End date must be greater than start date');
				return false;
			}
		}
		setActivePage(1);
		const pharmaParams =
		{
			"fromDate": fromDate ? formatDate(fromDate) : '',
			"toDate": toDate ? formatDate(toDate) : '',
			"doctorName": document.getElementById('searchText').value,
			"pharmacyName": document.getElementById('searchTextPharmacy').value,
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"max": 10,
			"offset": pageOffset
		}
		callingAPI('pharmacyOrder/pharmacyOrder', pharmaParams)
			.then((response) => {
				if (response.data.success === '1') {
					setActive(true)
					setPharmaData(response.data.result.memberPlanData)
					setDoctorName(response.data.result.doctorName)
					setTotalCount(response.data.result.memberPlanCountData)
					setIsData(1);
				}
				else {
					setIsData(1);
					setPharmaData([])
					setDoctorName("")
					setTotalCount("")

				}
			})
			.catch();
	}
	const edit = (doctorVisitId, orderId) => {
		window.location.href = "/pharmacyLogin/outsidePharmacyOrderDetailsView"
		window.sessionStorage.setItem('outsidedoctorVisitId', doctorVisitId);
		window.sessionStorage.setItem('outsideorderId', orderId);
	}
	const pharmaOrderList = () => {
		const pharmaParams =
		{
			"fromDate": "",
			"toDate": "",
			"doctorName": "",
			"pharmacyName": " ",
			"userId": window.sessionStorage.getItem("userId"),
			"roleName": window.sessionStorage.getItem("role"),
			"max": 10,
			"offset": pageOffset
		}
		callingAPI('pharmacyOrder/pharmacyOrder', pharmaParams)
			.then((response) => {
				if (response.data.success === '1') {
					setActive(true)
					setPharmaData(response.data.result.memberPlanData)
					setDoctorName(response.data.result.doctorName)
					setTotalCount(response.data.result.memberPlanCountData)
					setIsData(1);
				}
				else {
					setTotalCount(0)
					setIsData(1);
				}
			})
			.catch();
	}
	const setdoctorDropDown = (name, id) => {
		setSearch(name)
		setIdSearch(id)
		setDoctordata([]);
		document.getElementById('prescriptionMedicineList').style.display = 'none';
	}
	const setPharmacyDropDown = (name, id) => {
		setSearchPharma(name)
		setIdSearch(id)
		setPharmacyData([]);
		document.getElementById('pharmacyList').style.display = 'none';
	}
	const onBlurItemCategory = () => {
		document.getElementById('prescriptionMedicineList').style.display = 'none';
		document.getElementById('pharmacyList').style.display = 'none';
		setDoctordata([]);
		setPharmacyData([]);
	};
	useEffect(() => {
		pharmaOrderList()
		doctorDropDown()
		pharmacyDropDown()
	}, [], [])
	const FromDateClear = () => {
		setFromDate("")
	};
	const MyContainer = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
					</span>

				</div>
			</CalendarContainer>
		);
	};
	const ToDateClear = () => {
		setToDate("")
	};
	const MyContainerTo = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}
	return (
		<div className="dashboardCover pharmacyManufactureWrap">
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Outside Pharmacy Order Details</div>
				</div>
			</div>
			{totalCount > 10 ? (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						hideDisabled
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={paginationView}
					/>
				</div>
			) : ""}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch" onClick={onBlurItemCategory}>
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<input
									autoComplete='off'
									onChange={changeText}
									value={search}
									type="text"
									className="inputField"
									id="searchText"
									name="searchText" placeholder='Select Doctor'
									onKeyUp={() =>
										doctorDropDown(
											'',
											'itemName',
											'prescriptionMedicineList'
										)}
								/>
								<ul
									className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
									id="prescriptionMedicineList"
									style={{ display: 'none' }}
								>
									{doctorData.map((item, i) => (

										<li
											onClick={() => setdoctorDropDown(item.fullName, item.doctorId)}
										>
											{item.fullName}
										</li>
									))}
								</ul>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									autoComplete='off'
									onChange={changePharmacyText}
									value={pharmaText}
									type="text"
									className="inputField"
									id="searchTextPharmacy"
									name="searchText" placeholder='Select Pharmacy'
									onKeyUp={() =>
										pharmacyDropDown(
											'',
											'itemName',
											'pharmacyList'
										)}
								/>
								<ul
									className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
									id="pharmacyList"
									style={{ display: 'none' }}
								>
									{pharmacyData.map((item, i) => (

										<li
											onClick={() => setPharmacyDropDown(item.pharmacyName, item.serviceProviderId)}
										>
											{item.pharmacyName}
										</li>
									))}
								</ul>
							</div>

							<div className="col-md-2 dashboardSearchList">
								<div>
								<Datepicker
										required
										style={{ background: "transparent" }}
										
										autoComplete="off"
										className="Datepicker pa2 inputField"
										maxDate={new Date()}
										placeholderText="From Date"
										calendarClassName="rasta-stripes"
										selected={fromDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setFromDate(date)}
										id="fromdate" calendarContainer={MyContainer}
										onChangeRaw={handleDateChangeRaw}
										peekNextMonth
										showMonthDropdown
										showYearDropdown

										dropdownMode="select"
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="fromdate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<div>
								<Datepicker
										required
										autoComplete="off"
									
										style={{ background: "transparent" }}
										className="Datepicker pa2 inputField"
										selected={toDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setToDate(date)}
										placeholderText="To Date"
										calendarClassName="rasta-stripes"
										id="todate" calendarContainer={MyContainerTo}
										onChangeRaw={handleDateChangeRaw}
										peekNextMonth
										showMonthDropdown
										showYearDropdown

										dropdownMode="select"
									/>
									<label
										className="icon iconCalendar calendar plReportsCalendar"
										style={{ fontSize: '16px' }}
										htmlFor="todate"
									>
										<FontAwesomeIcon icon={faCalendarAlt} />
									</label>
								</div>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span onClick={outSideSearch} className="dashButtonBorder"  >
									Search
								</span>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<span onClick={outsideExport} className="dashButtonBorder" style={{ border: "1px solid gainsboro" }}  >
									Export
								</span>
							</div>

						</div>
						<div className='row dflexend'>
							<div className="col-md-4 dashboardSearchList">
								<div className="searchCount" style={{ margin: "0px" }}>
									Total Pharmacy order  : {totalCount}
								</div>
							</div>
						</div>
					</div>
					<div className="dashboardtable">
						{isData == 0 ? (<Loader />
						) : pharmaData.length > 0 ?
							<table>
								<thead>
									<tr>
										<th>Patient  	</th>
										<th>Pharmacy Name	 </th>
										<th>Doctor Name	</th>
										<th>Prescription Date	</th>
										<th>View </th>
									</tr>
								</thead>


								<div align="center">

								</div>



								<tbody>
									{pharmaData.map(data => (
										<tr>
											<td>
												{data.patientname}
											</td>
											<td>{data.serviceprovidername}</td>
											<td>{data.doctorname}</td>
											<td>{data.createdDate}</td>

											<td > <a onClick={(e) => edit(data.doctorvisitid,
												data.orderid)
											}><img src={eyeTable} style={{ width: "25px" }} /></a>  </td>
										</tr>
									))}


								</tbody>


							</table>
							: <NoData />}
					</div>
				</div>
				{totalCount > 10 ? (
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							hideDisabled
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={totalCount}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={paginationView}
						/>
					</div>
				) : ""}
			</div>
		</div>


		// 		{/* </div>
		// 	</div>
		// 	<Footer />
		// </div> */}
	);
}

export default OutsidePharmacyOrderDetails;
