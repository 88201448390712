import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Editicon from '../images/icons/edit-bg.png';
import Tick from '../images/icons/tick.png';
import Close from '../images/icons/close.png';
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
function HospitalPatientGroup() {
	const [activePage, setActivePage] = useState(1);
	const [getGroupSms, setGetGroupSms] = useState([]);
	const [totalCount, setTotalCount] = useState()
	const [ pageOffset, setPageOffset ] = useState(0);
	const PaginationOnChange = (pageNumber) => {
		setActivePage(pageNumber)
		var offset;
		var max;
		if (pageNumber === "1") {
			offset = "0";
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber)
		}
		else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber)
		}
		setPageOffset(offset)
		let PostData = {
		
			"hospitalId": localStorage.getItem("hospitalId"),
			"offset": offset,
			"max": max,

		}
		callingAPI('promotionalSms/getGroupSms', PostData
		).then((response) => {
			if (response.data.success === "1") {
				setGetGroupSms(response.data.result.hospitalPatientGroupData)
				setTotalCount(response.data.result.totalCount)

			}
		}
		)
			.catch()
	}
	async function HospitalPatientGroupListing() {
		let PostData = {
	
			"hospitalId": localStorage.getItem("hospitalId"),
			"offset": pageOffset,
			"max": process.env.REACT_APP_PAGINATION_COUNT,
		};
		callingAPI('promotionalSms/getGroupSms', PostData).then((response) => {
			if (response.data.success === '1') {
				setGetGroupSms(response.data.result.hospitalPatientGroupData)
				setTotalCount(response.data.result.totalCount)
			}
		});
	}
	useEffect(() => {
		HospitalPatientGroupListing()
	}, []);
	const ChangeEnableDisableFunctionForPatientGroupList = (hospitalPatientGroupId, status) => {
		var x = "";
		if (status === true) {
			x = window.confirm("Do you want to Disable this?")
		}
		else {
			x = window.confirm("Do you want to Enable this?")
		}
		if (x === true) {
			let PostData = {
				"isActive": status === true ? false : true,
				"hospitalPatientGroupId": hospitalPatientGroupId,

			};
			callingAPI('promotionalSms/activateAndDeactivateGroup', PostData).then((response) => {
				
				if (response.data.success == 1) {
					HospitalPatientGroupListing()
				}
			});
		}
	};
	return (
		<div className="dashboardCover">

			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle">Hospital Patient Group</div>
					<div className="dashboardTitle">Invoice count:{totalCount}</div>
				</div>
				<div className="col-md-2">
					<span className="dashButtonBorder">
						<a href="/communications/patientGroup" style={{ color: '#000', textDecoration: 'none' }}>
							Add Group
						</a>

					</span>
				</div>
			</div>
			<div className="dashboardContent">
				<div className="paginationSection">
					<Pagination
						hideNavigation
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
						onChange={PaginationOnChange}
					/>
				</div>
				<div className="reportWrap">
					<div className="dashboardtable">
						<table>
							<thead>
								<tr>
									<th>GROUP NAME</th>
									<th> DESCRIPTION </th>
									<th> </th>
								</tr>
							</thead>
							<tbody>
								{getGroupSms.map((item, i) => (
									<tr>

										<td>{item.hospitalPatientGroupName}</td>
										<td>{item.hospitalPatientGroupDescription}</td>
										<td>		<a>
											<Link to={{
												pathname: "/communications/patientGroup", state: {
													hospitalPatientGroupDescription: item.hospitalPatientGroupDescription,
													doctorId: item.doctorId,
													specializationId: item.specializationId,
													hospitalPatientGroupName: item.hospitalPatientGroupName,
													hospitalPatientGroupId: item.hospitalPatientGroupId,
													enableEdit: true
												}
											}}><img src={Editicon} /></Link>
										</a>
											{item.isActive ===
												1 ? (
												<a
													title="Click to Activate"
													onClick={(e) =>
														ChangeEnableDisableFunctionForPatientGroupList(

															item.hospitalPatientGroupId,
															true
														)}
												>
													<img src={Tick} />
												</a>
											) : (
												<a
													title="Click to Activate"
													onClick={(e) =>
														ChangeEnableDisableFunctionForPatientGroupList(
															item.hospitalPatientGroupId,
															false
														)}>

													<img src={Close} />
												</a>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>

					</div>
				</div>

				<div className="paginationSection">
					<Pagination
						hideNavigation
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
						onChange={PaginationOnChange}
					/>
				</div>
			</div>
		</div>
	);
}

export default HospitalPatientGroup;
