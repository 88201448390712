import React from 'react';

function NoPresc() {
	return (
		<div className="practiceManagementForm" style={{ border: '1px solid #dadada' }}>
			<div className="noDataFound">
				<p>No Prescriptions Found</p>
			</div>
		</div>
	);
}
export default NoPresc;
