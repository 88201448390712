import Header from "../layout/header";
import Footer from "../layout/footer";
import Leftmenu from "../Patients/leftmenu";
import Profiledetail from "../Patients/profiledetail";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from "../Common/loader";
import { callingAPI } from "../config";
import moment from "moment";
function LabOrderAdd() {
  const [labTestListing, setLabTestListing] = useState([]);
  const [testName, setTestName] = useState("");
  const [labOrderItems, setLabOrderItems] = useState([]);
  const [getdoctorVisitId, setdoctorVisitId] = useState("");
  const [status, setStatus] = useState([]);
  const [labData, setLabData] = useState([]);
  const [specimenType, setSpecimenType] = useState([]);
  const [typeOfTest, setTypeOfTest] = useState([]);
  const [freeArticleData1, setFreeArticleData1] = useState([]);
  const [getServiceProviderId, setServiceProviderId] = useState("");
  const [hidOrderID, setHidOrderIDEdit] = useState("");
  const [labOrderTestId, setLabOrderTestId] = useState("");
  const [serviceProviderId, setServiceProviderIdEdit] = useState("");
  const [hidTestStatus, setHidTestStatus] = useState("");
  const [getLabOrdercomments, setLabOrdercomments] = useState("");
  const [load, setLoad] = useState(false);
  const [getEdit, setEdit] = useState("");
  const [createInvoiceId, setCreateInvoiceId] = useState([]);
  const location = useLocation();
  const [labDataArray, setLabDataArray] = useState({
    procedureNameSave: "",
    cmbSpecimen1: "",
    cmbtypeofTest1: "",
    procedureAmount: "",
    addNote: "",
    txtAmount1: "",
    txtTest1: "",
    txtTest2: "",
    typeName: "",
    specimenName: "",
  });
  const autoHide = () => {
    document.getElementById("locationSearch1").style.display = "none";
  };
  useEffect(() => {
    if (typeof location.state !== "undefined") {
      const { data } = location.state;
      setEdit(true);
      setdoctorVisitId(data[0].docVisitId);
      setHidOrderIDEdit(data[0].orderId);
      setServiceProviderId(data[0].serviceProviderId);
      setHidTestStatus(data[0].testStatus);
      setLabOrderTestId(data[0].labOrderTestId);
      setLabOrdercomments(data[0].labOrdercomments);
      setLabOrderTestId(data[0].labOrderTestId);
      typeDatas();
      for (var i = 0; i < data.length; i++) {
        var arrayObj = {
          txtTest1: data[i].testName,
          txtTest2: data[i].labOrderTestId,
          labOrderTestId: data[i].labOrderTestId,
          cmbSpecimen1: data[i].specimenTypeId,
          typeName: data[i].testTypeId,
          txtAmount1: data[i].buy_price,
          statusId: data[i].invoiceStatus,
          addNote: data[i].comments === "null" ? "" : data[i].comments,
        };
        labOrderItems.push(arrayObj);
      }
      autoLabData();
      LabTestListing();
      closeTest();
    } else {
      setStatus();
      setEdit(false);
      autoLabData();
      LabTestListing();
      closeTest();
      typeDatas();
    }
  }, []);
  const autoLabData = () => {
    const apiJson = {
      keyWord: "",
    };
    callingAPI("adminReports/getLabDetails", apiJson).then((data) => {
      if (data.data.success === "1") {
        setLabData(data.data.autoLabData);
      }
      setLoad(true);
    });
  };
  const typeDatas = () => {
    const apiJson = {
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("adminReports/specimenTypeOftest", apiJson).then((data) => {
      if (data.data.success === "1") {
        setSpecimenType(data.data.result.specimenType);
        setTypeOfTest(data.data.result.typeOfTest);
      }
      setLoad(true);
    });
  };
  const orderAt = (mode, dropdownId) => {
    const apiJson = {
      keyWord: document.getElementById(mode).value,
      hospitalId: localStorage.getItem("hospitalId")
    };
    callingAPI("adminReports/getLabDetails", apiJson).then((data) => {
      if (data.data.success === "1") {
        document.getElementById(dropdownId).style.display = "block";
        setFreeArticleData1(data.data.autoLabData);
        document.addEventListener("mouseup", function (e) {
          var container = document.getElementById(dropdownId);
          if (container !== null) {
            if (!container.contains(e.target)) {
              container.style.display = "none";
            }
          }
        });
      }
    });
  };
  const setFreeArticleData = (
    ServiceProviderId,
    itemName,
    mode,
    dropdownId
  ) => {
    document.getElementById(mode).value = itemName;
    setFreeArticleData1([]);
    setServiceProviderId(ServiceProviderId);
  };
  const searchProcedure = (event) => {
    const { name, value } = event.target;
    setTestName(event.target.value);
    LabTestListing(event.target.value);
  };
  const ChangeTo = (e, i) => {
    const { name, value } = e.target;
    const values = [...labOrderItems];
    values[i] = { ...values[i], [name]: value };
    setLabOrderItems(values);
  };
  const addTest = () => {
    document.getElementById("addProNew").style.display = "block";
    document.getElementById("addPro").style.display = "none";
    document.getElementById("closePro").style.display = "block";
  };
  const closeTest = () => {
    document.getElementById("closePro").style.display = "none";
    document.getElementById("addPro").style.display = "block";
    document.getElementById("addProNew").style.display = "none";
  };
  const addNotes = (p, addNotesLink1, addNote) => {
    document.getElementById(addNote).style.display = "block";
    document.getElementById(addNotesLink1).style.display = "none";
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setLabDataArray((prevState) => ({ ...prevState, [name]: value }));
  };
  const labTestSave = () => {
    let errMsg = "";
    var flag = true;
    if (flag === true) {
      for (var i = 0; i < labTestListing.length; i++) {
        var item = labTestListing[i].Test;
        if (labDataArray.procedureNameSave === item) {
          alert("Lab Test Already Exist");
          flag = false;
          break;
        } else if (labDataArray.procedureNameSave === "") {
          alert("Test Name Required");
          flag = false;
          break;
        }
      }
    }
    if (flag === true) {
      setLoad(false);
      let valuesToSend = {};
      valuesToSend.userId = window.sessionStorage.getItem("userId");
      valuesToSend.testName = labDataArray.procedureNameSave;
      callingAPI("adminReports/saveTest", valuesToSend)
        .then((res) => {
          setLoad(true);
          alert("New Test Added Successfully");
          LabTestListing();
          labDataArray.procedureNameSave = "";
        })
        .catch();
    }
  };
  const addNewLabOrder = (labOrderObj, i) => {
    var arrayObj = {
      txtTest1: labOrderObj.Test,
      txtTest2: labOrderObj.TestId,
      cmbSpecimen1: "",
      cmbtypeofTest1: "",
      txtAmount1: labOrderObj.buy_price,
      addNote: "",
      statusId: "",
      labOrderTestId: "",
      TestId: labOrderObj.TestId,
    };
    setEdit(true);
    setLabOrderItems([...labOrderItems, arrayObj]);
  };
  const invoiceClick = () => {
    document.getElementById("treatmentCompleteTick").click();
  };
  const createInvoice = (id) => {
    var array1 = [];
    array1.push(id);
    if (array1[0].length > 0) {
      var array = array1[0].replace('"').split(",");
    } else {
      var array = array1[0];
    }
    window.sessionStorage.setItem("labOrderId", array);
    window.location.href = "/patients/patientInvoiceAdd";
  };
  const removeLabOrder = (p) => {
    document.getElementById("lblMessage").style.display = "inline-block";
    let removed = [...labOrderItems];
    if (removed.length === 0) {
      if (typeof location.state !== "undefined") {
        setEdit(true);
      } else {
        setEdit(false);
      }
    } else {
      setEdit(true);
    }
    const apiJson = {
      labOrderTestId: removed[p].labOrderTestId,
    };
    callingAPI("adminReports/deletelabOrderTest", apiJson).then((data) => {
      if (data.data.success === "1") {
        removed.splice(p, 1);
        setLabOrderItems(removed);
      } else {
        removed.splice(p, 1);
        setLabOrderItems(removed);
      }
    });
    removeMessage();
  };
  const removeMessage = () => {
    setTimeout(() => {
      document.getElementById("lblMessage").style.display = "none";
    }, 2000);
  };
  const LabOrderSave1 = () => {
    let errMsg = "";
    var serviceProviderTestDetailsArray = [];
    for (var i = 0; i < labOrderItems.length; i++) {
      var obj = {
        serviceProviderTestDetailsTestSpecimen: document.getElementById(
          "cmbSpecimen1" + i
        ).value,
        serviceProviderTestDetailsTypeofTest: document.getElementById(
          "typeName" + i
        ).value,
        doctorVisitLabOrderTestComments: document.getElementById("addNote" + i)
          .value,
        serviceProviderTestDetailsTestAmount: labOrderItems[i].txtAmount1,
        serviceProviderTestDetailsTestName: labOrderItems[i].txtTest2,
        hidlaborderTestId: labOrderItems[i].labOrderTestId,
        hidTestStatus: "",
      };
      serviceProviderTestDetailsArray.push(obj);
    }
    var flag = true;
    if (flag === true) {
      setLoad(false);
      const postData = {
        hospitalId: localStorage.getItem("hospitalId"),
        hidOrderID: hidOrderID.toString(),
        doctorVisitId: getdoctorVisitId.toString(),
        memberId: window.sessionStorage.getItem("memberId"),
        userId: window.sessionStorage.getItem("userId"),
        doctorId: window.sessionStorage.getItem("patientDoctorId"),
        serviceProviderId: serviceProviderId.toString(),
        hidlaborderTestId: labOrderTestId.toString(),
        serviceProviderTestDetailsTestCount: "1",
        doctorVisitLabOrderComments: getLabOrdercomments,
        serviceProviderTestDetails: serviceProviderTestDetailsArray,
      };
      callingAPI("adminReports/saveLabOrder", postData)
        .then((data) => {
          if (data.data.success === "1") {
            alert("Lab Test Saved Successfully");
            window.sessionStorage.setItem(
              "getlabDate",
              data.data.result.testDate
            );
            window.sessionStorage.setItem(
              "getdoctorVisitId",
              data.data.result.doctorVisitId
            );
            window.sessionStorage.setItem(
              "getorderId",
              data.data.result.orderId
            );
            window.sessionStorage.setItem(
              "singlePrintId",
              data.data.result.doctorVisitLabOrderTestId
            );
            window.open(
              `${process.env.PUBLIC_URL}/patients/labOrderPrint`,
              "_blank"
            );
            setLoad(true);
            window.location.href = "/patients/labOrder/1";
          } else {
            alert(data.data.errorMessage);
            setLoad(true);
          }
        })
        .catch();
    }
  };
  const saveAndSendOutside = (serviceProviderId) => {
    const postData = {
      siteId: window.sessionStorage.getItem("siteId"),
      serviceProviderId: getServiceProviderId,
    };
    callingAPI("commonUserFunctions/labOrderMedicalSendMail", postData).then(
      (data) => {
        if (data.data.success === "1") {
          const PostData = {
            functionName: "createAppointmentMail",
            siteId: "",
            jsonMailOptions: {
              to: data.data.result.jsonMailOptions.to,
              AdminEmailId: data.data.result.jsonMailOptions.AdminEmailId,
              SupportEmailId: data.data.result.jsonMailOptions.SupportEmailId,
              subject: data.data.result.jsonMailOptions.subject,
              html: data.data.result.jsonMailOptions.html,
              bccEmailRequired: "",
              ccEmailRequired: "",
            },
          };
          callingAPI("commonUserFunctions/createAppointmentMail", PostData)
            .then((response) => {
              if (response.data.success === "0") {
                alert("Something went wrong");
              } else if (response.data.success === "1") {
                window.location.href = "/patients/labOrder";
              }
            })
            .catch();
        }
      }
    );
  };
  const LabOrderSave = (e, type) => {
    let errMsg = "";
    var serviceProviderTestDetailsArray = [];
    for (var i = 0; i < labOrderItems.length; i++) {
      var obj = {
        serviceProviderTestDetailsTestSpecimen: document.getElementById(
          "cmbSpecimen1" + i
        ).value,
        serviceProviderTestDetailsTypeofTest: document.getElementById(
          "typeName" + i
        ).value,
        doctorVisitLabOrderTestComments: document.getElementById("addNote" + i)
          .value,
        serviceProviderTestDetailsTestAmount: labOrderItems[i].txtAmount1,
        serviceProviderTestDetailsTestName: labOrderItems[i].txtTest2,
        hidlaborderTestId: labOrderItems[i].labOrderTestId,
        hidTestStatus: "",
      };
      setLabOrderTestId(labOrderItems[i].txtTest2);
      serviceProviderTestDetailsArray.push(obj);
    }
    var flag = true;
    if (flag === true) {
      setLoad(false);
      if (
        document.getElementById("freeArticleData1").value === "" &&
        type === "Saveandsend"
      ) {
        alert("Select Lab");
        orderAt("freeArticleData1", "locationSearch1");
        setLoad(true);
        return false;
      }
      const postData = {
        hospitalId: localStorage.getItem("hospitalId"),
        hidOrderID: hidOrderID.toString(),
        txtAppoimenttDate: moment(new Date()).format("YYYY-MM-DD hh:mm A"),
        scheduledtimeSlotStartTime: Date.parse(
          moment(new Date()).format("YYYY-MM-DD hh:mm A")
        ),
        scheduledtimeSlotEndTime:
          Date.parse(moment(new Date()).format("YYYY-MM-DD hh:mm A")) +
          15 * 60 * 1000,
        doctorVisitId: getdoctorVisitId.toString(),
        memberId: window.sessionStorage.getItem("memberId"),
        userId: window.sessionStorage.getItem("userId"),
        doctorId: window.sessionStorage.getItem("patientDoctorId"),
        serviceProviderId: getServiceProviderId.toString(),
        hidlaborderTestId: labOrderTestId.toString(),
        serviceProviderTestDetailsTestCount: "1",
        doctorVisitLabOrderComments: getLabOrdercomments,
        serviceProviderTestDetails: serviceProviderTestDetailsArray,
      };
      callingAPI("adminReports/saveLabOrder", postData)
        .then((data) => {
          if (data.data.success === "1") {
            if (type === "Saveandsend") {
              alert(" Lab Test details saved and sent successfully ");
              saveAndSendOutside(data.data.serviceProviderId);
              window.location.href = "/patients/labOrder";
            } else if (type === "Save") {
              setLoad(true);
              alert("Lab Test Saved Successfully");
              window.location.href = "/patients/labOrder";
            } else if (type === "createinvoice") {
              alert("Lab Test Saved Successfully");
              createInvoice(data.data.result.doctorVisitLabOrderTestId);
            }
          } else {
            alert(data.data.errorMessage);
          }
        })
        .catch();
    }
  };
  const LabTestListing = (testName) => {
    const apiJson = {
      testName: testName === undefined ? "" : testName,
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("adminReports/searchLabTest", apiJson).then((data) => {
      if (data.data.success === "1") {
        setLabTestListing(data.data.labTests);
      }
    });
  };
  const cancel = (testName) => {
    window.location.href = "/patients/labOrder";
  };
  return (
    <div className="coverWraper">
      <Header />
      <div className="contentWraper contentWraperScroll patientPayment plInvoice labOrderAddWrap">
        <Leftmenu />
        <div className="rightSection">
          <Profiledetail />
          <div className="practiceManagementWrap">
            <div className="row practiceManagementRow">
              <div className="col-lg-8 col-md-12 practiceLeftSection">
                {load === false ? <Loader /> : ""}
                <div class="treatmentPlanWrap patienonsultInnerPgeLoadDiv_PTour">
                  <div id="divPatientContent" class="sideMenuContent">
                    <div class="treatmentPlanCover" id="treatmentPlanListDiv">
                      <div class="treatmentPlanHead">
                        <div class="treatmentSelect pl_treatmentSelect">
                          <span>LAB ORDER</span>
                          <label
                            id="lblMessage"
                            style={{
                              color: "red",
                              marginLeft: "50px",
                              display: "none",
                            }}
                          >
                            Removed lab test{" "}
                          </label>
                          <label />
                          <div
                            class="treatmentSelectBtns"
                            style={{ display: "flex" }}
                          >
                            <a
                              onClick={cancel}
                              href="javascript:void(0);"
                              class="clinicalCancel labOrderCancelBtn_PTour"
                              style={{ textDecoration: "none" }}
                            >
                              Cancel
                            </a>
                            {labOrderItems.length ? (
                              <React.Fragment>
                                <span
                                  class="laborderSaveOnly_PTour"
                                  id="saveBut"
                                  style={{
                                    padding: "0",
                                    float: "none",
                                    display:
                                      getEdit === true ? "block" : "none",
                                  }}
                                >
                                  <input
                                    type="submit"
                                    name="_action_savelabOrder"
                                    value="Save"
                                    id="mainSave"
                                    tabindex="10"
                                    class=" clinicalSave"
                                    style={{ display: "inline - block " }}
                                    onClick={(e) => {
                                      LabOrderSave(e, "Save");
                                    }}
                                  />
                                </span>
                                <span
                                  id="sendBut"
                                  class="laborderSaveSendOnly_PTour"
                                  style={{
                                    padding: "0",
                                    float: "none",
                                    display:
                                      getEdit === true ? "block" : "none",
                                  }}
                                >
                                  <input
                                    type="submit"
                                    name="_action_savelabOrder"
                                    value="Save and Send Outside "
                                    id="btnSaveandSend"
                                    tabindex="11"
                                    class=" clinicalSave"
                                    style={{ display: "inline - block" }}
                                    onClick={(e) => {
                                      LabOrderSave(e, "Saveandsend");
                                    }}
                                  />
                                </span>
                                <span
                                  id="printBut"
                                  class="laborderSavePrintOnly_PTour"
                                  style={{
                                    padding: "0",
                                    float: "none",
                                    display:
                                      getEdit === true ? "block" : "none",
                                  }}
                                >
                                  <input
                                    type="submit"
                                    name="_action_savelabOrderPrint"
                                    value="Save &amp; Print"
                                    id="btnSaveandPrint"
                                    tabindex="10"
                                    class=" clinicalSave"
                                    style={{ display: "inline - block" }}
                                    onClick={LabOrderSave1}
                                  />
                                </span>
                                <span
                                  className="treatmentPlanBtn pl_blue_btn  clinicalSave  "
                                  id="createinvoice"
                                  onClick={(e) => {
                                    LabOrderSave(e, "createinvoice");
                                  }}
                                  index="18"
                                  name="_action_savePrescription"
                                  style={{
                                    float: "none",
                                    display:
                                      getEdit === true ? "block" : "none",
                                  }}
                                >
                                  <Link
                                    id={"treatmentCompleteTick"}
                                    to={{
                                      pathname: "/patients/patientInvoiceAdd",
                                      state: {
                                        labOrderInvoiceIds: createInvoiceId,
                                        hospitalId:
                                          localStorage.getItem("hospitalId"),
                                        memberId:
                                          window.sessionStorage.getItem(
                                            "memberId"
                                          ),
                                        isFlag: "",
                                      },
                                    }}
                                  ></Link>
                                  Create Invoice
                                </span>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div class="labOrderContentCover" id="divAddLabOrder">
                        <div
                          class="treatmentWrap labOrderTestSavedEditProceDetails_PTour"
                          id="treatmentPlanSection"
                        >
                          <div>
                            <div class="treatmentsList precscriptionTrtmntList">
                              <div
                                class="practiceManagementForm"
                                style={{ padding: "0px 0px", margin: "0px" }}
                              >
                                {labOrderItems.length ? (
                                  <div
                                    id="mainTeStListingDiv"
                                    class="practiceManagementFormBlcok"
                                    style={{ margin: "0px", display: "block" }}
                                  >
                                    <div class="col-md-12 col-lg-12 form_registrationPatient">
                                      <div
                                        id="labTest"
                                        class="labOrderTestEditingProceDetails_PTour"
                                        style={{
                                          minHeight: "auto",
                                          maxHeight: "290px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        {labOrderItems.map((labOrderObj, p) => (
                                          <table
                                            id="innerEduDiv1"
                                            className={
                                              labOrderObj.statusId ===
                                              "Invoice Generated"
                                                ? "treatmentSectionDisable"
                                                : ""
                                            }
                                            style={{
                                              width: "100%",
                                              display: "block",
                                            }}
                                          >
                                            <div className="plrmv">
                                              <span
                                                class="treatmentContentClose1"
                                                title="Remove LabOrder"
                                                onClick={(e) =>
                                                  removeLabOrder(p)
                                                }
                                              >
                                                x
                                              </span>
                                            </div>
                                            <ul className="row">
                                              <li class="col-md-4 col-lg-3">
                                                <label>Test Name</label>
                                                <input
                                                  type="text"
                                                  id="txtTest1"
                                                  readonly="readonly"
                                                  name="txtTest1"
                                                  tabindex="2"
                                                  autocomplete="off"
                                                  autofocus=""
                                                  placeholder="Test Name"
                                                  maxlength="100"
                                                  value={labOrderObj.txtTest1}
                                                />
                                              </li>
                                              <li class="col-md-3 col-lg-3">
                                                <label>Specimen</label>
                                                <select
                                                  class="fulWidthSelect"
                                                  id={"cmbSpecimen1" + p}
                                                  name="cmbSpecimen1"
                                                  onChange={(e) =>
                                                    ChangeTo(e, p)
                                                  }
                                                  value={
                                                    labOrderObj.cmbSpecimen1
                                                  }
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {specimenType.map(
                                                    (data, p) => (
                                                      <option
                                                        value={
                                                          data.specimenTypeId
                                                        }
                                                      >
                                                        {data.specimenName}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </li>
                                              <li class="col-md-3 col-lg-3">
                                                <label>Type Of Test</label>
                                                <select
                                                  class="fulWidthSelect"
                                                  id={"typeName" + p}
                                                  name="typeName"
                                                  onChange={(e) =>
                                                    ChangeTo(e, p)
                                                  }
                                                  value={labOrderObj.typeName}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {typeOfTest.map((data, p) => (
                                                    <option value={data.typeId}>
                                                      {data.typeName}
                                                    </option>
                                                  ))}
                                                </select>
                                              </li>
                                              <li class="col-md-3 col-lg-3">
                                                <label>Amount</label>
                                                <input
                                                  type="text"
                                                  id="txtAmount1"
                                                  name="txtAmount1"
                                                  tabindex="2"
                                                  autocomplete="off"
                                                  readonly="readonly"
                                                  autofocus=""
                                                  placeholder="Amount"
                                                  maxlength="100"
                                                  value={
                                                    labOrderObj.txtAmount1 ===
                                                      "" ||
                                                    labOrderObj.txtAmount1 ===
                                                      null
                                                      ? "Amount"
                                                      : labOrderObj.txtAmount1 +
                                                        ".00"
                                                  }
                                                />
                                              </li>
                                              <li class="col-md-8 col-lg-8">
                                                <div class="treatmentNote">
                                                  <span
                                                    style={{
                                                      display:
                                                        labOrderObj.addNote !==
                                                        ""
                                                          ? "none"
                                                          : "block",
                                                    }}
                                                    id={"addNotesLink1" + p}
                                                    name={"addNotesLink1" + p}
                                                    onClick={() =>
                                                      addNotes(
                                                        p,
                                                        "addNotesLink1" + p,
                                                        "addNote" + p
                                                      )
                                                    }
                                                  >
                                                    + add notes
                                                  </span>
                                                  <input
                                                    style={{
                                                      display:
                                                        labOrderObj.addNote !==
                                                        ""
                                                          ? "block"
                                                          : "none",
                                                    }}
                                                    id={"addNote" + p}
                                                    name="addNote"
                                                    value={labOrderObj.addNote}
                                                    type="text"
                                                    onChange={(e) =>
                                                      ChangeTo(e, p)
                                                    }
                                                    placeholder="Add your notes"
                                                    autocomplete="off"
                                                    maxlength="254"
                                                  />
                                                </div>
                                              </li>
                                              <div class="clearfix" />
                                            </ul>
                                          </table>
                                        ))}
                                      </div>
                                      <div
                                        onClick={(e) => autoHide()}
                                        class="precsriptionStore"
                                        id="precsriptionStores"
                                      >
                                        <ul>
                                          <li class="col-md-12 col-lg-12 PaddingGridFix12 labOrderTestEditingOrderAtDetails_PTour">
                                            <label>Order At</label>
                                            <input
                                              type="text"
                                              id="freeArticleData1"
                                              name="cityName"
                                              tabindex="6"
                                              autocomplete="off"
                                              placeholder="Lab Name"
                                              maxlength="100"
                                              class="ui-autocomplete-input"
                                              onClick={() =>
                                                orderAt(
                                                  "freeArticleData1",
                                                  "locationSearch1"
                                                )
                                              }
                                              style={{ height: "34px" }}
                                              onKeyUp={() =>
                                                orderAt(
                                                  "freeArticleData1",
                                                  "locationSearch1"
                                                )
                                              }
                                            />
                                            <ul
                                              className="autoSearch1 autoSearch-p0"
                                              id="locationSearch1"
                                              style={{ display: "none" }}
                                            >
                                              {freeArticleData1.map(
                                                (freeArticleDataObj, i) => (
                                                  <li
                                                    onClick={() =>
                                                      setFreeArticleData(
                                                        freeArticleDataObj.ServiceProviderId,
                                                        freeArticleDataObj.Lab,
                                                        "freeArticleData1",
                                                        "locationSearch1"
                                                      )
                                                    }
                                                  >
                                                    {freeArticleDataObj.Lab}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </li>
                                          <li class="col-md-12 col-lg-12 PaddingGridFix12 labOrderTestEditingComments_PTour">
                                            <label>Comments</label>
                                            <textarea
                                              id="txtComments"
                                              name="txtComments"
                                              tabindex="14"
                                              value={getLabOrdercomments}
                                              onChange={(e) =>
                                                setLabOrdercomments(
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Comments"
                                              maxlength="5000"
                                              className="textareaField"
                                            />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    id="labTestingEmptyMsg"
                                    class="labOrderTestEditingProceDetails_PTour"
                                    style={{ display: "block" }}
                                  >
                                    <div
                                      id="innerEduDiv1"
                                      class="prescriptionTrtmntListItem"
                                    >
                                      <span class="treatmentCommonTxt1 labTestMessage">
                                        <span>
                                          {" "}
                                          Add Lab Test(s) from right, Multiple
                                          Lab Test(s) can be added
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 practiceRightSection">
                <div className="patientright pl_pro">
                  <div className="rightTagSection">
                    <div className="rightTagList">
                      <div className="rightTagTitle">
                        Lab Test
                        <div class="treatmentSideBtn">
                          <span
                            class="trtmntPlanBtn completedPlanProceAdd_PTour treatmntPlanProceAdd_PTour"
                            id="addPro"
                            onClick={addTest}
                          >
                            + Add
                          </span>
                          <span
                            style={{ display: "none" }}
                            class="trtmntPlanBtn"
                            title="Close"
                            id="closePro"
                            onClick={closeTest}
                          >
                            x
                          </span>
                        </div>
                        <div class="treatmentListNew" id="addProNew">
                          <div class="treatmentNewAdd completedPlanProFillForm_PTour treatmntPlanProFillForm_PTour">
                            <input
                              type="text"
                              placeholder="Name"
                              id="procedureNameSave"
                              name="procedureNameSave"
                              class="completedPlanProName_PTour treatmntPlanProName_PTour"
                              value={labDataArray.procedureNameSave}
                              onChange={onChange}
                            />
                            <div
                              class="saVeBtn completedPlanProSave_PTour treatmntPlanProSave_PTour"
                              id="savePro"
                              onClick={labTestSave}
                              style={{ fontweight: "normal" }}
                            >
                              Save
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tagListingDiv">
                        <input
                          type="text"
                          className="inputField"
                          placeholder="Search"
                          name="testName"
                          value={testName}
                          onChange={(e) => searchProcedure(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="plLabOrderRightSec labOrderAddRightScroll">
                    {labTestListing.length > 0 ? (
                      labTestListing.map((data, j) => (
                        <div className="treatmentNameList treatmentPlanList LabOrderAddLIst ">
                          <ul id="tabProceduresUL">
                            {data.Test !== "" ? (
                              <li onClick={(e) => addNewLabOrder(data, j)}>
                                <span class="elipsis" title="test">
                                  <b>{data.Test}</b>
                                </span>
                                <span class="treatmentNameListRight">
                                  {data.buy_price === "" ? (
                                    <span
                                      class="treatmentNameListAmt"
                                      style={{ margin: "0px" }}
                                    >
                                      NA
                                    </span>
                                  ) : (
                                    <span
                                      class="treatmentNameListAmt"
                                      style={{ margin: "0px" }}
                                    >
                                      &#8377; {data.buy_price}.00
                                    </span>
                                  )}
                                </span>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      ))
                    ) : (
                      <div className="treatmentNameList treatmentPlanList LabOrderAddLIst ">
                        <ul id="tabProceduresUL">
                          <li>
                            <span class="elipsis" title="test">
                              <b>No Test Found</b>
                            </span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default LabOrderAdd;
