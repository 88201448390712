import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import TodoListStatusView from './todoListStatusView';
import UpcomingAppoiments from "./upcomingAppoiments";


function TodayAppoinmentStatus() {
    const [dataFlag, setDataFlag] = useState(false);
    const [todayAppoinmentStatus, setTodayAppoinmentStatus] = useState([])
    const [role, setRole] = useState(window.sessionStorage.getItem("role"));
    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            doctorId: role === "ROLE_DOCTOR" ? window.sessionStorage.getItem('doctorId') : "",
            hospitalId: role === "ROLE_DOCTOR" ? "" : localStorage.getItem("hospitalId"),
        };
        callingAPI('calenderListing/todayAppointmentStatus', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setTodayAppoinmentStatus(response.data.result)
            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    const consultClick = (event) => {

        window.sessionStorage.setItem("memberId", event.memberId);
        window.sessionStorage.setItem("patientDoctorId", event.doctorId);
        window.sessionStorage.setItem("patientDoctorName", event.doctorName);
        localStorage.setItem("hospitalId", event.hospitalId);
        window.sessionStorage.setItem("doctorMedicineBranchId", event.doctorMedicineBranchId);
        window.location.href = "/patients/patientconsult";
    };
    return (
        <>
            <div className="todayappoimnt_wrapr">
                <div className="total_revnuheader">
                    <h2 className='total_revnuheadtxt'>Today's Appointments</h2>
                </div>
                <div className='todayAppScroll todayAppScroll-max'>

                    {todayAppoinmentStatus.length > 0 ?

                        <>

                            {todayAppoinmentStatus.map((data) => (

                                <div class="dash_AppmntRightBlock">
                                    <div class="todyTimeSlot-appo">{data.patientAppointmentDateTime.split(' ')[1]} {data.patientAppointmentDateTime.split(' ')[2]}</div>
                                    <div class="todyDetails-appomnt">
                                        <div class="patntname-apponmnt elipsis"  >
                                            <b>{data.secUserDisplayName}</b>
                                        </div>
                                        <div class="dctrname-apponmnt elipsis" style={{ textTransform: "capitalize" }}>{data.doctorName}</div>

                                        <div class="dctrname-apponmnt elipsis" style={{ textTransform: "capitalize" }}>

                                            {window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<p style={{ margin: "0px" }}>MR No : {data.memberMrNo},  {data.patientAppointmentStatus}  </p>)}
                                            {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<p style={{ margin: "0px" }}>PR No : {data.memberPrNo},  {data.patientAppointmentStatus} </p>)}

                                            {/* {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? 
                                                 data.memberMrNo 
                                            :   data.memberPrNo
                                             } */}
                                        </div>

                                    </div>
                                    {/* <div class="consult-btn-appomnt">
                                        <a className='consultpatient-btntxt'>Consult</a>
                                    </div> */}
                                    {((role == "ROLE_HOSPITALADMIN" ||
                                        role == "ROLE_NURSE" ||
                                        role == "ROLE_RECEPTION") &&
                                        data.patientAppointmentStatus == "ARRIVED") ||
                                        (role == "ROLE_DOCTOR" &&
                                            data.patientAppointmentStatus == "ARRIVED")
                                        ? (
                                            <div class="consult-btn-appomnt">
                                                <div class="consultpatient-btntxt">
                                                    <a onClick={() => consultClick(data)}>
                                                        Consult
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                        :
                                        < ></>

                                    }
                                </div>

                            ))}
                        </>
                        :
                        <div className='todayAppScroll nodataFnd'>
                            <p>No Today's Appointments Found </p>
                        </div>
                    }

                </div>

                {/* <TodoListStatusView/> */}
            </div>
            <div className="todayappoimnt_wrapr upcomingappoinmt_wraper">
                <UpcomingAppoiments />
            </div>
        </>
    )
}

export default TodayAppoinmentStatus