import React from 'react';
import Editicon from '../images/icons/edit-bg.png';
import Tick from '../images/icons/tick.png';
import Close from '../images/icons/close.png';
import { useEffect, useState } from 'react';
import { callingAPI, callingDeleteAPI } from '../config';
import Pagination from 'react-js-pagination';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
function PharmacySupplier() {
	const [supplierData, setSupplierData] = useState([]);
	const [selectedSupplier, setSelectedSupplier] = useState({});
	const [count, setCount] = useState(0);
	const [popupHeading, setPopupHeading] = useState('');
	const [activePage, setActivePage] = useState(window.sessionStorage.getItem('PharmacysupplierpageNumber'));
	const [pageOffset, setPageOffset] = useState(0);
	const [DataFlag, setDataFlag] = useState(false);
	const [ContactNumber, setContactNumber] = useState('');
	const [searchValue, setsearchValue] = useState("");
	const [filterName, setFilterInput] = useState("");
	const [booliansearchValue, setbooliansearchValue] = useState('');
	const [booliantxtInput, setbooliantxtInput] = useState('');
	const downloadName = 'SupplierData';
	const re = /^\d+$/;
	const procedureCostChange = (event) => {
		if ((event.target.value).match(re) || event.target.value === '') {
			setContactNumber(event.target.value);
		}
		else {
			alert('Only Numbers Accepted');
			return event.target.value = ContactNumber
		}
	};
	const getGridValues = () => {
		setActivePage(Number(window.sessionStorage.getItem('PharmacysupplierpageNumber')))
		callingAPI('supplier', {
			hospitalId: localStorage.getItem("hospitalId"),
			max: 10,
			offset: window.sessionStorage.getItem('SupplierListDownloadactivepagenew') == null ? 0 : window.sessionStorage.getItem('SupplierListDownloadactivepagenew'),
		}).then((res) => {
			if (res.data.success === "1") {
				setSupplierData(res.data.result.supplierList);
				setCount(res.data.result.totalCount);
				setDataFlag(true);
			}
			else {
				setDataFlag(true);
			}
		});
	};
	useEffect(() => {
		getGridValues();
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);
	const handleBeforeUnload = (e) => {
		e.preventDefault();
		window.sessionStorage.setItem('PharmacysupplierpageNumber', 1)
		window.sessionStorage.setItem('SupplierListDownloadactivepagenew', 0)
	};
	const updateSupplier = () => {
		window.sessionStorage.setItem('SupplierListDownloadactivepagenew', pageOffset)
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		if (document.getElementById('supplierName').value == '') {
			alert('Enter Supplier Name');
			document.getElementById('supplierName').focus();
			return;
		}
		if (ContactNumber === '') {
			alert('Enter a Contact Number')
			document.getElementById('manufacturerContactNumber').focus();
			return;
		}
		if (!ContactNumber.match(phNum)) {
			alert('Enter a Valid Contact Number');
			document.getElementById('manufacturerContactNumber').focus();
			return;
		}
		if (document.getElementById('supplierEmail').value !== '') {
			if (!document.getElementById('supplierEmail').value.match(email)) {
				alert('Enter a Valid Email ID');
				document.getElementById('supplierEmail').focus();
				return;
			}
		}
		var postdata = {
			supplierId: selectedSupplier.supplierId,
			supplierName: document.getElementById('supplierName').value,
			supplierAddress: document.getElementById('supplierAddress').value,
			supplierContactNo: ContactNumber,
			supplierContactPerson: document.getElementById('supplierContactPerson').value,
			supplierEmail: document.getElementById('supplierEmail').value,
			userId: window.sessionStorage.getItem('userId'),
			hospitalId: localStorage.getItem("hospitalId"),
			supplierGSTIN: document.getElementById('supplierGSTIN').value,
		};
		setDataFlag(false);
		callingAPI('supplier/supplierAddUpdate', postdata).then((res) => {
			if (res.data.success === '1') {
				setDataFlag(true);
				alert('Supplier Details Updated Successfully ');
				getGridValues();
				setSelectedSupplier({});
				setPopupHeading('');
				setContactNumber('');
			} else if (res.data.success === '0') {
				alert(res.data.successMessage);
				setDataFlag(true);
				setPopupHeading('');
				setContactNumber('');
			}
		});
	};
	const addNewSupplier = () => {
		window.sessionStorage.setItem('SupplierListDownloadactivepagenew', pageOffset)
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		if (document.getElementById('supplierName').value == '') {
			alert('Enter Supplier Name');
			document.getElementById('supplierName').focus();
			return;
		}
		if (ContactNumber === '') {
			alert('Enter a Contact Number')
			document.getElementById('manufacturerContactNumber').focus();
			return;

		}
		if (!ContactNumber.match(phNum)) {
			alert('Enter a Valid Contact Number');
			document.getElementById('manufacturerContactNumber').focus();
			return;
		}
		if (document.getElementById('supplierEmail').value !== '') {
			if (!document.getElementById('supplierEmail').value.match(email)) {
				alert('Enter a Valid Email ID');
				document.getElementById('supplierEmail').focus();
				return;
			}
		}
		var postdata = {
			supplierId: '',
			supplierName: document.getElementById('supplierName').value,
			supplierAddress: document.getElementById('supplierAddress').value.replace(/\s{1,}/g, ' '),
			supplierContactNo: ContactNumber,
			supplierContactPerson: document.getElementById('supplierContactPerson').value,
			supplierEmail: document.getElementById('supplierEmail').value,
			userId: window.sessionStorage.getItem('userId'),
			hospitalId: localStorage.getItem("hospitalId"),
			supplierGSTIN: document.getElementById('supplierGSTIN').value,
		};
		setDataFlag(false);
		callingAPI('supplier/supplierAddUpdate', postdata).then((response) => {
			if (response.data.success === '1') {
				setDataFlag(true);
				alert('Supplier Details Saved Successfully ');
				setSelectedSupplier({});
				setPopupHeading('');
				setContactNumber('');
				setActivePage(1);
				setPageOffset(0)
				window.sessionStorage.setItem('SupplierListDownloadactivepagenew', 0)
				window.sessionStorage.setItem('PharmacysupplierpageNumber', 1)
				getGridValues();
			} else if (response.data.success === '0') {
				setDataFlag(true);
				alert(response.data.successMessage);
				setPopupHeading('');
				setContactNumber('');
			}
		});
	};
	const changeText = (value) => {
		setFilterInput(value);
		setsearchValue("");
		if (value === "") {
			setsearchValue("");
			document.getElementById("TextToSearch").disabled = true;
		} else {
			document.getElementById("TextToSearch").disabled = false;
			document.getElementById("TextToSearch").focus();
		}
	};
	const searchSupplier = () => {
		if (filterName !== "" && searchValue === "") {
			var t = document.getElementById("SearchingOption");
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById("TextToSearch").focus();
			alert('Enter' + " " + selectedText);
			return false;
		}
		setbooliansearchValue(filterName);
		setbooliantxtInput(searchValue);
		var postdata = {
			cmbSelect: filterName,
			txtInput: searchValue,
			hospitalId: localStorage.getItem("hospitalId"),
			max: 10,
			offset: 0,
		};
		setActivePage(1);
		callingAPI('supplier', postdata).then((res) => {
			if (res.data.success === '1') {
				setSupplierData(res.data.result.supplierList);
				setCount(res.data.result.totalCount);
				setSelectedSupplier({});
			} else if (res.data.success === '0') {
				setSupplierData('');
				setCount(res.data.totalCount);
			}
		});
	};
	const paginationHandling = (pageNumber) => {
		window.sessionStorage.setItem('PharmacysupplierpageNumber', pageNumber)
		setActivePage(pageNumber);
		var offset = 0;
		var max = 0;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
			setActivePage(pageNumber);
			setPageOffset(offset);
		}
		if (!booliantxtInput) { setFilterInput("") }
		if (!booliansearchValue) { setsearchValue("") }
		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			cmbSelect: booliansearchValue,
			txtInput: booliantxtInput,
		};
		setDataFlag(false);
		callingAPI('supplier', postData).then((response) => {
			if (response.data.success === '1') {
				setSupplierData(response.data.result.supplierList);
				setCount(response.data.result.totalCount);
				setDataFlag(true);
			} else if (response.data.success === '0') {
				setSupplierData('');
				setCount(response.data.totalCount);
				setDataFlag(true);
			}
		});
	};
	const deleteSupllier = (supplier) => {
		window.sessionStorage.setItem('SupplierListDownloadactivepagenew', pageOffset)
		var x = false;
		if (supplier.isActive == true) {
			x = window.confirm('Do you want to delete this?');
		} else {
			x = false;
		}
		if (x === true) {
			var postdata = {
				supplierId: supplier.supplierId,
				isActive: !supplier.isActive,
				userId: window.sessionStorage.getItem('userId'),
			};
			setDataFlag(false);
			callingAPI('supplier/supplierActiveDeactive', postdata).then((res) => {
				if (res.data.success === '1') {
					alert(' Successfully Deleted');
					getGridValues();
					setSelectedSupplier({});
					setDataFlag(true);
				}
			});
		}
	};
	const filterByPatient = (value) => {
		if (filterName == "2" && value !== "") {

			if (/[^0-9.]/.test(value)) {
				alert('Only Numbers Accepted');
				return;

			}
			if (value.length > 10) {
				alert('Enter a Valid Contact Number');
				return;
			}

		}
		setsearchValue(value);
	};
	const closepopup = () => {
		setPopupHeading('');
		setContactNumber('');
	};
	const handleKeypress = (event) => {
		if (event.key === 'Enter') {
			searchSupplier();
		}
	};
	return (
		<div className="dashboardCover pharmacyManufactureWrap pharmacyManuf ">
			<div className="row">
				<div className="col-md-10">
					<div className="dashboardTitle"> Supplier</div>
				</div>
				<div className="col-md-2">
					<a
						href="#plpopup1"
						style={{ textDecoration: 'none' }}
						onClick={() => {
							setSelectedSupplier({});
							setPopupHeading('Add Supplier');
						}}
					>
						<span
							className="dashButtonBorder"
							style={{ background: '#22a2fd', color: '#fff', border: 'none' }}
						>
							Add +
						</span>
					</a>
				</div>
			</div>
			<div className="dashboardContent">
				{DataFlag == false ? (
					<div align="center">
						<Loader />
					</div>
				) : (
					<div className="reportWrap">
						{count > 10 && (
							<Pagination
								//hideNavigation
								hideDisabled
								activePage={activePage}
								itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
								totalItemsCount={count}
								pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
								onChange={paginationHandling}
							/>
						)}
						<div className="dashboardSearch">
							<div className="row">
								<div className="col-md-2 dashboardSearchList">
									<select className="inputField" value={filterName}
										onChange={(e) => changeText(e.target.value)} id="SearchingOption" onKeyPress={handleKeypress}>
										<option value="">Search In</option>
										<option value="1">Supplier Name</option>
										<option value="2">Mobile Number</option>
										<option value="3">Email</option>
									</select>
								</div>
								<div className="col-md-2 dashboardSearchList">
									{filterName == "" ? (
										<input type="text" value={searchValue}
											onChange={(e) => filterByPatient(e.target.value)} className="inputField" id="TextToSearch" style={{ background: "transparent" }} disabled />
									) : (
										<input type="text" value={searchValue}
											onChange={(e) => filterByPatient(e.target.value)} onKeyPress={handleKeypress} className="inputField" autofocus="autofocus" style={{ background: "white" }} id="TextToSearch" disabled />
									)}</div>
								<div className="col-md-2 dashboardSearchList" onKeyPress={handleKeypress} onClick={searchSupplier}>
									<span className="dashButtonBorder">Search</span>
								</div>
								<div className="col-md-6 dashboardSearchList">
									<div className="searchCount">
										<b>Total : {count}</b>
									</div>
								</div>
							</div>
						</div>
						{supplierData.length > 0 &&
							DataFlag == true && (
								<div className="dashboardtable">
									<table>
										<thead>
											<tr>
												<th>Name</th>
												<th> Address </th>
												<th>Email</th>
												<th>Contact Number</th>
												<th>Contact Person</th>
												<th style={{ width: "101px" }}> Created On</th>
												<th style={{ width: "120px" }}>GSTIN</th>
												<th style={{ width: "100px" }}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{supplierData.map((supplier) => (
												<tr>
													<td>{supplier.supplierName}</td>
													<td>{supplier.supplierAddress}</td>
													<td>{supplier.supplierEmail}</td>
													<td>{supplier.supplierContactNo}</td>
													<td>{supplier.supplierContactPerson}</td>
													<td>{supplier.createdOn}</td>
													<td>{supplier.supplierGSTIN}</td>
													<td>
														<a
															href="#plpopup1"
															onClick={() => {
																setSelectedSupplier(supplier);
																setPopupHeading('Edit Supplier');
																setContactNumber(supplier.supplierContactNo)
															}}
														>
															<img src={Editicon} title='Edit' />
														</a>
														<a>
															{supplier.isActive === true ? (
																<img
																	src={Close} title='Delete'
																	onClick={() => {
																		setSelectedSupplier(supplier);
																		deleteSupllier(supplier);
																	}}
																/>
															) : (
																<img
																	src={Tick}
																	onClick={() => {
																		setSelectedSupplier(supplier);
																		deleteSupllier(supplier);
																	}}
																/>
															)}
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{count > 10 && (
										<Pagination
											//hideNavigation
											hideDisabled
											activePage={activePage}
											itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
											totalItemsCount={count}
											pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
											onChange={paginationHandling}
										/>
									)}
								</div>
							)}
						{supplierData.length == 0 && DataFlag == true && <NoData />}
						{popupHeading !== '' && (
							<div id="plpopup1" class="ploverlay">
								<div class="plpopup">
									<h2>{popupHeading}</h2>
									<a id="btnCancel" title="Close" class="close"
										onClick={() => {
											closepopup()
										}}>
										<span class="popUpClose" style={{ right: '25px' }}>
											x
										</span>
									</a>
									<div class="plcontent">
										<div class="addDataContent">
											<div class="addDataDiv">
												<label>
													Name<span class="mandatory">*</span>
												</label>
												<input
													type="text"
													id="supplierName"
													autoComplete="off"
													defaultValue={selectedSupplier.supplierName}
													placeholder="Enter Name"
												/>
											</div>
											<div class="addDataDiv">
												<label>Address</label>
												<textarea
													id="supplierAddress"
													defaultValue={selectedSupplier.supplierAddress}
													placeholder="Enter Address"
													style={{ height: '85px', resize: 'none' }}
													autoComplete="off"
												/>
											</div>
											<div class="addDataDiv">
												<label>Email</label>
												<input
													id="supplierEmail"
													defaultValue={selectedSupplier.supplierEmail}
													type="text"
													placeholder="Enter Email"
													autoComplete="off"
												/>
											</div>
											<div class="addDataDiv">
												<label>Contact Number<span class="mandatory">*</span></label>
												<input
													maxLength="10"
													class=""
													onChange={procedureCostChange}
													id="manufacturerContactNumber"
													Value={ContactNumber}
													style={{ boxShadow: 'none' }}
													autoComplete="off"
													type="text"
													placeholder="Enter Phone Number"
												/>
											</div>
											<div class="addDataDiv">
												<label>Contact Person</label>
												<input
													class=""
													id="supplierContactPerson"
													defaultValue={selectedSupplier.supplierContactPerson}
													style={{ boxShadow: 'none' }}
													type="text"
													placeholder="Enter contact Person"
													autoComplete="off"
												/>
											</div>
											<div class="addDataDiv">
												<label>GSTIN</label>
												<input
													id="supplierGSTIN"
													class=""
													defaultValue={selectedSupplier.supplierGSTIN}
													style={{ boxShadow: 'none' }}
													autoComplete="off"
													type="text"
													placeholder=" Gstin"
												/>
											</div>
											<div class="summaryBtnDiv">
												{popupHeading === 'Add Supplier' && (
													<input
														type="submit"
														value="Save"
														onClick={() => {
															addNewSupplier()
														}}
														class="practiceGreenButton"
													/>
												)}
												{popupHeading === 'Edit Supplier' && (
													<input
														type="submit"
														value="Update"
														onClick={() => {
															updateSupplier()
														}}
														class="practiceGreenButton"
													/>
												)}
												<a class="close" href="#">
													<input
														type="submit"
														value="Cancel"
														onClick={closepopup}
														class=" practiceGreyButton"
														style={{ fontFamily: 'Montserrat-SemiBold' }}
													/>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
export default PharmacySupplier;
