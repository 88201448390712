import React from 'react';
import Logo from '../images/media/logo.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config'
import HeaderPrint from '../Settings/headerPrint';
import Loader from '../Common/loader';
import FooterPrint from '../Settings/footerPrint';
function PatientInvoicePrint() {
	let { printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [isData, setIsData] = useState(false);
	const [pagesizeid, setpagesizeid] = useState('');
	const [memberDetails, setMemberDetails] = useState({});
	const [hospitalDetails, setHospitalDetails] = useState({});
	const [invoiceDate, setInvoiceDate] = useState("");
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [invoiceDetails, setInvoiceDetails] = useState([]);
	const [balanceAmount, setBalanceAmount] = useState("");
	const [doctorDetails, setDoctorDetails] = useState([])
	const [estimationAmount, setEstimationAmount] = useState("");
	const [discountAmt, setDiscountAmt] = useState("");
	const [invoiceType, setInvoiceType] = useState("");
	const [paidAmount, setPaidAmount] = useState("")
	const [hosId, setHosId] = useState("")


	function styleValues() {
		var hos1 = printId.toString().split('|')[2]
		const PostData = {
			hospitalId: hos1 ? hos1 : hosId,
			doctorId: ""
		};
		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {
				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);
				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);
			});

	}
	const onPrintButtonClick = () => {
		// document.getElementById('cancel').style.display = 'none';
		document.getElementById('print').style.display = 'none';
		window.print();
		// document.getElementById('cancel').style.display = 'inline-block';
		document.getElementById('print').style.display = 'inline-block';
	};
	useEffect(() => {
		let memberProcedureInvoiceId = printId.toString().split('|')[0]
		let userId = printId.toString().split('|')[1]
		var hos = printId.toString().split('|')[2]
		setHosId(hos)
		callingAPI('commonUserFunctions/printTreatmentPlanInvoice', {
			"userId": userId,
			"invoiceId": memberProcedureInvoiceId
		})
			.then((res) => {
				if (res.data.success === '1') {
					//alert(res.data.result[0].hospitalDetails[0].hospitalAddress);
					setMemberDetails(res.data.result[0].memberDetails[0]);
					setHospitalDetails(res.data.result[0].hospitalDetails[0]);
					setInvoiceDate(res.data.result[0].final_array[0].invoiceDate);
					setInvoiceNumber(res.data.result[0].final_array[0].main[0].invoiceList.invoiceNumber);
					setInvoiceDetails(res.data.result[0].final_array[0].main[0].item)
					setBalanceAmount(res.data.result[0].final_array[0].main[0].invoiceList.balanceAmount);
					setEstimationAmount(res.data.result[0].final_array[0].main[0].invoiceList.totalAmount)
					setInvoiceType(res.data.result[0].final_array[0].main[0].item[0].itemType)
					setDoctorDetails(res.data.result[0].doctorDetails)
					setDiscountAmt(res.data.result[0].final_array[0].main[0].invoiceList.discountAmount)
					setPaidAmount(res.data.result[0].final_array[0].main[0].invoiceList.paidAmount)
					setIsData(true);
					//alert(balanceAmount + "  "+ estimationAmount)
				}

			})
		styleValues()
	}, []);
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div align="center">
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '0px',
					marginBottom: '0px',
				}}
			>
				{isData === false ? <Loader /> : ''}
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">
					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>
											<HeaderPrint hospitalDataId={hosId} />

											<div class="printBtm" style={{ width: '595px', margin: 'auto' }}>
												<p
													onClick={onPrintButtonClick}
													id="print"
													style={{
														width: '70px',
														textAlign: 'center',
														paddingTop: '5px',
														lineHeight: '18px',
														display: 'inline-block',
														fontWeight: 'bold',
														fontSize: '14px',
														color: 'white',
														background: 'rebeccapurple none repeat scroll 0% 0%',
														marginTop: '2px',
														cursor: 'pointer',
														borderRadius: '5px',
														marginBottom: '2px',
														height: '25px',
														position: 'absolute',
														right: '-27px',
														fontFamily: '  Verdana',
														top: '110px'
													}}
												>
													Print
												</p>&nbsp;
											</div>

											<tr>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Name : {memberDetails.memberFirstName + " " + memberDetails.memberLastName}
												</td>
												<td
													colspan="6"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Date : {invoiceDate}{' '}
												</td>
											</tr>

											<tr>
												<td
													colspan="3"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Age : {memberDetails.memberAge}
												</td>
												<td
													colspan="9"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>
													Email Id :{memberDetails.secUserEmailid}{' '}
												</td>
											</tr>
											<tr>
												<td
													colspan="3"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'left',
													}}
												>
													Sex : {memberDetails.memberGender}
												</td>
												<td
													colspan="9"
													style={{
														width: '50%',
														fontSize: '12px',
														padding: '0',
														lineHeight: '17px',
														textAlign: 'right',
													}}
												>Mobile No : {memberDetails.SecuserMobileNumber}</td>
											</tr>
											<tr>
												{window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (
													<td
														colspan="6"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														MR No : {memberDetails.memberMrNo}
													</td>) :


													(!memberDetails.memberPrNo == "" &&
														<td
															colspan="6"
															style={{
																width: '50%',
																fontSize: '12px',
																padding: '0',
																lineHeight: '17px',
																textAlign: 'left',
															}}
														>
															PR No : {memberDetails.memberPrNo}
														</td>
													)
												}
											</tr>

											<tr>
												<td colspan="12">
													<hr color="#142536" style={{ margin: ' 0', height: '2px', opacity: '1' }} />
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr style={{ borderBottom: '2px solid black' }}>
												<td style={{ fontSize: '14px', paddingBottom: '10px' }}>Invoice</td>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'right',
													}}
												>
													{' '}
													Invoice Number : {invoiceNumber}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0" style={{ borderBottom: '1px solid #777' }}>
										<tbody>
											<tr style={{ fontSize: '13px', background: '#eae8e8' }}>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Towards
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '7%',
														display: 'inline-block',
														textAlign: 'left',
														fontWeight: '500',
													}}
												>
													Qty
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Unit Price
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '25%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Cost
												</td>

												<td
													style={{
														boxSizing: 'border-box',
														padding: ' 5px 2px',
														width: '14%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Tax
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '15%',
														display: 'inline-block',
														textAlign: 'right',
														fontWeight: '500',
													}}
												>
													Total
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						{invoiceDetails.map((itemObj, i) => (
							<div>
								<tr>
									<td>
										<div class="printOutSettingContent">
											<table width="100%" cellspacing="0">
												<tbody>
													<tr>
														<td
															colspan="6"
															style={{
																padding: '5px',
																fontSize: '12px',
																letterSpacing: '1px',
																background: '#f3f3f3',
															}}
														>
															{itemObj.itemType}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</tr>

								<tr>
									<td>
										<div class="printOutSettingContent">
											<table width="100%" cellspacing="0">

												<tbody>

													{itemObj.item.map((obj, j) => (
														<tr>

															<td
																style={{
																	boxSizing: ' border-box',
																	padding: '5px 2px',
																	width: '25%',
																	display: 'inline-block',
																	textAlign: 'left',
																	fontSize: '12px',
																	color: '#393939',
																	lineHeight: '18px',
																}}
															>
																{obj.itemName}
																{itemObj.itemType === "Procedure" && "(" + (obj.itemCode) + ")"}
															</td>
															<td
																style={{
																	boxSizing: 'border-box',
																	padding: '5px 2px',
																	width: '7%',
																	display: 'inline-block',
																	textAlign: 'left',
																	fontSize: '12px',
																	color: '#393939',
																	lineHeight: '18px',
																}}
															>
																{obj.quantity}
															</td>
															<td
																style={{
																	boxSizing: 'border-box',
																	padding: '5px 2px',
																	width: '14%',
																	display: 'inline-block',
																	textAlign: 'right',
																	fontSize: '12px',
																	color: '#393939',
																	lineHeight: '18px',
																}}
															>
																{obj.unitPrice}
															</td>
															<td
																style={{
																	boxSizing: 'border-box',
																	padding: '5px 2px',
																	width: '25%',
																	display: 'inline-block',
																	textAlign: 'right',
																	fontSize: '12px',
																	color: '#393939',
																	lineHeight: '18px',
																}}
															>
																{obj.cost}
															</td>

															<td
																style={{
																	boxSizing: 'border-box',
																	padding: '5px 2px',
																	width: '14%',
																	display: 'inline-block',
																	textAlign: 'right',
																	fontSize: '12px',
																	color: '#393939',
																	lineHeight: '18px',
																}}
															>
																{obj.taxAmount}
															</td>
															<td
																style={{
																	boxSizing: 'border-box',
																	padding: '5px 2px',
																	width: '15%',
																	display: 'inline-block',
																	textAlign: 'right',
																	fontSize: '12px',
																	color: '#393939',
																	lineHeight: '18px',
																}}
															>
																{obj.totalAmount}
															</td>
														</tr>
													))}
												</tbody>


											</table>
										</div>
									</td>
								</tr>
							</div>
						))}

						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr class="dentalProPlaned">
												<td>
													<div
														class="taxValueTable"
														style={{
															width: '42%',
															marginTop: '-6px',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
															color: '#393939',
														}}
													/>

													<div
														style={{
															width: '56%',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
														}}
													>
														<div
															class="estimatedPrice txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																paddingTop: '15px',
																color: ' #4a4a4a',
															}}
														>
															Estimated amount (INR) :&nbsp;
															<span
																style={{
																	width: '75px',
																	display: 'inline-block',
																	verticalAlign: 'top',
																}}
															>
																{estimationAmount}
															</span>
														</div>
														{/* <div
															class="estimatedPrice txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																paddingTop: '15px',
																color: ' #4a4a4a',
															}}
														>
															Estimated amount (INR) :&nbsp;
															<span
																style={{
																	width: '75px',
																	display: 'inline-block',
																	verticalAlign: 'top',
																}}
															>
																{estimationAmount}
															</span>
														</div> */}
														{paidAmount > 0 &&
															<div
																class="estimatedPrice txtright"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '15px',
																	color: ' #4a4a4a',
																}}
															>
																Paid amount  (INR) :&nbsp;
																<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{paidAmount}
																</span>
															</div>
														}
														{discountAmt > 0 &&
															<div
																class="estimatedPrice txtright"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '15px',
																	color: ' #4a4a4a',
																}}
															>
																Total discount amount  (INR) :&nbsp;
																<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{discountAmt}
																</span>
															</div>
														}


														{/* <div class="estimatedPrice txtright" style="display:none; text-align: right; font-size: 13px; padding-top: 3px;  color: #4a4a4a;">Amount paid (INR) :&nbsp;<span style="width: 75px; display: inline-block; vertical-align: top;">700.00 </span></div> */}

														<div
															class="estimatedPrice txtright"
															style={{
																display: 'block',
																textAlign: 'right',
																fontSize: '13px',
																paddingTop: '3px',
																color: '#4a4a4a',
															}}
														>
															Balance amount (INR) :&nbsp;<span
																style={{
																	width: '75px',
																	display: 'inline-block',
																	verticalAlign: 'top',
																}}
															>
																{' '}
																{balanceAmount}
															</span>
														</div>

														{/* <div class="estimatedPrice txtright" style="display:none; text-align: right; font-size: 13px; padding-top: 3px;  color: #4a4a4a;">Advance amount (INR) :&nbsp;<span style="width: 75px; display: inline-block; vertical-align: top;"> 12.00</span></div> */}
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>
					<FooterPrint hospitalDataId={hosId} />
				</table>
			</div>
		</div>
	);
}

export default PatientInvoicePrint;
