import React, { useState, useEffect } from "react";
import PharmacyHeader from "./layout/header";
import Footer from "./layout/footer";
import { Route, Link } from "react-router-dom";
// import PharmacyLeft from "./pharmacyLeft";
import Datepicker, { CalendarContainer } from "react-datepicker";
import Pagination from "react-js-pagination";
import { callingAPI } from "../config";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
function ProcedureBill() {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [count, setCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [procedureData, setProcedureData] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const [filterName, setFilterInput] = useState("");
  const [dataFlag, setDataFlag] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  const [isData, setIsData] = useState(0);


  const searchValueChange = (value) => {
    setsearchValue(value);
  };
  // const setReceiptRecptno = (htmlData) => {
  //   window.sessionStorage.setItem("htmlDataprintid", htmlData);
  //   window.open(
  //     `${process.env.PUBLIC_URL}/invoice/prescriptionBillPrint`,
  //     "_blank"
  //   );
  // };
  const setReceiptRecptno = (htmlData, doctorId) => {

    var a = `${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${htmlData + '|' + doctorId + '|' + window.localStorage.getItem('hospitalId')}`
    window.open(a, "_blank")
  }

  const dataPagination = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) {
      setFilterInput("");
    }
    if (!booliansearchValue) {
      setsearchValue("");
    }
    if (!boolianfromDate) {
      setFromDate("");
    }
    if (!booliantoDate) {
      setToDate("");
    }
    let PostData = {
      hospitalId: localStorage.getItem('hospitalId'),
      cmbAppPatient: booliansearchValue,
      txtPatientName: booliansearchValue,
      fromDate: boolianfromDate ? formatDate(fromDate) : "",
      toDate: booliantoDate ? formatDate(toDate) : "",
      max: max.toString(),
      offset: offset.toString(),
    }
    setDataFlag(false);
    callingAPI("clinicalBackground/procedurePaymentBill", PostData)
      .then((data) => {
        if (data.data.success === "0") {
          setProcedureData([]);
          setCount(0);
          setDataFlag(true);
          setIsData(1);
        }
        if (data.data.success === "1") {
          setProcedureData(data.data.result.procedureData);
          setCount(data.data.result.totalCount[0].totalCount);

          setDataFlag(true);
          setIsData(1);
        }
      })
      .catch();
  };
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
}
  const changeTextonTextArea = (value) => {
    setFilterInput(value);
    setsearchValue("");
    if (value === "") {
      setsearchValue("");
      document.getElementById("searchText").disabled = true;
    } else {
      document.getElementById("searchText").disabled = false;
      document.getElementById("searchText").focus();
    }
  };

  const searchValueOnChangeOnSelect = () => {
    if (
      document.getElementById("selectName").value !== "" &&
      document.getElementById("searchText").value === ""
    ) {
      var t = document.getElementById("selectName");
      var selectedText = t.options[t.selectedIndex].text;
      document.getElementById("selectName").focus();
      alert(selectedText + " required");
      return false;
    }
    setbooliansearchValue(searchValue);
    setboolianfilterName(filterName);
    setboolianfromDate(fromDate);
    setbooliantoDate(toDate);
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        alert("End date must be greater than start date");
        return false;
      }
    }
    setActivePage(1);
    let PostData =
    {
      hospitalId: localStorage.getItem('hospitalId'),
      offset: "0",
      max: process.env.REACT_APP_PAGINATION_COUNT,
      cmbAppPatient: filterName,
      txtPatientName: searchValue,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    };

    callingAPI("clinicalBackground/procedurePaymentBill", PostData)
      .then((data) => {
        if (data.data.success === "0") {
          setProcedureData([]);
          setCount(0);
          setDataFlag(true);
          setIsData(1);
        }
        if (data.data.success === "1") {
          setProcedureData(data.data.result.procedureData);
          setCount(data.data.result.totalCount[0].totalCount);
          setDataFlag(true);
          setIsData(1);
        }
      })
      .catch();
  };
  const FromDateClear = () => {
    setFromDate("");
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const ToDateClear = () => {
    setToDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  useEffect(() => {
    const PostData = {
      hospitalId: localStorage.getItem('hospitalId'),
      offset: pageOffset.toString(),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      cmbAppPatient: filterName,
      txtPatientName: searchValue,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    };

    callingAPI("/clinicalBackground/procedurePaymentBill", PostData)
      .then((data) => {
        if (data.data.success === "0") {
          setProcedureData([]);
          setCount(0);
          setDataFlag(true);
          setIsData(1);
        } else if (data.data.success === "1") {

          setDataFlag(true);
          setProcedureData(data.data.result.procedureData);
          setCount(data.data.result.totalCount[0].totalCount);
          setIsData(1);
        }
      })
      .catch();
  }, []);
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle">Procedure Bill</div>
        </div>
      </div>
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch">
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectName"
                  name="selectName"
                  value={filterName}
                  onChange={(e) => changeTextonTextArea(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Patient Name">Patient Name</option>
                  {window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<option value="MR Number">MR Number</option>)}
                  {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<option value="PR Number">PR Number</option>)}

                </select>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  className="inputField"
                  value={searchValue}
                  onChange={(e) => searchValueChange(e.target.value)}
                  disabled
                  style={{
                    background: filterName === "" ? "transparent" : "white",
                  }}
                  placeholder="Enter Value"
                  id="searchText"
                  name="searchText"
                />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <Datepicker
                  autoComplete="off"
                  required
                  className="Datepicker pa2 inputField"
                  maxDate={new Date()}
                  placeholderText="From Date"
                  calendarClassName="rasta-stripes"
                  selected={fromDate}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => setFromDate(date)}
                  id="fromdate"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  calendarContainer={MyContainer}
                  onChangeRaw={handleDateChangeRaw}
                />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <Datepicker
                  autoComplete="off"
                  required
                  className="Datepicker pa2 inputField"
                  selected={toDate}
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => setToDate(date)}
                  placeholderText="To Date"
                  calendarClassName="rasta-stripes"
                  id="todate"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  calendarContainer={MyContainerTo}
                  onChangeRaw={handleDateChangeRaw}
                />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  className="dashButtonBorder labButton"
                  style={{ padding: "8px 20px", height: "35px" }}
                  onClick={searchValueOnChangeOnSelect}
                >
                  Search
                </span>
              </div>
            </div>
          </div>
        </div>
        {procedureData.length > 0 ? (
          <div>
            {procedureData.map((item, i) => (
              <div class="treatmentPlanList">
                <div class="treatmentPlanDate"> {item.visit_date}</div>
                <div class="treatmentTable planActive">
                  {isData === 0 ? (
                    <Loader />
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th class="col-md-3">
                            <div class="dentalProName">Patient Name</div>
                          </th>
                          <th class="col-md-2">
                            {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                              (<div class="dentalProName">MR No</div>) :
                              (<div class="dentalProName">PR No</div>)
                            }
                          </th>
                          <th class="col-md-1">
                            <div class="dentalProName">Receipt No</div>
                          </th>
                          <th class="col-md-1">
                            <div class="dentalProName">&nbsp;</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.procedurePaymentData.map((data, i) => (
                          <tr class="groupBrdr">
                            <td class="col-md-3">
                              <div class="dentalProName paddingFive">
                                <span class="elipsis">{data.displayName} </span>
                              </div>
                            </td>
                            <td class="col-md-2">
                              {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                (<div class="dentalProName paddingFive">
                                  {data.mrNo}{" "}
                                </div>) :
                                (<div class="dentalProName paddingFive">
                                  {data.prNo}{" "}
                                </div>)
                              }
                            </td>
                            <td class="col-md-1">
                              <div class="dentalProName txtCenter  ">
                                {data.receiptNo}
                              </div>
                            </td>
                            <td class="col-md-1">
                              <div class="dentalProName txtCenter pharmacyLgnPrint">
                                <a>
                                  {" "}
                                  {data.receiptNo != "" ? (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        setReceiptRecptno(data.receiptId, data.doctorId)
                                      }
                                    >
                                      View & Print Bill
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          isData === 1 && (
            <NoData />
          )
        )}
        {count > 10 && (
          <div className="paginationSection">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={count}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={dataPagination}
            />
          </div>
        )}
      </div>
    </div>

    // </div>
  );
}

export default ProcedureBill;
