import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import VisitorStatics from './visitorsStatics'
import Man from "../../images/icons/man.png"
import Woman from "../../images/icons/woman.png"
import Children from "../../images/icons/sister-and-brother.png"
import SeniorCitizen from "../../images/icons/old.png"

function VisitorStaticsApoinments() {
    const [dataFlag, setDataFlag] = useState(false);
    const [menAppointmentCount, setMenAppoinmentCount] = useState("");
    const [womenAppointmentCount, setWomenAppoinmentCount] = useState("");
    const [seniorCitizenAppointmentCount, setSeniorCitizenAppointmentCount] = useState("");
    const [childrenAppointmentCount, setChildrenAppointmentCount] = useState("");
    const [appointmentYearAndMonth, setAppointmentYearAndMonth] = useState([]);
    const [totalAppointments, setTotalAppointments] = useState([]);
    const [totalAppointmentResult, setTotalAppointmentResult] = useState([]);


    function getReverseMonthsAndYears(lastMonthsCount) {
        const today = new Date();
        const currentMonth = today.getMonth() + 1; // Month index starts from 0, so we add 1 to get the actual month number
        const currentYear = today.getFullYear();
        const result = [];
        for (let i = 0; i < lastMonthsCount; i++) {
            const month = (currentMonth - i - 1 + 12) % 12 + 1;
            const year = currentYear - Math.floor((currentMonth + i) / 12); // Update this line
            const monthName = getMonthName(month);
            result.push({ month: monthName, year });
        }
        return result;
    }
    function getMonthName(month) {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames[month - 1];
    }
    const lastYearMonths = getReverseMonthsAndYears(12); // Get the last 12 months in reverse order
    const reversedMonthsArray = [];
    for (const item of lastYearMonths) {
        reversedMonthsArray.push(`${item.month} ${item.year}`);
    }

    const monthsYearArray = reversedMonthsArray.map(monthName => {
        const matchingEntry = totalAppointmentResult.find(entry => entry.appointmentYearAndMonth === monthName);
        return matchingEntry ? matchingEntry : { appointmentYearAndMonth: monthName, totalAppointments: 0 };
    });

    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            doctorId: role === "ROLE_DOCTOR" ? window.sessionStorage.getItem('doctorId') : "",
            hospitalId: role === "ROLE_DOCTOR" ? "" : localStorage.getItem("hospitalId"),
        };
        callingAPI('calenderListing/dashBoardVisitorStatics', PostData).then((response) => {
            if (response.data.success === '1') {
                setDataFlag(true);
                setMenAppoinmentCount(response.data.result.menAppointmentCount)
                setWomenAppoinmentCount(response.data.result.womenAppointmentCount)
                setSeniorCitizenAppointmentCount(response.data.result.seniorCitizenAppointmentCount)
                setChildrenAppointmentCount(response.data.result.childrenAppointmentCount)
                setTotalAppointmentResult(response.data.result.totalAppointmentResult)
                setTotalAppointments(monthsYearArray.map((obj) => obj.totalAppointments))


            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    return (
        <div className="visitors_card">
            <div className="total_revnuheader">
                <h2 className='total_revnuheadtxt'>Appointments in One Year</h2>

            </div>
            <div className="totalrevnu_chart">
                {totalAppointments.length > 0 ?

                    <VisitorStatics key={[appointmentYearAndMonth, totalAppointments]} totalAppointments={monthsYearArray} />
                    :
                    <div className='todayAppScroll nodataFnd nodata-found_wrpr graphDataNotfound' >
                        <p>No Data Found </p>
                    </div>
                }
            </div>

            <div className="row text-center visitrs_cardouter">
                {totalAppointments.length > 0 ?
                    <>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="xl-parpl visitrs_cardblock">
                                <div className="visior_subblock visior_iconblock"><img src={Man} alt="Man" width={40} height={40} className='img-fluid' /></div>
                                <div className="visior_subblock"><h4 className="visitrs_card-h4">{menAppointmentCount}</h4>
                                    <span className='visitrs_cardtxt'>Men</span></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="xl-salmon visitrs_cardblock">
                                <div className="visior_subblock visior_iconblock visitor_block-bg"><img src={Woman} alt="Woman" width={40} height={40} className='img-fluid' /></div>
                                <div className="visior_subblock"><h4 className='visitrs_card-h4'>{womenAppointmentCount}</h4>
                                    <span className='visitrs_cardtxt'>Women</span></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="xl-parpl visitrs_cardblock">
                                <div className="visior_subblock visior_iconblock"><img src={SeniorCitizen} alt="Senior Citizen" width={40} height={40} className='img-fluid' /></div>
                                <div className="visior_subblock"><h4 className='visitrs_card-h4'>{seniorCitizenAppointmentCount}</h4>
                                    <span className='visitrs_cardtxt'>Senior Citizen</span></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="xl-salmon visitrs_cardblock">
                                <div className="visior_subblock visior_iconblock visitor_block-bg"><img src={Children} alt="Children" width={40} height={40} className='img-fluid' /></div>
                                <div className="visior_subblock">
                                    <h4 className='visitrs_card-h4'>{childrenAppointmentCount}</h4>
                                    <span className='visitrs_cardtxt'>Children</span>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3'>Total Appointment in Last Year</div>
                    </>
                    : ''
                }
            </div>

        </div>
    )
}

export default VisitorStaticsApoinments