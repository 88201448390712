import React, { useState, useEffect } from 'react';
import Logo from '../images/media/logo.png';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
function TreatmentChartPrint() {
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');

	const [printQueryResult, setPrintQueryResult] = useState([]);
	const [grandTotal, setGrandTotal] = useState([]);
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH + "/"
	);
	const [load, setLoad] = useState(false);

	function styleValues() {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {

				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

				//setMarginTop()
			});

	}


	useEffect(() => {
		styleValues()


		const PostData = {
			procedureId: window.sessionStorage.getItem('procedureId'),
		};
		callingAPI('clinicalBackground/treatmentChartPrint', PostData)
			.then((data) => {

				setPrintQueryResult(data.data.result.printQueryResult);
				setGrandTotal(data.data.result.grandTotal);
				setLoad(true)

			})
			.catch();
	}, []);
	const back = () => {
		window.location.href = '/clinics/treatmentChart';
	};
	const printPage = () => {
		// printDisplay.style.display="none"
		document.getElementById('cancel').style.display = 'none';
		document.getElementById('print').style.display = 'none';
		// document.getElementById("savePrint").style.display="none"
		// document.getElementById("send").style.display="none"
		window.print();
		document.getElementById('cancel').style.display = 'inline-block';
		document.getElementById('print').style.display = 'inline-block';
		// document.getElementById("savePrint").style.display="inline-block"
		// document.getElementById("send").style.display="inline-block"
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '595px'
		var a4sizeheight = '842px'
	}
	return (
		<div align="center">
			{load === false ? <Loader /> : ''}
			<div
				align="center"
				style={{
					width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
					height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
					marginLeft: '0px',
					marginright: '0px',
					marginTop: '0px',
					marginBottom: '0px',
				}}
			>
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0">
					<thead>
						<tr>
							<th style={{ fontWeight: '400' }}>
								<div class="printOutHeaderSec ">
									<table style={{ width: '100%' }}>
										<tbody>
											<HeaderPrint />
										</tbody>
									</table>
								</div>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr>
												<td
													style={{
														fontSize: '14px',
														paddingBottom: '10px',
														textAlign: 'center',
													}}
												>
													Treatment chart for patient
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr
												style={{
													fontSize: '13px',
													background: '#eae8e8',
													borderBottom: '1px solid black',
												}}
											>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '150px',
														display: 'inline-block',
														textAlign: 'left',
														fontFamily: "SourceSansPro-Regular",
														fontSize: "14px",
														fontWeight: "600",
														textShadow: "none",
														color: "#000!important",
													}}
												>
													Procedure
												</td>
												<td
													style={{
														boxSizing: 'border - box',
														padding: '5px 2px',
														width: '100px',
														display: 'inline-block',
														textAlign: 'left', fontFamily: "SourceSansPro-Regular",
														fontSize: "14px",
														fontWeight: "600",
														textShadow: "none",
														color: "#000!important",
													}}
												>
													Procedure Code
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '80px',
														display: 'inline-block',
														textAlign: 'right', fontFamily: "SourceSansPro-Regular",
														fontSize: "14px",
														fontWeight: "600",
														textShadow: "none",
														color: "#000!important",
													}}
												>
													Cost (INR)
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '80px',
														display: 'inline-block',
														textAlign: 'right', fontFamily: "SourceSansPro-Regular",
														fontSize: "14px",
														fontWeight: "600",
														textShadow: "none",
														color: "#000!important",
													}}
												>
													Tax (INR)
												</td>
												<td
													style={{
														boxSizing: 'border-box',
														padding: '5px 2px',
														width: '125px',
														display: 'inline-block',
														textAlign: 'right', fontFamily: "SourceSansPro-Regular",
														fontSize: "14px",
														fontWeight: "600",
														textShadow: "none",
														color: "#000!important",
													}}
												>
													Total Cost (INR)
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>

						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										{printQueryResult.map((item, index) => (
											<tbody>
												<tr>
													<td
														style={{
															boxSizing: ' border-box',
															padding: '5px 2px',
															width: '150px',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{/* inj Dexona (91) */}
														{item.procedure_name}
													</td>
													<td
														style={{
															boxSizing: ' border-box',
															padding: '5px 2px',
															width: '100px',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{/* inj Dexona (91) */}
														{item.procedure_code}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '80px',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{/* 10.35 price */}
														{item.price.toFixed(2)}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '80px',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{/* 0.00 taxamount */}
														{item.totaltaxAmount}
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '125px',
															display: 'inline-block',
															textAlign: 'right',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														{/* 10.35 totalcost */}
														{item.totaltaxcost}
													</td>
												</tr>
											</tbody>
										))}
									</table>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="printOutSettingContent">
									<table width="100%" cellspacing="0">
										<tbody>
											<tr class="dentalProPlaned">
												<td>
													<div
														class="taxValueTable"
														style={{
															width: '44%',
															marginTop: '-6px',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
															color: '#393939',
														}}
													/>

													<div
														style={{
															width: '100%',
															display: 'inline-block',
															boxSizing: 'border-box',
															verticalAlign: 'top',
														}}
													>
														<div
															class="estimatedPrice txtright"
															style={{
																textAlign: 'right',
																fontSize: '13px',
																paddingTop: '15px',
																color: ' #4a4a4a',
															}}
														>
															Estimated Amount (INR):&nbsp;
															<span
																style={{
																	width: '75px',
																	display: 'inline-block',
																	verticalAlign: 'top',
																}}
															>
																{/* 10.35 */}
																{grandTotal}
															</span>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<div class="printOutSettingContent">
														<table width="100%" cellspacing="0">
															<tbody>
																<tr class="dentalProPlaned">
																	<td
																		style={{
																			paddingTop: '15px',
																			fontSize: '10px',
																		}}
																	>
																		The Final treatment charges may vary depending
																		on any unseen treatments included or
																		modification in treatment plan.
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</td>
						</tr>
					</tbody>
					<FooterPrint />
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<table style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td colspan="6" style={{ textAlign: 'center' }}>
								<p
									onClick={printPage}
									id="print"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '15px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										right: '80px',
										fontFamily: '  Verdana',
									}}
								>
									Print
								</p>&nbsp;
								<p
									id="cancel"
									style={{
										width: '70px',
										textAlign: 'center',
										paddingTop: '5px',
										lineHeight: '15px',
										display: 'inline-block',
										fontWeight: 'bold',
										fontSize: '12px',
										color: 'white',
										background: 'rebeccapurple none repeat scroll 0% 0%',
										marginTop: '2px',
										cursor: 'pointer',
										borderRadius: '5px',
										marginBottom: '2px',
										height: '25px',
										position: 'absolute',
										fontFamily: '  Verdana',
										right: '0px',
									}}
									onClick={back}
								>
									Back
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default TreatmentChartPrint;
