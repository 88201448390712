import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import { useState } from 'react';
function HospitalLeftMenu() {
    const [toggleLab, setToggleLab] = useState(false);
    const [toggleFiles, setToggleFiles] = useState(false);
    const [togglepPayment, setTogglePayment] = useState(false);
    const [toggleInvoice, setToggleInvoice] = useState(false);
    const [toggleMed, setToggleMed] = useState(false);



    const labTest = () => {
        window.sessionStorage.setItem("activePage", 1)
        window.sessionStorage.setItem("offset", 0)

    };


    const setToggleFiles1 = (event) => {
        if (document.getElementById("qaz1").style.display === "block") {
            window.sessionStorage.setItem("qaz1", "none")
        }
        else {
            window.sessionStorage.setItem("qaz1", "block")
        }

    }
    const setToggleFiles2 = (event) => {
        if (document.getElementById("qaz2").style.display === "block") {
            window.sessionStorage.setItem("qaz2", "none")
        }
        else {
            window.sessionStorage.setItem("qaz2", "block")
        }
    }
    const setToggleFiles3 = (event) => {
        if (document.getElementById("qaz3").style.display === "block") {
            window.sessionStorage.setItem("qaz3", "none")
        }
        else {
            window.sessionStorage.setItem("qaz3", "block")
        }
    }
    const setToggleFiles4 = (event) => {
        if (document.getElementById("qaz4").style.display === "block") {
            window.sessionStorage.setItem("qaz4", "none")
        }
        else {
            window.sessionStorage.setItem("qaz4", "block")
        }
    }
    const setToggleFiles5 = (event) => {
        if (document.getElementById("qaz5").style.display === "block") {
            window.sessionStorage.setItem("qaz5", "none")
        }
        else {
            window.sessionStorage.setItem("qaz5", "block")
        }
    }


    return (

        <div className="settingMenuList">
            <div className="settingLeftTitle">Reports</div>
            <span className='LeftMenuTitle'>
                {/* IDA Test Clinic */}
                {window.sessionStorage.getItem("userName")}
            </span>
            <ul>
                <li>
                    <NavLink to="/reports/appointmentList" activeClassName="active" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Appointment List </NavLink>
                </li>
                <li>
                    <NavLink to="/reports/patientdetails" activeClassName="active" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Patient Details </NavLink>
                </li>

                <li>
                    <Link to="#"  >
                        <span onClick={() => setToggleFiles1()}>Lab</span>

                        <div className="plSubMenu" id="qaz1" style={{ display: window.sessionStorage.getItem("qaz1"), padding: '0px', paddingTop: '15px' }}>
                            <div>
                                <li>
                                    <NavLink to="/labLogin/outsideLabOrderDetails" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span> Outside Lab Order</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink to="/labLogin/labOrderPayment" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Lab Order Payment</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/labLogin/labTestUnavailability" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Lab Test Unavilability</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li style={{ borderBottom: "none" }}>
                                    <NavLink to="/reports/labTestInventory" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Lab Test Inventory</span>
                                    </NavLink>
                                </li>
                            </div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="#"  >
                        <span onClick={() => setToggleFiles2()}>Pharmacy</span>

                        <div className="plSubMenu" id="qaz2" style={{ display: window.sessionStorage.getItem("qaz2"), padding: '0px', paddingTop: '15px' }}>
                            <div>
                                <li>
                                    <NavLink to="/pharmacyLogin/outsidePharmacyOrderDetails" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Outside Pharmacy Order</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink to="/reports/pharmacystockdetails" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Pharmacy Stock Details</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/reports/pharmacypayment" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Pharmacy Payment</span>
                                    </NavLink>
                                </li>
                            </div>

                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="#"  >
                        <span onClick={() => setToggleFiles3()}>Medicine</span>

                        <div className="plSubMenu" id="qaz3" style={{ display: window.sessionStorage.getItem("qaz3"), padding: '0px', paddingTop: '15px' }}>
                            <div>
                                <li>
                                    <NavLink to="/pharmacyLogin/prescribedMedicinebyQuantity" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Prescribed Medicine by Quantity</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink to="/pharmacyLogin/patientMedicine" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Patient Medicine</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/reports/genericMedicineList" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Generic Medicine List</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/pharmacyLogin/hospitalMedicineHistory" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Hospital Medicine History</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/pharmacyLogin/medicineReorder" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Medicine Reorder</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/pharmacyLogin/expiredMedicineStockDetails" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Expired Medicine Stock Details</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/pharmacyLogin/medicineSalebyDay" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Medicine Sale by Day</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li >
                                    <NavLink to="/pharmacyLogin/unavailableMedicine" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span  >Unavailable Medicine</span>
                                    </NavLink>
                                </li>
                            </div>

                        </div>
                    </Link>
                </li>

                <li>
                    <Link to="#"  >
                        <span onClick={() => setToggleFiles4()}>Invoice</span>

                        <div className="plSubMenu" id="qaz4" style={{ display: window.sessionStorage.getItem("qaz4"), padding: '0px', paddingTop: '15px' }}>
                            <div>
                                <li>
                                    <NavLink to="/reports/purchaseInvoiceDetails" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Purchase Invoice Details</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink to="/reports/supplierInvoice" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Supplier Invoice</span>
                                    </NavLink>
                                </li>
                            </div>


                        </div>
                    </Link>
                </li>

                <li>
                    <Link to="#"  >
                        <span onClick={() => setToggleFiles5()}>Payment</span>
                        <div className="plSubMenu" id="qaz5" style={{ display: window.sessionStorage.getItem("qaz5"), padding: '0px', paddingTop: '15px' }}>
                            <div>
                                <li>
                                    <NavLink to="/reports/incomeExpense" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Income & Expense</span>
                                    </NavLink>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <NavLink to="/reports/pendingpaymentdetails" activeclassname="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}>
                                        <span>Pending Payment Details</span>
                                    </NavLink>
                                </li>
                            </div>

                            <div>
                                <li className="settingListLi">
                                    <NavLink to="/reports/patientpayment" activeClassName="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}> Patient Payment</NavLink>
                                </li>
                            </div>
                            <div>
                                <li className="settingListLi">
                                    <NavLink to="/reports/refund" activeClassName="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}> Refund</NavLink>
                                </li>
                            </div>
                            <div>
                                <li className="settingListLi">
                                    <NavLink to="/reports/prescriptionAmount" activeClassName="active" onClick={() => {
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}> Prescription(s) Amount</NavLink>
                                </li>
                            </div>


                        </div>
                    </Link>
                </li>

                <li>
                    <NavLink to="/reports/revenueByDoctor" activeClassName="active" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Revenue By Doctor</NavLink>
                </li>
                <li>
                    <NavLink to="/reports/treatmentDetails" activeClassName="active" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Treatment Details</NavLink>
                </li>
                {/* <li>
                    <NavLink to="/reports/receiptTaxDetails" activeClassName="active" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Receipt Tax Details</NavLink>
                </li> */}


            </ul>
        </div>
    )
}

export default HospitalLeftMenu
