import React, { useEffect, useState } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import { callingAPI } from '../config';
import { Link, useLocation } from 'react-router-dom';
function MedicalCertificateEdit() {
	const location = useLocation();
	const [medicalCertificateId, setMedicalCertificateId] = useState("");
	const [medicalCertificateListing, setMedicalCertificateListing] = useState([]);

	useEffect(() => {
		document.getElementById("textAreaMedical").value = "Signature of the Applicant _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _                                  I," + window.sessionStorage.getItem('patientDoctorName') + " after careful personal examination of the case hereby certify that Mr/Mrs. " + window.sessionStorage.getItem("PATIENTNAME") + "  whose signature is given above, is suffering from _ _ _ _ _ _ _ _ _ _ , that I consider that a period of absence from duty , with effect from  _ _ _ _ _ _ _ _ _ _  to  _ _ _ _ _ _ _ _ _ _ is absolutely necessary for the restoration of his/her health."

		if (typeof location.state !== 'undefined') {
			const { certificateId } = location.state;

			setMedicalCertificateId(certificateId);

			const postdata = {
				certificateId: certificateId,

			};

			callingAPI('medicalCertificate/getMedicalCertificateDetailsById', postdata).then((data) => {

				if (data.data.success === '1') {

					setMedicalCertificateListing(data.data.result);
					document.getElementById("textAreaMedical").value = data.data.result[0].medicalCertificateDetails.memberMedicalCertificateDescription
				}
			});
		}

	}, [])

	const medicalCertificateSave = (e, type) => {

		const listParams = {
			// "medicalCertificateId": "24",
			"medicalCertificateId": medicalCertificateId === '' ? "" : medicalCertificateId,
			"hospitalId": localStorage.getItem('hospitalId'),
			"memberId": window.sessionStorage.getItem('memberId'),
			"userId": window.sessionStorage.getItem('userId'),
			"doctorId": window.sessionStorage.getItem('patientDoctorId'),
			"medicalCertificateDescription": document.getElementById("textAreaMedical").value,
		}
		callingAPI('medicalCertificate/saveOrUpdateMemberMedicalCertificate', listParams)
			.then((response) => {
				if (response.data.success === '1') {
					if (type === "save") {
						if (medicalCertificateId == "") {
							alert("Medical Certificate Saved Successfully.");
							window.sessionStorage.setItem('memberCertificateId', response.data.result.memberMedicalCerticateId);
							window.location.href = "/patients/medicalCertificate";
						}
						else {
							alert("Medical Certificate Edited Successfully.");
							window.sessionStorage.setItem('memberCertificateId', response.data.result.memberMedicalCerticateId);
							window.location.href = "/patients/medicalCertificate";
						}
					}
					else if (type === "printSave") {
						alert("Medical Certificate Saved Successfully.");
						window.location.href = "/patients/medicalCertificate";
						window.sessionStorage.setItem('memberCertificateId', response.data.result.memberMedicalCerticateId);
						window.sessionStorage.setItem('fromMedicalCertificateSaveAndPrint', 1);

					}


				}
				else {
					alert(response.data.errorMessage);
				}

			})
			.catch();
	};
	useEffect(() => {

	}, []);


	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll completedPlansWrap">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent" style={{ border: 'none' }}>
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">Certificate</span>
												<button class="treatmentPlanBtn pl_blue_btn" style={{ background: "rgb(91, 183, 91)", padding: " 4px 15px", border: 'none' }}

													onClick={(e) => {
														medicalCertificateSave(e, 'printSave');
													}}>
													Save & Print
												</button>
												<button class="treatmentPlanBtn pl_blue_btn" style={{ background: "rgb(91, 183, 91)", padding: " 4px 15px", border: 'none' }}
													onClick={(e) => {
														medicalCertificateSave(e, 'save');
													}}>
													Save
												</button>
												<a href="/patients/medicalCertificate"><span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">Cancel</span></a>
											</div>
											<div className='medCertificateForm'>
												<div id="medicalCreate" class="treatmentPlanContent" >
													<div class="certificateCvrDiv">
														<h5>MEDICAL CERTIFICATE </h5>

														<textarea
															name='textArea'

															id="textAreaMedical">
														</textarea>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
export default MedicalCertificateEdit;

