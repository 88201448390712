import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import { useParams } from 'react-router-dom';
import HeaderPrint from '../Settings/headerPrint';
import FooterPrint from '../Settings/footerPrint';
function CasesheetDentalPrint() {
	let { membId, doctorId, printId } = useParams();
	const [marginbottom, setmarginbottom] = useState('');
	const [margintop, setmargintop] = useState('');
	const [marginleft, setmarginleft] = useState();
	const [marginright, setmarginright] = useState('');
	const [pagesizeid, setpagesizeid] = useState('');
	const [hospitalDetails, setHospitalDetails] = useState([]);
	const [doctorDetails, setDoctorDetails] = useState([]);
	const [memberDetails, setMemberDetails] = useState([]);
	const [memberFirstName, setMemberFirstName] = useState([]);
	const [hospitalName, sethospitalName] = useState('');
	const [hospitalAddress, sethospitalAddress] = useState('');
	const [hospitalCity, sethospitalCity] = useState('');
	const [hospitalPhoneNumber, sethospitalPhoneNumber] = useState('');
	const [memberLastName, setMemberLastName] = useState([]);
	const [listDetails, setListDetails] = useState([]);
	const [familyHistoryResult, setFamilyHistoryResult] = useState([]);
	const [socialHistoryResult, setSocialHistoryResult] = useState([]);
	const [illnessHistoryResult, setIllnessHistoryResult] = useState([]);
	const [drugAllergyResult, setDrugAllergyResult] = useState("");
	const [allergyResult, setAllergyResult] = useState("");
	const [HospitalLogo, setHospitalLogo] = useState([]);
	const [pregnancyHistoryResult, setPregnancyHistoryResult] = useState([]);
	const [age, setAge] = useState('');
	const [hospitalCountryName, sethospitalCountryName] = useState('')
	const [hospitalpincode, sethospitalpincode] = useState('')
	const [hospitalStateName, sethospitalStateName] = useState('');
	const [gender, setGender] = useState('');
	const [date, setDate] = useState('');
	const [pmKeyData, setPmKeyData] = useState({});
	const [logoPath, setlogoPath] = useState(
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH
	);
	function styleValues() {
		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			doctorId: ""
		};

		callingAPI('commonUserFunctions/headerFooterLogoPrints', PostData)
			.then((res) => {

				setmarginbottom(res.data.result.headerFooterDetails[0].page_margin_bottom);
				setmargintop(res.data.result.headerFooterDetails[0].page_margin_top);
				setmarginleft(res.data.result.headerFooterDetails[0].page_margin_left);
				setmarginright(res.data.result.headerFooterDetails[0].page_margin_right);

				setpagesizeid(res.data.result.headerFooterDetails[0].page_size_id);

				//setMarginTop()
			});

	}
	useEffect(() => {
		styleValues()
		const postData = {
			memberId: membId,
			hospitalId: localStorage.getItem('hospitalId'),
			doctorId: doctorId,
			// doctorId: window.sessionStorage.getItem('patientDoctorId'),
			doctorVisitId: printId,

		};
		callingAPI('commonUserFunctions/printDentalCaseSheet', postData).then((data) => {

			if (data.data.success === '1') {
				setListDetails(data.data.result.list);
				sethospitalCountryName(data.data.result.hospitalDetails[0].countryName);
				sethospitalpincode(data.data.result.hospitalDetails[0].pincode);
				sethospitalStateName(data.data.result.hospitalDetails[0].stateName);
				sethospitalName(data.data.result.hospitalDetails[0].hospitalName);
				sethospitalAddress(data.data.result.hospitalDetails[0].hospitalAddress);
				sethospitalCity(data.data.result.hospitalDetails[0].hospitalCity);
				sethospitalPhoneNumber(data.data.result.hospitalDetails[0].hospitalPhoneNumber);
				setHospitalDetails(data.data.result.hospitalDetails);
				setDoctorDetails(data.data.result.doctorDetails);
				setMemberDetails(data.data.result.memberDetails);
				setAge(data.data.result.memberDetails[0].memberAge)
				setGender(data.data.result.memberDetails[0].memberGender)
				setMemberFirstName(data.data.result.memberDetails[0].memberFirstName);
				setMemberLastName(data.data.result.memberDetails[0].memberLastName);
				if (data.data.result.dentalCaseSheetQueryResult[0].aptDate.length > 0) {
					setDate(data.data.result.dentalCaseSheetQueryResult[0].aptDate);
				}
				setHospitalLogo(data.data.result.hospitalDetails[0].hospitalLogo);
				setFamilyHistoryResult(data.data.result.familyHistoryResult)
				setSocialHistoryResult(data.data.result.socialHistoryResult)
				setIllnessHistoryResult(data.data.result.illnessHistoryResult)
				setDrugAllergyResult(data.data.result.drugAllergyResult[0].drugAllergyHistory)
				setAllergyResult(data.data.result.allergyResult[0].allergyHistory)
				setPregnancyHistoryResult(data.data.result.pregnancyHistoryResult)
				setPmKeyData(data.data.result.pmKeyData);
				let keyData = data.data.result.pmKeyData;
				for (let i = 0; i < keyData.length; i++) {
					document.getElementById('appendeText' + keyData[i].toothNo).innerHTML = keyData[i].toothKeys;
				}
				window.sessionStorage.removeItem("printId");
			}
		})
			.catch();
	}, []);

	const printCaseSheetDental = () => {
		document.getElementById('printButton').style.display = 'none';
		window.print();
		document.getElementById('printButton').style.display = 'block';
	};
	if (pagesizeid == '1') {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	if (pagesizeid == '2') {
		var a5sizewidth = '420px'
		var a5sizeheight = '595px'
	}
	if (pagesizeid == '3') {
		var b4sizewidth = '780px'
		var b4sizeheight = '288px'
	}
	if (pagesizeid == '4') {
		var b5sizewidth = '540px'
		var b5sizeheight = '414px'
	}
	if (pagesizeid == '5') {
		var lettersizewidth = '792px'
		var lettersizeheight = '612px'
	}
	if (pagesizeid == '6') {
		var prescriptionsizewidth = '540px'
		var prescriptionsizeheight = '794px'
	}
	else {
		var a4sizewidth = '540px'
		var a4sizeheight = '842px'
	}
	return (
		<div class="caseSheetPrintWrap caseSheetDentalPrintWraper"
			align="center" style={{
				fontFamily: 'SourceSansPro-Regular', width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
				height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
				marginLeft: '0px',
				marginright: '0px',
				marginTop: '0px',
				marginBottom: '0px', margin: '0 auto'
			}}>
			<div
				align="center"
			// style={{
			// 	width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth,
			// 	height: a4sizeheight || a5sizeheight || b4sizeheight || b5sizeheight || lettersizeheight || prescriptionsizeheight,
			// 	marginLeft: '0px',
			// 	marginright: '0px',
			// 	marginTop: '0px',
			// 	marginBottom: '0px',
			// }}
			>
				<table style={{ width: '100%', marginLeft: marginleft + 'px', marginRight: marginright + 'px', marginTop: margintop + 'px', marginBottom: marginbottom + 'px' }} border="0" className='caseSheetDentalPrintTble'>
					<thead>
						<HeaderPrint />
						<tr>
							<th style={{ fontWeight: '400', color: '#000', fontFamily: 'SourceSansPro-Regular' }}>
								<div class="caseSheetTop">
									<div class="printOutHeaderSec ">
										{/* <HeaderPrint /> */}
										{/* <div class="caseLogo">
						             <img src={logoPath + '/' + localStorage.getItem('hospitalId') + ".png"} />
					                    </div>
					                      <div class="caseNAme">
						                  <h2 style={{ fontWeight: '600' }}>{hospitalName}</h2>
						                   <span>
							            {hospitalAddress},{hospitalCity} {hospitalStateName},{hospitalCountryName},{hospitalpincode}
						                  </span>
						                <span>{hospitalPhoneNumber}</span>
					                         </div> */}
									</div>
									{doctorDetails.map((item, i) => (
										<div class="caseDoctor">
											<p>
												{item.title}
												{item.doctorDisplayName},
												{' '}
												{item.education}
												{/* {item.speciality} */}

											</p>
											<p>Council Reg. No.{item.medicalRegNumber}</p>
										</div>
									))}
								</div>
								{memberDetails.map((data, i) => (

									<div class="patientDetails caseSheetpatientDetails">

										<div class="patientDetailItem">
											<div class="patientNAme">
												<span class="patientNAmeLeft" style={{ width: 'auto' }}>PATIENT'S NAME : &nbsp;{data.memberFirstName} {data.memberLastName}</span>
												{/* <span class="patientNAmeRight">
													&nbsp;{data.memberFirstName} {data.memberLastName}
												</span> */}
											</div>
											<div class="patientAge">
												<span class="patientAgeLeft">AGE :&nbsp;</span>
												{data.memberAge !== 0 ?
													<span class="patientAgeRight">{data.memberAge}</span> : null}
											</div>
											<div class="patientSex">
												<span class="patientSexLeft">SEX :</span>
												<span class="patientSexRight">&nbsp;{data.memberGender}</span>
											</div>
										</div>
										<div class="patientDetailItem">
											<div class="patientCase">
												<span class="patientCaseLeft" style={{ width: 'auto' }}>
													{/* CASE RECORD NO : {data.memberMrNo} */}
													{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
														<span>CASE RECORD NO : {data.memberMrNo}</span>
														:
														(!data.memberPrNo == "" &&
															<span>CASE RECORD NO : {data.memberPrNo}</span>
														)}
												</span>
												<span class="patientCaseRight"></span>
											</div>
											<div class="patientRef">
												<span class="patientRefLeft">Ref.by :</span>
												<span class="patientRefRight" />
											</div>
											<div class="patientDate">
												<span class="patientDateLeft">DATE : </span>
												<span class="patientDateRight">{date}</span>
											</div>
										</div>
										<div class="patientDetailItem">
											<div class="patientAddress">
												<span class="patientAddressLeft" style={{ width: 'auto' }}>ADDRESS :</span>
												<span class="patientAddressRight">
													{data.memberAddress} {data.memberCity} {data.memberState} {data.memberCountry} {data.memberZip}
												</span>
											</div>
										</div>
									</div>
								))}
							</th>
						</tr>
					</thead>
					<div class="patientComplaint casesheetpatientComplaint">
						<div class="patientComplaintItem">
							<span class="patientComplaintLabel">
								CHIEF COMPLAINT
							</span>
							<span class="patientComplainttXT">
								<span class="colan" style={{ float: "left" }}>:</span>
								<span > &nbsp;{listDetails.Chiefpresentingcomplainttag}</span>
							</span>

						</div>
						{listDetails.Chiefpresentingcomplaintdescription ?
							<div class="patientComplaintItem">
								<span class="patientComplaintLabel"> </span>
								<span class="patientComplainttXT">
									<span className='printDescription'><span><b style={{ float: "left" }}> Description&nbsp;:&nbsp;</b>
										{listDetails.Chiefpresentingcomplaintdescription}</span></span>
								</span>
							</div> : null}

						<div class="patientComplaintItem">
							<span class="patientComplaintLabel" >ASSOCIATED COMPLAINT</span>
							<span class="patientComplainttXT">
								<span class="colan" style={{ float: "left" }}>:</span>
								<span >&nbsp;{listDetails.Associatedcomplaintstag}</span>
							</span>
						</div>
						{listDetails.Associatedcomplaintsdescription ?
							<div class="patientComplaintItem">
								<span class="patientComplaintLabel"> </span>
								<span class="patientComplainttXT">
									<span className='printDescription'><span><b style={{ float: "left" }}> Description&nbsp;:&nbsp;</b>
										{listDetails.Associatedcomplaintsdescription}</span></span>
								</span>
							</div> : null}
					</div>

					<div class="patientMedicalHistory casesheetpatientComplaint">
						<span class="medicalHeding">RELEVANT MEDICAL HISTORY</span>
						<div class="patientHistorySec">
							{illnessHistoryResult.length > 0 ? (
								<div>
									{illnessHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Systemic Illness</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">
												{/* <span class="colan" style={{ float: "left" }}>:</span> */}
												{data.illnessHistory}
												<span />
											</span>
										</div>))}
								</div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Systemic Illness</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
										{/* <span class="colan" style={{ float: "left" }}>:</span> */}
										<span />
									</span>
								</div>)}
							{/* {drugAllergyResult != "" || allergyResult != ""? ( */}
							<div>
								{/* {drugAllergyResult.map((data, i) => ( */}
								<div class="patientDetailItem">
									<span class="patientMedical">Drug History</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
										{/* <span class="colan" style={{ float: "left" }}>:</span> */}
										{drugAllergyResult}
										<span />
									</span>
								</div>
							</div>
							<div>
								{/* {drugAllergyResult.map((data, i) => ( */}
								<div class="patientDetailItem">
									<span class="patientMedical">Allergy</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
										{/* <span class="colan" style={{ float: "left" }}>:</span> */}
										{allergyResult}
										<span />
									</span>
								</div>
							</div>
							{/* ))} */}
							{/* </div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Drug History/ Allergy</span>
									<span class="patientMedicalTxt">
										<span class="colan" style={{ float: "left" }}>:</span>
										<span />
									</span>
								</div>)} */}
							{age >= 15 && gender == 'Female' ? (
								<div>
									{pregnancyHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Pregnancy</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">

												{data.pregnancyHistory}
												<span />
											</span>
										</div>))}
								</div>) : ('')}
							{familyHistoryResult.length > 0 ? (
								<div>
									{familyHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Family History</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">

												{data.history}
												<span />
											</span>
										</div>))}
								</div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Family History</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">
										<span />
									</span>
								</div>)}
							{socialHistoryResult.length > 0 ? (
								<div>
									{socialHistoryResult.map((data, i) => (
										<div class="patientDetailItem">
											<span class="patientMedical">Habits (Smoking etc)</span>
											<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
											<span class="patientMedicalTxt">

												{data.history}
												<span />
											</span>
										</div>))}
								</div>) : (<div class="patientDetailItem">
									<span class="patientMedical">Habits (Smoking etc)</span>
									<span class="colan" style={{ float: "left", width: '8px' }}>:</span>
									<span class="patientMedicalTxt">

										<span />
									</span>
								</div>)}
						</div>
					</div>
					<div class="keySelectionWrap casesheetpatientComplaint">
						<div class="keySelectTop">
							<div class="keySelectLeft">
								<span class="keyHead">Key :</span>
								<ul>
									<li>
										<span class="keyLetter">D</span>
										<span class="keyDes">- Decay</span>
									</li>
									<li>
										<span class="keyLetter">M</span>
										<span class="keyDes">- Missing/Extracted</span>
									</li>
									<li>
										<span class="keyLetter">F</span>
										<span class="keyDes">- Filled</span>
									</li>
									<li>
										<span class="keyLetter">R</span>
										<span class="keyDes">- Replaced</span>
									</li>
									<li>
										<span class="keyLetter">X</span>
										<span class="keyDes">- Recommended Extraction</span>
									</li>
									<li>
										<span class="keyLetter">#</span>
										<span class="keyDes">- Fractured Tooth</span>
									</li>
									<li>
										<span class="keyLetter">I</span>
										<span class="keyDes">- Impacted</span>
									</li>
									<li>
										<span class="keyLetter">P</span>
										<span class="keyDes">- Periodontal/Pockets</span>
									</li>
								</ul>
							</div>
							<div class="keySelectRight">
								<div class="keySelectDiv">
									<div class="keySelectDivTopOne">
										<div class="keySelectDivTop">
											<div class="keySelectDivTopLeft">
												<span class="keyItem">
													<span id="appendeText55" class="keySelector" />
													<span>E</span>
												</span>
												<span class="keyItem">
													<span id="appendeText54" class="keySelector" />
													<span>D</span>
												</span>
												<span class="keyItem">
													<span id="appendeText53" class="keySelector" />
													<span>C</span>
												</span>
												<span class="keyItem">
													<span id="appendeText52" class="keySelector" />
													<span>B</span>
												</span>
												<span class="keyItem">
													<span id="appendeText51" class="keySelector" />
													<span>A</span>
												</span>
											</div>
										</div>
										<div class="keySelectDivBottom">
											<div class="keySelectDivTopLeft">
												<span class="keyItem">
													<span>18</span>
													<span id="appendeText18" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>17</span>
													<span id="appendeText17" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>16</span>
													<span id="appendeText16" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>15</span>
													<span id="appendeText15" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>14</span>
													<span id="appendeText14" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>13</span>
													<span id="appendeText13" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>12</span>
													<span id="appendeText12" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>11</span>
													<span id="appendeText11" class="keySelector" />
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="keySelectDiv">
									<div class="keySelectDivTopTwo">
										<div class="keySelectDivTop">
											<div class="keySelectDivTopRight">
												<span class="keyItem">
													<span id="appendeText61" class="keySelector" />
													<span>A</span>
												</span>
												<span class="keyItem">
													<span id="appendeText62" class="keySelector" />
													<span>B</span>
												</span>
												<span class="keyItem">
													<span id="appendeText63" class="keySelector" />
													<span>C</span>
												</span>
												<span class="keyItem">
													<span id="appendeText64" class="keySelector" />
													<span>D</span>
												</span>
												<span class="keyItem">
													<span id="appendeText65" class="keySelector" />
													<span>E</span>
												</span>
											</div>
										</div>
										<div class="keySelectDivBottom">
											<div class="keySelectDivTopRight">
												<span class="keyItem">
													<span>21</span>
													<span id="appendeText21" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>22</span>
													<span id="appendeText22" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>23</span>
													<span id="appendeText23" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>24</span>
													<span id="appendeText24" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>25</span>
													<span id="appendeText25" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>26</span>
													<span id="appendeText26" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>27</span>
													<span id="appendeText27" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>28</span>
													<span id="appendeText28" class="keySelector" />
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="keySelectDiv">
									<div class="keySelectDivTopOne keyBorder" style={{ borderBottom: "none" }}>
										<div class="keySelectDivBottom">
											<div class="keySelectDivBottomLeft">
												<span class="keyItem">
													<span id="appendeText48" class="keySelector" />
													<span>48</span>
												</span>
												<span class="keyItem">
													<span id="appendeText47" class="keySelector" />
													<span>47</span>
												</span>
												<span class="keyItem">
													<span id="appendeText46" class="keySelector" />
													<span>46</span>
												</span>
												<span class="keyItem">
													<span id="appendeText45" class="keySelector" />
													<span>45</span>
												</span>
												<span class="keyItem">
													<span id="appendeText44" class="keySelector" />
													<span>44</span>
												</span>
												<span class="keyItem">
													<span id="appendeText43" class="keySelector" />
													<span>43</span>
												</span>
												<span class="keyItem">
													<span id="appendeText42" class="keySelector" />
													<span>42</span>
												</span>
												<span class="keyItem">
													<span id="appendeText41" class="keySelector" />
													<span>41</span>
												</span>
											</div>
										</div>
										<div class="keySelectDivTop">
											<div class="keySelectDivBottomLeft">
												<span class="keyItem">
													<span>E</span>
													<span id="appendeText85" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>D</span>
													<span id="appendeText84" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>C</span>
													<span id="appendeText83" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>B</span>
													<span id="appendeText82" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>A</span>
													<span id="appendeText81" class="keySelector" />
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="keySelectDiv">
									<div class="keySelectDivTopTwo keyBorder" style={{ borderBottom: "none" }}>
										<div class="keySelectDivBottom">
											<div class="keySelectDivBottomRight">
												<span class="keyItem">
													<span id="appendeText31" class="keySelector" />
													<span>31</span>
												</span>
												<span class="keyItem">
													<span id="appendeText32" class="keySelector" />
													<span>32</span>
												</span>
												<span class="keyItem">
													<span id="appendeText33" class="keySelector" />
													<span>33</span>
												</span>
												<span class="keyItem">
													<span id="appendeText34" class="keySelector" />
													<span>34</span>
												</span>
												<span class="keyItem">
													<span id="appendeText35" class="keySelector" />
													<span>35</span>
												</span>
												<span class="keyItem">
													<span id="appendeText36" class="keySelector" />
													<span>36</span>
												</span>
												<span class="keyItem">
													<span id="appendeText37" class="keySelector" />
													<span>37</span>
												</span>
												<span class="keyItem">
													<span id="appendeText38" class="keySelector" />
													<span>38</span>
												</span>
											</div>
										</div>
										<div class="keySelectDivTop">
											<div class="keySelectDivBottomRight">
												<span class="keyItem">
													<span>A</span>
													<span id="appendeText71" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>B</span>
													<span id="appendeText72" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>C</span>
													<span id="appendeText73" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>D</span>
													<span id="appendeText74" class="keySelector" />
												</span>
												<span class="keyItem">
													<span>E</span>
													<span id="appendeText75" class="keySelector" />
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="clinicContentWrap">
							<div class="clinicContentLeft" style={{ width: "100%", border: "none" }}>
								{listDetails.OcclusionEvaluationdescription &&
									<div class="caseSheetItemList">
										<label>Occlusion Evaluation</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">{listDetails.OcclusionEvaluationdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Lymphnodesdescription &&
									<div class="caseSheetItemList">
										<label>Lymph nodes</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan"> {listDetails.Lymphnodesdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.TMJdescription &&
									<div class="caseSheetItemList">
										<label>TMJ</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan"> {listDetails.TMJdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>
								}
								{listDetails.Buccalmucosadescription &&
									<div class="caseSheetItemList">
										<label>Buccal mucosa</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan"> {listDetails.Buccalmucosadescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Vestibulesdescription &&
									<div class="caseSheetItemList">
										<label>Vestibules</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.Vestibulesdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Tonguedescription &&
									<div class="caseSheetItemList">
										<label>Tongue</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.Tonguedescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.FloorofMouthdescription &&
									<div class="caseSheetItemList">
										<label>Floor of Mouth</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.FloorofMouthdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Palatedescription &&
									<div class="caseSheetItemList">
										<label>Palate</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.Palatedescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Pharynxdescription &&
									<div class="caseSheetItemList">
										<label>Pharynx</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan"> {listDetails.Pharynxdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Alveolarbonedescription &&
									<div class="caseSheetItemList">
										<label>Alveolar bone</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan"> {listDetails.Alveolarbonedescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Periopocketsdescription &&
									<div class="caseSheetItemList">
										<label>Perio pockets</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.Periopocketsdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Gingivadescription &&
									<div class="caseSheetItemList">
										<label>Gingiva</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.Gingivadescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.OralHygienedescription &&
									<div class="caseSheetItemList">
										<label>Oral Hygiene</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.OralHygienedescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.Calculusdescription &&
									<div class="caseSheetItemList">
										<label>Calculus </label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.Calculusdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
								{listDetails.GingivalBleedingdescription &&
									<div class="caseSheetItemList">
										<label>Gingival Bleeding</label><span style={{ width: "auto", float: "left", marginRight: "2px" }}>:</span>
										<span>
											<span class="colan">  {listDetails.GingivalBleedingdescription}</span>
											<span class="caseSheetItemListTxt" />
										</span>
									</div>}
							</div>
							{/* <div class="clinicContentRight" style={{width:'100%', border:"none"}}>
						<div class="clinicRadioText">
							<label>Radiographs existing/ advised</label>
							<span>{listDetails.Radiographsexistingadviseddescription}</span>
						</div>
						<div class="clinicRadioText">
							<label>Comments</label>
							<span>{listDetails.Commentsdescription}</span>
						</div>
					</div> */}
						</div>
					</div>

					<div class="specialisationWrap casesheetpatientComplaint" >

						<div class="specialisationOne">
							<span class="medicalTitle" style={{ width: "auto", textTransform: "uppercase" }}>Radiographs existing/ advised : </span>
							<span>&nbsp;{listDetails.Radiographsexistingadviseddescription}</span>
						</div>
						<div class="specialisationOne">
							<span class="medicalTitle" style={{ width: "auto", textTransform: "uppercase" }}>Comments : </span>
							<span>&nbsp;{listDetails.Commentsdescription}</span>
						</div>

						<div class="specialisationOne">
							<span class="medicalTitle" style={{ width: "auto" }}>SPECIALIST OPINION IF ANY :</span>
							<span>&nbsp;{listDetails.SpecialistOpinionifanydescription}</span>
						</div>
						<div class="specialisationTwo">
							<span class="medicalTitle" style={{ width: "auto" }}>DIAGNOSIS &amp; TREATMENT PLAN/ EXPLAINED OPTIONS : </span>
							<span dangerouslySetInnerHTML={{ __html: listDetails.DiagnosisTreatmentPlanExplainedOptionsdescription }}  ></span>
						</div>
						<div class="specialisationOne">
							<span class="medicalTitle" style={{ width: "auto", textTransform: "uppercase" }}>Treatment Done : </span>
							<span>&nbsp;{listDetails.TreatmentDonedescription}</span>
						</div>
						<div class="specialisationConsent">
							<span class="medicalTitle">CONSENT :</span>
							<span>
								I {memberFirstName} {memberLastName} the understand in my full sense  give my consent for the above procedure explained and advised
								to me in this clinic. The doctor has explained the risk involved in this procedure and satisfied
								my queries.
							</span>
						</div>
						{memberDetails.map((data, i) => (
							<div class="specialisationNames">
								<span>Name : {data.memberFirstName} {data.memberLastName}</span>
								<span>Signature :</span>
							</div>))}
					</div>
					<span className='FooterPrintCasesheet'><FooterPrint /></span>
				</table>
			</div>
			<div class="printBtm" style={{ textAlign: 'center' }}>
				<p
					id="printButton"
					style={{
						width: '70px',
						textAlign: 'center',
						paddingTop: '5px',
						display: 'inline-block',
						fontWeight: 'bold',
						fontSize: '14px',
						color: 'white',
						background: 'rebeccapurple none repeat scroll 0% 0%',
						marginTop: '2px',
						cursor: 'pointer',
						borderRadius: '5px',
						marginBottom: '2px',
						height: '25px',
						fontFamily: '  Verdana',
						lineHeight: '15px',
					}}
					onClick={printCaseSheetDental}
				>
					Print
				</p>
			</div>
		</div>
	);
}
export default CasesheetDentalPrint;
