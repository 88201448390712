import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux"
import Header from '../layout/header';
import Footer from '../layout/footer';
import HomeleftMenu from '../Dashboard/homeleftMenu';
import Loader from '../Common/loader';
import doctorHome from '../images/media/doctorHome.jpg';
import Clinic from '../images/icons/clinic.png';
import hearts from '../images/icons/hearts.png';
import { Link } from 'react-router-dom';
import user from '../images/icons/avatar.png';
import { callingAPI } from '../config';
function Welcome() {
	const [healthInfoArrayDetails, setHealthInfoArrayDetails] = useState([])
	const [doctorMemberDetails, setDoctorMemberDetails] = useState([]);
	const [hospitalHomeScreenArray, setHospitalHomeScreenArray] = useState([])
	const [doctorImage, setDoctorImage] = useState([]);
	const [load, setLoad] = useState(false);
	const [doctorSpecialityDetails, setDoctorSpecialityDetails] = useState([]);
	const userId = useSelector(state => state.userId.value)
	var getS3folder =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_DOCTOR_PROFILE_S3_IMAGE_PATH;
	var getS3healthInfo =
		process.env.REACT_APP_S3_URI +
		process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
		process.env.REACT_APP_HEALTHINFO_PROFILE_S3_IMAGE_PATH;
	useEffect(() => {
		getDoctorDetails1()
		let PostData = {
			"pmSiteId": window.sessionStorage.getItem("siteId")
		};
		callingAPI("commonUserFunctions/hospitalHomescreen", PostData
		).then((data) => {
			if (data.data.success === "1") {
				setHospitalHomeScreenArray(data.data.result.hospitalHomescreenArraydetails[0].dashboardContent)
			}
		})
		// let PostData = {
		// 	"pmSiteId": window.sessionStorage.getItem("siteId"),

		// };
		// callingAPI("commonUserFunctions/doctorHomeScreen", PostData)
		// 	.then((data) => {
		// 		if (data.data.success === "1") {
		// 			setLoad(true)
		// 			setHealthInfoArrayDetails(data.data.result.healthInfoArraydetails)
		// 		}
		// 	})
			.catch()
	}, []);
	const getDoctorDetails1 = () => {

		const postData = {
			doctorId: window.sessionStorage.getItem("doctorId")
		};
		callingAPI('doctorProfile/getDoctorDetails', postData
		).then((res) => {
			setDoctorMemberDetails(res.data.result.memberDetails.length ? res.data.result.memberDetails[0] : [])
			window.sessionStorage.setItem('setDoctorImage', res.data.result.doctorImage.length > 0 ? res.data.result.doctorImage[0].doctorImageName : 'download.jpg')
			setDoctorImage(res.data.result.doctorImage.length > 0 ? res.data.result.doctorImage[0].doctorImageName : 'download.jpg');
			setDoctorSpecialityDetails(res.data.result.specialityDetails ? res.data.result.specialityDetails : [])
		})
			.catch()
	}
	return (
		<div>
			<div className="coverWraper innerCoverWraper DashboardWelcome">
				<Header />
				<div className="contentWraper contentWraperScroll">
					{/* {load === false ? <Loader /> : ''} */}
					<div className="container">
						<div className="row">
							<div className="col-md-4">
								<HomeleftMenu doctorImg={window.sessionStorage.getItem('setDoctorImage')} doctorName={doctorMemberDetails} specialityDetails={window.sessionStorage.getItem('specializationName')}></HomeleftMenu>
							</div>
							<div className="col-md-8">
							{hospitalHomeScreenArray ? (
									<div dangerouslySetInnerHTML={{ __html: hospitalHomeScreenArray }}></div>
								):<div style={{ textAlign: "center", color: "red", fontSize: "14px", padding: "15px" }}>Nothing to Display</div>}
								{/* <div className="landingBodyWrap doc blogVisitPage">
									<div class="landingHead">Bulletin Board</div>

									{healthInfoArrayDetails.map((index, i) => (
										<div class="" id="content" style={{ padding: '0 15px' }}>
											<div class="blogVisitBlock">
												<div class="blogVistAvtarBlock">
													<a href="#">
														{(index.doctorImage != null) &&
															<img class="avtarImg139496" src={getS3folder + "/" + index.doctorImage} />}

													</a>
												</div>
												<div class="blogVisitAvatarInfo">
													<span class="avtarname">
														<a href=" ">{index.healthIfoTitle}</a>
													</span>
												
													<span class="datetime">{index.healthInfoPostedDate}</span>
												</div>
												<div class="blogVisitbannerBlock">
													{(index.healthInfoProfileImage != null && "") &&
														<img src={getS3healthInfo + "/" + index.healthInfoProfileImage} />}

												</div>
												<div class="blogVisitHead">
													<Link to={{ pathname: "/Dashboard/healthInfoDetails", state: { data: index.healthInfoId } }} >{index.healthIfoTitle}</Link>
												</div>
												<div class="blogVisitPara">
													<Link to={{ pathname: "/Dashboard/healthInfoDetails", state: { data: index.healthInfoId } }} >{index.healthInfoShortDescription}</Link>
												</div>
												<div class="clearfix" />
												<div class="blogVisiIconsSet">
													<span class="blogVisitLike">
														<div class="iconTwo" style={{ display: 'inline - block' }}>
															<img title="Liked" style={{ cursor: 'default' }} src={hearts} />
														</div>
														<span class="nocursorPointer">
															<sup style={{ fontSize: '13px', color: "#333" }}>&nbsp;{index.userLiked}</sup>
														</span>
													</span>
													<span class="blogVisitFollow" />
												</div>
											</div>
										</div>
									))}
									{healthInfoArrayDetails.length == 0 && load === true ?
										<div style={{ textAlign: "center", color: "red", fontSize: "14px", padding: "15px" }}>Nothing to Display</div> : ''}
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
export default Welcome;
