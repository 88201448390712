import React, { useEffect, useState } from "react";
import { callingAPI } from "../config";
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import Loader from "../Common/loader";
import NoData from "../Common/noDataFound";
function MedicineReorder() {
  const [medicineListing, setMedicineListing] = useState([]);
  const [totalSellingUnitPrice, setTotalSellingUnitPrice] = useState("");
  const [totalSellingPrice, setTotalSellingPrice] = useState("");
  const [totalPurchaseUnitPrice, setTotalPurchaseUnitPrice] = useState("");
  const [totalPurchasePrice, setTotalPurchasePrice] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [searchType, setSearchType] = useState("0");
  const [searchValue, setSearchValue] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const [isDateEnabled, setIsDateEnabled] = useState(true);
  const [dropDown, setDropDown] = useState("0");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [activePage, setActivePage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  const [functionName] = useState("MedicineReorder");
  const [dataFlag, setDataFlag] = useState(false);
  useEffect(() => {
    let postData = {
      userId: window.sessionStorage.getItem("userId"),
      searchId: "",
      searchValue: "",
      searchStatus: "",
      fromDate: "",
      toDate: "",
      max: "10",
      offset: "0",
    };
    callingAPI("medicinePrescription/medicineReorder", postData)
      .then((response) => {
        if (response.data.success === "1") {
          setMedicineListing(response.data.result.medicineHistoryData);
          setTotalSellingUnitPrice(response.data.result.totalunitprice);
          setTotalSellingPrice(response.data.result.totalpricevalue);
          setTotalPurchaseUnitPrice(
            response.data.result.totalpurchaseunitprice
          );
          setTotalPurchasePrice(response.data.result.totalpurchaseprice);
          setTotalCount(response.data.result.totalCount);
          setDataFlag(true);
        } else {
          setDataFlag(true);
          setTotalCount([]);
          setMedicineListing([]);
        }
      })
      .catch();
  }, []);
  const searchTypeOnChange = (event) => {
    document.getElementById("searchText").focus();
    if (event.target.value == 0) {
      setIsEnabled(true);
      setSearchType(event.target.value);
      setSearchValue("");
      document.getElementById("searchText").focus();
    } else {
      document.getElementById("searchText").focus();
      setSearchType(event.target.value);
      setIsEnabled(false);
    }
  };
  const searchValueFunction = (event) => {
    setSearchValue(event.target.value);
    // getPrecriptionMedicineAutoComplete()
  };
  const changeTowards = (event) => {
    // setDropDown(event.target.value);
    // setIsDateEnabled(false);
    // setStartDate('');
    if (event.target.value == "0") {
      setIsDateEnabled(true);
      setDropDown(event.target.value);
      setStartDate("");
      setEndDate("");
      document.getElementById("fromdate").style.backgroundColor = "transparent";
      document.getElementById("todate").style.backgroundColor = "transparent";
    } else {
      setDropDown(event.target.value);
      setIsDateEnabled(false);
      document.getElementById("fromdate").style.backgroundColor = "WHITE";
      document.getElementById("todate").style.backgroundColor = "WHITE";
    }
  };
  const searchClickFunction = () => {
    if (startDate && endDate) {
      if (startDate > endDate) {
        alert("End date must be greater than start date");
        document.getElementById("todate").focus();
        return false;
      }
    }

    if (searchType !== 0 && searchValue.split("").length === 0) {
      if (searchType === "1") {
        alert("Enter Medicine Name");
        return false;
      }
    }
    // if(dropDown !== 0 && dropDown === 1){
    // 	alert('Egfhjkl');
    // 	return false;
    // }
    // else {
    // 	alert("podaaaaaaaaaaa")
    // 	return false;
    // }

    if (dropDown === "1" && document.getElementById("fromdate").value === "") {
      alert("Enter stock update from date");
      return false;
    } else if (
      dropDown === "1" &&
      document.getElementById("todate").value === ""
    ) {
      alert("Enter stock update to date");
      return false;
    }

    if (dropDown === "2" && document.getElementById("fromdate").value === "") {
      alert("Enter expiry from date");
      return false;
    } else if (
      dropDown === "2" &&
      document.getElementById("todate").value === ""
    ) {
      alert("Enter expiry to date");
      return false;
    }

    setActivePage(1);
    setbooliansearchValue(searchValue);
    setboolianfilterName(searchType);
    setboolianfromDate(startDate);
    setbooliantoDate(endDate);
    const postData = {
      userId: window.sessionStorage.getItem("userId"),
      searchId: searchType,
      searchValue: searchValue,
      searchStatus: dropDown,
      fromDate: startDate ? formatDate(startDate) : "",
      toDate: endDate ? formatDate(endDate) : "",
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: "0",
    };
    callingAPI("medicinePrescription/medicineReorder", postData)
      .then((response) => {
        if (response.data.success === "0") {
          setMedicineListing([]);
          setTotalCount(0);
          setTotalSellingUnitPrice(0);
          setTotalPurchaseUnitPrice(0);
          setTotalPurchasePrice(0);
          setTotalSellingPrice(0);
        }
        if (response.data.success === "1") {
          setMedicineListing(response.data.result.medicineHistoryData);
          setTotalSellingUnitPrice(response.data.result.totalunitprice);
          setTotalSellingPrice(response.data.result.totalpricevalue);
          setTotalPurchaseUnitPrice(
            response.data.result.totalpurchaseunitprice
          );
          setTotalPurchasePrice(response.data.result.totalpurchaseprice);
          setTotalCount(response.data.result.totalCount);
          //  setDataFlag(true);
        }
      })
      .catch();
  };

  const handlePagination = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) {
      setSearchType("");
    }
    if (!booliansearchValue) {
      setSearchValue("");
    }
    if (!boolianfromDate) {
      setStartDate("");
    }
    if (!booliantoDate) {
      setEndDate("");
    }
    let postData = {
      userId: window.sessionStorage.getItem("userId"),
      searchId: boolianfilterName,
      searchValue: booliansearchValue,
      searchStatus: dropDown,
      fromDate: boolianfromDate ? formatDate(startDate) : "",
      toDate: booliantoDate ? formatDate(endDate) : "",
      max: max,
      offset: offset,
    };
    callingAPI("medicinePrescription/medicineReorder", postData)
      .then((response) => {
        if (response.data.success === "1") {
          setMedicineListing(response.data.result.medicineHistoryData);
          setTotalSellingUnitPrice(response.data.result.totalunitprice);
          setTotalSellingPrice(response.data.result.totalpricevalue);
          setTotalPurchaseUnitPrice(
            response.data.result.totalpurchaseunitprice
          );
          setTotalPurchasePrice(response.data.result.totalpurchaseprice);
          setTotalCount(response.data.result.totalCount);
          // setDataFlag(true);
        }
      })
      .catch();
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  function exportFunction() {
    const csvRow = [];
    var medicineListingArray = [
      [
        "Medicine%20Name",
        "Total%20Available%20Quantity",
        "Batch%20Quantity",
        "Available%20Quantity",
        "Expiry%20Date",
        "Selling%20Price",
        "Selling%20Unit%20Price",
        "Medicine%20Purchase%20Price",
        "Medicine%20Purchase%20Unit%20Price",
        "Ordered%20Date",
      ],
    ];
    var arrayDetails = medicineListing;;
    const postData = {
      userId: window.sessionStorage.getItem("userId"),
      searchId: "",
      searchValue: "",
      searchStatus: "",
      fromDate: "",
      toDate: "",
      max: "",
      offset: "0",
    };
    callingAPI("medicinePrescription/medicineReorder", postData).then((response) => {
      if (response.data.success === '0') {
        alert("No Data to Export")
      }

      if (response.data.success === '1') {
        for (var item = 0; item < response.data.result.medicineHistoryData.length; item++) {
          for (var j = 0; j < response.data.result.medicineHistoryData[item].sellingPrice.length; j++) {

            let batch = "%20"
            if (typeof response.data.result.medicineHistoryData[item].batch[j] !== "undefined") {
              batch = response.data.result.medicineHistoryData[item].batch[j]
            }
            let availableQuantity = "%20"
            if (typeof response.data.result.medicineHistoryData[item].availableQuantity[j].availableQuantity !== "undefined") {
              availableQuantity = response.data.result.medicineHistoryData[item].availableQuantity[j].availableQuantity

            }
            let expiryDate = "%20"
            if (typeof response.data.result.medicineHistoryData[item].expiryDate[j].expiryDate !== "undefined") {
              expiryDate = response.data.result.medicineHistoryData[item].expiryDate[j].expiryDate.replace(/ /g, "%20")

            }
            let sellingPrice = "%20"
            if (typeof response.data.result.medicineHistoryData[item].sellingPrice[j] !== "undefined") {
              sellingPrice = response.data.result.medicineHistoryData[item].sellingPrice[j]

            }
            let sellingUnitPrice = "%20"
            if (typeof response.data.result.medicineHistoryData[item].sellingUnitPrice[j] !== "undefined") {
              sellingUnitPrice = response.data.result.medicineHistoryData[item].sellingUnitPrice[j]

            }
            let purchasePrice = "%20"
            if (typeof response.data.result.medicineHistoryData[item].purchasePrice[j] !== "undefined") {
              purchasePrice = response.data.result.medicineHistoryData[item].purchasePrice[j]

            }
            let purchaseUnitPrice = "%20"
            if (typeof response.data.result.medicineHistoryData[item].purchaseUnitPrice[j] !== "undefined") {
              purchaseUnitPrice = response.data.result.medicineHistoryData[item].purchaseUnitPrice[j]

            }

            let ordreDate = "%20"
            if (typeof response.data.result.medicineHistoryData[item].ordreDate[j] !== "undefined") {
              ordreDate = response.data.result.medicineHistoryData[item].ordreDate[j].replace(/,/g, "%20").replace(/ /g, "%20")
            }

            let medicineName = (response.data.result.medicineHistoryData[item].medicineName.trim() === "" ? "%20" : response.data.result.medicineHistoryData[item].medicineName)
            if (j == 0) {
              medicineListingArray.push([
                medicineName,
                response.data.result.medicineHistoryData[item].totalAvailableQuantity,
                batch,
                availableQuantity,
                expiryDate,
                sellingPrice,
                sellingUnitPrice,
                purchasePrice,
                purchaseUnitPrice,
                ordreDate,
              ]);
            }
            else {
              let batch = "%20"
              if (typeof response.data.result.medicineHistoryData[item].batch[j] !== "undefined") {
                batch = response.data.result.medicineHistoryData[item].batch[j]

              }
              let availableQuantity = "%20"
              if (typeof response.data.result.medicineHistoryData[item].availableQuantity[j].availableQuantity !== "undefined") {
                availableQuantity = response.data.result.medicineHistoryData[item].availableQuantity[j].availableQuantity

              }
              let expiryDate = "%20"
              if (typeof response.data.result.medicineHistoryData[item].expiryDate[j].expiryDate !== "undefined") {
                expiryDate = response.data.result.medicineHistoryData[item].expiryDate[j].expiryDate.replace(/ /g, "%20")

              }
              let sellingPrice = "%20"
              if (typeof response.data.result.medicineHistoryData[item].sellingPrice[j] !== "undefined") {
                sellingPrice = response.data.result.medicineHistoryData[item].sellingPrice[j]

              }
              let sellingUnitPrice = "%20"
              if (typeof response.data.result.medicineHistoryData[item].sellingUnitPrice[j] !== "undefined") {
                sellingUnitPrice = response.data.result.medicineHistoryData[item].sellingUnitPrice[j]

              }
              let purchasePrice = "%20"
              if (typeof response.data.result.medicineHistoryData[item].purchasePrice[j] !== "undefined") {
                purchasePrice = response.data.result.medicineHistoryData[item].purchasePrice[j]

              }
              let purchaseUnitPrice = "%20"
              if (typeof response.data.result.medicineHistoryData[item].purchaseUnitPrice[j] !== "undefined") {
                purchaseUnitPrice = response.data.result.medicineHistoryData[item].purchaseUnitPrice[j]

              }
              let ordreDate = "%20"
              if (typeof response.data.result.medicineHistoryData[item].ordreDate[j] !== "undefined") {
                ordreDate = response.data.result.medicineHistoryData[item].ordreDate[j].replace(/,/g, "%20").replace(/ /g, "%20")
              }

              medicineListingArray.push([
                "%20",
                "%20",
                batch,
                availableQuantity,
                expiryDate,
                sellingPrice,
                sellingUnitPrice,
                purchasePrice,
                purchaseUnitPrice,
                ordreDate,
              ]);
            }
          }
        }
      }


      for (var i = 0; i < medicineListingArray.length; ++i) {
        csvRow.push(medicineListingArray[i].join("|"));
      }
      var csvString = csvRow.join("%0A");
      var a = document.createElement("a");
      a.href = "data:attachment/csv, " + csvString;
      a.target = "_Blank";
      a.download = functionName + ".csv";
      document.body.appendChild(a);
      a.click();
    }
    );
  };


  //           availableQuantity = []
  //         response.data.result[item].availableQuantity.map((datavalue, i) => (
  //           availableQuantity.push(datavalue.availableQuantity)
  //         ))
  //         expiryDate = []
  // 					response.data.result[item].expiryDate.map((datavalue, i) => (
  // 						expiryDate.push(datavalue.expiryDate)
  // 					))
  //         sellingPrice = []
  // 					response.data.result[item].sellingPrice.map((datavalue, i) => (
  // 						sellingPrice.push(datavalue.sellingPrice)
  // 					))
  //         sellingUnitPrice = []
  // 					response.data.result[item].sellingUnitPrice.map((datavalue, i) => (
  // 						sellingUnitPrice.push(datavalue.sellingUnitPrice)
  // 					))
  //         purchasePrice = []
  // 					response.data.result[item].purchasePrice.map((datavalue, i) => (
  // 						purchasePrice.push(datavalue.purchasePrice)
  //           ))
  //         purchaseUnitPrice = []
  // 					response.data.result[item].purchaseUnitPrice.map((datavalue, i) => (
  // 						purchaseUnitPrice.push(datavalue.purchaseUnitPrice)
  //           ))
  //         ordreDate = []
  //           response.data.result[item].ordreDate.map((datavalue, i) => (
  //             ordreDate.push(datavalue.ordreDate)
  //         ))
  //         medicineListingArray.push([
  //           response.data.result.medicineHistoryData[item].medicinename,
  //           response.data.result.medicineHistoryData[item].totalAvailableQuantity,
  //           response.data.result.medicineHistoryData[item].batch,
  //         ]);
  //          }
  //       for (var i = 0; i < medicineListingArray.length; ++i) {
  //         csvRow.push(medicineListingArray[i].join("|"));
  //       }}
  //       var csvString = csvRow.join('%0A');
  //       var csvClick = document.createElement('csvClick');
  //       csvClick.href = 'data:attachment/csv,' + csvString;
  //       csvClick.target = '_Blank';
  //       csvClick.download = functionName + '.csv';
  //       document.body.appendChild(csvClick);
  //       csvClick.click();
  // }})
  // };

  // function csvFileDownload() {
  // 	const csvRow = [];
  // 	var MedicalhistoryList = [
  // 		[
  // 			'Medicine%20Name',
  // 			'Batch%20Number',
  // 			'Ordered%20Stock%20Qty',
  // 			'Available%20Qty',
  // 			'Purchase%20Date	',
  // 			'Expiry%20Date',
  // 			'Selling%20Price',
  // 			],
  // 	];
  // 	var name = Medicalhistory;
  // 	const PostData = {
  // 		userId: window.sessionStorage.getItem('userId'),
  // 		searchStatus: searchstatus,
  // 		offset: 0,
  // 		max: "",
  // 		searchId: filterName,
  // 		searchValue: searchValue,
  // 		fromDate: fromDate ? formatDate(fromDate) : '',
  // 		toDate: toDate ? formatDate(toDate) : '',
  // 	};

  // 	callingAPI('medicinePrescription/hospitalMedicalHistory', PostData)
  // 		.then((response) => {
  // 			var availableQuantity = []
  // 			var expiryDate = []
  // 			var purchaseDate = []
  // 			for (var item = 0; item < response.data.result.length; item++) {
  // 				expiryDate = []

  // 				response.data.result[item].expiryDate.map((datavalue, i) => (
  // 						expiryDate.push(datavalue.expiryDate)
  // 					))
  // 				purchaseDate = []
  // 					response.data.result[item].purchaseDate.map((datavalue, i) => (
  // 						purchaseDate.push(datavalue)
  // 					))
  // 				availableQuantity = []
  // 					response.data.result[item].availableQuantity.map((datavalue, i) => (
  // 						availableQuantity.push(datavalue.availableQuantity)

  // 					))
  // 				MedicalhistoryList.push([
  // 					response.data.result[item].medicineName,
  // 					response.data.result[item].bantchNumber,
  // 					response.data.result[item].orderStockQuantity,
  // 					availableQuantity,
  // 					purchaseDate,
  // 					expiryDate,
  // 					"Rs" + response.data.result[item].sellingPrice,
  // 				]);
  // 			}
  // 			for (var i = 0; i < MedicalhistoryList.length; ++i) {
  // 				csvRow.push(MedicalhistoryList[i].join(','));
  // 			}
  // 			var csvString = csvRow.join('%0A');
  // 			var create = document.createElement('a');
  // 			create.href = 'data:attachment/csv,' + csvString;
  // 			create.download = csvDownload + '.csv';
  // 			document.body.appendChild(create);
  // 			create.click();
  // 		})
  // }



  const FromDateClear = () => {
    setStartDate("");
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const ToDateClear = () => {
    setEndDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <div className="row">
        <div className="col-md-8">
          <div className="dashboardTitle"> Medicine Reorder</div>
        </div>
      </div>
      {totalCount > 10 && (
        <div className="paginationSection">
          <Pagination
            // itemsCountPerPage={2}
            // totalItemsCount={5}
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
            totalItemsCount={totalCount}
            pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
            onChange={handlePagination}
            style={{ margin: "0px" }}
          />
        </div>
      )}
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch">
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectName"
                  name="selectName"
                  value={searchType}
                  onChange={searchTypeOnChange}
                >
                  <option id="opt1" value="0">
                    Search In
                  </option>
                  <option id="opt2" value="1">
                    Medicine
                  </option>
                </select>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  className="inputField"
                  id="searchText"
                  name="searchText"
                  value={searchValue}
                  disabled={isEnabled}
                  onChange={searchValueFunction}
                  style={{
                    background: searchType === "0" ? "transparent" : "white",
                  }}
                  autoFocus
                />

                {/* <ul
												className="autoSearch autoSearch-p0 appointmentNumberSearch"
												id="prescriptionMedicineList"
												style={{ display: 'none' }}
											>
												{drugListingArr.map((item, i) => (
													<li
														onClick={() => setMedicineName(item.medicineName)}
													>
														{item.medicineName}
													</li>
												))}
											</ul> */}
                {/* <input type="hidden" id="hiddenPrescriptionMedicineId" /> */}
              </div>
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectName"
                  name="selectName"
                  value={dropDown}
                  onChange={changeTowards}
                >
                  <option value="0">Search In</option>
                  <option value="1">Stock Update</option>
                  <option value="2">Expiry Date</option>
                </select>
              </div>

              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    disabled={isDateEnabled}
                    required
                    className="disabledField"
                    placeholderText="From Date"
                    calendarClassName="rasta-stripes"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd-MM-yyyy"
                    id="fromdate"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>

              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    disabled={isDateEnabled}
                    required
                    className="disabledField"
                    placeholderText="To Date"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd-MM-yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    id="todate"
                    dropdownMode="select"
                    autoComplete="off"
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>

              <div className="col-md-1 dashboardSearchList">
                <span
                  onClick={searchClickFunction}
                  className="dashButtonBorder"
                  style={{ border: "1px solid gainsboro", padding: "6px 5px" }}
                >
                  Search
                </span>
              </div>
              <div className="col-md-1 dashboardSearchList">
                <span
                  onClick={exportFunction}
                  className="dashButtonBorder dashButtonBorderGrey"
                  style={{ border: "1px solid gainsboro", padding: "6px 5px" }}
                >
                  Export
                </span>
              </div>
            </div>
            <div className="row  ">
              <div className="col-md-5">
                <div className="searchTxt">
                  Total Selling Unit Price : &#8377; {totalSellingUnitPrice}
                </div>
              </div>
              <div className="col-md-4">
                <div className="searchTxt">
                  Total Selling Price : &#8377; {totalSellingPrice}
                </div>
              </div>

              <div className="col-md-3">
                <div className="searchCount" style={{ margin: "0px" }}>
                  Total Medicines : {totalCount}
                </div>
              </div>
              <div className="col-md-5">
                <div className="searchTxt">
                  Total Purchase Price : &#8377; {totalPurchasePrice}
                </div>
              </div>
              <div className="col-md-4">
                <div className="searchTxt">
                  Total Purchase Unit Price : &#8377; {totalPurchaseUnitPrice}
                </div>
              </div>
            </div>
          </div>
          {dataFlag == false ? (
            <Loader />
          ) : medicineListing.length > 0 ? (
            <div className="dashboardtable">
              <table>
                <thead>
                  <tr>
                    <th>Medicine Name </th>
                    <th>Total Available Quantity </th>
                    <th>Batch Number</th>
                    <th>Available Quantity</th>
                    <th style={{ width: "100px" }}>Expiry Date</th>
                    <th style={{ textAlign: "right", width: "100px" }}>
                      Selling Price
                    </th>
                    <th style={{ textAlign: "right", width: "100px" }}>
                      Selling Unit Price
                    </th>
                    <th style={{ textAlign: "right", width: "100px" }}>
                      Purchase Price
                    </th>
                    <th style={{ textAlign: "right", width: "100px" }}>
                      Purchase Unit Price
                    </th>
                    <th style={{ width: "150px", textAlign: "right" }}>
                      Ordered Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {medicineListing.map((data, i) => (
                    <tr>
                      <td>{data.medicineName}</td>
                      <td>{data.totalAvailableQuantity}</td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: data.batch.join("<br/><br/>"),
                        }}
                      ></td>
                      <td>
                        {data.availableQuantity.map((value, i) => (
                          <>
                            {value.hasStock == 0 ? (
                              <>
                                <span style={{ color: "red" }}>
                                  {value.availableQuantity}
                                </span>{" "}
                                <br />
                                <br />
                              </>
                            ) : (
                              <>
                                <span>{value.availableQuantity}</span>
                                <br />
                                <br />
                              </>
                            )}
                          </>
                        ))}
                      </td>
                      <td>
                        {data.expiryDate.map((value, i) => (
                          <>
                            {value.hasExpiry == 0 ? (
                              <>
                                <span style={{ color: "red" }}>
                                  {value.expiryDate}
                                </span>
                              </>
                            ) : (
                              <span>{value.expiryDate}</span>
                            )}
                            <br />
                            <br />
                          </>
                        ))}
                      </td>
                      <td
                        style={{ textAlign: "right" }}
                        dangerouslySetInnerHTML={{
                          __html: data.sellingPrice.join("<br/><br/>"),
                        }}
                      ></td>
                      <td
                        style={{ textAlign: "right" }}
                        dangerouslySetInnerHTML={{
                          __html: data.sellingUnitPrice.join("<br/><br/>"),
                        }}
                      ></td>
                      <td
                        style={{ textAlign: "right" }}
                        dangerouslySetInnerHTML={{
                          __html: data.purchasePrice.join("<br/><br/>"),
                        }}
                      ></td>
                      <td
                        style={{ textAlign: "right" }}
                        dangerouslySetInnerHTML={{
                          __html: data.purchaseUnitPrice.join("<br/><br/>"),
                        }}
                      ></td>
                      <td
                        style={{ textAlign: "right" }}
                        dangerouslySetInnerHTML={{
                          __html: data.ordreDate.join("<br/><br/>"),
                        }}
                      ></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>
              <NoData />
            </p>
          )}
        </div>
        {totalCount > 10 && (
          <div className="paginationSection">
            <Pagination
              // itemsCountPerPage={2}
              // totalItemsCount={5}
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={totalCount}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={handlePagination}
              style={{ margin: "0px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MedicineReorder;
