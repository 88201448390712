import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
// import DatePicker, { CalendarContainer } from "react-datepicker";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { callingAPI } from '../config';
import 'react-tabs/style/react-tabs.css';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
function GenericMedicineList() {
  const [functionName, setFunctionName] = useState('GenericMedicineDetails');
  const [GenericMedicineDetails, setGenericMedicineDetails] = useState([]);
  const [count, setTotalCount] = useState();
  const [filterName, setFilterInput] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [isData, setIsData] = useState(0);
  const handlePagination = (pageNumber) => {
    window.sessionStorage.getItem("offset")
      setActivePage(pageNumber);
      var offset;
      var max;
      if (pageNumber === '1') {
          offset = '0';
          max = process.env.REACT_APP_PAGINATION_COUNT;
      } else {
          offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
          max = process.env.REACT_APP_PAGINATION_COUNT;
      }
      setActivePage(pageNumber);
      // setPageOffset(offset)
      let PostData = {
          userId: window.sessionStorage.getItem("userId"),
          role: window.sessionStorage.getItem("role"),
          max: max,
          offset: offset,
          type: window.sessionStorage.getItem("handlePagination"),
          cmbSelect: document.querySelector('#selectName').value,
          textInput: document.querySelector('#searchText').value,
      };
      callingAPI('medicine/genericMedicineList', PostData)
          .then((response) => {
              if (response.data.success === '1') {
                  setGenericMedicineDetails(response.data.result.GenericMedicineDetails);
                  setTotalCount(response.data.result.count);
                  setIsData(1);
              }
          })
          .catch();
  };
  const changeText = () => {
      setFilterInput(document.querySelector('#selectName').value)
      if (document.querySelector('#selectName').value !== '0') {
          document.getElementById('searchText').disabled = false;
          document.querySelector('input[name=searchText]').focus();
      } else {
          document.getElementById('searchText').disabled = true;
      }
      document.getElementById('searchText').value = '';
  };
  const searchValueChange = () => {
      if (document.querySelector('#selectName').value !== '0' && document.querySelector('#searchText').value === '') {
          var t = document.getElementById('selectName');
          var selectedText = t.options[t.selectedIndex].text;
          // alert(selectedText + ' Required');
          alert("Enter" + " " + selectedText)
          document.querySelector('#searchText').focus();
          return false;
      }
      setActivePage(1)
      let PostData = {
        userId: window.sessionStorage.getItem("userId"),
        role: window.sessionStorage.getItem("role"),
        max: process.env.REACT_APP_PAGINATION_COUNT,
        offset: 0,
        type: "generic",
        cmbSelect: document.querySelector('#selectName').value,
        textInput: document.querySelector('#searchText').value,
      };
      callingAPI('medicine/genericMedicineList', PostData)
          .then((response) => {
              if (response.data.success === '0') {
                setGenericMedicineDetails([]);
                  setIsData(1);
              }
              if (response.data.success === '1') {
                setGenericMedicineDetails(response.data.result.GenericMedicineDetails);
                setTotalCount(response.data.result.count);
                setIsData(1);
              }
          })
          .catch();
  };
  const getGenericMedicineGeneric = () => {
    window.sessionStorage.setItem("handlePagination", "generic")
    setActivePage(1)
    const PostData = {
        userId: window.sessionStorage.getItem("userId"),
        role: window.sessionStorage.getItem("role"),
        max: process.env.REACT_APP_PAGINATION_COUNT,
        offset: 0,
        type:"generic"
    };

    callingAPI('medicine/genericMedicineList', PostData)
        .then((response) => {
          
            if (response.data.success === '1') {
              setGenericMedicineDetails(response.data.result.GenericMedicineDetails);
              setTotalCount(response.data.result.count);
              setIsData(1);
            }
            else {
                setIsData(1);
                setGenericMedicineDetails([]);
            
            }
        })
        .catch();
    setIsData(1);
      }
  useEffect(() => {
    getGenericMedicineGeneric()
      changeText();
    // getGenericMedicineLow()
      // changeText2();    
  }, []);
  
  function csvFileDownload() {
    const csvRow = [];
    var fileDownloadArray = [
        ["Generic%20Name", "Brand%20Name", "Batch%20No", "Unit%20Price", "Price"]
    ];
    var arrayDetails = GenericMedicineDetails;
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: "",
      offset: 0,
      type: "generic",
      cmbSelect: document.querySelector('#selectName').value,
      textInput: document.querySelector('#searchText').value
    };
    callingAPI('medicine/genericMedicineList', PostData)
        .then((response) => {
            for (var j = 0; j < response.data.result.GenericMedicineDetails.length; j++) {
                fileDownloadArray.push([    
                  response.data.result.GenericMedicineDetails[j].genre.replace(/ /g, '%20'),
                  response.data.result.GenericMedicineDetails[j].medicine.replace(/ /g, '%20'),
                  response.data.result.GenericMedicineDetails[j].batchNo.replace(/ /g, '%20'),
                  response.data.result.GenericMedicineDetails[j].unitPrice.replace(/,/g, '%20'),
                  response.data.result.GenericMedicineDetails[j].price
                ]);
            }
            for (var i = 0; i < fileDownloadArray.length; ++i) {
                csvRow.push(fileDownloadArray[i].join('|'));
            }
            var csvString = csvRow.join("%0A");
            var a = document.createElement("a");
            a.href = "data:attachment/csv," + csvString;
            a.target = "_Blank";
            a.download = functionName + ".csv";
            document.body.appendChild(a);
            a.click();
        })
}
//////////////////////////////////////////////// medicine type=low ///////////////////////////////////////////////////////////

const changeText2 = () => {
  setFilterInput(document.querySelector('#selectName').value)
  if (document.querySelector('#selectName').value !== '0') {
      document.getElementById('searchText').disabled = false;
      document.querySelector('input[name=searchText]').focus();
  } else {
      document.getElementById('searchText').disabled = true;
  }
  document.getElementById('searchText').value = '';
};
const searchValueChange2 = () => {
  if (document.querySelector('#selectName').value !== '0' && document.querySelector('#searchText').value === '') {
      var t = document.getElementById('selectName');
      var selectedText = t.options[t.selectedIndex].text;
      // alert(selectedText + ' Required');
      alert("Enter" + " " + selectedText)
      document.querySelector('#searchText').focus();
      return false;
  }
  setActivePage(1)
  let PostData = {
    userId: window.sessionStorage.getItem("userId"),
    role: window.sessionStorage.getItem("role"),
    max: process.env.REACT_APP_PAGINATION_COUNT,
    offset: 0,
    type: "low",
    cmbSelect: document.querySelector('#selectName').value,
    textInput: document.querySelector('#searchText').value,
  };
  callingAPI('medicine/genericMedicineList', PostData)
      .then((response) => {
          if (response.data.success === '0') {
            setGenericMedicineDetails([]);
              setIsData(1);
          }
          if (response.data.success === '1') {
            setGenericMedicineDetails(response.data.result.GenericMedicineDetails);
            setTotalCount(response.data.result.count);
            setIsData(1);
          }
      })
      .catch();
};
// useEffect(() => {
   const getGenericMedicineLow = () => {
    // window.sessionStorage.setItem("offset", 0 );
    window.sessionStorage.setItem("handlePagination", "low")
    window.sessionStorage.setItem("offset",0)
    setActivePage(1)
    
  const PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: '0',
      type:"low"
  };

  callingAPI('medicine/genericMedicineList', PostData)
      .then((response) => {
          if (response.data.success === '1') {
            setGenericMedicineDetails(response.data.result.GenericMedicineDetails);
            setTotalCount(response.data.result.count);
            setIsData(1);
          }
          else {
              setIsData(1);
              setGenericMedicineDetails([]);

          }
          
      })
      .catch();
  setIsData(1);
    }
// }, []);
function csvFileDownload2() {
const csvRow = [];
var fileDownloadArray = [
    ['Generic%20Name:', 'Brand%20Name', 'Batch%20No', 'Unit%20Price', 'Price'],
];
var arrayDetails = GenericMedicineDetails;
let PostData = {
  userId: window.sessionStorage.getItem("userId"),
  role: window.sessionStorage.getItem("role"),
  max: "",
  offset: 0,
  type: "low",
  cmbSelect: document.querySelector('#selectName').value,
  textInput: document.querySelector('#searchText').value
};
callingAPI('medicine/genericMedicineList', PostData)
    .then((response) => {
        for (var j = 0; j < response.data.result.GenericMedicineDetails.length; j++) {
            fileDownloadArray.push([    
              response.data.result.GenericMedicineDetails[j].genre.replace(/ /g, '%20'),
              response.data.result.GenericMedicineDetails[j].medicine.replace(/ /g, '%20'),
              response.data.result.GenericMedicineDetails[j].batchNo.replace(/ /g, '%20'),
              response.data.result.GenericMedicineDetails[j].unitPrice.replace(/,/g, '%20'),
              response.data.result.GenericMedicineDetails[j].price
            ]);
        }
        for (var i = 0; i < fileDownloadArray.length; ++i) {
            csvRow.push(fileDownloadArray[i].join('|'));
        }
        var csvString = csvRow.join("%0A");
        var a = document.createElement("a");
        a.href = "data:attachment/csv," + csvString;
        a.target = "_Blank";
        a.download = functionName + ".csv";
        document.body.appendChild(a);
        a.click();
    })
}



  return (
    <div className="dashboardCover patientDetailsTable genericMedicineWrap" id="dashboardCover">
      <div className="dashboardTitle">Generic Medicine List</div>
      <div>
        <div className="dashboardContent">
        {count > 10 && (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePagination}
            hideDisabled
					/>
				</div>
			)}
          <Tabs>
            <TabList>             
              <Tab> 
                <div className="tabHead"  onClick={() => getGenericMedicineGeneric()}> All Medicine List</div>
                 </Tab>
              <Tab>
                 <div className="tabHead" onClick={() => getGenericMedicineLow()} > Lowest Price List</div> 
                 
                 </Tab>
            </TabList>
            <TabPanel>
              <div className="reportWrap">
                <div className="dashboardSearch">
                  <div className="row">

                    <div className="col-md-2 dashboardSearchList">
                    <select className="inputField" id="selectName" name="selectName" title="Search In"
                                    onChange={changeText}>
                        <option value="0">Search In</option>
                        <option value="1">Generic Name</option>
                        <option value="2">Brand Name</option>
                      </select>
                    </div>

                    <div className="col-md-2 dashboardSearchList">
                    {filterName == 0 ?
                                    <input type="text" className="inputField" id="searchText" name="searchText" style={{ background: "transparent" }} />
                                   : <input type="text" className="inputField" id="searchText" name="searchText" />}
                      </div>
                    <div className="col-md-2 dashboardSearchList">
                      <span
                        className="dashButtonBorder"
                        onClick={searchValueChange}
                         title="search"
                      >
                        Search
                      </span>
                    </div>

                    <div className="col-md-2 dashboardSearchList">
                      <button
                        type="button"
                        style={{ width: "100%" }}
                        id="export"
                        className="dashButtonBorder dashButtonBorderGrey"
                        onClick={csvFileDownload}
                      >
                        Export
                      </button>
                    </div>


                  </div>
                  <div className="searchCount">Total Count : {GenericMedicineDetails.length == 0 ? 0 : count}</div>
                </div>
                <div className="dashboardtable">
                {isData == 0 ? (<Loader />
                        ) : GenericMedicineDetails.length > 0 ?
                  <table>

                    <thead>
                      <tr>
                        <th>Generic Name</th>
                        <th >Brand Name</th>
                        <th>Batch No</th>
                        <th >Unit Price</th>
                        <th>Price</th>

                      </tr>
                    </thead>

                    <tbody>
                    {GenericMedicineDetails.map((data, i) => (
                      <tr>                        
                        <td dangerouslySetInnerHTML={{ __html: data.genre }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.medicine }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.batchNo }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.unitPrice }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.price }}></td>                     
                      </tr>
                       ))}
                    </tbody>
                  </table>
                  : <NoData />}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="reportWrap">
                <div className="dashboardSearch">
                  <div className="row">

                    <div className="col-md-2 dashboardSearchList">                    
                        <select className="inputField" id="selectName" name="selectName" title="Search In"
                        onChange={changeText2}
                        >                        
                        <option value="0">Search In</option>
                        <option value="1">Generic Name</option>
                        <option value="2">Brand Name</option>
                      </select>
                    </div>

                    <div className="col-md-2 dashboardSearchList">

                    {filterName == 0 ?
                                    <input type="text" className="inputField" id="searchText" name="searchText" style={{ background: "transparent" }} />
                                   : <input type="text" className="inputField" id="searchText" name="searchText" />}
                      </div>
                    <div className="col-md-2 dashboardSearchList">
                    <span
                        className="dashButtonBorder"
                        onClick={searchValueChange2}
                         title="search"
                      >
                        Search
                      </span>
                    </div>

                    <div className="col-md-2 dashboardSearchList">
                      <button
                        type="button"
                        style={{ width: "100%" }}
                        id="export"
                        className="dashButtonBorder dashButtonBorderGrey"
                        onClick={csvFileDownload2}
                      >
                        Export
                      </button>
                    </div>


                  </div>
                  <div className="searchCount">Total Count : {GenericMedicineDetails.length == 0 ? 0 : count}</div>
                </div>
                <div className="dashboardtable">
                {isData == 0 ? (<Loader />
                        ) : GenericMedicineDetails.length > 0 ?
                  <table>

                    <thead>
                      <tr>
                        <th>Generic Name</th>
                        <th >Brand Name</th>
                        <th>Batch No</th>
                        <th >Unit Price</th>
                        <th>Price</th>

                      </tr>
                    </thead>

                    <tbody>
                    {GenericMedicineDetails.map((data, i) => (
                      <tr>                          
                        <td dangerouslySetInnerHTML={{ __html: data.genre }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.medicine }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.batchNo }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.unitPrice }}></td>
                        <td dangerouslySetInnerHTML={{ __html: data.price }}></td>                        
                      </tr>
                       ))}
                    </tbody>
                  </table>
                  : <NoData />}
                </div>
              </div>
            </TabPanel>
          </Tabs>
          {count > 10 && (
					<div className="paginationSection">
						<Pagination
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={count}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePagination}
              hideDisabled
						/>
					</div>
				)}
        </div>
      </div>
    </div>
  );
}
export default GenericMedicineList;
