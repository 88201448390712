// import React from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Printer_icon from '../images/icons/printer_icon.png';
import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Editicon from '../images/icons/edit-bg.png';
import Print from '../images/icons/printer_icon.png';
import Loader from '../Common/loader';
import NoData from '../Common/noDataFound';
import { Link } from 'react-router-dom';
import Delete from '../images/icons/delete_btn.png';
function TestResult() {
	// const [ testValObj , setTestValObj ] = useState([]);
	const [testValueArr, setTestValueArr] = useState([]);
	const [loader, setLoader] = useState(true);
	const [userId, setUserId] = useState(window.sessionStorage.getItem('userId'));
	const [hospitalId, setHospitalId] = useState(localStorage.getItem("hospitalId"));
	const [memberId, setMemberId] = useState(window.sessionStorage.getItem('testresultmemebrid'));
	const [billingWrap, setbillingWrap] = useState('');
	var phoneno = /^[0-9]+$/;
	var num = /^\d{10}$/;
	useEffect(() => {
		getTestResult()
		if (window.sessionStorage.getItem('role') === 'ROLE_BILLINGADMIN') {
			setbillingWrap('billingAdminWrap ')
		} else {
			setbillingWrap('')
		}
	}, []);


	const getTestResult = () => {

		const PostData = {
			memberId: memberId,
			hospitalId: hospitalId
		};

		callingAPI('patientsListing/testResult', PostData)
			.then((data) => {
				setLoader(false)
				setTestValueArr(data.data.result.testGroupData);
			})
			.catch();
	}
	const printAll = (item, name) => {
		let data = []
		if (name === "PrintAll") {
			for (let i = 0; i < item.labData.length; i++) {
				data.push(item.labData[i].labDetails[0].labOrderTestId)
			}
			window.sessionStorage.setItem("labOrderPrint", data)
			window.open('/patients/patientTestResultPrint', '_blank');
		} else {
			data.push(item.labDetails[0].labOrderTestId)
			window.sessionStorage.setItem("labOrderPrint", data)
			window.open('/patients/patientTestResultPrint', '_blank');
		}
	}
	const deleteResult = (data) => {
		setLoader(true)
		const PostData = {
			doctorVisitLabOrderTestId: data.labDetails[0].labOrderTestId,
		};
		callingAPI('patientsListing/deleteTestResultById', PostData)
			.then((data) => {
				getTestResult()
			})
			.catch((err) => {
				setLoader(false)

			});
	}
	return (
		<div className="coverWraper">
			<Header />
			<div className={"contentWraper contentWraperScroll testResultWrappp" + " " + billingWrap}>

				{window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? "" : <Leftmenu />}

				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								{loader === true ? <Loader /> : ''}
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">TEST RESULT</span>

												<span class="treatmentPlanBtn pl_blue_btn">
													<a href="/patients/testResultAdd " id="testResultAddBtn">+ Add</a>
												</span>
											</div>

											{testValueArr.length > 0 ?
												<div>
													{testValueArr.map((item, i) => (
														<div class="treatmentPlanList1" style={{ height: 'auto' }}>
															<div class="treatmentPlanDate">
																{item.createdOn}<span
																	style={{
																		float: 'right',
																		marginBottom: '10px',
																	}}
																>
																	<a onClick={() => printAll(item, "PrintAll")}
																	//target="_blank"
																	>
																		<input
																			type="submit"
																			name="_action_savePrescription"
																			value="Print All"
																			class="clinicalSave precriptionSaveBtn_PTour"
																		/>
																	</a>
																</span>
															</div>
															<div class="treatmentTable planActive">
																<table>
																	<thead>
																		<tr>
																			<th class="col-md-3">
																				<div class="dentalProName">Test Name (Type)</div>
																			</th>
																			<th class="col-md-2">
																				<div class="dentalProDis">Normal Value</div>
																			</th>
																			<th class="col-md-3" />
																			<th class="col-md-4">
																				<div class="dentalProDis">Patient Readings</div>
																			</th>
																		</tr>
																	</thead>
																	{item.labData.map((data, index) => (
																		<tbody>
																			<tr style={{ border: 'none', padding: '5px', }}>
																				<td colspan="8">
																					{data.department !== "" &&
																						<div class="dentalProName">
																							<span title="">Department : {data.department}</span>
																						</div>
																					}
																					<div class="dentalProName">
																						<span title="">{data.testName}(Specimen Type :{data.specimenType})</span>
																					</div>
																				</td>
																				<td class=" pl_testdpdn1" colspan="4">
																					<div class="dentalProNote">
																						<div>
																							<label class="dropdown">
																								<div class="dd-button" />

																								<input
																									type="checkbox"
																									class="dd-input"
																									id="test"
																								/>

																								<ul class="dd-menu">
																									{/* <Link to={{ pathname: '/patients/testResultAdd', state: { data: item, index: index, label: "Edit" } }}>
																										<li> <img src={Editicon} style={{ width: '15px', marginRight: '3px' }} />Edit</li>
																									</Link> */}
																									<a onClick={() => printAll(data, "Print")}>
																										<li><img src={Print} style={{ width: '15px', marginRight: '3px' }} />Print</li>
																									</a>
																									<a onClick={(e) => deleteResult(data)}>
																										<li><img src={Delete} style={{ width: '15px', marginRight: '5px' }} />Delete</li>
																									</a>
																								</ul>
																							</label>
																						</div>
																					</div>
																				</td>
																			</tr>
																			{data.labDetails.map((val) => (
																				<tr class="groupBrdr">
																					<td class="col-md-3">
																						<div class="dentalProName">
																							<span class="elipsis" title="">{val.parameter}</span>
																						</div>
																					</td>
																					<td class="col-md-2">
																						<div class="dentalProDis">{val.normalValue}</div>
																					</td>

																					<td class="col-md-1">
																						<div class="dentalProDis">&nbsp;</div>
																					</td>

																					{val.patientReadingValue.match(phoneno) && val.patientReadingValue.match(num) ? (
																						<td class="col-md-1">
																							<div class="dentalProDis pl_pending" style={{ color: 'black' }}>{val.patientReading}</div>
																						</td>) :
																						<td class="col-md-1">
																							<div class="dentalProDis pl_pending" style={{ color: val.color }}>{val.patientReading}</div>
																						</td>}
																				</tr>
																			))}
																			<tr class="dentalProPlaned">
																				<input
																					type="hidden"
																					name="hidTreatmentIds10"
																					id="hidTreatmentIds10"
																					value=""
																				/>
																				<td colspan="12">
																					<div class="plannedBy">
																						Noted by <span>{data.notedBy}</span>
																					</div>
																				</td>
																			</tr>
																		</tbody>
																	))}
																</table>
															</div>
														</div>
													))}
												</div>
												:
												<NoData />
											}
										</div>

										<div id="addTreatmentPlanDiv" class="addTreatmentCover">
											<div class="loaderWrap" id="loader1" style={{ display: 'none' }}>
												<div class="loaderPurple" />
												{/* <img src="../images/dental/purpleLoader.png"> */}
											</div>
										</div>
									</div>
								</div>
								{/*  */}
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default TestResult;
