import React, { useEffect, useState } from "react";
import PharmacyHeader from './layout/header';
import Footer from './layout/footer';
import { Route, Link } from 'react-router-dom';
import PharmacyLeft from './pharmacyLeft'
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { callingAPI } from "../config";
import Pagination from "react-js-pagination";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import moment from 'moment';
function PrescriptionDetail() {
    const [PrescriptionDetailsSelectValue, setPrescriptionDetailsSelectValue] = useState("");
    const [PrescriptionDetailsEnterValue, setPrescriptionDetailsEnterValue] = useState("");
    const [PrescriptionDetailsFromDate, setPrescriptionDetailsFromDate] = useState("");
    const [PrescriptionDetailsToDate, setPrescriptionDetailsToDate] = useState("");
    const [memberId, setmemberId] = useState("");
    const [visitDate, setvisitDate] = useState("");
    const [PrescriptionIdValue1, setPrescriptionIdValue1] = useState([]);
    const [getPrescriptionDetailsList, setPrescriptionDetailsList] = useState([]);
    const [boolianSelectValue, setbooliansearchValue] = useState("");
    const [booliansearchValue, setboolianSelectValue] = useState("");
    const [boolianfromDate, setboolianfromDate] = useState("");
    const [booliantoDate, setbooliantoDate] = useState("");
    const [labTestCount, setlabTestCount] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageOffset, setPageOffset] = useState(0);
    const [dataFlag, setDataFlag] = useState(false);
    ////////////////////////////////////////////////////////////////////////////////////
    const [PopUpodataFlag, setPopUpodataFlag] = useState(true);
    const [Printpage, setPrintpage] = useState([]);
    const [MedicineDetails, setMedicineDetails] = useState([]);
    const [medicineIds, setmedicineId] = useState([]);
    const [medicineIdsOnClick, setmedicineIdsOnClick] = useState([]);
    const [TypeOfPrintSelected, setTypeOfPrintSelected] = useState([]);
    const [PrintSelectedMemderId, setMemderId] = useState([]);
    const [PrintSelectedsetvisitdate, setvisitdate] = useState([])
    const [person, setPerson] = useState([]);
    const getlabTestList = () => {
        let PostData = {
            "userId": window.sessionStorage.getItem("userId"),
            "role": window.sessionStorage.getItem("role"),
            "searchId": PrescriptionDetailsSelectValue,
            "searchValue": PrescriptionDetailsEnterValue,
            "fromDate": PrescriptionDetailsFromDate ? formatDate(PrescriptionDetailsFromDate) : "",
            "toDate": PrescriptionDetailsToDate ? formatDate(PrescriptionDetailsToDate) : "",
            "max": 10,
            "offset": 0
        };
        callingAPI('calenderListing/pharmacyPrescriptionDetails', PostData).then((response) => {
            if (response.data.success === "1") {
                setDataFlag(true)
                setPrescriptionDetailsList(response.data.result.testGroupData);
                setlabTestCount(response.data.result.totalCount);
                setActivePage(1);
            }
            else {
                setDataFlag(true)
                setlabTestCount(0)
                setActivePage(0);
                setPrescriptionDetailsList([]);
            }
        });
    };
    const FromDateClear = () => {
        setPrescriptionDetailsFromDate("")
    };
    const MyContainer = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
                    </span>

                </div>
            </CalendarContainer>
        );
    };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const ToDateClear = () => {
        setPrescriptionDetailsToDate("")
    };

    const MyContainerTo = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div >
                    <span className="clearCloseCalander">
                        {children}
                        <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
                        {/* <a  className="closeCalander">Close</a> */}
                    </span>
                </div>
            </CalendarContainer>
        );
    };
    function formatDate(date) {
        var dates = new Date(date),
            month = "" + (dates.getMonth() + 1),
            day = "" + dates.getDate(),
            year = dates.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
    }
    useEffect(() => {

        getlabTestList();
        if (window.sessionStorage.getItem("htmlData") !== null) {
            window.open(`${process.env.PUBLIC_URL}/patients/RecieptPrint/Payment/${window.sessionStorage.getItem("htmlData")}`, "_blank")
        }
        window.sessionStorage.removeItem("htmlData")
    }, []);
    const Select = (value) => {

        setPrescriptionDetailsSelectValue(value);
        setPrescriptionDetailsEnterValue("")
        if (value === "") {
            setPrescriptionDetailsEnterValue("");
            document.getElementById("Enter").disabled = true;
        } else {
            document.getElementById("Enter").disabled = false;
            document.getElementById("Enter").focus();
        }
    }
    const searchFunction = () => {
        if (PrescriptionDetailsFromDate && PrescriptionDetailsToDate) {
            if (PrescriptionDetailsFromDate > PrescriptionDetailsToDate) {
                alert("To date should be greater than or equal to from date");
                return;
            }
        }
        if (document.getElementById('selectName').value !== '' && document.getElementById('Enter').value === '') {
            var t = document.getElementById('selectName');
            var selectedText = t.options[t.selectedIndex].text;
            document.getElementById('selectName').focus();
            alert(selectedText + ' required');
            return false;
        }
        setboolianSelectValue(PrescriptionDetailsSelectValue);
        setbooliansearchValue(PrescriptionDetailsEnterValue);
        setboolianfromDate(PrescriptionDetailsFromDate);
        setbooliantoDate(PrescriptionDetailsToDate);
        getlabTestList();
        setActivePage(1);
    };
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === "1") {
            offset = "0";
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset =
                parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        setActivePage(pageNumber);
        setPageOffset(offset);
        if (!boolianSelectValue) { setPrescriptionDetailsSelectValue("") }
        if (!booliansearchValue) { setPrescriptionDetailsEnterValue("") }
        if (!boolianfromDate) { setPrescriptionDetailsFromDate("") }
        if (!booliantoDate) { setPrescriptionDetailsToDate("") }
        let PostData = {
            "userId": window.sessionStorage.getItem("userId"),
            "role": window.sessionStorage.getItem("role"),
            "searchId": PrescriptionDetailsSelectValue,
            "searchValue": PrescriptionDetailsEnterValue,
            "fromDate": boolianfromDate ? formatDate(boolianfromDate) : "",
            "toDate": booliantoDate ? formatDate(booliantoDate) : "",
            "max": max,
            "offset": offset
        };
        callingAPI("calenderListing/pharmacyPrescriptionDetails", PostData).then((response) => {
            if (response.data.success === "1") {
                setPrescriptionDetailsList(response.data.result.testGroupData);
                setlabTestCount(response.data.result.totalCount);
                setDataFlag(true)
            }
        });
    };
    const ClosePopup = () => {
        setPrintpage("");
        setmedicineId('');
        setmedicineIdsOnClick("");
        //  setMedicineDetails("");
    }
    // {/* PRINNT   MODAL BEGINS HERE */} "2022-02-23"
    const PRINNTMODAL = (visitdate, memberid) => {
        setMemderId(memberid)
        setvisitdate(moment(visitdate).format('YYYY-MM-DD'),)
        let PostData = {
            "memberId": memberid,
            "userId": window.sessionStorage.getItem("userId"),
            "visitDate": moment(visitdate).format('YYYY-MM-DD'),
        };
        setPopUpodataFlag(false);
        callingAPI('calenderListing/prescriptionTestDetails', PostData).then((response) => {
            if (response.data.success === "1") {
                setPopUpodataFlag(true);
                setvisitDate(moment(visitdate).format('YYYY-MM-DD'))
                setmemberId(memberid)
                setPrintpage(response.data.result.testGroupData[0]);
                setMedicineDetails(response.data.result.testGroupData[0].labData);
            }
            else {
                setPrintpage([]);
                setPopUpodataFlag(true);
                setMedicineDetails([]);
            }

        });
    };

    const PrintSelected = (PrintType) => {

        if (PrescriptionIdValue1.length === 0) {
            alert("Atleast one test required")
        }

        else {
            window.sessionStorage.setItem("visitDate", visitDate)
            window.sessionStorage.setItem("PrescriptionIdValue", PrescriptionIdValue1.toString().replace(/[\[\]']+/g, ''))
            window.sessionStorage.setItem("memberId", memberId)
            window.open("prescriptionDetailsprint");
        }



    }

    const PrintSelectedAll = (PrintType) => {


        window.sessionStorage.setItem("visitDate", visitDate)
        window.sessionStorage.setItem("PrescriptionIdValue", '')
        window.sessionStorage.setItem("memberId", memberId)
        window.open("prescriptionDetailsprint");




    }
    const medicineIdsChecked = (prescribedmedicines) => {

        const value = prescribedmedicines;
        setPrescriptionIdValue1((item) =>
            PrescriptionIdValue1.includes(value)
                ? item.filter((data) => data !== value)
                : [...item, prescribedmedicines]
        );
    }
    const sessionSetting = (memberId, hospitalId) => {
        window.sessionStorage.setItem("memberId", memberId)
        localStorage.setItem('hospitalId', hospitalId)

    }
    return (
        <div className="dashboardCover pharmacyManufactureWrap 1122 ">
            <div className="row">
                <div className="col-md-10">
                    <div className="dashboardTitle">Prescription Details</div>
                </div>
                <div className="col-md-2">
                    <a style={{ textDecoration: 'none' }}>

                        <Link to={{ pathname: '/patients/patientInvoiceAdd', state: { hospitalId: localStorage.getItem('hospitalId'), memberId: "", isFlag: "Add Prescription" } }} style={{ color: '#fff' }}>
                            <span
                                className="dashButtonBorder labButton" style={{ borderRadius: "0px" }}
                            >Dispense Medicine</span></Link>


                    </a>
                </div>
            </div>
            <div className="dashboardContent">
                <div className="reportWrap">
                    <div className="dashboardSearch">
                        <div className="row">
                            <div className="col-md-2 dashboardSearchList">
                                <select className="inputField" value={PrescriptionDetailsSelectValue} id="selectName"
                                    name="selectName"
                                    onChange={(e) => Select(e.target.value)}  >
                                    <option value="">Select</option>
                                    <option value="1">Patient Name</option>
                                    {window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<option value="2">MR Number</option>)}
                                    {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<option value="3">PR Number</option>)}

                                </select>
                            </div>
                            <div className="col-md-2 dashboardSearchList"  >
                                <input type="text" className="inputField"
                                    style={{ background: PrescriptionDetailsSelectValue === "" ? "transparent" : "white" }}
                                    value={PrescriptionDetailsEnterValue} onChange={(e) => setPrescriptionDetailsEnterValue(e.target.value)} id="Enter" placeholder='Enter Value' disabled />
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <Datepicker
                                    autoComplete='off'
                                    className="inputField"
                                    selected={PrescriptionDetailsFromDate}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="From Date"
                                    maxDate={new Date()}
                                    onChange={(date) => setPrescriptionDetailsFromDate(date)}
                                    id="fromdate"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    calendarContainer={MyContainer}
                                    onChangeRaw={handleDateChangeRaw}
                                />
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <Datepicker
                                    className="inputField"
                                    autoComplete='off'
                                    selected={PrescriptionDetailsToDate}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="To Date"
                                    maxDate={new Date()}
                                    onChange={(date) => setPrescriptionDetailsToDate(date)}
                                    id="To"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    calendarContainer={MyContainerTo}
                                    onChangeRaw={handleDateChangeRaw}
                                />
                            </div>
                            <div className="col-md-2 dashboardSearchList"  >
                                <span className="dashButtonBorder labButton" onClick={searchFunction} style={{ padding: "8px 20px", height: "35px" }}>Search</span>
                            </div>
                        </div>
                    </div>

                    {dataFlag == false ? (
                        <div align="center">
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            {dataFlag == true && (
                                <div>
                                    {getPrescriptionDetailsList.map((DetailsList, i) => (
                                        <div class="treatmentPlanList">
                                            <div class="treatmentPlanDate">
                                                {DetailsList.createdOn}
                                            </div>
                                            <div class="treatmentTable planActive">
                                                {DetailsList.prescriptionDetails.map((prescriptionDetailsListsubarray, i) => (

                                                    <table style={{ tableLayout: "fixed" }}>
                                                        <thead>
                                                            <tr>
                                                                <th class="col-md-3"><div class="dentalProName">Patient Name</div></th>

                                                                {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                    (<th class="col-md-3"><div class="dentalProName">MR No</div></th>) :
                                                                    (<th class="col-md-3"><div class="dentalProName">PR No</div></th>)
                                                                }

                                                                <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                                <th class="col-md-1"><div class="dentalProName">&nbsp;</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="groupBrdr">
                                                                <td class="col-md-3">
                                                                    <div class="dentalProName paddingFive">
                                                                        <span class="elipsis">
                                                                            <input type="hidden" id={'itemId' + i} name="itemId" value={prescriptionDetailsListsubarray.memberId} />
                                                                            {prescriptionDetailsListsubarray.displayName}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                {window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
                                                                    (<td class="col-md-3"><div class="dentalProName paddingFive"> {prescriptionDetailsListsubarray.mrNo} </div></td>) :
                                                                    (<td class="col-md-3"><div class="dentalProName paddingFive"> {prescriptionDetailsListsubarray.prNo} </div></td>)
                                                                }
                                                                <td class="col-md-1"><div class="dentalProName txtCenter pharmacyLgnPrint" onClick={() => sessionSetting(prescriptionDetailsListsubarray.memberId, prescriptionDetailsListsubarray.hospitalId)}>
                                                                    {/* <a> View Details</a> */}
                                                                    <Link to={{ pathname: '/patients/patientInvoiceAdd', state: { prescriptionViewIds: prescriptionDetailsListsubarray.visitId, memberId: prescriptionDetailsListsubarray.memberId, hospitalId: prescriptionDetailsListsubarray.hospitalId, isFlag: "Prescription" } }}>  View Details</Link>
                                                                </div>
                                                                </td>
                                                                <td class="col-md-1"><div class="dentalProName txtCenter pharmacyLgnPrint" >
                                                                    <a href="#prescriptionDetailsView" onClick={(e) => PRINNTMODAL(prescriptionDetailsListsubarray.visitDate, prescriptionDetailsListsubarray.memberId)} > View & Print</a></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    {labTestCount > 10 && (
                                        <div className="paginationSection">
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                                                totalItemsCount={labTestCount}
                                                pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                                                onChange={handlePageChange}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {getPrescriptionDetailsList.length == 0 && dataFlag == true && <NoData />}
                        </div>
                    )}

                    <div>

                        <div id="prescriptionDetailsView" class="modalDialog">
                            {PopUpodataFlag == true ? (


                                <div>
                                    <a href="#close" onClick={ClosePopup} title="Close" class="close exportClose">X</a>

                                    <div class="modal-header" style={{ display: "block", border: "none", paddingBottom: "0px" }}>
                                        <div class="addDataHead">
                                            <span className='labTitle'>Prescription Details</span>
                                        </div>
                                        {PopUpodataFlag == true && (
                                            <div class="addDataContent" id="medicineDetails" >
                                                <div class="patientNameDiv row">
                                                    <span class="col-md-2 patientName patientDate">
                                                        <label>Date :</label>
                                                        <span>{Printpage.date}</span>
                                                    </span>
                                                    <span class="col-md-5 patientName ">
                                                        <label>Patient Name :</label>
                                                        <span>{Printpage.patientName} </span>
                                                    </span>
                                                    <span class="col-md-5 patientName">

                                                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<label>MR No :</label>) : (<label>PR No :</label>)}
                                                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<span>{Printpage.mrNo}</span>) : (<span>{Printpage.prNo}</span>)}
                                                    </span>
                                                </div>
                                                <div class="treatmentTable planActive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th class="col-md-3"><div class="dentalProName">Medicine</div></th>
                                                                <th class="col-md-3"><div class="dentalProCost txtLeft">Frequency & Dosage</div></th>
                                                                <th class="col-md-2"><div class="dentalProDis txtLeft">Intake</div></th>
                                                                <th class="col-md-1"><div class="dentalProDis txtLeft">Qty</div></th>
                                                                <th class="col-md-1"><div class="dentalProTotal txtLeft">Days</div></th>
                                                                <th class="col-md-2"><div class="dentalProTotal txtLeft">Start Date</div></th>
                                                            </tr>
                                                        </thead>
                                                        {MedicineDetails.map((medicineDetailsPatient, i) => (
                                                            <tbody>
                                                                {medicineDetailsPatient.prescriptionDetails.map((prescribedmedicines, idx) => (
                                                                    <tr>
                                                                        <td class="col-md-3">
                                                                            <div class="dentalProName">
                                                                                <div class="optionWrapper">
                                                                                    <input type="checkbox" id={"chkSelect0" + idx}
                                                                                        // onChange={(e) => medicineIdsChecked(prescribedmedicines.medicineId)} />
                                                                                        onChange={(e) => medicineIdsChecked(prescribedmedicines.doctorVisitMedicineId)} />
                                                                                    <label for={"chkSelect0" + idx}></label>

                                                                                </div>
                                                                                <span class="elipsis" title={prescribedmedicines.medicineName}>{prescribedmedicines.medicineName} </span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="col-md-3"><div class="dentalProCost txtLeft">{prescribedmedicines.frequencyAndDosage}</div></td>
                                                                        <td class="col-md-2"><div class="dentalProDis txtLeft">{prescribedmedicines.intake}	</div></td>
                                                                        <td class="col-md-1"><div class="dentalProDis txtLeft">
                                                                            {prescribedmedicines.quantity === "" ? 0 : prescribedmedicines.quantity}
                                                                        </div></td>
                                                                        <td class="col-md-1"><div class="dentalProTotal txtLeft" title="">  {prescribedmedicines.days}</div></td>
                                                                        <td class="col-md-2"><div class="dentalProTotal txtLeft" title="">  {prescribedmedicines.startDate}</div></td>
                                                                    </tr>
                                                                ))}
                                                                <tr class="dentalProPlaned">
                                                                    <td colspan="3"><div class="plannedBy">Prescribed by <span>
                                                                        {medicineDetailsPatient.prescribedBy}
                                                                    </span></div></td>
                                                                    <td colspan="3"></td>
                                                                </tr>
                                                            </tbody>
                                                        ))}
                                                    </table>
                                                </div>
                                                <div class="serviceProviderBtns">
                                                    <input type="submit" onClick={(e) => PrintSelected()} value="Print Selected" style={{ background: "#177f40" }} />
                                                    <input type="submit" onClick={(e) => PrintSelectedAll()} value="Print All" id="btnPrintAll" style={{ background: "#177f40" }} />
                                                </div>
                                            </div>
                                        )}
                                        {MedicineDetails.length == 0 && PopUpodataFlag == true && <NoData />}
                                    </div>

                                </div>
                            ) : (
                                <div align="center">
                                    <Loader />
                                </div>
                            )}
                        </div>

                    </div>

                </div>
            </div>
        </div>




    );
}
export default PrescriptionDetail;
