import React, { useState, useEffect } from 'react';
import {
    faArrowLeft,
    faHome,
    faUser,
    faWallet,
    faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TotalRevenue from '../dashboardView/totalRevenue'
import { callingAPI } from '../../config';

function TotalIncome() {
    const [dataFlag, setDataFlag] = useState(false);
    const [totalIncome, setTotalIncome] = useState([])
    const [xAxis, setXAxis] = useState([])
    const [treatmentIncome, setTreatmentIncome] = useState([])
    const [pharmacyIncome, setPharmacyIncome] = useState([])
    const [labIncome, setLabIncome] = useState([])
    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            hospitalId: localStorage.getItem("hospitalId"),
            // hospitalId: role === "ROLE_DOCTOR" ? "" : localStorage.getItem("hospitalId"),
            // hospitalId: "9373"
            // localStorage.getItem("hospitalId"),
        };
        callingAPI('commonUserFunctions/getTotalIncomeDetails', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setTotalIncome(response.data.result)

            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);




    useEffect(() => {
        var role = window.sessionStorage.getItem("role");
        const PostData = {
            hospitalId: localStorage.getItem("hospitalId")
        };
        callingAPI('commonUserFunctions/getYearWiseTotalIncomeDetails', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setXAxis(response.data.result.incomeResult.map((obj) => obj.incomeYear))
                setTreatmentIncome(response.data.result.incomeResult.map((obj) => parseInt(obj.incomeDetails.treatmentIncome)))
                setPharmacyIncome(response.data.result.incomeResult.map((obj) => parseInt(obj.incomeDetails.pharmacyIncome)))
                setLabIncome(response.data.result.incomeResult.map((obj) => parseInt(obj.incomeDetails.labIncome)))

            }
            else {
                setDataFlag(true);
            }
        })
            .catch();
    }, []);
    return (
        <div className="total_revenuecard">
            {/* <div className="total_revnuheader">
                <h2 className='total_revnuheadtxt'>Total Income</h2>
            </div> */} <div className="total_revnuheader">
                <h2 className='total_revnuheadtxt'>Treatment Income in Year</h2>
            </div>
            <div className="total_revnubody">

                <div className="row clearfix">
                    {/* {totalIncome.totalTreatmentIncome !== "0" ?
                    
                        <div className="col-md-4">
                            <div className="card-rvnu bg-success text-light">
                                <h4 className='card-rvnuhead'><FontAwesomeIcon icon={faWallet} />&nbsp;₹&nbsp;{totalIncome.totalTreatmentIncome}</h4>
                                <span className='card-rvnutxt'>Treatment Income</span>
                            </div>
                        </div>
                      : ""

                    } */}

                    {/* <div className="col-md-4">
                        <div className="card-rvnu bg-warning text-light">
                            <h4 className='card-rvnuhead'><FontAwesomeIcon icon={faWallet} />&nbsp;₹&nbsp;{totalIncome.totalPharmacyIncome}</h4>
                            <span className='card-rvnutxt'>Pharmacy Income</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card-rvnu bg-danger text-light">
                            <h4 className='card-rvnuhead'><FontAwesomeIcon icon={faWallet} />&nbsp;₹&nbsp;{totalIncome.totalLabIncome}</h4>
                            <span className='card-rvnutxt'>Lab Income</span>
                        </div>
                    </div> */}
                </div>
                <div className="totalrevnu_chart">
                    <TotalRevenue key={[xAxis, treatmentIncome]} xAxis={xAxis} treatmentIncome={treatmentIncome} />

                    {/* <TotalRevenue key={[xAxis, treatmentIncome, pharmacyIncome, labIncome]} xAxis={xAxis} treatmentIncome={treatmentIncome} pharmacyIncome={pharmacyIncome} labIncome={labIncome} /> */}
                </div>
            </div>
        </div>
    )
}

export default TotalIncome