
import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import Patientright from './patientright';
import Datepicker from 'react-datepicker';
import moment from 'moment';
import Add_img from '../images/icons/Add_img.png';
import Close from '../images/icons/close_img.png';
import SimplifiedFormCasesheetRight from '../Patients/patientright';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import Speech from '../AudioToText/speechRecognize'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UseMic from '../AudioToText/useMic'
const { detect } = require('detect-browser');
const browserDetect = detect();
const browser = browserDetect.name
const SimplifiedFormCasesheet = forwardRef((props, ref) => {
	const [isChief, setChief] = useState(false);
	const [isAssociated, setAssociated] = useState(false);
	const [isKnownCase, setKnownCaseMic] = useState(false);
	const [isObservation, setObservationMic] = useState(false);
	const [isInvestigation, setInvestigationMic] = useState(false);
	const [isRemark, setRemark] = useState(false);
	const [isDiagnosis, setIsDiagnosis] = useState(false);
	const [isTreatment, setTreatment] = useState(false);
	const [language, setLanguage] = useState('English');
	const [isObservations, setObservation] = useState(false)
	const [isInvestigations, setInvestigation] = useState(false)
	const [isAssociatedComplaints, setAssociatedComplaint] = useState(false)
	const [isSystemReview, setSystemReview] = useState(false)
	const [isChiefPresentingComplaint, setChiefPresentingComplaint] = useState(false)
	const [isKnbownCase, setKnownCase] = useState(false)
	const [txtTreatmentDone, setTxtTreatmentDone] = useState('');
	const [getDrName, setDrName] = useState(window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ? window.sessionStorage.getItem('patientDoctorId') : '');
	const [isChanged, setIsChanged] = useState(false);
	const [style, setStyle] = useState({ display: 'none' });
	const [fullArray, setFullArray] = useState([]);
	const [associatedArray, setAssociatedArray] = useState([]);
	const [knownCaseArray, setKnownCaseArray] = useState([]);
	const [chiefDescription, setChiefDescription] = useState('');
	const [knownDescription, setKnownDescription] = useState('');
	const [associatedDescription, setAssociatedDescription] = useState('');
	const [getValue, setGetValue] = useState([]);
	const [load, setLoad] = useState(false);
	const [clearValue, setClear] = useState('');
	const [associateClearValue, setAssociateClear] = useState('');
	const [knownClearValue, setKnownClear] = useState('');
	const [toggle, setToggle] = useState(false);
	const [recallToggle, setRecallToggle] = useState(false);
	const [appointmentDate, setAppointmentDate] = useState('');
	const [sinceDate1, setSinceDate1] = useState(new Date());
	const [sinceDate2, setSinceDate2] = useState(new Date());
	const [sinceDate3, setSinceDate3] = useState(new Date());
	const [observations, setObservations] = useState('');
	const [investigations, setInvestigations] = useState('');
	const [diagnosis, setDiagnosis] = useState('');
	const [remarks, setRemarks] = useState('');
	const [appointmentNext, setAppointmentNext] = useState('');

	const [doctorList, setDoctorList] = useState([]);
	const [diagnosisList, setDiagnosisList] = useState([]);
	const [freeArticleData1, setFreeArticleData1] = useState([]);
	const [nonGeneralData, setNonGeneralData] = useState([]);
	const [generalData, setGeneralData] = useState([]);
	const [pmId, setpmId] = useState('');
	const [diagnosisId, setDiagnosisId] = useState('');
	const [cmbStatus, setCmbStatus] = useState('3');
	const [chiefReason, setChiefReason] = useState('');
	const [associatedReason, setAssociatedReason] = useState('');
	const [knownReason, setKnownReason] = useState('');
	const [observationReason, setObservationReason] = useState('');
	const [investigationReason, setInvestigationReason] = useState('');
	const [diagnosisReason, setDiagnosisReason] = useState('');
	const [remarkReason, setRemarkReason] = useState('');
	const [futureAppointmentDate, setFutureAppointmentDate] = useState([]);
	const [dataFlag, setDataFlag] = useState(false);
	const [doctorVisitId, setDoctorVisitId] = useState('')
	const [disable, setDisable] = useState(false);
	const [followUpClick, setFollowUpClick] = useState(false);
	const [recallClick, setRecallClick] = useState(false);
	const [updatedFollowNote, setUpdatedFollowNote] = useState('');
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState('');
	const [chiefCaseSenstiveArray, setChiefCaseSenstiveArray] = useState([])
	const [associatedCaseSenstiveArray, setAssociatedCaseSenstiveArray] = useState([])
	const [knownCaseSenstiveArray, setKnownCaseSenstiveArray] = useState([])
	const [convertedFutureDate, setConvertedFutureDate] = useState('');
	const [txtDiagnosis, setTxtDiagnosis] = useState('');
	const [comments, setComments] = useState('');
	const [recallDate, setRecallDate] = useState('');

	// const reasonFieldShow = (event) => {
	// 	if (window.sessionStorage.getItem('editId') === '') {
	// 		document.getElementById('chiefReason').style.display = 'none';
	// 		document.getElementById('associatedReason').style.display = 'none';
	// 		document.getElementById('knownReason').style.display = 'none';
	// 		document.getElementById('observationsReason').style.display = 'none';
	// 		document.getElementById('investigationsReason').style.display = 'none';
	// 		document.getElementById('diagnosisReason').style.display = 'none';
	// 		document.getElementById('remarksReason').style.display = 'none';
	// 	} else {
	// 		document.getElementById('chiefReason').style.display = 'block';
	// 		document.getElementById('associatedReason').style.display = 'block';
	// 		document.getElementById('knownReason').style.display = 'block';
	// 		document.getElementById('observationsReason').style.display = 'block';
	// 		document.getElementById('investigationsReason').style.display = 'block';
	// 		document.getElementById('diagnosisReason').style.display = 'block';
	// 		document.getElementById('remarksReason').style.display = 'block';
	// 	}
	// };

	const orderAt = (id, mode, dropdownId) => {
		document.getElementById(mode).value =
			document.getElementById(mode).value.split('').length == 1
				? document.getElementById(mode).value.replace(/\s/, '')
				: document.getElementById(mode).value;
		setTimeout(function () {
			const apiJson = {
				complaintTypeId: id,
				txtInput: document.getElementById(mode).value,
			};
			callingAPI('clinicalBackground/dentalCaseSheetTags', apiJson).then((data) => {
				if (document.getElementById(mode).value.split('').length > 1) {

					if (data.data.success === '1' && data.data.result.tagName.length > 0) {
						setLoad(true);
						document.getElementById(dropdownId).style.display = 'block';
						setFreeArticleData1(data.data.result.tagName);
						document.addEventListener('mouseup', function (e) {
							var container = document.getElementById("locationSearch" + id);
							if (container !== null) {
								if (!container.contains(e.target)) {
									container.style.display = 'none';
								}
							}
						});
					} else {
						setLoad(true);
						document.getElementById(dropdownId).style.display = 'none';
						setFreeArticleData1([]);

					}
				}
				else {

					setFreeArticleData1([]);
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		}, 2000);
	};

	const setChiefTagData = (itemName, mode, dropdownId) => {
		if (chiefCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setFullArray([...fullArray, itemName])
			setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, itemName.toLowerCase()])
			setClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
		//	props.onCaseSheet(fullArray);
	};


	const setAssociatedTagData = (itemName, mode, dropdownId) => {
		if (associatedCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setAssociatedArray([...associatedArray, itemName]);
			setAssociatedCaseSenstiveArray([...associatedCaseSenstiveArray, itemName.toLowerCase()])
			setAssociateClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
	};
	const setKnownTagData = (itemName, mode, dropdownId) => {
		if (knownCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setKnownCaseArray([...knownCaseArray, itemName]);
			setKnownCaseSenstiveArray([...knownCaseSenstiveArray, itemName.toLowerCase()])
			setKnownClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
	};

	const deleteValue = (e, i) => {
		let newArray = [...fullArray];
		newArray.splice(i, 1);
		setFullArray(newArray);
		setChiefCaseSenstiveArray(newArray)

	};
	const associatedDelete = (e, i) => {
		let associateArray = [...associatedArray];
		associateArray.splice(i, 1);
		setAssociatedArray(associateArray);
		setAssociatedCaseSenstiveArray(associateArray)
	};
	const knownCaseDelete = (e, i) => {
		let knownArray = [...knownCaseArray];
		knownArray.splice(i, 1);
		setKnownCaseArray(knownArray);
		setKnownCaseSenstiveArray(knownArray)
	};
	const setValue = (e) => {
		const filteredData1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData1.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setClear('');
				setFullArray([...fullArray, e.target.value]);
				setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const setAssociateValue = (e) => {
		const filteredData = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setAssociateClear('');
				setAssociatedArray([...associatedArray, e.target.value]);
				setAssociatedCaseSenstiveArray([...associatedCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const setKnownValue = (e) => {
		const filteredData2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData2.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setKnownClear('');
				setKnownCaseArray([...knownCaseArray, e.target.value]);
				setKnownCaseSenstiveArray([...knownCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const deleteInputValue = (event) => {
		setClear(event.target.value);
	};
	const deleteAssociateValue = (event) => {
		setAssociateClear(event.target.value);
	};
	const deleteKnownValue = (event) => {
		setKnownClear(event.target.value);
	};
	const doctorClickFunction1 = (val) => {
		setDrName(val)
	};
	const doctorClickFunction = (val) => {
		const postData = {
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('commonUserFunctions/doctorList', postData).then((data) => {
			if (data.data.success === '1') {
				setLoad(true);
				setDoctorList(data.data.result.doctorList);
			} else {
				setLoad(true);
				alert(data.data.errorMessage);
			}
		})
			.catch();
	};
	const diagnosisClickFunction = (mode, dropdownId) => {
		const postData = {
			complaintTypeId: 33,
			txtInput: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 1) {
			callingAPI('clinicalBackground/caseSheetDiagnosis', postData).then((data) => {
				if (data.data.success === '1') {
					setLoad(true);
					document.getElementById(dropdownId).style.display = 'block';
					setDiagnosisList(data.data.result.tagName);
				} else {
					setLoad(true);
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else if (document.getElementById(mode).value.length <= 1) {
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	const setDiagnosisData = (itemName, mode, dropdownId) => {
		setDiagnosis(itemName);
		setDiagnosisList([]);
		document.getElementById(dropdownId).style.display = 'none';
	};
	const caseSheetChecking = () => {
		const postData = {
			"doctorId": window.sessionStorage.getItem('patientDoctorId'),
			"memberId": window.sessionStorage.getItem('memberId'),
			"hospitalId": localStorage.getItem('hospitalId'),
			// "date": moment(new Date()).format('YYYY-MM-DD'),
		}
		callingAPI('commonUserFunctions/appointmentCheck', postData)
			.then((data) => {
				if (data.data.success === '1') {
					document.getElementById("casesheetpresent").style.display = 'flex'
					document.getElementById("casesheetnotpresent").style.display = 'none'
					window.sessionStorage.setItem('editId', data.data.doctorVisitId);
				} else if (data.data.success === '0') {
					document.getElementById("casesheetnotpresent").style.display = 'flex'
					document.getElementById("casesheetpresent").style.display = 'none'
				}
			})
			.catch();
	}
	const simplifiedListing = () => {
		const postdata = {

			"doctorId": window.sessionStorage.getItem('userId'),
			"role": window.sessionStorage.getItem('role'),
			"hospitalId": localStorage.getItem('hospitalId'),

		};
		callingAPI('commonUserFunctions/simplifiedSettingsList', postdata).then((res) => {
			setObservation(res.data.result.main[0].sudArray[0].isActive)
			setInvestigation(res.data.result.main[0].sudArray[1].isActive)
			setAssociatedComplaint(res.data.result.main[0].sudArray[2].isActive)
			setSystemReview(res.data.result.main[0].sudArray[3].isActive)
			setChiefPresentingComplaint(res.data.result.main[0].sudArray[4].isActive)
			setKnownCase(res.data.result.main[0].sudArray[5].isActive)




		});
	}
	useEffect(() => {
		simplifiedListing();
		caseSheetChecking()
		doctorClickFunction();
		// reasonFieldShow();
		//	document.getElementById("clearinputname").focus()

	}, []);
	if (clearValue.length > 0) {
		props.onCaseSheet(clearValue);
	}
	else if (associateClearValue.length > 0) {
		props.onCaseSheet(associateClearValue);
	}
	else if (knownClearValue.length > 0) {
		props.onCaseSheet(knownClearValue);
	}
	else if (fullArray.length > 0) {
		props.onCaseSheet(fullArray);
	}
	else if (associatedArray.length > 0) {
		props.onCaseSheet(associatedArray);
	}
	else if (knownCaseArray.length > 0) {
		props.onCaseSheet(knownCaseArray);
	}
	else if (investigations != "") {
		props.onCaseSheet(investigations);
	}
	else if (observations != "") {
		props.onCaseSheet(observations);
	}

	useImperativeHandle(ref, () => ({
		showCaseSheet() {
			var postData
			var fullArrayCaseSheet
			if (window.sessionStorage.getItem("medicineBranchId") == 4) {
				if (clearValue) {
					const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
					if (filtered1.includes(clearValue.toLowerCase()) == false) {
						fullArray.push(clearValue)
					}
				}
				if (associateClearValue) {
					const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
					if (filtered.includes(associateClearValue.toLowerCase()) == false) {
						associatedArray.push(associateClearValue)
					}
				}
				if (knownClearValue) {
					const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
					if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
						knownCaseArray.push(knownClearValue)
					}
				}
				if (fullArray.length == 0) {
					alert("Please enter chief presenting complaint")
					document.getElementById("clearinputname1").focus()
					return false;

				}

				setLoad(false);
				if (disable) {
					return;
				}
				setDisable(true);
				postData = {
					userId: window.sessionStorage.getItem('userId'),
					doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
					tagCheifComp: fullArray ? fullArray.toString() : '',
					memberId: window.sessionStorage.getItem('memberId'),
					hospitalId: localStorage.getItem('hospitalId'),
					hidFinalize: 'false',
					txtCreatedDate: moment(new Date()).format('YYYY-MM-DD hh:mm'),
					needFollowUp: followUpClick,
					txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
					scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
					scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
					txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
					scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
					scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
					appointmentNextData: "",
					txtFollowNote: appointmentNext,
					txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
					txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
					txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
					cheifDesc: chiefDescription,
					txtAssociate: associatedDescription,
					tagKnowCase: knownCaseArray.toString(),
					txtKnown: knownDescription,
					txtOcclu: "",
					txtLymph: "",
					txtTMJ: "",
					txtBuccal: '',
					txtVesti: "",
					txtTongue: "",
					txtFloor: "",
					txtPalate: "",
					txtPharynx: "",
					txtAlBone: "",
					txtPerio: "",
					txtGingiva: "",
					txtRadio1: "",
					txtRadio2: "",
					txtRadio3: "",
					txtRadiograph: "",
					txtComments: "",
					txtSpecialist: "",
					txtDiagnosis: txtDiagnosis.replace(/(?:\r\n|\r|\n)/g, "<br>"),
					txtObservations: observations,
					tagAssocComp: associatedArray.toString(),
					teethDetails: "",
					cmbStatus: document.getElementById('cmbStatus').value,
					txtTreatmentDone: txtTreatmentDone
				};
			} else {
				if (clearValue) {
					const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
					if (filtered1.includes(clearValue.toLowerCase()) == false) {
						fullArray.push(clearValue)
					}
				}
				if (associateClearValue) {
					const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
					if (filtered.includes(associateClearValue.toLowerCase()) == false) {
						associatedArray.push(associateClearValue)
					}
				}
				if (knownClearValue) {
					const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
					if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
						knownCaseArray.push(knownClearValue)
					}
				}
				if (fullArray.length == 0 && associatedArray.length == 0 && knownCaseArray.length == 0 && knownCaseArray == "" && investigations == "" && observations == "") {
					alert("Please enter atleast one tag")
					return;

				}

				if (disable) {
					return;
				}
				setDisable(true);
				setLoad(false);
				postData = {
					userId: window.sessionStorage.getItem('userId'),
					doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
					txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
					memberId: window.sessionStorage.getItem('memberId'),
					hospitalId: localStorage.getItem('hospitalId'),
					scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
					scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
					txtFollowNote: appointmentNext,
					txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
					scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
					scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
					hidFinalize: 'false',
					needFollowUp: followUpClick,
					hidMode: '',
					uploadedImageNames: '',
					cmbStatus: document.getElementById('cmbStatus').value,
					tag1: fullArray.toString(),
					cheifDesc: chiefDescription,
					txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
					tag2: associatedArray.toString(),
					txtAssociate: associatedDescription,
					txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
					tag3: knownCaseArray.toString(),
					txtKnown: knownDescription,
					txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
					txtObservations: observations,
					txtInvestigations: investigations,
					txtRemarks: remarks,
					txtNeck: '',
					txtChest: '',
					txtEndo: '',
					txtAudiometry: '',
					txtEar: '',
					txtNose: '',
					txtThroat: '',
					txtDiagnosis: diagnosis,
					hidStatusId: '',
					currentDate: moment(new Date()).format('DD-MM-YYYY'),
					generalSection: '',
					nonGeneralSection: '',
					txtTreatmentDone: txtTreatmentDone,
					diagnosisAndTreatmentPlan: txtDiagnosis.replace(/(?:\r\n|\r|\n)/g, "<br>")
				};
			}
			const modifiedPostData = {
				...postData,
				getDate: recallDate,
				getcomment: comments,
				// recallClick:recallClick
			};
			return modifiedPostData

			//saveClickFunction()
		},
	}))
	const saveClickFunction = () => {
		if (window.sessionStorage.getItem("medicineBranchId") == 4) {
			if (clearValue) {
				const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
				if (filtered1.includes(clearValue.toLowerCase()) == false) {
					fullArray.push(clearValue)
				}
			}
			if (associateClearValue) {
				const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
				if (filtered.includes(associateClearValue.toLowerCase()) == false) {
					associatedArray.push(associateClearValue)
				}
			}
			if (knownClearValue) {
				const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
				if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
					knownCaseArray.push(knownClearValue)
				}
			}
			if (fullArray.length == 0) {
				alert("Please enter chief presenting complaint")
				document.getElementById("clearinputname1").focus()
				return false;
			}
			setLoad(false);
			if (disable) {
				return;
			}
			setDisable(true);

			const postData = {
				userId: window.sessionStorage.getItem('userId'),
				doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
				prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,

				tagCheifComp: fullArray ? fullArray.toString() : '',
				memberId: window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				hidFinalize: 'false',
				txtCreatedDate: moment(new Date()).format('YYYY-MM-DD hh:mm'),
				needFollowUp: followUpClick,
				txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
				scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
				scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
				txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
				scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
				scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
				appointmentNextData: "",
				txtFollowNote: appointmentNext,
				txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
				txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
				txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
				cheifDesc: chiefDescription,
				txtAssociate: associatedDescription,
				tagKnowCase: knownCaseArray.toString(),
				txtKnown: knownDescription,
				txtTreatmentDone: txtTreatmentDone,
				txtOcclu: "",
				txtLymph: "",
				txtTMJ: "",
				txtBuccal: '',
				txtVesti: "",
				txtTongue: "",
				txtFloor: "",
				txtPalate: "",
				txtPharynx: "",
				txtAlBone: "",
				txtPerio: "",
				txtGingiva: "",
				txtRadio1: "",
				txtRadio2: "",
				txtRadio3: "",
				txtRadiograph: "",
				txtComments: "",
				txtSpecialist: "",
				txtDiagnosis: "",
				txtObservations: observations,
				tagAssocComp: associatedArray.toString(),
				teethDetails: "",
				cmbStatus: document.getElementById('cmbStatus').value,

			};
			callingAPI('commonUserFunctions/saveCaseSheetDental ', postData).then((data) => {
				if (data.data.success === '1') {
					window.location.href = '/patients/patientconsult';
					// setDataFlag(true);
					// window.location.href = '/Patients/casesheetDental';
					// alert('Case Sheet Saved Successfully');
				} else if (data.data.success === '0') {
					setLoad(true);

					alert(data.data.errorMessage);
					// setDataFlag(true);
					// alert(data.data.errorMessage);
					// window.location.href = '/Patients/casesheetDentalAdd';
				}
				setDisable(false);
			})
				.catch((err) => {
					setDisable(false);
				});
		}
		else {
			if (clearValue) {
				const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
				if (filtered1.includes(clearValue.toLowerCase()) == false) {
					fullArray.push(clearValue)
				}
			}
			if (associateClearValue) {
				const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
				if (filtered.includes(associateClearValue.toLowerCase()) == false) {
					associatedArray.push(associateClearValue)
				}
			}
			if (knownClearValue) {
				const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
				if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
					knownCaseArray.push(knownClearValue)
				}
			}
			if (fullArray.length == 0 && associatedArray.length == 0 && knownCaseArray.length == 0 && knownCaseArray == "" && investigations == "" && observations == "") {
				alert("Please enter atleast one tag")
				return;

			}

			if (disable) {
				return;
			}
			setDisable(true);
			setLoad(false);
			const postData = {
				userId: window.sessionStorage.getItem('userId'),
				doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
				txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
				memberId: window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
				scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
				scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
				txtFollowNote: appointmentNext,
				txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
				scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
				scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
				hidFinalize: 'false',
				needFollowUp: followUpClick,
				hidMode: '',
				uploadedImageNames: '',
				cmbStatus: document.getElementById('cmbStatus').value,
				tag1: fullArray.toString(),
				cheifDesc: chiefDescription,
				txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
				tag2: associatedArray.toString(),
				txtAssociate: associatedDescription,
				txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
				tag3: knownCaseArray.toString(),
				txtKnown: knownDescription,
				txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
				txtObservations: observations,
				txtInvestigations: investigations,
				txtRemarks: remarks,
				txtNeck: '',
				txtChest: '',
				txtEndo: '',
				txtAudiometry: '',
				txtEar: '',
				txtNose: '',
				txtThroat: '',
				txtDiagnosis: diagnosis,
				hidStatusId: '',
				currentDate: moment(new Date()).format('DD-MM-YYYY'),
				generalSection: '',
				nonGeneralSection: '',
				txtTreatmentDone: txtTreatmentDone
			};
			callingAPI('commonUserFunctions/saveMedicalCaseSheet  ', postData).then((data) => {
				if (data.data.success === '1') {
					window.location.href = '/patients/patientconsult';
					//	alert('Case Sheet Saved Successfully');

					//	window.location.href = '/Patients/simplifiedForm';

				} else {
					setLoad(true);

					alert(data.data.errorMessage);
				}
				setDisable(false);
			})
				.catch((err) => {
					setDisable(false);
				});
		}
	};

	const chiefLeave = (i) => {
		document.getElementById('chief' + i).style.display = 'none';
	};
	const chiefEnter = (i) => {
		document.getElementById('chief' + i).style.display = 'block';
		document.getElementById('chief' + i).style.display = 'inline';
	};
	const knownLeave = (i) => {
		document.getElementById('known' + i).style.display = 'none';
	};
	const knownEnter = (i) => {
		document.getElementById('known' + i).style.display = 'block';
		document.getElementById('known' + i).style.display = 'inline';
	};
	const qaz1 = (i) => {
		document.getElementById('qwe' + i).style.display = 'none';
	};
	const qaz = (i) => {
		document.getElementById('qwe' + i).style.display = 'block';
		document.getElementById('qwe' + i).style.display = 'inline';
	};
	const toCaseSheet = () => {
		window.sessionStorage.setItem('fromSimplifiedForm', 1)
		if (window.sessionStorage.getItem("medicineBranchId") == 4) {
			window.location.href = '/patients/casesheetDentalAdd';
		}
		else {
			window.location.href = '/patients/casesheetMedicalAdd';
		}


	};
	const speechData = (data, variableName) => {
		if (variableName === 'chiefDescription') {
			setChief(false);
			if (data !== "") {
				setChiefDescription(chiefDescription.concat(' ' + data))
			}
		}
		else if (variableName === 'associatedDescription') {
			setAssociated(false);
			if (data !== "") {
				setAssociatedDescription(associatedDescription.concat(' ' + data));
			}
		}
		else if (variableName === 'knownDescription') {
			setKnownCaseMic(false);
			if (data !== "") {
				setKnownDescription(knownDescription.concat(' ' + data));
			}
		}
		else if (variableName === 'observations') {
			setObservationMic(false);
			if (data !== "") {
				setObservations(observations.concat(' ' + data));
			}
		}
		else if (variableName === 'investigations') {
			setInvestigation(false);
			if (data !== "") {
				setInvestigations(investigations.concat(' ' + data));
			}
		}
		else if (variableName === 'remarks') {
			setRemark(false);
			if (data !== "") {
				setRemarks(remarks.concat(' ' + data));
			}
		}
		else if (variableName === 'txtDiagnosis') {
			setIsDiagnosis(false);
			if (data !== "") {
				setTxtDiagnosis(txtDiagnosis.concat(' ' + data));
			}
		}
		else if (variableName === 'txtTreatmentDone') {
			setTreatment(false);
			if (data !== "") {
				setTxtTreatmentDone(txtTreatmentDone.concat(' ' + data));
			}
		}
	}
	return (
		<div className="coverWraper">
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap simplifiedForm">
				<div className="rightSection simplifiedLabrightSection" style={{ paddingLeft: "" }}>
					{load == false ? <Loader /> : ''}
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							{/* <div className="col-lg-8 col-md-8 practiceLeftSection" style={{ padding: "0px" }}> */}
							<div className="  practiceLeftSection simpliFormLeft" style={{ padding: "0px" }}>
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">

											<div class="treatmentClinicContent simplifiedAccordion" id="treatmentClinicContentId">
												{/* ====================== MEDICAL CONTENTS STARTS HERE ========================== */}
												<div class="accordion__button" style={{ marginBottom: '0px' }}>
													<div class="clinicContentList">
														<h2>Case Sheet</h2>
													</div>
													<button  >-</button>
												</div>
												{/* <span
													onClick={(e) => {
														saveClickFunction(e);
													}}
													class="treatmentPlanBtn pl_blue_btn"
													id="saveid"
													style={{ background: 'red', display: 'block' }}
												>
													Save
												</span> */}
												{/*  */}
												<div id="casesheetpresent"
													style={{ display: "none", minHeight: '100vh' }}
													class="treatmentDivSecWrap prescriptionAddDiv_PTour simplifiedFormCasesheetSection" >
													<span
														class="treatmentCommonTxt"
														id="treatmentCommonTxt"
													>
														<span>
															Case Sheet Already Added, click here to
															<a onClick={(e) => toCaseSheet()} style={{ color: "#337ab7", textDecoration: "none", cursor: "pointer" }}>Edit</a>
														</span>
													</span>
												</div>

												{/*  */}
												<div class="faq__content casesheetInput" id="casesheetnotpresent" style={{ display: "none" }}>
													<div style={{ width: '100%' }}>
														{isChiefPresentingComplaint == true &&

															<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">

																<div class="clinicalLabel">Chief presenting complaint</div>
																<div class="clinicalField">
																	<div class="bootstrap-tagsinput">

																		<span class="clinicalFieldSubTxt" id="chiefTags">


																			(Add Tag(s) & Press Enter Key.)<br />
																			{fullArray.map((index, i) => (
																				<span>
																					<li
																						style={{
																							listStyle: 'none',
																							fontSize: '12px',
																						}}
																						onMouseEnter={(e) => chiefEnter(index)}
																						onMouseLeave={(e) => chiefLeave(index)}
																					>
																						{index}
																						<span
																							style={{
																								display: 'none',
																								cursor: 'pointer',
																								padding: '10px',
																							}}
																							id={'chief' + index}
																							onClick={(e) => deleteValue(e, i)}
																						>
																							x
																						</span>{' '}
																					</li>
																				</span>
																			))}
																		</span>
																		<span
																			class="twitter-typeahead"
																			style={{
																				position: 'relative',
																				display: 'inline - block',
																			}}
																		>
																			<input
																				type="text"
																				onKeyPress={setValue}
																				value={clearValue}
																				onChange={deleteInputValue}
																				id="clearinputname"
																				className="inputField"
																				autoComplete="off"
																				onKeyUp={() =>
																					orderAt(
																						'1',
																						'clearinputname',
																						'locationSearch1'
																					)} style={{
																						border: "1px solid #ccc",
																						width: "50%",
																						borderRadius: "4px"
																					}}
																			/>
																			<ul
																				className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed "
																				id="locationSearch1"
																				style={{ display: 'none' }}
																			>
																				{freeArticleData1.map((freeArticleDataObj, i) => (
																					<li
																						onClick={() =>
																							setChiefTagData(
																								freeArticleDataObj.tagName,
																								'clearinputname',
																								'locationSearch1'
																							)}
																					>
																						{freeArticleDataObj.tagName}
																					</li>
																				))}
																			</ul>
																		</span>{' '}
																		<div class="clinicalDesField position-relative">
																			{browser === "chrome" ?
																				<div>
																					{isChief === false ?
																						<span className='speechBlock_sectn speechBlock_Change'>
																							<UseMic micClick={setChief} />
																						</span>
																						:
																						<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																							<Speech key={language} isClick={isChief} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"chiefDescription"} />
																						</span>
																					}</div> : ""}
																			<span class="clinicalDesTxt">Description</span>
																			<textarea
																				id="chiefDes"
																				maxlength="255"
																				// value={chiefDescription}
																				value={chiefDescription + (isChief === true ? ' Listening...' : '')}
																				onChange={(e) =>
																					setChiefDescription(e.target.value)}
																				placeholder="Description"
																				style={{ resize: 'none' }}
																			/>
																			{/* <div id="chiefReason" class="clinicalDesField">
																			<span class="clinicalDesTxt">
																				Reason For Change
																			</span>
																			<input
																				type="text"
																				value={chiefReason}
																				onChange={(e) => setChiefReason(e.target.value)}
																				className="inputField"
																				placeholder="Reason For Change"
																				style={{ width: '100%' }}
																			/>
																		</div> */}
																		</div>
																	</div>
																</div>
																<div class="clinicalDate">
																	<label>Since Date</label>
																	<Datepicker
																		required
																		id="sinceDateTime"
																		className="Datepicker pa2 inputField"
																		maxDate={new Date()}
																		dateFormat="dd/MM/yyyy"
																		placeholderText="From"
																		calendarClassName="rasta-stripes"
																		selected={sinceDate1}
																		onChange={(date) => setSinceDate1(date)}
																	/>
																</div>


															</div>

														}
														{isAssociatedComplaints == true &&

															<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
																<div class="clinicalLabel">
																	Associated <br />complaints
																</div>
																<div class="clinicalField">
																	<div class="bootstrap-tagsinput">
																		<span class="clinicalFieldSubTxt">
																			(Add Tag(s) & Press Enter Key.)
																			{associatedArray.map((index, i) => (
																				<span>
																					<li
																						style={{
																							listStyle: 'none',
																							fontSize: '12px',
																						}}
																						onMouseEnter={(e) => qaz(index)}
																						onMouseLeave={(e) => qaz1(index)}
																					>
																						{index}
																						<span
																							style={{
																								display: 'none',
																								cursor: 'pointer',
																								padding: '10px',
																							}}
																							id={'qwe' + index}
																							onClick={(e) => associatedDelete(e, i)}
																						>
																							x
																						</span>{' '}
																					</li>
																				</span>
																			))}
																		</span>
																		<span
																			class="twitter-typeahead"
																			style={{
																				position: 'relative',
																				display: 'inline - block',
																			}}
																		>
																			<input
																				type="text"
																				onKeyPress={setAssociateValue}
																				value={associateClearValue}
																				id="clearinputname2"
																				onChange={deleteAssociateValue}
																				className="inputField"
																				autoComplete="off"
																				onKeyUp={() =>
																					orderAt(
																						'2',
																						'clearinputname2',
																						'locationSearch2'
																					)} style={{
																						border: "1px solid #ccc",
																						width: "50%",
																						borderRadius: "4px"
																					}}
																			/>
																			<ul
																				className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																				id="locationSearch2"
																				style={{ display: 'none' }}
																			>
																				{freeArticleData1.map((freeArticleDataObj, i) => (
																					<li
																						onClick={() =>
																							setAssociatedTagData(
																								freeArticleDataObj.tagName,
																								'clearinputname2',
																								'locationSearch2'
																							)}
																					>
																						{freeArticleDataObj.tagName}
																					</li>
																				))}
																			</ul>
																		</span>{' '}
																		<div class="clinicalDesField position-relative">
																			{browser === "chrome" ?
																				<div>
																					{isAssociated === false ?
																						<span className='speechBlock_sectn speechBlock_Change'>
																							<UseMic micClick={setAssociated} />
																						</span>
																						:
																						<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																							<Speech key={language} isClick={isAssociated} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"associatedDescription"} />
																						</span>
																					}</div> : ""}

																			<span class="clinicalDesTxt">Description</span>
																			<textarea
																				id="associatedDes"
																				// value={associatedDescription}
																				value={associatedDescription + (isAssociated === true ? ' Listening...' : '')}
																				maxlength="255"
																				onChange={(e) =>
																					setAssociatedDescription(e.target.value)}
																				placeholder="Description"
																				style={{ resize: 'none' }}
																			/>
																			{/* <div id="associatedReason" class="clinicalDesField">
																			<span class="clinicalDesTxt">
																				Reason For Change
																			</span>
																			<input
																				type="text"
																				className="inputField"
																				value={associatedReason}
																				onChange={(e) =>
																					setAssociatedReason(e.target.value)}
																				placeholder="Reason For Change"
																				style={{ width: '100%' }}
																			/>
																		</div> */}
																		</div>
																	</div>
																</div>
																<div class="clinicalDate">
																	<label>Since Date</label>
																	<Datepicker
																		required
																		//id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		placeholderText="From"
																		maxDate={new Date()}
																		calendarClassName="rasta-stripes"
																		selected={sinceDate2}
																		dateFormat="dd/MM/yyyy"
																		onChange={(date) => setSinceDate2(date)}
																	/>
																</div>
															</div>

														}
														{isKnbownCase == true &&

															<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
																<div class="clinicalLabel">Known Case of</div>
																<div class="clinicalField">
																	<div class="bootstrap-tagsinput">
																		<span class="clinicalFieldSubTxt">
																			(Add Tag(s) & Press Enter Key.)
																			{knownCaseArray.map((index, i) => (
																				<span>
																					<li
																						style={{
																							listStyle: 'none',
																							fontSize: '12px',
																						}}
																						onMouseEnter={(e) => knownEnter(index)}
																						onMouseLeave={(e) => knownLeave(index)}
																					>
																						{index}
																						<span
																							style={{
																								display: 'none',
																								cursor: 'pointer',
																								padding: '10px',
																							}}
																							id={'known' + index}
																							onClick={(e) => knownCaseDelete(e, i)}
																						>
																							x
																						</span>{' '}
																					</li>
																				</span>
																			))}
																		</span>
																		<span
																			class="twitter-typeahead"
																			style={{
																				position: 'relative',
																				display: 'inline - block',
																			}}
																		>
																			<input
																				type="text"
																				onKeyPress={setKnownValue}
																				value={knownClearValue}
																				onChange={deleteKnownValue}
																				id="clearinputname3"
																				className="inputField"
																				autoComplete="off"
																				onKeyUp={() =>
																					orderAt(
																						'3',
																						'clearinputname3',
																						'locationSearch3'
																					)} style={{
																						border: "1px solid #ccc",
																						width: "50%",
																						borderRadius: "4px"
																					}}
																			/>
																			<ul
																				className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																				id="locationSearch3"
																				style={{ display: 'none' }}
																			>
																				{freeArticleData1.map((freeArticleDataObj, i) => (
																					<li
																						onClick={() =>
																							setKnownTagData(
																								freeArticleDataObj.tagName,
																								'clearinputname3',
																								'locationSearch3'
																							)}
																					>
																						{freeArticleDataObj.tagName}
																					</li>
																				))}
																			</ul>
																		</span>{' '}
																		<div class="clinicalDesField position-relative">

																			{browser === "chrome" ?
																				<div>
																					{isKnownCase === false ?
																						<span className='speechBlock_sectn speechBlock_Change'>
																							<UseMic micClick={setKnownCaseMic} />
																						</span>
																						:
																						<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																							<Speech key={language} isClick={isKnownCase} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"knownDescription"} />
																						</span>
																					}</div> : ""}

																			<span class="clinicalDesTxt">Description</span>
																			<textarea
																				id="knownDes"
																				// value={knownDescription}
																				value={knownDescription + (isKnownCase === true ? ' Listening...' : '')}
																				maxlength="255"
																				onChange={(e) =>
																					setKnownDescription(e.target.value)}
																				placeholder="Description"
																				style={{ resize: 'none' }}
																			/>
																			{/* <div id="knownReason" class="clinicalDesField">
																			<span class="clinicalDesTxt">
																				Reason For Change
																			</span>
																			<input
																				type="text"
																				value={knownReason}
																				onChange={(e) => setKnownReason(e.target.value)}
																				className="inputField"
																				placeholder="Reason For Change"
																				style={{ width: '100%' }}
																			/>
																		</div> */}
																		</div>
																	</div>
																</div>
																<div class="clinicalDate">
																	<label>Since Date</label>
																	<Datepicker
																		required
																		//id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		placeholderText="From"
																		maxDate={new Date()}
																		calendarClassName="rasta-stripes"
																		selected={sinceDate3}
																		dateFormat="dd/MM/yyyy"
																		onChange={(date) => setSinceDate3(date)}
																	/>
																</div>
															</div>

														}
														{window.sessionStorage.getItem('medicineBranchId') === '4' && isObservations == true &&

															<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
																{/* <span className='speechBlock_sectn'>
																	{isObservation === false ?
																		<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setObservationMic(true)} />
																		:
																		<Speech isClick={isObservation} speechData={speechData} variableName={"observations"} />
																	}
																</span> */}
																<div class="clinicalLabel">Observations</div>
																<div class="clinicalField">
																	<div class="bootstrap-tagsinput">
																		<div class="clinicalDesField">
																			<textarea
																				maxlength="255"
																				placeholder="Observations"
																				style={{ resize: 'none' }}
																				// value={observations}
																				value={observations + (isObservation === true ? ' Listening...' : '')}
																				onChange={(e) => setObservations(e.target.value)}
																			/>
																		</div>
																	</div>
																	{browser === "chrome" ?
																		<div>
																			{isObservation === false ?
																				<span className='speechBlock_sectn speechBlock_Change'>
																					<UseMic micClick={setObservationMic} />
																				</span>
																				:
																				<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																					<Speech key={language} isClick={isObservation} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"observations"} />
																				</span>
																			}</div> : ""}
																	{/* <div id="observationsReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">Reason For Change</span>
																	<input
																		type="text"
																		value={observationReason}
																		onChange={(e) => setObservationReason(e.target.value)}
																		className="inputField"
																		placeholder="Reason For Change"
																	/>
																</div> */}
																</div>
															</div>

														}
														{/* {window.sessionStorage.getItem('medicineBranchId') === '4' && */}
														<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
															{/* <span className='speechBlock_sectn'>
																{isDiagnosis === false ?
																	<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setIsDiagnosis(true)} />
																	:
																	<Speech isClick={isDiagnosis} speechData={speechData} variableName={"txtDiagnosis"} />
																}
															</span> */}
															<div class="clinicalLabel">Diagnosis & Treatment Plan/Explained Options</div>
															<div class="clinicalField">
																<div class="bootstrap-tagsinput">
																	<div class="clinicalDesField">
																		<textarea
																			placeholder="Diagnosis"
																			style={{ resize: 'none' }}
																			// value={txtDiagnosis}
																			value={txtDiagnosis + (isDiagnosis === true ? ' Listening...' : '')}
																			onChange={(e) => setTxtDiagnosis(e.target.value)}
																			name="txtComments"
																		/>
																	</div>
																</div>
																{browser === "chrome" ?
																	<div>
																		{isDiagnosis === false ?
																			<span className='speechBlock_sectn speechBlock_Change'>
																				<UseMic micClick={setIsDiagnosis} />																	</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																				<Speech key={language} isClick={isDiagnosis} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtDiagnosis"} />
																			</span>
																		}</div> : ""}
															</div>
														</div>

														{/* } */}
														{window.sessionStorage.getItem('medicineBranchId') !== '4' && isInvestigations == true &&
															<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
																<div class="clinicalLabel">Investigations</div>
																<div class="clinicalField">
																	<div class="bootstrap-tagsinput">
																		<div class="clinicalDesField">
																			<textarea
																				maxlength="255"
																				placeholder="Investigations"
																				style={{ resize: 'none' }}
																				value={investigations}

																				onChange={(e) => setInvestigations(e.target.value)}
																			/>
																		</div>
																	</div>
																	{/* <div id="investigationsReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">Reason For Change</span>
																	<input
																		type="text"
																		value={investigationReason}
																		onChange={(e) => setInvestigationReason(e.target.value)}
																		className="inputField"
																		placeholder="Reason For Change"
																	/>
																</div> */}
																</div>
															</div>
														}
														<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
															{/* <span className='speechBlock_sectn'>
																{isTreatment === false ?
																	<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setTreatment(true)} />
																	:
																	<Speech isClick={isTreatment} speechData={speechData} variableName={"txtTreatmentDone"} />
																}
															</span> */}
															<div class="clinicalLabel">Treatment Done</div>
															<div class="clinicalField">
																<div class="bootstrap-tagsinput">
																	<div class="clinicalDesField">
																		<textarea
																			maxlength="255"
																			placeholder="Treatment Done"
																			style={{ resize: 'none' }}
																			// value={txtTreatmentDone}
																			value={txtTreatmentDone + (isTreatment === true ? ' Listening...' : '')}
																			onChange={(e) => setTxtTreatmentDone(e.target.value)}
																		/>
																	</div>
																</div>

																{browser === "chrome" ?
																	<div>
																		{isTreatment === false ?
																			<span className='speechBlock_sectn speechBlock_Change'>
																				<UseMic micClick={setTreatment} />																</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																				<Speech key={language} isClick={isTreatment} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtTreatmentDone"} />
																			</span>
																		}</div> : ""}

															</div>
														</div>

														{/* <div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
															<div class="clinicalLabel">
																Diagnosis<br />
																<small style={{ color: 'red' }}>
																	(ICD-10)<sup>beta</sup>
																</small>
															</div>

															<div class="clinicalField">
																<div class="bootstrap-tagsinput">
																	<div class="clinicalDesField">
																		<input
																			type="text"
																			placeholder="Diagnosis"
																			className="inputField"
																			id="diagnosis"
																			value={diagnosis}
																			onChange={(e) => setDiagnosis(e.target.value)}
																			onKeyUp={() =>
																				diagnosisClickFunction(
																					'diagnosis',
																					'diagnosisSearch'
																				)}
																		/>
																		<ul
																			className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																			id="diagnosisSearch"
																			style={{ display: 'none' }}
																		>
																			{diagnosisList.map((diagnosisList1Obj, i) => (
																				<li
																					onClick={() =>
																						setDiagnosisData(
																							diagnosisList1Obj.tagName,
																							'clearinputname',
																							'locationSearch1'
																						)}
																				>
																					{diagnosisList1Obj.tagName}
																				</li>
																			))}
																		</ul>
																	</div>
																</div>
																<div id="diagnosisReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">Reason For Change</span>
																	<input
																		type="text"
																		value={diagnosisReason}
																		onChange={(e) => setDiagnosisReason(e.target.value)}
																		className="inputField"
																		placeholder="Reason For Change"
																	/>
																</div>
															</div>
														</div> */}
														{/* <div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
															<div class="clinicalLabel">Remarks</div>
															<div class="clinicalField">
																<div class="bootstrap-tagsinput">
																	<div class="clinicalDesField">
																		<textarea
																			maxlength="255"
																			placeholder="Remarks"
																			value={remarks}
																			onChange={(e) => setRemarks(e.target.value)}
																			style={{ resize: 'none' }}
																		/>
																	</div>
																</div>
																<div id="remarksReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">Reason For Change</span>
																	<input
																		type="text"
																		value={remarkReason}
																		onChange={(e) => setRemarkReason(e.target.value)}
																		className="inputField"
																		placeholder="Reason For Change"
																	/>
																</div>
															</div>
														</div> */}
														<div class="clinicContentList dentalCaseSheetAppontStatus_PTour">
															<div class="clinicArrived">
																<label class="clinicalLabel">Appointment Status</label>
																<div class="clinicalField">
																	<select
																		name="cmbStatus"
																		id="cmbStatus"
																		value={cmbStatus}
																		onChange={(e) => setCmbStatus(e.target.value)}
																	>
																		<option value="1">OPEN</option>
																		<option value="2">CONFIRM</option>
																		<option value="3" selected="selected">
																			ARRIVED
																		</option>
																		<option value="4">DOCTOR CHANGED</option>
																		<option value="8">ENGAGED</option>
																		<option value="5">ADMITTED</option>
																		<option value="7">DISCHARGE</option>
																		<option value="6">COMPLETED</option>
																		<option value="9">CANCELLED</option>
																		<option value="10">CALL COMPLETED-DOCTOR</option>
																	</select>
																</div>
															</div>
														</div>


														<div class="clinicFollowUp caseSheetNeedFollowUp_PTour caseSheetWNeedFollowUp_PTour">
															<span class="followUpTxt  needfollowup-inline" id="weee" onClick={() => setToggle(!toggle)}>
																<div className='followup-button' onClick={() =>
																	setFollowUpClick(true)}

																>
																	Add Appointment Follow Up
																</div>
															</span>
															<span class="followUpTxt recall-inline" id="weee" onClick={() => setRecallToggle(!recallToggle)}>
																<div className='recall-button' onClick={() =>
																	setRecallClick(true)}

																>
																	Add Patient Recall
																</div>
															</span>
															{toggle && (
																<div id="needFollowDiv" style={{ display: 'block' }}>
																	<div class="clinicContentList">
																		<div class="clinicalLabel">
																			Appointment Date &amp; Time
																		</div>
																		<div class="clinicalField">
																			<Datepicker
																				required
																				// id="appoinmentDateTime"
																				className="Datepicker pa2 inputField"
																				placeholderText="Date  Time"
																				dateFormat="dd-MM-y h:mm aa"
																				calendarClassName="rasta-stripes"
																				showTimeSelect
																				minDate={new Date()}
																				selected={appointmentDate}
																				value={appointmentDate}
																				onChange={(date) =>
																					setAppointmentDate(date)}
																			/>
																		</div>
																	</div>
																	{window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ?
																		<div class="clinicContentList">
																			<div class="clinicalLabel">By</div>
																			<div class="clinicalField">
																				<select
																					name="cmbDoctor"
																					id="cmbDoctor"
																					value={getDrName}
																					onChange={(e) => doctorClickFunction1(e.target.value)}
																				>
																					<option >
																						Select Doctor
																					</option>
																					{doctorList.map((index, i) => (
																						<option value={index.doctor_id}>
																							{index.doctor_name}
																						</option>
																					))}
																				</select>
																			</div>
																		</div> : ""}

																	<div class="clinicContentList">
																		<div class="clinicalLabel">
																			Bring For Next Appointment
																		</div>
																		<div class="clinicalField">
																			<textarea
																				name="txtFollowNote"
																				id="txtFollowNote"
																				maxlength="100"
																				style={{ resize: 'none' }}
																				value={appointmentNext}
																				onChange={(e) =>
																					setAppointmentNext(e.target.value)}
																			/>
																		</div>
																	</div>
																</div>
															)}
															{recallToggle && (
																<div id="needFollowDiv" style={{ display: 'block' }}>
																	<div class="clinicContentList">
																		<div class="clinicalLabel pt-1 pb-2">
																			Recall Date &amp; Time
																		</div>
																		<div class="clinicalField">
																			<Datepicker
																				required
																				// id="appoinmentDateTime"
																				className="Datepicker pa2 inputField"
																				placeholderText="Date  Time"
																				dateFormat="dd-MM-y h:mm aa"
																				calendarClassName="rasta-stripes"
																				showTimeSelect
																				minDate={new Date()}
																				selected={recallDate}
																				value={recallDate}
																				onChange={(date) =>
																					setRecallDate(date)}
																			/>
																		</div>
																	</div>
																	<div class="clinicContentList">
																		<div class="clinicalLabel">
																			Comments
																		</div>
																		<div class="clinicalField">
																			<textarea
																				name="txtFollowNote"
																				id="txtFollowNote"
																				maxlength="100"
																				value={comments}
																				onChange={(e) => setComments(e.target.value)}
																				style={{ resize: 'none' }}
																			/>
																		</div>
																	</div>
																</div>
															)}
														</div>


													</div>
												</div>

											</div>

										</div>
									</div>
								</div>
							</div>
							{/* <div className="col-md-4 practiceRightSection simplifiedFormRightSec" style={{ paddingLeft: "10px", background: "#fff" }}> */}
							<div className="  practiceRightSection simplifiedFormRightSec simpliFormRight" style={{ paddingLeft: "10px", background: "#fff" }}>

								<SimplifiedFormCasesheetRight />
							</div>
						</div>
					</div>

				</div>
			</div>


		</div>
	);
})
export default SimplifiedFormCasesheet;
