import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from '../config';
import Pagination from 'react-js-pagination';
import Editicon from '../images/icons/edit-bg.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
function HospitalExpenses() {
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [hospitalExpenseList, sethospitalExpenseListData] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [count, setCount] = useState('');
	const [pageOffset, setPageOffset] = useState(0);
	const [dataFlag, setDataFlag] = useState(false);
	const [filterName, setFilterInput] = useState("");

	const [booliansearchValue, setbooliansearchValue] = useState("");
	const [boolianfilterName, setboolianfilterName] = useState("");
	const [boolianfromDate, setboolianfromDate] = useState("");
	const [booliantoDate, setbooliantoDate] = useState("");

	const handlePageChange = (pageNumber) => {
		//	setActivePage(pageNumber);
		var offset;
		var max;
		if (pageNumber === '1') {
			offset = '0';
			max = process.env.REACT_APP_PAGINATION_COUNT;
		} else {
			offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
			max = process.env.REACT_APP_PAGINATION_COUNT;
		}
		setActivePage(pageNumber);
		setPageOffset(offset);
		if (!boolianfilterName) { document.getElementById('selectName').value = "" }
		if (!booliansearchValue) { document.getElementById('searchText').value = "" }
		if (!boolianfromDate) { setStartDate("") }
		if (!booliantoDate) { setEndDate("") }
		window.sessionStorage.setItem("pageNumberExpenseDetails", pageNumber);
		window.sessionStorage.setItem("offsetExpenseDetails", offset);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: max,
			offset: offset,
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
		};

		callingAPI('hospitalInventory/hospitalExpense', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					sethospitalExpenseListData(data.data.result.data);
					setCount(data.data.result.totalCount);
				}
				else {
					sethospitalExpenseListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	const enterKey = (e) => {
		if (e.key === 'Enter') {
			searchValueChange();
		}
	}
	const MyContainerFromDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={FromDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const MyContainerToDate = ({ className, children }) => {
		return (
			<CalendarContainer className={className}>
				<div >
					<span className="clearCloseCalander">
						{children}
						<a className="clearCalander" onClick={ToDateClear} >Clear  </a>
						{/* <a  className="closeCalander">Close</a> */}
					</span>
				</div>
			</CalendarContainer>
		);
	};

	const FromDateClear = () => {
		setStartDate("")

	};

	const ToDateClear = () => {
		setEndDate("")

	};
	const handleDateChangeRaw = (e) => {
		e.preventDefault();
	}

	const changeText = (value) => {
		setFilterInput(value);
		if (document.getElementById('selectName').value !== '') {
			document.getElementById('searchText').disabled = false;
			document.getElementById('searchText').focus();
		} else {
			document.getElementById('searchText').disabled = true;
		}
		document.getElementById('searchText').value = '';
	};
	const searchValueChange = () => {
		if (startDate && endDate) {
			if (startDate > endDate) {
				alert('End date must be greater than start date');
				return false;
			}
		}

		if (document.getElementById('selectName').value !== '' && document.getElementById('searchText').value === '') {
			var t = document.getElementById('selectName');
			var selectedText = t.options[t.selectedIndex].text;
			document.getElementById('searchText').focus();
			alert('Enter ' + selectedText);
			return false;
		}
		setActivePage(1);
		setbooliansearchValue(document.getElementById('searchText').value);
		setboolianfilterName(document.getElementById('selectName').value);
		setboolianfromDate(startDate);
		setbooliantoDate(endDate);
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			offset: 0,
			searchId: document.getElementById('selectName').value,
			searchValue: document.getElementById('searchText').value,
			fromDate: startDate ? formatDate(startDate) : '',
			toDate: endDate ? formatDate(endDate) : '',
		};

		callingAPI('hospitalInventory/hospitalExpense', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					sethospitalExpenseListData(data.data.result.data);
					setCount(data.data.result.totalCount);
				}
				else {
					sethospitalExpenseListData([]);
					setCount([]);
				}
			})
			.catch();
	};
	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}
	useEffect(() => {
		// changeText(value);

		setActivePage(Number(window.sessionStorage.getItem("pageNumberExpenseDetails")));

		const PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			max: process.env.REACT_APP_PAGINATION_COUNT,
			// offset: pageOffset,
			offset: window.sessionStorage.getItem("offsetExpenseDetails"),
		};

		callingAPI('hospitalInventory/hospitalExpense', PostData)
			.then((data) => {
				sethospitalExpenseListData(data.data.result.data);
				setCount(data.data.result.totalCount);
				setDataFlag(true);
			})
			.catch();
	}, []);

	return (
		<div className="dashboardCover">
			<div className="row">
				<div className="col-md-9">
					<div className="dashboardTitle">Hospital Expenses</div>
				</div>
				<div className="col-md-3 dflexend">
					<span className="dashButtonBorder">
						<Link
							to="/settings/addHospitalExpenses"
							style={{ color: '#393939', textDecoration: 'none', width: '190px', padding: '6px 5px' }}
						>
							Add Hospital Expenses
						</Link>
					</span>
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			<div className="dashboardContent">
				<div className="reportWrap">
					<div className="dashboardSearch">
						<div className="row">
							<div className="col-md-2 dashboardSearchList">
								<select
									autocomplete="off"
									className="inputField"
									id="selectName"
									name="selectName"
									onChange={(e) => changeText(e.target.value)}
								>
									<option value="">Select</option>
									<option value="1">Account Head</option>
									<option value="2">Item Name</option>
								</select>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<input
									autocomplete="off"
									className="inputField"
									type="text"
									id="searchText"
									name="searchText"
									disabled={filterName === "0" ? true : false}
									style={{ background: filterName === "" ? "transparent" : "white" }}
									onKeyDown={enterKey}
								/>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker

									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"

									closeCalendar="true"
									className="Datepicker pa2 inputField input-design"
									maxDate={new Date()}
									placeholderText="From"
									calendarClassName="rasta-stripes"
									selected={startDate}
									dateFormat="dd-MM-yyyy"
									onChange={(date) => setStartDate(date)}
									id="fromdate"
									autoComplete='off'
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerFromDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="fromdate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<DatePicker
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="select"

									className="Datepicker pa2 inputField"
									maxDate={new Date()}
									placeholderText="To"
									calendarClassName="rasta-stripes"
									selected={endDate}
									dateFormat="dd-MM-yyyy"
									autoComplete='off'
									onChange={(date) => setEndDate(date)}
									id="todate"
									onChangeRaw={handleDateChangeRaw}
									calendarContainer={MyContainerToDate}
								/>{' '}
								<label
									className="icon iconCalendar calendar plcalendar"
									style={{ fontSize: '16px' }}
									htmlFor="todate"
								>
									<FontAwesomeIcon icon={faCalendarAlt} />
								</label>
							</div>
							<div className="col-md-2 dashboardSearchList">
								<button

									className="dashButtonBorder"
									onClick={searchValueChange}
									title="Search" style={{ width: '100%' }}>
									Search
								</button>
							</div>
						</div>
					</div>
					{dataFlag == false ? (
						<Loader />
					) : hospitalExpenseList.length > 0 ? (
						<div className="dashboardtable">
							<table>
								<thead>
									<tr>
										<th style={{ width: "165px" }}>Account Head</th>
										<th style={{ width: "245px" }}>Item Name</th>
										<th style={{ width: "122px" }}>Date Incurred</th>
										<th style={{ width: '60px', textAlign: "center" }}>Qty</th>
										<th style={{ textAlign: "right", width: "100px" }}>Unit Price</th>

										<th style={{ textAlign: "right", width: "100px" }}>Total Cost</th>
										<th>Description</th>
										<th style={{ width: "60px" }}></th>
									</tr>
								</thead>
								<tbody>
									{hospitalExpenseList.map((item, index) => (
										<tr>
											<td>
												<span title={item.categoryName}
													className="refundReason ">{item.categoryName}</span>
											</td>
											<td>
												<span title={item.hospitalExpenseItemName}
													className="refundReason elipsis1" style={{ width: "98%" }}>
													{item.hospitalExpenseItemName}
												</span>
											</td>
											<td>{item.CreateOn}</td>
											<td style={{ textAlign: "center" }}>
												<span title={item.hospitalExpenseItemQuantity}
													className="refundReason elipsis1" style={{ textAlign: "center", width: "100%" }}>
													{item.hospitalExpenseItemQuantity}
												</span>
											</td>
											<td style={{ textAlign: "right" }}>
												<span title={item.hospitalExpenseItemUnitPrice}
													className="refundReason elipsis1" style={{ textAlign: "right", width: "100%" }}>
													&#8377; {item.hospitalExpenseItemUnitPrice}
												</span>
											</td>

											<td style={{ textAlign: "right" }}>
												<span title={item.hospitalExpenseItemTotalCost}
													className="refundReason elipsis1" style={{ textAlign: "right", width: "100%" }}>
													&#8377; {item.hospitalExpenseItemTotalCost}
												</span>
											</td>
											<td>
												<span title={item.hospitalExpenseDescription}
													className="refundReason elipsis1" style={{ width: "100%" }}>
													{item.hospitalExpenseDescription}
												</span>
											</td>
											<td>
												<Link
													to={{
														pathname: '/settings/editHospitalExpenses',
														state: { hospitalExpenseId: item.hospitalExpenseId },
													}}
												>
													<img src={Editicon} title="Edit" />
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<p>
							<NoData />
						</p>
					)}
				</div>
			</div>
			{count > 10 && (
				<div className="paginationSection">
					<Pagination
						// hideNavigation
						hideDisabled
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePageChange}
					/>
				</div>
			)}
			{/* {hospitalExpenseList.length == 0 && <NoData />} */}
		</div>
	);
}

export default HospitalExpenses;
