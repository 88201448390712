import React, { useState, useEffect } from 'react';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
function PrescriptionAmountdetails(props) {
    const [prescriptionAmountDetailsArray, setPrescriptionAmountDetailsArray] = useState([]);
    const [doctorVisistMedicineTotalAmount, setDoctorVisistMedicineTotalAmount] = useState("");
    const [load, setLoad] = useState(false);
    useEffect(() => {
        const PostData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            doctorId: props.location.state.data,
            fromDate: props.location.state.fDate && props.location.state.fDate != "NaN-NaN-NaN" ? props.location.state.fDate : "",
            toDate: props.location.state.tDate && props.location.state.tDate != "NaN-NaN-NaN" ? props.location.state.tDate : ""
        };
        callingAPI('reports/prescriptionAmountDetails', PostData)
            .then((data) => {
                setPrescriptionAmountDetailsArray(data.data.result.prescriptionAmountDetailsArray);
                setDoctorVisistMedicineTotalAmount(
                    data.data.result.totalAmount
                );
                setLoad(true)
            })
            .catch();
    }, []);
    return (
        <div className="dashboardCover">
            <div className="breadcrumbDoc">
                <ul>
                    <li>
                        <a href="/reports/prescriptionAmount" className="blueLink">
                            Prescription(s) Amount
                        </a>
                    </li>{' '}
                    <div className="arrow-right" />
                    <li> Prescription(s) Amount Details </li>
                </ul>
            </div>
            <div className="dashboardTitle">Prescription(s) Amount Details</div>
            <div className="dashboardContent">
                {load === false ? <Loader /> : ''}
                <div className="reportWrap">
                    <div className="dashboardSearch" style={{ background: 'transparent' }}>
                        <div className="row">
                            <div className="col-md-12 dashboardSearchList">
                                <div className="searchCount">
                                    <b>Total Amount :
                                        &#8377; {doctorVisistMedicineTotalAmount === "" ? "0.00" : doctorVisistMedicineTotalAmount.toFixed(2)}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboardtable">
                        <table className="PrescriptionAmnt">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Medicine Name</th>
                                    <th>Quantity</th>
                                    <th style={{ textAlign: 'right' }}> Price</th>
                                    {/* <th>Instructions of taking Medicine</th> */}

                                    <th style={{ textAlign: 'right' }}>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prescriptionAmountDetailsArray.map(data => (
                                    <tr>
                                        <td>{data.prescribedMedicineDate}</td>

                                        <td>
                                            {data.prescribedMedicineDetails.map(item => (
                                                <div>	{item.medicineName}</div>
                                            ))}</td>

                                        <td>{data.prescribedMedicineDetails.map(item => (
                                            <div> {item.totalQty}</div>
                                        ))}</td>
                                        <td style={{ textAlign: 'right' }}>
                                            {data.prescribedMedicineDetails.map(item => (

                                                <div> &#8377; {item.price == "" ? item.price : parseFloat(item.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))}</div>

                                            ))}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>{data.prescribedMedicineDetails.map(item => (

                                            <div>&#8377; {parseFloat(item.totalAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))}</div>
                                            // <div>&#8377; {item.totalAmount}</div>

                                        ))}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default PrescriptionAmountdetails;
