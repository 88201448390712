import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import Datepicker, { CalendarContainer } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { callingAPI } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
function LabTestInventory() {
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [MedicineDetails, setMedicineDetails] = useState([]);
    const [count, setTotalCount] = useState();
    const [activePage, setActivePage] = useState(1);
    const [pageOffset, setPageOffset] = useState(0);
    const [isData, setIsData] = useState(0);
    const handlePagination = (pageNumber) => {
      setActivePage(pageNumber);
      var offset;
      var max;
      if (pageNumber === '1') {
        offset = '0';
        max = process.env.REACT_APP_PAGINATION_COUNT;
      } else {
        offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
        max = process.env.REACT_APP_PAGINATION_COUNT;
      }
      setActivePage(pageNumber);
      setPageOffset(offset);
      let PostData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            max: max,
			      offset: offset,
        };
      callingAPI('reports/labTestInventory', PostData)
        .then((data) => {
          if (data.data.success === '1') {
            setMedicineDetails(data.data.result.MedicineDetails);
            setTotalCount(data.data.result.count);
            setIsData(1);
          }
        })
        .catch();
    };
    function formatDate(date) {
      var date = new Date(date),
        month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('-');
    }
    const searchFunction = () => {
      // setIsData(0);
      if (fromDate && toDate) {
        if (fromDate > toDate) {
          alert('End date must be greater than start date');
          document.getElementById("todate").focus()
          return (false);
        }
      }
      let PostData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            max: process.env.REACT_APP_PAGINATION_COUNT,
            offset: 0,
            txtInput: document.querySelector('#searchText').value,
            fromDate: fromDate ? formatDate(fromDate) : '',
			      toDate: toDate ? formatDate(toDate) : ''
      };
      callingAPI('reports/labTestInventory', PostData)
        .then((data) => {
          if (data.data.success === '0') {
            setMedicineDetails([]);
            setIsData(1);
          }
          if (data.data.success === '1') {
            setMedicineDetails(data.data.result.MedicineDetails);
            setTotalCount(data.data.result.count);
            setIsData(1);
          }
        })
        .catch();
    };
    useEffect(() => {
      const PostData = {
        userId: window.sessionStorage.getItem('userId'),
        role: window.sessionStorage.getItem('role'),
        max: process.env.REACT_APP_PAGINATION_COUNT,
        offset: pageOffset,
        txtInput: document.querySelector('#searchText').value,
        fromDate: fromDate ? formatDate(fromDate) : '',
			  toDate: toDate ? formatDate(toDate) : ''
      };
      callingAPI('reports/labTestInventory', PostData)
        .then((data) => {
          if (data.data.success === '1') {            
            setMedicineDetails(data.data.result.MedicineDetails);
            setTotalCount(data.data.result.count);
            setIsData(1);
          }
          else {
            setMedicineDetails([]);
            setTotalCount(0);
            setIsData(1);
          }
        })
        .catch();
    }, []);
    const downloadName = "labTestList";
    function exportListDownload() {
      const csvRow = [];
      var labTestlists = [
        [
          "testName"
        ],
      ];
      var name = MedicineDetails;
      let postData = {
            userId: window.sessionStorage.getItem('userId'),
            role: window.sessionStorage.getItem('role'),
            max: '',
            offset: 0,
            txtInput: document.querySelector('#searchText').value,
            fromDate: fromDate ? formatDate(fromDate) : '',
			      toDate: toDate ? formatDate(toDate) : ''
      };
      callingAPI('reports/labTestInventory', postData).then((data) => {
        for (var item = 0; item < data.data.result.MedicineDetails.length; item++) {
          labTestlists.push([
            data.data.result.MedicineDetails[item].testName
          ]);
        }
        for (var i = 0; i < labTestlists.length; ++i) {
          csvRow.push(labTestlists[i].join("|"));
        }
        var csvString = csvRow.join("%0A");
        var creat = document.createElement("a");
        creat.href = "data:attachment/csv," + csvString;
        creat.download = downloadName + ".csv";
        document.body.appendChild(creat);
        creat.click();
      })
    }
  const FromDateClear = () => {
    setFromDate("")
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
          </span>

        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  const ToDateClear = () => {
    setToDate("")
  };
  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <div className="dashboardTitle">Lab Test Inventory</div>
      <div>
        <div className="dashboardContent">
        {count > 10 && (
				<div className="paginationSection">
					<Pagination
						activePage={activePage}
						itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
						totalItemsCount={count}
						pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
						onChange={handlePagination}
					/>
				</div>
			)}
          <div className="reportWrap">
            <div className="dashboardSearch">
              <div className="row">

                <div className="col-md-2 dashboardSearchList">

                  <input
                    type="text"
                    className="inputField"
                    placeholder="Test Name"
                    id="searchText"
                    // value={searchText}
                    // onChange={(e) => setllabTestUnavilabilitySelectTest(e.target.value)}
                    name="searchText"
                  />
                </div>
                <div className="col-md-2 dashboardSearchList">
                  <div>
                  <Datepicker
										autoComplete='off'
										required
										className="Datepicker pa2 inputField"
										calendarClassName="rasta-stripes"
										// maxDate={new Date()}
										placeholderText="From Date"
										selected={fromDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setFromDate(date)}
										id="fromdate"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										calendarContainer={MyContainer}
										onChangeRaw={handleDateChangeRaw}
									/>
                    <label
                      className="icon iconCalendar calendar plReportsCalendar"
                      style={{ fontSize: "16px" }}
                      htmlFor="fromdate"
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </label>
                  </div>

                </div>
                <div className="col-md-2 dashboardSearchList">

                  <div>
                  <Datepicker
										autoComplete='off'
										required
										className="Datepicker pa2 inputField"
										calendarClassName="rasta-stripes"
										// maxDate={new Date()}
										placeholderText="To Date"
										selected={toDate}
										dateFormat="dd-MM-yyyy"
										onChange={(date) => setToDate(date)}
										id="toDate"
										peekNextMonth
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										onKeyDown={(e) => {
											e.preventDefault();
										}}
										calendarContainer={MyContainerTo}
										onChangeRaw={handleDateChangeRaw}
									/>
                    <label
                      className="icon iconCalendar calendar plReportsCalendar"
                      style={{ fontSize: "16px" }}
                      htmlFor="todate"
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </label>
                  </div>

                </div>
                <div className="col-md-2 dashboardSearchList">
                  <span
                    className="dashButtonBorder" onClick={searchFunction} title="Search"

                  >
                    Search
                  </span>
                </div>

                <div className="col-md-2 dashboardSearchList">
                  <button
                    type="button"
                    style={{ width: "100%" }}
                    id="export"
                    className="dashButtonBorder dashButtonBorderGrey"
                    onClick={exportListDownload}
                  >
                    Export
                  </button>
                </div>

              </div>
              <div className="searchCount">Total Count :{' '}
										{MedicineDetails.length == 0 ? 0 : count}</div>
            </div>
            <div className="dashboardtable">
            {isData == 0 ? (
							<Loader />
						) : MedicineDetails.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Test Name</th>
                    <th > </th>
                  </tr>
                </thead>
                <tbody>
                {MedicineDetails.map((index) => (
                  <tr>
                    <td>{index.testName}</td>
                  </tr>
))}
                </tbody>                
              </table>
              	) : (
                  <NoData />
                )}
            </div>
          </div>

          {count > 10 && (
					<div className="paginationSection">
						<Pagination
							// hideNavigation
							activePage={activePage}
							itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
							totalItemsCount={count}
							pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
							onChange={handlePagination}
						/>
					</div>
				)}

        </div>


      </div>

    </div>
  );
  }
export default LabTestInventory;
