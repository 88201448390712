import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { callingAPI, callingPostAPI } from '../config';
import moment from 'moment';
import Loader from '../Common/loader';

function EditPurchaseDetails() {
	const [supplierInvoiceId, setSupplierInvoiceId] = useState('');

	const [supplierInvoiceMedicinesId, setsupplierInvoiceMedicinesId] = useState('');
	const [supplierInvoiceNumber, setSupplierInvoiceNumber] = useState('');
	const [supplierInvoiceDate, setSupplierInvoiceDate] = useState('');
	const [supplierName, setSupplierName] = useState('');
	const [supplierInvoiceStatus, setSupplierInvoiceStatus] = useState('');

	const [taxListdataPurchase, setTaxListDataPurchase] = useState([]);

	const [taxCalculationPurchase, setTaxCalculationPurchase] = useState(0);
	const [CategoryData, setCategoryData] = useState([])
	const [selectedTaxNewArray, setSelectedTaxNewArray] = useState([]);
	const [selectedTaxPurchaseNewArray, setSelectedTaxPurchaseNewArray] = useState([]);
	const [isData, setIsData] = useState(true);
	const [taxCalculation, setTaxCalculation] = useState(0);
	const [manufactureAuto, setManufactureAuto] = useState([]);
	const [medicineAuto, setMedicineAuto] = useState([]);
	const [disposableAuto, setDisposableAuto] = useState([]);
	const [medicineUnits, setMedicineUnits] = useState([]);
	const [medicineTypes, setMedicineTypes] = useState([]);
	const [taxListdata, setTaxListData] = useState([]);
	const [isShowPharmacy, setShowPharmacy] = useState(false);
	const [isShowGeneric, setIsGeneric] = useState(false);
	const [mManufactureName, setmManufactureName] = useState('');
	const [mManufactureId, setmManufactureId] = useState('');
	const [categoryType, setcategoryType] = useState(0);
	const [categoryTypeName, setcategoryTypeName] = useState('');
	const [brandName, setBrandName] = useState('');
	const [medicineId, setMedicineId] = useState('');
	const [genericName, setGenericName] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const [medType, setMedType] = useState('');
	const [medTypeName, setMedTypeName] = useState('');
	const [strength, setStrength] = useState('');
	const [medUnit, setMedUnit] = useState('');
	const [medUnitName, setMedUnitName] = useState('');
	const [interactions, setInteractions] = useState('');
	const [drugReactions, setDrugReactions] = useState('');
	const [majorSideEffects, setMajorSideEffects] = useState('');
	const [dosage, setDosage] = useState('');
	const [precautions, setPrecautions] = useState('');
	const [stripCount, setStripCount] = useState('');
	const [batchNo, setBatchNo] = useState('');
	const [stockQuantity, setStockQuantity] = useState('');
	const [expireDate, setExpireDate] = useState('');
	const [reOrderQuantity, setReorderQuantitu] = useState('');

	const [itemName, setItemName] = useState('');
	const [itemNameID, setItemNameId] = useState('');
	const [masterCode, setMasterCode] = useState('');
	const [noOfUse, setnoOfUse] = useState('');
	const [disposablehsnCode, setDisposableHsnCode] = useState('');
	const [disposablebatchNo, setDisposableBatchNo] = useState('');
	const [disposablestockQuantity, setDisposableStockQuantity] = useState('');
	const [disposableexpireDate, setDisposableExpireDate] = useState('');
	const [disposablereOrderQuantity, setDisposableReorderQuantitu] = useState('');
	const [pmhospitalMedicineId, setpmHospitalMedicineId] = useState('');
	const [manufactError, setManufactError] = useState('');

	const [mrp, setMrp] = useState("");
	const [mrpUnitPrice, setmrpUnitPrice] = useState("");
	const [sellingPriceWithoutTax, setSellingPriceWithoutTax] = useState("");
	const [sellingUnitPriceWithoutTax, setSellingUnitPriceWithoutTax] = useState("");
	const [sellingPriceWithTax, setSellingPriceWithTax] = useState("");
	const [sellingUnitPriceWithTax, setSellingUnitPriceWithTax] = useState("");

	const [purchasePrice, setPurchasePrice] = useState("");
	const [purchaseUnitPrice, setPurchaseUnitPrice] = useState("");
	const [purchasePriceWithTax, setPurchasePriceWithTax] = useState("");
	const [purchaseUnitPriceWithTax, setPurchaseUnitPriceWithTax] = useState("");
	const [popVisible, setPopVisible] = useState(false)


	const location = useLocation();
	const [selectedTaxIdList, setSelectedTaxIdList] = useState([]);
	const [selectedTaxIdPurchaseList, setSelectedTaxIdPurchaseList] = useState([]);


	var selectedTaxListdata = [];
	var selectedTaxListPurchasedata = [];
	var date = new Date();
	var requiredDate = moment(date).format('YYYY-MM');
	var toDay = requiredDate.split('-');
	toDay = new Date(toDay[2], toDay[1] - 1, toDay[0]);
	const [onlycheckedTaxList, setOnlyCheckedTaxList] = useState([]);
	const [onlycheckedTaxListPurchase, setOnlyCheckedTaxListPurchase] = useState([]);

	const onChangeisGeneric = (event) => {
		setIsGeneric(event.target.checked);
	};
	const onChangeShowPharmacy = (event) => {
		setShowPharmacy(event.target.checked);
	};
	const onChangeGenericName = (event) => {
		setGenericName(event.target.value);
	};
	const onChangeHsnCode = (event) => {
		setHsnCode(event.target.value);
	};
	const onChangeInteraction = (event) => {
		setInteractions(event.target.value);
	};
	const onChangeDrugReactions = (event) => {
		setDrugReactions(event.target.value);
	};
	const onChangeMajorSideEffects = (event) => {
		setMajorSideEffects(event.target.value);
	};
	const onChangeDosage = (event) => {
		setDosage(event.target.value);
	};
	const onChangePrecautions = (event) => {
		setPrecautions(event.target.value);
	};
	const onChangeStripCount = (event) => {
		if (!categoryType) {
			alert('Please Select Category Type');
			return;
		}
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStripCount(event.target.value);
			setMrp('');
			setmrpUnitPrice('');
			setSellingPriceWithoutTax('');
			setSellingUnitPriceWithoutTax('');
			setSellingPriceWithTax('');
			setSellingUnitPriceWithTax('');
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeBatchNo = (event) => {
		setBatchNo(event.target.value);
	};
	const onChangeStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setStockQuantity(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeExpireDate = (event) => {
		setExpireDate(event.target.value);
	};
	const onChangeReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setReorderQuantitu(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};



	const stripCountBy = () => {
		if (categoryType == 1) {
			var price = mrp;
			var quantity = stripCount;
			var totalCost = price / quantity;
			setmrpUnitPrice(parseFloat(totalCost).toFixed(2));
			setSellingUnitPriceWithoutTax(parseFloat(totalCost).toFixed(2));
			setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
		}
	};

	const stripCountBySellingArea = (event) => {
		var quantity = stripCount;
		if (document.getElementById("sellingPricewithoutTax").value == "") {
			setSellingUnitPriceWithoutTax(sellingPriceWithoutTax);
			setSellingPriceWithTax(sellingPriceWithoutTax);
			setSellingUnitPriceWithTax(sellingPriceWithoutTax);
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc1 = 0;
			var quantity = stripCount;
			var totalCost = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc1 = taxCalc1 + (sellingPriceWithoutTax * Number(val.hospitalTaxDetailsTaxValue) / 100);
				}

				setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
				setSellingUnitPriceWithTax(parseFloat(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)).toFixed(2));
				setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)))
				if (categoryType == 1) {
					setSellingPriceWithTax(parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1));
					totalCost = (parseFloat(sellingPriceWithoutTax) + parseFloat(taxCalc1)) / quantity;
					setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
					setSellingUnitPriceWithoutTax((parseFloat(sellingPriceWithoutTax)) / quantity)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value / quantity);
				setSellingUnitPriceWithoutTax(event.target.value / quantity);
			}
			else {
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
			}



		}
	};

	const stripCountBySellingAreawithTax = (event) => {
		var stripcount = stripCount;
		if (document.getElementById("sellingPricewithTax").value == "") {
			setSellingPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithoutTax(sellingPriceWithTax);
			setSellingUnitPriceWithTax(sellingPriceWithTax)
		}
		else if (taxListdata.length != 0) {
			let obj = [...taxListdata]
			let taxCalc = 0;
			let sellingPriceMultiple = 0;
			let totalTaxValue = 0;
			let totalTaxCalculation = 0;
			obj.map((val, index) => {
				if (val.isChecked == true) {
					taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				}
				sellingPriceMultiple = sellingPriceWithTax * 100;
				totalTaxValue = taxCalc + 100;
				totalTaxCalculation = sellingPriceMultiple / totalTaxValue;

				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)))
				if (categoryType == 1) {
					var stripcount = stripCount;
					var unitSellingPice = totalTaxCalculation / stripcount;
					setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
					setSellingUnitPriceWithTax((parseFloat(sellingPriceWithTax)) / stripcount)
				}
			});
		}
		else {
			if (categoryType == 1) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value / stripCount);
				setSellingUnitPriceWithTax(event.target.value / stripCount);
			}
			else {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			}
		}
	};

	const onChangemrp = (event) => {
		if (!categoryType) {
			alert('Please Select Category Type');
			return;
		}
		const re = /^[0-9.\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setMrp(event.target.value);
			setSellingPriceWithoutTax(event.target.value);
			setSellingPriceWithTax(event.target.value);
		} else {
			alert('Only Numbers Accepted');
			return
		}
		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');
				setSelectedTaxNewArray([])
			}


			setSelectedTaxIdList([])
			// setSelectedTaxIdPurchaseList([])
		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setMrp(event.target.value);
				setmrpUnitPrice(event.target.value);
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}
			setSelectedTaxIdList([])
			// setSelectedTaxIdPurchaseList([])
		}
	};

	const onChangeSellingPriceAreawithTax = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');

			}
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				//setSellingPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}


		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				//setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}

		}
	};
	const onChangeSellingPriceArea = (event) => {
		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				setMrp('');
				setmrpUnitPrice('');
				setSellingPriceWithoutTax('');
				setSellingUnitPriceWithoutTax('');
				setSellingPriceWithTax('');
				setSellingUnitPriceWithTax('');

			}
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setSellingPriceWithoutTax(event.target.value);
				//setSellingPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}


		} else {
			const re = /^[0-9.\b]+$/;
			if (event.target.value === '' || re.test(event.target.value)) {
				setSellingPriceWithoutTax(event.target.value);
				setSellingUnitPriceWithoutTax(event.target.value);
				//setSellingPriceWithTax(event.target.value);
				setSellingUnitPriceWithTax(event.target.value);
			} else {
				alert('Only Numbers Accepted');
			}

		}

	};
	const onChangeMasterCode = (event) => {
		setMasterCode(event.target.value);
	};
	const onChangeNoOfUse = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setnoOfUse(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeDisposableHsnCode = (event) => {
		setDisposableHsnCode(event.target.value);
	};

	const onChangeDisposableStockQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setDisposableStockQuantity(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	const onChangeDisposableExpireDate = (event) => {
		setDisposableExpireDate(event.target.value);
	};
	const onChangeDisposableReOrderQuantity = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setDisposableReorderQuantitu(event.target.value);
		} else {
			alert('Only Numbers Accepted');
		}
	};
	let taxCalcPurchase = 0;
	const updateInventoryDetails = () => {
		setIsData(false);
		let flag = true;

		if (categoryType == 1) {
			if (!stripCount) {
				alert('NO: of Units Required');
				document.getElementById('hdnMedicineQuantity').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 300);
			} else if (!stockQuantity) {
				alert('Stock Quantity Required');
				document.getElementById('hdnMedicineStockQuantity').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 500);
			} else if (!expireDate) {
				alert('Expire Date Required');
				document.getElementById('scheduleDate').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 500);
			} else if (!reOrderQuantity) {
				alert('Reorder quantity Required');
				document.getElementById('hdnMedicineThreshold').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 500);
			} else if (!purchasePrice) {
				alert('Purchase Price Required');
				document.getElementById('txtPrice').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!purchaseUnitPrice) {
				alert('Purchase UnitPrice Required');
				document.getElementById('txtUntPrice').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!mrp) {
				alert('MRP Required');
				document.getElementById('mrp').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!mrpUnitPrice) {
				alert('MRP UnitPrice Required');
				document.getElementById('mrpUnitPrice').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!sellingPriceWithoutTax) {
				alert('Selling Price WithoutTax Required');
				document.getElementById('sellingPricewithoutTax').focus();
				flag = false;
				setIsData(true);
			} else if (!sellingUnitPriceWithoutTax) {
				alert('Selling UnitPrice WithoutTax Required');
				document.getElementById('sellingUnitPricewithoutTax').focus();
				flag = false;
				setIsData(true);
			} else if (!sellingPriceWithTax) {
				alert('Selling Price WithTax Required');
				document.getElementById('sellingPricewithTax').focus();
				flag = false;
				setIsData(true);
			} else if (!sellingUnitPriceWithTax) {
				alert('Selling UnitPrice WithTax Required');
				document.getElementById('sellingUnitPricewithTax').focus();
				flag = false;
				setIsData(true);
			}
			else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
				alert('MRP should not be less than Selling Price');
				flag = false;
				setIsData(true);
			}
			else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
				alert('MRP should not be less than Selling Price');
				flag = false;
				setIsData(true);
			}
		} else {
			//// disposable and other category
			if (!disposablestockQuantity) {
				alert('Stock Quantity Required');
				document.getElementById('stockQuantity').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 300);
			}

			else if (!disposablereOrderQuantity) {
				alert('Reorder quantity Required');
				document.getElementById('threshold').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 300);
			}
			else if (!mrp) {
				alert('MRP Required');
				document.getElementById('mrp').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!purchasePrice) {
				alert('Purchase Price Required');
				document.getElementById('txtPrice').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!purchaseUnitPrice) {
				alert('Purchase UnitPrice Required');
				document.getElementById('txtUntPrice').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!mrpUnitPrice) {
				alert('MRP UnitPrice Required');
				document.getElementById('mrpUnitPrice').focus();
				flag = false;
				setIsData(true);
				window.scrollTo(0, 900);
			} else if (!sellingPriceWithoutTax) {
				alert('Selling Price WithoutTax Required');
				document.getElementById('sellingPricewithoutTax').focus();
				flag = false;
				setIsData(true);
			} else if (!sellingUnitPriceWithoutTax) {
				alert('Selling UnitPrice WithoutTax Required');
				document.getElementById('sellingUnitPricewithoutTax').focus();
				flag = false;
				setIsData(true);
			} else if (!sellingPriceWithTax) {
				alert('Selling Price WithTax Required');
				document.getElementById('sellingPricewithTax').focus();
				flag = false;
				setIsData(true);
			} else if (!sellingUnitPriceWithTax) {
				alert('Selling UnitPrice WithTax Required');
				document.getElementById('sellingUnitPricewithTax').focus();
				flag = false;
				setIsData(true);
			}
			else if (parseFloat(sellingPriceWithoutTax) > parseFloat(mrp)) {
				alert('MRP should not be less than Selling Price');
				flag = false;
				setIsData(true);
			}
			else if (parseFloat(sellingPriceWithTax) > parseFloat(mrp)) {
				alert('MRP should not be less than Selling Price');
				flag = false;
				setIsData(true);
			}
		}

		if (flag === true) {
			var selectedtaxvaluesArr = [];
			var selectedtaxvaluesArr = taxListdata.reduce(
				(a, o) => (o.isChecked && a.push(o.hospitalTaxDetailsId), a),
				[]
			);
			var selectedtaxvaluesPurchaseArr = [];
			var selectedtaxvaluesPurchaseArr = taxListdataPurchase.reduce(
				(a1, o1) => (o1.isChecked && a1.push(o1.hospitalTaxDetailsId), a1),
				[]
			);
			setSelectedTaxNewArray([...selectedtaxvaluesArr]);
			setSelectedTaxPurchaseNewArray([...selectedtaxvaluesPurchaseArr]);
			var today = new Date();

			var currentDate = today.getDate();
			if(currentDate <= 9)
			{
				currentDate = '0'+currentDate;
			}
			const postData = {
				pmHospitalMedicineId: pmhospitalMedicineId,
				medicine: categoryType == 1 ? brandName : '',
				role: window.sessionStorage.getItem('role'),
				isPurchase: true,
				hospitalInventoryCategoryId: categoryType,
				cmbSpeci: '',
				medicineId: categoryType == 1 ? medicineId : '',
				medicineGenericName: categoryType == 1 ? genericName : '',
				medicineIndicationAndDosage: categoryType == 1 ? dosage : '',
				userId: window.sessionStorage.getItem('userId'),
				medicineBrandId: '',
				medicineQuantity: categoryType == 1 ? stripCount : '',
				medicineDrugReactions: categoryType == 1 ? drugReactions : '',
				medicineMajorSideEffects: categoryType == 1 ? majorSideEffects : '',
				manufactureId: categoryType == 1 ? document.getElementById('hiddenManufacId').value : '',
				medicinePrecautions: categoryType == 1 ? precautions : '',
				medicineTypeId: categoryType == 1 ? '' + medType : '',
				medicineUnitId: categoryType == 1 ? '' + medUnit : '',
				medicineStrength: categoryType == 1 ? '' + strength : '',
				medicineHsnCode: categoryType == 1 ? hsnCode : '',
				medicineForDentalAndMedical: '',
				medicineSpecializationId: '',
				medicineInteractions: categoryType == 1 ? interactions : '',
				pmHospitalMedicineBatchNo: categoryType == 1 ? batchNo : disposablebatchNo,
				hospitalInventoryMasterHsnCode: categoryType == 1 ? '' : disposablehsnCode,
				hospitalInventoryMasterId: categoryType == 1 ? '' : itemNameID,
				hospitalInventoryMasterCode: categoryType == 1 ? '' : masterCode,
				hospitalInventoryMasterName: categoryType == 1 ? '' : itemName,
				hospitalInventoryMasterNoOfUse: categoryType == 1 ? '' : noOfUse,

				pmHospitalMedicineMrp: mrp,
				pmHospitalMedicineMrpUnitPrice: mrpUnitPrice,
				pmHospitalMedicineQty: categoryType == 1 ? stripCount : '',
				pmHospitalMedicineCode: '',
				pmHospitalMedicineNoOfUse: categoryType == 1 ? '' : noOfUse,
				pmHospitalMedicineIsDisposableItem: categoryType == 1 ? false : isShowPharmacy,
				isGeneric: categoryType == 1 ? isShowGeneric : false,
				pmHospitalMedicineHsnCode: categoryType == 1 ? hsnCode : disposablehsnCode,
				pmHospitalMedicineExpiryDate: categoryType == 1 ? expireDate + "-" + currentDate : disposableexpireDate + "-" + currentDate,
				pmHospitalMedicineThreshold: categoryType == 1 ? reOrderQuantity : disposablereOrderQuantity,
				pmHospitalMedicinePrice: sellingPriceWithoutTax,
				pmHospitalMedicineUnitPrice: sellingUnitPriceWithoutTax,
				pmHospitalMedicineStockQty: categoryType == 1 ? stockQuantity : disposablestockQuantity,

				hospitalId: localStorage.getItem("hospitalId"),
				// selectedTax: selectedTaxNewArray,
				selectedTax: selectedtaxvaluesArr,

				supplierInvoiceMedicinesMrp: mrp,
				supplierInvoiceMedicinesMrpUnitPrice: mrpUnitPrice,
				supplierInvoiceMedicinesSellingPrice: sellingPriceWithoutTax,
				supplierInvoiceMedicinesSellingUnitPrice: sellingUnitPriceWithoutTax,

				supplierInvoiceId: supplierInvoiceId
					? supplierInvoiceId
					: '',
				// supplierInvoiceMedicinesTotalTax: taxCalcPurchase,
				supplierInvoiceMedicinesTotalTax: selectedTaxPurchaseNewArray,

				supplierInvoiceMedicinesPrice: purchasePrice,
				supplierInvoiceMedicinesUnitPrice: purchaseUnitPrice,
				supplierInvoiceMedicinesPriceWithTax: purchasePriceWithTax,
				supplierInvoiceMedicinesUnitPriceWithTax: purchaseUnitPriceWithTax,
			};

			callingAPI('inventoryStockUpdate/addInventoryStock', postData).then((data) => {
				setIsData(true);
				if (data.data.success === '1') {
					alert('Purchase Stock Updated Successfully');
					//window.location.href = '/settings/addItemDetails';
					window.location.href = '/settings/purchaseLists';

				} else {
					alert(data.data.errorMessage);
				}
			});
		}
	};

	const setManufactureClick = (name, id) => {
		document.getElementById('manufactureInput').value = name;
		document.getElementById('hiddenManufacId').value = id;
		setManufactureAuto([]);
		document.getElementById('manufactureList').style.display = 'none';
	};
	const getManufactureAutoComplete = () => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
			keyWord: document.getElementById('manufactureInput').value,
		};
		if (document.getElementById('manufactureInput').value.length > 1) {

			callingAPI('inventoryStockUpdate/getManuFactureAutocomplete', postdata)
				.then((res) => {
					if (res.data.success === '1' && res.data.result.manufactureDetails.length > 0) {
						document.getElementById('manufactureList').style.display = 'block';
						setManufactureAuto(res.data.result.manufactureDetails);
						setManufactError('');
					} else {
						document.getElementById('manufactureList').style.display = 'none';
						setManufactureAuto([]);
						setManufactError('Please Add Manufacturer');
					}
				})
				.catch();
		}
		else if (document.getElementById('manufactureInput').value.length === 0) {
			document.getElementById('manufactureList').style.display = 'none';
			setManufactureAuto([]);
			setManufactError('Please Add Manufacturer');
		}
	};
	const addNewManufacture = () => {
		var email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var phNum = /^\d{10}$/;
		let flagManufacture = true;
		if (!document.getElementById('manufactureName').value) {
			alert('Manufacturer Name Required');
			flagManufacture = false;
			return;
		}
		if (!document.getElementById('manufactureNumber').value) {
			alert('Contact Number Required');
			flagManufacture = false;
			return;
		}
		if (document.getElementById('manufactureNumber').value !== '') {
			if (!document.getElementById('manufactureNumber').value.match(phNum)) {
				alert('Valid Contact Number Required');
				document.getElementById('manufactureNumber').focus();
				return;
			}
		}
		if (document.getElementById('manufactureEmail').value !== '') {
			if (!document.getElementById('manufactureEmail').value.match(email)) {
				alert('Valid Email Required');
				document.getElementById('manufactureEmail').focus();
				return;
			}
		}
		if (flagManufacture === true) {
			const postdata = {
				manufactureName: document.getElementById('manufactureName').value,
				manufactureAddress: document.getElementById('manufactureAddress').value,
				manufactureEmail: document.getElementById('manufactureEmail').value,
				manufactureContactNo: document.getElementById('manufactureNumber').value,
				manufactureContactPerson: document.getElementById('manufactureContactPerson').value,
				hospitalId: localStorage.getItem("hospitalId"),
				userId: window.sessionStorage.getItem('userId'),
			};
			setIsData(false);
			callingAPI('manufacturer/manufacturerAddUpdate', postdata).then((res) => {
				if (res.data.success === '1') {
					setIsData(true);
					alert('Manufacturer Saved Successfully');
					ManufactureClose()
				} else if (res.data.success === '0') {
					alert(res.data.successMessage);

				}
			});


		}
	};

	const ManufacturePopUpClick = () => {
		setPopVisible(true)
	}
	const ManufactureClose = () => {
		setPopVisible(false)
	}

	const taxesChange = (event, item, index) => {
		if (!categoryType) {
			alert('Please Select Category Type');
			return;
		} else {
			if (categoryType == 1) {
				if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					return;
				} else if (!mrp) {
					alert('MRP Required');
					document.getElementById('mrp').focus();
					return;
				}
			} else {
				//// disposable and other category
				if (!mrp) {
					alert('MRP Required');
					document.getElementById('mrp').focus();
					return;
				}
			}
		}
		const checkedTaxArrCopy = [...taxListdata];
		checkedTaxArrCopy[index].isChecked = !item.isChecked;
		setTaxListData(checkedTaxArrCopy);

		if (event.target.checked == true) {
			var IdList = selectedTaxIdList;
			IdList.push(item.hospitalTaxDetailsId);
			setSelectedTaxIdList(IdList);
		} else {
			var IdList = selectedTaxIdList;
			var indexNumber = IdList.indexOf(item.hospitalTaxDetailsId);
			IdList.splice(indexNumber, 1);
			setSelectedTaxIdList(IdList);
		}
		let onlyCheckedTaxArrCopy = [...checkedTaxArrCopy];
		onlyCheckedTaxArrCopy = checkedTaxArrCopy.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});
		setOnlyCheckedTaxList(onlyCheckedTaxArrCopy)
		////// tax Calculation//////
		let taxCalc = 0;
		let sellingPriceMultiple = 0;
		let totalTaxValue = 0;
		let totalTaxCalculation = 0;

		checkedTaxArrCopy.map((val, index) => {
			if (val.isChecked == true) {
				taxCalc = taxCalc + Number(val.hospitalTaxDetailsTaxValue);
				setTaxCalculation(taxCalc);
			}

			sellingPriceMultiple = parseInt(sellingPriceWithTax * 100);
			totalTaxValue = taxCalc + 100;
			totalTaxCalculation = sellingPriceMultiple / totalTaxValue;
			setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			setSellingUnitPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
			if (categoryType == 1) {
				var stripcount = stripCount;
				var unitSellingPice = totalTaxCalculation / stripcount;

				setSellingPriceWithoutTax(Math.round(totalTaxCalculation.toFixed(2) * 100) / 100);
				setSellingUnitPriceWithoutTax(Math.round(unitSellingPice.toFixed(2) * 100) / 100);
			}
		});
	};

	const taxesChangePurchase = (event, item, index) => {
		if (!categoryType) {
			alert('Please Select Category Type');
			return;
		}
		else {
			if (categoryType == 1) {
				if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					return;
				} else if (!purchasePrice) {
					alert('PurchasePrice Required');
					document.getElementById('txtPrice').focus();
					return;
				}
			} else {
				//// disposable and other category
				if (!purchasePrice) {
					alert('PurchasePrice Required');
					document.getElementById('txtPrice').focus();
					return;
				}
			}
		}

		const checkedTaxArrPurchaseCopy = [...taxListdataPurchase];
		checkedTaxArrPurchaseCopy[index].isChecked = !item.isChecked;
		setTaxListDataPurchase(checkedTaxArrPurchaseCopy);

		if (event.target.checked == true) {
			var IdList = selectedTaxIdPurchaseList;
			IdList.push(item.hospitalTaxDetailsId);
			setSelectedTaxIdPurchaseList(IdList);
		} else {
			var IdList = selectedTaxIdPurchaseList;
			var indexNumber = IdList.indexOf(item.hospitalTaxDetailsId);
			IdList.splice(indexNumber, 1);
			setSelectedTaxIdPurchaseList(IdList);
		}
		let onlyCheckedTaxArrPurchaseCopy = [...checkedTaxArrPurchaseCopy];
		onlyCheckedTaxArrPurchaseCopy = checkedTaxArrPurchaseCopy.filter((val, idx) => {
			if (val.isChecked == true) {
				return true;
			} else {
				return false;
			}
		});

		setOnlyCheckedTaxListPurchase(onlyCheckedTaxArrPurchaseCopy)
		let sellingPriceMultiplePurchase = 0;
		let totalTaxValuePurchase = 0;
		let totalTaxCalculationPurchase = 0;
		checkedTaxArrPurchaseCopy.map((val, index) => {
			if (val.isChecked == true) {
				taxCalcPurchase = taxCalcPurchase + Number(val.hospitalTaxDetailsTaxValue);
				setTaxCalculationPurchase(taxCalcPurchase);
			}
			totalTaxValuePurchase = taxCalcPurchase + 100;
			sellingPriceMultiplePurchase = purchasePrice * totalTaxValuePurchase;
			totalTaxCalculationPurchase = sellingPriceMultiplePurchase / 100;
			setPurchasePriceWithTax(Math.round(totalTaxCalculationPurchase.toFixed(2) * 100) / 100);
			setPurchaseUnitPriceWithTax(Math.round(totalTaxCalculationPurchase.toFixed(2) * 100) / 100);
			if (categoryType == 1) {
				var stripcount = stripCount;
				var unitSellingPurchasePice = totalTaxCalculationPurchase / stripcount;
				setPurchasePriceWithTax(Math.round(totalTaxCalculationPurchase.toFixed(2) * 100) / 100);
				setPurchaseUnitPriceWithTax(Math.round(unitSellingPurchasePice.toFixed(2) * 100) / 100);
			}
		});
	};
	const getTaxListingDataValues = (selectedTaxIdList) => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};

		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					if (selectedTaxIdList.includes(val.hospitalTaxDetailsId)) {
						val.isChecked = true;
					}
					else {
						val.isChecked = false;
					}
					return val;
				});
			}
				if (res.data.success === '1') {
					setTaxListData(res.data.result.hospitalTaxDetailsList);
					//setTaxListDataPurchase(res.data.result.hospitalTaxDetailsList);
				} else {
					setTaxListData([]);
					//setTaxListDataPurchase([]);
				}

			})
			.catch();
	};
	const getTaxListingPurchaseDataValues = (selectedTaxIdPurchaseList) => {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};

		callingAPI('inventoryStockUpdate/taxListing', postdata)
			.then((res) => {
				var result = [];
				if (res.data.success === '1') {
				result = res.data.result.hospitalTaxDetailsList.map((val, idx) => {
					if (selectedTaxIdPurchaseList.includes(val.hospitalTaxDetailsId)) {
						val.isChecked = true;
					}
					else {
						val.isChecked = false;
					}
					return val;
				});
			}

				if (res.data.success === '1') {
					//	setTaxListData(res.data.result.hospitalTaxDetailsList);
					setTaxListDataPurchase(res.data.result.hospitalTaxDetailsList);
				} else {
					//	setTaxListData([]);
					setTaxListDataPurchase([]);
				}

			})
			.catch();
	};




	const getEditedInventoryValues = () => {
		if (typeof location.state !== 'undefined') {
			const { pmhospitalMedicineId } = location.state;
			setpmHospitalMedicineId(pmhospitalMedicineId);
			const postdata = {
				pmHospitalMedicineId: pmhospitalMedicineId,
			};

			callingAPI('inventoryStockUpdate/id', postdata).then((data) => {
				if (data.data.success === '1') {
					let medicinecategoryId = data.data.result.inventoryList[0].hospitalInventoryCategoryId;
					let formattedExpireDate = formatDate(
						data.data.result.inventoryList[0].pmHospitalMedicineExpiryDate
					);
					data.data.result.inventoryList[0].taxDetails.map((val, idx) => {
						val.isChecked = true;
						return val;
					});

					selectedTaxListdata = data.data.result.inventoryList[0].taxDetails.map((val) => {
						var id = val.hospitalTaxDetailsId.toString();

						return id;
					});
					let selectedTaxListdataEdit = data.data.result.inventoryList[0].taxDetails.map(
						(val) => {
							var id = val.hospitalTaxDetailsId;
							return id
						}
					);

					setSelectedTaxIdList(selectedTaxListdata);

					selectedTaxListPurchasedata = data.data.result.inventoryList[0].suppliertaxDetails.map((val) => {
						var id = val.hospitalTaxDetailsId.toString();

						return id;
					});

					setSelectedTaxIdPurchaseList(selectedTaxListPurchasedata);


					if (medicinecategoryId == 1) {
						var mStrStripCount = data.data.result.inventoryList[0].pmHospitalMedicineQty.toString();
						setcategoryType(data.data.result.inventoryList[0].hospitalInventoryCategoryId);
						setcategoryTypeName(data.data.result.inventoryList[0].hospitalInventoryCategoryName);

						/////////medicine ////////
						setBrandName(data.data.result.inventoryList[0].medicine);
						setMedicineId(data.data.result.inventoryList[0].medicineId);
						setGenericName(data.data.result.inventoryList[0].medicineGenericName);
						setHsnCode(data.data.result.inventoryList[0].hsnCode);
						setManufactureClick(
							data.data.result.inventoryList[0].manufactureName,
							data.data.result.inventoryList[0].manufactureId
						);
						setMedType(data.data.result.inventoryList[0].medicineTypeId);
						setMedTypeName(data.data.result.inventoryList[0].medicineTypeName);
						setStrength(data.data.result.inventoryList[0].medicineStrength);
						setMedUnit(data.data.result.inventoryList[0].medicineUnitId);
						setMedUnitName(data.data.result.inventoryList[0].medicineUnitName);
						setInteractions(data.data.result.inventoryList[0].medicineInteractions);
						setDrugReactions(data.data.result.inventoryList[0].medicineDrugReactions);
						setMajorSideEffects(data.data.result.inventoryList[0].medicineMajorSideEffects);
						setDosage(data.data.result.inventoryList[0].medicineIndicationAndDosage);
						setPrecautions(data.data.result.inventoryList[0].medicinePrecautions);
						setStripCount(mStrStripCount);
						setBatchNo(data.data.result.inventoryList[0].pmHospitalMedicineBatchNo);
						setStockQuantity(data.data.result.inventoryList[0].pmHospitalMedicineStockQty);
						setExpireDate(formattedExpireDate.slice(0, -3));
						setReorderQuantitu(data.data.result.inventoryList[0].pmHospitalMedicineThresholdNo);
						setIsGeneric(data.data.result.inventoryList[0].isGeneric === 1 ? true : false)
					} else {
						////disposable/////
						setcategoryType(data.data.result.inventoryList[0].hospitalInventoryCategoryId);
						setcategoryTypeName(data.data.result.inventoryList[0].hospitalInventoryCategoryName);

						setItemName(data.data.result.inventoryList[0].hospitalInventoryMasterName);
						setMasterCode(data.data.result.inventoryList[0].hospitalInventoryMasterName);
						setnoOfUse(data.data.result.inventoryList[0].pmHospitalMedicineNoOfUse);
						setDisposableHsnCode(data.data.result.inventoryList[0].pmHospitalMedicineHsnCode);
						setDisposableBatchNo(data.data.result.inventoryList[0].pmHospitalMedicineBatchNo);
						setDisposableStockQuantity(data.data.result.inventoryList[0].pmHospitalMedicineStockQty);
						setDisposableExpireDate(formattedExpireDate.slice(0, -3));
						setDisposableReorderQuantitu(data.data.result.inventoryList[0].pmHospitalMedicineThresholdNo);
						setShowPharmacy(
							data.data.result.inventoryList[0].pmHospitalMedicineIsDisposableItem === 1 ? true : false
						);
					}

					setMrp(data.data.result.inventoryList[0].pmHospitalMedicineMrp);
					setmrpUnitPrice(data.data.result.inventoryList[0].pmHospitalMedicineMrpUnitPrice);
					setSellingPriceWithoutTax(data.data.result.inventoryList[0].pmHospitalMedicinePrice);
					setSellingUnitPriceWithoutTax(data.data.result.inventoryList[0].pmHospitalMedicineUnitPrice);
					//	setSellingPriceWithTax(data.data.result.inventoryList[0].pmHospitalMedicinePrice);
					//setSellingUnitPriceWithTax(data.data.result.inventoryList[0].pmHospitalMedicineUnitPrice);

					setPurchasePrice(data.data.result.inventoryList[0].supplierInvoiceMedicinesPrice);
					setPurchaseUnitPrice(data.data.result.inventoryList[0].supplierInvoiceMedicinesUnitPrice);
					setPurchasePriceWithTax(data.data.result.inventoryList[0].supplierInvoiceMedicinesPriceWithTax);
					setPurchaseUnitPriceWithTax(data.data.result.inventoryList[0].supplierInvoiceMedicinesUnitPriceWithTax);
					getTaxListingDataValues(selectedTaxListdata);
					getTaxListingPurchaseDataValues(selectedTaxListPurchasedata);
					if (data.data.result.inventoryList[0].taxDetails.length == 0) {
						setSellingPriceWithTax(data.data.result.inventoryList[0].pmHospitalMedicinePrice);
						setSellingUnitPriceWithTax(data.data.result.inventoryList[0].pmHospitalMedicineUnitPrice);
					}
					else {
						let resultEdit = data.data.result.inventoryList[0].taxDetails.map((val, idx) => {
							if (selectedTaxListdataEdit.includes(val.hospitalTaxDetailsId)) {
								val.isChecked = true;
							}
							else {
								val.isChecked = false;
							}
							return val;
						});
						let objEdit = [...resultEdit]
						let taxCalc1 = 0;
						var quantity = mStrStripCount;
						var totalCost = 0;
						objEdit.map((val, index) => {
							if (val.isChecked == true) {
								taxCalc1 = taxCalc1 + ((data.data.result.inventoryList[0].pmHospitalMedicinePrice) * Number(val.hospitalTaxDetailsTaxValue) / 100);
							}

							setSellingPriceWithTax(parseFloat(parseFloat((data.data.result.inventoryList[0].pmHospitalMedicinePrice)) + parseFloat(taxCalc1)).toFixed(2));
							setSellingUnitPriceWithTax(parseFloat(parseFloat(data.data.result.inventoryList[0].pmHospitalMedicinePrice) + parseFloat(taxCalc1)).toFixed(2));

							if (categoryType == 1) {
								var quantity = mStrStripCount;
								setSellingPriceWithTax(parseFloat(parseFloat((data.data.result.inventoryList[0].pmHospitalMedicinePrice)) + parseFloat(taxCalc1)).toFixed(2));
								totalCost = (parseFloat((data.data.result.inventoryList[0].pmHospitalMedicinePrice)) + parseFloat(taxCalc1)) / quantity;
								setSellingUnitPriceWithTax(parseFloat(totalCost).toFixed(2));

							}
						});
					}
				}
			});
		}
	};

	const stripCountByPurchaseArea = () => {
		if (categoryType == 1) {
			var price = purchasePrice;
			var quantity = stripCount;
			var totalCost = price / quantity;
			setPurchaseUnitPrice(parseFloat(totalCost).toFixed(2));
			setPurchaseUnitPriceWithTax(parseFloat(totalCost).toFixed(2));
		}
	};

	const onChangePurchasePrice = (event) => {
		if (categoryType == '') {
			alert('Please Select Category Type');
			return;
		} else {
			if (categoryType == 1) {
				if (!stripCount) {
					alert('NO: of Units Required');
					document.getElementById('hdnMedicineQuantity').focus();
					setPurchasePrice('');
					setPurchaseUnitPrice('');
					setPurchasePriceWithTax('');
					setPurchaseUnitPriceWithTax('');
					setSelectedTaxPurchaseNewArray([])
				}
				const re = /^[0-9.\b]+$/;
				if (event.target.value === '' || re.test(event.target.value)) {
					setPurchasePrice(event.target.value);
					setPurchasePriceWithTax(event.target.value);
				} else {
					alert('Only Numbers Accepted');
				}
				// setSelectedTaxIdList([])
				setSelectedTaxIdPurchaseList([])
			} else {
				const re = /^[0-9.\b]+$/;
				if (event.target.value === '' || re.test(event.target.value)) {
					setPurchasePrice(event.target.value);
					setPurchaseUnitPrice(event.target.value);
					setPurchasePriceWithTax(event.target.value);
					setPurchaseUnitPriceWithTax(event.target.value);
				} else {
					alert('Only Numbers Accepted');
				}
				// setSelectedTaxIdList([])
				setSelectedTaxIdPurchaseList([])
			}
		}
		onlycheckedTaxListPurchase.map((val, index) => {
			if (taxListdataPurchase.includes(val.hospitalTaxDetailsId)) {
				val.isChecked = true;
			}
			else {
				val.isChecked = false;
			}
			return val;

		})
	};

	const categoryDropDownApi = () => {
		callingAPI('inventoryStockUpdate/categoryListing',
		).then((response) => {
			if (response.data.success === "1") {
				setCategoryData(response.data.result.categoryList)
			}
		}
		)
			.catch()
	}

	useEffect(async () => {


		if (typeof location.state !== 'undefined') {
			const { supplierInvoiceMedicinesId } = location.state;
			const { supplierInvoiceId } = location.state;
			const { supplierInvoiceNumber } = location.state;
			const { supplierInvoiceDate } = location.state;
			const { supplierName } = location.state;
			const { supplierInvoiceStatus } = location.state;

			setSupplierInvoiceNumber(supplierInvoiceNumber)
			setsupplierInvoiceMedicinesId(supplierInvoiceMedicinesId);
			setSupplierInvoiceDate(supplierInvoiceDate);
			setSupplierName(supplierName);
			setSupplierInvoiceStatus(supplierInvoiceStatus);
			setSupplierInvoiceId(supplierInvoiceId)
		}
		await getEditedInventoryValues();


	}, []);
	const formatDate = (dateStr) => {
		const [day, month, year] = dateStr.split('-');
		let newDate = `${year}-${month}-${day}`;
		return newDate;
	};
	return (
		<div class="pl_editInventory practiceManagementForm practiceManagementFormNew edtinv ">
			{isData === false ? <Loader /> : ''}
			<div class="doc breadcrumbDoc breadCrumbPrctmngt">
				<ul>
					<li>
						<a href="/settings/purchaseLists">Purchase Lists</a>
					</li>
					<div class="arrow-right" />
					<li>
						<span>Purchase Details</span>
					</li>
				</ul>
			</div>
			{/* ====================== */}
			<div class="practiceManagementForm practiceManagementFormNew " style={{ padding: '2px 0px' }}>
				<h3 class="practiceHead">Purchase Details</h3>
				<div class="practiceManagementFormBlcok">
					<form>
						<div class="taxMainContentWrap">
							<div id="divItemDefault">
								<div class="taxMainWrap">
									<ul className="row">
										<li class="col-md-3 col-lg-3" style={{ paddingLeft: '0px' }}>
											<label>
												<span>Invoice Number *</span>
											</label>

											<input
												readOnly
												type="text"
												id="txtInvno"
												name="txtInvno"
												value={supplierInvoiceNumber}
											/>
										</li>
										<li class="col-md-3 col-lg-3">
											<label>
												<span>Invoice Date *</span>
											</label>

											<input
												readOnly
												type="text"
												class="arrQuantity is-datepick"
												placeholder="Date"
												value={pmhospitalMedicineId !=""?supplierInvoiceDate:supplierInvoiceDate.replace(/,|-/g, "/")}
												// value={supplierInvoiceDate}
											/>
										</li>
										<li class="col-md-3 col-lg-3">
											<label>
												<span>Supplier Name *</span>
											</label>

											<input
												readOnly
												type="text"
												autocomplete="off"
												maxlength="100"
												value={supplierName}
											/>
										</li>
										<li class="col-md-3 col-lg-3">
											<label>
												<span>Status</span>
											</label>
											<select
												id="cmbItem"
												name="cmbItem"
												style={{ width: '80%' }}
												disabled="disabled"
												value={supplierInvoiceStatus}
											>
												<option value="UnPaid">UnPaid</option>
												<option value="Paid">Paid</option>
												{/* <option value="Open">Open</option> */}
											</select>
										</li>
									</ul>
								</div>
							</div>
							<div class="clearfix" />
						</div>
					</form>
				</div>
			</div>
			{/* ====================== */}
			<div class="practiceManagementFormBlcok inventoryStockDiv">
				<h3 class="practiceHead">Item Details</h3>
				<form>
					<ul className="row">
						<li class="col-md-4 col-lg-4">
							<label>Category Name *</label>

							<input
								autocomplete="off"
								name="category"
								id="category"
								value={categoryTypeName}
								readOnly={true}

							/>
						</li>

						{categoryType == 2 || categoryType == 3 || categoryType == 4 || categoryType == 5 || categoryType == 0 ? (
							///////////////////
							<div className="row" >
								<li class="col-md-4 col-lg-4">
									<label id="medBid">Item Name*</label>

									<input
										autocomplete="off"
										type="text"
										id="itemName"
										name="itemName"
										autofocus="autofocus"
										value={itemName}
										readOnly={true}
										placeholder="Enter Name"

									/>

								</li>

								<li class="col-md-4 col-lg-4">
									<label>Master Code*</label>

									<input
										autocomplete="off"
										type="text"
										id="masterCode"
										name="masterCode"
										autofocus="autofocus"
										value={masterCode}
										onChange={onChangeMasterCode}
										placeholder="Enter Master Code"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>No Of Use*</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="2"
										id="noOfUse"
										name="noOfUse"
										autofocus="autofocus"
										value={noOfUse}
										onChange={onChangeNoOfUse}
										placeholder="Quantity"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Show In Pharmacy</label>
									<div class="procedureCatType">
										<input
											autocomplete="off"
											type="checkbox"
											id="chkShowPharmacy"
											name="chkShowPharmacy"
											checked={isShowPharmacy}
											onChange={onChangeShowPharmacy}
										/>
										<label for="Show In Pharmacy"> </label>
									</div>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Hsn Code</label>

									<input
										autocomplete="off"
										type="text"
										id="hsnCode"
										name="hsnCode"
										autofocus="autofocus"
										value={disposablehsnCode}
										onChange={onChangeDisposableHsnCode}
										placeholder="Hsn Code"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Batch No*</label>

									<input
										autocomplete="off"
										type="text"
										id="batchNum"
										name="batchNum"
										autofocus="autofocus"
										value={disposablebatchNo}
										readOnly={true}
										placeholder="Enter Batch No"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Stock Quantity*</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="100"
										id="stockQuantity"
										name="stockQuantity"
										autofocus="autofocus"
										value={disposablestockQuantity}
										onChange={onChangeDisposableStockQuantity}
										placeholder="Enter Stock Quantity"
									/>
								</li>

								<li class="col-md-4 col-lg-4">
									<label id="expDatY">Expiry Date</label>

									<input
										autocomplete="off"
										type="month"
										min={requiredDate}
										name="expireDate"
										id="expireDate"
										autofocus="autofocus"
										value={disposableexpireDate}
										onChange={onChangeDisposableExpireDate}
										onKeyDown={(e) => e.preventDefault()}
										placeholder='Expiry Date'
										// required
										style={{ display: "flex", justifyContent: "space-between" }}
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Reorder Quantity*</label>
									<input
										autocomplete="off"
										type="text"
										id="threshold"
										name="threshold"
										autofocus="autofocus"
										value={disposablereOrderQuantity}
										onChange={onChangeDisposableReOrderQuantity}
										placeholder="Enter Threshold"
									/>
								</li>
							</div>
						) : (
							<div className="row" >
								<li class="col-md-4 col-lg-4">
									<label id="medBid">Medicine/Brand Name*</label>
									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineName"
										name="hdnMedicineName"
										autofocus="autofocus"
										value={brandName}
										readOnly={true}
										placeholder="Enter Medicine Name"

									/>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Generic Name</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineGenericName"
										name="hdnMedicineGenericName"
										autofocus="autofocus"
										value={genericName}
										onChange={onChangeGenericName}
										placeholder="Enter Generic Name"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Hsn Code</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="100"
										id="hdnMedicineHsnCode"
										name="hdnMedicineHsnCode"
										autofocus="autofocus"
										value={hsnCode}
										onChange={onChangeHsnCode}
										placeholder="Hsn Code"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Manufacture</label>
									<input
										type="text"
										id="manufactureInput"
										name="cityName"
										placeholder="Item Name"
										className="input-design"
										autoComplete="off"
										onKeyUp={() => getManufactureAutoComplete()}
										style={{ marginBottom: '5px' }}
									//	onBlur={onBlurManufacture}
									/>
									<ul
										className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
										id="manufactureList"
										style={{ display: 'none' }}
									>
										{manufactureAuto.map((item, i) => (
											<li
												onClick={() =>
													setManufactureClick(item.manufactureName, item.manufactureId)}
											>
												{item.manufactureName + " - " + item.phoneNumber}
											</li>
										))}
									</ul>
									<input type="hidden" id="hiddenManufacId" />
									<p
										id=""
										style={{
											color: 'red',
											display: 'flex',
											justifyContent: 'left',
											fontSize: '12px',
											marginBottom: '4px',
										}}
									>
										{manufactError}
									</p>
									<span class="addManufLink addLinkTxt">
										<a onClick={() => ManufacturePopUpClick()} style={{ color: '#0093cc', textDecoration: 'none' }}>
											Add Manufacturer +
										</a>
									</span>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Type*</label>

									<input
										autocomplete="off"
										class="fulWidthSelect"
										name="cmbMedicineType"
										id="cmbMedicineType"
										value={medTypeName}
										readOnly={true}

									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Strength</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineStrength"
										name="hdnMedicineStrength"
										autofocus="autofocus"
										value={strength}
										readOnly={true}
										placeholder="Enter Strength"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Unit</label>

									<input
										autocomplete="off"
										class="fulWidthSelect"
										name="cmbMedicineType"
										id="cmbMedicineType"
										value={medUnitName}
										readOnly={true}

									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Interactions</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineInteractions"
										name="hdnMedicineInteractions"
										autofocus="autofocus"
										value={interactions}
										onChange={onChangeInteraction}
										placeholder="Enter Interactions"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Drug reactions</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineDrugReactions"
										name="hdnMedicineDrugReactions"
										autofocus="autofocus"
										value={drugReactions}
										onChange={onChangeDrugReactions}
										placeholder="Enter Drug reactions"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Major side effects</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineSideEffect"
										name="hdnMedicineSideEffect"
										autofocus="autofocus"
										value={majorSideEffects}
										onChange={onChangeMajorSideEffects}
										placeholder="Enter Side Effect"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Indication and dosage</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineIndication"
										name="hdnMedicineIndication"
										autofocus="autofocus"
										value={dosage}
										onChange={onChangeDosage}
										placeholder="Enter Indication"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Precautions</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicinePrecautions"
										name="hdnMedicinePrecautions"
										autofocus="autofocus"
										value={precautions}
										onChange={onChangePrecautions}
										placeholder="Enter Precautions"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Number Of Units per Strip/Box Required *</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineQuantity"
										name="hdnMedicineQuantity"
										autofocus="autofocus"
										value={stripCount}
										onChange={onChangeStripCount}
										placeholder="Enter Quantity"
									/>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Batch No*</label>

									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineBatchNo"
										name="hdnMedicineBatchNo"
										autofocus="autofocus"
										value={batchNo}
										readOnly={true}
										placeholder="Enter Batch No"
									/>
								</li>

								<li class="col-md-4 col-lg-4">
									<label>Stock Quantity*</label>

									<input
										autocomplete="off"
										type="text"
										maxlength="100"
										id="hdnMedicineStockQuantity"
										name="hdnMedicineStockQuantity"
										autofocus="autofocus"
										value={stockQuantity}
										onChange={onChangeStockQuantity}
										placeholder="Enter Stock Quantity"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label id="expDatY">Expiry Date*</label>

									<input
										autocomplete="off"
										type="month"
										min={requiredDate}
										name="scheduleDate"
										id="scheduleDate"
										value={expireDate}
										onChange={onChangeExpireDate}
										onKeyDown={(e) => e.preventDefault()}
										placeholder='Expiry Date'
										// required
										style={{ display: "flex", justifyContent: "space-between" }}
									/>

								</li>
								<li class="col-md-4 col-lg-4">
									<label>Reorder Quantity*</label>
									<input
										autocomplete="off"
										type="text"
										id="hdnMedicineThreshold"
										name="hdnMedicineThreshold"
										autofocus="autofocus"
										value={reOrderQuantity}
										onChange={onChangeReOrderQuantity}
										placeholder="Enter Threshold"
									/>
								</li>
								<li class="col-md-4 col-lg-4">
									<label>Is generic</label>
									<div class="procedureCatType">
										<input
											autocomplete="off"
											type="checkbox"
											id="chkgeneric"
											name="chkgeneric"
											checked={isShowGeneric}
											onChange={onChangeisGeneric}
											style={{ width: "15px" }}
										/>
										<label for="Is generic"> </label>
									</div>
								</li>
							</div>
						)}
					</ul>
					<div class="borderSeparate">
						<span />
					</div>
					{/* ==================== Bottom portion updation Begins here ==========================  */}
					<h3 class="practiceHead">Pricing Details</h3>
					<ul>
						<h3 class="practiceHead">Purchase Details</h3>
						<ul className="row">
							<li class="col-md-3 col-lg-3" style={{ paddingLeft: '0px' }}>
								<label>Purchase Price *</label>

								<input
									autoComplete="off"
									type="text"
									id="txtPrice"
									name="txtPrice"
									placeholder="Enter Purchase Price"
									tabindex="18"
									value={purchasePrice}
									onChange={onChangePurchasePrice}
									onKeyUp={stripCountByPurchaseArea}
								/>
							</li>
							<li class="col-md-3 col-lg-3">
								<label>Purchase Unit Price *</label>

								<input
									autoComplete="off"
									type="text"
									id="txtUntPrice"
									name="txtUntPrice"
									placeholder="Unit Price"
									value={purchaseUnitPrice}
								/>
							</li>
							<li class="col-md-3 col-lg-3" id="newTax">
								<label class="priceLbl">
									Price with Tax{' '}
									<p className="plPriceInfo" id="totalprice">
										{purchasePriceWithTax}
									</p>
								</label>
							</li>
							<li class="col-md-3 col-lg-3">
								<label class="priceLbl">
									Unit Price with Tax{' '}
									<p className="plPriceInfo" id="totalUntprice">
										{purchaseUnitPriceWithTax}
									</p>
								</label>
							</li>
							<br />
							<div class="procedureCatList" id="divTaxDetails">
								<div
									class="applicabletaxDiv pl_applicableTax_scroll row"
									style={{ flexWrap: 'nowrap', margin: '0px' }}
								>
									<div class="col-lg-3 procedureCatName">Applicable Taxes</div>

									<div class="col-lg-9 procedureCatType procedureCatListWrap">
										<div className="row">
											{/* <div className="col-lg-3"> */}
											{taxListdataPurchase.map((option, idx) => {
												return (
													<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
														<input
															autoComplete="off"
															type="checkbox"
															id={option.hospitalTaxDetailsTaxValue}
															value={option.hospitalTaxDetailsTaxName}
															checked={
																selectedTaxIdPurchaseList.indexOf(option.hospitalTaxDetailsId) >=
																	0 ? (
																	true
																) : (
																	false
																)
															}
															onChange={(event) => taxesChangePurchase(event, option, idx)}

														/>
														<label>{option.taxNames}</label>
													</div>
												);
											})}
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						</ul>
					</ul>

					<div class="borderSeparate">
						<span />
					</div>
					<h3 class="practiceHead">Selling Details</h3>
					<ul className="row">
						<li class="col-md-3 col-lg-3">
							<label>MRP*</label>
							<input
								autocomplete="off"
								type="text"
								id="mrp"
								name="mrp"
								autofocus="autofocus"
								value={mrp}
								onChange={onChangemrp}
								onKeyUp={stripCountBy}
								placeholder="Enter MRP"
							/>
						</li>
						<li class="col-md-3 col-lg-3">
							<label>MRP Unit Price*</label>
							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="mrpUnitPrice"
								name="mrpUnitPrice"
								autofocus="autofocus"
								value={mrpUnitPrice}
								//onChange={onChangeParamPrice}
								placeholder="MRP Unit Price"
							/>
						</li>

						<div class="borderSeparate">
							<span />
						</div>
						<div class="subTitileNAme">
							<h3 class="practiceHead">Taxes</h3>
						</div>
						{taxListdata.length > 0 ? (
							<div className="taxType">
								<div className="taxDataOuter">
									{taxListdata.map((option, idx) => {
										return (
											<div className="taxDataSelected">
												{selectedTaxIdList.indexOf(option.hospitalTaxDetailsId) >= 0 ? (
													option.taxNames +
													': ' +
													(sellingPriceWithoutTax *
														(option.hospitalTaxDetailsTaxValue / 100)).toFixed(2)
												) : (
													''
												)}
											</div>
										);
									})}
								</div>
							</div>
						) :
							(
								''
							)
						}
						<div class="procedureCatList" id="divTaxDetails">
							<div
								class="applicabletaxDiv pl_applicableTax_scroll row"
								style={{ flexWrap: 'nowrap', margin: '0px' }}
							>
								<div class="col-lg-3 procedureCatName">
									Applicable Taxes
									{/* {selectedTaxIdList.map((id)=>(<span style={{margin:"5px"}}>{id}</span>))} */}
								</div>
								<div class=" col-lg-9 procedureCatType procedureCatListWrap">
									<div className="row">
										{taxListdata.map((option, idx) => {
											return (
												<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
													<input
														type="checkbox"
														id={option.hospitalTaxDetailsTaxValue}
														value={option.hospitalTaxDetailsTaxName}
														checked={
															selectedTaxIdList.indexOf(option.hospitalTaxDetailsId) >=
																0 ? (
																true
															) : (
																false
															)
														}
														onChange={(event) => taxesChange(event, option, idx)}
													/>&nbsp;
													<label> {option.taxNames} </label>
													{/* {option.hospitalTaxDetailsId} {selectedTaxIdList.indexOf(option.hospitalTaxDetailsId)} */}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div class="borderSeparate">
							<span />
						</div>
						{/* <div class="subTitileNAme">
							<h3 class="practiceHead">Selling Details</h3>
						</div> */}
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Price (without tax)*</label>

							<input
								autocomplete="off"
								type="text"
								id="sellingPricewithoutTax"
								name="sellingPricewithoutTax"
								autofocus="autofocus"
								value={sellingPriceWithoutTax}
								onChange={onChangeSellingPriceArea}
								onKeyUp={stripCountBySellingArea}
								placeholder="Enter Price"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Unit Price (without tax)*</label>

							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="sellingUnitPricewithoutTax"
								name="sellingUnitPricewithoutTax"
								autofocus="autofocus"
								value={sellingUnitPriceWithoutTax}
								placeholder="Enter Selling Unit Price"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Price(with tax)*</label>
							<input
								autocomplete="off"
								type="text"
								id="sellingPricewithTax"
								name="sellingPricewithTax"
								autofocus="autofocus"
								value={sellingPriceWithTax}
								onChange={onChangeSellingPriceAreawithTax}
								onKeyUp={stripCountBySellingAreawithTax}
								placeholder="Enter Selling Price"
							/>
						</li>
						<li class="col-md-3 col-lg-3 sellingDetailSec">
							<label>Selling Unit Price(with tax)*</label>
							<input
								autocomplete="off"
								type="text"
								readonly=""
								id="sellingUnitPricewithTax"
								name="sellingUnitPricewithTax"
								autofocus="autofocus"
								value={sellingUnitPriceWithTax}
								placeholder="Enter Selling Unit Price"
							/>
						</li>
					</ul>
					<div class="clearfix" />
					<br />

					<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
						<span className="dashButtonBorder" onClick={updateInventoryDetails}>
							Update{' '}
						</span>
					</div>
					<div className="col-md-2 dashboardSearchList" style={{ float: 'right' }}>
						<span className="dashButtonBorder" style={{ border: '1px solid #b22222' }}>
							<Link to="/settings/purchaseLists" style={{ textDecoration: 'none', color: '#393939' }}>
								Cancel
							</Link>
						</span>
					</div>
				</form>

				{/* ==================== Bottom portion updation ends here ==========================  */}
			</div>

			{/* ====================== Category = Disposable ends here ====================== */}

			{popVisible === true &&
				<div id="plpopup12" class="ploverlay" style={{ visibility: 'visible', opacity: 1 }}>
					<div class="plpopup">
						<h2>
							Add Manufacturer{' '}
							<a class="close" onClick={() => ManufactureClose()}>
								&times;
							</a>
						</h2>
						<div class="plcontent">
							<div class="addDataContent">
								<div class="addDataDiv">
									<label>
										Manufacturer Name<span class="mandatory">*</span>
									</label>
									<input autocomplete="off" type="text" id="manufactureName"
										placeholder="Manufacture Name" />
								</div>
								<div class="addDataDiv">
									<label>Address</label>
									<textarea
										autocomplete="off"
										id="manufactureAddress"
										placeholder="Enter Address"
										style={{ height: '85px' }}
									/>
								</div>
								<div class="addDataDiv">
									<label>Email</label>
									<input autocomplete="off" type="text" id="manufactureEmail"
										placeholder="Enter Email" />
								</div>
								<div class="addDataDiv">
									<label>Contact Number<span class="mandatory">*</span></label>
									<input
										maxLength="10"
										autocomplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="manufactureNumber"
										placeholder="Mobile Number"
										onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) event.preventDefault(); }}

									/>
								</div>
								<div class="addDataDiv">
									<label>Contact Person</label>
									<input
										autocomplete="off"
										class=""
										style={{ boxShadow: 'none' }}
										type="text"
										id="manufactureContactPerson"
										placeholder="Contact Person"
									/>
								</div>

								<div class="summaryBtnDiv">
									<input
										type="submit"
										value="Save"
										onClick={addNewManufacture}
										class="practiceGreenButton"
									/>

									<a class="close" onClick={() => ManufactureClose()}>
										<input type="submit" value="Cancel" class=" practiceGreyButton" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default EditPurchaseDetails;
