import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
function BillingLogin() {
	const [toggle1, setToggle1] = useState(false);
	const [toggleFiles1, setToggleFiles1] = useState(false);
	const [toggleFilesReports1, setToggleFilesReports1] = useState(false);

	const setToggleFilesPro = (event) => {
		if (document.getElementById("qazPro").style.display === "block") {
			window.sessionStorage.setItem("qazPro", "none")
		}
		else {
			window.sessionStorage.setItem("qazPro", "block")
		}
	}
	const setToggleFiles6 = (event) => {
		if (document.getElementById("qaz6").style.display === "block") {
			window.sessionStorage.setItem("qaz6", "none")
		}
		else {
			window.sessionStorage.setItem("qaz6", "block")
		}
	}
	const setToggleFiles7 = (event) => {
		if (document.getElementById("qaz7").style.display === "block") {
			window.sessionStorage.setItem("qaz7", "none")
		}
		else {
			window.sessionStorage.setItem("qaz7", "block")
		}
	}
	const setToggleFiles8 = (event) => {
		if (document.getElementById("qaz8").style.display === "block") {
			window.sessionStorage.setItem("qaz8", "none")
		}
		else {
			window.sessionStorage.setItem("qaz8", "block")
		}
	}


	return (
		<div className="settingMenu" style={{ paddingTop: '0px' }}>
			<span className='LeftMenuTitle'>{window.sessionStorage.getItem("userName")}</span>
			<div className="settingMenuList">
				{/* <p className='lablogginedUser'>Billing</p> */}
				<ul>
					{/* <li className="settingListLi">
						<NavLink to="/billingLogin/procedurePayment" activeClassName="active" onClick={() => {
							window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
						}} >Procedure Payment</NavLink>
					</li> */}
					<li>
						<Link to="#"  >
							<span onClick={() => setToggleFilesPro()}>Procedure</span>

							<div className="plSubMenu" id="qazPro" style={{ display: window.sessionStorage.getItem("qazPro"), padding: '0px', paddingTop: '15px' }}>
								<div>
									<li>
										<NavLink to="/billingLogin/procedurePayment" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}  >
											<span  > Procedure Payment</span></NavLink>
									</li>
								</div>
								<div>
									<li>
										<NavLink to="/billingLogin/procedureBill" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}   >
											<span >Procedure Bill</span></NavLink>

									</li>
								</div>

							</div>
						</Link>
					</li>
					<li>
						<Link to="#"  >
							<span onClick={() => setToggleFiles6()}>Lab Order</span>

							<div className="plSubMenu" id="qaz6" style={{ display: window.sessionStorage.getItem("qaz6"), padding: '0px', paddingTop: '15px' }}>
								<div>
									<li>
										<NavLink to="/labLogin/labOrderDetails" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}  >
											<span  > Lab Order Details</span></NavLink>
									</li>
								</div>
								<div>
									<li>
										<NavLink to="/labLogin/labTestBill" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}   >
											<span >Lab Test Bill</span></NavLink>

									</li>
								</div>

							</div>
						</Link>
					</li>
					<li>
						<Link to="#"  >
							<span onClick={() => setToggleFiles7()}>Prescription</span>

							<div className="plSubMenu" id="qaz7" style={{ display: window.sessionStorage.getItem("qaz7"), padding: '0px', paddingTop: '15px' }}>
								<div>
									<li>
										<NavLink to="/pharmacyLogin/prescriptionDetails" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}  >
											<span  > Prescription Details</span></NavLink>
									</li>
								</div>
								<div>
									<li>
										<NavLink to="/pharmacyLogin/prescriptionBill" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}   >
											<span >Prescription Bill</span></NavLink>

									</li>
								</div>

							</div>
						</Link>
					</li>





					<li>
						<Link to="#"  >
							<span onClick={() => setToggleFiles8()}>Reports</span>

							<div className="plSubMenu" id="qaz8" style={{ display: window.sessionStorage.getItem("qaz8"), padding: '0px', paddingTop: '15px' }}>
								<div>
									<li>
										<NavLink to="/reports/pharmacypayment" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}  >
											<span  > Pharmacy Payment</span></NavLink>
									</li>
								</div>
								<div>
									<li>
										<NavLink to="/labLogin/labOrderPayment" activeClassName="active" onClick={() => {
											window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
										}}  >
											<span > Lab Order Payment </span></NavLink>

									</li>
								</div>

							</div>
						</Link>
					</li>

					<li className="settingListLi">
						<NavLink to="/Dashboard/changepassword" activeClassName="active" onClick={() => {
							window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
						}} >Change Password</NavLink>
					</li>

				</ul>
			</div>
		</div>
	);
}

export default BillingLogin;
