import React from 'react';
import Logo from '../images/media/logo.png';
function IncomeExpenseInvoice() {
	return (
		<div>
			<div align="center">
				<div
					align="center"
					style={{
						width: '550px',
						height: '595px',
						marginLeft: '0px',
						marginRight: '0px',
						marginTop: '0px',
						marginBottom: '0px',
					}}
				>
					<table style={{ width: '100%', marginTop: '0px' }} border="0">
						<thead>
							<tr>
								<th style={{ fontWeight: '400' }}>
									<div class="printOutHeaderSec ">
										<table style={{ width: '100%' }}>
											<tbody>
												<tr height="80px">
													<td colspan="3" valign="middle" align="center">
														<div style={{ width: '13%', float: 'left' }}>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '16px',
																	clear: 'both',
																	width: '100%;',
																}}
															>
																<span
																	style={{
																		marginTop: '-5px',
																		display: 'inline-block',
																		width: '100%',
																	}}
																>
																	<img
																		src={Logo}
																		style={{
																			display: 'block',
																			width: 'auto',
																			height: '45px ',
																		}}
																	/>
																</span>
															</p>
														</div>
														<div
															class="practicePrintName "
															style={{ width: '87%', float: 'left' }}
														>
															<p
																style={{
																	fontWeight: '600',
																	fontSize: '16px',
																	clear: 'both',
																	width: '100%',
																	marginBottom: '1px',
																	marginTop: '0',
																}}
															/>
															<p
																style={{
																	fontWeight: '400',
																	fontSize: '12px !important',
																	clear: 'both',
																	width: '100%',
																	margin: '0',
																}}
															/>
														</div>
													</td>
												</tr>

												<tr>
													<td colspan="3">
														<hr color="#142536" style={{ margin: ' 0' }} />
													</td>
												</tr>
												<tr>
													<td
														width="50%"
														colspan="2"
														align="left"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													/>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '12px', wordBreak: 'break-all' }}
													/>
												</tr>

												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Name : AAVANI MOHAN
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Date : 20, Oct 2021{' '}
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '15px',
															textAlign: 'left',
														}}
													>
														MR No : 202003050048
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Phone No : 0000000000
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Age: 26
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													>
														Email Id :{' '}
													</td>
												</tr>
												<tr>
													<td
														colspan="2"
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'left',
														}}
													>
														Sex : Male
													</td>
													<td
														style={{
															width: '50%',
															fontSize: '12px',
															padding: '0',
															lineHeight: '17px',
															textAlign: 'right',
														}}
													/>
												</tr>

												<tr>
													<td colspan="3">
														<hr color="#142536" style={{ margin: '0' }} />
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</th>
							</tr>
						</thead>

						<tbody>
							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr>
													<td
														style={{
															fontSize: '14px',
															paddingBottom: '10px',
															textAlign: 'left',
														}}
													>
														Payments
													</td>
													<td
														style={{
															fontSize: '14px',
															paddingBottom: '10px',
															textAlign: 'right',
														}}
													>
														Receipt Number :&nbsp; RCP28
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>

							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr
													style={{
														fontSize: '13px',
														color: '#393939',
														background: ' #eae8e8',
													}}
												>
													<td
														style={{
															boxSizing: ' border-box',
															padding: ' 5px 2px',
															width: '10%',
															textAlign: 'left',
															fontWeight: '600',

															paddingLeft: '3px',
														}}
													>
														INVOICE
													</td>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '90%',
															textAlign: 'left',
															position: 'relative',
														}}
													>
														<div
															class="procedureCode"
															style={{ fontSize: '10px !important' }}
														>
															<div
																class="procedureCodeHead"
																style={{ fontWeight: '600' }}
															>
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	TOWARDS
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Batch
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Exp
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	Qty
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Unit Price
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Cost
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Tax
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	Total
																</div>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>

							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr style={{ borderBottom: '1px solid #ddd' }}>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '10%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '10px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														INV3
													</td>
													<td
														class="inoviceBorder"
														style={{
															verticalAlign: 'top !important',
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '90%',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														<div
															class="procedureCode"
															style={{
																fontSize: '10px !important',
																marginTop: '0',

																paddingBottom: '2px',
																marginBottom: '2px',
															}}
														>
															<div
																class="procedureCodeHead"
																style={{ fontWeight: '600', paddingBottom: '3px' }}
															>
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Procedure
																</div>
															</div>

															<div class="procedureCodeValue">
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	CONSULTATION
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	1
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	200.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	200.00
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	0.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	200.00
																</div>
															</div>

															<div class="procedureCodeValue">
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	root canal
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	1
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	500.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	500.00
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	0.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	500.00
																</div>
															</div>
														</div>
													</td>
												</tr>
												<tr style={{ borderBottom: '1px solid #ddd' }}>
													<td
														style={{
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '10%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '10px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														INV3
													</td>
													<td
														class="inoviceBorder"
														style={{
															verticalAlign: 'top !important',
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '90%',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														<div
															class="procedureCode"
															style={{
																fontSize: '10px !important',
																marginTop: '0',

																paddingBottom: '2px',
																marginBottom: '2px',
															}}
														>
															<div
																class="procedureCodeHead"
																style={{ fontWeight: '600', paddingBottom: '3px' }}
															>
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Procedure
																</div>
															</div>

															<div class="procedureCodeValue">
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	CONSULTATION
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	1
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	200.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	200.00
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	0.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	200.00
																</div>
															</div>

															<div class="procedureCodeValue">
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	root canal
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	-
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	1
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	500.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	500.00
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	0.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	500.00
																</div>
															</div>
														</div>
													</td>
												</tr>
												{/*  */}
												<tr style={{ borderBottom: '1px solid #ddd' }}>
													<td
														style={{
															boxSizing: ' border-box',
															padding: '5px 2px',
															width: '10%',
															display: 'inline-block',
															textAlign: 'left',
															fontSize: '10px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														INV40
													</td>
													<td
														class="inoviceBorder"
														style={{
															verticalAlign: 'top !important',
															boxSizing: 'border-box',
															padding: '5px 2px',
															width: '90%',
															textAlign: 'left',
															fontSize: '12px',
															color: '#393939',
															lineHeight: '18px',
														}}
													>
														<div
															class="procedureCode"
															style={{
																fontSize: '10px !important',
																marginTop: '0',

																paddingBottom: '2px',
																marginBottom: '2px',
															}}
														>
															<div
																class="procedureCodeHead"
																style={{ fontWeight: '600', paddingBottom: '3px' }}
															>
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	Prescription
																</div>
															</div>

															<div class="procedureCodeValue">
																<div
																	style={{
																		width: '18%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	WZ FC-tab 300 mdi
																</div>
																<div
																	style={{
																		width: '15%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	1234edf
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'left',
																		verticalAlign: 'top',
																	}}
																>
																	12/22
																</div>
																<div
																	style={{
																		width: '7%',
																		display: 'inline-block',
																		textAlign: 'center',
																		verticalAlign: 'top',
																	}}
																>
																	1
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	102.83
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	102.83
																</div>
																<div
																	style={{
																		width: '8%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	0.00
																</div>
																<div
																	style={{
																		width: '13%',
																		display: 'inline-block',
																		textAlign: 'right',
																		verticalAlign: 'top',
																	}}
																>
																	102.83
																</div>
															</div>

															<div class="procedureCodeIntake">
																<strong>HSN Code : </strong>
																<span>4tetry</span>
															</div>

															<div class="procedureCodeIntake">
																<strong>Intake : </strong>
																<span>Once Daily - 0 - 1 - 0 (Anytime)</span>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>

							<tr>
								<td>
									<div class="printOutSettingContent">
										<table width="100%" cellspacing="0">
											<tbody>
												<tr>
													<td>
														<div
															class="taxValueTable"
															style={{
																width: '42%',
																marginTop: '-6px',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
																color: '#393939',
															}}
														/>

														<div
															style={{
																width: '56%',
																display: 'inline-block',
																boxSizing: 'border-box',
																verticalAlign: 'top',
															}}
														>
															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Total amount (INR):&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	200.00
																</span>
															</div>

															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Amount paid (INR):&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	Cash
																</span>
															</div>

															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Mode of payment:&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	Refund
																</span>
															</div>
															<div
																class="estimatedPrice"
																style={{
																	textAlign: 'right',
																	fontSize: '13px',
																	paddingTop: '6px',
																	color: '#4a4a4a',
																}}
															>
																Total due amount (INR):&nbsp;<span
																	style={{
																		width: '75px',
																		display: 'inline-block',
																		verticalAlign: 'top',
																	}}
																>
																	{' '}
																	432.00
																</span>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>
									<div class="printOutFooterSec">
										<table style={{ width: '100%' }}>
											<tbody>
												<tr>
													<td
														width="25%"
														colspan="2"
														align="left"
														style={{ wordBreak: 'break-all' }}
													>
														<div align="justify" style={{ fontSize: '10px' }}>
															testclinic@ida.com
														</div>
													</td>
													<td
														width="50%"
														align="right"
														style={{ fontSize: '10px', wordBreak: ' break-all' }}
													>
														Dr. Manu
													</td>
												</tr>

												<tr>
													<td colspan="3">
														<div align="justify" class="practiceFootBtm">
															Working time
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="3" style={{ paddingTop: '10px' }} />
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div class="printBtm" style={{ width: '595px', margin: 'auto' }}>
				<p
					style={{
						width: '70px',
						textAlign: 'center',
						paddingTop: '5px',
						lineHeight: '18px',
						display: 'inline-block',
						fontWeight: 'bold',
						fontSize: '12px',
						color: 'white',
						background: 'rebeccapurple none repeat scroll 0% 0%',
						marginTop: '2px',
						cursor: 'pointer',
						borderRadius: '5px',
						marginBottom: '2px',
						height: '25px',
					}}
				>
					Print
				</p>
			</div>
		</div>
	);
}

export default IncomeExpenseInvoice;
