import React, { useState, useEffect } from 'react';
import Add from '../images/icons/add.png';
import Close from '../images/icons/close-sm.png';
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import Pagination from 'react-js-pagination';
import $ from "jquery";
import { callingAPI, callingPostAPI, callingGetAPI } from '../config';
import Tick from '../images/icons/tick.png';
import InActive from '../images/icons/close.png';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';

function AddLabWork() {
    const [dataFlag, setDataFlag] = useState(false);
    const [saveFlag, setSaveFlag] = useState(false);
    const [addLabWork, setAddLabWork] = useState([])
    const [labName, setLabName] = useState("");
    const [labNameSearch, setLabNameSearch] = useState("");
    const [categoryValues, setCategoryValues] = useState([]);
    const [labCategoryId, setlabCategoryId] = useState('0');
    const [dentalLabWorkNameId, setDentalLabWorkNameId] = useState("");
    const [count, setCount] = useState('');
    const [activePage, setActivePage] = useState(parseInt(window.sessionStorage.getItem('labTestactivePage')));
    const [pageOffset, setPageOffset] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const statusChange = (id, status) => {
        var x = false;
        if (status === true) {
            x = window.confirm('Do you want to Enable this?');
        } else {
            x = window.confirm('Do you want to Disable this?');
        }
        if (x === true) {
            const satusData = {
                dentalLabWorkNameId: id,
                dentalLabWorkNameStatus: status == false ? false : true,

            };

            callingAPI('commonUserFunctions/changeStatusDentalLabWorkNames', satusData).then((data) => {
                if (data.data.success === '1') {
                    alert('Status Changed Successfully');
                    labworkList();
                }
                if (data.data.success === '0') {
                    alert(data.data.errorMessage);
                }
            });
        }
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        // setActivePage(pageNumber);
        // setPageOffset(offset);
        window.sessionStorage.setItem("labTestactivePage", pageNumber)
        window.sessionStorage.setItem('labTestoffset', offset)
        const PostData = {
            "dentalLabWorkNameId": "",
            "dentalLabWorkName": labNameSearch === "" ? "" : labNameSearch,
            "dentalLabWorkType": "",
            "dentalLabWorkNameStatus": "",
            "hospitalId": localStorage.getItem("hospitalId"),
            "offset": offset,
            "max": max
        };
        callingAPI('commonUserFunctions/getDentalLabWorkNames', PostData).then((response) => {

            if (response.data.success === '1') {
                setDataFlag(true);
                setAddLabWork(response.data.result.dentalLabWorkNames);
                setCount(response.data.result.totalCount);
            }
            else {
                setDataFlag(true);
                setAddLabWork([]);
                setCount("");
            }
        })
            .catch();
    };
    const categoryChange = (event) => {
        setlabCategoryId(event.target.value);
    };

    const labWorkAddSave = () => {
        setIsButtonDisabled(true);
        let flags = true;
        if (!labName) {
            alert('Enter Lab Name');
            document.getElementById('labName').focus();
            setIsButtonDisabled(false);
            flags = false;
        }
        else if (labCategoryId === '0') {
            alert('Select Type');
            document.getElementById('accountHead').focus();
            setIsButtonDisabled(false);
            flags = false;
        }
        else {
            if (flags === true) {
                let postData = {
                    "dentalLabWorkNameId": dentalLabWorkNameId,
                    "dentalLabWorkName": labName,
                    "dentalLabWorkTypeId": labCategoryId,
                    "dentalLabWorkNameStatus": true,
                    "hospitalId": localStorage.getItem("hospitalId")
                }

                callingAPI('commonUserFunctions/saveDentalLabWorkNames', postData).then((response) => {

                    if (response.data.success === "1") {
                        labworkList();
                        setlabCategoryId("0")
                        setLabName("")
                        closePopup();
                        setDentalLabWorkNameId("")
                        if (dentalLabWorkNameId === "") {
                            alert("Added Successfully");
                            setIsButtonDisabled(false);
                        }
                        else {
                            alert("Updated Successfully");
                            setIsButtonDisabled(false);
                        }

                    }
                    else {
                        alert(response.data.errorMessage);
                    }
                })
            }
        }
    }

    const enterKey = (e) => {
        if (e.key === 'Enter') {
            searchValueChange();
        }
    }

    const searchValueChange = () => {
        const PostData = {
            "dentalLabWorkNameId": "",
            "dentalLabWorkName": labNameSearch === "" ? "" : labNameSearch,
            "dentalLabWorkType": "",
            "dentalLabWorkNameStatus": "",
            "hospitalId": localStorage.getItem("hospitalId"),
            "offset": 0,
            "max": 10
        };
        callingAPI('commonUserFunctions/getDentalLabWorkNames', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                window.sessionStorage.setItem("labTestactivePage", 1)
                window.sessionStorage.setItem('labTestoffset', 0)
                setAddLabWork(response.data.result.dentalLabWorkNames);
                setCount(response.data.result.totalCount);


            }
            else {
                setDataFlag(true);
                setAddLabWork([]);
                setCount("");
            }
        })
            .catch();

    }

    const labworkList = () => {
        const PostData = {
            "dentalLabWorkNameId": "",
            "dentalLabWorkName": "",
            "dentalLabWorkType": "",
            "dentalLabWorkNameStatus": "",
            "hospitalId": localStorage.getItem("hospitalId"),
            "offset": window.sessionStorage.getItem("labTestoffset"),
            "max": 10
        };
        callingAPI('commonUserFunctions/getDentalLabWorkNames', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setAddLabWork(response.data.result.dentalLabWorkNames);
                setCount(response.data.result.totalCount);


            }
            else {
                setDataFlag(true);
                setAddLabWork([]);
                setCount("");
            }
        })
            .catch();
    }
    const addLabWorkClick = () => {
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setlabCategoryId("0")
        setLabName("")
        setDentalLabWorkNameId("")
    }
    const closePopup = () => {
        setSaveFlag(false)
        $("#plpopup1").css("visibility", "hidden");
        $("#plpopup1").css("opacity", "0");
    }
    const editLablist = (data) => {
        setSaveFlag(true)
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setLabName(data.dentalLabWorkName);
        setlabCategoryId(data.dentalLabWorkTypeId);
        setDentalLabWorkNameId(data.dentalLabWorkNameId)
    }

    useEffect(() => {
        const PostData = {};
        callingAPI('commonUserFunctions/getDentalLabWorkTypes', PostData)
            .then((data) => {
                if (data.data.success === '1') {

                    setCategoryValues(data.data.result.dentalLabWorkNames);
                }
                else {
                    setCategoryValues([]);
                }

            })
            .catch();
        labworkList();

    }, []);

    return (
        <div className="dashboardCover">
            <div className="row">
                <div className="col-md-10">
                    <div className="dashboardTitle">Add Lab Work</div>
                </div>

                <div className="col-md-2">
                    <div onClick={() => { addLabWorkClick(); }} style={{ color: '#fff', textDecoration: 'none' }}>
                        <span
                            className="dashButtonBorder"
                            style={{ background: '#22a2fd', border: 'none', color: '#fff' }}
                        >
                            Add Lab Work
                        </span>
                    </div>
                </div>
            </div>
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        hideDisabled
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <div className="dashboardContent">
                <div className="reportWrap">
                    <div className="dashboardSearch">
                        <div className="row">
                            <div className="col-md-2 dashboardSearchList">
                                <input
                                    id="labNameSearch"
                                    type="text"
                                    className="inputField"
                                    placeholder='Enter Lab Name'
                                    autoComplete="off"
                                    name='labNameSerach'
                                    value={labNameSearch}
                                    onChange={(e) => setLabNameSearch(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span className="dashButtonBorder"
                                    onClick={searchValueChange}
                                    title="Search"
                                    onKeyDown={enterKey}>
                                    Search
                                </span>
                            </div>
                        </div>
                    </div>
                    {dataFlag == false ? (
                        <Loader />
                    ) : addLabWork.length > 0 ? (
                        <div className="dashboardtable">
                            <table>
                                <thead>
                                    <th>Lab Name</th>
                                    <th>Type</th>
                                    <th style={{ width: "10%" }}></th>
                                </thead>
                                <tbody>
                                    {addLabWork.map((data) => (
                                        <tr>

                                            <td className='vertical-middle'>{data.dentalLabWorkName}</td>
                                            <td className='vertical-middle'>{data.dentalLabWorkTypeName}</td>
                                            <td className='vertical-middle' style={{ width: "10%" }}>
                                                <img src={Editicon} onClick={() => editLablist(data)} alt="Edit" className='img-fluid' title='Edit' />
                                                {data.dentalLabWorkNameStatus === 1 ? (
                                                    <img
                                                        src={Tick}
                                                        alt="Active"
                                                        className='img-fluid'
                                                        width={20}
                                                        title="Click to Deactivate"
                                                        onClick={() => statusChange(data.dentalLabWorkNameId, false)} />
                                                ) : (
                                                    <img
                                                        src={InActive}
                                                        alt="InActive"
                                                        className='img-fluid'
                                                        width={20}
                                                        title="Click to Activate"
                                                        onClick={() => statusChange(data.dentalLabWorkNameId, true)} />
                                                )}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            <br />
                        </div>
                    ) : (
                        <p>
                            <NoData />
                        </p>
                    )}                </div>
            </div>
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        hideDisabled
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}

            {/* Popup Begins */}
            <div id="plpopup1" class="ploverlay">
                <div class="plpopup addLabpopup">
                    <h2>Add Lab</h2>
                    <div class="close" onClick={closePopup}><span class="popUpClose">x</span></div>
                    <div class="plcontent">
                        <div class="addDataContent">
                            <div class="row">
                                <div className="col-md-3">
                                    <label>Lab Name<span class="mandatory">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className='inputField'
                                        placeholder="Enter Lab Name"
                                        id="labName"
                                        autoComplete="off"
                                        name='labName'
                                        value={labName}
                                        onChange={(e) => setLabName(e.target.value)} />
                                </div>

                            </div>
                            <div class="row">
                                <div className="col-md-3">
                                    <label>Type<span class="mandatory">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <select name="labCategoryId"
                                        id="accountHead"
                                        className='inputField'
                                        value={labCategoryId}
                                        onChange={categoryChange}
                                    >
                                        <option value="0">Select</option>
                                        {categoryValues.map((item, idix) => (
                                            <option value={item.dentalLabWorkTypeId}>
                                                {item.dentalLabWorkTypeName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div class="summaryBtnDiv">
                                {saveFlag === true ? <button type="submit" class="practiceGreenButton" onClick={labWorkAddSave} style={{ color: '#046b29' }} disabled={isButtonDisabled}>Update</button> :
                                    <button type="submit" class="practiceGreenButton" onClick={labWorkAddSave} style={{ color: '#046b29' }} disabled={isButtonDisabled}>Save</button>}
                                <button type="submit" class="practiceGreenButton" onClick={closePopup} style={{ color: 'red', border: "1px solid red" }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup Ends */}

        </div>
    );
}
export default AddLabWork;
