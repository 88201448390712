import React, { useEffect, useState } from "react";
import { callingAPI, callingAPI_GET } from "../config";
import Add from "../images/icons/add.png";
import { useLocation } from "react-router-dom";
import Loader from "../Common/loader";
import AWS from "aws-sdk";
import Close from "../images/icons/close.png";
var s3;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  region: "us-east-1",
});
var bucketParams = {
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
};
s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: bucketParams,
});
function PatientOldHistoryListsEditpg() {
  const location = useLocation();
  const [showDiv, setShowDiv] = useState(true);
  const [showDiv1, setShowDiv1] = useState(true);
  const [load, setLoad] = useState(true);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [disable, setDisable] = React.useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [uploadSetImage, setUploadSet] = useState([]);
  const [uploadEditImage, setUploadEditImage] = useState([]);
  const [uploadSetImagesave, setuploadSetImagesave] = useState([]);
  const [editFlag, setEditFlag] = useState();
  const [historyId, setHistoryId] = useState("");
  const saveoldHistory = async () => {
    setLoad(false);
    setDisable(false);
    let flag = true;
    if (imagePreview !== "") {
      await handleImageUpload();
    }
    if (title === "") {
      alert("Please enter the title")
      document.getElementById('title').focus();
      flag = false
      setLoad(true);
      return false
    } else if (description === "") {
      alert("Please enter the description")
      document.getElementById('description').focus();
      flag = false
      setLoad(true);
      return false
    }
    if (flag === true) {
      setLoad(false);
      setDisable(true);
      var imageName = [];
      uploadEditImage.map((obj) => {
        imageName.push({
          imageName: obj.imageNameOnly,
        });
      });
      const postdata = {
        patientHistoryId: editFlag === true ? historyId : "",
        userId: window.sessionStorage.getItem("userId"),
        memberId: window.sessionStorage.getItem("memberId"),
        doctorId: window.sessionStorage.getItem("doctorId"),
        title: title,
        description: description,
        isActive: true,
        hospitalId: localStorage.getItem("hospitalId"),
        patientHistoryImageArray:
          editFlag === true ? (imageName === [] ? "" : imageName) : uploadSetImagesave,
        createdDateAndTime: Date.now()
      };
      callingAPI("commonUserFunctions/savePatientHistory", postdata)
        .then((res) => {
          if (res.data.success === "1") {
            setDisable(true);
            setLoad(true);
            alert("Added successfully");
            window.location.href = "/patients/historyFromOtherVendor";
          } else {
            setDisable(true);
            setLoad(true);
          }
        })
        .catch();
    }
  };
  const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const handleImageUpload = () => {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < uploadSetImage.length; i++) {
        let fullFilePath = uploadSetImage[i].imageName;
        let fullFile = uploadSetImage[i].imageFile;
        s3.upload(
          {
            Key: fullFilePath,
            Body: uploadSetImage[i].imageFile,
            ACL: "public-read",
          },
          (err, data) => {
            if (err) {
              alert("There was an error uploading your image");
            } else if (data) {
              if (uploadSetImage.length === i + 1) {
                resolve(true);
              }
              return;
            }
          }
        );
      }
    });
  };
  const removeImageEdit = (i) => {
    let uploadSet = [...uploadEditImage];
    uploadSet.splice(i, 1);
    setUploadEditImage(uploadSet);
  };
  const removeImage = (i) => {
    let uploadSet = [...uploadSetImagesave];
    uploadSet.splice(i, 1);
    setuploadSetImagesave(uploadSet);
    let uploadSet1 = [...uploadSetImage];
    uploadSet1.splice(i, 1);
    setUploadSet(uploadSet1);
  };
  const addImageSet = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    if (editFlag === true) {
      let uploadSet = [...uploadEditImage];
      uploadSet.push({
        imageNameWithPath:
          process.env.REACT_APP_S3_BUCKET_URL +
          process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
          "/" +
          e.target.files[0].name,
        imageNameOnly: e.target.files[0].name,
      });
      setUploadEditImage(uploadSet);
    }
    let uploadSet = [...uploadSetImage];
    let uploadSetsave = [...uploadSetImagesave];
    uploadSet.push({
      imageFile: e.target.files[0],
      imageNameArray: URL.createObjectURL(e.target.files[0]),
      imageName:
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        "/" +
        e.target.files[0].name,
    });
    uploadSetsave.push({
      imageName: e.target.files[0].name,
    });
    setUploadSet(uploadSet);
    setuploadSetImagesave(uploadSetsave);
  };
  useEffect(() => {
    if (typeof location.state !== "undefined") {
      const { editData } = location.state;
      const { editFlag } = location.state;
      setHistoryId(editData.patientHistoryId);
      setTitle(editData.title);
      setDescription(editData.description);
      setUploadEditImage(editData.patientHistoryImageArray);
      setEditFlag(editFlag);
    }
  }, []);
  const cancel = () => {
    window.location.href = "/patients/historyFromOtherVendor";
  };
  return (
    <div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
      <div id="divPatientContent" class="sideMenuContent">
        <div
          class="treatmentPlanCover"
          id="treatmentPlanListDiv"
          style={{ border: "none" }}
        >
          <div class="treatmentPlanHead">
            <span class="treatmentPlanName">History From Other Vendor</span>
            {editFlag !== true ? (
              <span
                id="savebutton"
                class="treatmentPlanBtn pl_blue_btn"
                onClick={saveoldHistory}
                style={{
                  background: "#54a954",
                  pointerEvents: showDiv == true ? "" : "none",
                }}
              >
                Save
              </span>
            ) : (
              <span
                id="updatebutton"
                class="treatmentPlanBtn pl_blue_btn"
                onClick={saveoldHistory}
                style={{
                  background: "#54a954",
                  pointerEvents: showDiv1 === true ? "" : "none",
                }}
              >
                Update
              </span>
            )}
            <span
              class="treatmentPlanBtn pl_blue_btn pl_grey_btn"
              onClick={(e) => cancel()}
            >
              Cancel
            </span>
          </div>
          {load === false ? (
            <Loader />
          ) : (
            <>
              <div class="treatmentClinicContent">
                <div class="clinicContentList appointmentsHospName_PTour">
                  <div class="clinicalLabel">Title</div>
                  <div class="clinicalField">
                    <input
                      //onChange={doctorNameChange}
                      class="fulWidthSelect changeMainSelect hospitalClass"
                      name="title"
                      id="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="treatmentClinicContent">
                <div class="clinicContentList appointmentsHospName_PTour">
                  <div class="clinicalLabel">Description </div>
                  <div class="clinicalField">
                    <textarea
                      id="description"
                      name="description"
                      class="fulWidthSelect changeMainSelect hospitalClass"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="treatmentClinicContent">
                <div class="clinicContentList appointmentsHospName_PTour">
                  <div class="clinicalLabel">Image Upload </div>
                  <div class="clinicalField">
                    <div class="webCamImageWrap" style={{ float: "left" }}>
                      <div class="btnFileUpload practiceGreenButton dentalFilesUpload_PTour uploadbtnleft">
                        <input
                          type="file"
                          id="imageName"
                          name="imageName"
                          onChange={(e) => {
                            addImageSet(e);
                          }}
                        />
                        <label for="imageName" title="Upload" id="uploadButton">
                          Upload
                        </label>
                      </div>
                      <div className="uploadCondtiontext">Maximum 3MB</div>
                    </div>

                    {/* <span
                      // onClick={(e) => addAchievements(e)}
                      class="uploadIcon uploadIconhisory"
                      style={{ position: "relative" }}
                    >
                      <img src={Add} title="Add More" />
                    </span> */}
                  </div>
                  {editFlag === true ? (
                    <div className="previewimg_wraper">
                      {uploadEditImage.map((item, i) => {
                        return (
                          <>
                            <div className="oldHistoryprevimg">

                              <img
                                src={item.imageNameWithPath}
                                className="img-fluid previwimg"
                              />
                              <img
                                src={Close}
                                alt="Close"
                                className="closeimg"
                                onClick={() => {
                                  removeImageEdit(i);
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="previewimg_wraper">
                      {uploadSetImage.map((item, i) => {
                        return (
                          <>
                            <div className="oldHistoryprevimg">
                              <img
                                src={item.imageNameArray}
                                className="img-fluid previwimg"
                              />
                              <img
                                src={Close}
                                alt="Close"
                                className="closeimg"
                                onClick={() => {
                                  removeImage(i);
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                  {/* <div className=""><img src={img1} alt="" /></div> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default PatientOldHistoryListsEditpg;
