import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { callingAPI } from '../../config';

const TotalRevenue = (props) => {
  const [xAxis, setXAxis] = useState([])
  const [series] = useState([
    {
      name: "Treatment Income",
      data: props.treatmentIncome
    }
    // {
    //   name: "Pharmacy Income",
    //   data: props.pharmacyIncome
    // },
    // {
    //   name: "Lab Income",
    //   data: props.labIncome
    // }
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      categories: props.xAxis,
      type: "string"
    }
  });

  return (
    <div id="chart">
     
      {props.xAxis.length > 0 ?
      <ReactApexChart key={props} options={options} series={series} type="line" height={350} />
      :
      <div className='todayAppScroll nodataFnd nodata-found_wrpr'>
      <p>No Treatment Income in a Year Found </p>
  </div>
      }
      
    </div>
  );
};

export default TotalRevenue;
