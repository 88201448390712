import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';

import "bootstrap/dist/css/bootstrap.css"
import "../src/css/style.css"
import "../src/css/responsive.css"
import './index.css';

import Login from "./Authentication/login";
import ForgotPassword from "./Authentication/forgotPassword";
import MembersReceive from "./Authentication/membersReceive";
import ForgotPasswordOtp from "./Authentication/forgotPasswordOtp";
import LoginLoader from "./Authentication/loginLoading";
import About from "./Authentication/About"
import TermsAndConditions from "./Authentication/terms&conditions"
import PrivacyPolicy from "./Authentication/privacypolicy"
import PrescriptiomMessage from "./Authentication/prescriptionMessage"


import LoginHeader from "./layout/loginHeader";

import Welcome from "./Dashboard/welcome";
import Profile from "./Dashboard/profile";
import Changepassword from "./Dashboard/changepassword";
import DashboardMain from "./Dashboard/dashboardMain";
import HealthInfoDetails from "./Dashboard/healthInfoDetails";
import Help from "./Dashboard/help";



import Report from './Reports/reports';
import Patientdetails from "./Reports/patientdetails";


import Communicationtab from './Communications/communication';
import PromotionalSMS from "./Communications/promotionalSMS";
import Preparesms from "./Communications/prepareSms";
import PatientGroup from "./Communications/patientGroup";



import Healthcard from "./Settings/healthcard";
import Settings from './Settings/settings';
import AddHospitalExpenses from './Settings/addHospitalExpenses';
import EditInventory from "./Settings/editInventory";
import AddPurchase from "./Settings/addPurchase";
import EditPurchaseDetails from "./Settings/editPurchaseDetails";
import HeaderPrint from "./Settings/headerPrint";
import FooterPrint from "./Settings/footerPrint";
import HeaderDoctorPrescriptionPrint from "./Settings/headerDoctorPrescriptionPrint";
import FooterDoctorPrescriptionPrint from "./Settings/footerDoctorPrescriptionPrint";

// import Healthcard from "./Settings/healthcard";


import LabOrderDetails from './labLogin/labOrderDetails';
import LabTestBill from './labLogin/labTestBill';
import LabOrderPayment from './labLogin/labOrderPayment';
import LabTestUnavailability from './labLogin/labTestUnavailability';
import OutsideLabOrderDetails from './labLogin/outsideLabOrderDetails';
import OutsideLabOrderDetailsView from './labLogin/outsideLabOrderDetailsView';
import LabAddCollectionDate from './labLogin/labAddCollectionDate';
import AddLabTestDetails from './labLogin/addLabTestDetails';
import LabTestParametersLab from './labLogin/labTestParametersLab';
import LabTestPrint from './labLogin/labTestPrint';
import TestResultPrint from './labLogin/testResultPrint';


import PrescriptionDetails from './pharmacyLogin/prescriptionDetails';
import PrescriptionBill from './pharmacyLogin/prescriptionBill';
// import SupplierInvoice from './pharmacyLogin/supplierInvoice';
import PharmacyStockDetails from './pharmacyLogin/pharmacyStockDetails';
import MedicineReorder from './pharmacyLogin/medicineReorder';
import ExpiredMedicineStockDetails from './pharmacyLogin/expiredMedicineStockDetails';
import MedicineSalebyDay from './pharmacyLogin/medicineSalebyDay';

import PatientMedicine from './pharmacyLogin/patientMedicine';
import PatientMedicineView from './pharmacyLogin/patientMedicineView';
import PrescribedMedicinebyQuantity from './pharmacyLogin/prescribedMedicinebyQuantity';
import PrescriptionAmountView from './pharmacyLogin/prescriptionAmountView';
import OutsidePharmacyOrderDetails from './pharmacyLogin/outsidePharmacyOrderDetails';
import OutsidePharmacyOrderDetailsView from './pharmacyLogin/outsidePharmacyOrderDetailsView';
import UnavailableMedicine from './pharmacyLogin/unavailableMedicine';
import HospitalMedicineHistory from './pharmacyLogin/hospitalMedicineHistory';
import PrescriptionBillPrint from './Invoice/prescriptionBillPrint';
import PrescriptionDetailsPrint from './pharmacyLogin/prescriptionDetailsPrint';



import LabOrderDetailsBill from './billingLogin/labOrderDetails';
import BlankPage from './billingLogin/blankPage';
import ProcedurePayment from './billingLogin/procedurePayment';


import ConsentFormInvoiceSettings from "./Invoice/consentFormInvoiceSettings";


// import Calendar from "./Clinics/calendar";
import StaffList from "./Clinics/staffList";
import StaffAdd from "./Clinics/staffAdd";
import StaffEdit from "./Clinics/staffEdit";
import StaffSetPermission from "./Clinics/staffSetPermission";
import TreatmentChart from "./Clinics/treatmentChart";
import TreatmentChartPrint from "./Clinics/treatmentChartPrint";
import ClinicsCalendar from "./Clinics/clinicsCalendar";
import BlockcalenderPopup from "./Clinics/blockcalenderPopup";
import ExportCalendar from "./Clinics/exportCalendar";

import PatientListing from "./Patients/patientListing";
import Patientsprofile from "./Patients/profile";
import AddPatient from "./Patients/addPatient";
import Appointment from "./Patients/appointment";
import Patientconsult from "./Patients/patientconsult";
import TreatmentPlans from "./Patients/treatmentPlans";
import AddTreatmentPlans from './Patients/addTreatmentPlans';
import PatientAppointments from "./Patients/patientAppointments";
import PatientOldHistory from "./Patients/patientOldHistory";
import PatientoldhistoryEdit from "./Patients/patientoldhistoryEdit";
import PatientAppointmentEdit from "./Patients/patientAppointmentEdit";
import CompletedPlans from './Patients/completedPlans';
import MedicalCertificate from './Patients/medicalCertificate';
import MedicalCertificateEdit from './Patients/medicalCertificateEdit';
import AddCompletedPlans from './Patients/addCompletedPlans';
import PatientPayments from "./Patients/patientPayments";
import PatientPaymentRefundInvoice from "./Patients/patientPaymentRefundInvoice";
import PatientPaymentInvoice from "./Patients/patientPaymentInvoice";
import AddPayments from "./Patients/addPayments";
import AddPaymentsRefund from "./Patients/addPaymentsRefund";
import PatientHealthcard from "./Patients/patientHealthcard";
import History from "./Patients/history";
import LabDetailsView from "./Patients/labDetailsView";
import AddToGroup from "./Patients/addToGroup";
import InvoiceNotInHospitalPrint from "./Patients/invoiceNotInHospitalPrint";
import PatientInvoices from "./Patients/patientInvoices";
import PatientInvoicePrint from "./Patients/patientInvoicePrint";
import PatientInvoiceAdd from "./Patients/patientInvoiceAdd";
import LabOrder from "./Patients/labOrder";
import LabOrderAdd from "./Patients/labOrderAdd";
import CreateInvoiceSelectedTreatment from "./Patients/createInvoiceSelectedTreatment";
import PatientPriscription from "./Patients/patientPriscription";
import PatientPriscriptionAdd from "./Patients/patientPriscriptionAdd";
import DrugsRight from "./Patients/drugsRight";
import PatientPriscriptionWrite from "./Patients/patientPriscriptionWrite";
import CreateInvoiceSelectedMedicine from "./Patients/createInvoiceSelectedMedicine";
import ConsentFormInvoice from "./Patients/consentFormInvoice";
import CasesheetDental from "./Patients/casesheetDental";
import CasesheetMedical from "./Patients/casesheetMedical";
import CasesheetDentalHistory from "./Patients/casesheetDentalHistory";
import CasesheetDentalAdd from "./Patients/casesheetDentalAdd";
import CasesheetMedicalHistory from "./Patients/casesheetMedicalHistory";
import CasesheetMedicalAdd from "./Patients/casesheetMedicalAdd";
import CasesheetMedicalPrint from "./Patients/casesheetMedicalPrint";
import CasesheetDentalPrint from "./Patients/casesheetDentalPrint";
import CasesheetMedicalAddWrite from "./Patients/casesheetMedicalAddWrite";
import TreatmentPlansPrint from "./Patients/treatmentPlansPrint";
import CompletedPlansPrint from "./Patients/completedPlansPrint";
import LabOrderPrint from "./Patients/labOrderPrint";
import PriscriptionPrint from "./Patients/priscriptionPrint";
import PriscriptionImgPrint from "./Patients/priscriptionImgPrint";
import InvoicePayNow from "./Patients/invoicePayNow";
import InvoicePaymentList from "./Patients/invoicePaymentList";
import LabOrderDental from "./Patients/labOrderDental";
import LabOrderDentalAdd from "./Patients/labOrderDentalAdd";
import TabsRight from "./Patients/tabsRight";
import TestResult from "./Patients/testResult";
import TestResultPrintAll from "./Patients/testResultPrintAll";
import TestResultAdd from "./Patients/testResultAdd";
import LabOrderRight from "./Patients/labOrderRight";
import PatientFiles from "./Patients/patientFiles";
import PatientFilesAdd from "./Patients/patientFilesAdd";
import PatientFilesMedical from "./Patients/patientFilesMedical";
import PatientFilesMedicalView from "./Patients/patientFilesMedicalView";
import PatientFilesRight from "./Patients/patientFilesRight";
import RecieptPrint from './Patients/RecieptPrint';
import LabOrderDentalPrint from './Patients/labOrderDentalPrint';
import LabOrderOrthoCreation from './Patients/labOrderOrthoCreation';
import LabOrderMetalFreeCeramic from './Patients/labOrderMetalFreeCeramic';
import LabOrderMetalCeramic from './Patients/labOrderMetalCeramic';
import HistoryPrint from './Patients/historyPrint';
import SimplifiedForm from './Patients/simplifiedForm';
import HistoryInfo from './Patients/historyInfo';
import SimplifiedFormLabOrder from './Patients/simplifiedFormLabOrder';
import SimplifiedFormCasesheet from './Patients/simplifiedFormCasesheet';
import SimplifiedFormPrint from './Patients/simplifiedFormPrint';
import SimplifiedFormPrintNew from './Patients/simplifiedFormPrintNew';


import SimplifiedFormPrescription from './Patients/simplifiedFormPrescription';
import SimplifiedFormPrescriptionRight from './Patients/simplifiedFormPrescriptionRight';
import PatientTestResultPrint from './Patients/patientTestResultPrint';
import MedicalCertificatePrint from './Patients/medicalCertificatePrint';
import VitalSigns from './Patients/vitalSigns';
import VitalSignsPrint from './Patients/vitalSignsPrint';
import VitalSignsAdd from './Patients/vitalSignsAdd';
import ShareData from './Patients/shareData';
import RefereToaDoctor from './Patients/refereToaDoctor';
import ReferToDoctorPrint from './Patients/referToDoctorPrint';
import VitalGraph from './Patients/vitalGraph';
import ShareDataConsentForm from './Patients/shareDataConsentForm';
import HospitalHome from "./Hospital Admin/hospitalHome";
import DoctorsList from "./Doctor/doctorsList";
import DoctorListAdd from "./Doctor/doctorListAdd";
import store from "./Redux/store"
import Pharmacy from './pharmacyLogin/pharmacy';
import Billing from './billingLogin/billing';
import PrescriptionDetailsPrint1 from './pharmacyLogin/prescriptionDetailsPrint';
import LabTestBillPrint from './labLogin/labTestBillPrint';
import DoctorReference from './Reports/doctorReference';
import MedicineByDoctor from './Reports/medicineByDoctor';
import AppointmentList from './Reports/appointmentList';
import LabTestInventory from './Reports/labTestInventory';
import TreatmentDetails from './Reports/treatmentDetails';
import ReceiptTaxDetails from './Reports/receiptTaxDetails';
import GenericMedicineList from './Reports/genericMedicineList';
import PracticeDetails from './Settings/practiceDetails';
import DashboardView from './Dashboard/dashboardView';
import TotalPendingPayment from './Dashboard/totalPendingPayment';
import UpcomingAppoiments from './Dashboard/dashboardView/upcomingAppoiments';

import LabOrderList from './LabOrder/labOrderList'
import LabOrderSave from './LabOrder/labOrder'

import { Provider } from "react-redux"

ReactDOM.render((
	<Provider store={store}>
		<Router>
			<Switch>
				<Route path="/" component={Login} exact />


				<Route path="/pharmacyLogin/prescriptionDetailsPrint" component={PrescriptionDetailsPrint1} />

				<Route path="/Authentication/forgotPassword" component={ForgotPassword} />
				<Route path="/layout/loginHeader" component={LoginHeader} />
				<Route path="/Authentication/membersReceive" component={MembersReceive} />
				<Route path="/Authentication/forgotPasswordOtp/:siteIdOtp?/:userIdOtp?" component={ForgotPasswordOtp} />

				<Route path="/Dashboard/welcome" component={Welcome} />
				<Route path="/Dashboard/profile" component={Profile} />
				<Route path="/Dashboard/changepassword" component={Changepassword} />
				<Route path="/Dashboard/dashboardMain" component={DashboardMain} />
				<Route path="/Dashboard/healthInfoDetails" component={HealthInfoDetails} />
				<Route path="/Dashboard/help" component={Help} />
				<Route path="/labLogin/labTestBillPrint" component={LabTestBillPrint} />


				<Route path="/reports" component={Report} />
				<Route path="/Reports/patientdetails" component={Patientdetails} />
				<Route path="/reports/doctorReference" component={DoctorReference} />
				<Route path="/reports/medicineByDoctor " component={MedicineByDoctor} />
				<Route path="/reports/appointmentList " component={AppointmentList} />
				<Route path="/reports/labTestInventory " component={LabTestInventory} />
				<Route path="/reports/treatmentDetails" component={TreatmentDetails} />
				<Route path="/reports/receiptTaxDetails" component={ReceiptTaxDetails} />
				<Route path="/reports/genericMedicineList" component={GenericMedicineList} />


				<Route path="/Clinics/calendar" component={ClinicsCalendar} />
				<Route path="/clinics/staffList" component={StaffList} />
				<Route path="/clinics/staffAdd" component={StaffAdd} />
				<Route path="/clinics/staffEdit" component={StaffEdit} />
				<Route path="/clinics/staffSetPermission" component={StaffSetPermission} />
				<Route path="/clinics/treatmentChart" component={TreatmentChart} />
				<Route path="/clinics/treatmentChartPrint" component={TreatmentChartPrint} />
				<Route path="/Clinics/clinicsCalendar" component={ClinicsCalendar} />
				<Route path="/Clinics/blockcalenderPopup" component={BlockcalenderPopup} />
				<Route path="/Clinics/exportCalendar" component={ExportCalendar} />

				<Route path="/communications" component={Communicationtab} />
				<Route path="/communications/promotionalSMS" component={PromotionalSMS} />
				<Route path="/communications/prepareSms" component={Preparesms} />
				<Route path="/communications/patientGroup" component={PatientGroup} />


				<Route path="/pharmacyLogin" component={Pharmacy} />

				<Route path="/settings/headerDoctorPrescriptionPrint" component={HeaderDoctorPrescriptionPrint} />
				<Route path="/settings/footerDoctorPrescriptionPrint" component={FooterDoctorPrescriptionPrint} />
				<Route path="/settings/headerPrint" component={HeaderPrint} />
				<Route path="/settings/footerPrint" component={FooterPrint} />
				<Route path="/settings" component={Settings} />
				<Route path="/settings/healthcard" component={Healthcard} />
				<Route path="/settings/addHospitalExpenses" component={AddHospitalExpenses} />
				<Route path="/settings/editInventory" component={EditInventory} />
				<Route path="/settings/addPurchase" component={AddPurchase} />
				<Route path="/settings/editPurchaseDetails" component={EditPurchaseDetails} />
				<Route path="/settings/practiceDetails" component={PracticeDetails} />
				<Route path="/invoice/consentFormInvoiceSettings" component={ConsentFormInvoiceSettings} />


				<Route path="/labLogin/labOrderDetails" component={LabOrderDetails} />
				<Route path="/labLogin/labTestBill" component={LabTestBill} />
				<Route path="/labLogin/labOrderPayment" component={LabOrderPayment} />
				<Route path="/labLogin/labTestUnavailability" component={LabTestUnavailability} />
				<Route path="/labLogin/outsideLabOrderDetails" component={OutsideLabOrderDetails} />
				<Route path="/labLogin/outsideLabOrderDetailsView" component={OutsideLabOrderDetailsView} />
				<Route path="/labLogin/labAddCollectionDate" component={LabAddCollectionDate} />
				<Route path="/labLogin/addLabTestDetails" component={AddLabTestDetails} />
				<Route path="/labLogin/labTestParametersLab" component={LabTestParametersLab} />
				<Route path="/labLogin/labTestPrint" component={LabTestPrint} />
				<Route path="/labLogin/testResultPrint" component={TestResultPrint} />


				<Route path="/pharmacyLogin/prescriptionDetails" component={PrescriptionDetails} />
				<Route path="/pharmacyLogin/prescriptionBill" component={PrescriptionBill} />
				{/* <Route path="/pharmacyLogin/supplierInvoice" component={SupplierInvoice} /> */}
				<Route path="/pharmacyLogin/pharmacyStockDetails" component={PharmacyStockDetails} />
				<Route path="/pharmacyLogin/medicineReorder" component={MedicineReorder} />
				<Route path="/pharmacyLogin/expiredMedicineStockDetails" component={ExpiredMedicineStockDetails} />
				<Route path="/pharmacyLogin/medicineSalebyDay" component={MedicineSalebyDay} />

				<Route path="/pharmacyLogin/patientMedicine" component={PatientMedicine} />
				<Route path="/pharmacyLogin/patientMedicineView" component={PatientMedicineView} />
				<Route path="/pharmacyLogin/prescribedMedicinebyQuantity" component={PrescribedMedicinebyQuantity} />
				<Route path="/pharmacyLogin/prescriptionAmountView" component={PrescriptionAmountView} />
				<Route path="/pharmacyLogin/outsidePharmacyOrderDetails" component={OutsidePharmacyOrderDetails} />
				<Route path="/pharmacyLogin/outsidePharmacyOrderDetailsView" component={OutsidePharmacyOrderDetailsView} />
				<Route path="/pharmacyLogin/unavailableMedicine" component={UnavailableMedicine} />
				<Route path="/pharmacyLogin/hospitalMedicineHistory" component={HospitalMedicineHistory} />
				<Route path="/invoice/prescriptionBillPrint" component={PrescriptionBillPrint} />
				<Route path="/pharmacyLogin/prescriptionDetailsPrint" component={PrescriptionDetailsPrint} />



				<Route path="/billingLogin" component={Billing} />
				<Route path="/billingLogin/labOrderDetails" component={LabOrderDetailsBill} />
				<Route path="/billingLogin/blankPage" component={BlankPage} />
				<Route path="/billingLogin/procedurePayment" component={ProcedurePayment} />


				<Route path="/patients/patientListing" component={PatientListing} />
				<Route path="/patients/profile" component={Patientsprofile} />
				<Route path="/patients/addPatient" component={AddPatient} />
				<Route path="/patients/appointment" component={Appointment} />
				<Route path="/patients/patientconsult/:showEdit?" component={Patientconsult} />
				<Route path="/patients/treatmentPlans" component={TreatmentPlans} />
				<Route path="/patients/addTreatmentPlans" component={AddTreatmentPlans} />
				<Route path="/patients/patientAppointments" component={PatientAppointments} />
				<Route path="/patients/patientAppointmentEdit" component={PatientAppointmentEdit} />
				<Route path="/patients/completedPlans" component={CompletedPlans} />
				<Route path="/patients/medicalCertificate" component={MedicalCertificate} />
				<Route path="/patients/medicalCertificateEdit" component={MedicalCertificateEdit} />
				<Route path="/patients/addCompletedPlans" component={AddCompletedPlans} />
				<Route path="/patients/patientPayments" component={PatientPayments} />
				<Route path="/patients/patientPaymentRefundInvoice" component={PatientPaymentRefundInvoice} />
				<Route path="/patients/patientPaymentInvoice" component={PatientPaymentInvoice} />
				<Route path="/patients/addPayments" component={AddPayments} />
				<Route path="/patients/addPaymentsRefund" component={AddPaymentsRefund} />
				<Route path="/patients/patientHealthcard" component={PatientHealthcard} />
				<Route path="/patients/history" component={History} />
				<Route path="/patients/labDetailsView" component={LabDetailsView} />

				<Route path="/patients/addToGroup" component={AddToGroup} />
				<Route path="/patients/invoiceNotInHospitalPrint" component={InvoiceNotInHospitalPrint} />
				<Route path="/patients/patientInvoices" component={PatientInvoices} />
				<Route path="/patients/patientInvoicePrint/:printId?" component={PatientInvoicePrint} />
				<Route path="/patients/patientInvoiceAdd" component={PatientInvoiceAdd} />
				<Route path="/patients/labOrder" component={LabOrder} />
				<Route path="/patients/labOrderAdd" component={LabOrderAdd} />
				<Route path="/patients/createInvoiceSelectedTreatment" component={CreateInvoiceSelectedTreatment} />
				<Route path="/patients/patientPriscription/:rxPrint?" component={PatientPriscription} />
				<Route path="/patients/patientPriscriptionAdd" component={PatientPriscriptionAdd} />
				<Route path="/patients/drugsRight" component={DrugsRight} />
				<Route path="/patients/patientPriscriptionWrite" component={PatientPriscriptionWrite} />
				<Route path="/patients/createInvoiceSelectedMedicine" component={CreateInvoiceSelectedMedicine} />
				<Route path="/patients/consentFormInvoice/:hospitalId?/:memberId?/:doctorId?" component={ConsentFormInvoice} />
				<Route path="/patients/casesheetDental" component={CasesheetDental} />
				<Route path="/patients/casesheetMedical" component={CasesheetMedical} />
				<Route path="/patients/casesheetDentalHistory" component={CasesheetDentalHistory} />
				<Route path="/patients/casesheetDentalAdd" component={CasesheetDentalAdd} />
				<Route path="/patients/casesheetMedicalHistory" component={CasesheetMedicalHistory} />
				<Route path="/patients/casesheetMedicalAdd" component={CasesheetMedicalAdd} />
				<Route path="/patients/casesheetMedicalPrint/:membId?/:doctorId?/:printId" component={CasesheetMedicalPrint} />
				<Route path="/patients/casesheetDentalPrint/:membId?/:doctorId?/:printId" component={CasesheetDentalPrint} />
				<Route path="/patients/casesheetMedicalAddWrite" component={CasesheetMedicalAddWrite} />
				<Route path="/patients/treatmentPlansPrint/:printId?/:hospitalId?" component={TreatmentPlansPrint} />
				<Route path="/patients/completedPlansPrint/:printId?" component={CompletedPlansPrint} />
				<Route path="/patients/labOrderPrint/:orderTestId?/:hospId?/" component={LabOrderPrint} />
				<Route path="/patients/priscriptionPrint/:printId?/:hospId?/:doctorId?/:tp?/:tw?" component={PriscriptionPrint} />
				<Route path="/patients/priscriptionImgPrint" component={PriscriptionImgPrint} />
				<Route path="/patients/invoicePayNow" component={InvoicePayNow} />
				<Route path="/patients/invoicePaymentList" component={InvoicePaymentList} />
				<Route path="/patients/labOrderDental" component={LabOrderDental} />
				<Route path="/patients/labOrderDentalAdd" component={LabOrderDentalAdd} />
				<Route path="/patients/tabsRight" component={TabsRight} />
				<Route path="/patients/testResult" component={TestResult} />
				<Route path="/patients/testResultPrintAll" component={TestResultPrintAll} />
				<Route path="/patients/testResultAdd" component={TestResultAdd} />
				<Route path="/patients/labOrderRight" component={LabOrderRight} />
				<Route path="/patients/patientFiles" component={PatientFiles} />
				<Route path="/patients/patientFilesAdd" component={PatientFilesAdd} />
				<Route path="/patients/patientFilesMedical" component={PatientFilesMedical} />
				<Route path="/patients/patientFilesMedicalView" component={PatientFilesMedicalView} />
				<Route path="/patients/patientFilesRight" component={PatientFilesRight} />
				<Route path="/patients/labOrderDentalPrint" component={LabOrderDentalPrint} />
				<Route path="/patients/labOrderOrthoCreation" component={LabOrderOrthoCreation} />
				<Route path="/patients/labOrderMetalFreeCeramic" component={LabOrderMetalFreeCeramic} />
				<Route path="/patients/labOrderMetalCeramic" component={LabOrderMetalCeramic} />
				<Route path="/patients/historyPrint" component={HistoryPrint} />
				<Route path="/patients/simplifiedForm" component={SimplifiedForm} />
				<Route path="/patients/historyInfo" component={HistoryInfo} />
				<Route path="/patients/simplifiedFormLabOrder" component={SimplifiedFormLabOrder} />
				<Route path="/patients/simplifiedFormPrescription" component={SimplifiedFormPrescription} />
				<Route path="/patients/simplifiedFormCasesheet" component={SimplifiedFormCasesheet} />
				<Route path="/patients/simplifiedFormPrint" component={SimplifiedFormPrint} />
				<Route path="/patients/simplifiedFormPrintNew" component={SimplifiedFormPrintNew} />


				<Route path="/patients/simplifiedFormPrescriptionRight" component={SimplifiedFormPrescriptionRight} />
				<Route path="/patients/patientTestResultPrint" component={PatientTestResultPrint} />

				<Route path="/patients/medicalCertificatePrint" component={MedicalCertificatePrint} />
				<Route path="/patients/vitalSigns" component={VitalSigns} />
				<Route path="/patients/vitalSignsPrint" component={VitalSignsPrint} />
				<Route path="/patients/vitalSignsAdd" component={VitalSignsAdd} />
				<Route path="/patients/shareData" component={ShareData} />
				<Route path="/patients/refereToaDoctor" component={RefereToaDoctor} />
				<Route path="/patients/referToDoctorPrint" component={ReferToDoctorPrint} />
				<Route path="/patients/vitalGraph" component={VitalGraph} />
				<Route path="/patients/shareDataConsentForm" component={ShareDataConsentForm} />
				<Route path="/patients/historyFromOtherVendor" component={PatientOldHistory} />
				<Route path="/patients/historyFromOtherVendorEdit" component={PatientoldhistoryEdit} />

				<Route path="/aboutus" component={About} />
				<Route path="/termsAndConditions" component={TermsAndConditions} />
				<Route path="/privacyPolicy" component={PrivacyPolicy} />

				<Route path="/hospital Admin/hospitalHome" component={HospitalHome} />
				<Route path="/doctor/doctorsList" component={DoctorsList} />
				<Route path="/doctor/doctorListAdd" component={DoctorListAdd} />
				<Route path="/patients/RecieptPrint/:typeId?/:printId?" component={RecieptPrint} />

				<Route path="/Dashboard/dashboardView" component={DashboardView} />
				<Route path="/totalPendingPayment" component={TotalPendingPayment} />
				<Route path="/upcomingAppoiments" component={UpcomingAppoiments} />
				<Route path="/labOrderList" component={LabOrderList} />
				<Route path="/labOrder" component={LabOrderSave} />

				<Route path="/T=:token" component={PrescriptiomMessage} exact />
				<Route path="/GROUP-:siteId" component={Login} exact />
				<Route path="/:siteId?/patients/priscriptionPrint/:printId?/:hospId?/:doctorId?/:tp?/:tw?" component={PriscriptionPrint} exact />
				<Route path="/:token" component={Login} exact />
				<Route path="/:siteId?/T=:token" component={PrescriptiomMessage} exact />
				<Route path="/:siteId?/:token?" component={LoginLoader} exact />




			</Switch>
		</Router>
	</Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
reportWebVitals();
