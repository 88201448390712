import React from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import { CodeStarNotifications } from 'aws-sdk';


function HeaderDoctorPrescriptionPrint(props) {
    const [printOutId, setprintOutId] = useState('');

    const [footeralign, setfooteralign] = useState('');
    const [footerfullwidthcontent, setfooterfullwidthcontent] = useState('');
    const [header_align, setheader_align] = useState('');
    const [headerIncludeLogo, setheaderIncludeLogo] = useState('');
    const [header_logo_type_id, setheader_logo_type_id] = useState('');
    const [header_title, setheader_title] = useState('');
    const [page_size_id, setpage_size_id] = useState('');
    const [custum_height, setcustum_height] = useState();
    const [custum_width, setcustum_width] = useState('');
    const [showFooter, setshowFooter] = useState('');
    const [showHeader, setshowHeader] = useState('');
    const [showRx, setshowRx] = useState('');
    const [header_left_text, setheader_left_text] = useState('');
    const [header_right_text, setheader_right_text] = useState(0);
    const [logoTypeName, setlogoTypeName] = useState('');


    const [hospitalAddress, sethospitalAddress] = useState('');
    const [hospitalName, sethospitalName] = useState('');
    const [hospitalEmail, sethospitalEmail] = useState('');

    const [logo_image, setlogo_image] = useState('');
    const [doctorName, setdoctorName] = useState('');
    const [specializationName, setspecializationName] = useState('');
    const [education, seteducation] = useState([]);
    useEffect(() => {
        callingAPI('commonUserFunctions/headerFooterLogoPrints', {
            hospitalId: props.hospitalDataId ? props.hospitalDataId : localStorage.getItem("hospitalId"),
            doctorId: props.doctorDataId ? props.doctorDataId : sessionStorage.getItem('doctorId'),

        })
            .then((res) => {
                setprintOutId(res.data.result.headerFooterDetails[0].header_left_text);
                setheaderIncludeLogo(res.data.result.headerFooterDetails[0].headerIncludeLogo);
                setfooteralign(res.data.result.headerFooterDetails[0].footer_align);
                setfooterfullwidthcontent(res.data.result.headerFooterDetails[0].footer_full_width_content);
                setheader_align(res.data.result.headerFooterDetails[0].header_align);
                setheader_logo_type_id(res.data.result.headerFooterDetails[0].header_logo_type_id);
                setheader_title(res.data.result.headerFooterDetails[0].header_title);
                setpage_size_id(res.data.result.headerFooterDetails[0].page_size_id);
                setcustum_height(res.data.result.headerFooterDetails[0].custum_height);
                setcustum_width(res.data.result.headerFooterDetails[0].custum_width);
                setshowFooter(res.data.result.headerFooterDetails[0].showFooter);
                setshowHeader(res.data.result.headerFooterDetails[0].showHeader);
                setshowRx(res.data.result.headerFooterDetails[0].showRx);
                setheader_left_text(res.data.result.headerFooterDetails[0].header_left_text);
                setheader_right_text(res.data.result.headerFooterDetails[0].header_right_text);
                setlogoTypeName(res.data.result.headerFooterDetails[0].logoTypeName);

                sethospitalAddress(res.data.result.hospitalDetail[0].hospitalAddress);
                sethospitalEmail(res.data.result.hospitalDetail[0].hospitalEmail);
                sethospitalName(res.data.result.hospitalDetail[0].hospitalName);

                setlogo_image(res.data.result.hospitalDetail[0].hospitalLogoImage);




                if (res.data.result.doctorDetail.length > 0) {
                    setdoctorName(res.data.result.doctorDetail[0].doctorName);
                    setspecializationName(res.data.result.doctorDetail[0].specializationName);


                }
                seteducation(res.data.result.doctorEducationDetail);
                //setMarginTop()
            });

    }, [])

    var getS3folder =
        process.env.REACT_APP_S3_URI +
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;

    if (page_size_id == '1') {
        var a4sizewidth = '595px'

    }
    if (page_size_id == '2') {
        var a5sizewidth = '420px'

    }
    if (page_size_id == '3') {
        var b4sizewidth = '780px'

    }
    if (page_size_id == '4') {
        var b5sizewidth = '540px'

    }
    if (page_size_id == '5') {
        var lettersizewidth = '792px'

    }
    if (page_size_id == '6') {
        var prescriptionsizewidth = '540px'

    }
    else {
        var a4sizewidth = '595px'
    }

    return (
        <div align="center" className='patientPrescriptionWrapper 222222222'>

            <div>
                <div
                    align="center"
                    style={{
                        width: a4sizewidth || a5sizewidth || b4sizewidth || b5sizewidth || lettersizewidth || prescriptionsizewidth || custum_width + 'px',
                        // height: custum_height +'px',
                        marginLeft: '0px',
                        marginRight: '0px',
                        marginTop: '0px',
                        marginBottom: '0px',
                        // width:"595px"
                    }}
                >



                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '400' }}>
                                    <div class="printOutHeaderSec ">
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr height="80px">
                                                    <td colspan="12" valign="middle" align="center">
                                                        {showHeader == 1 &&
                                                            <div style={{ width: '100%', float: 'none', marginTop: '30px' }}>
                                                                <p
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '16px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        margin: '0px 0 3px 0px',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            marginTop: '-5px',
                                                                            display: 'inline - block',
                                                                            width: '100%',
                                                                        }}
                                                                    >

                                                                        {headerIncludeLogo == 1 &&
                                                                            logoTypeName == 'Wide' &&
                                                                            showHeader &&
                                                                            <img

                                                                                src={

                                                                                    logo_image != (null || "") ?

                                                                                        getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : Logo


                                                                                }

                                                                                style={{

                                                                                    float: 'left',

                                                                                    width: 'auto',
                                                                                    height: '45px',
                                                                                }}
                                                                            />}
                                                                        {headerIncludeLogo == 1 &&
                                                                            logoTypeName == 'Square' &&
                                                                            showHeader &&
                                                                            <img
                                                                                src={logo_image != (null || "") ?

                                                                                    getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : Logo}

                                                                                style={{


                                                                                    float: 'middle',
                                                                                    width: 'auto',
                                                                                    height: '65px',
                                                                                }}
                                                                            />}
                                                                        {headerIncludeLogo == 1 &&
                                                                            logoTypeName == 'Narrow' &&
                                                                            showHeader &&
                                                                            <img
                                                                                src={logo_image != (null || "") ?

                                                                                    getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : Logo}

                                                                                style={{


                                                                                    float: 'left',
                                                                                    width: 'auto',
                                                                                    height: '43px',
                                                                                }}
                                                                            />}
                                                                    </span>
                                                                </p>
                                                            </div>}

                                                        <div class="practicePrintName" style={{ marginTop: logoTypeName !== 'Square' && '30px', width: logoTypeName == 'Square' ? '100%' : '87%' }}>
                                                            <p
                                                                style={{
                                                                    fontWeight: '700',
                                                                    fontSize: '16px',
                                                                    clear: 'both',
                                                                    width: '100%',
                                                                    marginBottom: '1px',
                                                                    marginTop: '0',
                                                                }}
                                                            >
                                                                {/* {header_title} */}
                                                                {hospitalName}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    fontWeight: '400',
                                                                    fontSize: '12px!important',
                                                                    clear: 'both',
                                                                    width: '100%',
                                                                    margin: '0',
                                                                }}
                                                            >
                                                                {hospitalAddress}
                                                            </p>
                                                        </div>
                                                        {header_align == 'Right' &&
                                                            showHeader == 1 &&
                                                            <div class="practicePrintName" style={{ width: '100%' }}>
                                                                <p
                                                                    align="right"
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '12px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        marginBottom: '1px',
                                                                        marginTop: '0',
                                                                        textAlign: 'right',
                                                                    }}
                                                                >
                                                                    {doctorName}
                                                                </p>

                                                                {education.map((item, i) => (
                                                                    <p
                                                                        style={{
                                                                            fontWeight: '400',
                                                                            fontSize: '12px',
                                                                            clear: 'both',
                                                                            width: '100%',
                                                                            marginBottom: '1px',
                                                                            marginTop: '0',
                                                                            textAlign: 'right',
                                                                        }}
                                                                    >
                                                                        {item.education}
                                                                    </p>))}
                                                                <p
                                                                    align="right"
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '12px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        marginBottom: '1px',
                                                                        marginTop: '0',
                                                                        textAlign: 'right',
                                                                    }}
                                                                >
                                                                    {specializationName}
                                                                </p>
                                                            </div>}
                                                        {header_align == 'Left' &&
                                                            showHeader == 1 &&
                                                            <div class="practicePrintName" style={{ width: '100%' }}>
                                                                <p
                                                                    align="left"
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '12px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        marginBottom: '1px',
                                                                        marginTop: '0',
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    {doctorName}
                                                                </p>

                                                                {education.map((item, i) => (
                                                                    <p
                                                                        style={{
                                                                            fontWeight: '400',
                                                                            fontSize: '12px',
                                                                            clear: 'both',
                                                                            width: '100%',
                                                                            marginBottom: '1px',
                                                                            marginTop: '0',
                                                                            textAlign: 'left',
                                                                        }}
                                                                    >
                                                                        {item.education}
                                                                    </p>))}
                                                                <p
                                                                    align="right"
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '12px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        marginBottom: '1px',
                                                                        marginTop: '0',
                                                                        textAlign: 'right',
                                                                    }}
                                                                >
                                                                    {specializationName}
                                                                </p>
                                                            </div>}
                                                        {header_align == 'Center' &&
                                                            showHeader == 1 &&
                                                            <div class="practicePrintName" style={{ width: '100%' }}>
                                                                <p
                                                                    align="center"
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '12px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        marginBottom: '1px',
                                                                        marginTop: '0',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    {doctorName}
                                                                </p>

                                                                {education.map((item, i) => (
                                                                    <p
                                                                        style={{
                                                                            fontWeight: '400',
                                                                            fontSize: '12px',
                                                                            clear: 'both',
                                                                            width: '100%',
                                                                            marginBottom: '1px',
                                                                            marginTop: '0',
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {item.education}
                                                                    </p>))}
                                                                <p
                                                                    align="right"
                                                                    style={{
                                                                        fontWeight: '400',
                                                                        fontSize: '12px',
                                                                        clear: 'both',
                                                                        width: '100%',
                                                                        marginBottom: '1px',
                                                                        marginTop: '0',
                                                                        textAlign: 'right',
                                                                    }}
                                                                >
                                                                    {specializationName}
                                                                </p>
                                                            </div>}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colspan="12">
                                                        <hr color="#142536" style={{ margin: '0' }} />
                                                    </td>
                                                </tr>
                                                {showHeader == 1 &&
                                                    <tr>
                                                        {showHeader == 1 && header_left_text != '' && header_left_text != null &&
                                                            <td
                                                                width={header_right_text != '' ? "50%" : "100%"}
                                                                colspan={header_right_text != '' ? "6" : "12"}
                                                                align="left"
                                                                style={{ fontSize: '12px', wordBreak: 'break-word' }}
                                                            >
                                                                {header_left_text}
                                                            </td>
                                                        }
                                                        {showHeader == 1 && header_right_text != '' && header_right_text != null &&
                                                            <td
                                                                width={header_left_text != '' ? "50%" : "100%"}
                                                                colspan={header_left_text != '' ? "6" : "12"}
                                                                align="right"
                                                                style={{ fontSize: '12px', wordBreak: 'break-word' }}
                                                            >{header_right_text}</td>}
                                                    </tr>
                                                }

                                                <tr>
                                                    <td colspan="12">
                                                        <hr color="#142536" style={{ margin: '0' }} />
                                                    </td>
                                                </tr>




                                            </tbody>
                                        </table>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        {/* Images Print Section ends here */}

                    </table>
                </div>

            </div>
        </div>
    );
}

export default HeaderDoctorPrescriptionPrint;
