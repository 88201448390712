import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import ReactApexChart from 'react-apexcharts';



const PatientHistory = (props) => {
  const series = [
    {
      name: 'Total Income',
      data: props.totalIncomes
    },
    {
      name: 'Total Expenses',
      // data: props.totalIncomes
      data: props.totalExpense
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 430,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 20 + (60 / (1 + 30*Math.exp(-props.xAxis.length /3)))+ "%",
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: props.xAxis,
      type: "string",
    },
  };

  return (
    <div id="chart">
      <ReactApexChart key={props} options={options} series={series} type="bar" height={430} />
    </div>
  );
};

export default PatientHistory;

