import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import noImage from '../images/noImage.png'
import moment from 'moment';
import $ from "jquery";

function TodoListStatusView() {

    const [todoStatusList, setTodoStatusList] = useState([]);
    const [todoStatusListPopup, setTodoStatusListPopup] = useState([]);
    const [todoStatusChanage, setTodoStatusChanage] = useState("0");

    var getS3healthInfo =
        process.env.REACT_APP_S3_BUCKET_URL +
        process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
        process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;

    useEffect(() => {
        toDoStatusList();
    }, []);

    const toDoStatusList = () => {

        let postData = {
            hospitalId: localStorage.getItem('hospitalId')
        }
        callingAPI('commonUserFunctions/dashboardTodoList', postData).then((response) => {

            if (response.data.success === "1") {
                setTodoStatusList(response.data.result)
            }

        })
    }

    const openPopup = (list) => {
        let listarr = [list]
        setTodoStatusListPopup(listarr)
        setTodoStatusChanage("0")
        $("#open-modal").css("visibility", "visible");
        $("#open-modal").css("opacity", "1");
    }
    const closePopup = () => {
        $("#open-modal").css("visibility", "hidden");
        $("#open-modal").css("opacity", "0");
    }
    const addNewHref = () => {
        window.location.href = "/settings/toDoList"
    }

    const todoStatusChangeClick = (toDoId) => {
        var x = window.confirm("Are you sure?");
        if (x) {
            let postData = {
                todo_id: toDoId,
                comments: document.getElementById("addComment").value !== "" ? document.getElementById("addComment").value : "",
            }
            callingAPI('commonUserFunctions/todoListStatusChange', postData).then((response) => {

                if (response.data.success === "1") {
                    setTodoStatusChanage(response.data.success)
                    setTimeout(() => {
                        toDoStatusList();
                        closePopup()
                    }, 700);
                }

            })
        }
    }



    return (
        <>

            <div className='toDoListPg'>

                <>
                    {(window.sessionStorage.getItem('isOwner')) === "true" || (window.sessionStorage.getItem('role') === "ROLE_HOSPITALADMIN") ?

                        <div className="total_revnuheader">
                            <h2 className='total_revnuheadtxt'>To-Do List(s) <span onClick={addNewHref}>Add New</span></h2>
                        </div>
                        :
                        <div className="total_revnuheader">
                            <h2 className='total_revnuheadtxt'>To-Do List(s)</h2>
                        </div>
                    }

                    {todoStatusList.length > 0 ?
                        <div className='todayAppScroll toDoScroll toDoList-mb'>

                            {todoStatusList.map((data, i) =>
                                <div id="openTodoStatus" onClick={() => openPopup(data)}>
                                    <div class="dash_AppmntRightBlock" style={data.due_date <= moment().startOf('day').valueOf() ? { border: '2px solid red' } : { backgroundColor: '#efa7a733' }}>
                                        <div class="todyDetails-appomnt">
                                            <div class="patntname-apponmnt elipsis toDoCover"><b>{data.todoCategoryName}</b>
                                                <span className='toDoListDate'>
                                                    <div class="dctrname-apponmnt " style={{ textTransform: "none" }}> {data.due_date !== "" ? <><span>Due Date :</span>
                                                        <span>{moment(parseInt(data.due_date)).format('DD-MM-YYYY')}</span>
                                                    </> : ""} </div>
                                                </span>
                                            </div>
                                            <div class="dctrname-apponmnt elipsis" style={{ textTransform: "none", paddingRight: "7px" }}>{data.discription}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        :

                        <div className='todayAppScroll nodataFndTodo'>
                            <p> No To-Do List Found </p>
                        </div>

                    }

                </>


                {/* ================ POPUP CSS BEGINS HERE ==================== */}


                <div id="open-modal" class="modal-window">

                    {todoStatusListPopup.map((list) =>
                        <div className='todoList-popuptop-padd'>
                            <h1 className='todoList-popupheadtxt'>{list.todoCategoryName}</h1>
                            <div className="todoList-popupwrperbody">
                                <span>
                                    {list.image_name !== "" && list.image_name !== "null" ?
                                        <img src={getS3healthInfo + "/" + list.image_name} alt={list.image_name} />
                                        : ""}
                                </span>
                                <div title="Close" class="modal-close" onClick={closePopup}>&times;</div>


                                <div className='toDoCateDes'>{list.discription}</div>
                                <span className='toDoListDate'>
                                    <div class="dctrname-apponmnt d-flex" style={{ textTransform: "none", width: "190px" }}>{list.created_on !== "" ? <><span>Created Date :</span><span> {moment(parseInt(list.created_on)).format('DD-MM-YYYY')}</span></> : ""}</div>
                                    <div class="dctrname-apponmnt  d-flex" style={{ textTransform: "none", width: "190px" }}>{list.due_date !== "" ? <><span>Due Date :</span> <span>{moment(parseInt(list.due_date)).format('DD-MM-YYYY')}</span></> : ""}</div>


                                    {(list.status === "Pending" && todoStatusChanage === "0") &&
                                        <>
                                            {/* <div class="consult-btn-appomnt toDoPending" >
                                        <div className='consultpatient-btntxt'
                                            onClick={(e) => todoStatusChangeClick(list.todo_id)}
                                        >Change Status to Done</div>
                                    </div> */}
                                            <div className='addComentBox'>
                                                <textarea id="addComment" className='addComentBox-textarea' placeholder='Add Comments'></textarea>
                                                <div className='comment-btntxt'><button className='addcomment-btn' onClick={(e) => todoStatusChangeClick(list.todo_id)}>Change Status to Done</button></div>
                                            </div>
                                        </>
                                    }
                                    {todoStatusChanage === "1" &&
                                        <div class="consult-btn-appomnt toDoPending greentext-donebtn" >
                                            <div className='consultpatient-btntxt greentext-done' id="statusDone">Done</div>
                                        </div>
                                    }



                                </span>
                            </div>

                        </div>
                    )}
                </div>


                {/* ================ POPUP CSS ENDS HERE ==================== */}
            </div >
        </>
    )
}

export default TodoListStatusView