import React, { useEffect, useState } from "react";
import { callingAPI } from "../config";
// import Pagination from "react-js-pagination";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
function DoctorReference() {
  const [filterName, setFilterInput] = useState([]);
  const [dataFlag, setDataFlag] = useState(false);
  const downloadName = "DoctorReferen";
  const getpatientsList = () => {
    let PostData = {
      "doctorId": window.sessionStorage.getItem("doctorId"),
    };
    callingAPI("patientProfile/doctorReferenceReport", PostData).then((response) => {
      if (response.data.success === "1") {
        setFilterInput(response.data.result.doctorReferenceData);
        setDataFlag(true);
      } else { setDataFlag(true); }
    });
  };
  useEffect(() => {
    getpatientsList();
  }, []);
  function patientListDownload() {
    const csvRow = [];
    var MedicineDetails = [
      [
        "Doctor%20Name",
        "Doctor%20Email",
        "	Date%20",
        "Patient%20Name",
        "Message%20Note",
      ],
    ];
    var name = MedicineDetails;
    let PostData = {
      "doctorId": window.sessionStorage.getItem("doctorId"),
    };
    callingAPI("patientProfile/doctorReferenceReport", PostData).then((response) => {
      for (var item = 0; item < response.data.result.doctorReferenceData.length; item++) {
        MedicineDetails.push([
          response.data.result.doctorReferenceData[item].referDoctorName.replace(/ /g, '%20'),
          response.data.result.doctorReferenceData[item].referDrEmailid.replace(/,/g, '%20').replace(/ /g, '%20'),
          response.data.result.doctorReferenceData[item].date,
          response.data.result.doctorReferenceData[item].patientName,
          response.data.result.doctorReferenceData[item].drNote,
        ]);
      }
      for (var i = 0; i < MedicineDetails.length; ++i) {
        csvRow.push(MedicineDetails[i].join(","));
      }
      var csvString = csvRow.join("%0A");
      var creat = document.createElement("a");
      creat.href = "data:attachment/csv," + csvString;
      creat.target = "_Blank";
      creat.download = downloadName + ".csv";
      document.body.appendChild(creat);
      creat.click();
    })
  }
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <div className="dashboardTitle">Doctor Reference</div>

      <div>
        {dataFlag == false ? (
          <div align="center">
            <Loader />
          </div>
        ) : ("")}
        <div className="dashboardContent">
          <div className="reportWrap">
            <div className="dashboardSearch1">
              <div className="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!filterName.length ? (
                  <div className="col-md-2 dashboardSearchList">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      id="export"
                      className="dashButtonBorder dashButtonBorderGrey"
                      disabled
                    >
                      Export
                    </button>
                  </div>
                ) : (
                  <div className="col-md-2 dashboardSearchList">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      onClick={patientListDownload}
                      id="export"
                      className="dashButtonBorder dashButtonBorderGrey"
                    >
                      Export
                    </button>
                  </div>
                )}

              </div>

            </div>
            <div className="dashboardtable">
              {filterName.length > 0 ?
                <table>

                  <thead>
                    <tr>
                      <th>Doctor Name</th>
                      <th>Doctor Email</th>
                      <th >Date</th>
                      <th>Patient Name</th>
                      <th >Message</th>

                    </tr>
                  </thead>
                  <tbody>
                    {filterName.map((item, index) => (
                      <tr>
                        <td>{item.referDoctorName}</td>
                        <td>{item.referDrEmailid}</td>
                        <td>{item.date}</td>
                        <td>{item.patientName}</td>
                        <td>{item.drNote}</td>
                      </tr>))}
                  </tbody>
                </table> : <NoData />}
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}

export default DoctorReference;
