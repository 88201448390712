import { faSave } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import Datepicker from 'react-datepicker';
import { callingAPI, callingAPI_GET } from '../config';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { DAX } from 'aws-sdk';
import { data } from 'jquery';
import Loader from '../Common/loader';
function PatientAppointmentsEdit() {
	var role = window.sessionStorage.getItem('role');
	const [showDiv, setShowDiv] = useState(true);
	const [showDiv1, setShowDiv1] = useState(true);
	const [load, setLoad] = useState(true);
	//var hospitalSession = localStorage.getItem("hospitalId");
	const [hospitalSessionName, sethospitalSessionName] = useState('');
	const [newDate, setDate] = useState("");
	const [getDoctorName1, setDoctorName1] = useState('');
	const [doctorData, setDoctordata] = useState([]);
	const [hospitalData, sethospitalData] = useState([]);
	const [statusData, setStatusData] = useState([]);
	const [saveButton, setSaveButton] = useState();
	const [hospitalName, setHospitalName] = useState('')
	const [doctorName, setDoctorName] = useState(window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN' ? window.sessionStorage.getItem('patientDoctorId') : '')
	const [doctorNameSession, setDoctorNameSession] = useState(window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userName') : '')
	const [doctorNameSession1, setDoctorNameSession1] = useState(window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userName') : '')
	const [hospitalSessionName1, sethospitalSessionName1] = useState(window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? '' : window.sessionStorage.getItem('siteName'))
	const [appointDatetime, setAppointDatetime] = useState('');
	const [status, setStatus] = useState('3');
	const [getDoctorVisitId, setDoctorVisitId] = useState('');
	const [getEmrPatientAppointmentId, setEmrPatientAppointmentId] = useState('');
	const [reason, setReason] = useState('');
	const location = useLocation();
	const [lastVisitedDate, setLastVisitedDate] = useState("")
	const [doctor, setDoctor] = useState('')
	const [hospitalId, setHospitalId] = useState("")
	const [doctorsId, setDoctorId] = useState("")
	const [hospitalsId, sethospitalsId] = useState("")
	var date = moment(newDate).format('YYYY-MM-DD');
	var time = moment(newDate).format('hh:mm:ss A');

	var convertedDate = moment(newDate).format('YYYY-MM-DD hh:mm A');
	var newDateee = Date.parse(convertedDate)
	const hospitalNameChange = (event) => {
		setHospitalName(event.target.value);
	};
	const doctorNameChange = (event) => {
		setDoctorName(event.target.value);
	};
	const reasonChange = (event) => {
		setReason(event.target.value);
	};
	const statusChange = (event) => {
		setStatus(event.target.value);
	}
	const filterPassedTime = (time) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};
	useEffect(() => {
		sethospitalSessionName(window.sessionStorage.getItem('userName'))

		getStatusDropdownValues();
		getHospitaldata();
		doctorDropDown();
		if (typeof location.state !== 'undefined') {
			document.getElementById("savebutton").style.display = 'none'
			document.getElementById("updatebutton").style.display = 'block'

			const { data } = location.state;
			setDoctorNameSession(data.doctorName)
			sethospitalSessionName(data.hospitalName)
			setHospitalName(data.hospitalId)
			setDoctorName(data.doctorId)
			setStatus(data.statusId);
			setReason(data.visitReason);
			setDate(data.startTime);

			setEmrPatientAppointmentId(data.appointmentId)
			setDoctorVisitId(data.docVisitId)
		}
		else {
			document.getElementById("savebutton").style.display = 'block'
			document.getElementById("updatebutton").style.display = 'none'
			//setDoctorName(getDoctorName1)
		}
	}, []);
	//Save
	const onAppoinmentSave = () => {
		setLoad(false)
		var flag = true;
		if (document.getElementById("appoinmentDateTime").value === "") {
			document.getElementById("savebutton").disabled = true;
			alert("Enter Date")
			setLoad(true)
			document.getElementById("appoinmentDateTime").focus()
			flag = false;


		}
		else if (document.getElementById("appoinmentReason").value === "") {
			alert("Please Enter Reason")
			setLoad(true)
			document.getElementById("appoinmentReason").focus()
			flag = false;


		}
		if (flag === true) {
			setShowDiv(false);
			setShowDiv1(false);
			let PostData = {
				doctorId: window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('patientDoctorId') : document.getElementById("cmbDocName").value,
				scheduledTimeSlotAppointmentType: "Walk In",
				emrPatientAppointmentCreatedOn: 'Now()',
				doctorVisitDateOfVisit: date + " " + time,
				doctorVisitDischargeSummary: '',
				hospitalId: window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? document.getElementById("cmbHospitalName").value : localStorage.getItem("hospitalId"),
				doctorVisitIsActive: true,
				memberId: window.sessionStorage.getItem('memberId'),
				doctorVisitReasonByDoctor: reason,
				doctorVisitReasonForVisitByPatient: reason,
				visitDetails: reason,
				emrPatientAppointmentId: getEmrPatientAppointmentId,
				emrPatientAppointmentLastVisitedDate: lastVisitedDate,
				emrPatientAppointmentLastVisitedReason: reason,
				doctorVisitId: getDoctorVisitId,
				doctorVisitModifiedBy: window.sessionStorage.getItem('patientDoctorId'),
				doctorVisitModifiedOn: 'Now()',
				emrPatientAppointmentDate: date,
				emrPatientappointmentTime: time,
				emrPatientAppointmentReasonForVisit: reason,
				emrPatientStatusId: status,
				emrPatientStatusIsCurrentStatus: '1',
				createdBy: window.sessionStorage.getItem("userId"),
				isTimeSlotBooked: true,
				patientAppointmentStartTime: newDateee,
				patientAppointmentEndTime: newDateee + 15 * 60 * 1000

			};
			callingAPI('patientProfile/addPatientAppointment', PostData)
				.then((response) => {
					if (response.data.success === '1') {
						setShowDiv(false);
						setShowDiv1(false);
						window.location.href = '/patients/patientAppointments';
						if (typeof location.state !== 'undefined') {
							alert(' Appointment details Updated successfully ');
						}
						else {
							alert('Appointment details Saved Successfully ');
						}

					}
					else if (response.data.success === '0') {
						alert(response.data.errorMessage);
						document.getElementById("aaa").style.display = "block"
						document.getElementById("savebutton").disabled = true;
						setShowDiv(true);
						setLoad(true)
					}
					// setShowDiv(true);
					// alert(response.data.errorMessage);
				}

				);

		}
	};
	//statusdropdown
	const getStatusDropdownValues = () => {
		callingAPI('patientAppointment/patientStatusDropdown')
			.then((response) => {
				if (response.data.success === '1') {
					setStatusData(response.data.result);
				}
			})
			.catch();
	};
	//Doctor Name API
	const doctorDropDown = () => {
		let PostData = {
			hospitalId: localStorage.getItem("hospitalId"),
			selectCombo: '',
			inputSearch: '',
		};
		callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
			if (response.data.success === '1') {
				//setDoctorName1(response.data.result.doctorsListArray[0].doctorId)


				setDoctordata(response.data.result.doctorsListArray);
				setDoctorId(response.data.result.doctorsListArray[0].doctorId)

			}
			else {
				alert("no doctors found")
			}
		});
	}
	//HospitalName
	const getHospitaldata = () => {
		let HospitalData = {
			doctorId: window.sessionStorage.getItem('doctorId')
		};
		callingAPI('patientsListing/getHospitalListByDoctor', HospitalData)
			.then((response) => {
				if (response.data.success === '1') {
					sethospitalData(response.data.result);
					sethospitalSessionName(
						response.data.result.find((x) => x.hospitalId == localStorage.getItem("hospitalId")).hospitalName);
					//setHospitalId(response.data.result[0].hospitalId)

					//sethospitalsId(response.data.result[0].hospitalId)

				}
			})
			.catch();
	};


	return (
		<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
			<div id="divPatientContent" class="sideMenuContent">
				<div class="treatmentPlanCover" id="treatmentPlanListDiv" style={{ border: 'none' }}>
					<div class="treatmentPlanHead">
						<span class="treatmentPlanName">APPOINTMENTS</span>
						<a onClick={onAppoinmentSave} id="aaa">
							<span id="savebutton" class="treatmentPlanBtn pl_blue_btn" style={{ background: '#54a954', pointerEvents: showDiv == true ? '' : 'none' }}>
								Save{saveButton}{' '}
							</span>
						</a>
						<a onClick={onAppoinmentSave}>
							<span id="updatebutton" class="treatmentPlanBtn pl_blue_btn" style={{ background: '#54a954', pointerEvents: showDiv1 === true ? '' : 'none' }}>
								Update{saveButton}{' '}
							</span>
						</a>
						<a href="/patients/patientAppointments" style={{ color: '#000' }}>
							<span class="treatmentPlanBtn pl_blue_btn pl_grey_btn">Cancel</span>
						</a>
					</div>
					{load === false ? (
						<Loader />
					) :
						<>
							<div class="treatmentClinicContent">
								<div class="clinicContentList appointmentsHospName_PTour">
									<div class="clinicalLabel">Hospital Name</div>
									<div class="clinicalField">
										{role === 'ROLE_DOCTOR' ? (
											<select
												value={hospitalName}
												onChange={hospitalNameChange}
												class="fulWidthSelect changeMainSelect hospitalClass"
												name="cmbHospital"
												id="cmbHospitalName"
											>

												{hospitalData.map((data, i) => (
													<option value={data.hospitalId}>{data.hospitalName}</option>
												))}
											</select>
										) : (
											<input
												value={hospitalSessionName}
												//onChange={hospitalNameChange}
												disabled
												class="fulWidthSelect changeMainSelect hospitalClass"
												name="cmbHospital"
												id="cmbHospitalIName"
											/>
										)}
									</div>
								</div>
							</div>
							<div class="treatmentClinicContent">
								<div class="clinicContentList appointmentsHospName_PTour">
									<div class="clinicalLabel">Doctor Name</div>
									<div class="clinicalField">
										{role === 'ROLE_DOCTOR' ? (
											<input
												value={doctorNameSession1}
												//onChange={doctorNameChange}
												class="fulWidthSelect changeMainSelect hospitalClass"
												disabled
												name="cmbHospital"
												id="cmbDoctorName"
											/>
										) : (
											<select
												value={doctorName}
												onChange={doctorNameChange}
												class="fulWidthSelect changeMainSelect hospitalClass"
												name="cmbHospital"
												id="cmbDocName"
											>
												{doctorData.map((data, i) => (
													<option value={data.doctorId}>{data.doctorName}</option>
												))}

											</select>

										)}
									</div>
								</div>
							</div>
							{/*  */}
							<div class="treatmentClinicContent">
								<div class="clinicContentList appointmentsHospName_PTour">
									<div class="clinicalLabel">Appointment Date & Time <sup style={{ color: '#ff0000', fontSize: "16px", top: '0px' }}>*</sup></div>
									<div class="clinicalField">
										<Datepicker
											autoComplete='off'
											required
											id="appoinmentDateTime"
											className="Datepicker pa2 inputField"
											minDate={new Date()}
											filterTime={filterPassedTime}
											placeholderText="Date  Time"
											dateFormat="dd-MM-y h:mm aa"
											calendarClassName="rasta-stripes"
											selected={newDate}
											showTimeSelect
											onChange={(date) => setDate(date)}
											peekNextMonth
											showMonthDropdown
											showYearDropdown
											dropdownMode="select"
										/>

										<label
											className="patientAppontmnt icon iconCalendar calendar plcalendar"
											style={{ fontSize: '16px' }}
											htmlFor="appoinmentDateTime"
										>
											<FontAwesomeIcon icon={faCalendarAlt} />
										</label>
									</div>
								</div>
							</div>
							{/*  */}
							<div class="treatmentClinicContent">
								<div class="clinicContentList appointmentsHospName_PTour">
									<div class="clinicalLabel">Reason <sup style={{ color: '#ff0000', fontSize: "16px", top: '0px' }}>*</sup></div>
									<div class="clinicalField">
										<textarea
											id="appoinmentReason"
											value={reason}
											onChange={reasonChange}
											class="fulWidthSelect changeMainSelect hospitalClass"
											style={{ resize: 'none' }}
										>
											{reason}
										</textarea>
									</div>
								</div>
							</div>

							<div class="treatmentClinicContent">
								<div class="clinicContentList appointmentsHospName_PTour">
									<div class="clinicalLabel">Status</div>
									<div class="clinicalField">
										{Date.parse(moment(newDate).format('YYYY-MM-DD')) > Date.parse(moment(new Date()).format('YYYY-MM-DD')) ?
											<select
												value={status}
												onChange={statusChange}
												class="fulWidthSelect changeMainSelect hospitalClass"
												name="cmbHospital"
												id="cmbStatus"
											>

												{statusData.filter((item) => item.patientAppointmentStatus !== "COMPLETED").map((data, k) => (

													<option value={data.patientAppointmentStatusId}>{data.patientAppointmentStatus}</option>

												))}

											</select>

											:
											<select
												value={status}
												onChange={statusChange}
												class="fulWidthSelect changeMainSelect hospitalClass"
												name="cmbHospital"
												id="cmbStatus"
											>
												{statusData.slice(0, 10).map((data, i) => (
													<option value={data.patientAppointmentStatusId}>{data.patientAppointmentStatus}</option>
												))}
											</select>}
									</div>
								</div>
							</div>
						</>}
				</div>
			</div>
		</div>
	);
}
export default PatientAppointmentsEdit;
