import React, { useState, useEffect } from 'react'
import { callingAPI } from '../../config';
import noImage from '../images/noImage.png'
import moment from 'moment';
import $ from "jquery";

function PatientRecallStatusView() {

    const [recallList, setRecallList] = useState([]);
    const [recallListPopUp, setRecallListPopUp] = useState([]);



    useEffect(() => {
        recallStatusList();
    }, []);

    const recallStatusList = () => {

        let postData = {

            memberId: "",
            doctorId: "",
            hospitalId: localStorage.getItem('hospitalId')
        }
        callingAPI('patientProfile/patientRecallListing', postData).then((response) => {

            if (response.data.success === "1") {
                setRecallList(response.data.result.patientRecallData)
            }

        })
    }
    const recallStatusChangeClick = (patientReacallId) => {
        var x = window.confirm("Are you sure?");
        if (x) {
            let postData = {
                patientReacallId: patientReacallId,
                status: "Done",
                userId: window.sessionStorage.getItem("userId")
            }
            callingAPI('patientProfile/patientRecallStatusChange', postData).then((response) => {

                if (response.data.success === "1") {
                    recallStatusList();
                    closePopupeRecall();
                }

            })
        }
    }
    const openPopupRecall = (data) => {
        let listarrRecall = [data]
        setRecallListPopUp(listarrRecall)
        $("#open-modal_recall").css("visibility", "visible");
        $("#open-modal_recall").css("opacity", "1");
    }
    const closePopupeRecall = () => {
        $("#open-modal_recall").css("visibility", "hidden");
        $("#open-modal_recall").css("opacity", "0");
    }
    return (
        <>

            <div className='toDoListPg recall-wrpar'>
                <div>
                    <div className="total_revnuheader recall-paadTop">
                        <h2 className='total_revnuheadtxt'>Recall List(s)</h2>
                    </div>


                    <div className='todayAppScroll toDoScroll recall-box'>
                        {recallList.length > 0 ?
                            <>
                                {recallList.map((data) =>
                                    <div class="dash_AppmntRightBlock" onClick={() => openPopupRecall(data)} style={data.recallDateAndTime <= moment().startOf('day').valueOf() ? { border: '2px solid red' } : { backgroundColor: '#efa7a733' }}>
                                        <div class="todyDetails-appomnt">
                                            <div class="patntname-apponmnt elipsis toDoCover"><b>{data.memberName}</b>
                                                <span className='toDoListDate'>
                                                    {data.recallDateAndTime !== "" && data.recallDateAndTime !== 0 ? <div class="dctrname-apponmnt " style={{ textTransform: "none" }}>  <><span>Recall Date : </span>
                                                        {moment(parseInt(data.recallDateAndTime)).format('DD-MM-YYYY hh:mm A')}
                                                    </> </div> : ""}
                                                </span>
                                            </div>
                                            <div className="patntname-apponmnt" style={{ textTransform: "capitalize" }}>{data.doctorFullName}</div>
                                            <div class="dctrname-apponmnt elipsis" style={{ textTransform: "none", paddingRight: "7px" }}>{data.comments !== "" && data.comments !== "undefined" ? data.comments : ""} </div>
                                        </div>
                                    </div>
                                )}
                            </>
                            :
                            <div className='todayAppScroll nodataFnd nodata-found_wrpr'>
                                <p>No Recall List Found </p>
                            </div>
                        }


                    </div>

                    <div id="open-modal_recall" class="modal-window">
                        {recallListPopUp.map((list) =>

                            <div className='todoList-popuptop-padd'>

                                <div title="Close" class="modal-close" onClick={() => closePopupeRecall()} >&times;</div>
                                <h1 className='todoList-popupheadtxt'>{list.memberName}</h1>
                                <div style={{
                                    padding: "7px 0px 20px 1px"
                                }}>

                                    <div className='row'>
                                        <div className='col-md-3 text-right'>Doctor Name :</div>
                                        <div className='col-md-8' style={{ paddingLeft: "0" }}>{list.doctorFullName !== "" ? list.doctorFullName : ""}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3 text-right'>Created Date :</div>
                                        <div className='col-md-8' style={{ paddingLeft: "0" }}>{list.createdOnDate !== "" ?
                                            (moment(list.createdOnDate).format('DD, MMM YYYY')) : ""}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3 text-right'>Recall Date :</div>
                                        <div className='col-md-8' style={{ paddingLeft: "0" }}>{list.recallDateAndTime !== "" ?
                                            (moment(list.recallDateAndTime).format('DD, MMM YYYY hh:mm:ss a')) : ""}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3 text-right'>Note :</div>
                                        <div className='col-md-8' style={{ paddingLeft: "0" }}>{list.comments !== "" ? list.comments : ""}</div>
                                    </div>


                                    <div className='comment-btntxt text-right pt-3' style={{ marginRight: "20px" }}><button className='addcomment-btn' onClick={(e) => recallStatusChangeClick(list.patientReacallId)}>Change Status to Done</button></div>





                                </div>
                                {/* <div className="todoList-popupwrperbody">
                                <span>
                                    <img />
                                </span>
                                <div title="Close" class="modal-close" onClick={() => closePopupeRecall()} >&times;</div>

                                <div class="" style={{ textTransform: "none", width: "190px" }}>{list.doctorFullName !== "" ? <><span>Doctor Name :</span> <span>{list.doctorFullName}</span></> : ""}</div>
                                <span className='toDoListDate'>
                                    <div class="row" style={{ textTransform: "none", width: "190px" }}>
                                        {list.createdOnDate !== "" ?<>
                                        <div className='col-md-3'>Created Date :</div> 
                                        <div className='col-md-9'>{moment(list.createdOnDate).format('DD, MMM YYYY')}</div></> : ""}
                                        </div>
                                    <div class=" " style={{ textTransform: "none", width: "190px" }}>{list.recallDateAndTime !== "" ? <><span>Recall Date :</span> <span>{moment(parseInt(list.recallDateAndTime)).format('DD, MMM YYYY')}</span></> : ""}</div>
                                    <div>{list.comments !== "" ?<>Note :{list.comments}</> : ""}</div>


                                    <>
                                        <div className='addComentBox'>
                                            <div className='comment-btntxt'><button className='addcomment-btn'>Change Status to Done</button></div>
                                        </div>
                                    </>



                                </span>
                            </div> */}

                            </div>
                        )}
                    </div>

                    {/* <div className='todayAppScroll nodataFndTodo'>
                        <p> No To-Do List Found </p>
                    </div> */}

                </div>

            </div >
        </>
    )
}

export default PatientRecallStatusView