import React, { useState, useEffect } from 'react';
import Header from '../layout/header';
import Footer from '../layout/footer';
import Leftmenu from '../Patients/leftmenu';
import Profiledetail from '../Patients/profiledetail';
import Patientright from '../Patients/patientright';
import Light from '../images/icons/light.png';
import Youtube from '../images/icons/youtube.png';
import med1 from '../images/icons/med1.png';
import med2 from '../images/icons/med2.png';
import med3 from '../images/icons/med3.png';
import med4 from '../images/icons/med4.png';
import med5 from '../images/icons/med5.png';
import med6 from '../images/icons/med6.png';
import med7 from '../images/icons/med7.png';
import med8 from '../images/icons/med8.png';
import Datepicker from 'react-datepicker';
import moment from 'moment';
import Loader from '../Common/loader';
import { callingAPI } from '../config';
import Speech from '../AudioToText/speechRecognize'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from 'material-ui-core';
import UseMic from '../AudioToText/useMic'
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_PATIENT_MEDICALFILES_S3_IMAGE_PATH;
const { detect } = require('detect-browser');
const browserDetect = detect();
const browser = browserDetect.name
function CasesheetMedicalAdd() {
	const [isChief, setChief] = useState(false);
	const [isAssociated, setAssociated] = useState(false);
	const [isKnownCase, setKnownCase] = useState(false);
	const [isObservation, setObservation] = useState(false);
	const [isInvestigation, setInvestigation] = useState(false);
	const [isRemark, setRemark] = useState(false);
	const [isDiagnosis, setIsDiagnosis] = useState(false);
	const [isTreatment, setTreatment] = useState(false);
	const [activeComponent, setActiveComponent] = useState(null);
	const [getDrName, setDrName] = useState('');
	const [isChanged, setIsChanged] = useState(false);
	const [style, setStyle] = useState({ display: 'none' });
	const [fullArray, setFullArray] = useState([]);
	const [associatedArray, setAssociatedArray] = useState([]);
	const [knownCaseArray, setKnownCaseArray] = useState([]);
	const [diagnosisArray, setDiagnosisArray] = useState([]);
	const [chiefDescription, setChiefDescription] = useState('');
	const [chiefDescriptionSpeech, setChiefDescriptionSpeech] = useState([]);
	const [knownDescription, setKnownDescription] = useState('');
	const [associatedDescription, setAssociatedDescription] = useState('');
	const [getValue, setGetValue] = useState([]);
	const [clearValue, setClear] = useState('');
	const [associateClearValue, setAssociateClear] = useState('');
	const [knownClearValue, setKnownClear] = useState('');
	const [toggle, setToggle] = useState(false);
	const [appointmentDate, setAppointmentDate] = useState('');
	const [sinceDate1, setSinceDate1] = useState(new Date());
	const [sinceDate2, setSinceDate2] = useState(new Date());
	const [sinceDate3, setSinceDate3] = useState(new Date());
	const [observations, setObservations] = useState('');
	const [investigations, setInvestigations] = useState('');
	const [diagnosis, setDiagnosis] = useState('');
	const [remarks, setRemarks] = useState('');
	const [appointmentNext, setAppointmentNext] = useState('');
	const [general, setGeneral] = useState([]);
	const [nonGeneral, setNonGeneral] = useState([]);
	const [image, setImage] = useState([]);
	const [doctorList, setDoctorList] = useState([]);
	const [diagnosisList, setDiagnosisList] = useState([]);
	const [freeArticleData1, setFreeArticleData1] = useState([]);
	const [nonGeneralData, setNonGeneralData] = useState([]);
	const [generalData, setGeneralData] = useState([]);
	const [pmId, setpmId] = useState('');
	const [diagnosisId, setDiagnosisId] = useState('');
	const [cmbStatus, setCmbStatus] = useState('3');
	const [chiefReason, setChiefReason] = useState('');
	const [associatedReason, setAssociatedReason] = useState('');
	const [knownReason, setKnownReason] = useState('');
	const [observationReason, setObservationReason] = useState('');
	const [investigationReason, setInvestigationReason] = useState('');
	const [diagnosisReason, setDiagnosisReason] = useState('');
	const [txtDiagnosis, setTxtDiagnosis] = useState('');
	const [remarkReason, setRemarkReason] = useState('');
	const [futureAppointmentDate, setFutureAppointmentDate] = useState([]);
	const [dataFlag, setDataFlag] = useState(false);
	const [futureDrVisitId, setFutureDrVisitId] = useState('')
	const [txtTreatmentDone, setTxtTreatmentDone] = useState('');
	const [disable, setDisable] = useState(false);
	const [followUpClick, setFollowUpClick] = useState(false);
	const [updatedFollowNote, setUpdatedFollowNote] = useState('');
	const [selectedAppointmentDate, setSelectedAppointmentDate] = useState('');
	const [chiefCaseSenstiveArray, setChiefCaseSenstiveArray] = useState([])
	const [associatedCaseSenstiveArray, setAssociatedCaseSenstiveArray] = useState([])
	const [knownCaseSenstiveArray, setKnownCaseSenstiveArray] = useState([])
	const [diagnosisSenstiveArray, setDiagnosisSenstiveArray] = useState([])
	const [convertedFutureDate, setConvertedFutureDate] = useState('');
	const [recallClick, setRecallClick] = useState(false);
	const [recallToggle, setRecallToggle] = useState(false);
	const [comments, setComments] = useState('');
	const [recallDate, setRecallDate] = useState('');
	const [speechFlag, setSpeechFlag] = useState();
	const [language, setLanguage] = useState('English');

	const reasonFieldShow = (event) => {
		if (window.sessionStorage.getItem('editId') === '') {
			document.getElementById('chiefReason').style.display = 'none';
			document.getElementById('associatedReason').style.display = 'none';
			document.getElementById('knownReason').style.display = 'none';
			document.getElementById('observationsReason').style.display = 'none';
			document.getElementById('investigationsReason').style.display = 'none';
			document.getElementById('diagnosisReason').style.display = 'none';
			document.getElementById('remarksReason').style.display = 'none';
		} else {
			document.getElementById('chiefReason').style.display = 'block';
			document.getElementById('associatedReason').style.display = 'block';
			document.getElementById('knownReason').style.display = 'block';
			document.getElementById('observationsReason').style.display = 'block';
			document.getElementById('investigationsReason').style.display = 'block';
			document.getElementById('diagnosisReason').style.display = 'block';
			document.getElementById('remarksReason').style.display = 'block';
		}
	};

	const editRecall = () => {
		const postData = {
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId')
		}
		callingAPI('patientProfile/getPatientRecallDetails', postData)
			.then((data) => {

				if (data.data.success === '1') {
					setRecallDate(data.data.result[0].recallDateAndTime)
					setComments(data.data.result[0].comments)
					setRecallToggle(true);
				}
			})
	}

	const editingId = (event) => {
		if (window.sessionStorage.getItem('editId') === '') {
			document.getElementById('saveid').style.display = 'block';
			document.getElementById('lowsaveid').style.display = 'block';
			document.getElementById('updateid').style.display = 'none';
			document.getElementById('lowupdateid').style.display = 'none';
			setDataFlag(true);
		} else {
			document.getElementById('saveid').style.display = 'none';
			document.getElementById('lowsaveid').style.display = 'none';

			document.getElementById('updateid').style.display = 'block';
			document.getElementById('lowupdateid').style.display = 'block';
			const postData = {
				doctorVisitId: window.sessionStorage.getItem('editId'),
				userId: window.sessionStorage.getItem('userId'),
				memberId: window.sessionStorage.getItem('memberId'),
				hospitalId: localStorage.getItem('hospitalId'),
			};
			callingAPI('commonUserFunctions/clinicalNotesAdd', postData)
				.then((data) => {

					if (data.data.success === '1') {
						editRecall();
						setDataFlag(true);
						setpmId(data.data.result.PmIdData);
						setDiagnosisId(data.data.result.hidDiagnosisId);
						let chiefTags = data.data.result.tag1 ? data.data.result.tag1.split(',') : [];
						setFullArray(chiefTags);
						let associateTags = data.data.result.tag2 ? data.data.result.tag2.split(',') : [];
						setAssociatedArray(associateTags);
						let knownTags = data.data.result.tag3 ? data.data.result.tag3.split(',') : [];
						setKnownCaseArray(knownTags);
						setChiefDescription(data.data.result.cheifDesc);
						setAssociatedDescription(data.data.result.txtAssociate);
						var date1 = data.data.result.txtCheifDate
							? data.data.result.txtCheifDate.toString().split('/')
							: [];
						var date2 = data.data.result.txtAssociateDate
							? data.data.result.txtAssociateDate.toString().split('/')
							: [];
						var date3 = data.data.result.txtKnownDate
							? data.data.result.txtKnownDate.toString().split('/')
							: [];
						date1.length > 0
							? setSinceDate1(new Date(date1[2], date1[1] - 1, date1[0]))
							: setSinceDate1(new Date());
						date2.length > 0
							? setSinceDate2(new Date(date2[2], date2[1] - 1, date2[0]))
							: setSinceDate2(new Date());
						date3.length > 0
							? setSinceDate3(new Date(date3[2], date3[1] - 1, date3[0]))
							: setSinceDate3(new Date());
						setKnownDescription(data.data.result.txtKnown);
						setObservations(data.data.result.txtObservations);
						setInvestigations(data.data.result.txtInvestigations);
						setRemarks(data.data.result.txtRemarks);
						setDiagnosis(data.data.result.txtDiagnosis);
						setCmbStatus(data.data.result.status);
						setFutureAppointmentDate(data.data.result.futureAppoinmentDateData);
						setTxtTreatmentDone(data.data.result.txtTreatmentDone)
						setTxtDiagnosis(data.data.result.diagnosisAndTreatmentPlan)
						if (data.data.result.futureAppoinmentDateData.length > 0) {
							setFutureDrVisitId(data.data.result.futureAppoinmentDateData[0].doctorVisitId)
							var date = data.data.result.futureAppoinmentDateData[0].appointDate;
							var d = new Date(date);
							var convertedDate = moment(d).format('YYYY-MM-DD hh:mm A');
							var nextappointmentdate = moment(convertedDate).format('DD-MM-YYYY hh:mm A')
							setSelectedAppointmentDate(convertedDate)
							document.getElementById('weee').style.visibility = 'hidden'
						}
						else {
							setSelectedAppointmentDate('')
						}

						setConvertedFutureDate(nextappointmentdate)
						setGeneralData(data.data.result.generalSection);
						setNonGeneralData(data.data.result.nonGeneralSection);
						if (data.data.result.generalSection.length > 0) {
							document.getElementById('radGeneral1').checked = true;
							let innerChkArr = data.data.result.generalSection[0].genSymptomsId
								? data.data.result.generalSection[0].genSymptomsId.toString().split(',')
								: [];
							for (let i = 0; i < innerChkArr.length; i++) {
								document.getElementById('chkgenSymptom1' + innerChkArr[i]).checked = true;
							}
							document.getElementById('txtRadiographGeneral1').value =
								data.data.result.generalSection[0].genDescription;
						}
						for (let i = 0; i < data.data.result.nonGeneralSection.length; i++) {

							document.getElementById(
								'one' + data.data.result.nonGeneralSection[i].nonGenMemberReviewTypeId
							).checked = true;

							document.getElementById(
								'two' + data.data.result.nonGeneralSection[i].nonGenMemberReviewTypeId
							).style.display =
								'block';

							let innerChkArr = data.data.result.nonGeneralSection[i].nonGenSymptomsId
								? data.data.result.nonGeneralSection[i].nonGenSymptomsId.toString().split(',')
								: [];
							for (let j = 0; j < innerChkArr.length; j++) {
								document.getElementById('chkSymptoms6' + innerChkArr[j]).checked = true;
							}
							document.getElementById(
								'txtRadiograph' + data.data.result.nonGeneralSection[i].nonGenMemberReviewTypeId
							).value =
								data.data.result.nonGeneralSection[i].nonGenDescription;
						}
					}
				})
				.catch();
		}
	};
	const orderAt = (id, mode, dropdownId) => {

		document.getElementById(mode).value =
			document.getElementById(mode).value.split('').length == 1
				? document.getElementById(mode).value.replace(/\s/, '')
				: document.getElementById(mode).value;
		setTimeout(function () {
			const apiJson = {
				complaintTypeId: id,
				txtInput: document.getElementById(mode).value,
			};
			callingAPI('clinicalBackground/dentalCaseSheetTags', apiJson).then((data) => {
				if (document.getElementById(mode).value.split('').length > 1) {

					if (data.data.success === '1' && data.data.result.tagName.length > 0) {
						document.getElementById(dropdownId).style.display = 'block';
						setFreeArticleData1(data.data.result.tagName);
					} else {
						document.getElementById(dropdownId).style.display = 'none';
						setFreeArticleData1([]);
					}
				}
				else {
					setFreeArticleData1([]);
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		}, 200);

	};

	// const setChiefTagData = (itemName, mode, dropdownId) => {
	// 	setFullArray([ ...fullArray, itemName ]);
	// 	setClear('');
	// 	setFreeArticleData1([]);
	// 	document.getElementById(dropdownId).style.display = 'none';
	// };
	// const setAssociatedTagData = (itemName, mode, dropdownId) => {
	// 	setAssociatedArray([ ...associatedArray, itemName ]);
	// 	setAssociateClear('');
	// 	setFreeArticleData1([]);
	// 	document.getElementById(dropdownId).style.display = 'none';
	// };
	// const setKnownTagData = (itemName, mode, dropdownId) => {
	// 	setKnownCaseArray([ ...knownCaseArray, itemName ]);
	// 	setKnownClear('');
	// 	setFreeArticleData1([]);
	// 	document.getElementById(dropdownId).style.display = 'none';
	// };
	const setChiefTagData = (itemName, mode, dropdownId) => {
		if (chiefCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setFullArray([...fullArray, itemName])
			setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, itemName.toLowerCase()])
			setClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
	};
	const setAssociatedTagData = (itemName, mode, dropdownId) => {
		if (associatedCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setAssociatedArray([...associatedArray, itemName]);
			setAssociatedCaseSenstiveArray([...associatedCaseSenstiveArray, itemName.toLowerCase()])
			setAssociateClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
	};
	const setKnownTagData = (itemName, mode, dropdownId) => {
		if (knownCaseSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setKnownCaseArray([...knownCaseArray, itemName]);
			setKnownCaseSenstiveArray([...knownCaseSenstiveArray, itemName.toLowerCase()])
			setKnownClear('');
			setFreeArticleData1([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
	};
	const generalClick = (event, checkedId, mainId) => {
		var symptomsIds = [];
		var tempData = generalData;
		var tempIndex = tempData.findIndex((x) => x.genMemberReviewTypeId == mainId);
		if (tempIndex == -1) {
			tempData.push({
				genMemberReviewTypeId: mainId.toString(),
				genSymptomsId: checkedId.toString(),
				genDescription: '',
			});
		} else {
			symptomsIds = tempData[tempIndex].genSymptomsId
				? tempData[tempIndex].genSymptomsId.toString().split(',')
				: [];
			if (event.target.checked == false) {
				var dataIndex = symptomsIds.findIndex((x) => x == checkedId);
				symptomsIds.splice(dataIndex, 1);
			} else {
				symptomsIds.push(checkedId.toString());
			}
			tempData[tempIndex].genSymptomsId = symptomsIds.toString();
		}
		setGeneralData(tempData);
	};
	const handleClickNonGeneral = (event, id, mainId) => {
		var symptomsIds = [];
		var tempData = nonGeneralData;
		var tempIndex = tempData.findIndex((x) => x.nonGenMemberReviewTypeId == mainId);
		if (tempIndex == -1) {
			tempData.push({
				nonGenMemberReviewTypeId: mainId.toString(),
				nonGenSymptomsId: id.toString(),
				nonGenDescription: '',
			});
		} else {
			symptomsIds = tempData[tempIndex].nonGenSymptomsId
				? tempData[tempIndex].nonGenSymptomsId.toString().split(',')
				: [];
			if (event.target.checked == false) {
				var dataIndex = symptomsIds.findIndex((x) => x == id);
				symptomsIds.splice(dataIndex, 1);
			} else {
				symptomsIds.push(id.toString());
			}
			tempData[tempIndex].nonGenSymptomsId = symptomsIds.toString();
		}
		setNonGeneralData(tempData);
	};
	const handleClick = (event, id) => {
		if (event.target.checked == false) {
			var tempData = nonGeneralData;
			var tempIndex = tempData.findIndex((x) => x.nonGenMemberReviewTypeId == id);
			tempData.splice(tempIndex, 1);
			setNonGeneralData(tempData);
		}
		if (document.getElementById('one' + id).checked === true) {
			document.getElementById('two' + id).style.display = 'block';
		} else {
			document.getElementById('two' + id).style.display = 'none';
		}
	};
	const deleteValue = (e, i) => {
		let newArray = [...fullArray];
		newArray.splice(i, 1);
		setFullArray(newArray);
		setChiefCaseSenstiveArray(newArray)

	};
	const associatedDelete = (e, i) => {
		let associateArray = [...associatedArray];
		associateArray.splice(i, 1);
		setAssociatedArray(associateArray);
		setAssociatedCaseSenstiveArray(associateArray)
	};
	const knownCaseDelete = (e, i) => {
		let knownArray = [...knownCaseArray];
		knownArray.splice(i, 1);
		setKnownCaseArray(knownArray);
		setKnownCaseSenstiveArray(knownArray)
	};
	const diagnosisDelete = (e, i) => {
		let diagArray = [...diagnosisArray];
		diagArray.splice(i, 1);
		setDiagnosisArray(diagArray);
		setDiagnosisSenstiveArray(diagArray)

	};
	// const setValue = (e) => {
	// 	if (e.key == 'Enter') {
	// 		alert("enterrrrrrrrrrrrrrrrrrrrrrrr")
	// 		setGetValue(e.target.value);
	// 		setClear('');
	// 		setFullArray([ ...fullArray, e.target.value ]);
	// 	}

	// };
	// const setAssociateValue = (e) => {
	// 	if (e.key == 'Enter') {
	// 		setGetValue(e.target.value);
	// 		setAssociateClear('');
	// 		setAssociatedArray([ ...associatedArray, e.target.value ]);
	// 	}
	// };
	// const setKnownValue = (e) => {
	// 	if (e.key == 'Enter') {
	// 		setGetValue(e.target.value);
	// 		setKnownClear('');
	// 		setKnownCaseArray([ ...knownCaseArray, e.target.value ]);
	// 	}
	// };
	const setValue = (e) => {
		const filteredData1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData1.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setClear('');
				setFullArray([...fullArray, e.target.value]);
				setChiefCaseSenstiveArray([...chiefCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const setAssociateValue = (e) => {
		const filteredData = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setAssociateClear('');
				setAssociatedArray([...associatedArray, e.target.value]);
				setAssociatedCaseSenstiveArray([...associatedCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
	};
	const setKnownValue = (e) => {
		const filteredData2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
		if (e.key == 'Enter') {
			if (filteredData2.includes(e.target.value.toLowerCase()) == false) {
				setGetValue(e.target.value);
				setKnownClear('');
				setKnownCaseArray([...knownCaseArray, e.target.value]);
				setKnownCaseSenstiveArray([...knownCaseSenstiveArray, e.target.value.toLowerCase()])
			}
			else {
				alert("Already added")
				return false;
			}
		}
		// if(knownCaseSenstiveArray.indexOf(e.target.value.toLowerCase()) == -1){
		// if (e.key == 'Enter') {
		// 	setGetValue(e.target.value);
		// 	setKnownClear('');
		// 	setKnownCaseArray([...knownCaseArray, e.target.value]);
		// 	setKnownCaseSenstiveArray([...knownCaseSenstiveArray, e.target.value.toLowerCase()])
		// }}
		// else{
		// 	alert("Already added")
		// 	return false;
		// }
	};
	const setDiagnosisValue = (e) => {
		if (diagnosisSenstiveArray.indexOf(e.target.value.toLowerCase()) == -1) {
			if (e.key == 'Enter') {
				setGetValue(e.target.value);
				setDiagnosis('');
				setDiagnosisArray([...diagnosisArray, e.target.value]);
				setDiagnosisSenstiveArray([...diagnosisSenstiveArray, e.target.value.toLowerCase()])
			}
		}
		else {
			alert("Already added")
			return false;
		}
	};
	const deleteInputValue = (event) => {
		setClear(event.target.value);
	};
	const deleteAssociateValue = (event) => {
		setAssociateClear(event.target.value);
	};
	const deleteKnownValue = (event) => {
		setKnownClear(event.target.value);
	};
	const doctorClickFunction1 = (val) => {
		setDrName(val)
		// const postData = {
		// 	hospitalId: localStorage.getItem('hospitalId'),
		// };
		// callingAPI('commonUserFunctions/doctorList', postData)
		// 	.then((data) => {
		// 		if (data.data.success === '1') {
		// 			setDoctorList(data.data.result.doctorList);
		// 		} else {
		// 			alert(data.data.errorMessage);
		// 		}
		// 	})
		// 	.catch();
	};
	const doctorClickFunction = (val) => {
		//setDrName(val)
		const postData = {
			hospitalId: localStorage.getItem('hospitalId'),
		};
		callingAPI('commonUserFunctions/doctorList', postData)
			.then((data) => {
				if (data.data.success === '1') {
					setDoctorList(data.data.result.doctorList);
				} else {
					alert(data.data.errorMessage);
				}
			})
			.catch();
	};
	const diagnosisClickFunction = (mode, dropdownId) => {
		const postData = {
			complaintTypeId: 33,
			txtInput: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 1) {
			callingAPI('clinicalBackground/caseSheetDiagnosis', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setDiagnosisList(data.data.result.tagName);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else if (document.getElementById(mode).value.length <= 1) {
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	const setDiagnosisData = (itemName, mode, dropdownId) => {
		// setDiagnosis(itemName);
		// document.getElementById(dropdownId).style.display = 'none';
		if (diagnosisSenstiveArray.indexOf(itemName.toLowerCase()) == -1) {
			setDiagnosisArray([...diagnosisArray, itemName]);
			setDiagnosisSenstiveArray([...diagnosisSenstiveArray, itemName.toLowerCase()])
			setDiagnosis('');
			setDiagnosisList([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		else {
			alert("Already added")
			return false;
		}
	};
	useEffect(() => {
		doctorClickFunction();
		editingId();
		reasonFieldShow();
		callingAPI('commonUserFunctions/reviewTypeView')
			.then((data) => {
				if (data.data.success === '1') {
					setGeneral(data.data.result.reviewTypeData);
					setNonGeneral(data.data.result.reviewGroupTypeData);
					setImage(data.data.result.reviewTypeImageData);
				} else {
					alert(data.data.errorMessage);
				}
			})
			.catch();
	}, []);


	const recallClickSave = () => {
		let postData = {
			patientReacallId: "",
			memberId: window.sessionStorage.getItem('memberId'),
			doctorId: window.sessionStorage.getItem('patientDoctorId'),
			hospitalId: localStorage.getItem('hospitalId'),
			userId: window.sessionStorage.getItem('userId'),
			recallDateAndTime: recallDate ? Date.parse(moment(recallDate).format('YYYY-MM-DD hh:mm A')).toString() : "",
			status: "Pending",
			comments: comments
		}
		callingAPI('patientProfile/savePatientRecallDetails', postData).then((data) => {

		})
	}

	const saveClickFunction = (e, type) => {
		var temp = generalData;
		temp.map((data) => {
			data.genDescription = document.getElementById('txtRadiographGeneral' + data.genMemberReviewTypeId).value;
		});
		setGeneralData(temp);
		var temp = nonGeneralData;
		temp.map((data) => {
			data.nonGenDescription = document.getElementById('txtRadiograph' + data.nonGenMemberReviewTypeId).value;
		});
		setNonGeneralData(temp);
		if (clearValue) {
			const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered1.includes(clearValue.toLowerCase()) == false) {
				fullArray.push(clearValue)
			}
		}
		if (associateClearValue) {
			const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered.includes(associateClearValue.toLowerCase()) == false) {
				associatedArray.push(associateClearValue)
			}
		}
		if (knownClearValue) {
			const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
				knownCaseArray.push(knownClearValue)
			}
		}
		if (fullArray.length == 0) {
			alert("Please enter chief presenting complaint")
			document.getElementById("clearinputname1").focus()
			setDataFlag(true);
			return false;

		}
		// if (!recallDate) {
		// 	alert("Please Enter Recall date")
		// 	return false
		// }
		setDataFlag(false);
		if (disable) {
			return;
		}
		setDisable(true);

		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
			prNoEnabled: window.sessionStorage.getItem('isPrNumEnabled') === "true" ? true : false,

			// doctorId: appointmentDate
			// 	? document.getElementById('cmbDoctor').value
			// 	: window.sessionStorage.getItem('patientDoctorId'),
			txtAppoimenttDate: moment(new Date()).format('YYYY-MM-DD hh:mm A'),
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			scheduledtimeSlotStartTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')),
			scheduledtimeSlotEndTime: Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm A')) + 15 * 60 * 1000,
			txtFollowNote: appointmentNext,
			txtAppoimenttDateFuture: appointmentDate ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A') : "",
			scheduledtimeFutureDateSlotStartTime: appointmentDate ? Date.parse(appointmentDate) : "",
			scheduledtimeFutureDateSlotEndTime: appointmentDate ? Date.parse(appointmentDate) + 15 * 60 * 1000 : "",
			hidFinalize: type == 'Save' ? 'false' : 'true',
			needFollowUp: followUpClick,
			hidMode: '',
			uploadedImageNames: '',
			cmbStatus: document.getElementById('cmbStatus').value,
			tag1: fullArray.toString(),
			cheifDesc: chiefDescription,
			txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
			tag2: associatedArray.toString(),
			txtAssociate: associatedDescription,
			txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
			tag3: knownCaseArray.toString(),
			txtKnown: knownDescription,
			txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
			txtObservations: observations,
			txtInvestigations: investigations,
			txtRemarks: remarks,
			txtNeck: '',
			txtChest: '',
			txtEndo: '',
			txtAudiometry: '',
			txtEar: '',
			txtNose: '',
			txtThroat: '',
			txtDiagnosis: diagnosisArray.toString(),
			diagnosisAndTreatmentPlan: txtDiagnosis,
			hidStatusId: '',
			currentDate: moment(new Date()).format('DD-MM-YYYY'),
			generalSection: generalData,
			nonGeneralSection: nonGeneralData,
			txtTreatmentDone: txtTreatmentDone
		};
		callingAPI('commonUserFunctions/saveNewMedicalCaseSheet ', postData)
			.then((data) => {

				if (data.data.success === '1') {
					if (comments !== "" || recallDate !== "") {
						recallClickSave();
					}
					alert('Case Sheet Saved Successfully');
					window.location.href = '/Patients/casesheetMedical';
					setDataFlag(true);
				} else {
					setDataFlag(true);
					alert(data.data.errorMessage);
				}
				setDisable(false);
			})
			.catch((err) => {
				setDisable(false);
			});
	};
	const updateClickFunction = (e, type) => {
		setDataFlag(false);
		var temp = generalData;
		if (disable) {
			return;
		}
		setDisable(true);
		temp.map((data) => {
			data.genDescription = document.getElementById('txtRadiographGeneral' + data.genMemberReviewTypeId).value;
		});
		setGeneralData(temp);

		var temp = nonGeneralData;
		temp.map((data) => {
			data.nonGenDescription = document.getElementById('txtRadiograph' + data.nonGenMemberReviewTypeId).value;
		});
		setNonGeneralData(temp);
		if (clearValue) {
			const filtered1 = chiefCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered1.includes(clearValue.toLowerCase()) == false) {
				fullArray.push(clearValue)
			}
		}
		if (associateClearValue) {
			const filtered = associatedCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered.includes(associateClearValue.toLowerCase()) == false) {
				associatedArray.push(associateClearValue)
			}
		}
		if (knownClearValue) {
			const filtered2 = knownCaseSenstiveArray.filter(item => item.toLowerCase());
			if (filtered2.includes(knownClearValue.toLowerCase()) == false) {
				knownCaseArray.push(knownClearValue)
			}
		}
		if (fullArray.length == 0) {
			alert("Please enter chief presenting complaint")
			document.getElementById("clearinputname1").focus()
			return false;

		}
		// if (!recallDate) {
		// 	alert("Please Enter Recall date")
		// 	return false
		// }

		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			doctorId: getDrName ? getDrName : window.sessionStorage.getItem('patientDoctorId'),
			memberId: window.sessionStorage.getItem('memberId'),
			hospitalId: localStorage.getItem('hospitalId'),
			txtAppoimenttDate: appointmentDate
				? moment(appointmentDate).format('YYYY-MM-DD hh:mm A')
				: "",
			scheduledtimeSlotStartTime: appointmentDate
				? Date.parse(appointmentDate).toString()
				: "",
			scheduledtimeSlotEndTime: appointmentDate
				? (Date.parse(appointmentDate) + 15 * 60 * 1000).toString()
				: "",
			// txtAppoimenttDateFuture: appointmentDate
			// ? moment(appointmentDate).format('YYYY-MM-DD hh:mm A')
			// : "",
			// scheduledtimeFutureDateSlotStartTime: appointmentDate
			// ? Date.parse(appointmentDate)
			// :"",
			// scheduledtimeFutureDateSlotEndTime: appointmentDate
			// ? Date.parse(appointmentDate) + 15 * 60 * 1000
			// : "",
			needFollowUp: followUpClick,
			isChanged: isChanged,
			hidAptDocVisit: futureDrVisitId,
			hidDoctorVisitId1: window.sessionStorage.getItem('editId'),
			txtFollowNote: appointmentNext ? appointmentNext : updatedFollowNote,
			hidFinalize: type == 'Save' ? 'false' : 'true',
			hidMode: '',
			uploadedImageNames: '',
			cmbStatus: document.getElementById('cmbStatus').value,
			hidUpdatePmcId: pmId ? pmId[0].pmcId : '',
			hidDiagnosisId: diagnosisId,
			hidCurrentDate: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
			tag1: fullArray.toString(),
			cheifDesc: chiefDescription,
			txtCheifDate: sinceDate1 ? moment(sinceDate1).format('YYYY-MM-DD') : '',
			tag2: associatedArray.toString(),
			txtAssociate: associatedDescription,
			txtAssociateDate: sinceDate2 ? moment(sinceDate2).format('YYYY-MM-DD') : '',
			tag3: knownCaseArray.toString(),
			txtKnown: knownDescription,
			txtKnownDate: sinceDate3 ? moment(sinceDate3).format('YYYY-MM-DD') : '',
			txtObservations: observations,
			txtInvestigations: investigations,
			txtRemarks: remarks,
			txtNeck: '',
			txtChest: '',
			txtEndo: '',
			txtAudiometry: '',
			txtEar: '',
			txtNose: '',
			txtThroat: '',
			txtDiagnosis: diagnosis,
			diagnosisAndTreatmentPlan: txtDiagnosis,
			txtChiefReason: chiefReason,
			txtAssociatedReason: associatedReason,
			txtKnownCaseReason: knownReason,
			txtObservationsReason: observationReason,
			txtInvestigationsReason: investigationReason,
			txtRemarksReason: remarkReason,
			txtDiagnosisReason: diagnosisReason,
			hidStatusId: '',
			generalSection: generalData,
			nonGeneralSection: nonGeneralData,
			txtTreatmentDone: txtTreatmentDone
		};
		callingAPI('commonUserFunctions/updateMedicalCaseSheet', postData)
			.then((data) => {
				if (data.data.success === '1') {
					if (comments !== "" || recallDate !== "") {
						recallClickSave();
					}
					setDataFlag(true);
					alert('Case Sheet Updated Successfully');
					window.location.href = '/Patients/casesheetMedical';
				} else {
					setDataFlag(true);
					alert(data.data.errorMessage);
				}
				setDisable(false);
			})
			.catch((err) => {
				setDisable(false);
			});
	};
	const finalizeClick = (e) => {
		if (window.sessionStorage.getItem('editId') === '') {
			saveClickFunction(e, 'Finalize');
		} else {
			updateClickFunction(e, 'Finalize');
		}
	};

	const onChangelinkWrite = () => {
		window.location.href = '/patients/casesheetMedicalAddWrite';
	};
	const chiefLeave = (i) => {
		document.getElementById('chief' + i).style.display = 'none';
	};
	const chiefEnter = (i) => {
		document.getElementById('chief' + i).style.display = 'block';
		document.getElementById('chief' + i).style.display = 'inline';
	};
	const diagnosisEnter = (i) => {
		document.getElementById('diag' + i).style.display = 'block';
		document.getElementById('diag' + i).style.display = 'inline';
	};
	const diagnosisLeave = (i) => {
		document.getElementById('diag' + i).style.display = 'none';
	};

	const knownLeave = (i) => {
		document.getElementById('known' + i).style.display = 'none';
	};
	const knownEnter = (i) => {
		document.getElementById('known' + i).style.display = 'block';
		document.getElementById('known' + i).style.display = 'inline';
	};
	const qaz1 = (i) => {
		document.getElementById('qwe' + i).style.display = 'none';
	};
	const qaz = (i) => {
		document.getElementById('qwe' + i).style.display = 'block';
		document.getElementById('qwe' + i).style.display = 'inline';
	};


	const speechData = (data, variableName) => {
		if (variableName === 'chiefDescription') {
			setChief(false);
			if (data !== "") {
				setChiefDescription(chiefDescription.concat(' ' + data))
				// setLanguage("")
			}
		}
		else if (variableName === 'associatedDescription') {
			setAssociated(false);
			if (data !== "") {
				setAssociatedDescription(associatedDescription.concat(' ' + data));
			}
		}
		else if (variableName === 'knownDescription') {
			setKnownCase(false);
			if (data !== "") {
				setKnownDescription(knownDescription.concat(' ' + data));
			}
		}
		else if (variableName === 'observations') {
			setObservation(false);
			if (data !== "") {
				setObservations(observations.concat(' ' + data));
			}
		}
		else if (variableName === 'investigations') {
			setInvestigation(false);
			if (data !== "") {
				setInvestigations(investigations.concat(' ' + data));
			}
		}
		else if (variableName === 'remarks') {
			setRemark(false);
			if (data !== "") {
				setRemarks(remarks.concat(' ' + data));
			}
		}
		else if (variableName === 'txtDiagnosis') {
			setIsDiagnosis(false);
			if (data !== "") {
				setTxtDiagnosis(txtDiagnosis.concat(' ' + data));
			}
		}
		else if (variableName === 'txtTreatmentDone') {
			setTreatment(false);
			if (data !== "") {
				setTxtTreatmentDone(txtTreatmentDone.concat(' ' + data));
			}
		}
	}

	return (
		<div className="coverWraper">
			<Header />
			<div className="contentWraper contentWraperScroll patientPayment pl_casesheetWrap caseshtMedAdd">
				<Leftmenu />
				<div className="rightSection">
					<Profiledetail />
					<div className="practiceManagementWrap">
						<div className="row practiceManagementRow">
							<div className="col-lg-8 col-md-12 practiceLeftSection">
								<div class="treatmentPlanWrap patientConsultInnerPgeLoadDiv_PTour">
									<div id="divPatientContent" class="sideMenuContent">
										{dataFlag === false ? <Loader /> : ''}
										<div class="treatmentPlanCover" id="treatmentPlanListDiv">
											<div class="treatmentPlanHead">
												<span class="treatmentPlanName">CASE SHEET &nbsp;</span>
												{/* {window.sessionStorage.getItem('historynumb') == 1 ? 
												<span class="treatmentPlanBtn pl_blue_btn"><a href="/patients/casesheetDentalHistory">View History</a></span>:""} */}
												{/*  */}
												<div class="radioAreaType prescriptionradioBtn_PTour" id="radioareaid">
													<span class="precriptionDetails_PTour" style={{ float: 'left' }}>
														<input
															type="radio"
															name="radiobtn"
															value="type"
															id="radio3Id"
															class="precriptionTypeDetails_PTour"
															checked
														/>
														<a
															href="/patients/casesheetMedicalAdd"
															style={{ color: '#000', float: 'left' }}
														>
															<label class="radiolabel">Type&nbsp;&nbsp;</label>
														</a>
														<input
															type="radio"
															name="radiobtn"
															value="write"
															id="radio4Id"
															class="precriptionWriteDetails_PTour"
															onChange={onChangelinkWrite}
														/>
														<label class="radiolabel">Write</label>
													</span>
												</div>
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Youtube} />
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: 'transparent', padding: '0' }}
												>
													<img src={Light} style={{ width: '25px' }} />
												</span> */}
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', border: '1px solid #5bb75b' }}
													onClick={(e) => {
														finalizeClick(e);
													}}
												>
													Finalize
												</span>
												{/* <span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', border: '1px solid #5bb75b' }}
												>
													Admit
												</span> */}
												<span
													class="treatmentPlanBtn pl_blue_btn"
													style={{ background: '#5bb75b', border: '1px solid #5bb75b' }}
													id="saveid"
													disabled={disable}
													onClick={(e) => {
														saveClickFunction(e, 'Save');
													}}
												>
													Save
												</span>
												<span
													class="treatmentPlanBtn pl_blue_btn"
													id="updateid"
													disabled={disable}
													style={{
														background: '#5bb75b',
														display: 'none',
														border: '1px solid #5bb75b',
													}}
													onClick={(e) => {
														updateClickFunction(e, 'Save');
													}}
												>
													Update
												</span>
												<span class="treatmentPlanBtn pl_blue_btn pl_cancelbtn">
													<a href="/Patients/casesheetMedical">Cancel</a>
												</span>
											</div>
											<div class="treatmentClinicContent" id="treatmentClinicContentId">
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
													<div class="clinicalLabel">Chief presenting complaint <sup style={{ color: "red", fontSize: "15px", top: "0px" }}>*</sup></div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt" id="chiefTags">
																(Add Tag(s) & Press Enter Key.)<br />
																{fullArray.map((index, i) => (
																	<span>
																		<li
																			style={{
																				listStyle: 'none',
																				fontSize: '12px',
																			}}
																			onMouseEnter={(e) => chiefEnter(index)}
																			onMouseLeave={(e) => chiefLeave(index)}
																		>
																			{index}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'chief' + index}
																				onClick={(e) => deleteValue(e, i)}
																			>
																				x
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<span
																class="twitter-typeahead"
																style={{
																	position: 'relative',
																	display: 'inline - block',
																}}
															>
																<input
																	type="text"
																	onKeyPress={setValue}
																	value={clearValue}
																	onChange={deleteInputValue}
																	id="clearinputname1"
																	className="inputField"
																	autoComplete="off"
																	onKeyUp={() =>
																		orderAt(
																			'1',
																			'clearinputname1',
																			'locationSearch1'
																		)}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed "
																	id="locationSearch1"
																	style={{ display: 'none' }}
																>
																	{freeArticleData1.map((freeArticleDataObj, i) => (
																		<li
																			onClick={() =>
																				setChiefTagData(
																					freeArticleDataObj.tagName,
																					'clearinputname1',
																					'locationSearch1'
																				)}
																		>
																			{freeArticleDataObj.tagName}
																		</li>
																	))}
																</ul>
															</span>{' '}

															<div class="clinicalDesField position-relative">
																{browser === "chrome" ?
																	<div>
																		{isChief === false ?
																			<span className='speechBlock_sectn'>
																				<UseMic micClick={setChief} />
																			</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking'>
																				<Speech key={language} isClick={isChief} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"chiefDescription"} />
																			</span>
																		}</div> : ""}
																<span class="clinicalDesTxt">Description</span>

																<textarea
																	id="chiefDes"
																	maxlength="255"
																	value={chiefDescription + (isChief === true ? ' Listening...' : '')}
																	// value={chiefDescription}
																	onChange={(e) =>
																		setChiefDescription(e.target.value)}
																	placeholder="Description"
																	style={{ resize: 'none' }}
																/>
																<div id="chiefReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">
																		Reason For Change
																	</span>
																	<input
																		type="text"
																		value={chiefReason}
																		onChange={(e) => setChiefReason(e.target.value)}
																		className="inputField"
																		placeholder="Reason For Change"
																		style={{ width: '100%' }}
																	/>
																</div>
															</div>
														</div>
													</div>
													<div class="clinicalDate">
														<label>Since Date</label>
														<Datepicker
															required
															id="sinceDateTime"
															className="Datepicker pa2 inputField"
															maxDate={new Date()}
															dateFormat="dd/MM/yyyy"
															placeholderText="From"
															calendarClassName="rasta-stripes"
															selected={sinceDate1}
															onChange={(date) => setSinceDate1(date)}
														/>
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
													<div class="clinicalLabel">
														Associated <br />complaints
													</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt">
																(Add Tag(s) & Press Enter Key.)
																{associatedArray.map((index, i) => (
																	<span>
																		<li
																			style={{
																				listStyle: 'none',
																				fontSize: '12px',
																			}}
																			onMouseEnter={(e) => qaz(index)}
																			onMouseLeave={(e) => qaz1(index)}
																		>
																			{index}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'qwe' + index}
																				onClick={(e) => associatedDelete(e, i)}
																			>
																				x
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<span
																class="twitter-typeahead"
																style={{
																	position: 'relative',
																	display: 'inline - block',
																}}
															>
																<input
																	type="text"
																	onKeyPress={setAssociateValue}
																	value={associateClearValue}
																	id="clearinputname2"
																	onChange={deleteAssociateValue}
																	className="inputField"
																	autoComplete="off"
																	onKeyUp={() =>
																		orderAt(
																			'2',
																			'clearinputname2',
																			'locationSearch2'
																		)}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																	id="locationSearch2"
																	style={{ display: 'none' }}
																>
																	{freeArticleData1.map((freeArticleDataObj, i) => (
																		<li
																			onClick={() =>
																				setAssociatedTagData(
																					freeArticleDataObj.tagName,
																					'clearinputname2',
																					'locationSearch2'
																				)}
																		>
																			{freeArticleDataObj.tagName}
																		</li>
																	))}
																</ul>
															</span>{' '}
															<div class="clinicalDesField position-relative">
																{browser === "chrome" ?
																	<div>
																		{isAssociated === false ?
																			<span className='speechBlock_sectn'>
																				<UseMic micClick={setAssociated} />
																			</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking'>
																				<Speech key={language} isClick={isAssociated} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"associatedDescription"} />
																			</span>
																		}</div> : ""}
																<span class="clinicalDesTxt">Description</span>
																<textarea
																	id="associatedDes"
																	// value={associatedDescription}
																	value={associatedDescription + (isAssociated === true ? ' Listening...' : '')}
																	maxlength="255"
																	onChange={(e) =>
																		setAssociatedDescription(e.target.value)}
																	placeholder="Description"
																	style={{ resize: 'none' }}
																/>
																<div id="associatedReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">
																		Reason For Change
																	</span>
																	<input
																		type="text"
																		className="inputField"
																		value={associatedReason}
																		onChange={(e) =>
																			setAssociatedReason(e.target.value)}
																		placeholder="Reason For Change"
																		style={{ width: '100%' }}
																	/>
																</div>
															</div>
														</div>
													</div>
													<div class="clinicalDate">
														<label>Since Date</label>
														<Datepicker
															required
															//id="appoinmentDateTime"
															className="Datepicker pa2 inputField"
															placeholderText="From"
															maxDate={new Date()}
															calendarClassName="rasta-stripes"
															selected={sinceDate2}
															dateFormat="dd/MM/yyyy"
															onChange={(date) => setSinceDate2(date)}
														/>
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
													<div class="clinicalLabel">Known Case of</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt">
																(Add Tag(s) & Press Enter Key.)
																{knownCaseArray.map((index, i) => (
																	<span>
																		<li
																			style={{
																				listStyle: 'none',
																				fontSize: '12px',
																			}}
																			onMouseEnter={(e) => knownEnter(index)}
																			onMouseLeave={(e) => knownLeave(index)}
																		>
																			{index}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'known' + index}
																				onClick={(e) => knownCaseDelete(e, i)}
																			>
																				x
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<span
																class="twitter-typeahead"
																style={{
																	position: 'relative',
																	display: 'inline - block',
																}}
															>
																<input
																	type="text"
																	onKeyPress={setKnownValue}
																	value={knownClearValue}
																	onChange={deleteKnownValue}
																	id="clearinputname3"
																	className="inputField"
																	autoComplete="off"
																	onKeyUp={() =>
																		orderAt(
																			'3',
																			'clearinputname3',
																			'locationSearch3'
																		)}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																	id="locationSearch3"
																	style={{ display: 'none' }}
																>
																	{freeArticleData1.map((freeArticleDataObj, i) => (
																		<li
																			onClick={() =>
																				setKnownTagData(
																					freeArticleDataObj.tagName,
																					'clearinputname3',
																					'locationSearch3'
																				)}
																		>
																			{freeArticleDataObj.tagName}
																		</li>
																	))}
																</ul>
															</span>{' '}
															<div class="clinicalDesField position-relative">
																{browser === "chrome" ?
																	<div>
																		{isKnownCase === false ?
																			<span className='speechBlock_sectn'>
																				<UseMic micClick={setKnownCase} />
																			</span>
																			:
																			<span className='speechBlock_sectn speechBlock_speaking'>
																				<Speech key={language} isClick={isKnownCase} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"knownDescription"} />
																			</span>
																		}</div> : ""}
																<span class="clinicalDesTxt">Description</span>
																<textarea
																	id="knownDes"
																	// value={knownDescription}
																	value={knownDescription + (isKnownCase === true ? ' Listening...' : '')}
																	maxlength="255"
																	onChange={(e) =>
																		setKnownDescription(e.target.value)}
																	placeholder="Description"
																	style={{ resize: 'none' }}
																/>
																<div id="knownReason" class="clinicalDesField">
																	<span class="clinicalDesTxt">
																		Reason For Change
																	</span>
																	<input
																		type="text"
																		value={knownReason}
																		onChange={(e) => setKnownReason(e.target.value)}
																		className="inputField"
																		placeholder="Reason For Change"
																		style={{ width: '100%' }}
																	/>
																</div>
															</div>
														</div>
													</div>
													<div class="clinicalDate">
														<label>Since Date</label>
														<Datepicker
															required
															//id="appoinmentDateTime"
															className="Datepicker pa2 inputField"
															placeholderText="From"
															maxDate={new Date()}
															calendarClassName="rasta-stripes"
															selected={sinceDate3}
															dateFormat="dd/MM/yyyy"
															onChange={(date) => setSinceDate3(date)}
														/>
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
													{/* <span className='speechBlock_sectn'>
														{isObservation === false ?
															<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setObservation(true)} />
															:
															<Speech isClick={isObservation} speechData={speechData} variableName={"observations"} />
														}
													</span> */}
													<div class="clinicalLabel">Observations</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<div class="clinicalDesField">
																<textarea
																	maxlength="255"
																	placeholder="Observations"
																	style={{ resize: 'none' }}
																	// value={observations}
																	value={observations + (isObservation === true ? ' Listening...' : '')}
																	onChange={(e) => setObservations(e.target.value)}
																/>
															</div>
														</div>
														<div id="observationsReason" class="clinicalDesField">
															<span class="clinicalDesTxt">Reason For Change</span>
															<input
																type="text"
																value={observationReason}
																onChange={(e) => setObservationReason(e.target.value)}
																className="inputField"
																placeholder="Reason For Change"
															/>
														</div>

														{browser === "chrome" ?
															<div>
																{isObservation === false ?
																	<span className='speechBlock_sectn speechBlock_Change'>
																		<UseMic micClick={setObservation} />
																	</span>
																	:
																	<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																		<Speech key={language} isClick={isObservation} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"observations"} />
																	</span>
																}
															</div> : ""}
													</div>
												</div>

												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
													{/* <span className='speechBlock_sectn'>
														{isInvestigation === false ?
															<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setInvestigation(true)} />
															:
															<Speech isClick={isInvestigation} speechData={speechData} variableName={"investigations"} />
														}
													</span> */}
													<div class="clinicalLabel">Investigations</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<div class="clinicalDesField">
																<textarea
																	maxlength="255"
																	placeholder="Investigations"
																	style={{ resize: 'none' }}
																	// value={investigations}
																	value={investigations + (isInvestigation === true ? ' Listening...' : '')}
																	onChange={(e) => setInvestigations(e.target.value)}
																/>
															</div>
														</div>
														<div id="investigationsReason" class="clinicalDesField">
															<span class="clinicalDesTxt">Reason For Change</span>
															<input
																type="text"
																value={investigationReason}
																onChange={(e) => setInvestigationReason(e.target.value)}
																className="inputField"
																placeholder="Reason For Change"
															/>
														</div>
														{browser === "chrome" ?
															<div>
																{isInvestigation === false ?
																	<span className='speechBlock_sectn speechBlock_Change'>
																		<UseMic micClick={setInvestigation} />
																	</span>
																	:
																	<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																		<Speech key={language} isClick={isInvestigation} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"investigations"} />
																	</span>
																}</div> : ""}
													</div>
												</div>

												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour">
													<div class="clinicalLabel">
														Diagnosis<br />
														<small style={{ color: 'red' }}>
															(ICD-10)<sup>beta</sup>
														</small>

													</div>

													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<span class="clinicalFieldSubTxt" id="diagnosisTag">

																{diagnosisArray.map((index, i) => (
																	<span>
																		<li
																			style={{
																				listStyle: 'none',
																				fontSize: '12px',
																			}}
																			onMouseEnter={(e) => diagnosisEnter(index)}
																			onMouseLeave={(e) => diagnosisLeave(index)}
																		>
																			{index}
																			<span
																				style={{
																					display: 'none',
																					cursor: 'pointer',
																					padding: '10px',
																				}}
																				id={'diag' + index}
																				onClick={(e) => diagnosisDelete(e, i)}
																			>
																				x
																			</span>{' '}
																		</li>
																	</span>
																))}
															</span>
															<div class="clinicalDesField">
																<input
																	type="text"
																	placeholder="Diagnosis"
																	onKeyPress={setDiagnosisValue}
																	className="inputField"
																	id="diagnosis"
																	value={diagnosis}
																	onChange={(e) => setDiagnosis(e.target.value)}
																	onKeyUp={() =>
																		diagnosisClickFunction(
																			'diagnosis',
																			'diagnosisSearch'
																		)}
																/>
																<ul
																	className="autoSearch autoSearch-p0 appointmentNumberSearch appointmentNumberSearchCSMed"
																	id="diagnosisSearch"
																	style={{ display: 'none' }}
																>
																	{diagnosisList.map((diagnosisList1Obj, i) => (
																		<li
																			onClick={() =>
																				setDiagnosisData(
																					diagnosisList1Obj.tagName,
																					'clearinputname',
																					'locationSearch1'
																				)}
																		>
																			{diagnosisList1Obj.tagName}
																		</li>
																	))}
																</ul>
															</div>
														</div>
														<div id="diagnosisReason" class="clinicalDesField">
															<span class="clinicalDesTxt">Reason For Change</span>
															<input
																type="text"
																value={diagnosisReason}
																onChange={(e) => setDiagnosisReason(e.target.value)}
																className="inputField"
																placeholder="Reason For Change"
															/>
														</div>
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
													{/* <span className='speechBlock_sectn'>
														{isRemark === false ?
															<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setRemark(true)} />
															:
															<Speech isClick={isRemark} speechData={speechData} variableName={"remarks"} />
														}
													</span> */}
													<div class="clinicalLabel">Remarks</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<div class="clinicalDesField">
																<textarea
																	maxlength="255"
																	placeholder="Remarks"
																	// value={remarks}
																	value={remarks + (isRemark === true ? ' Listening...' : '')}
																	onChange={(e) => setRemarks(e.target.value)}
																	style={{ resize: 'none' }}
																/>
															</div>
														</div>
														<div id="remarksReason" class="clinicalDesField">
															<span class="clinicalDesTxt">Reason For Change</span>
															<input
																type="text"
																value={remarkReason}
																onChange={(e) => setRemarkReason(e.target.value)}
																className="inputField"
																placeholder="Reason For Change"
															/>
														</div>
														{browser === "chrome" ?
															<div>
																{isRemark === false ?
																	<span className='speechBlock_sectn speechBlock_Change'>
																		<UseMic micClick={setRemark} />
																	</span>
																	:
																	<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																		<Speech key={language} isClick={isRemark} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"remarks"} />
																	</span>
																}</div> : ""}
													</div>

												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
													{/* <span className='speechBlock_sectn'>
														{isDiagnosis === false ?
															<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setIsDiagnosis(true)} />
															:
															<Speech isClick={isDiagnosis} speechData={speechData} variableName={"txtDiagnosis"} />
														}
													</span> */}
													<div class="clinicalLabel">Diagnosis & Treatment Plan/Explained Options</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<div class="clinicalDesField">
																<textarea
																	maxlength="255"
																	placeholder=""
																	style={{ resize: 'none' }}
																	// value={txtDiagnosis}
																	value={txtDiagnosis + (isDiagnosis === true ? ' Listening...' : '')}
																	onChange={(e) => setTxtDiagnosis(e.target.value)}
																/>
															</div>
														</div>
														{browser === "chrome" ?
															<div>
																{isDiagnosis === false ?
																	<span className='speechBlock_sectn speechBlock_Change'>
																		<UseMic micClick={setIsDiagnosis} />
																	</span>
																	:
																	<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																		<Speech key={language} isClick={isDiagnosis} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtDiagnosis"} />
																	</span>
																}</div> : ""}
													</div>
												</div>
												<div class="clinicContentList dentalCaseSheetCheifPresntcomp_PTour position-relative">
													{/* <span className='speechBlock_sectn'>
														{isTreatment === false ?
															<FontAwesomeIcon icon={faMicrophone}  className='micIcon' onClick={() => setTreatment(true)} />
															:
															<Speech isClick={isTreatment} speechData={speechData} variableName={"txtTreatmentDone"} />
														}
													</span> */}
													<div class="clinicalLabel">Treatment Done</div>
													<div class="clinicalField">
														<div class="bootstrap-tagsinput">
															<div class="clinicalDesField">
																<textarea
																	maxlength="255"
																	placeholder=""
																	style={{ resize: 'none' }}
																	// value={txtTreatmentDone}
																	value={txtTreatmentDone + (isTreatment === true ? ' Listening...' : '')}
																	onChange={(e) => setTxtTreatmentDone(e.target.value)}
																/>
															</div>
														</div>
														{browser === "chrome" ?
															<div>
																{isTreatment === false ?
																	<span className='speechBlock_sectn speechBlock_Change'>
																		<UseMic micClick={setTreatment} />
																	</span>
																	:
																	<span className='speechBlock_sectn speechBlock_speaking speechBlock_speaking_change'>
																		<Speech key={language} isClick={isTreatment} language={language} speechData={speechData} setLanguage={setLanguage} variableName={"txtTreatmentDone"} />
																	</span>
																}</div> : ""}

													</div>
												</div>

												<div class="clinicContentList dentalCaseSheetAppontStatus_PTour">
													<div class="clinicArrived">
														<label class="clinicalLabel">Appointment Status</label>
														<div class="clinicalField">
															<select
																name="cmbStatus"
																id="cmbStatus"
																value={cmbStatus}
																onChange={(e) => setCmbStatus(e.target.value)}
															>
																<option value="1">OPEN</option>
																<option value="2">CONFIRM</option>
																<option value="3" selected="selected">
																	ARRIVED
																</option>
																<option value="4">DOCTOR CHANGED</option>
																<option value="8">ENGAGED</option>
																<option value="5">ADMITTED</option>
																<option value="7">DISCHARGE</option>
																<option value="6">COMPLETED</option>
																<option value="9">CANCELLED</option>
																<option value="10">CALL COMPLETED-DOCTOR</option>
															</select>
														</div>
													</div>
												</div>
												{/* {futureAppointmentDate.length > 0 ? ( */}

												{futureAppointmentDate.map((item, i) => (
													<div class="clinicFollowUp">
														<span class="followUpTxtDate" id="followUpTxtDateLabel">
															Next Appointment On {convertedFutureDate}
														</span>
														<span
															class="followUpTxt"
															onClick={() => setToggle(!toggle)}
														>
															<span onClick={() =>
																setIsChanged(true)}

															>

																Change
															</span>
														</span>
														<br />
														<span class="followUpTxtDate">
															Doctor : {item.doctor_name}
														</span>
														<br />
														{item.followUpNotes ? (
															<span class="followUpTxtDate">
																Bring For Next Appointment : {item.followUpNotes}
															</span>
														) : ('')}
													</div>

												))}
												<div class="clinicFollowUp caseSheetNeedFollowUp_PTour caseSheetWNeedFollowUp_PTour">
													<span class="followUpTxt needfollowup-inline" id="weee" onClick={() => setToggle(!toggle)} style={{ visibility: "visible" }}>
														<div onClick={() =>
															setFollowUpClick(true)}
															className='followup-button'

														>
															Add Appointment Follow Up
														</div>
													</span>
													<span class="followUpTxt recall-inline" id="weee" onClick={() => setRecallToggle(!recallToggle)} style={{ visibility: "visible" }}>
														<div style={{ paddingLeft: '15px' }} onClick={() =>
															setRecallClick(true)}
															className='recall-button'

														>
															Add Patient Recall
														</div>
													</span>
													{toggle && (
														<div id="needFollowDiv" style={{ display: 'block' }}>
															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Appointment Date &amp; Time
																</div>
																<div class="clinicalField">
																	<Datepicker
																		required
																		// id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		placeholderText="Date  Time"
																		dateFormat="dd-MM-y h:mm aa"
																		calendarClassName="rasta-stripes"
																		showTimeSelect
																		selected={appointmentDate}
																		onChange={(date) =>
																			setAppointmentDate(date)}
																	/>
																</div>
															</div>
															<div class="clinicContentList">
																<div class="clinicalLabel">By</div>
																<div class="clinicalField">
																	<select
																		name="cmbDoctor"
																		id="cmbDoctor"
																		value={getDrName}
																		onChange={(e) => doctorClickFunction1(e.target.value)}
																	>
																		<option >
																			Select Doctor
																		</option>
																		{doctorList.map((index, i) => (
																			<option value={index.doctor_id}>
																				{index.doctor_name}
																			</option>
																		))}
																	</select>
																</div>
															</div>

															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Bring For Next Appointment
																</div>
																<div class="clinicalField">
																	<textarea
																		name="txtFollowNote"
																		id="txtFollowNote"
																		maxlength="100"
																		style={{ resize: 'none' }}
																		value={appointmentNext}
																		onChange={(e) =>
																			setAppointmentNext(e.target.value)}
																	/>
																</div>
															</div>
														</div>
													)}

													{recallToggle && (
														<div id="needFollowDiv" style={{ display: 'block' }}>
															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Recall Date &amp; Time
																	{/* <span className='starimp'>*</span> */}
																</div>
																<div class="clinicalField">
																	<Datepicker
																		required
																		// id="appoinmentDateTime"
																		className="Datepicker pa2 inputField"
																		placeholderText="Date Time"
																		dateFormat="dd-MM-y h:mm aa"
																		calendarClassName="rasta-stripes"
																		showTimeSelect
																		minDate={new Date()}
																		selected={recallDate}
																		onChange={(date) =>
																			setRecallDate(date)}
																	/>
																</div>
															</div>

															<div class="clinicContentList">
																<div class="clinicalLabel">
																	Comments
																</div>
																<div class="clinicalField">
																	<textarea
																		name="txtFollowNote"
																		id="txtFollowNote"
																		maxlength="100"
																		style={{ resize: 'none' }}
																		value={comments}
																		onChange={(e) =>
																			setComments(e.target.value)}
																	/>
																</div>
															</div>
														</div>
													)}


												</div>


												<div class="medicalWrap">
													<span class="medicalHeading caseSheetSystemReview_PTour caseSheetWSystemReview_PTour">
														System Review
													</span>
													{general.map((item, i) => (
														<div>
															<div class="medicalCheck caseSheetMedicalChk_PTour caseSheetWMedicalChk_PTour">
																<span class="medicalGeneral">
																	<input
																		type="checkbox"
																		name="radGeneral"
																		id={'radGeneral' + item.prtId}
																	/>

																	<span>{item.prtName}</span>
																</span>
																<ul>
																	{item.genGrpSymptoms.map((data, i) => (
																		<li>
																			<input
																				type="checkbox"
																				name="chkgenSymptom1"
																				id={'chkgenSymptom1' + data.prsId}
																				onChange={(event) =>
																					generalClick(
																						event,
																						data.prsId,
																						item.prtId
																					)}
																			/>
																			<span>{data.symptomName}</span>
																		</li>
																	))}
																</ul>
															</div>

															<div
																class="clinicContentList"
																style={{ border: 'none', padding: '0px' }}
															>
																<div class="caseSheetRadioText dentalCaseSheetRadiographs_PTour">
																	<label>Description</label>
																	<textarea
																		id={'txtRadiographGeneral' + item.prtId}
																		name="txtRadiographGeneral"
																	/>
																</div>
															</div>
														</div>
													))}
													<div
														class="medicalPartWrap caseSheetMedicalPartWrap_PTour caseSheetWMedicalPartWrap_PTour"
														data-position="top"
													>
														{image.map((item, i) => (
															<div class="medicalPartList">
																<div class="medicalPartListMain">
																	<input
																		type="checkbox"
																		id={'one' + item.prtId}
																		name={'one' + item.prtId}
																		onChange={(e) => handleClick(e, item.prtId)}
																	/>

																	<span>
																		<img
																			src={getS3folder + '/' + item.imageName}
																			alt=""
																			title={item.prtName}
																		/>
																	</span>
																</div>
																<div class="medicalPartListSub" />
															</div>
														))}

														<br />
														<div id="medicalListDrpcontent">
															{nonGeneral.map((item, i) => (
																<div
																	class="medicalListDrpcontent"
																	id={'two' + item.prtId}
																	style={{ display: 'none' }}
																>
																	<div class="medicalListTitle">{item.prtName}</div>
																	<div class="medicalListcontent">
																		<ul>
																			{item.nonGenGrpSymptoms.map((data, j) => (
																				<li>
																					<input
																						type="checkbox"
																						name="chkSymptoms6"
																						id={'chkSymptoms6' + data.prsId}
																						onChange={(e) =>
																							handleClickNonGeneral(
																								e,
																								data.prsId,
																								item.prtId
																							)}
																					/>
																					<span>{data.symptomName}</span>
																				</li>
																			))}
																		</ul>
																	</div>
																	<div
																		class="clinicContentList"
																		style={{ border: 'none', padding: '0px' }}
																	>
																		<div class="caseSheetRadioText dentalCaseSheetRadiographs_PTour">
																			<label style={{ color: '#000' }}>
																				Description
																			</label>
																			<textarea
																				id={'txtRadiograph' + item.prtId}
																				name="txtRadiograph"
																			/>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>

												<div
													class="treatmentSelect treatmentPlanHead"
													id="bottomDiv"
													style={{ border: 'none', float: 'right' }}
												>
													<div class="treatmentSelectBtns caseSheetMAdd">
														<a
															href="/Patients/casesheetMedical"
															type="button"
															class="treatmentPlanBtn pl_blue_btn pl_cancelbtn"
															style={{
																float: 'left',
															}}
														>
															Cancel
														</a>

														<input
															type="submit"
															value="Save"
															id="lowsaveid"
															disabled={disable}
															onClick={(e) => {
																saveClickFunction(e, 'Save');
															}}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
														<input
															type="submit"
															value="Update"
															disabled={disable}
															id="lowupdateid"
															style={{ background: '#5bb75b', display: 'none' }}
															onClick={(e) => {
																updateClickFunction(e, 'Save');
															}}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
														{/* <input
															type="submit"
															value="Admit"
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/> */}
														<input
															type="submit"
															value="Finalize"
															onClick={(e) => {
																finalizeClick(e);
																// saveClickFunction(e, 'Finalize');
																// updateClickFunction(e, 'Finalize')
															}}
															class="clinicalSave dentalCaseSheetSaveBtn_PTour"
														/>
													</div>
												</div>
												{/*  */}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 practiceRightSection">
								<Patientright />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default CasesheetMedicalAdd;
