import React from "react";
import PharmacyHeader from "../pharmacyLogin/layout/header";
import Footer from "../layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "../pharmacyLogin/pharmacyLeft";
import PrescriptionDetails from "./prescriptionDetails";
import PrescriptionBill from "./prescriptionBill";
import PharmacyStockDetails from "./pharmacyStockDetails";
import MedicineReorder from "./medicineReorder";
import ExpiredMedicineStockDetails from "./expiredMedicineStockDetails";
import MedicineSalebyDay from "./medicineSalebyDay";
import PatientMedicine from "./patientMedicine";
import PatientMedicineView from "./patientMedicineView";
import PrescribedMedicinebyQuantity from "./prescribedMedicinebyQuantity";
import PrescriptionAmountView from "./prescriptionAmountView";
import OutsidePharmacyOrderDetails from "./outsidePharmacyOrderDetails";
import OutsidePharmacyOrderDetailsView from "./outsidePharmacyOrderDetailsView";
import UnavailableMedicine from "./unavailableMedicine";
import HospitalMedicineHistory from "./hospitalMedicineHistory";
import PrescriptionDetailsPrint from "./prescriptionDetailsPrint";
import PharmacyManufacture from "../Settings/pharmacyManufacture";
import PharmacySupplier from "../Settings/pharmacySupplier";
import PharmacySupplierReports from "../Reports/supplierInvoice";
import PharmacystockdetailsReports from "../Reports/pharmacystockdetails";
import PharmacyPaymentsReports from "../Reports/pharmacypayment";
import PharmacyprescriptionAmountReports from "../Reports/prescriptionAmount";
import PharmacyPurchaseLists from "../Settings/purchaseLists";
import PharmacyhospitalInventory from "../Settings/hospitalInventory";
import PharmacymedicineStockUpdate from "../Settings/medicineStockUpdate";
import BillingHeader from "../billingLogin/layout/header";
import BillingLeft from "../billingLogin/billingLeft";
import DoctorLeftMenu from "../Reports/DoctorLeftMenu";
function Pharmacy() {
  return (
    <div className="coverWraper innerCoverWraper hospitalLabWraper exportCalndrSec hosPharmacy headerHospitalLab headerHospitalharmacy ">
      {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? (
        <BillingHeader />
      ) : (
        <PharmacyHeader />
      )}
      <div className="contentWraper contentWraperScroll">
        {window.sessionStorage.getItem("role") === "ROLE_BILLINGADMIN" ? (
          <BillingLeft />
        ) : (
          <PharmacyLeft />
        )}
        <div className="settingsRight">
          <Route
            path="/pharmacyLogin/prescriptionDetails"
            component={PrescriptionDetails}
          />
          <Route
            path="/pharmacyLogin/prescriptionBill"
            component={PrescriptionBill}
          />
          <Route
            path="/pharmacyLogin/pharmacyStockDetails"
            component={PharmacyStockDetails}
          />
          <Route
            path="/pharmacyLogin/medicineReorder"
            component={MedicineReorder}
          />
          <Route
            path="/pharmacyLogin/expiredMedicineStockDetails"
            component={ExpiredMedicineStockDetails}
          />
          <Route
            path="/pharmacyLogin/medicineSalebyDay"
            component={MedicineSalebyDay}
          />
          <Route
            path="/pharmacyLogin/patientMedicine"
            component={PatientMedicine}
          />
          <Route
            path="/pharmacyLogin/patientMedicineView"
            component={PatientMedicineView}
          />
          <Route
            path="/pharmacyLogin/prescribedMedicinebyQuantity"
            component={PrescribedMedicinebyQuantity}
          />
          <Route
            path="/pharmacyLogin/prescriptionAmountView"
            component={PrescriptionAmountView}
          />
          <Route
            path="/pharmacyLogin/outsidePharmacyOrderDetails"
            component={OutsidePharmacyOrderDetails}
          />
          <Route
            path="/pharmacyLogin/outsidePharmacyOrderDetailsView"
            component={OutsidePharmacyOrderDetailsView}
          />
          <Route
            path="/pharmacyLogin/unavailableMedicine"
            component={UnavailableMedicine}
          />
          <Route
            path="/pharmacyLogin/hospitalMedicineHistory"
            component={HospitalMedicineHistory}
          />
          <Route
            path="/pharmacyLogin/prescriptionDetailsPrint"
            component={PrescriptionDetailsPrint}
          />
          <Route
            path="/settings/pharmacyManufacture"
            component={PharmacyManufacture}
          />
          <Route
            path="/settings/pharmacySupplier"
            component={PharmacySupplier}
          />
          <Route
            path="/reports/supplierInvoice"
            component={PharmacySupplierReports}
          />
          <Route
            path="/reports/pharmacystockdetails"
            component={PharmacystockdetailsReports}
          />
          <Route
            path="/reports/pharmacypayment"
            component={PharmacyPaymentsReports}
          />
          <Route
            path="/reports/prescriptionAmount"
            component={PharmacyprescriptionAmountReports}
          />
          <Route
            path="/settings/purchaseLists"
            component={PharmacyPurchaseLists}
          />
          <Route
            path="/settings/hospitalInventory"
            component={PharmacyhospitalInventory}
          />
          <Route
            path="/settings/medicineStockUpdate"
            component={PharmacymedicineStockUpdate}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Pharmacy;
